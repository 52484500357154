import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import { defineMessages } from 'react-intl'
import { findAddressUse } from 'src/models/Address'
import { ADDRESS_USE } from 'src/models/Address/AddressUse'

const bem = bemClassName.bind(null, 'form__group__button__address')

const messages = defineMessages({
  button_remove_delivery_address: {
    defaultMessage: 'Remove delivery address',
    id: 'form.field.button.remove.delivery.address'
  },
  button_remove_alternative_address: {
    defaultMessage: 'Remove alternative address',
    id: 'form.field.button.remove.alternative.address'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id:'remove',
    node: (
      <AddressButton
        intl={intl}
        icon="remove"
        messages={messages}
      />
    )
  }
}

export function AddressButton(props) {
  const { disabled, hidden, icon } = props

  const title = getTitle(props)

  return (
    <button
      {...props}
      className={bem({ disabled, hidden })}
      title={title}>
      <i className={bem('icon', [icon])} />
      {title}
    </button>
  )
}

function getTitle(props) {
  const { intl, messages, value } = props

  const lastItem = value.length -1
  const isDeliveryAddress = value[lastItem].addressUse.find(findAddressUse, { useId: ADDRESS_USE.DELIVERY })

  if (isDeliveryAddress) {
    return intl.formatMessage(messages.button_remove_delivery_address)
  }

  return intl.formatMessage(messages.button_remove_alternative_address)
}

AddressButton.propTypes = {
  intl: PropTypes.object,
  onClick: PropTypes.func,
  messages: PropTypes.object,
  value: PropTypes.array,
  titles: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  icon: PropTypes.string
}
