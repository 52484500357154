import {DocumentFieldsMapper} from './DocumentFieldsMapper'
import {ATTACHMENT_TYPES} from '../../../../../../../AttachmentTypes'
import {DOCUMENTS} from '../../../../../../../../models/Document'

export const MESSAGES_ID = {
  [DOCUMENTS.CITIZEN_CARD]: 'box_documents_label_document',
  [DOCUMENTS.FOREIGN_CARD]: 'box_documents_label_document_foreign',
}

const attachmentTypeToMessageIdsEnum = {
  [ATTACHMENT_TYPES.CELULA_CIUDADANIA_FRONT]: 'box_documents_label_attachments_document_front',
  [ATTACHMENT_TYPES.CELULA_CIUDADANIA_BACK]: 'box_documents_label_attachments_document_back',
  [ATTACHMENT_TYPES.CELULA_EXTRANJERIA_FRONT]: 'box_documents_label_attachments_document_foreign_front',
  [ATTACHMENT_TYPES.CELULA_EXTRANJERIA_BACK]: 'box_documents_label_attachments_document_foreign_back',
}

export const getDocumentsFields = ({ intl, nationalityId, messages, documents, attachments }) => {
  const documentFieldsMapper = new DocumentFieldsMapper({
    intl,
    messagesId: MESSAGES_ID,
    attachmentTypeToMessageIdsEnum
  })
  const nationality = documentFieldsMapper.mapNationalityField({ nationalityId, messages })
  const documentsFields = documentFieldsMapper.mapDocuments({ documents, messages })
  const attachmentsField = documentFieldsMapper.mapAttachmentsField({ attachments, messages, documentsFields })
  const termsField = documentFieldsMapper.mapTermsField({ attachments, messages })

  return [nationality, ...documentsFields, attachmentsField, termsField]
}
