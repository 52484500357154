import * as yup from 'yup'
import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import Policies from '../Policies/Policies.basic'
import messages from './Messages'

export const touchAfterReload = [
  'emails[0].email',
]

const disabledBy = ['DISABLED_RUC']

export function buildFormSchema(dependencies) {
  const { intl } = dependencies
  const errorMessage = intl.formatMessage(messages['email_confirm_error'])

  const DOCUMENTS = commonParams({ ...TYPES.DOCUMENTS(intl, dependencies), disabledBy })
  const RUC = commonParams(TYPES.RUC(intl, dependencies), false)
  const NAME_FIRST = commonParams(TYPES.NAME_FIRST(intl, dependencies))
  const NAME_LAST = commonParams(TYPES.NAME_LAST(intl, dependencies))
  const NAME_MESSAGE = TYPES.NAME_MESSAGE(intl, dependencies)
  const GENDER = commonParams(TYPES.GENDER(intl, dependencies))
  const BIRTHDAY = commonParams(TYPES.BIRTHDAY(intl, dependencies))
  const NATIONALITY = commonParams(TYPES.NATIONALITY(intl, dependencies))
  const PHONES = commonParams(TYPES.PHONES(intl, dependencies))
  const POLICIES = commonParams(Policies(intl, dependencies, TYPES.POLICIES), false)
  const EMAILS = commonParams(emailParams(TYPES.EMAILS(intl, dependencies), errorMessage))

  const fieldsSchema = [
    [DOCUMENTS],
    [RUC],
    [NAME_FIRST, NAME_LAST],
    [NAME_MESSAGE],
    [GENDER, BIRTHDAY],
    [NATIONALITY],
    [EMAILS],
    [PHONES],
    [POLICIES]
  ]

  return new FormSchema({ fieldsSchema, name: 'ConsultantPersonalInfo' })
}

function commonParams(field, required = true) {
  return {
    ...field,
    required
  }
}

function emailParams(field, message) {
  return {
    ...field,
    validations: yup.array().of(yup.object().shape({
      email: yup.string().lowercase().email().required(),
      email_confirm: yup.string().lowercase().equalTo(yup.ref('email'), message)
    }))
  }
}
