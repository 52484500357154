import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  ruc: {
    defaultMessage: 'RUC',
    id: 'form.field.ruc'
  },
  ruc_error: {
    defaultMessage: 'Must be at least {min} characters',
    id: 'form.field.error.ruc'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { documents }, locale: { configs }} = injection
  const { localization: { documents: { other, mask, lengthValidation } } } = configs

  const { document: number } = documents.find(findDocument, { ruc: other?.ruc }) || {}

  return {
    id: 'ruc',
    label: intl.formatMessage(messages.ruc),
    mask: mask.ruc,
    defaultValue: number,
    validations: yup.string().test({
      name: 'rucValidation',
      message: intl.formatMessage(messages.ruc_error, { min: lengthValidation.ruc && lengthValidation.ruc.min }),
      test() {
        const { options: { context: { values: { ruc } }}} = this

        return !ruc || ruc.length === lengthValidation.ruc.min
      }
    })
  }
}

function findDocument({ type }) {
  return type === this.ruc
}
