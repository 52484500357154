import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Input from 'src/base/Form/Components/Input'
import { documentsEnum, defineOptionByLocale } from './Documents.type'
import { DOCUMENTS } from 'src/models/Document'
import { isDefaultFlowToggleActive } from 'src/config'

const messages = defineMessages({
  passport: {
    defaultMessage: 'Enter the passport number',
    id: 'form.field.passport'
  },
  national_registry: {
    defaultMessage: 'Enter the national registry number',
    id: 'form.field.national_registry'
  },
  foreign_registry: {
    defaultMessage: 'Enter the foreign registry number',
    id: 'form.field.foreign_registry'
  },
  national_registry_label: {
    defaultMessage: 'Document number',
    id: 'form.field.label.national_registry'
  },
  citizen_card: {
    defaultMessage: 'Enter the citizen card number',
    id: 'form.field.citizen_card'
  },
  foreign_card: {
    defaultMessage: 'Enter the foreign card number',
    id: 'form.field.foreign_card'
  },
})

const dependencies = {
  messages
}

const helpMessage = {}
const placeholderMessage = {}
const labelMessage = {}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  helpMessage[DOCUMENTS.PASSPORT_PERU] = intl.formatMessage(messages['passport'])
  placeholderMessage[DOCUMENTS.PASSPORT_PERU] = intl.formatMessage(
    messages['passport']
  )
  placeholderMessage[DOCUMENTS.FOREIGN_REGISTRY] = intl.formatMessage(
    messages['foreign_registry']
  )
  placeholderMessage[DOCUMENTS.NATIONAL_REGISTRY] = intl.formatMessage(
    messages['national_registry']
  )
  placeholderMessage[DOCUMENTS.RUT] = intl.formatMessage(messages['national_registry'])
  labelMessage[DOCUMENTS.RUT] =  intl.formatMessage(messages['national_registry_label'])

  placeholderMessage[DOCUMENTS.FOREIGN_CARD] = intl.formatMessage(
    messages['foreign_card']
  )
  placeholderMessage[DOCUMENTS.CITIZEN_CARD] = intl.formatMessage(
    messages['citizen_card']
  )

  placeholderMessage[DOCUMENTS.DNI_ARGENTINA] = 'XX.XXX.XXX'
  labelMessage[DOCUMENTS.DNI_ARGENTINA] =  intl.formatMessage(messages['national_registry_label'])

  return {
    id: 'document',
    type: connect(mapStateToProps)(Input),
    onBlur: pushDataLayer(injection),
  }
}

const pushDataLayer = (injection) => {
  const {
    intl,
    locale: { id }
  } = injection
  const options = defineOptionByLocale(intl)[id]

  return (event) => {
    const { values, value: fieldValue } = event
    const selectedDocumentTypeId = values.documents[0].type

    const documentType = options.filter(
      (documentType) => documentType.value === selectedDocumentTypeId
    )[0]

    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationDocuments',
        document: documentType.label,
        value: fieldValue
      }
    })
  }
}

function mapStateToProps({ locale: { locale } }, ownProps) {
  const {
    configs: {
      localization: {
        documents: { mask: documentsMask }
      }
    }
  } = locale

  const {
    form: {
      values: { documents: [{ type } = {}] = [] }
    },
    disabled
  } = ownProps

  return {
    ...ownProps,
    label: labelMessage[type],
    placeholder: isDefaultFlowToggleActive() ? placeholderMessage[type] : null,
    message: isDefaultFlowToggleActive() ? null : helpMessage[type],
    mask: getMask({ type, disabled, documentsMask })
  }
}

function getMask({ type, disabled, documentsMask }) {
  if (disabled) return null

  return documentsMask[documentsEnum[type]]
}
