import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Form from 'src/base/Form'
import Modal from 'src/base/Modal'
import ErrorModal from 'src/views/Register/RegisterErrorModal'

import { APP_ROUTES } from 'src/App'

class FormWithDigitalSpace extends PureComponent{
  constructor(props) {
    super(props)

    this.onConfirm = this.onConfirm.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(data) {
    const { onSubmit } = this.props

    return onSubmit(data)
  }

  onConfirm() {
    const { history } = this.props

    return history.push(APP_ROUTES.DASHBOARD)
  }

  render() {
    return (
      <Fragment>
        {this.renderForm()}
        {this.renderModal()}
      </Fragment>
    )
  }

  renderForm() {
    const {
      step: { name, actions: { previous, next }, updateRule },
      touchAfterReload,
      goToPreviousPage,
      formSchema,
      error,
      paper
    } = this.props

    return (
      <Form
        key={name}
        error={error}
        formSchema={formSchema}
        updateRule={updateRule}
        className={`${name}__form`}
        touchAfterReload={touchAfterReload}
        cancelAction={previous ? goToPreviousPage : null}
        hiddenSubmit={!next}
        onSubmit={this.onSubmit}
        modal={ErrorModal}
        paper={paper}
      />
    )
  }

  renderModal() {
    if(!this.props.person.isRegistrationComplete)
      return null

    const { messages, intl: { formatMessage } } = this.props

    return (
      <Modal
        key="modal"
        open={true}
        title={formatMessage(messages['modal_success_title'])}
        message={formatMessage(messages['modal_success_message'])}
        acceptLabel={formatMessage(messages['smodal_success_accept_label'])}
        onClose={this.onConfirm}
      />
    )
  }

}

FormWithDigitalSpace.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  touchAfterReload: PropTypes.array,
  history: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  formSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  paper: PropTypes.bool,
  messages: PropTypes.object,
  intl: PropTypes.object
}

export default FormWithDigitalSpace
