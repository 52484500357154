export default {
  address: ['neighborhood', 'city', 'department'],
  authenticationLegacy: true,
  callEndpointComplete: false,
  contactValidationLegacy: true,
  date: {
    format: 'DD/MM/YYYY'
  },
  featureLite: false,
  getBusinessModelFromApi: false,
  getFiltersFromApi: false,
  map: {
    center: {
      lat: 4.624335,
      lng: -74.063644
    }
  },
  offlineMode: false,
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 2
    },
    countryCode: '55',
    customValidation: {
      invalid: /.*/,
      line: /.*/,
      mobile: {
        'start_code': {
          number: 0,
          validation: /.*/
        },
        'start_number_negation': {
          number: 0,
          validation: /^.{2}[1-9]/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 10
      },
      mobile: {
        min: 11
      }
    },
    mask: {
      line: '99 9999 9999',
      mobile: '(99) 99999-9999'
    },
    placeholder: {
      line: '(00) 0000-0000',
      mobile: '(00) 00000-0000'
    }
  },
  policies: [],
  selectPaymentPlan: false,
  shouldCallCreatePaymentPlanInPaymentView: false,
  shouldCallGetPersonInVerifyContacts: true,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: false,
  showProfileEditButton: true
}
