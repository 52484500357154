import { getContext } from '../../infra/Api/Api.context'

export const PERSON_SET_ERROR = 'natura-caduni/Person/PERSON_SET_ERROR'

export const types = [
  PERSON_SET_ERROR,
]

export function personSetErrorReducer(state, action) {
  const { payload: { error: errorToBeSet }} = action
  return {
    ...state,
    loading: false,
    error: errorToBeSet
  }
}

export function setPersonError(error) {
  const { dispatch } = getContext()

  return dispatch({
    type: PERSON_SET_ERROR,
    payload: {
      error
    }
  })
}
