import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import bemClassName from 'bem-classname'
import Button, { TYPES } from 'src/base/Button'
import Loader from 'src/base/Loader'
import PropTypes from 'prop-types'
import MESSAGES from './Action.messages'
import TagManager from 'react-gtm-module'

import './Action.styl'
import { isDefaultFlowToggleActive } from '../../../../../../config'

class Action extends PureComponent {
  constructor(props) {
    super(props)
    this.bem = isDefaultFlowToggleActive()
      ? bemClassName.bind(null, 'defaultFile__actions')
      : bemClassName.bind(null, 'file__actions')
  }

  get label() {
    const { editLabel, success, intl } = this.props
    const messageId =
      success || editLabel ? 'file_button_edit' : 'file_button_send'
    const message = MESSAGES[messageId]

    return (message && intl.formatMessage(message)) || ''
  }

  setDataLayer(buttonId, buttonText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }

  render() {
    const { disabled, error, success, loading, onClick, id} = this.props

    return (
      <div className={this.bem({ disabled, error, success })}>
        <Button
          onClick={(event) => {
            this.setDataLayer(id, this.label)
            onClick(event)
          }}
          className={this.bem('button')}
          type={
            isDefaultFlowToggleActive()
              ? TYPES.TEXT_ONLY_DEFAULT
              : TYPES.PRIMARY
          }
          disabled={disabled || loading}
        >
          {this.label}
          <Loader
            className={this.bem('loader', { ['disabled']: !loading })}
            transparent={true}
            fixed={false}
          />
        </Button>
      </div>
    )
  }
}

Action.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  success: PropTypes.bool,
  intl: PropTypes.object,
  loading: PropTypes.bool,
  editLabel: PropTypes.bool,
  id: PropTypes.string
}

export default injectIntl(Action)
