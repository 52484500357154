import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import FrontPhoto from './Documents/FrontPhoto'
import BackPhoto from './Documents/BackPhoto'
import Audio from './Documents/Audio'
import CNH from './Documents/CNH'
import Photo from './Documents/Photo'
import RG from './Documents/RG'
import RNE from './Documents/RNE'
import Deny from './Modal/Deny'
import Approved from './Modal/Approved'

import './Icon.styl'

const bem = bemClassName.bind(null, 'icon-custom')

const icons = {
  frontPhoto: FrontPhoto,
  backPhoto: BackPhoto,
  audio: Audio,
  cnh: CNH,
  photo: Photo,
  rg: RG,
  rne: RNE,
  deny: Deny,
  approved: Approved
}

const defineState = props => {
  const { state } = props
  if (state === '') return 'primary'
  return state
}

class IconCustom extends PureComponent {
  render() {
    const { withWrapper, name, label, className } = this.props
    const Icon = icons[name]
    const state = defineState(this.props)

    if (!withWrapper) {
      return <Icon {...this.props} state={state} />
    }

    return (
      <div className={classNames(bem(), className)}>
        <Icon {...this.props} state={state} />
        { label &&
          <div className={classNames(bem('label', { [state]: state }))}>{ label }</div>
        }
      </div>
    )
  }
}

IconCustom.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  withWrapper: PropTypes.bool,
  state: PropTypes.string,
  label: PropTypes.string,
}

IconCustom.defaultProps = {
  withWrapper: true,
  state: 'primary',
}

export default IconCustom
