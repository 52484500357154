import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'

import Message from 'src/base/Form/Components/Message'

const messages = defineMessages({
  analysis: {
    defaultMessage: 'Thanks to inform your data. Your registration will be reviewed and we contact you soon! If you have your document and electricity or water bill with you, click on NEXT',
    id: 'form.field.analysis'
  },
  in_analysis: {
    defaultMessage: 'In analysis.',
    id: 'form.field.in.analysis'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  const analysis = intl.formatMessage(messages['analysis'])

  return {
    id: 'analysis',
    node: <AnalysisField analysis={analysis} />
  }
}

function AnalysisField({ analysis }) {
  return (
    <Fragment>
      <Message message={analysis} />
    </Fragment>
  )
}

AnalysisField.propTypes = {
  analysis: PropTypes.string
}
