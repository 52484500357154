import { MalaysiaOptionsStrategy } from './options.mys'

const optionsStrategy = {
  MYS: MalaysiaOptionsStrategy,
  __default__: []
}

export const optionsByLocaleId = (localeId, { intl, messages }) => {
  return optionsStrategy[localeId]
    ? optionsStrategy[localeId]({ intl, messages })
    : optionsStrategy.__default__
}
