import { getContext } from 'src/infra/Api/Api.context'
import { PERSON_SAVE_STATE } from 'src/reducers/Person/Person.save'
import Person from 'src/models/Person'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const SAVE_PERSON_ATTACHMENTS_LOADING =
  'natura-caduni/Person/SAVE_PERSON_ATTACHMENTS_LOADING'
export const SAVE_PERSON_ATTACHMENTS_SUCCESS =
  'natura-caduni/Person/SAVE_PERSON_ATTACHMENTS_SUCCESS'
export const SAVE_PERSON_ATTACHMENTS_ERROR =
  'natura-caduni/Person/SAVE_PERSON_ATTACHMENTS_ERROR'

export const types = [
  SAVE_PERSON_ATTACHMENTS_LOADING,
  SAVE_PERSON_ATTACHMENTS_SUCCESS,
  SAVE_PERSON_ATTACHMENTS_ERROR
]

export function personSaveAttachmentsReducer(
  state,
  action,
  personDependencies
) {
  const { type, payload } = action
  let { error, person } = state

  const loading = type === SAVE_PERSON_ATTACHMENTS_LOADING
  const isError = type === SAVE_PERSON_ATTACHMENTS_ERROR
  const isSucess = type === SAVE_PERSON_ATTACHMENTS_SUCCESS

  if (isSucess)
    person = new Person({ ...person, ...payload.data }, personDependencies)

  if (isError) error = new Error(action.error)

  return {
    ...state,
    person,
    loading,
    error
  }
}

export function savePersonAttachments(person, data) {
  const globalAppContext = getContext()
  const {
    dispatch,
    connectivity,
    user: { sellerId }
  } = globalAppContext

  const { personId } = person

  if (connectivity.isOffline)
    return new Promise(offlineStep({ data, personId }))

  const { clientName, requestUrl, requestHeaders } = getRequestPayload({
    globalAppContext,
    personData: person
  })

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'PUT',
        url: requestUrl,
        headers: requestHeaders,
        data
      }
    }
  })

  function getRequestPayload({ globalAppContext, personData }) {
    const {
      ATTACHMENTS,
      ATTACHMENTS_BFF,
      bffHeaders,
      countryId,
      headersAuthenticationInfo,
      shouldAttachmentsFromBFF,
      tenantId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const clientName = shouldAttachmentsFromBFF
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId
    const { requestHeaders } = getRequestHeaders({
      personData,
      shouldAttachmentsFromBFF,
      headersAuthenticationInfo
    })

    const requestUrl = shouldAttachmentsFromBFF ? ATTACHMENTS_BFF : ATTACHMENTS

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }) {
      const {
        urls: { ATTACHMENTS },
        bff_urls: { ATTACHMENTS_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: { id: countryId, tenantId, companyId, configs },
        user: {
          sellerId,
          authentication: { xApikey, accessToken }
        }
      } = globalAppContext

      const shouldAttachmentsFromBFF = configs?.localization?.isHostedUIEnabled

      return {
        ATTACHMENTS: ATTACHMENTS(personId),
        ATTACHMENTS_BFF: ATTACHMENTS_BFF(personId),
        shouldAttachmentsFromBFF,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
        tenantId,
        personId
      }
    }

    function getRequestHeaders({
      personData,
      shouldAttachmentsFromBFF,
      headersAuthenticationInfo
    }) {
      const {
        globalPeoplePlatformApiKey,
        registersBffApiKey,
        userAccessToken
      } = headersAuthenticationInfo
      const { businessModel } = personData
      const { businessModelId, functionId, roleId } = businessModel
      const requestHeaders = shouldAttachmentsFromBFF
        ? {
          ...bffHeaders,
          'x-api-key': registersBffApiKey,
          Authorization: userAccessToken,
          businessmodel: businessModelId,
          function: functionId,
          role: roleId,
          tenantid: tenantId,
        }
        : {
          'x-api-key': globalPeoplePlatformApiKey,
          Authorization: userAccessToken,
          access_token: userAccessToken,
          userId: sellerId,
          businessmodel: businessModelId,
          function: functionId,
          role: roleId
        }

      return { requestHeaders }
    }
  }
}

function offlineStep({ data, personId }) {
  const { peopleRegister, dispatch } = getContext()

  return function (resolve) {
    dispatch({
      type: PERSON_SAVE_STATE,
      payload: { data }
    })

    peopleRegister.savePerson({ ...data, personId })

    return resolve(data)
  }
}
