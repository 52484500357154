import * as yup from 'yup'
import { Entity } from 'speck-entity'
import Roles from 'src/models/Roles'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import { isEqual } from 'lodash'

const adapter = validatorAdapter('Yup', yup)

const CN = {
  businessModelId: 1,
  roleId: 1,
  functionId: 1
}

const CCC = {
  businessModelId: 3,
  roleId: 2,
  functionId: 9
}

const ACT = {
  businessModelId: 2,
  roleId: 2,
  functionId: 14
}

const ACC = {
  businessModelId: 2,
  roleId: 3,
  functionId: 16
}

export const ROLES = {
  CN,
  CCC,
  ACT,
  ACC
}

export const BUSINESS_MODEL = {
  CONSULTANT: '1',
  SPECIALIST: '2',
  ENTREPRENEUR: '3'
}

export const SEPARATOR = '/'

export default class BusinessModel extends Entity {
  static SCHEMA = {
    businessModelId: adapter(yup.number().required()),
    functionId: adapter(yup.number().required()),
    roleId: adapter(yup.number().required()),
    roles: {
      validator: adapter(yup.array().nullable()),
      builder: builderRoles,
      defaultValue: []
    }
  }

  constructor(props) {
    super(props)

    this.getIdsFromLastWorkflow()
  }

  getIdsFromLastWorkflow() {
    const [{ businessModelId, functionId, roleId } = {}] = this.roles.items

    if (businessModelId && functionId && roleId) {
      Object.assign(this, { businessModelId, functionId, roleId })
    }
  }

  get businessModelAsUrl() {
    const { businessModelId, functionId, roleId } = this

    if (!businessModelId || !functionId || !roleId) return null

    return `${businessModelId}${SEPARATOR}${roleId}${SEPARATOR}${functionId}`
  }

  get businessModelAsParams() {
    const { businessModelId, functionId, roleId } = this

    return {
      businessModelId: +businessModelId,
      roleId: +roleId,
      functionId: +functionId
    }
  }

  get isCN() {
    const { businessModelAsParams } = this

    return isEqual(businessModelAsParams, ROLES.CN)
  }

  get isACT() {
    const { businessModelAsParams } = this

    return isEqual(businessModelAsParams, ROLES.ACT)
  }

  get isCollaborator() {
    const { businessModelAsParams: { businessModelId, roleId } } = this

    return businessModelId === ROLES.CCC.businessModelId && roleId === ROLES.CCC.roleId
  }

  get isConsultant() {
    const { businessModelAsParams: { businessModelId, roleId } } = this

    return businessModelId === ROLES.CN.businessModelId && roleId === ROLES.CN.roleId
  }

  get isFinalConsumer() {
    const { businessModelAsParams } = this

    return isEqual(businessModelAsParams, ROLES.ACC)
  }
}

function builderRoles(data) {
  const { items } = data

  return new Roles(items || data)
}
