import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import './SectionTitle.styl'
import { isDefaultFlowToggleActive } from 'src/config'



class SectionTitle extends PureComponent {

  constructor(props) {
    super(props)
    this.bem = isDefaultFlowToggleActive() ? bemClassName.bind(null, 'defaultSection-title') : bemClassName.bind(null, 'section-title')
    this.renderSubtitle = this.renderSubtitle.bind(this)
  }
  render() {

    return this.renderSectionTitleAccordingToToggle()
  }

  renderSectionTitleAccordingToToggle() {
    const { className, title, subtitle } = this.props

    return (
      <div className={classNames(this.bem(), className)}>
        <h2 className={this.bem('title')}>{title}</h2>
        {subtitle && subtitle.map(this.renderSubtitle)}
      </div>
    )
  }

  renderSubtitle(subtitle, key) {
    return (
      <p className={this.bem('subtitle')} key={key}>{subtitle}</p>
    )
  }
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  subtitle: PropTypes.any,
}

export default SectionTitle
