import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import classNames from 'classnames'

import './Message.styl'

const bem = bemClassName.bind(null, 'message')

class Message extends PureComponent {
  get content() {
    const { error, message, disabled } = this.props

    return disabled ? message : (error || message)
  }

  render() {
    const { className, error, disabled, emphasis, complement, primary, lineBreak } = this.props
    const { content } = this

    if(!content) return null

    return (
      <span className={classNames(bem({ error: !!error, disabled, primary: !!primary }), className)}>
        {content}
        {lineBreak && <br />}
        {emphasis && this.renderEmphasis()}
        {complement && this.renderComplement()}
      </span>
    )
  }

  renderComplement() {
    const { complement } = this.props

    return <Fragment>&nbsp;{complement}</Fragment>
  }

  renderEmphasis() {
    const { emphasis } = this.props

    return <strong>&nbsp;{emphasis}</strong>
  }
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  emphasis: PropTypes.string,
  paragraph: PropTypes.string,
  complement: PropTypes.string,
  primary: PropTypes.bool,
  lineBreak: PropTypes.bool
}

export default Message
