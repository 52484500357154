import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { Suspense } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'

export const history = createBrowserHistory()

import './App.i18n'

import { ROUTES } from './App.routes'
import State from './App.state'
import Title from './App.title'
import Theme from './App.theme'
import Locale from './App.locale'
import YupLocale from './App.yup.locale'

import { CompanyContext } from './contexts'
import { useCompany } from './hooks/useCompany'

import { LoadingPage } from './layouts/loading-page'
import { FaviconConfig } from './configs/favicon'
import { setHotjarConfig } from './configs/hotjar'
import { setGoogleTagManager } from './configs/google-tag-manager'

const Maintenance = React.lazy(() => import('src/views/Maintenance'))
const Login = React.lazy(() => import('src/views/Login'))
const Logout = React.lazy(() => import('src/views/Logout'))
const Auth = React.lazy(() => import('src/views/Auth'))
const Main = React.lazy(() => import('src/layouts/Main'))

export const APP_ROUTES = ROUTES

export default function App() {
  const { companyId } = useCompany()
  
  setGoogleTagManager({ companyId })
  setHotjarConfig({ companyId })

  return (
    <div>
      <Helmet>
        <body className={'body-mobile-friendly'} />
      </Helmet>
      <FaviconConfig />
      <Suspense fallback={<LoadingPage />}>
        <State>
          <Theme companyId={companyId}>
            <CompanyContext.Provider value={{ companyId }}>
              <Locale>
                <YupLocale>
                  <Router history={history}>
                    <Title>
                      <Switch key="appRoutes" path="?lang">
                        <Route
                          path={APP_ROUTES.MAINTENANCE}
                          component={Maintenance}
                        />
                        <Route path={APP_ROUTES.LOGIN} component={Login} />
                        <Route path={APP_ROUTES.AUTH} component={Auth} />
                        <Route path={APP_ROUTES.LOGOUT} component={Logout} />
                        <Route path={APP_ROUTES.BASE} component={Main} />
                      </Switch>
                    </Title>
                  </Router>
                </YupLocale>
              </Locale>
            </CompanyContext.Provider>
          </Theme>
        </State>
      </Suspense>
    </div>
  )
}
