import { getContext } from 'src/infra/Api/Api.context'

const ONLINE = 'natura-caduni/Connection/ONLINE'
const OFFLINE = 'natura-caduni/Connection/OFFLINE'

const initialState = {
  isOffline: false
}

export default function connectivityReducer(state = initialState, action = {}) {
  const { type, payload } = action

  const isOnline = type === ONLINE
  const isOffline = type === OFFLINE

  if (isOnline || isOffline) return payload

  return state
}

export function connectivityOnline() {
  const { dispatch } = getContext()

  return dispatch({
    type: ONLINE,
    payload: {
      isOffline: false
    }
  })
}

export function connectivityOffline() {
  const { dispatch } = getContext()

  return dispatch({
    type: OFFLINE,
    payload: {
      isOffline: true
    }
  })
}
