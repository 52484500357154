import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

export const DISABLED_BY = ['cpf']
export const touchAfterReload = [...DISABLED_BY, 'emails[0].email']

export function buildFormSchema(dependencies) {
  const { intl } = dependencies
  const autoFocus = { autoFocus: true }
  const documentsDependencies = Object.assign({}, dependencies, { htmlProps: autoFocus })

  const DOCUMENT_CPF = commonParams(TYPES.DOCUMENT_CPF(intl, documentsDependencies), true, false)
  const NAME_FULL = commonParams(TYPES.NAME_FULL(intl, dependencies))
  const NAME_MESSAGE = TYPES.NAME_MESSAGE(intl, dependencies)
  const GENDER = commonParams(TYPES.GENDER(intl, dependencies))
  const BIRTHDAY = commonParams(TYPES.BIRTHDAY(intl, dependencies))
  const EMAILS = commonParams(TYPES.EMAILS(intl, dependencies))
  const PHONES = commonParams(TYPES.PHONES(intl, dependencies))
  const ZIPCODE = commonParams(TYPES.ZIPCODE(intl, dependencies))
  const ZIPCODE_SEARCH = TYPES.ZIPCODE_SEARCH(intl, dependencies)

  const fieldsSchema = [
    [DOCUMENT_CPF],
    [NAME_FULL],
    [NAME_MESSAGE],
    [GENDER, BIRTHDAY],
    [EMAILS],
    [PHONES],
    [ZIPCODE],
    [ZIPCODE_SEARCH]
  ]

  return new FormSchema({ fieldsSchema, name: 'PersonalInfo' })
}

function commonParams(field, required = true, disabledBy = DISABLED_BY) {
  return {
    ...field,
    disabledBy,
    required
  }
}
