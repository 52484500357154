import { isEnableDigitalStoreStepForPeruFlowToggleActive } from '../../../../../config'
import { FormattedHTMLMessage } from 'react-intl'
import React from 'react'
import { messages } from '../../Email'

const strategies = {
  PER: () => isEnableDigitalStoreStepForPeruFlowToggleActive(),
  COL: () => true,
  ARG: () => true,
  __default__: () => false
}

// eslint-disable-next-line react/prop-types
export const getEmailInputInfoText = ({ countryId }) => {
  const { email_info_text } = messages
  const strategy = strategies[countryId] || strategies.__default__
  return strategy() ? <FormattedHTMLMessage {...email_info_text} /> : null
}
