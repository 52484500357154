import React from 'react'
import {
  FormattedMessage,
  defineMessages,
  FormattedHTMLMessage
} from 'react-intl'
import Tooltip from '../../../../../../base/Tooltip/Tooltip'
import Icon from '../../../../../../base/Icon'
import Tag from '../../../../../../base/Tag/Tag'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Pre Register',
    id: 'register.documentation.label'
  },
  page_title: {
    defaultMessage: 'Send Documents',
    id: 'register.documents.page.title'
  },
  subtitle_default: {
    defaultMessage:
      'Now we need you to send us a photo of your document. Remember that for document, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default'
  },
  subtitle_default_dni: {
    defaultMessage:
      'Now we need you to send us a photo of your DNI. Remember that for document, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_dni'
  },
  subtitle_default_citizenCard: {
    defaultMessage:
      'Now we need you to send us a photo of your Citizen Card document. Remember that for Citizen Card, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_citizenCard'
  },
  subtitle_default_foreignCard: {
    defaultMessage:
      'Now we need you to send us a photo of your Foreign Card document. Remember that for Foreign Card, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_foreignCard'
  },
  subtitle_default_nationalRegistry: {
    defaultMessage:
    'Now we need you to send us a photo of your DNI document. Remember that for DNI, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_nationalRegistry'
  },
  subtitle_default_foreignRegistry: {
    defaultMessage:
      'Now we need you to send us a photo of your Foreign Registry document. Remember that for Foreign Registry, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_foreignRegistry'
  },
  subtitle_default_temporaryPermissionWork: {
    defaultMessage:
      'Now we need you to send us a photo of your Passport document. Remember that for Passport, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_temporaryPermissionWork'
  },
  subtitle_default_rut: {
    defaultMessage:
      'Now we need you to send us a photo of your National Registry document. Remember that for National Registry, we need a photo of the <strong>front</strong> and another of the <strong>back</strong> of the document.',
    id: 'register.documents.subtitle_default_rut'
  },
  tooltip: {
    defaultMessage:
      'We ask for your ID Card to verify your identity. That way we can confirm the veracity of your data, avoiding possible fraud.',
    id: 'register.documents.tooltip'
  },
  fileBack: {
    defaultMessage: 'Backs',
    id: 'register.documents.file.back'
  },
  fileFront: {
    defaultMessage: 'Front',
    id: 'register.documents.file.front'
  },
  fileSubtitle: {
    defaultMessage:
      'The files must be in image format and have a maximum of 5MB.',
    id: 'register.documents.file.subtitle'
  },
  fileErrorMessage: {
    defaultMessage:
      'We found an error in the image you uploaded. Check that the image format matches those required above.',
    id: 'register.documents.file.errorMessage'
  },
  citizenCard: {
    defaultMessage: 'Citizen Card',
    id: 'register.documents.file.citizen_card'
  },
  foreignCard: {
    defaultMessage: 'Foreign Card',
    id: 'register.documents.file.foreign_card'
  },
  nationalRegistry: {
    defaultMessage: 'National Registry',
    id: 'register.documents.file.national_registry'
  },
  foreignRegistry: {
    defaultMessage: 'Foreign Registry',
    id: 'register.documents.file.foreign_registry'
  },
  temporaryPermissionWork: {
    defaultMessage: 'Passport',
    id: 'register.documents.file.temporary_permission_work'
  },
  rut: {
    defaultMessage: 'National Registry',
    id: 'register.documents.file.rut'
  },
  dni: {
    defaultMessage: 'DNI',
    id: 'register.documents.file.dni',
  }
})

export function getSectionTitle({ messages = Messages }, documentType) {
  const { page_title, subtitle_default, step_label, tooltip } = messages
  const translatedSubtitle = messages[`subtitle_default_${documentType}`]
  const subtitle = translatedSubtitle || subtitle_default

  return {
    title: <><FormattedMessage {...page_title} /> { documentType && <Tag content={<FormattedMessage {...messages[documentType]}/>}/> }</>,
    subtitle: [
      <>
        <FormattedHTMLMessage {...subtitle} key="subtitle_default" />
        <Tooltip subtitle={<FormattedMessage {...tooltip} key="tooltip" />}>
          <Icon name="help_outline2" />
        </Tooltip>
      </>
    ],
    label: <FormattedMessage {...step_label} />
  }
}

export function getFileTitles({ messages = Messages }) {
  const { fileBack, fileFront } = messages

  return {
    fileBack: <FormattedMessage {...fileBack} />,
    fileFront: <FormattedMessage {...fileFront} />
  }
}

export function getFileSubtitle({ messages = Messages }) {
  const { fileSubtitle } = messages

  return {
    fileSubtitle: <FormattedMessage {...fileSubtitle} />
  }
}

export function getErrorMessage({ messages = Messages }) {
  const { fileErrorMessage } = messages

  return {
    fileErrorMessage: <FormattedMessage {...fileErrorMessage} />
  }
}

export default Messages
