import React from 'react'
import { defineMessages } from 'react-intl'

import Message from 'src/base/Form/Components/Message'

const messages = defineMessages({
  name_message: {
    defaultMessage: 'Exactly as shown on the identification document',
    id: 'form.field.name.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const message = intl.formatMessage(messages['name_message'])

  return {
    id: 'name_message',
    node: (
      <Message message={message} />
    )
  }
}
