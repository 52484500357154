import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import { injectIntl } from 'react-intl'

import Icon from 'src/base/Icon'
import Countdown from './Countdown'
import MESSAGES from './RecordAudio.messages'

const bem = bemClassName.bind(null, 'record-audio')

//TODO Make Document entity.type to return the document name as well
const DOCUMENT_NAME = {
  9: 'Cédula Ciudadania',
  10: 'Cédula Extranjera',
  19: 'DNI',
  33: 'Carnet de Extranjeria',
  32: 'Pasaporte'
}

export const RecordTerms = function({
  recordAudio,
  recording,
  activeCountdownNumber,
  recordDuration,
  maxDurationInSeconds,
  stopRecording,
  personalDocument,
  person,
  permitted,
  intl
}) {
  const { name } = person

  return (
    <div className={bem()}>
      <header>
        <h3 className={bem('title')}>
          { intl.formatMessage(MESSAGES.recordTermsTitle) }
        </h3>
      </header>
      <section className={bem('content')}>
        <p>
          { intl.formatMessage(
            MESSAGES.recordTermsContent,
            { name, type: DOCUMENT_NAME[personalDocument.type], number: personalDocument.document })
          }
        </p>
      </section>

      <section className={bem('content')}>
        {renderCountdown({ recording, activeCountdownNumber, recordAudio, permitted })}
        {renderRecordingStatus({ recording, recordDuration, maxDurationInSeconds, stopRecording })}
      </section>
    </div>
  )
}

RecordTerms.defaultProps = {
  recordDuration: 0,
  maxDurationInSeconds: 50
}

RecordTerms.propTypes = {
  activeCountdownNumber: PropTypes.number,
  maxDurationInSeconds: PropTypes.number,
  personalDocument: PropTypes.object,
  recordAudio: PropTypes.func,
  recordDuration: PropTypes.number,
  recording: PropTypes.bool,
  stopRecording:  PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  person: PropTypes.object,
  permitted: PropTypes.bool,
  intl: PropTypes.object
}

function renderCountdown ({ recording, activeCountdownNumber, recordAudio, permitted }) {
  if (recording) return null
  const handleCountdownClick = permitted ? recordAudio : null

  return (
    <React.Fragment>
      <Countdown activeCountdownNumber={activeCountdownNumber} />
      <Icon
        name="microphone"
        className={bem('actions__icon', { microphone: true, disabled: !permitted })}
        onClick={handleCountdownClick} />
    </React.Fragment>
  )
}

renderCountdown.propTypes = {
  activeCountdownNumber: PropTypes.number,
  permitted: PropTypes.bool,
  recordAudio: PropTypes.func,
  recording: PropTypes.bool
}

function renderRecordingStatus ({ recording, recordDuration, maxDurationInSeconds, stopRecording }) {
  if (!recording) return null

  return (
    <div className={bem('record-status')}>
      <Icon
        name="audio_stop"
        className={bem('actions__icon', { microphone: true, disabled: !stopRecording })}
        onClick={stopRecording || null} />
      <span className={bem('record-status__duration')}>
        {`00:${recordDuration.toString().padStart(2, 0)} / 00:${maxDurationInSeconds.toString().padStart(2, 0)}`}
      </span>
    </div>
  )
}

renderRecordingStatus.propTypes = {
  maxDurationInSeconds: PropTypes.number,
  recordAudio: PropTypes.func,
  recordDuration: PropTypes.number,
  recording: PropTypes.bool,
  stopRecording: PropTypes.func
}

export default injectIntl(RecordTerms)
