import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class DigitalSpace extends Entity {
  static SCHEMA = {
    available: adapter(yup.bool()),
    saved: adapter(yup.bool()),
    digitalStoreName: adapter(yup.string())
  }

  constructor(props, injection) {
    super(props)

    Object.assign(this, injection)

    this.get = this.get.bind(this)
    this.check = this.check.bind(this)
    this.save = this.save.bind(this)
  }

  get(personId) {
    return this.getDigitalSpace(personId)
  }

  check(digitalStoreName, personId) {
    return this.checkDigitalSpace(digitalStoreName, personId)
  }

  save(digitalStoreName, personId) {
    return this.saveDigitalSpace(digitalStoreName, personId)
  }
}

