import { SUBSTATUS } from '../../../../../../models/Substatus'

export function onSubmition(deps) {
  const { person, peopleRegister, upload } = deps

  return async function (data, callback) {
    const parsedData = parseData({ data, person })

    const attachmentsValues = Object.values(data)
    const buildAttachmentDeps = { person, upload }


    const attachments = await Promise.all(
      attachmentsValues.map(buildAttachmentList, buildAttachmentDeps)
    )

    const attachmentsFiltered = attachments.filter((attach) => attach)

    const updatePerson = person.save(
      parsedData,
      savePersonStatus(person, callback)
    )

    peopleRegister.savePerson({ ...person, ...parsedData, attachmentsFiltered })
    person.saveAttachments({ attachments: attachmentsFiltered }, callback)

    return updatePerson
  }
}

async function buildAttachmentList(value) {
  const { upload, person: { personId, attachments } } = this

  if (!value || !value.type) return

  const { type, downloadUrl, file } = value

  const personAttachment = (attachments && attachments.find(findAttachment, { type })) || {}
  const sequence = value.sequence || personAttachment.sequence

  if (downloadUrl) {
    return { sequence, type, url: downloadUrl }
  }

  const {
    payload: {
      data: [{ uploadUrl, downloadUrl: newDownloadUrl }] = [{}],
      error: errorUploadCreate
    } = {}
  } = await upload.create(personId, { type, value: file })

  const { error: errorUploadSave } = await upload.save({ uploadUrl, type, file })

  if (errorUploadCreate || errorUploadSave) return

  return { sequence, type, url: newDownloadUrl }
}

function findAttachment({ type }) {
  return type === this.type
}

function savePersonStatus(person, callback) {
  return function (newPerson, result) {
    if (!result.error) {
      person.saveStatus({
        substatus: SUBSTATUS.FINAL_DATA_VALIDATION
      })
    }
    callback()
  }
}

function parseData({ data, person }) {
  return {
    birthday: data.birthday || person.birthday,
    gender: data.gender || person.gender,
    nationalityId: data.nationalityId || person.nationalityId
  }
}
