import BusinessModel from 'src/viewModels/BusinessModel'

export const REHYDRATE = 'persist/REHYDRATE'

export const types = [REHYDRATE]

export function businessModelRehydrateReducer(state, action, dependencies) {
  const { payload = {} } = action
  const { businessModel: { businessModel: businessModelSaved = [] } = {} } = payload

  const businessModel = new BusinessModel(businessModelSaved, dependencies)

  return {
    loading: false,
    error: false,
    businessModel
  }
}
