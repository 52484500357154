import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Pre Register',
    id: 'register.documentation.label'
  },
  page_title: {
    defaultMessage: 'Documentation',
    id: 'register.documentation.page.title'
  },
  subtitle_1: {
    defaultMessage: 'To finish, you must complete this information and read and accept the terms.',
    id: 'register.documentation.subtitle_1'
  },
})

export function getSectionTitle({ messages = Messages }) {

  const {
    page_title,
    subtitle_1,
    step_label
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />
    ],
    label: <FormattedMessage {...step_label} />,
  }
}

export default Messages
