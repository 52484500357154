import * as yup from 'yup'
import { store } from 'src/App.state'
import Level1 from './Level1.arg'
import Level2 from './Level2.arg'
import Level3 from './Level3.arg'
import Level4 from './Level4.arg'
import FormDivider from '../AddressDivider'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    locale,
    connectivity
  } = injection
  const countryName = retrieveCountryNameFromLocale(locale)

  const baseValue = {
    copyAdress: true,
    countryName
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)
  const level1 = Level1(intl, injection)
  const level2 = Level2(intl, injection)
  const level3 = Level3(intl, injection)
  const level4 = Level4(intl, injection)
  const GROUP_DIVIDER = FormDivider(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    itemTitle: GROUP_DIVIDER,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [level1, level2],
      [level3, level4]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          level1: yup.string().required(),
          level2: yup.string().required(),
          level3: yup.string().required(),
          level4: yup.string().required()
        })
      )
      .min(1)
      .max(20)
  }

  function retrieveCountryNameFromLocale(locale) {
    return locale.name && locale.name.toUpperCase()
  }
}

export function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)

    const onlyMailingAddress = addresses.filter(address => {
      const addressUseDelivery =  address.addressUse.find(use =>  {
        return use.id === MAILING_ADDRESS.id
      })
      return addressUseDelivery !== undefined
    })

    const parsedValues = connectivity.isOffline
      ? onlyMailingAddress.reduce(reduceAddressOffline, [baseValue])
      : onlyMailingAddress.reduce(reduceAddress, [baseValue])

    return [{...parsedValues[0], addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]}]
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

export function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress =
    (deliveryAddress && !mailingAddress) ||
    (deliveryAddress && mailingAddress && !mailingAddress.isUseToDelivery)

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

export function reduceAddress(accum, address, index, array) {
  const {
    addresses: { addressesPeopleManagement: addresses }
  } = store.getState()
  const { departments, cities, neighborhoods, communes } = addresses

  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    geographicalLevels,
    addressUse,
    isUseToDelivery,
    config: { levels }
  } = address

  const geographicalLevelsSortedByLevel = geographicalLevels.sort((currentGeoLevel, nextGeoLevel) => currentGeoLevel.level - nextGeoLevel.level)

  const { geoStructureCode: level1Code } = getStructure(
    departments[countryName],
    geographicalLevelsSortedByLevel[levels.LEVEL1]
  )

  const { geoStructureCode: level2Code } = getStructure(
    cities[level1Code],
    geographicalLevelsSortedByLevel[levels.LEVEL2]
  )

  const { geoStructureCode: level3Code } = getStructure(
    neighborhoods[level2Code],
    geographicalLevelsSortedByLevel[levels.LEVEL3]
  )

  const { geoStructureCode: level4Code } = getStructure(
    communes[level3Code],
    geographicalLevelsSortedByLevel[levels.LEVEL4]
  )

  const newAddress = {
    level1: level1Code,
    level2: level2Code,
    level3: level3Code,
    level4: level4Code,
    addressUse,
    countryName,
    copyAdress: isUseToDelivery
  }

  return [...accum, newAddress]
}

export function reduceAddressOffline(accum, address, index, array) {
  const [{ countryName, copyAdress }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const { geographicalLevels, config: { levels } } = address

  const newAddress = {
    level1: geographicalLevels[levels.LEVEL1].code,
    level2: geographicalLevels[levels.LEVEL2].code,
    level3: geographicalLevels[levels.LEVEL3].code,
    level4: geographicalLevels[levels.LEVEL4].code,
    countryName,
    copyAdress
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  if (this.code) {
    return value.toString() === this.code.toString()
  }
  return value.toString() === this.code
}
