import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  ethnicity: {
    defaultMessage: 'Ethnicity',
    id: 'form.field.ethnicity'
  },
  ethnicity_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.ethnicity.placeholder'
  },
  ethnicity_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.ethnicity.no.options.message'
  },
  ethnicity_malay: {
    defaultMessage: 'Malay',
    id: 'form.field.ethnicity.malay'
  },
  ethnicity_bumiputera: {
    defaultMessage: 'Bumiputera',
    id: 'form.field.ethnicity.bumiputera'
  },
  ethnicity_chinese: {
    defaultMessage: 'Chinese',
    id: 'form.field.ethnicity.chinese'
  },
  ethnicity_indian: {
    defaultMessage: 'Indian',
    id: 'form.field.ethnicity.indian'
  },
  ethnicity_others: {
    defaultMessage: 'Others',
    id: 'form.field.ethnicity.others'
  }
})
