import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'

export const SMS_TOKEN_VERIFY_LOADING = 'natura-caduni/People/SMS_TOKEN_VERIFY_LOADING'
export const SMS_TOKEN_VERIFY_SUCCESS = 'natura-caduni/People/SMS_TOKEN_VERIFY_SUCCESS'
export const SMS_TOKEN_VERIFY_ERROR = 'natura-caduni/People/SMS_TOKEN_VERIFY_ERROR'

export default function verifySmsTokenReducer(state, action) {
  const { payload, type, error } = action
  const params = {}

  if(type === SMS_TOKEN_VERIFY_SUCCESS) {
    const { data } = payload

    const verified = data ? data.verified : true

    Object.assign(params, { verified })
  }

  return {
    loading: type === SMS_TOKEN_VERIFY_LOADING,
    error: type === SMS_TOKEN_VERIFY_ERROR && new Error(error),
    isValid: params.verified
  }
}

export function SmsTokenValidation({ token, person, phone }) {
  const {
    dispatch,
    urls: { SMS_TOKEN_VALIDATION },
    locale: { id },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  const { sequence, number } = phone
  const { personId } = person

  return dispatch({
    types: [SMS_TOKEN_VERIFY_LOADING, SMS_TOKEN_VERIFY_SUCCESS, SMS_TOKEN_VERIFY_ERROR],
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: SMS_TOKEN_VALIDATION({ personId, sequence, token }),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId
        },
        data: { verification: { token, phoneNumber: number } }
      }
    }
  })
}
