import { isEnableDigitalStoreStepForNaturaEcuadorFlowToggleActive } from '../../../../../config'
import { ROUTES } from 'src/App.routes'
import { ROLES } from 'src/infra/Api/ECU/roles.ecuador'

export default () => ({
  countryName: 'ECUADOR',
  agreements: {
    linkPDF: null,
    linkAudio: null,
    hasEnvironmentsFiles: false
  },
  address: {
    levels: {
      COUNTRY: 0,
      STATE: 1,
      CITY: 2,
      NEIGHBORHOOD: 3,
      STREET: 4,
      NUMBER: 5,
      COMPLEMENT: 6,
      REFERENCES: 7,
      ZIPCODE: 8
    },
    hasZipCode: false
  },
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 2
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '593',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 0
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '',
      mobile: ''
    },
    placeholder: {
      line: '',
      mobile: ''
    }
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: false,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  preRegister: {
    useCreatePersonFromBFF: true
  },
  personalData: {
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: true
  },
  businessModel: {
    shouldHideButton: false
  },
  date: {
    format: 'DD/MM/YYYY'
  },
  selectPaymentPlan: true,
  shouldCallCreatePaymentPlanInPaymentView: false,
  shouldCallGetPersonInVerifyContacts: false,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: true,
  showProfileEditButton: false,
  hasLego: true,
  shouldCallCompleteAddressStepUsingBff: true,
  shouldUpdatePersonFromBff: true,
  shouldUpdateRegistrationSubstatusFromBff: true,
  shouldGetPersonDetailsFromBff: true,
  shouldGetEstablishmentsFromBff: true,
  shouldGetBusinessModelFromBff: true,
  shouldGetRejectionReasonsFromBff: true,
  shouldGetAddressFromBff: true,
  shouldIgnorePeopleContextResponseOnLogin: true,
  shouldCompleteRegisterWithBff: true,
  isHostedUIEnabled: true,
  hasDigitalStore: isEnableDigitalStoreStepForNaturaEcuadorFlowToggleActive(),
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: true,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlToReportsFV: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=ecu&company=natura`,
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=ecu&company=natura`,
      external: true,
      shouldSSO: true
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: '/ecu/auto/pre-register',
      external: false,
      shouldSSO: false
    },
    GR: {
      url: `${process.env.REACT_MFE_NATURA_FV_REPORTS_URL}/login?country=ecu&company=natura`,
      external: true,
      shouldSSO: true
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/naturaec',
      label: 'Facebook Natura'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/naturaecuador',
      label: 'Youtube Natura'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/NaturaEcuador',
      label: 'Twitter Natura'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/natura.ecu',
      label: 'Instagram Natura'
    },
    {
      type: 'pinterest',
      link: 'https://www.pinterest.com.mx/naturaecuador',
      label: 'Pinterest Natura'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
