export const BEAUTY_CONSULTANT_PROFILE = '1/1/1'
export const BIG_CONSULTANT_PROFILE = '1/1/10'
export const ENTREPRENEUR_CONSULTANT_PROFILE = '1/1/11'
export const FRANCHISE_PROFILE = '1/1/20'
export const BUSINESS_LEADER_PROFILE = '1/6/4'
export const SALES_MANAGER_PROFILE = '1/6/8'
export const BUSINESS_MANAGER_PROFILE = '1/6/17'
export const ADM_COLLABORATOR_PROFILE = '2/2/9'
export const THIRD_PARTY_PROFILE = '2/2/14'
export const SNAC_PROFILE = '2/2/15'
export const FINAL_CONSUMER_PROFILE = '2/3/16'
export const CCC_PROFILE = '3/2/9'
export const MANAGER_PROFILE = '3/2/12'
export const DIRECTOR_PROFILE = '3/2/13'

export const getBusinessModelByProfile = (profile) => {
  const splitProfile = profile.split('/')

  return {
    businessModelId: Number(splitProfile[0]),
    roleId: Number(splitProfile[1]),
    functionId: Number(splitProfile[2])
  }
}
