import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'

export const SMS_RESEND_LOADING = 'natura-caduni/People/SMS_RESEND_LOADING'
export const SMS_RESEND_SUCCESS = 'natura-caduni/People/SMS_RESEND_SUCCESS'
export const SMS_RESEND_ERROR = 'natura-caduni/People/SMS_RESEND_ERROR'

export default function smsResendReducer(state, action) {
  const { type, error } = action

  return {
    loading: type === SMS_RESEND_LOADING,
    error: type === SMS_RESEND_ERROR && new Error(error),
    resent: type === SMS_RESEND_SUCCESS
  }
}

export function SmsResend(person) {

  const {
    dispatch,
    urls: { REVALIDATE },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  return dispatch({
    types: [SMS_RESEND_LOADING, SMS_RESEND_SUCCESS, SMS_RESEND_ERROR],
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: REVALIDATE(person.personId),
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId
        },
        data: {
          steps: [{
            taskId: 4,
            subtaskId: 6,
          },
          {
            taskId: 1,
            subtaskId: 8,
          }]
        }
      }
    }
  })
}
