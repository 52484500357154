import React, { Component } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import Icon from 'src/base/Icon'
import Form from 'src/base/Form'
import RegisterErrorModal from 'src/views/Register/RegisterErrorModal'

import './Payment.styl'

import { FormattedMessage } from 'react-intl'
import { UPDATE_TYPES } from 'src/base/Form/Form.state'
import { PERSON_SAVE_ERROR } from 'src/reducers/Person/Person.save'

const bem = bemClassName.bind(null, 'payment')

const STATUS_GAVE_UP = 5
const SUBSTATUS_GAVE_UP_PERSON = 11

class Payment extends Component {
  constructor(props) {
    super(props)

    this.timeout = 0

    this.state = {
      showModalCancelRegistration: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.onCancelRegistration = this.onCancelRegistration.bind(this)
    this.onConfirmCancelRegistrationModal = this.onConfirmCancelRegistrationModal.bind(
      this
    )
  }

  componentDidMount() {
    const {
      person: { personId, get, draft },
      connectivity: { isOffline },
      step: { shouldRequestPerson }
    } = this.props

    if (shouldRequestPerson && !isOffline && !draft) {
      get(personId)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      locale: {
        configs: {
          localization: { shouldCallCreatePaymentPlanInPaymentView }
        }
      },
      person: { personId, savePaymentPlan },
      user
    } = this.props
    const shouldCallPaymentPlanWhenSelfRegister =
      shouldCallCreatePaymentPlanInPaymentView && user.isSelfRegistration

    if (
      shouldCallPaymentPlanWhenSelfRegister &&
      personId &&
      personId !== prevProps.person.personId
    ) {
      savePaymentPlan(personId)
    }
  }

  onSubmit(data) {
    const { onSubmit } = this.props

    return onSubmit(data, this.goToNextPage)
  }

  onConfirmCancelRegistrationModal() {
    return this.props.goToPage(0)
  }

  onCancelRegistration() {
    const {
      person: { save }
    } = this.props

    const params = {
      status: { status: STATUS_GAVE_UP },
      substatus: { id: SUBSTATUS_GAVE_UP_PERSON }
    }

    return save(params)
  }

  goToNextPage(person, result) {
    const hasError = result && result.type === PERSON_SAVE_ERROR

    if (hasError) return null

    clearTimeout(this.timeout)

    return this.props.goToNextPage()
  }

  get cancelAction() {
    const {
      step: {
        actions: { previous }
      },
      goToPreviousPage
    } = this.props

    return previous ? goToPreviousPage : null
  }

  render() {
    return (
      <div className={bem()}>
        {this.renderTitle()}
        {this.renderForm()}
      </div>
    )
  }

  renderTitle() {
    const {
      creditModality: { title }
    } = this.props

    return (
      <div className={bem('title')}>
        <div className={bem('title__image')}>
          <Icon name="money" className={bem('title__icon')} />
        </div>
        <h3 className={bem('title__description')}>
          <FormattedMessage {...title} />
        </h3>
      </div>
    )
  }

  renderForm() {
    const { formSchema, error } = this.props

    return (
      <Form
        className={bem('form')}
        formSchema={formSchema}
        onSubmit={this.onSubmit}
        updateRule={UPDATE_TYPES.FIELDS_ONLY}
        cancelAction={this.cancelAction}
        error={error}
        modal={RegisterErrorModal}
      />
    )
  }
}

Payment.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  step: PropTypes.object.isRequired,
  connectivity: PropTypes.object.isRequired,
  creditModality: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  formSchema: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired
}

export default Payment
