import RegisterSchema from 'src/viewModels/RegisterSchema'

import Stepper from 'src/views/Register/Components/Stepper'
import SectionTitle from 'src/views/Register/Components/SectionTitle'
import FormWithContactValidation from 'src/views/Register/Components/FormWithContactValidation'
import FormWithDigitalSpace from 'src/views/Register/Components/FormWithDigitalSpace'
import FormDefault from 'src/views/Register/Components/FormDefault'
import Payment from 'src/views/Register/Components/Payment'
import Loader from 'src/views/Register/Components/Loader'

import { buildPersonalData } from './PersonalData'
import { buildAddress } from './Address'
import { buildPayment } from './Payment'
import { buildDigitalSpace } from './DigitalSpace'


function buildSteps({ basePath }) {
  return [
    {
      name: 'personal-data',
      route: `${basePath}/personal-data/:personId?`,
      path: `${basePath}/personal-data`,
      components: [Stepper, SectionTitle, FormWithContactValidation, Loader],
      componentsPropsBuilder: buildPersonalData,
      displayOnStepper: true,
      currentStep: 0,
      actions: { next: true, previous: false }
    },
    {
      name: 'address',
      route: `${basePath}/complementary-data/:personId`,
      path: `${basePath}/complementary-data`,
      components: [Stepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildAddress,
      displayOnStepper: true,
      currentStep: 1,
      actions: { next: true, previous: true }
    },
    {
      name: 'payment',
      route: `${basePath}/payment/:personId`,
      path: `${basePath}/payment`,
      components: [Stepper, Payment, Loader],
      componentsPropsBuilder: buildPayment,
      displayOnStepper: false,
      currentStep: 2,
      actions: { next: true, previous: true }
    },
    {
      name: 'digital-space',
      route: `${basePath}/digital-space/:personId`,
      path: `${basePath}/digital-space`,
      components: [Stepper, SectionTitle, FormWithDigitalSpace, Loader],
      componentsPropsBuilder: buildDigitalSpace,
      displayOnStepper: true,
      currentStep: 3,
      paper: true,
      actions: { next: true, previous: true }
    }
  ]
}

const flows = {
  '1/1/1': ['personal-data', 'address', 'digital-space']
}

export function RegisterSchemaBuilder(deps) {
  const steps = buildSteps(deps.context).map(mapSteps, deps)

  return new RegisterSchema(steps, { flows })
}

// TODO: Get rid of this, make a better builder
// (this is how we tied up the store (person and user) to the schema)

function mapSteps(step) {
  const { step: stepProps, ...rest } = step.componentsPropsBuilder(this)

  Object.assign(step, {
    ...stepProps,
    componentsProps: { ...rest }
  })

  return step
}
