import { DOCUMENTS } from "../../../../../models/Document/Document"

export default ({ intl, messages }) => {
  return [
    {
      value: DOCUMENTS.NATIONAL_REGISTRY,
      label: intl.formatMessage(messages['type_dni'])
    },
    {
      value: DOCUMENTS.FOREIGN_REGISTRY,
      label: intl.formatMessage(messages['type_ce'])
    },
    {
      value: DOCUMENTS.PASSPORT_PERU,
      label: intl.formatMessage(messages['type_passport'])
    }
  ]
}
