import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const ERROR_STATUS = 'error'
export const SUCCESS_STATUS = 'success'
export const EXPIRED_STATUS = 'expired'
export const PROGRESS_STATUS = 'progress'

export default class Ocr extends Entity {
  static SCHEMA = {
    verified: {
      validator: adapter(yup.bool()),
      defaultValue: false
    }
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    this.start = this.start.bind(this)
    this.reset = this.reset.bind(this)
    this.saveAttachments = this.saveAttachments.bind(this)
  }

  get success() {
    return this.verified
  }

  get error() {
    return !this.verified
  }

  get status() {
    return this.success ? SUCCESS_STATUS : ERROR_STATUS
  }

  start(personId, sequence) {
    return this.startOcr(personId, sequence)
  }

  reset() {
    return this.resetOcr()
  }

  saveAttachments(person, data) {
    return this.saveOCRAttachments(person, data)
  }
}
