import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

const bem = bemClassName.bind(null, 'take-photo')

export default function CameraPreview ({ takePhoto, streamElementId }) {
  return (
    <div className={bem()}>
      <section className={bem('content')}>
        {/*TODO: How to get it work with REF instead ID?*/}
        <video id={streamElementId} autoPlay className={bem('stream')} />
        <div className={bem('gridlines')} />
        <button className={bem('camera-button')} onClick={takePhoto} />
      </section>
    </div>
  )
}

CameraPreview.propTypes = {
  takePhoto: PropTypes.func,
  streamElementId: PropTypes.string
}
