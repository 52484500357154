import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const ADDRESS_TYPE = {
  DELIVERY: { id: 1, description: 'DELIVERY ADDRESS' },
  MAILING: { id: 2, description: 'HOME ADDRESS' }
}

export default class AddressType extends Entity {
  static SCHEMA = {
    id: adapter(yup.string().required()),
    description: adapter(yup.string().required())
  }
}
