import { STATUS } from 'src/models/Status'
import { SUBSTATUS } from 'src/models/Substatus'
import { SORT, PAYMENT_PLANS, SOURCE_SYSTEM } from './FilterOptions'

export function createFiltersSchema(dependencies) {
  const { intl, messages, user, people } = dependencies
  const { isCNO, isGV, isGR, isCSC } = user
  const { queryParams: { filters } } = people

  let substatus = []
  let status = []
  let paymentPlan = []
  let sourceSystem = []
  let _sort = []

  if(!filters && isGV) {
    substatus = [{ value: SUBSTATUS.PENDING_GV_APPROVAL }]
  }

  if(!filters && isGR) {
    substatus = [{ value: SUBSTATUS.PENDING_GR_APPROVAL }]
  }

  if(!filters && isCNO) {
    status = [{ value: STATUS.PENDING }]
  }

  if(!filters && isCSC) {
    status = [
      { value: STATUS.PENDING },
      { value: STATUS.APPROVED }
    ]

    substatus = [
      { value: SUBSTATUS.PENDING_RESTART_REVIEW },
      { value: SUBSTATUS.PENDING_ADDRESS_REVIEW },
      { value: SUBSTATUS.PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED },
      { value: SUBSTATUS.PENDING_PREPAID },
      { value: SUBSTATUS.PENDING_ALLOCATE }
    ]
  }

  if(filters.status && filters.status.length) {
    status = filters.status
  }

  if(filters.substatus && filters.substatus.length) {
    substatus = filters.substatus
  }

  if(filters.paymentPlan && filters.paymentPlan.length) {
    paymentPlan = filters.paymentPlan
  }

  if(filters.sourceSystem && filters.sourceSystem.length) {
    sourceSystem = filters.sourceSystem
  }

  if(filters._sort && filters._sort.length) {
    _sort = filters._sort
  }

  const fields = [
    {
      title: intl.formatMessage(messages.filters_title_list_view),
      fields: [{
        id: '_sort',
        placeholder: intl.formatMessage(messages.filters_label_order),
        isMulti: false,
        isDefault: true,
        fallbackValue: [{ value: SORT.APPROVAL_DATE }],
        defaultValue: _sort,
        options: getSort(dependencies)
      }]
    },
    {
      title: intl.formatMessage(messages.filters_title_list_filter),
      fields: [
        {
          id: 'status',
          placeholder: intl.formatMessage(messages.filters_label_status),
          defaultValue: status,
          isMulti: true,
          options: getStatus(dependencies).sort(sort)
        },
        {
          id: 'substatus',
          placeholder: intl.formatMessage(messages.filters_label_substatus),
          defaultValue: substatus,
          hasParent: true,
          isMulti: true,
          options: getSusbstatus(dependencies).sort(sort)
        },
        {
          id: 'paymentPlan',
          placeholder: intl.formatMessage(messages.filters_label_payment_plan),
          defaultValue: paymentPlan,
          isMulti: true,
          options: getPayments(dependencies).sort(sort)
        },
        {
          id: 'sourceSystem',
          placeholder: intl.formatMessage(messages.filters_label_source_system),
          isMulti: false,
          defaultValue: sourceSystem,
          options: getSourceSystem(dependencies).sort(sort)
        }
      ]
    }
  ]

  return {
    fields,
    custom: getCustomFilters(user)
  }
}

function getCustomFilters(user) {
  return function({ sourceSystem }) {
    const { isCNO, isGR, isGV, isCSC } = user
    const source = sourceSystem && sourceSystem.length ? sourceSystem[0] : sourceSystem
    const registerByMe = source && source.value === SOURCE_SYSTEM.REGISTERED_BY_ME

    if(registerByMe) return getMyRegistersFilter(user)

    if(isCNO) return getCNOFilters(user)

    if(isGR || isGV) return getGFilters(user)

    if(isCSC) return getCSCFilters()

    return {}
  }
}

function getMyRegistersFilter({ sellerId }) {
  return {
    registrationResponsible: formatCustomFilter(sellerId),
    leaderId: [],
    salesHierarchyId: [],
    salesHierarchyLevelId: []
  }
}

function getCNOFilters(user) {
  return {
    leaderId: formatCustomFilter(user.sellerId),
    ...getGFilters(user)
  }
}

function getGFilters({ salesHierarchyLevelId, hierarchyId }) {
  return {
    salesHierarchyId: formatCustomFilter(hierarchyId),
    salesHierarchyLevelId: formatCustomFilter(salesHierarchyLevelId),
    registrationResponsible: []
  }
}

function getCSCFilters() {
  return {
    registrationResponsible: [],
    leaderId: [],
    salesHierarchyId: [],
    salesHierarchyLevelId: []
  }
}

function formatCustomFilter(value) {
  return [{ value }]
}

function getSort({ intl, messages }) {
  return [
    { value: SORT.NAME_ASC, label: 'A-Z' },
    { value: SORT.NAME_DESC, label: 'Z-A' },
    {
      value: SORT.REGISTRATION_DATE_DESC,
      label: intl.formatMessage(messages.filters_option_order_NewestToOldest)
    },
    {
      value: SORT.REGISTRATION_DATE_ASC,
      label: intl.formatMessage(messages.filters_option_order_OldestToNewest)
    },
  ]
}

function getPayments({ intl, messages }) {
  return [
    {
      value: PAYMENT_PLANS.CREDIT_PAYMENT,
      label: intl.formatMessage(messages.filters_options_payment_credit)
    },
    {
      value: PAYMENT_PLANS.PREPAID_PAYMENT,
      label: intl.formatMessage(messages.filters_options_payment_prepaid)
    }
  ]
}

function getStatus({ intl, messages }) {
  return [
    {
      value: STATUS.APPROVED,
      label: intl.formatMessage(messages.filters_option_status_approved)
    },
    {
      value: STATUS.GAVEUP,
      label: intl.formatMessage(messages.filters_option_status_gave_up)
    },
    {
      value: STATUS.PENDING,
      label: intl.formatMessage(messages.filters_option_status_pending)
    },
    {
      value: STATUS.REJECTED,
      label: intl.formatMessage(messages.filters_option_status_rejected)
    }
  ]
}

function getSourceSystem({ intl, messages }) {
  return [
    {
      value: SOURCE_SYSTEM.THIRD_PARTY_REGISTER,
      label: intl.formatMessage(messages.filters_option_source_third)
    },
    {
      value: SOURCE_SYSTEM.REGISTERED_BY_ME,
      exclude: true,
      label: intl.formatMessage(messages.filters_option_source_me)
    }
  ]
}

function getSusbstatus({ intl, messages }) {
  return [
    {
      value: SUBSTATUS.PENDING_DATA,
      label: intl.formatMessage(messages.filters_option_substatus_pending_data),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_DOCUMENTATION,
      label: intl.formatMessage(messages.filters_option_substatus_pending_documentation),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_RESTART_REVIEW,
      label: intl.formatMessage(messages.filters_option_substatus_pending_restart_review),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_ADDRESS_REVIEW,
      label: intl.formatMessage(messages.filters_option_substatus_pending_address_review),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_EMAIL_VERIFICATION,
      label: intl.formatMessage(messages.filters_option_substatus_pending_email_verification),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_GR_APPROVAL,
      label: intl.formatMessage(messages.filters_option_substatus_pending_gr_approval),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_GV_APPROVAL,
      label: intl.formatMessage(messages.filters_option_substatus_pending_gv_approval),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED,
      label: intl.formatMessage(messages.filters_option_substatus_pending_commercial_structure_not_covered),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.PROCESSING,
      label: intl.formatMessage(messages.filters_option_substatus_processing),
      parent: { 'status': STATUS.PENDING },
    },
    {
      value: SUBSTATUS.APPROVED_SELLER,
      label: intl.formatMessage(messages.filters_option_substatus_approved_seller),
      parent: { 'status': STATUS.APPROVED },
    },
    {
      value: SUBSTATUS.PENDING_PREPAID,
      label: intl.formatMessage(messages.filters_option_substatus_pending_prepaid),
      parent: { 'status': STATUS.APPROVED },
    },

    {
      value: SUBSTATUS.RESTRICTED_ADDRESS,
      label: intl.formatMessage(messages.filters_option_substatus_restricted_address),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.CREDIT_VALIDATION,
      label: intl.formatMessage(messages.filters_option_substatus_credit_validation),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.EMAIL_EXISTENT,
      label: intl.formatMessage(messages.filters_option_substatus_email_existent),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.ACTIVE_DEBT,
      label: intl.formatMessage(messages.filters_option_substatus_active_debt),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.ATTACHMENT_VALIDATION_FAIL,
      label: intl.formatMessage(messages.filters_option_substatus_attachment_validation_fail),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.REJECTED_BY_GV,
      label: intl.formatMessage(messages.filters_option_substatus_rejected_by_gv),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.REJECTED_BY_REGISTER_TEAM,
      label: intl.formatMessage(messages.filters_option_substatus_rejected_by_register_team),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.REJECTED_BY_GR,
      label: intl.formatMessage(messages.filters_option_substatus_rejected_by_gr),
      parent: { 'status': STATUS.REJECTED }
    },
    {
      value: SUBSTATUS.RENUNCIATION_NATURA,
      label: intl.formatMessage(messages.filters_option_substatus_renunciation_natura),
      parent: { 'status': STATUS.GAVEUP }
    },
    {
      value: SUBSTATUS.RENUNCIATION_CANDIDATE,
      label: intl.formatMessage(messages.filters_option_substatus_renunciation_candidate),
      parent: { 'status': STATUS.GAVEUP }
    }
  ]
}

function sort(a, b) {
  return a.label.localeCompare(b.label)
}
