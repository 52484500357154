import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { classNameBuilder } from 'src/base/Form/Form'

import Input from 'src/base/Form/Components/Input'
import InputWithCheckbox from 'src/base/Form/Components/InputWithCheckbox'
import Select from 'src/base/Form/Components/Select'
import File from 'src/base/Form/Components/Attachments/File'
import FileUpload from 'src/base/Form/Components/Attachments/FileUpload'
import Ocr from 'src/base/Form/Components/Attachments/Ocr'
import UserMedia from 'src/base/Form/Components/Attachments/UserMedia'
import Checkbox from 'src/base/Form/Components/Checkbox'
import CheckboxModal from 'src/base/Form/Components/CheckboxModal'
import Maps from 'src/base/Form/Components/Maps'
import MapsV2 from 'src/base/Form/Components/MapsV2'
import IndicationDefault from 'src/base/Form/Components/IndicationDefault'
import Indication from 'src/base/Form/Components/Indication'
import Textarea from 'src/base/Form/Components/Textarea'
import DigitalSpace from 'src/base/Form/Components/DigitalSpace'

const TAB = 9
const ENTER = 13

function findComponentByType(type) {
  if (typeof type === 'function' || type.displayName) return type
  return (
    findComponentByType.fieldTypes[type] || findComponentByType.fieldTypes.input
  )
}

findComponentByType.fieldTypes = {
  input: Input,
  inputWithCheckbox: InputWithCheckbox,
  select: Select,
  file: File,
  fileUpload: FileUpload,
  ocr: Ocr,
  checkbox: Checkbox,
  checkboxModal: CheckboxModal,
  maps: Maps,
  mapsV2: MapsV2,
  indicationDefault: IndicationDefault,
  indication: Indication,
  textarea: Textarea,
  userMedia: UserMedia,
  digitalSpace: DigitalSpace,
}

export class FormField extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  onChange(value, label) {
    const { schema, groupSchema, form } = this.props
    const { onChange, id, groupId } = schema
    const { setFieldValue } = form

    if (groupSchema) {
      setFieldValue(groupId, value, groupSchema.id)
    } else {
      setFieldValue(id, value)
    }

    if (onChange) {
      onChange({ ...form, value, schema, groupSchema, label })
    }
  }

  onBlur(value) {
    const { schema, groupSchema, form } = this.props
    const { onBlur, id, groupId } = schema
    const { setFieldTouched, runFieldValidation } = form

    if (groupSchema) {
      setFieldTouched(groupId)
      runFieldValidation(groupId, groupSchema.id)
    } else {
      setFieldTouched(id)
      runFieldValidation(id)
    }

    if (onBlur) {
      onBlur({ ...form, value, schema, groupSchema })
    }
  }

  onKeyDown(event) {
    const { schema, form } = this.props
    const { onKeyDown, id, groupId } = schema
    const { setFieldTouched } = form

    const pressedTab = event.keyCode === TAB
    const pressedEnter = event.keyCode === ENTER

    pressedTab && setFieldTouched(groupId || id)
    pressedEnter && event.preventDefault()

    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  get id() {
    const { id, groupId } = this.props.schema

    return groupId || id
  }

  get label() {
    const {
      schema: { offlineLabel, label },
      offline
    } = this.props

    return offline && offlineLabel ? offlineLabel : label
  }

  get success() {
    const { schema, error, value, touched } = this.props

    return schema.success && !error && !!value && !!touched
  }

  get error() {
    const { error, touched } = this.props

    return !!touched && error
  }

  get component() {
    const {
      schema: { type, offlineType },
      offline
    } = this.props

    if (offline && offlineType) {
      return findComponentByType(offlineType)
    }

    return findComponentByType(type)
  }

  render() {
    const { className, schema, value, form, touched } = this.props
    const { id, component: Component } = this
    const { node, type, mask, placeholder, customProps } = schema
    const classNames = classNameBuilder(className, 'field', [schema.id])

    if (node) return this.renderCustomNode({ classNames })

    const maskConfig = typeof mask === 'function' ? mask(form, id) : mask

    const placeholderConfig =
      typeof placeholder === 'function' ? placeholder(form, id) : placeholder

    const customClassName =
      customProps && customProps.className ? customProps.className : ''

    return (
      <Component
        {...schema}
        form={form}
        className={classNames}
        type={type}
        id={id}
        label={this.label}
        name={id}
        data-testid={id}
        value={value}
        error={this.error}
        success={this.success}
        mask={maskConfig}
        placeholder={placeholderConfig}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange}
        onBlur={this.onBlur}
        customClassName={customClassName}
        touched={touched}
      />
    )
  }

  renderCustomNode({ classNames }) {
    const { schema, form } = this.props

    return (
      <div className={classNames}>
        {React.cloneElement(schema.node, { id: this.id, form })}
      </div>
    )
  }
}

FormField.propTypes = {
  className: PropTypes.string,
  schema: PropTypes.object.isRequired,
  offline: PropTypes.bool,
  groupSchema: PropTypes.object,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object
  ]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  form: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    toucheds: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    runFieldValidation: PropTypes.func,
    addItemToGroup: PropTypes.func,
    removeItemFromGroup: PropTypes.func
  })
}

export default FormField
