import { parseDateToApi } from 'src/lib/SpeckAdapter/Yup.customs'
import { normalizePhones } from 'src/models/Phone'
import { DOCUMENTS } from 'src/models/Document'

import { reduceEmails } from 'src/configs/Locales/DEFAULT/views/Register/PersonalData/Submit'

export function onSubmition(deps) {
  const { person, locale: { configs } } = deps

  return function(data, callback) {
    const parsedData = parseData({ data, person, configs })

    return person.save(parsedData, callback)
  }
}

function parseData({ data, person, configs }) {
  const { policiesAcceptedGroup, birthday, firstName,lastName, gender, nationalityId } = data

  const ruc = { type: DOCUMENTS.RUC, document: data.ruc }
  const documents =  data.ruc ? [ ...data.documents, ruc ] : data.documents
  const emails = data.emails && data.emails.reduce(reduceEmails, person.emails)
  const phones = data.phones.map(normalizePhones, { configs, phones: person.phones })
  const policiesAccepted = policiesAcceptedGroup && person.policiesAccepted.filterAccepteds(policiesAcceptedGroup)
  const additionalInformation = mapAdditionalInformation(person, data, configs)

  return {
    birthday,
    firstName,
    lastName,
    nationalityId,
    phones,
    gender,
    emails,
    policiesAccepted,
    name: `${firstName.trim()} ${lastName.trim()}`,
    documents,
    additionalInformation
  }
}

function mapAdditionalInformation({ additionalInformation = {} }, data, configs) {
  const { localization: { date: { format } } } = configs
  const { establishmentId, admissionDate } = data
  const newAdditionalInformation = {}

  establishmentId && Object.assign(newAdditionalInformation, { establishmentId })

  admissionDate && Object.assign(newAdditionalInformation, {
    admissionDate: parseDateToApi(admissionDate, format)
  })

  return { ...additionalInformation, ...newAdditionalInformation }
}
