import Icon from '../../../../../base/Icon'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import React from 'react'
import bemClassName from 'bem-classname'
import PropTypes from 'prop-types'
import './Success.styl'
import StrongDarkText from '../../../../../base/StrongDarkText/StrongDarkText'
import Button, { TYPES } from 'src/base/Button'
import { ROUTES } from '../../../../../App.routes'
import TagManager from 'react-gtm-module'
import { shouldShowDigitalStoreSection } from './strategies/should-show-digital-store-section'

const bem = bemClassName.bind(null, 'completed-status')

const Success = (props) => {
  const {
    hasIndicationStep,
    indicatorName,
    messages,
    personDetails,
    paymentPlan,
    appStoreUrls,
    intl,
    digitalStoreStatus,
    country
  } = props

  const PaymentPlan = () => {
    let message

    if (paymentPlan.isPrePaid) {
      message = messages['prepaid_payment']
    }

    if (paymentPlan.isCredit) {
      message = messages['credit_payment']
    }

    if (paymentPlan.isDenied) {
      message = messages['denied_payment']
    }

    return message ? <FormattedMessage {...message} /> : '-'
  }

  const LeaderSection = () => {

    return <div className={bem('business_leader')}>
      <div className={bem('header_container')}>
        <div className={bem('header_marker')} />
        <div className={bem('header')}>
          <FormattedMessage {...messages['business_leader_title']} />
        </div>
      </div>
      <div className={`${bem('inner_content')}`}>
        <p>
          <FormattedHTMLMessage {...messages['business_leader_content']} />
        </p>
      </div>
    </div>
  }

  const AppAdds = () => {
    return <div className={bem('apps_ads')}>
      <div className={`${bem('apps_ads_inner_content')}`}>
        <div>
          <img
            src={require('src/assets/images/icon_consultoria.png')}
            alt=""
          />
          <h3>
            <FormattedMessage {...messages['download_app']} />
          </h3>
        </div>
        <div>
          <FormattedMessage {...messages['google_play_banner']}>
            {(message) => (
              <a
                href={appStoreUrls.android}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require(`src/assets/images/apps_banner/${message}`)}
                  alt=""
                />
              </a>
            )}
          </FormattedMessage>

          <FormattedMessage {...messages['app_store_banner']}>
            {(message) => (
              <a
                href={appStoreUrls.iOS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require(`src/assets/images/apps_banner/${message}`)}
                  alt=""
                />
              </a>
            )}
          </FormattedMessage>
        </div>
      </div>
    </div>
  }

  const NextSteps = () => {
    return <div className={bem('next_steps_container')}>
      <div className={bem('header_container')}>
        <div className={bem('header_marker')} />
        <div className={bem('header')}>
          <FormattedMessage {...messages['next_steps']} />
        </div>
      </div>
      <div className={`${bem('next_steps')} ${bem('inner_content')}`}>
        <ol>
          <li>
            <div>
              <FormattedHTMLMessage {...messages['step_1']} />
            </div>
          </li>
          <li>
            <div>
              <FormattedHTMLMessage {...messages['step_2']} />
            </div>
          </li>
          <li>
            <div>
              <FormattedHTMLMessage {...messages['step_3']} />
            </div>
          </li>
        </ol>
      </div>
    </div>
  }

  const IndicatorNameSection = () => {
    const { indicatorName } = props
    return (
      <div className={bem('indicator_name_section')}>
        <div>
          <FormattedHTMLMessage {...messages['indication_name_label']} />
        </div>
        <div className={bem('indicator_name')}>{indicatorName}</div>
      </div>
    )
  }

  const setDataLayer = (buttonId, buttonText) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }
  const IndicatorSection = () => {
    const { country, history, personId } = props

    return <div className={bem('indicator_section')}>
      <p><FormattedMessage {...messages.indicator_label} /></p>
      <Button
        className={`${bem('indicator_action')}`}
        onClick={() => {
          setDataLayer('indication-button', intl.formatMessage(messages.indicator_action))
          return history.push(`/${country.toLowerCase()}${ROUTES.SELF_REGISTER}/indication/${personId}`)
        }}
        type={TYPES.PRIMARY}
      >
        <FormattedMessage {...messages.indicator_action} />
      </Button>
    </div>
  }

  const DigitalStoreSection = () => {
    if (!shouldShowDigitalStoreSection(country)) return null
    if (!digitalStoreStatus?.digitalStoreName) return null

    return <div className={bem('digital_store')}>
      <div className={bem('header_container')}>
        <div className={bem('header_marker')} />
        <div className={bem('header')}>
          <FormattedMessage {...messages['digital_store_title']} />
        </div>
      </div>
      <div className={`${bem('inner_content')}`}>
        <p>
          <FormattedHTMLMessage {...messages['digital_store_content']} values={{
            digitalStoreName: digitalStoreStatus.digitalStoreName
          }} />
        </p>
      </div>
      <div className={bem('header_container')}>
        <div className={bem('header_marker')} />
        <div className={bem('header')}>
          <FormattedMessage {...messages['digital_store_warning_title']} />
        </div>
      </div>
      <div className={`${bem('inner_content')}`}>
        <p>
          <FormattedHTMLMessage {...messages['digital_store_warning_content']} />
        </p>
      </div>
    </div>
  }

  return (
    <>
      <div className={bem('container')}>
        <div className={bem('info')}>
          <Icon name="approved_icon_thin" className={bem('approved_icon')} />
          <h4>
            <FormattedMessage {...messages['approved_title']} />
          </h4>
          <h5 className={bem('welcome_message')}>
            <span>
              <StrongDarkText>
                <FormattedMessage {...messages['welcome_greeting']} />
              </StrongDarkText>
              <br />
              <FormattedMessage {...messages['welcome_message']} />
            </span>
          </h5>
          <div className={bem('consultant_code_container')}>
            <p>
              <FormattedMessage {...messages['your_consultant_code']} />
            </p>
            <p className={bem('consultant_code_number')}>
              {personDetails.naturaCode}
            </p>
            <p>
              <span className={bem('payment_method')}>
                <FormattedMessage {...messages['payment_condition']} />
                :&nbsp;
                <StrongDarkText>
                  <PaymentPlan />
                </StrongDarkText>
              </span>
            </p>
            {indicatorName && <IndicatorNameSection />}
          </div>
        </div>
        <DigitalStoreSection />
        <NextSteps />
      </div>
      <LeaderSection />
      {(hasIndicationStep && !indicatorName) && <IndicatorSection />}
      <AppAdds />
    </>
  )
}

Success.propTypes = {
  match: PropTypes.object,
  country: PropTypes.string,
  hasIndicationStep: PropTypes.bool,
  history: PropTypes.object,
  indicatorName: PropTypes.string,
  messages: PropTypes.object.isRequired,
  personId: PropTypes.string.isRequired,
  personDetails: PropTypes.object.isRequired,
  paymentPlan: PropTypes.object.isRequired,
  appStoreUrls: PropTypes.object,
  intl: PropTypes.object,
  digitalStoreStatus: PropTypes.object,
  isLoadingDigitalStoreStatus: PropTypes.bool
}

export default Success
