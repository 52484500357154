import Language from 'src/viewModels/Language'

const CHANGE_LANGUAGE = 'natura-caduni/Language/CHANGE_LANGUAGE'
const REHYDRATE = 'persist/REHYDRATE'

const initialState = {
  language: new Language()
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  if((type === REHYDRATE || type === CHANGE_LANGUAGE) && payload) {
    const { language: { language } = {} } = payload || {}

    return {
      ...state,
      language: new Language(language)
    }
  }

  return state
}

export function ChangeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    payload: {
      language: { language: { language } }
    }
  }
}
