import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class StatusValidation extends Entity {
  static SCHEMA = {
    verified: adapter(yup.bool()),
    wasSent: adapter(yup.bool())
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    this.resend = this.resend.bind(this)
    this.verify = this.verify.bind(this)
    this.reset = this.reset.bind(this)
  }

  resend(data) {
    return this.resendEmail(data)
  }

  verify(data) {
    return this.verifyEmail(data)
  }

  reset() {
    return this.resetEmail()
  }

  shouldVerifyEmail(data) {
    const { emailId, personId, signature, confirmationCode, countryId } = data
    const id = emailId || personId
    const code = signature || confirmationCode

    return !!(id && countryId && code)
  }
}
