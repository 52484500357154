import * as yup from 'yup'
import { store } from 'src/App.state'
import NumberAddress from './Number'
import Street from './Street'
import Complement from './Complement'
import References from './References'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import { ADDRESS_USE, DELIVERY_ADDRESS, MAILING_ADDRESS } from 'src/models/Address/AddressUse'

import { getAddressNumberValidation } from './strategies/get-address-number-validation'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    connectivity,
    locale: { id: localeId }
  } = injection

  const baseValue = {
    copyAdress: true,
    countryName: 'PERU'
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)

  const STREET = { ...Street(intl, { ...injection, showAsterisk: false }) }
  const NUMBER_ADDRESS = { ...NumberAddress(intl, { ...injection, showAsterisk: false }) }
  const COMPLEMENT = { ...Complement(intl, injection) }
  const REFERENCES = { ...References(intl, { ...injection, showAsterisk: false }) }

  return {
    id: 'address',
    min: 1,
    max: 20,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [STREET, NUMBER_ADDRESS],
      [COMPLEMENT],
      [REFERENCES],
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          street: yup.string().max(100).required(),
          number: getAddressNumberValidation(localeId),
          complement: yup.string().max(100),
          references: yup.string().max(100).required()
        })
      )
      .min(1)
      .max(20)
  }
}

function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)
    const onlyDeliveryAddress = addresses.filter(address => {
      return address.addressUse.find(use => use.id === ADDRESS_USE.DELIVERY)
    })

    return connectivity.isOffline
      ? onlyDeliveryAddress.reduce(reduceAddressOffline, [baseValue])
      : onlyDeliveryAddress.reduce(reduceAddress, [baseValue])
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress = deliveryAddress && !mailingAddress.isUseToDelivery

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

function reduceAddress(accum, address, index, array) {
  const {
    locale: { locale: { configs: { localization: { address: { levels } } } } },
    person: { person }
  } = store.getState()
  const [{ countryName }] = accum

  const addressStreet = person.addresses[0].geographicalLevels.find(({ level }) => level === levels.STREET)

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    addressNumber,
    addressComplement,
    references,
    addressUse,
  } = address

  const addressNumberDescription = addressNumber && addressNumber.description
  const addressComplementDescription =
    addressComplement && addressComplement.description
  const referencesDescription = references && references.description
  const addressStreetDescription = addressStreet && addressStreet.description

  const newAddress = {
    street: addressStreetDescription,
    number: addressNumberDescription,
    complement: addressComplementDescription,
    references: referencesDescription,
    addressUse,
    countryName,
  }

  return [...accum, newAddress]
}

function reduceAddressOffline(accum, address, index, array) {

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    addressNumber,
    addressComplement,
    references
  } = address

  const newAddress = {
    number: addressNumber,
    complement: addressComplement,
    references
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  return value.toString() === this.code
}
