import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'
import Button, { TYPES } from 'src/base/Button'

import { APP_ROUTES } from 'src/App'

import './Completed.styl'

const bem = bemClassName.bind(null, 'completed-status')

class Completed extends PureComponent {
  constructor(props) {
    super(props)

    this.showRegister = this.showRegister.bind(this)
  }

  componentDidMount() {
    const { person, connectivity: { isOffline } } = this.props

    if (person.draft)
      this.removePersonLocal()

    if (isOffline) return

    return person.complete()
  }

  showRegister() {
    const { person: { resetErrors, personId }, history } = this.props

    resetErrors()

    return history.push(APP_ROUTES.PROFILE.replace(':personId', personId))
  }

  render() {
    const { statusInfo, messages, person: { personId } } = this.props
    const { icon, description, phoneNumber, actions, label, loading } = statusInfo

    return (
      <div className={bem('info')}>
        <Icon className={bem('icon')} name={icon} />
        <p className={bem('description')}>
          <FormattedMessage {...messages[description]} />
        </p>
        {phoneNumber &&
          <p className={bem('description')}>
            <FormattedMessage {...messages[phoneNumber]} />
          </p>
        }
        {actions && !personId &&
          <h2 className={bem('loading')}>
            <FormattedMessage {...messages[loading]} />
          </h2>
        }
        {actions && personId &&
          <Button {...actions} type={TYPES.PRIMARY}
            data-testid="button-show-register"
            onClick={this.showRegister}>
            <FormattedMessage {...messages[label]} />
          </Button>
        }
      </div>
    )
  }
}

Completed.propTypes = {
  loading: PropTypes.bool,
  personPayment: PropTypes.object,
  locale: PropTypes.object,
  peopleRegister: PropTypes.object,
  connectivity: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  person: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  statusInfo: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default Completed
