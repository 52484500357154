import BRA from 'src/infra/Api/BRA'
import wrapAsync from 'src/infra/Api/wrapAsync'

function getSellerById ({ accessToken, sellerId }) {
  const url = BRA.urls.SELLER(sellerId)
  return BRA.client.get(url, { headers: { access_token: accessToken } })
}

export default {
  getSellerById: wrapAsync(getSellerById),
}
