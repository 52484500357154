import {
  BEAUTY_CONSULTANT_PROFILE,
  BIG_CONSULTANT_PROFILE,
  BUSINESS_LEADER_PROFILE,
  BUSINESS_MANAGER_PROFILE,
  ADM_COLLABORATOR_PROFILE,
  ENTREPRENEUR_CONSULTANT_PROFILE,
  FINAL_CONSUMER_PROFILE,
  FRANCHISE_PROFILE,
  SALES_MANAGER_PROFILE,
  SNAC_PROFILE,
  THIRD_PARTY_PROFILE, CCC_PROFILE, MANAGER_PROFILE, DIRECTOR_PROFILE
} from '../../../../NaturaProfiles'


import { isEnableDigitalStoreStepForColombiaFlowToggleActive } from '../../../../../../../config'

export const personalStepByProfile = {
  [BEAUTY_CONSULTANT_PROFILE]: 'personal-data',
  [BIG_CONSULTANT_PROFILE]: 'big-consultant-personal-data',
  [ENTREPRENEUR_CONSULTANT_PROFILE]: 'entrepreneur-consultant-personal-data',
  [FRANCHISE_PROFILE]: 'franchise-personal-data',
  [BUSINESS_LEADER_PROFILE]: 'business-leader-personal-data',
  [SALES_MANAGER_PROFILE]: 'sales-manager-personal-data',
  [BUSINESS_MANAGER_PROFILE]: 'business-manager-personal-data',
  [ADM_COLLABORATOR_PROFILE]: 'collaborator-personal-data',
  [THIRD_PARTY_PROFILE]: 'third-party-personal-data',
  [SNAC_PROFILE]: 'snac-personal-data',
  [FINAL_CONSUMER_PROFILE]: 'final-consumer-personal-data',
  [CCC_PROFILE]: 'ccc-personal-data',
  [MANAGER_PROFILE]: 'manager-personal-data',
  [DIRECTOR_PROFILE]: 'director-personal-data',
}

export const buildFlows = () => {
  const useDigitalStore = isEnableDigitalStoreStepForColombiaFlowToggleActive() ? 'digital-store' : null
  
  const flows = [
    'pre-register',
    'personal-data',
    'authorization-code',
    'address',
    'documentation',
    'documents',
    'indication',
    useDigitalStore,
    'completed'
  ]


  return {
    [BEAUTY_CONSULTANT_PROFILE]: flows.filter(step => step !== null)
  }
}
