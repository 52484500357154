import FormSchema from 'src/base/Form/Schemas'
import { getFieldsSchema } from '../Address/strategies/getFieldsSchema'

export function buildFormSchema(dependencies) {
  const {
    intl,
    locale: { id: localeId }
  } = dependencies

  const defaultFieldsSchema = []
  const additionalFieldsSchema = getFieldsSchema(localeId, {
    intl,
    dependencies
  })
  const fieldsSchema = [...defaultFieldsSchema, ...additionalFieldsSchema]

  return new FormSchema({ fieldsSchema, name: 'Address' })
}
