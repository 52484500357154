import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  email: {
    defaultMessage: 'E-mail',
    id: 'form.field.email'
  },
  email_confirm: {
    defaultMessage: 'Confirm e-mail',
    id: 'form.field.email.confirm'
  },
  email_confirm_error: {
    defaultMessage: 'E-mails must match',
    id: 'form.field.email.confirm.error'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { emails } } = { ...injection }

  const errorMessage = intl.formatMessage(messages['email_confirm_error'])

  const EMAIL = {
    id: 'email',
    label: intl.formatMessage(messages['email']),
    onChange: handleEmailChange
  }

  const EMAIL_CONFIRM = {
    id: 'email_confirm',
    disabledBy: ['emails[].email'],
    label: intl.formatMessage(messages['email_confirm']),
    success: true,
    required: true,
    onPaste: (e) => e.preventDefault()
  }

  const [email] = emails.map(mapEmails)
  const baseValue = [mapEmails({ email: '' })]

  return {
    id: 'emails',
    min: 1,
    max: 1,
    baseValue,
    defaultValue: [email],
    fields: [
      [EMAIL],
      [EMAIL_CONFIRM]
    ],
    validations: yup.array().of(yup.object().shape({
      email: yup.string().lowercase().email(),
      email_confirm: yup.string().lowercase().equalTo(yup.ref('email'), errorMessage)
    }))
  }
}

function handleEmailChange(props) {
  const { value, setFieldValue, runFieldValidation, schema } = props
  const { groupId, group } = schema

  if (!value) {
    setFieldValue(`${groupId}_confirm`, '', group.id)
  }

  runFieldValidation(null, 'emails')
  runFieldValidation(null, 'phones')
}

function mapEmails({ email }) {
  return {
    email,
    email_confirm: email
  }
}
