import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { isEqual } from 'lodash'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import MapsDisplay from './Maps.display'
import MapsInput from './Maps.input'
import Button, { TYPES } from 'src/base/Button'
import Modal from './Maps.modal'

import { messages } from './Maps.messages'
import './Maps.styl'

const bem = bemClassName.bind(null, 'maps')

const addressesOptionsEnum = {
  region: 'regions',
  department: 'departments',
  province: 'provinces',
  district: 'districts',
  city: 'cities',
  neighborhood: 'neighborhoods'
}

export class Maps extends PureComponent {
  constructor(props) {
    super(props)

    const { locale: { configs: { localization: { map: { center } }}} } = this.props

    this.state = {
      mapLoaded: false,
      address: {
        location: center,
        street: '',
      },
      modal: {
        open: false,
        error: false
      }
    }

    this.onChange = this.onChange.bind(this)
    this.onMapLoad = this.onMapLoad.bind(this)
    this.handleGeoCode = this.handleGeoCode.bind(this)
    this.handleGeoCodeModal = this.handleGeoCodeModal.bind(this)
    this.handleAdjustPIN = this.handleAdjustPIN.bind(this)
    this.handleConfirmAddress = this.handleConfirmAddress.bind(this)
  }

  componentDidUpdate({ value }) {
    const { id, group, hidden, value: defaultValue = {}, form: { setFieldValue } } = this.props
    const { location, street } = defaultValue
    const { address } = this.state

    const isHidden = hidden && address.street
    const hasAddress = address.location && address.street
    const hasNoValues = !!value && (!defaultValue || !Object.keys(defaultValue).length)
    const shouldClearAddress = !isEqual(defaultValue, value) && hasNoValues
    const shouldUpdateAddress = location && street && !hidden && !hasAddress

    if(shouldUpdateAddress) {
      this.setState({
        address: { ...address, location, street },
        hiddenActions: true
      })

      setFieldValue(id, defaultValue, group.id)
    }

    if(isHidden || shouldClearAddress) {
      this.setState({
        address: { ...address, street: '' }
      })
    }
  }

  onChange(params) {
    const { onChange } = this.props
    const { address } = this.state
    const { hasError } = params

    onChange && onChange(params)

    this.setState({
      address: { ...address, ...params },
      modal: {
        open: !!hasError,
        error: hasError
      },
      hiddenActions: !params.location
    })
  }

  onMapLoad(map) {
    if (!map) return null

    this.setState({ mapLoaded: true })
  }

  handleGeoCode(location) {
    const { address } = this.state
    this.setState({ address: { ...address, location } })
  }

  handleGeoCodeModal(location) {
    this.handleGeoCode(location)
    this.setState({ modal: { open: false, error: false } })
  }

  handleAdjustPIN() {
    this.setState({
      modal: {
        open: true,
        error: false
      }
    })
  }

  handleConfirmAddress() {
    const { address: { street, location } } = this.state
    const { form, id, group, onChange } = this.props
    const { setFieldValue, setFieldTouched } = form
    const confirmed = true

    if (onChange) {
      onChange({ street, location, confirmed })
    }

    setFieldTouched(id, group.id)
    setFieldValue(id, { street, location, confirmed }, group.id)

    this.setState({ hiddenActions: true })
  }

  get region() {
    const { form, group: { index }, locale, addresses, addressesPeopleManagement } = this.props
    const { configs: { localization: { address: { gmap: { fields, isLegacy } } } }, countryName } = locale
    const { values: { address = [] } } = form

    const values = address[index] || {}

    if (isLegacy) {
      return fields.reduce(reduceRegion, [{ values, addresses: addressesPeopleManagement, countryName }]).join(', ')
    }

    return fields.reduce(reduceRegion, [{ values, addresses, countryName }]).join(', ')
  }

  get shouldShowMap() {
    const { address: { location, street } } = this.state

    return street && location && !!location.lat && !!location.lng
  }

  render() {
    const { disabled, error, hidden } = this.props

    return (
      <div className={bem({ disabled, hidden })} data-error={!!error}>
        {this.renderModal()}
        {this.renderInput()}
        {this.renderDescription()}
        {this.renderDisplay()}
        {this.renderActions()}
      </div>
    )
  }

  renderInput() {
    const {
      id,
      group,
      locale,
      label,
      message,
      required,
      disabled,
      hidden,
      error,
      success,
      form,
      mask,
      htmlProps
    } = this.props

    const { mapLoaded, address: { street } } = this.state

    if(!mapLoaded) return null

    return (
      <MapsInput
        id={id}
        group={group}
        label={label}
        form={form}
        message={message}
        error={error}
        required={required}
        hidden={hidden}
        value={street}
        disabled={disabled}
        success={success}
        mask={mask}
        htmlProps={htmlProps}
        region={this.region}
        locale={locale}
        onChange={this.onChange}
      />
    )
  }

  renderDescription() {
    const { intl } = this.props
    const { address: { street }, hiddenActions } = this.state

    if(!street || hiddenActions) return null

    return (
      <div>
        <p className={bem('description')}>
          {intl.formatMessage(messages['maps_description'])}
        </p>
      </div>
    )
  }

  renderDisplay() {
    const { GMKey, locale } = this.props
    const { address: { location } } = this.state

    return (
      <MapsDisplay
        hidden={!this.shouldShowMap}
        center={location}
        GMKey={GMKey}
        locale={locale}
        fixedAtCenter={false}
        onMapLoad={this.onMapLoad}
        handleGeoCode={this.handleGeoCode}
      />
    )
  }

  renderActions() {
    const { hidden, intl } = this.props
    const { address: { street }, hiddenActions } = this.state

    if(!street || hiddenActions || hidden) return null

    return (
      <div className={bem('map_actions')} >
        <h3 className={bem('map_actions', ['description'])}>
          {intl.formatMessage(messages['maps_description_actions'])}
        </h3>
        <div className={bem('map_actions', ['buttons'])}>
          <Button
            data-testid="map-button-not"
            type={TYPES.PRIMARY}
            className={bem('map_actions', ['not'])}
            onClick={this.handleAdjustPIN}>
            {intl.formatMessage(messages['maps_button_pin'])}
          </Button>
          <Button
            data-testid="map-button-yes"
            type={TYPES.PRIMARY}
            onClick={this.handleConfirmAddress}
            className={bem('map_actions', ['yes'])}>
            {intl.formatMessage(messages['maps_button_confirm'])}
          </Button>
        </div>
      </div>
    )
  }

  renderModal() {
    const { GMKey, locale } = this.props
    const { address: { location }, modal: { open, error }} = this.state

    return (
      <Modal
        GMKey={GMKey}
        locale={locale}
        location={location}
        showModal={open}
        error={error}
        fixedAtCenter={true}
        onMapLoad={this.onMapLoad}
        handleConfirmAddress={this.handleGeoCodeModal}
      />
    )
  }
}

Maps.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  customProps: PropTypes.object,
  addressesPeopleManagement: PropTypes.object,
  addresses: PropTypes.object,
  htmlProps: PropTypes.object,
  success: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hidden: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  intl: PropTypes.object,
  locale: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  group: PropTypes.object,
  GMKey: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  mask: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  label: PropTypes.string,
  message: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

Maps.defaultProps = {
  GMKey: process.env.GOOGLE_MAPS_API_KEY
}

function mapStateToProps(state, ownProps) {
  const { locale: { locale }, addresses: { addresses, addressesPeopleManagement } } = state

  return {
    ...ownProps,
    addresses,
    addressesPeopleManagement,
    locale
  }
}

function reduceRegion(accum, field, index, array) {
  const [dependencies] = accum

  const lastItem = index === array.length -1
  const address = getAddress(field, dependencies)

  if (lastItem) accum.shift()

  if (!address) return accum

  return [ ...accum, address.label ]
}

function getAddress(field, dependencies) {
  const { values, addresses } = dependencies

  const fieldValue = values[field.label]
  const parentValue = values[field.parent]
  const addressOptions = addressesOptionsEnum[field.label]

  if (!fieldValue || !addressOptions) return

  const parentOptions = addresses[addressOptions][parentValue]

  if (!parentOptions) return

  return parentOptions.find(findAddress, { value: fieldValue })
}

function findAddress({ value }) {
  return value === this.value
}

export default injectIntl(connect(mapStateToProps)(Maps))
