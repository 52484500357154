import { digitalSpaceCheckReducer, checkDigitalSpace, types as digitalSpaceCheckTypes } from './DigitalSpace.check'
import { digitalSpaceSaveReducer, saveDigitalSpace, types as digitalSpaceSaveTypes } from './DigitalSpace.save'
import { digitalSpaceGetReducer, getDigitalSpace, types as digitalSpaceGetTypes } from './DigitalSpace.get'
import { digitalSpaceStatusReducer, statusDigitalSpace, types as digitalSpaceStatusTypes } from './DigitalSpace.status'

import DigitalSpace from 'src/viewModels/DigitalSpace'

const dependences = {
  checkDigitalSpace,
  saveDigitalSpace,
  getDigitalSpace,
  statusDigitalSpace
}

const initialState = {
  digitalSpace: new DigitalSpace({}, dependences),
  loading: false,
  error: false
}

export default function reducer(state = initialState, action = {}) {
  const { type } = action

  if (digitalSpaceCheckTypes.includes(type))
    return digitalSpaceCheckReducer(state, action, dependences)

  if(digitalSpaceSaveTypes.includes(type))
    return digitalSpaceSaveReducer(state, action, dependences)

  if(digitalSpaceGetTypes.includes(type))
    return digitalSpaceGetReducer(state, action, dependences)

  if(digitalSpaceStatusTypes.includes(type))
    return digitalSpaceStatusReducer(state, action, dependences)

  return state
}

