import { getContext } from 'src/infra/Api/Api.context'
import User from 'src/models/User'

export const OFFLINE = 'natura-caduni/User/OFFLINE'

export const types = [ OFFLINE ]

export function userOfflineAuthReducer(state, action, dependencies) {
  const {  type, payload } = action

  if(type === OFFLINE) {
    return {
      ...state,
      loading: false,
      error: false,
      user: new User(payload, dependencies),
    }
  }

  return state
}

export function offlineAuthenticate() {
  const { dispatch } = getContext()

  return dispatch({
    type: OFFLINE,
    payload: {
      name: 'USER_OFFLINE',
      sellerId: 'OFFLINE',
      roles: {
        CSC: true
      },
      authentication: {
        accessToken: 'ACCESS_TOKEN_OFFLINE',
        expiresIn: 12000
      },
    }
  })
}
