import { SUBSTATUS } from 'src/models/Substatus/Substatus'
import { EMAIL_ALREADY_EXISTS_CODE } from 'src/views/Register/RegisterErrorEnum'

export const getColombiaSubstatus = (error) => {
  if (!error) {
    return SUBSTATUS.WITHOUT_ADDRESS
  } else if (error?.response?.data?.code === EMAIL_ALREADY_EXISTS_CODE) {
    return SUBSTATUS.EMAIL_EXISTENT
  }
}
