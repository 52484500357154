import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import { BUSINESS_MODEL } from 'src/viewModels/BusinessModel'

import Select from 'src/base/Form/Components/Select'

const messages = defineMessages({
  business_model: {
    defaultMessage: 'Business Model',
    id: 'form.field.business.model'
  },
  business_model_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.business.model.placeholder'
  },
  business_model_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.business.model.no.options.message'
  },
  business_model_direct_sale_option: {
    defaultMessage: 'Direct Sale',
    id: 'form.field.business.direct.sale.option'
  }
})

const dependencies = {
  messages
}

const translateDependencies = {}


export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  Object.assign(translateDependencies, { intl })

  return {
    id: 'businessModelId',
    label: intl.formatMessage(messages['business_model']),
    placeholder: intl.formatMessage(messages['business_model_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['business_model_no_options_message']),
    type: connect(mapStateToProps)(Select),
    required: true,
    onChange
  }
}

function onChange({ setFieldValue, setFieldTouched, schema }) {
  const { parent, group: { id } } = schema

  setFieldValue(`${parent}.functionId`, '', id)
  setFieldValue(`${parent}.roleId`, '', id)

  setFieldTouched(`${parent}.functionId`, false)
  setFieldTouched(`${parent}.roleId`, false)
}

function mapStateToProps(state, ownProps) {
  const { businessModel, connectivity, user: { user }, locale: { locale } } = state
  const { businessModel: { items }, loading } = businessModel

  const options = getOptions({ items, connectivity, user, locale })

  getBusinessModel(state)

  return {
    ...ownProps,
    options,
    loading
  }
}

function getOptions({ items, connectivity, user, locale }) {
  const { configs: { localization } } = locale
  const { intl } = translateDependencies

  const shouldShowBusinessModelFromApi =
    localization.getBusinessModelFromApi &&
    !connectivity.isOffline &&
    user.isCSC

  if (shouldShowBusinessModelFromApi) return items

  return [{
    label: intl.formatMessage(messages.business_model_direct_sale_option).toUpperCase(),
    value: BUSINESS_MODEL.DIRECT_SALE
  }]
}

function getBusinessModel({
  locale: { locale: { configs: { localization } } },
  businessModel: { businessModel },
  connectivity
}) {
  const shouldGetBusinessModels =
    localization.getBusinessModelFromApi &&
    !businessModel.items.length &&
    !businessModel.loading &&
    !businessModel.error &&
    !connectivity.isOffline

  if (shouldGetBusinessModels) {
    businessModel.getBusinessModel()
  }
}
