import React, { Component } from 'react'
import PropTypes from 'prop-types'

import File from 'src/base/Form/Components/Attachments/File'
import { isDefaultFlowToggleActive } from '../../../../../config'

class FileUpload extends Component {
  constructor(props) {
    super(props)

    this.onSaveUploadedAttachment = this.onSaveUploadedAttachment.bind(this)
  }

  componentDidMount() {
    const { upload } = this.props

    upload.reset()
    this.runFieldValidation()
  }

  componentWillUnmount() {
    const { upload } = this.props

    upload.reset()
  }

  async onSaveUploadedAttachment(attachment) {
    const { file, type } = attachment

    if (!file) return

    const {
      upload,
      validations,
      person: { personId },
      customProps: { isOCRValidation }
    } = this.props

    const customAttachment = { ...attachment, verified: isOCRValidation }
    const isValid = validations.isValidSync(customAttachment)

    if (!isValid) return this.onSaveAttachment(customAttachment, true)

    const result = await upload.create(personId, {
      value: customAttachment,
      type
    })
    const { payload, error } = result

    if (error) return

    const { data: [{ downloadUrl, uploadUrl }] = [{}] } = payload

    await upload.save({ file, type, uploadUrl })

    return this.onSaveAttachment(
      { ...customAttachment, downloadUrl, uploadUrl },
      !isOCRValidation
    )
  }

  onSaveAttachment(attachment, touched) {
    const {
      id,
      form: { setFieldValue, setFieldTouched }
    } = this.props

    const value = isDefaultFlowToggleActive()
      ? { ...attachment, verified: false }
      : attachment

    setFieldValue(id, value)
    setFieldTouched(id, touched)
  }

  async runFieldValidation() {
    const {
      id,
      form: { runFieldValidation, setFieldTouched },
      validations,
      value
    } = this.props

    await runFieldValidation(id)

    setFieldTouched(id, validations.isValidSync(value))
  }

  get actionLoading() {
    return this.props.uploading || this.props.validating
  }

  render() {
    return (
      <File
        {...this.props}
        actionLoading={this.actionLoading}
        onSaveAttachment={this.onSaveUploadedAttachment}
      />
    )
  }
}

FileUpload.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  validating: PropTypes.bool,
  uploading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  validations: PropTypes.object,
  customProps: PropTypes.object,
  ocr: PropTypes.object,
  person: PropTypes.object,
  form: PropTypes.object,
  upload: PropTypes.object
}

export default FileUpload
