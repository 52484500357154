import React, { Component } from 'react'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import PropTypes from 'prop-types'

import Message from 'src/base/Form/Components/Message'
import Action from 'src/base/Form/Components/Attachments/base/Action'
import Icon from 'src/base/Icon'

import './File.styl'
import { isDefaultFlowToggleActive } from '../../../../../config'
import FilePlaceholder from './FilePlaceholder'
import toBase64 from './toBase64'

class File extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editLabel: false,
      thumbnail: null
    }

    this.onChange = this.onChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.setRefFile = this.setRefFile.bind(this)
    this.onSaveAttachment = this.onSaveAttachment.bind(this)
    this.bem = isDefaultFlowToggleActive()
      ? bemClassName.bind(null, 'defaultFile')
      : bemClassName.bind(null, 'file')
  }

  async onChange(event) {
    const {
      form,
      customProps: { type },
      onSaveAttachment,
      value
    } = this.props

    const {
      target: {
        files: [file]
      }
    } = event

    if (!file) return

    const thumbnail = await toBase64(file)
    this.setState({ thumbnail })
    form.setFieldValue(`${this.props.id}.verified`, false)

    const { name, size } = file
    const attachment = { ...value, name, size, file, type }

    this.setState({ editLabel: true })

    if (onSaveAttachment) {
      return onSaveAttachment(attachment)
    }

    this.onSaveAttachment(attachment)
  }

  async onSaveAttachment(attachment) {
    const {
      id,
      form: { setFieldValue, setFieldTouched }
    } = this.props

    await setFieldValue(id, attachment)
    await setFieldTouched(id)

    this.setBase64(attachment.file)
  }

  setBase64(file) {
    const {
      id,
      form: { setFieldValue },
      connectivity,
      value
    } = this.props

    if (!connectivity.isOffline) return

    const reader = new FileReader()

    reader.addEventListener('loadend', function () {
      setFieldValue(id, { ...value, base64: reader.result })
    })

    reader.readAsDataURL(file)
  }

  handleClick() {
    this.file.click()
  }

  setRefFile(node) {
    this.file = node
  }

  get error() {
    const {
      id,
      form: { toucheds, errors },
      value,
      validations,
      disabled
    } = this.props

    return (
      !disabled && !validations.isValidSync(value) && toucheds[id] && errors[id]
    )
  }

  get success() {
    const {
      id,
      form: { toucheds, errors },
      value,
      validations,
      disabled
    } = this.props

    const hasFile =
      value.hasOwnProperty('file') || value.hasOwnProperty('downloadUrl')

    return (
      !disabled &&
      validations.isValidSync(value) &&
      toucheds[id] &&
      !errors[id] &&
      hasFile
    )
  }

  defaultFlowRender() {
    const { id } = this.props
    const { error, success } = this

    return (
      <>
        {this.renderTitle()}
        {this.renderSubtitle()}
        <div className={this.bem('wrapper', [id])}>
          {this.renderDefaultFile(success, error)}
          {this.renderAction(success, error)}
        </div>
        {this.renderDefaultMessage()}
      </>
    )
  }

  render() {
    if (isDefaultFlowToggleActive()) {
      return this.defaultFlowRender()
    }

    const { id } = this.props
    const { error, success } = this

    return (
      <div className={this.bem('wrapper', [id])}>
        {this.renderTitle()}
        {this.renderSubtitle()}
        {this.renderFile(success, error)}
        {this.renderAction(success, error)}
        {this.renderMessage()}
      </div>
    )
  }

  renderDefaultFile(success, error) {
    const {
      accept,
      customProps: { title, subtitle },
      disabled,
      id
    } = this.props

    const { thumbnail } = this.state

    return (
      <div
        className={classNames(this.bem('file', { disabled, error, success }))}
        data-error={Boolean(error)}
      >
        <div className={this.bem('placeholder')}>
          <FilePlaceholder
            error={Boolean(error)}
            subtitle={subtitle}
            success={success}
            thumbnail={thumbnail}
            title={title}
          />
          <input
            accept={accept}
            className={this.bem('tag')}
            data-testid={id}
            disabled={disabled}
            id={id}
            name={id}
            onChange={this.onChange}
            ref={this.setRefFile}
            type="file"
          />
        </div>
      </div>
    )
  }

  renderFile(success, error) {
    const {
      id,
      customProps: { icon, iconLabel },
      disabled,
      accept
    } = this.props

    return (
      <div
        className={classNames(this.bem('file', { disabled, error, success }))}
        data-error={!!error}
      >
        <div className={this.bem('placeholder')}>
          <Icon
            name={icon}
            state={classNames({ disabled, error, success })}
            custom={true}
            size={65}
            label={iconLabel}
          />
        </div>
        <input
          id={id}
          name={id}
          type="file"
          accept={accept}
          data-testid={id}
          ref={this.setRefFile}
          className={this.bem('tag')}
          disabled={disabled}
          onChange={this.onChange}
        />
      </div>
    )
  }

  renderAction(success, error) {
    const { id, disabled, actionLoading } = this.props

    return (
      <Action
        id={`${id}-action`}
        disabled={disabled}
        error={!!error}
        success={success}
        onClick={this.handleClick}
        loading={actionLoading}
        editLabel={this.state.editLabel}
      />
    )
  }

  renderTitle() {
    if (!this.props.label) return null

    return <div className={this.bem('title')}>{this.props.label}</div>
  }

  renderSubtitle() {
    if (!this.props.sublabel) return null

    return <p className={this.bem('subtitle')}>{this.props.sublabel}</p>
  }

  renderDefaultMessage() {
    const {
      customProps: { errorMessage }
    } = this.props

    if (!this.error) return null

    return (
      <span
        style={{
          color: '#DC0719',
          fontSize: '12px',
          marginTop: '16px',
          textAlign: 'left'
        }}
      >
        {errorMessage}
      </span>
    )
  }

  renderMessage() {
    if (!this.error) return null

    return <Message error={this.props.error} />
  }
}

File.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.object,
  message: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  externalSuccess: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onChange: PropTypes.func,
  onSaveAttachment: PropTypes.func,
  connectivity: PropTypes.object,
  customProps: PropTypes.object,
  actionLoading: PropTypes.bool,
  validations: PropTypes.object
}

File.defaultProps = {
  type: 'file',
  accept: 'image/*'
}

export default File
