import React, { PureComponent, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import Modal from 'src/base/Modal'
import Avatar from '@material-ui/core/Avatar'
import Button, { TYPES } from 'src/base/Button'

import Checkbox from 'src/base/Checkbox'
import Loader from 'src/base/Loader'
import MESSAGES from './Indication.messages'

import './Indication.styl'

const bem = bemClassName.bind(null, 'indication__modal')

export class IndicationModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      indication: {},
      checkedIndication: {}
    }

    this.onFetchMore = this.onFetchMore.bind(this)
    this.getMore = this.getMore.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
  }

  onCancel() {
    this.setState({ checkedIndication: {} }, this.handleCancel)
  }

  onConfirm(){
    this.setState(({ indication: indicationValue }) => {
      const indication = !this.error ? indicationValue : {}

      return {
        indication,
        checkedIndication: {}
      }
    }, this.handleConfirm)
  }

  onFetchMore(event) {
    const { consultants } = this.props
    const { scrollTop, scrollHeight } = event.target
    const shouldLoadMore = scrollTop + 380 > scrollHeight

    if (shouldLoadMore && consultants.hasMore) {
      this.getMore()
    }
  }

  handleCancel() {
    const { consultants, onCancel } = this.props

    consultants.reset()
    onCancel(false)
  }

  handleConfirm() {
    const { onConfirm, consultants } = this.props

    consultants.reset()
    onConfirm(false, this.state.indication)
  }

  handleChecked(introducer) {
    this.setState( ({ checkedIndication: checked }) => {

      const { id } = introducer
      const lastState = checked[id]

      const indication = !lastState ? introducer : {}
      const checkedIndication = { [id]:  !lastState }

      return {
        indication,
        checkedIndication
      }})
  }

  initials(name) {
    if (!name) return null

    const names = name.trim().split(' ')
    const firstName = names[0]
    const lastName = names.length > 1 ? names[1] : ''

    const initailFirstName = firstName.charAt()
    const initailLastName = lastName.charAt()

    return `${initailFirstName}${initailLastName}`
  }

  getMore() {
    const { consultants, personId, search } = this.props

    return consultants.getMore({ personId, search })
  }

  get title() {
    const { loading, intl, id, messages } = this.props

    if (loading)
      return intl.formatMessage(messages.button_load_more_loading)

    if (this.error)
      return intl.formatMessage(messages.indication_modal_title_attention)

    return intl.formatMessage(messages[`${id}_indication_modal_title`])
  }

  get acceptLabel() {
    const { intl, messages } = this.props

    if (this.error)
      return intl.formatMessage(messages.indication_modal_button_accept)

    return intl.formatMessage(messages.indication_modal_button_comfirm)
  }

  get buttonMoreLabel() {
    const { intl, loading, messages } = this.props

    if (loading)
      return intl.formatMessage(messages.button_load_more_loading)

    return intl.formatMessage(messages.button_load_more)
  }

  get disabledConfirm() {
    const { checkedIndication, indication: { id } } = this.state

    return !checkedIndication[id] && !this.error
  }

  get error() {
    const { consultants, error } = this.props

    return error || !consultants.items.length
  }

  render() {
    const { showModal, loading, intl, messages } = this.props

    return (
      <Modal
        className={bem()}
        key="errorModal"
        title={this.title}
        open={showModal}
        accept={!loading}
        acceptLabel={this.acceptLabel}
        onClose={this.onConfirm}
        onCancel={this.onCancel}
        cancelLabel={intl.formatMessage(messages['indication_modal_button_cancel'])}
        cancel={!this.error}
        disabledConfirm={this.disabledConfirm}>
        {this.renderBody()}
      </Modal>
    )
  }

  renderBody() {
    return (
      <div className={bem('wrapper')}>
        {this.renderLoader()}
        {this.renderError()}
        {this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const { consultants, intl, messages } = this.props

    if (!consultants.items.length) return

    return (
      <Fragment>
        <div className={bem('header')}>
          <div className={bem('header__item', ['name'])}>
            {intl.formatMessage(messages['indication_modal_header_introducer'])}
          </div>
          <div className={bem('header__item', ['code'])}>
            {intl.formatMessage(messages['indication_modal_header_code'])}
          </div>
        </div>
        <div className={bem('body')} onScroll={this.onFetchMore}>
          {consultants.items.map(this.renderModalItem, this)}
          {this.renderButtonMore()}
        </div>
      </Fragment>
    )
  }

  renderLoader() {
    const { loading } = this.props

    if (!loading) return

    return <Loader className={bem('loader')} />
  }

  renderError() {
    const { intl, messages, loading } = this.props

    if (loading || !this.error) return

    return (
      <span className={bem('error')}>
        {intl.formatMessage(messages.indication_modal_error_api)}
      </span>
    )
  }

  renderModalItem(introducer) {
    const { intl, messages } = this.props

    const code = intl
      .formatMessage(messages.indication_modal_header_code)
      .substring(0, 3)
      .toUpperCase()

    return (
      <div key={introducer.naturaCode} className={bem('body__item')}>
        <div className={bem('body__item__content', ['description'])}>
          <Avatar className={bem('body__item__content', ['avatar'])}>
            {this.initials(introducer.name)}
          </Avatar>
          <span className={bem('body__item__content', ['name'])}>
            {introducer.name}
            <span className={bem('body__item__content', ['code'])}>
              {`${code}: ${introducer.naturaCode}`}
            </span>
          </span>
        </div>
        <span className={bem('body__item__content', ['id'])}>
          {introducer.naturaCode}
        </span>
        <div className={bem('body__item__content', ['checkbox'])}>
          <Checkbox
            onChange={() => this.handleChecked(introducer)}
            checked={!!this.state.checkedIndication[introducer.id]}
          />
        </div>
      </div>
    )
  }

  renderButtonMore() {
    const { consultants } = this.props

    if (!consultants.hasMore) return

    return (
      <Button
        className={bem('button-more')}
        type={TYPES.TEXT_DEFAULT}
        key="button-more"
        onClick={this.getMore}>
        {this.buttonMoreLabel}
      </Button>
    )
  }
}

IndicationModal.propTypes = {
  showModal: PropTypes.bool,
  messages: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onCheck: PropTypes.func,
  consultants: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedIntroducer: PropTypes.any,
  intl: PropTypes.any,
  personId: PropTypes.string,
  search: PropTypes.string,
  id: PropTypes.string,
}

IndicationModal.defaultProps = {
  introducers: [],
  messages: MESSAGES
}


export default injectIntl(IndicationModal)
