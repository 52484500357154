import { connect } from 'react-redux'

function mapStateToProps(state, ownProps) {
  const {
    person: { person },
    upload: { upload, loading: uploading },
  } = state

  return {
    ...ownProps,
    upload,
    person,
    uploading: uploading[ownProps.customProps.type],
  }
}

export default connect(mapStateToProps)
