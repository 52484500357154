import { defineMessages } from 'react-intl'

const messages = defineMessages({
  address_map: {
    defaultMessage: 'Address',
    id: 'form.field.address.map'
  },
  address_map_message: {
    defaultMessage: 'Write your complete address, in case it does not appear in the drop-down press Enter and place it in the Google maps.',
    id: 'form.field.address.map.message'
  },
  street_placeholder: {
    defaultMessage: 'Street, Avenue, Square...',
    id: 'form.field.street.placeholder'
  },
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'gmap',
    label: intl.formatMessage(messages['address_map']),
    required: true,
    type: 'mapsV2',
    offlineType: 'input',
    message: intl.formatMessage(messages['address_map_message']),
    htmlProps: {
      placeholder: intl.formatMessage(messages['street_placeholder'])
    }
  }
}
