import * as yup from 'yup'
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  zipcode_label: {
    defaultMessage: 'Zip Code',
    id: 'form.field.zipcode.label'
  },
  zipcode_invalid: {
    defaultMessage: 'Invalid Zip Code',
    id: 'form.field.zipcode.invalid'
  }
})

const dependencies = {
  messages
}

export const fieldParamsByLocaleId = {
  MYS: {
    placeholder: 'XXXXX',
    mask: '99999',
    fieldMaxSize: 5,
    testRule: /^[0-9]{5}$/
  },
  BRA: {
    placeholder: '00000-000',
    mask: '99999-999',
    fieldMaxSize: 8,
    testRule: /^[0-9]{8}$/
  }
}

export default function Builder(intl, injection) {
  const { locale: { id: localeId } } = injection
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { addresses } } = injection
  const { zipCode: defaultValue } = addresses.find(({ zipCode }) => zipCode) || {}


  const invalidZipcode = intl.formatMessage(messages['zipcode_invalid'])
  const fieldParams = fieldParamsByLocaleId[localeId]

  return {
    id: 'zipCode',
    label: intl.formatMessage(messages['zipcode_label']),
    placeholder: fieldParams.placeholder,
    mask: fieldParams.mask,
    defaultValue,
    required: true,
    showAsterisk: false,
    validations: yup.string()
      .test('ZIPCODE', invalidZipcode, value => validateZipcode(value, fieldParams))
  }
}

export function validateZipcode(value, fieldParams) {
  const validateValue = value.replace(/\D+/g, '')

  return validateValue.length && fieldParams.testRule.test(validateValue)
    ? true
    : false
}
