import axios from 'axios'

const SOURCE_SYSTEM = 6
const COUNTRY = 'BR'
const LANGUAGE = 'pt-BR'
const APPLICATION_JSON = 'application/json'
const URL_PEOPLE_MENAGEMENT = '/businessrelationship/peoplemanagement/v1'
const URL_PEOPLE = `${URL_PEOPLE_MENAGEMENT}/people`
const URL_DOMAINS = `${URL_PEOPLE_MENAGEMENT}/domains`
const URL_REJECTION = `${URL_DOMAINS}/rejectionReason`
const URL_DIGITAL_SPACE = '/digital-store/v1'

const BRA_CLIENT = {
  baseURL: process.env.BASE_URI,
  headers: {
    Accept: APPLICATION_JSON,
    Authorization: process.env.LOGIN_API_AUTHORIZATION,
    'Content-Type': APPLICATION_JSON,
    client_id: process.env.CLIENT_ID,
    country: COUNTRY,
    language: LANGUAGE,
    sourceSystem: SOURCE_SYSTEM
  }
}

const URLS = {
  AUTH: '/api-token/v1/oauth/access-token',
  CSC: (sellerId) => `/businessrelationship/peoplemanagement/v1/parameters/registrationTeam?user=${sellerId}`,
  SELLER: (sellerId) => `businessrelationship/sellersmanagement/v2/sellers/${sellerId}`,
  PEOPLE: (personId) => {
    const url = 'businessrelationship/peoplemanagement/v1/people'
    return personId ? `${url}/${personId}` : url
  },
  EMAIL_VALIDATION: (personId) => `${URL_PEOPLE}/${personId}/emails/verify`,
  SMS_TOKEN_RESEND: ({ id, sequence }) => `${URL_PEOPLE}/${id}/telephones/${sequence}/request_verification`,
  REJECTION: (rejectReasonId) => rejectReasonId ? `${URL_REJECTION}?reason=${rejectReasonId}` : URL_REJECTION,
  CONSULTANTS: {
    findById: (personId, sellerId) => `${URL_PEOPLE}/${personId}/consultants/${sellerId}`,
    findByName: (name) => `${URL_PEOPLE}/search?name=${name}`,
    findByDocuments: (personId, sellerDocument) => `${URL_PEOPLE}/${personId}/consultants/${sellerDocument}`,
  },
  DIGITAL_SPACE: {
    check: (digitalStoreName) => `${URL_DIGITAL_SPACE}/availability/${digitalStoreName}`,
    save: (digitalStoreName) => `${URL_DIGITAL_SPACE}/save/${digitalStoreName}`,
    get: (personId) => `${URL_DIGITAL_SPACE}/info/${personId}`,
    status: (personId) => `${URL_DIGITAL_SPACE}/fetch-statuses?personIds=${personId}`
  }
}

export default {
  client: axios.create(BRA_CLIENT),
  urls: URLS
}
