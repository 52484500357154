import { PERSON_SAVE_STATE } from 'src/reducers/Person/Person.save'
import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import Person from 'src/models/Person'

export const PERSON_FIND_LOADING = 'natura-caduni/Person/PERSON_FIND_LOADING'
export const PERSON_FIND_SUCCESS = 'natura-caduni/Person/PERSON_FIND_SUCCESS'
export const PERSON_FIND_ERROR = 'natura-caduni/Person/PERSON_FIND_ERROR'

export const types = [PERSON_FIND_LOADING, PERSON_FIND_SUCCESS, PERSON_FIND_ERROR]

export function personFindReducer(state, action, dependencies) {
  let { loading, error, person } = state
  const { payload, type } = action

  const hasSuccess = type === PERSON_FIND_SUCCESS
  const hasError = type === PERSON_FIND_ERROR
  const isLoading = type === PERSON_FIND_LOADING

  if(hasSuccess) {
    const personData = payload.data[0] || {}

    error = false
    loading = false
    person = new Person({ ...person, ...personData }, dependencies)
  }

  if(hasError) {
    loading = false
    error = new Error(action.error)
  }

  return {
    ...state,
    loading: isLoading || loading,
    error,
    person
  }
}

export function findPerson({ documents }) {
  const {
    dispatch,
    connectivity,
    urls: { PEOPLE },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  const [{ type, document }] = documents

  const queryString = `documentTypeId=${type}&document=${document}&relations=documents`
  const url = PEOPLE() + `?${queryString}`

  if (connectivity.isOffline) {
    return new Promise(offlineStep({ documents }))
  }

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId
        }
      }
    }
  })
}

function offlineStep({ documents }) {
  const { peopleRegister, dispatch } = getContext()
  const { items: [ person ] } = peopleRegister.draft({ documents })

  return function(resolve){
    dispatch({
      type: PERSON_SAVE_STATE,
      payload: { data: person }
    })

    return resolve([ person ])
  }
}
