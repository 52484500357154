import { getSubstatus } from './strategies/getSubstatus'
import { parseDateToApi } from 'src/lib/SpeckAdapter/Yup.customs'
import { isEnablePersonOtherInformation } from 'src/config'

export function onSubmition(deps) {
  const {
    person,
    peopleRegister,
    locale: { id: localeId, configs: localizationConfig }
  } = deps

  return function (data, callback) {
    const parsedData = parseData({ data, person, localizationConfig })
    const updatePerson = person.save(
      parsedData,
      savePersonStatus(person, callback, localeId)
    )

    peopleRegister.savePerson({ ...person, ...parsedData })

    return updatePerson
  }
}

function savePersonStatus(person, callback, localeId) {
  return function (newPerson, result) {
    if (!result.error) {
      person.saveStatus({ substatus: getSubstatus(localeId) })
    }
    callback()
  }
}

export function parseData({ data, person, localizationConfig }) {
  const { localization: { date: { format: dateFormat } } } = localizationConfig

  const {
    policiesAcceptedGroup,
    birthday,
    gender,
    nationalityId,
    ethnicityId,
    establishmentId,
    admissionDate,
    stateOfBirth
  } = data

  let policiesAccepted
  if (policiesAcceptedGroup) {
    policiesAccepted = person.policiesAccepted.filterAccepteds(
      policiesAcceptedGroup
    )
  }

  const additionalInformation = {
    ...(person.additionalInformation && person.additionalInformation),
    ...(ethnicityId && { ethnicityId }),
    ...(establishmentId && { establishmentId }),
    ...(admissionDate && { admissionDate: parseDateToApi(admissionDate, dateFormat) }),
    ...(isEnablePersonOtherInformation() && { otherInformation: { stateOfBirth } })
  }

  return {
    birthday,
    gender,
    policiesAccepted,
    nationalityId,
    additionalInformation
  }
}
