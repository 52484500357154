import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import AddressUse, { ADDRESS_USE } from 'src/models/Address/AddressUse'
import AddressType from './AddressType'

const adapter = validatorAdapter('Yup', yup)

export const ADDRESS_TYPES = {
  STREET: 'STREET',
  NUMBER: 'NUMBER',
  FLOOR: 'FLOOR',
  INNER: 'INNER',
  NEIGHBORHOOD: 'NEIGHBORHOOD',
  CITY: 'CITY',
  PROVINCE: 'PROVINCE',
  DISTRICT: 'DISTRICT',
  DEPARTMENT: 'DEPARTMENT',
  REGION: 'REGION',
  COUNTRY: 'COUNTRY',
  COMPLEMENT: 'COMPLEMENT',
  REFERENCES: 'REFERENCES',
  ZIPCODE: 'ZIPCODE'
}

export default class Address extends Entity {
  static SCHEMA = {
    id: adapter(yup.string().nullable()),
    description: adapter(yup.string().nullable()),
    extraInfo: adapter(yup.string().nullable()),
    complement: adapter(yup.string().nullable()),
    number: adapter(yup.string().required()),
    zipCode: adapter(yup.string().required()),
    latitude: adapter(yup.number().required()),
    longitude: adapter(yup.number().required()),
    addressType: {
      validator: adapter(yup.object(AddressType).required()),
      type: AddressType,
      defaultValue: ADDRESS_TYPES.MAILING
    },
    addressUse: {
      validator: adapter(yup.array().of(yup.object(AddressUse)).required()),
      type: AddressUse,
      defaultValue: []
    },
    geographicalLevels: adapter(
      yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required(),
            code: yup.string().required(),
            description: yup.string().required()
          })
        )
        .required()
    ),
    sequence: adapter(yup.string().nullable()),
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }

  get complements() {
    try {
      return JSON.parse(this.extraInfo)
    } catch (e) {
      return this.extraInfo
    }
  }

  get references() {
    const references = this.findGeographicLevels(this.config.levels.REFERENCES)
    return this.complements && !references
      ? this.complements[ADDRESS_TYPES.REFERENCES]
      : references
  }

  get addressComplement() {
    return this.findGeographicLevels(this.config.levels.COMPLEMENT)
  }

  get country() {
    return this.findGeographicLevels(this.config.levels.COUNTRY)
  }

  get region() {
    return this.findGeographicLevels(this.config.levels.REGION)
  }

  get department() {
    return (
      this.findGeographicLevels(this.config.levels.DEPARTMENT) || this.region
    )
  }

  get province() {
    return this.findGeographicLevels(this.config.levels.PROVINCE)
  }

  get city() {
    return this.findGeographicLevels(this.config.levels.CITY)
  }

  get district() {
    return this.findGeographicLevels(this.config.levels.DISTRICT)
  }

  get neighborhood() {
    return this.findGeographicLevels(this.config.levels.NEIGHBORHOOD)
  }

  get street() {
    return this.findGeographicLevels(this.config.levels.STREET)
  }

  get location() {
    return {
      lat: this.latitude && +this.latitude,
      lng: this.longitude && +this.longitude
    }
  }

  get addressNumber() {
    return this.findGeographicLevels(this.config.levels.NUMBER) || this.number
  }

  get isUseToDelivery() {
    return this.addressUse.some(findAddressUse, { useId: ADDRESS_USE.DELIVERY })
  }

  findGeographicLevels(level) {
    if (!this.geographicalLevels) return undefined

    return this.geographicalLevels.find(findGeographicLevels, { level })
  }
}

export function findGeographicLevels({ level = null }) {
  return level === this.level
}

export function findAddress({ addressUse }) {
  return addressUse.find(findAddressUse, this)
}

export function findAddressUse({ id }) {
  return id === this.useId
}
