import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { statusEnum } from 'src/models/Status/Status'
import { substatusEnum } from 'src/models/Substatus/Substatus'

const adapter = validatorAdapter('Yup', yup)

export class FilterSubstatus extends Entity {
  static SCHEMA = {
    substatusId: adapter(yup.number()),
    parent: adapter(yup.object().shape({
      status: yup.number()
    })),
  }

  get label() {
    return substatusEnum[this.substatusId]
  }

  get value() {
    return this.substatusId
  }
}

export class FilterStatus extends Entity {
  static SCHEMA = {
    statusId: adapter(yup.number()),
  }

  get label() {
    return statusEnum[this.statusId]
  }

  get value() {
    return this.statusId
  }
}

export default class Filters extends Entity {
  static SCHEMA = {
    status: {
      validator: adapter(yup.array().of(yup.object())),
      type: FilterStatus,
      defaultValue: []
    },
    substatus: {
      validator: adapter(yup.array().of(yup.object())),
      type: FilterSubstatus,
      defaultValue: []
    },
  }

  constructor(props, injection) {
    super(props, injection)
    Object.assign(this, injection)
  }
}
