import * as yup from 'yup'
import { defineMessages } from 'react-intl'

import FirstNameConfig from 'src/configs/Fields/Name/Name.first'
import LastNameConfig from 'src/configs/Fields/Name/Name.last'
import PhoneConfig, { getPhoneValidation } from 'src/configs/Fields/Phones/Phone.number'
import PhoneTypeConfig from 'src/configs/Fields/Phones/Phone.type'
import FormTitle from 'src/configs/Fields/Group/Group.title'

import ContactReference from 'src/models/ContactReference'

const messages = defineMessages({
  title: {
    defaultMessage: 'Reference contacts',
    id: 'form.group.references.title'
  },
  error_contact_duplicated: {
    defaultMessage: 'Duplicate reference contacts',
    id: 'form.group.references.error.contact.duplicated'
  },
  error_data_duplicated: {
    defaultMessage: 'The reference contact can not have the same data as the contact of the intention',
    id: 'form.group.references.error.data.duplicated'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    person: { contactReferences },
    locale: { configs: { localization: { phone } } }
  } = injection

  const title = intl.formatMessage(messages['title'])
  const phoneValidation = getPhoneValidation({ ...phone, intl, emailRequired: false })

  const GROUP_TITLE = FormTitle(`${title}*`)
  const FIRST_NAME = FirstNameConfig(intl, injection)
  const LAST_NAME = LastNameConfig(intl, injection)
  const PHONE = PhoneConfig(intl, injection)
  const PHONE_TYPE = PhoneTypeConfig(intl, injection)

  if(!contactReferences.length) {
    const base = {
      type: PHONE_TYPE.options[0].value,
      countryCode: phone.countryCode,
      phone: ''
    }

    contactReferences.push(new ContactReference(base))
    contactReferences.push(new ContactReference(base))
  }

  return {
    id: 'contactReferences',
    min: 2,
    max: 2,
    defaultValue: contactReferences.map(mapContacts),
    title: GROUP_TITLE,
    fields: [
      [FIRST_NAME, LAST_NAME],
      [PHONE_TYPE, PHONE]
    ],
    validations: yup.array().of(yup.object().shape({
      firstName: yup.string().test({
        name: 'duplicatedContactName',
        message: intl.formatMessage(messages.error_contact_duplicated),
        test: duplicatedContact(nameParser)
      }).test({
        name: 'duplicatedData',
        message: intl.formatMessage(messages.error_data_duplicated),
        test: duplicatedDataName
      }).required(),
      lastName: yup.string().test({
        name: 'duplicatedContactName',
        message: intl.formatMessage(messages.error_contact_duplicated),
        test: duplicatedContact(nameParser)
      }).test({
        name: 'duplicatedDataName',
        message: intl.formatMessage(messages.error_data_duplicated),
        test: duplicatedDataName
      }).required(),
      phone: phoneValidation.test({
        name: 'duplicatedContactPhone',
        message: intl.formatMessage(messages.error_contact_duplicated),
        test: duplicatedContact(phoneParser)
      }).test({
        name: 'duplicatedDataPhone',
        message: intl.formatMessage(messages.error_data_duplicated),
        test: duplicatedDataPhone
      }).required(),
      type: yup.number().required()
    }))
  }
}

function mapContacts({ firstName, lastName, phone, countryCode, type }) {
  return {
    firstName,
    lastName,
    phone,
    countryCode: String(countryCode),
    type
  }
}

function duplicatedDataName() {
  const { path, options: { context: { values: { contactReferences, firstName, lastName } }}} = this
  const [rawIndex] = path.match(/\d+/g)
  const index = +rawIndex
  const current = contactReferences[index]

  const currentData = nameParser(current)
  const otherData = nameParser({ firstName, lastName })

  return currentData.toUpperCase() !== otherData.toUpperCase()
}

function duplicatedDataPhone(value) {
  const { options: { context: { values: { phones } }}} = this
  const [phoneA, phoneB] = phones
  const phone = value.replace(' ', '')

  const firstPhone = phoneA && phoneParser(phoneA)
  const secondPhone = phoneB && phoneParser(phoneB)

  if(firstPhone && firstPhone === phone) return false
  if(secondPhone && secondPhone === phone) return false

  return true
}

function duplicatedContact(parser) {
  return function() {
    const { path, options: { context: { values: { contactReferences } }}} = this
    const [rawIndex] = path.match(/\d+/g)
    const index = +rawIndex
    const current = contactReferences[index]
    const other = contactReferences[index + 1] || contactReferences[0]

    const currentData = parser(current)
    const otherData = parser(other)

    return currentData.toUpperCase() !== otherData.toUpperCase()
  }
}

function nameParser(field) {
  return `${field.firstName} ${field.lastName}`
}

function phoneParser(field) {
  return field.phone.replace(' ', '')
}
