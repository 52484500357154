import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Modal from 'src/base/Modal'
import { APP_ROUTES } from 'src/App'
import MESSAGES from './Register.messages'
import { getContext } from '../../infra/Api/Api.context'

import {
  PERSON_NOT_FOUND_CODE,
  DOCUMENT_ALREADY_EXISTS_CODE,
  EXISTS_ACTIVE_PERSON_CODE,
  errorsByCodeEnum,
  errorsByStatusEnum,
  fieldErrorsEnum,
  fieldTouchedEnum
} from './RegisterErrorEnum'

export class RegisterErrorModal extends PureComponent {
  constructor(props) {
    super(props)

    this.onCloseErrorModal = this.onCloseErrorModal.bind(this)
  }

  async onCloseErrorModal() {
    const {
      error: { code, resetErrors, },
      setFieldError,
      setFieldTouched,
      setFieldValue,
      focusOnFirstError,
      history,
      name
    } = this.props

    resetErrors()
    if (code === PERSON_NOT_FOUND_CODE)
      return history.replace(APP_ROUTES.REGISTER)

    if (code === EXISTS_ACTIVE_PERSON_CODE) {
      return setFieldValue('documents[0].document', '')
    }

    if (!fieldErrorsEnum[code]) return

    await setFieldError(fieldErrorsEnum[code], this.message)
    await setFieldTouched(fieldTouchedEnum[code], true)

    focusOnFirstError(name)
  }

  get title() {
    const { intl, error } = this.props
    const defaultErrorMessage = intl.formatMessage(MESSAGES.error_title)
    const customError = getCustomError(error)

    if (!customError)
      return error.title || defaultErrorMessage

    if (customError.title)
      return intl.formatMessage(MESSAGES[customError.title])

    return defaultErrorMessage
  }

  get message() {
    const { error, intl } = this.props
    const { code, message, originalError: { response: { data: { items } = {} } = {} } = {} } = error

    const customError = getCustomError(error)

    if (code === DOCUMENT_ALREADY_EXISTS_CODE) {
      const documentWithError = items.find(({ document }) => document) || {}

      return intl.formatMessage(MESSAGES[customError.message], { document: documentWithError.document })
    }

    if (customError) {
      return intl.formatMessage(MESSAGES[customError.message])
    }

    return message
  }

  render() {
    const { error } = this.props

    if (!error) return null

    return (
      <Modal
        key="errorModal"
        open={true}
        title={this.title}
        message={this.message}
        onClose={this.onCloseErrorModal}
      />
    )
  }
}

RegisterErrorModal.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  intl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  resetErrors: PropTypes.func,
  name: PropTypes.string,
  focusOnFirstError: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default withRouter(injectIntl(RegisterErrorModal))

function getCustomError(error) {
  const { locale } = getContext()
  const { configs: { localization: { isHostedUIEnabled }}} = locale

  return isHostedUIEnabled
    ? getErrorFromBFF(error.originalError.response)
    : getError(error)
}

function getError({ code, status }) {
  return errorsByCodeEnum[code] || errorsByStatusEnum[status]
}

function getErrorFromBFF(errorResponse) {
  const {
    data: { error: { code } },
    status,
  } = errorResponse

  return errorsByCodeEnum[code] || errorsByStatusEnum[status]
}
