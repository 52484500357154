import { defineMessages } from 'react-intl'

import { ACCEPT_AUDIO_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { CONFIGS } from 'src/models/Attachment'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'

const FIELD_ID = 'termAndConditions'

const messages = defineMessages({
  termAndConditions: {
    defaultMessage: 'Terms and conditions',
    id: 'form.field.termAndConditions'
  },
  termAndConditions_sublabel: {
    defaultMessage: "The voice of intention must be captured by reading the acceptance text of Natura's terms and conditions.",
    id: 'form.field.termAndConditions.sublabel'
  }
})
const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id: localeId, configs: { localization }  }, person: { documents, attachments }, required } = injection
  const { documents: documentsConfigs } = localization

  const personalDocument = documents.find(findDocuments, { documents: documentsConfigs.personal }) || {}

  const attachmentsIds = CONFIGS[localeId]
  const TYPE = attachmentsIds[FIELD_ID]
  const defaultValue = defineDefaultValue(attachments, TYPE)

  return {
    id: FIELD_ID,
    type: 'userMedia',
    defaultValue,
    success: true,
    label: intl.formatMessage(messages[FIELD_ID]),
    sublabel: intl.formatMessage(messages[`${FIELD_ID}_sublabel`]),
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_AUDIO_FORMATS,
      isAudio: true,
      required
    }),
    htmlProps: {
      accept: ACCEPT_AUDIO_FORMATS.join(',')
    },
    customProps: {
      icon: 'microphone',
      modal: {
        Component: 'audio',
        className: 'modal-record-audio',
      },
      type: TYPE,
      personalDocument
    }
  }
}

function findDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}
