export const ROLES = {
  LEADER: {
    businessModelId: 1,
    roleId: 6,
    functionId: 4
  },
  GDN: {
    businessModelId: 1,
    roleId: 6,
    functionId: 17
  },
  GV: {
    businessModelId: 1,
    roleId: 6,
    functionId: 8
  },
  GR: {
    businessModelId: 1,
    roleId: 6,
    functionId: 22
  },
  CN: {
    businessModelId: 1,
    roleId: 1,
    functionId: 1
  },
  CSC: {
    businessModelId: 2,
    roleId: 2,
    functionId: 14
  }
}
