import { store } from 'src/App.state'
import { clients, CLIENT_NAMES } from './Api'

export function getContext() {
  const { getState, dispatch } = store
  const { locale: { locale }, user: { user }, connectivity, peopleRegister: { peopleRegister } } = getState()
  const { urls } = clients[locale.id] || {}
  const { urls: cmm_urls } = clients[`${locale.id}_CMM`] || {}
  const { client: bff_client, urls: bff_urls, apiKeys: bff_apiKeys } = clients[`${locale.id}_BFF`] || {}
  const { client: default_bff_client, urls: default_bff_urls, apiKeys: default_bff_apiKeys } = clients[CLIENT_NAMES.DEFAULT_BFF] || {}
  const { urls: authenticationUrls } = clients.AUTHENTICATION || {}

  return {
    getState,
    dispatch,
    urls,
    bff_client,
    bff_urls,
    bff_apiKeys,
    default_bff_client,
    default_bff_urls,
    default_bff_apiKeys,
    cmm_urls,
    locale,
    user,
    peopleRegister,
    connectivity,
    authenticationUrls
  }
}
