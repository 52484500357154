import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_REJECTION_REASONS_LOADING = 'natura-caduni/People/PROFILE_REJECTION_REASONS_LOADING'
export const PROFILE_REJECTION_REASONS_SUCCESS = 'natura-caduni/People/PROFILE_REJECTION_REASONS_SUCCESS'
export const PROFILE_REJECTION_REASONS_ERROR = 'natura-caduni/People/PROFILE_REJECTION_REASONS_ERROR'

const TYPES_REJECTION = [PROFILE_REJECTION_REASONS_LOADING, PROFILE_REJECTION_REASONS_SUCCESS, PROFILE_REJECTION_REASONS_ERROR]

export function getRejectionReasons() {
  const globalAppContext = getContext()
  const {
    dispatch,
  } = globalAppContext

  const { requestClientName, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext })

  return dispatch({
    types: TYPES_REJECTION,
    payload: {
      client: requestClientName,
      request: {
        method: 'GET',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload ({ globalAppContext }){
    const {
      REJECTION_REASONS_URL,
      REJECTION_REASONS_BFF_URL,
      countryIdAlphaCode2,
      countryId,
      companyId,
      bffHeaders,
      headersAuthenticationInfo,
      shouldGetRejectionReasonsFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const headersData = { companyId, countryId, countryIdAlphaCode2, bffHeaders, sellerId }

    const requestClientName = shouldGetRejectionReasonsFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId

    const { requestHeaders } = getRequestHeaders({ shouldGetRejectionReasonsFromBff, headersAuthenticationInfo, headersData })

    const requestUrl = shouldGetRejectionReasonsFromBff ? REJECTION_REASONS_BFF_URL : REJECTION_REASONS_URL

    return { requestClientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({globalAppContext}){
      const {
        urls: { REJECTION_REASONS },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { REJECTION_REASONS: REJECTION_REASONS_BFF },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          countryId: countryIdAlphaCode2,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetRejectionReasonsFromBff = configs?.localization?.shouldGetRejectionReasonsFromBff

      return {
        REJECTION_REASONS_URL: REJECTION_REASONS(),
        REJECTION_REASONS_BFF_URL: REJECTION_REASONS_BFF(),
        shouldGetRejectionReasonsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryIdAlphaCode2,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetRejectionReasonsFromBff, headersAuthenticationInfo, headersData }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const { countryIdAlphaCode2, countryId, companyId, bffHeaders, sellerId } = headersData

      const requestHeaders = shouldGetRejectionReasonsFromBff ? {
        ...bffHeaders,
        Authorization: userAccessToken,
        'x-api-key': registersBffApiKey,
        country: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        countryId: countryIdAlphaCode2,
      }

      return { requestHeaders }
    }
  }
}
