import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'src/base/Modal'
import SmsTokenModalContent from 'src/base/ContactValidation/Sms'

class VerifyContacts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false
    }

    this.verifyTokenModal = this.verifyTokenModal.bind(this)
  }

  componentDidMount() {
    const { person: { get, personId }, localization: { shouldCallGetPersonInVerifyContacts } } = this.props

    if (shouldCallGetPersonInVerifyContacts)
      return get(personId, this.verifyTokenModal)

    return this.verifyTokenModal()
  }

  get phone() {
    const { person: { mobilePhone: { phone, phoneNumber: number, sequence } = {} } } = this.props

    return {
      phone,
      number,
      sequence
    }
  }

  verifyTokenModal() {
    this.setState({ openModal: true })
  }

  render() {
    return (
      <Modal open={this.state.openModal} accept={false}>
        <SmsTokenModalContent
          phone={this.phone}
          closeModal={this.props.callback}
        />
      </Modal>
    )
  }
}

VerifyContacts.propTypes = {
  person: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  localization: PropTypes.object.isRequired
}

export default VerifyContacts
