import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const STATUS = {
  DRAFT: 1,
  PENDING: 2,
  APPROVED: 3,
  REJECTED: 4,
  GAVE_UP: 5,
  BLOCKED_NATURA: 6
}

export default class Status extends Entity {
  static SCHEMA = {
    description: adapter(yup.string().nullable()),
    isBlocked: adapter(yup.boolean().nullable()),
    status: adapter(yup.number().nullable()),
    restrictions: adapter(yup.object().shape({
      beginDate: yup.date().required(),
      endDate: yup.date().required(),
      id: yup.number().required(),
      description: yup.string().required(),
      typeDescription: yup.string().required(),
      typeId: yup.string().required()
    }).nullable())
  }

  get isApproved() {
    return this.status === STATUS.APPROVED
  }

  get rejectedDescription() {
    const [{ typeDescription } = {}] = this.restrictions || []

    return typeDescription
  }

  get isPending() {
    return this.status === STATUS.PENDING
  }

  get isRejected() {
    return this.status === STATUS.REJECTED
  }

  get isGaveup() {
    return this.status === STATUS.GAVEUP
  }
}

export const statusEnum = Object.entries(STATUS).reduce(substatusReduce, {})

function substatusReduce(status, [name, id]) {
  return { ...status, [id] : name.toLowerCase() }
}
