export default class CreditModality {
  constructor(params) {
    Object.assign(this, params)
  }

  get title() {
    const { messages, person: { paymentPlan, status: { isRejected } }, configs } = this
    const { isDenied, isPrePaid, isCredit } = paymentPlan
    const { localization: { selectPaymentPlan }} = configs

    if (selectPaymentPlan) return messages.title_select_payment
    if (isDenied || isRejected) return messages.title_not_approved
    if (isPrePaid) return messages.title_pre_paid
    if (isCredit) return messages.title_credit

    return messages.title_analysis
  }

  get confirmLabel() {
    const { messages, person: { paymentPlan: { isPrePaid }}} = this
    const customLabel = { confirmLabel: messages.yes_accept_label }

    return isPrePaid ? customLabel : {}
  }

  get isDeniedPaymentPlan() {
    const { person: { paymentPlan: { isDenied }, status: { isRejected }}} = this

    return isDenied || isRejected
  }

  get showCancelRegistrationButton() {
    const { configs: { localization: { selectPaymentPlan }}, person: { paymentPlan: { isPrePaid } } } = this

    return !selectPaymentPlan && isPrePaid
  }
}
