import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  payment_plan_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.paymentPlan'
  },
  pre_paid_label: {
    defaultMessage: 'Pre-Paid',
    id: 'form.field.pre.paid.label'
  },
  credit_label: {
    defaultMessage: 'Credit',
    id: 'form.field.credit.label'
  },
  payment_plan_no_options: {
    defaultMessage: 'No options',
    id: 'form.field.no.options.label'
  }
})

const dependencies = {
  messages
}


export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { paymentPlan: { id: defaultValue }}, connectivity: { isOffline }} =  injection

  const credit = {
    value: 1,
    label: intl.formatMessage(messages['credit_label'])
  }

  const prePaid = {
    value: 2,
    label: intl.formatMessage(messages['pre_paid_label'])
  }

  const options = isOffline ? [prePaid] : [credit, prePaid]

  return {
    id: 'paymentPlan',
    validations: yup.number().required(),
    placeholder: intl.formatMessage(messages['payment_plan_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['payment_plan_no_options']),
    type: 'select',
    defaultValue,
    options
  }
}
