import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'
import Message from 'src/base/Form/Components/Message'
import Button, { TYPES } from 'src/base/Button'
import Modal from 'src/base/Modal'
import Person from 'src/models/Person/Person'
import RecordAudio from 'src/base/GetUserMedia/RecordAudio'
import TakePhoto from 'src/base/GetUserMedia/TakePhoto'

import MESSAGES from './UserMedia.messages'

import './UserMedia.styl'
import { isDefaultFlowToggleActive } from '../../../../../config'

const userMedia = {
  audio: RecordAudio,
  photo: TakePhoto
}

class UserMedia extends React.Component {
  constructor(props) {
    super(props)

    this.inputFile = React.createRef()
    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.setAttachment = this.setAttachment.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.bem = bemClassName.bind(null, isDefaultFlowToggleActive() ? 'defaultUser-media-upload' : 'user-media-upload')

    this.state = { modalOpened: false }
  }

  onClick() {
    this.setState({ modalOpened: true })
  }

  async onChange(event) {
    const [file] = event.target.files
    const { form, id, customProps: { type }, connectivity: { isOffline } } = this.props

    if (file) {
      const { name, size } = file

      await form.setFieldValue(id, { name, size, type, file })
      await form.setFieldTouched(id)

      isOffline && this.setBase64(file)
    }
  }

  get error() {
    const { id, form: { errors, toucheds }, disabled } = this.props

    return !disabled && toucheds[id] && errors[id]
  }

  get success() {
    const { id, form: { values } } = this.props
    const value = values[id]
    const hasFile =  value.hasOwnProperty('file') ||  value.hasOwnProperty('downloadUrl')

    return !this.error && hasFile
  }

  closeModal() {
    this.setState({ modalOpened: false })
  }

  setAttachment(blob) {
    const file = generateFileFromBlob(blob)
    this.onChange({ target: { files: [file] } })
    this.closeModal()
  }

  uploadFile() {
    this.closeModal()
    this.inputFile.current.click()
  }

  setBase64(file) {
    const { id, form: { setFieldValue }, value } = this.props
    const reader = new FileReader()

    reader.addEventListener("loadend", function() {
      setFieldValue(id, { ...value, base64: reader.result })
    })

    reader.readAsDataURL(file)
  }

  render() {
    return (
      <section className={this.bem()}>
        {this.renderLabel()}
        {this.renderInput()}
        {this.renderMessage()}
        {this.renderModal()}
      </section>
    )
  }

  renderLabel() {
    const {
      label, sublabel,
    } = this.props

    return(
      <Fragment>
        {label && <div className={this.bem('title')}>{label}</div>}
        {sublabel && <p className={this.bem('subtitle')}>{sublabel}</p>}
      </Fragment>
    )
  }

  renderInput() {
    const {
      intl,
      disabled,
      customProps: { icon },
      htmlProps: { accept },
    } = this.props

    const {
      success,
      error
    } = this

    const stateClassModifier = {
      error: error,
      disabled: disabled,
      success: success
    }

    return(
      <Fragment>
        <div className={this.bem('input-box', stateClassModifier)} data-error={!!this.error}>
          <input
            data-testid={`document-input-${icon}`}
            hidden
            accept={accept}
            onChange={this.onChange}
            ref={this.inputFile}
            disabled={disabled}
            type="file" />

          <Icon name={icon} className={this.bem('icon', { [icon]: icon, ...stateClassModifier })} />
        </div>
        <Button
          className={this.bem('action-button', stateClassModifier)}
          disabled={disabled}
          onClick={this.onClick}
          type={TYPES.PRIMARY}>

          {this.success
            ? intl.formatMessage(MESSAGES['userMediaUploadType_edit'])
            : intl.formatMessage(MESSAGES['userMediaUploadType_send'])}

        </Button>
      </Fragment>
    )
  }

  renderMessage() {
    if (!this.error) return null

    return <Message error={this.error} />
  }

  renderModal() {
    const { person } = this.props
    const { modal, personalDocument } = this.props.customProps

    if (!modal) return null

    const component = userMedia[modal.Component]

    return (
      <Modal open={this.state.modalOpened} className={modal.className} accept={false} onClose={this.closeModal}>
        {React.createElement(component, {
          uploadFile: this.uploadFile,
          setAttachment: this.setAttachment,
          closeParentModal: this.closeModal,
          person,
          personalDocument
        })}
      </Modal>
    )
  }
}

UserMedia.propTypes = {
  accept: PropTypes.string,
  customProps: PropTypes.object,
  connectivity: PropTypes.object,
  disabled: PropTypes.bool,
  form: PropTypes.object,
  htmlProps: PropTypes.object,
  icon: PropTypes.string,
  id: PropTypes.string,
  intl: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  person: PropTypes.instanceOf(Person),
  sublabel: PropTypes.string,
}

function generateFileFromBlob(blob) {
  const [, extension] = blob.type.split('/')
  const extensionWithoutCodec = extension.split(';')[0]
  const filename = `${Date.now()}.${extensionWithoutCodec}`

  return new File([blob], filename, { type: blob.type })
}

export default UserMedia
