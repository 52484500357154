import { BuildStepsMapper } from './BuildStepsMapper'
import {
  isChileAddressMfeToggleActive,
  isChileDocumentationToggleActive,
  isChilePersonalDataMfeToggleActive,
  isChileWithLegoToggleActive,
  isEnableCompletedStepOnMfeChileCSCToggleActive,
  isEnableCompletedStepOnMfeToggleActive,
  isEnableAttachmentsStepOnMfeForChileToggleActive,
  isPreRegisterMfeChileToggleActive
} from 'src/config'
import { buildCompletedPropsWithInstructionsMessage } from '../../Completed/CompletedPropsWithInstructionMessage'
import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import Loader from '../../../../../../../views/Register/Components/Loader'
import { store } from 'src/App.state'
import {
  ADM_COLLABORATOR_PROFILE,
  BEAUTY_CONSULTANT_PROFILE,
  BIG_CONSULTANT_PROFILE,
  BUSINESS_LEADER_PROFILE,
  BUSINESS_MANAGER_PROFILE,
  CCC_PROFILE,
  DIRECTOR_PROFILE,
  ENTREPRENEUR_CONSULTANT_PROFILE,
  FINAL_CONSUMER_PROFILE,
  FRANCHISE_PROFILE,
  MANAGER_PROFILE,
  SALES_MANAGER_PROFILE,
  SNAC_PROFILE,
  THIRD_PARTY_PROFILE
} from 'src/configs/Locales/DEFAULT/NaturaProfiles'
import { personalStepByProfile } from 'src/configs/Locales/DEFAULT/views/SelfRegister/strategies/buildFlows/buildFlows.chl'
import { buildCompletedMicrofrontendProps } from '../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildDocumentsPropsMicrofrontend } from '../../Documents/DocumentsPropsMicrofrontend'
import { buildPreRegisterPropsMicrofrontend } from '../../PreRegister'

export const buildSteps = ({ basePath }) => {
  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 8 })
  const {
    user: { user }
  } = store.getState()

  const overrideFormDefaultWithMfeContainer = {
    components: [
      LinearProgressStepper,
      SectionTitle,
      MicroFrontendContainer,
      Loader
    ]
  }

  const overrideCompletedWithStatusWithMfeContainer = {
    components: [SectionTitle, MicroFrontendContainer],
    componentsPropsBuilder: buildCompletedMicrofrontendProps
  }
  const personalDataOverrideProps = isChilePersonalDataMfeToggleActive()
    ? overrideFormDefaultWithMfeContainer
    : {}
  const addressOverrideProps = isChileAddressMfeToggleActive()
    ? overrideFormDefaultWithMfeContainer
    : {}
  const completedOverrideProps = isEnableCompletedStepOnMfeToggleActive()
    ? overrideCompletedWithStatusWithMfeContainer
    : { componentsPropsBuilder: buildCompletedPropsWithInstructionsMessage }
  const documentationOverrideProps = isChileDocumentationToggleActive()
    ? overrideFormDefaultWithMfeContainer
    : {}
  const attachmentsOverrideProps = isEnableAttachmentsStepOnMfeForChileToggleActive()
    ? {
      ...overrideFormDefaultWithMfeContainer,
      componentsPropsBuilder: buildDocumentsPropsMicrofrontend
    } : {}
  const preRegisterOverrideProps =
    isPreRegisterMfeChileToggleActive() && !user.isCSC
      ? {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }  : {}

  const steps = [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: preRegisterOverrideProps
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: personalDataOverrideProps
    }),
    buildStepsMapper.mapAddress({
      currentStep: 3,
      overrideProps: addressOverrideProps
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 4,
      overrideProps: documentationOverrideProps
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 5,
      overrideProps: attachmentsOverrideProps
    }),
    buildStepsMapper.mapIndication({ currentStep: 6 }),
    buildStepsMapper.mapDigitalStore({ currentStep: 7 }),
    buildStepsMapper.mapCompleted({
      currentStep: 8,
      overrideProps: { ...completedOverrideProps }
    })
  ]
  const shouldEnableBusinessModelSteps =
    user && user.isCSC && isChileWithLegoToggleActive()
  const completedCSCOverrideProps = isEnableCompletedStepOnMfeChileCSCToggleActive()
    ? overrideCompletedWithStatusWithMfeContainer
    : { componentsPropsBuilder: buildCompletedPropsWithInstructionsMessage }

  const generatePersonalSteps = (builderSteps) => {
    return [
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: BEAUTY_CONSULTANT_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: BIG_CONSULTANT_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: ENTREPRENEUR_CONSULTANT_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: FRANCHISE_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: BUSINESS_LEADER_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: SALES_MANAGER_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: BUSINESS_MANAGER_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: ADM_COLLABORATOR_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: THIRD_PARTY_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: SNAC_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: FINAL_CONSUMER_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: CCC_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: MANAGER_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      }),
      builderSteps.mapPersonalDataByProfile({
        currentStep: 3,
        profile: DIRECTOR_PROFILE,
        personalStepByProfile,
        overrideProps: personalDataOverrideProps
      })
    ]
  }

  if (shouldEnableBusinessModelSteps) {
    const buildCSCStepsMapper = new BuildStepsMapper({
      basePath,
      totalSteps: 9
    })

    return [
      buildCSCStepsMapper.mapPreRegister({
        currentStep: 1,
        overrideProps: preRegisterOverrideProps
      }),
      buildCSCStepsMapper.mapBusinessModel({ currentStep: 2 }),
      ...generatePersonalSteps(buildCSCStepsMapper),
      buildCSCStepsMapper.mapAddress({
        currentStep: 4,
        overrideProps: addressOverrideProps
      }),
      buildCSCStepsMapper.mapDocumentation({ currentStep: 5 }),
      buildCSCStepsMapper.mapDocumentAttachment({
        currentStep: 6,
        overrideProps: attachmentsOverrideProps
      }),
      buildCSCStepsMapper.mapIndication({ currentStep: 7 }),
      buildCSCStepsMapper.mapDigitalStore({ currentStep: 8 }),
      buildCSCStepsMapper.mapCompleted({
        currentStep: 9,
        overrideProps: { ...completedCSCOverrideProps }
      })
    ]
  }

  return steps
}
