import React from 'react'
import PropTypes from 'prop-types'
import { nationalities } from 'src/configs/Fields/Nationality/Nationality.list'
import { nationalitiesMessages } from 'src/configs/Fields/Nationality/Nationality.messages'

export default function Builder(params) {
  const { intl, messages, person } = params
  const { documents, nationalityId, attachments = [] } = person

  const documentsFields = documents.reduce(createDocumentField, [params])

  const [{ label: documentLabel } = {}] = documentsFields

  const attachmentsFields = createAttachments(attachments, { ...params, documentLabel })

  const nationality = Object.entries(nationalities)
    .map(createNationalitiesList, { intl, nationalitiesMessages })
    .find(item => item.value == nationalityId) || {}

  const { label: nationalityLabel } = nationality

  const rg = documents.find(({ type }) => type == 2) || {}
  const uf = rg.extraInfos ? rg.extraInfos[0].extraInfo : ''

  return {
    id: 'profile-documents',
    title: intl.formatMessage(messages.box_documents_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      {
        label: intl.formatMessage(messages.box_documents_label_nationality),
        field: nationalityLabel || '-'
      },
      ...documentsFields,
      {
        label: intl.formatMessage(messages.box_documents_label_uf),
        field: uf || '-'
      },
      attachmentsFields
    ]
  }
}

// TODO: We need to include on Locales this type of ids (9 and 10)
// and we need to store enums like this on models
const documentsEnum = {
  1: 'box_documents_label_document_cpf',
  2: 'box_documents_label_document_rg',
  3: 'box_documents_label_document_rne',
  4: 'box_documents_label_document_cnh'
}

function createDocumentField(accum, { document, type }, index, array) {
  const [{ intl, messages }] = accum
  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!documentsEnum[type]) return accum

  const field = {
    label: intl.formatMessage(messages[documentsEnum[type]]),
    field: document
  }

  return [...accum, field]
}

// TODO: We need to include on Locales this type of ids
// and we need to store enums like this on models
const attachmentsEnum = {
  1: 'box_documents_label_attachments_document_cnh_front',
  2: 'box_documents_label_attachments_document_cnh_verse',
  3: 'box_documents_label_attachments_document_rg_front',
  4: 'box_documents_label_attachments_document_rg_verse',
  5: 'box_documents_label_attachments_document_rne',
  6: 'box_documents_label_attachments_document_identify_front',
  7: 'box_documents_label_attachments_document_identify_back',
  8: 'box_documents_label_attachments_document_proof_address',
  9: 'box_documents_label_attachments_document_accenptance_term',
  10: 'box_documents_label_attachments_document_candidate_photo',
  11: 'box_documents_label_attachments_document_economic_life'
}

function createAttachments(attachments, params) {
  const { intl, messages } = params
  const links = attachments.map(createAttachmentLink, params)

  const field = links.length
    ? <div className="profile__attachments">{links}</div>
    : '-'

  return {
    label: intl.formatMessage(messages.box_documents_label_attachments),
    field
  }
}

function createAttachmentLink({ type, url }, key) {
  const { intl, messages } = this
  const link = intl.formatMessage(messages[attachmentsEnum[type]], { doc: this.documentLabel })

  return (
    <a
      className="profile__attachment__link"
      href={url}
      key={key}
      target="_blank"
      rel="noopener noreferrer">
      {link}
    </a>
  )
}

function createNationalitiesList([key, value]) {
  const label = this.intl.formatMessage(nationalitiesMessages[key])
  return {
    value,
    label
  }
}

createAttachmentLink.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string
}
