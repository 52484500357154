import TYPES from 'src/configs/Fields'

export const getDefaultFieldsSchema = ({ intl, dependencies }) => {

  const ADDRESS_DETAILS_DEFAULT = commonParams(TYPES.ADDRESS_DETAILS_DEFAULT(intl, dependencies))

  return [[ADDRESS_DETAILS_DEFAULT]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
