import { getContext } from 'src/infra/Api/Api.context'
import Ocr from 'src/viewModels/Ocr'

export const OCR_SAVE_ATTACHMENTS_LOADING = 'natura-caduni/Ocr/OCR_SAVE_ATTACHMENTS_LOADING'
export const OCR_SAVE_ATTACHMENTS_SUCCESS = 'natura-caduni/Ocr/OCR_SAVE_ATTACHMENTS_SUCCESS'
export const OCR_SAVE_ATTACHMENTS_ERROR = 'natura-caduni/Ocr/OCR_SAVE_ATTACHMENTS_ERROR'

export const types = [
  OCR_SAVE_ATTACHMENTS_LOADING,
  OCR_SAVE_ATTACHMENTS_SUCCESS,
  OCR_SAVE_ATTACHMENTS_ERROR
]

export function ocrSaveAttachmentsReducer(state, action, dependencies) {
  let { error, ocr } = state

  const loading = action.type === OCR_SAVE_ATTACHMENTS_LOADING
  const isError = action.type === OCR_SAVE_ATTACHMENTS_ERROR
  const isSucess = action.type === OCR_SAVE_ATTACHMENTS_SUCCESS

  if (isSucess)
    ocr = new Ocr({}, dependencies)

  if (isError)
    error = new Error(action.error)

  return {
    ocr,
    loading,
    error,
  }
}

export function saveOCRAttachments(person, data) {
  const {
    dispatch,
    urls: { ATTACHMENTS },
    locale: { id },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  const {
    personId,
    businessModel: {
      businessModelId,
      functionId,
      roleId
    }
  } = person

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'PUT',
        url: ATTACHMENTS(personId),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          businessModel: businessModelId,
          function: functionId,
          role: roleId,
        },
        data
      }
    }
  })
}
