import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import Select from 'src/base/Form/Components/Select'

const messages = defineMessages({
  establishment_label: {
    defaultMessage: 'Establishment',
    id: 'form.field.establishment.label'
  },
  establishment_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.establishment.placeholder'
  },
  establishment_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.establishment.no.options.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const { messages } = { ...dependencies, ...injection }
  const { person: { additionalInformation: { establishmentId } } } = injection

  return {
    id: 'establishmentId',
    type: connect(mapStateToProps)(Select),
    label: intl.formatMessage(messages['establishment_label']),
    placeholder: intl.formatMessage(messages['establishment_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['establishment_no_options_message']),
    defaultValue: establishmentId,
    onChange: clearAdmissionDate,
    validations: yup.string(),
  }
}

function clearAdmissionDate(props) {
  const { setFieldValue, setFieldTouched } = props

  setFieldValue('admissionDate', '')
  setFieldTouched('admissionDate', false)
}

const calleds = {}

function mapStateToProps({ establishments }, ownProps) {
  const { establishments: { getEstablishments, items: options } } = establishments

  if(!options.length && !calleds.establishments) {
    calleds.establishments = true

    getEstablishments()
  }

  return {
    ...ownProps,
    options
  }
}
