import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Digital Space',
    id: 'register.digital.space.title'
  },
  page_title: {
    defaultMessage: 'Consultant Registration',
    id: 'register.personal.page.title'
  },
  page_title_cno: {
    defaultMessage: 'Consultant Indication',
    id: 'register.personal.page.title.cno'
  },
  subtitle_1: {
    defaultMessage: 'Complete the fields below to make your register.',
    id: 'register.personal.subtitle_1'
  },
  subtitle_1_cno: {
    defaultMessage: 'Complete the fields below to make your identification.',
    id: 'register.personal.subtitle_1_cno'
  },
  subtitle_2: {
    defaultMessage: 'Fields with "*" are required.',
    id: 'register.personal.subtitle_2'
  },
})

export function getSectionTitle({ user, messages = Messages }) {
  const { isCNO } = user

  const {
    page_title,
    page_title_cno,
    subtitle_1,
    subtitle_1_cno,
    subtitle_2,
    step_label
  } = messages

  const title = isCNO ? page_title_cno : page_title
  const subtitle = isCNO ? subtitle_1_cno : subtitle_1

  return {
    title: <FormattedMessage {...title} />,
    subtitle: [
      <FormattedMessage {...subtitle} key="subtitle_1" />,
      <FormattedMessage {...subtitle_2} key="subtitle_2" />
    ],
    label: <FormattedMessage {...step_label} />,
  }
}
