import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import { defineMessages } from 'react-intl'

const bem = bemClassName.bind(null, 'form__review__step')

const messages = defineMessages({
  review_title: {
    defaultMessage: 'Fields that need to be reviewed',
    id: 'register.review.title'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { reviewFields, shouldReviewPerson } = injection

  return {
    id: 'review',
    node: (
      <Review
        intl={intl}
        messages={messages}
        reviewFields={reviewFields}
        shouldReviewPerson={shouldReviewPerson}
      />
    )
  }
}

function Review({ intl, messages, reviewFields, shouldReviewPerson }) {
  if (!shouldReviewPerson) return null

  return (
    <div className={bem()}>
      <h3>{intl.formatMessage(messages.review_title)}</h3>
      <div className={bem('container')}>
        {reviewFields.reduce(reduceFieldsReview, [])}
      </div>
    </div>
  )
}

function buildField(field, index) {
  const { title, fields = [] } = field

  return (
    <div key={index} className={bem('content')}>
      {renderTitle(title)}
      {fields.reduce(reduceFields, [])}
    </div>
  )
}

function renderTitle(title) {
  if (!title) return

  return <h3 className={bem('title')}>{title}</h3>
}

function reduceFields(accum, { label, value }) {
  return value ? [...accum, renderContent({ label, value })] : accum
}

function renderContent({ label, value }) {
  return (
    <span key={label}>
      <strong>{label}</strong>: {value}
    </span>
  )
}

function reduceFieldsReview(accum, field, index) {
  return [...accum, buildField(field, index)]
}

Review.propTypes = {
  intl: PropTypes.object,
  messages: PropTypes.object,
  reviewFields: PropTypes.arrayOf(PropTypes.object),
  shouldReviewPerson: PropTypes.bool
}

renderContent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}
