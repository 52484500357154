import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import { getFileTitles, getFileSubtitle, getErrorMessage } from './Messages'

const CARD_FRONT_ID = 'identificationCardFront'
const CARD_BACK_ID = 'identificationCardBack'

export function buildFormSchema(dependencies) {
  const { intl } = dependencies
  const autoFocus = { autoFocus: true }
  const { fileBack, fileFront } = getFileTitles(dependencies)
  const { fileSubtitle } = getFileSubtitle(dependencies)
  const { fileErrorMessage } = getErrorMessage(dependencies)

  const IDENTIFICATION_CARD_FRONT = TYPES.IDENTIFICATION_CARD_OCR(intl, {
    ...dependencies,
    autoFocus,
    cardId: CARD_FRONT_ID,
    subtitle: fileSubtitle,
    title: fileFront,
    errorMessage: fileErrorMessage
  })
  const IDENTIFICATION_CARD_BACK = TYPES.IDENTIFICATION_CARD_OCR(intl, {
    ...dependencies,
    cardId: CARD_BACK_ID,
    subtitle: fileSubtitle,
    title: fileBack,
    errorMessage: fileErrorMessage
  })

  const OCR = TYPES.OCR(intl, { ...dependencies })

  const fieldsSchema = [
    [IDENTIFICATION_CARD_FRONT],
    [IDENTIFICATION_CARD_BACK],
    [OCR]
  ]

  return new FormSchema({ fieldsSchema, name: 'Documents' })
}
