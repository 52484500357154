import FormSchema from 'src/base/Form/Schemas'
import { getFieldsSchema } from './strategies/getFieldsSchema/getFieldsSchema'

export const touchAfterReload = []

export function buildFormSchema(dependencies) {
  const { intl, locale: {id: localeId } } = dependencies

  const defaultFieldsSchema = []
  const additionalFieldsSchema = getFieldsSchema(localeId, {
    intl,
    dependencies
  })

  const fieldsSchema = [...defaultFieldsSchema, ...additionalFieldsSchema]

  return new FormSchema({ fieldsSchema, name: 'PreRegister' })
}
