import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Form from 'src/base/Form'
import Modal from 'src/base/Modal'
import RegisterErrorModal from 'src/views/Register/RegisterErrorModal'

import { PERSON_FIND_ERROR } from 'src/reducers/Person/Person.find'
import { APP_ROUTES } from 'src/App'

class FormWithPersonExists extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      shouldVerifyPersonExistency: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.verifyPersonExistency = this.verifyPersonExistency.bind(this)
  }

  onSubmit(data) {
    return this.props.onSubmit(data, this.verifyPersonExistency)
  }

  onConfirm() {
    const { personVerify: { personId }, history } = this.props

    return history.push(APP_ROUTES.PROFILE.replace(':personId', personId))
  }

  onCancel() {
    const { personVerify } = this.props

    personVerify.reset()
    this.setState({ shouldVerifyPersonExistency: false })
  }

  verifyPersonExistency(result) {
    if(result.type === PERSON_FIND_ERROR)
      return null

    const { goToNextPage, personVerify } = this.props

    if(personVerify.exists)
      return this.setState({ shouldVerifyPersonExistency: true })

    return goToNextPage()
  }

  get title() {
    const { intl, messages, connectivity: { isOffline } } = this.props

    if (isOffline)
      return intl.formatMessage(messages.modal_title_person_local_exists)

    return  intl.formatMessage(messages.modal_title_person_exists)
  }

  render() {
    return (
      <Fragment>
        {this.renderForm()}
        {this.renderVerifyPersonExists()}
      </Fragment>
    )
  }

  renderForm() {
    const {
      error,
      disabledSubmit,
      formSchema,
      goToPreviousPage,
      step: { name, actions: { previous, next }, title, subtitle},
      touchAfterReload,
    } = this.props

    return (
      <Form
        cancelAction={previous ? goToPreviousPage : null}
        className={`${name}__form`}
        disabledSubmit={disabledSubmit}
        error={error}
        formSchema={formSchema}
        hiddenSubmit={!next}
        key={name}
        modal={RegisterErrorModal}
        onSubmit={this.onSubmit}
        subtitle={subtitle}
        title={title}
        touchAfterReload={touchAfterReload}
      />
    )
  }

  renderVerifyPersonExists() {
    const { intl, messages } = this.props
    const { shouldVerifyPersonExistency } = this.state

    if(!shouldVerifyPersonExistency)
      return null

    return (
      <Modal
        acceptLabel={intl.formatMessage(messages.modal_accept_person_exists)}
        cancel={true}
        cancelLabel={intl.formatMessage(messages.modal_cancel_person_exists)}
        key="verifyPersonExistency"
        message={intl.formatMessage(messages.modal_message_person_exists)}
        onCancel={this.onCancel}
        onClose={this.onConfirm}
        open={true}
        title={this.title}
      />
    )
  }
}

FormWithPersonExists.propTypes = {
  className: PropTypes.string,
  connectivity: PropTypes.object.isRequired,
  disabledSubmit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  formSchema: PropTypes.object.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object,
  messages: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  peopleRegister: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personVerify: PropTypes.object,
  step: PropTypes.object.isRequired,
  subtitle: PropTypes.any,
  title: PropTypes.any,
  touchAfterReload: PropTypes.array.isRequired,
}

export default FormWithPersonExists
