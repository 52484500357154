export const PERSON_NOT_FOUND_CODE = 'PR-0003'
export const EXISTS_ACTIVE_PERSON_CODE = 'P-0002'
export const DOCUMENT_ALREADY_EXISTS_CODE = 'DOC-0001'
export const EMAIL_ALREADY_EXISTS_CODE = 'PR-0002'

export const errorsByCodeEnum = {
  [EMAIL_ALREADY_EXISTS_CODE]: {
    title: 'modal_error_title_email_already',
    message: 'modal_error_email_already'
  },
  'PR-0001': {
    message: 'modal_error_invalid_arguments',
  },
  'PR-0002': {
    message: 'modal_error_email_already',
  },
  'TNT-0003': {
    message: 'modal_error_tenant_id',
  },
  'QR-0001': {
    message: 'modal_error_invalid_user_id'
  },
  [PERSON_NOT_FOUND_CODE]: {
    message: 'modal_error_exists_active_person'
  },
  'TNT-0002': {
    message: 'modal_error_tenant_logic_failed'
  },
  'TNT-0001': {
    message: 'modal_error_tenant_not_found'
  },
  'TP-0001': {
    message: 'modal_error_type_error'
  },
  'ALLOC-0001': {
    message: 'modal_error_allocation_does_not_exist'
  },
  'CPE-0001': {
    message: 'modal_error_create_person_validation'
  },
  'GR-0001': {
    message: 'modal_error_cannot_get_data_from_gera'
  },
  'STS-0001': {
    message: 'modal_error_invalid_status'
  },
  'SBS-0001': {
    message: 'modal_error_invalid_substatus'
  },
  'CI-0001': {
    message: 'modal_error_cannot_update_contact_information'
  },
  'CMM-0001': {
    message: 'modal_error_fail_connect_with_cmm'
  },
  'PR-0009': {
    message: 'modal_error_person_role_already_ceased'
  },
  'PR-0010': {
    title: 'attention_title',
    message: 'modal_error_person_has_debts'
  },
  'PR-0012': {
    message: 'modal_error_person_role_already_exists'
  },
  'PR-0028': {
    message: 'modal_error_person_role_already_active'
  },
  'PR-0004': {
    message: 'modal_error_person_role_not_active'
  },
  'PR-0008': {
    message: 'modal_error_person_role_not_found'
  },
  'BL-0001': {
    message: 'modal_error_person_role_blacklisted'
  },
  [DOCUMENT_ALREADY_EXISTS_CODE]: {
    title: 'attention_title',
    message: 'modal_error_document_already_exists'
  },
  'CT-0001': {
    title: 'attention_title',
    message: 'modal_error_invalid_captcha_token'
  },
  'ISS-0001': {
    message: 'modal_error_invalid_status_substatus_for_approval'
  },
  'ISS-0002': {
    message: 'modal_error_invalid_status_substatus_for_rejection'
  },
  'RNF-0001': {
    message: 'modal_error_rejection_reason_not_found'
  },
  '36': {
    message: 'modal_error_snac_message'
  },
  'DA-0001': {
    message: 'Document attachment not found'
  },
  'DA-0002': {
    message: 'Document attachment validation not found'
  },
  'TE-0002': {
    message: 'invalid_tenant_application_messages_for_creat_person'
  },
  [EXISTS_ACTIVE_PERSON_CODE]: {
    message: 'modal_error_person_already_active'
  }
}

export const errorsByStatusEnum = {
  500: {
    title: 'error_title',
    message: 'modal_error_system_unavailable'
  },
  502: {
    title: 'error_title',
    message: 'error_forwarding_call_to_back_end_server'
  }
}

export const fieldErrorsEnum = {
  35: 'documents.fields[0].document',
  36: 'documents.fields[0].document',
  14: 'emails.fields[0].email',
  [EMAIL_ALREADY_EXISTS_CODE]: 'emails.fields[0].email',
  'DOC-0001': 'ruc'
}

export const fieldTouchedEnum = {
  14: 'emails[0].email',
  [EMAIL_ALREADY_EXISTS_CODE]: 'emails.[0].email',
  'DOC-0001': 'ruc'
}
