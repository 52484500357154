import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

export const touchAfterReload = []

const HIDDEN_BY = ['digitalSpace']

export function buildFormSchema(dependencies) {
  const { intl } = dependencies

  const DIGITALSPACE_DESCRIPTION = TYPES.DIGITALSPACE_DESCRIPTION(intl, dependencies)
  const DIGITALSPACE = TYPES.DIGITALSPACE(intl, dependencies)
  const EMAIL = commonParams(TYPES.EMAIL(intl, dependencies))
  const PHONE = commonParams(TYPES.PHONE(intl, dependencies))

  const fieldsSchema = [
    [DIGITALSPACE_DESCRIPTION],
    [DIGITALSPACE],
    [EMAIL],
    [PHONE]
  ]

  return new FormSchema({ fieldsSchema, name: 'DigitalSpace' })
}

function commonParams(field, required = true, hiddenBy = HIDDEN_BY) {
  return {
    ...field,
    hiddenBy,
    required
  }
}
