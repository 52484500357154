import { getContext } from 'src/infra/Api/Api.context'

import User from 'src/models/User'
import Login from 'src/infra/login/login.command'

export const LOGIN_LOADING = 'natura-caduni/User/LOGIN_LOADING'
export const LOGIN_SUCCESS = 'natura-caduni/User/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'natura-caduni/User/LOGIN_ERROR'

export const types = [LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_ERROR]

export const UNAUTORIZED_STATUS = 403

export function userLoginReducer(state, action, dependencies) {

  if (action.type === LOGIN_LOADING) {
    return {
      ...state,
      loading: true
    }
  }

  if (action.type === LOGIN_ERROR) {
    return {
      ...state,
      error: {
        ...action.error,
        timestamp: + new Date()
      },
      loading: false
    }
  }

  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      loading: false,
      error: false,
      user: new User(action.payload.user, dependencies),
    }
  }

  return state
}

const dependencies = {
  Login
}

export async function login(data, keycloakParsed) {
  const { Login } = Object.assign({}, dependencies)
  const { dispatch, locale: { id, configs: { localization: { authenticationLegacy } } } } = getContext()

  dispatch({ type: LOGIN_LOADING })

  const { sellerId, personId, authentication } = getAuthenticationData({ data, keycloakParsed, authenticationLegacy })

  const login = Login.create(id)

  const user = await login.execute({
    localeId: id,
    personId,
    sellerId,
    accessToken: authentication.accessToken,
    xApikey: authentication.xApikey
  })

  if (user.error) {
    const { message } = user.error

    return dispatch({
      type: LOGIN_ERROR,
      error: { message }
    })
  }

  localStorage.setItem('sessionIdentifier', sellerId)

  return dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      user: {
        ...user,
        personId: personId,
        authentication
      }
    }
  })
}

function getAuthenticationData({ data, keycloakParsed, authenticationLegacy }) {
  // TODO: remove authentication legacy flow
  if (authenticationLegacy) {
    return {
      sellerId: data.login,
      authentication: {
        xApikey: process.env.GLOBAL_PEOPLE_X_API_KEY
      }
    }
  }

  return {
    personId: keycloakParsed.personId,
    sellerId: keycloakParsed.sellerId,
    authentication: {
      accessToken: `Bearer ${data.token}`,
      expiresIn: keycloakParsed.expiresIn
    }
  }
}

export function loginWithCognito(user, tokenData) {
  const { dispatch } = getContext()

  localStorage.setItem('sessionIdentifier', user.sellerId)

  return dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      user: {
        ...user,
        authentication: {
          xApikey: process.env.GLOBAL_PEOPLE_X_API_KEY,
          accessToken: `Bearer ${tokenData.accessToken}`,
          expiresIn: tokenData.expiresIn
        }
      }
    }
  })
}
