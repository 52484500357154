import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  indication_search: {
    defaultMessage: 'Search',
    id: 'form.field.indication_search'
  },
  indication_search_error: {
    defaultMessage: 'Please enter a valid flag and click on search',
    id: 'form.field.indication_search_error'
  },
  indication_modal_error_api: {
    defaultMessage: 'We do not find this person in the base of records of nature',
    id: 'form.field.indication_modal_error_api'
  },
  indication_modal_title_attention: {
    defaultMessage: 'Attention',
    id: 'form.field.indication_title_attention'
  },
  indication_modal_title_select: {
    defaultMessage: 'Select the indicator',
    id: 'form.field.indication_title_select'
  },
  indication_modal_button_comfirm: {
    defaultMessage: 'Confirm',
    id: 'form.field.indication_button_comfirm'
  },
  indication_modal_button_accept: {
    defaultMessage: 'Ok',
    id: 'form.field.indication_button_ok'
  },
  indication_modal_button_cancel: {
    defaultMessage: 'Cancel',
    id: 'form.field.indication_button_cancel'
  },
  indication_modal_header_introducer: {
    defaultMessage: 'Introducer',
    id: 'form.field.indication_header_introducer'
  },
  indication_modal_header_code: {
    defaultMessage: 'Code',
    id: 'form.field.indication_header_code'
  },
  indication_search_error_size: {
    defaultMessage: 'Must have at least {min} characters',
    id: 'form.field.indication_search_error_size'
  },
  indication_error_required: {
    defaultMessage: 'Required field',
    id: 'form.field.indication.error.required'
  },
  introducerSellerId_indication_default_title: {
    defaultMessage: 'Select the person that has indicated you',
    id: 'form.field.introducerSellerId_indication_default_title'
  },
  button_load_more_loading: {
    defaultMessage: 'Loading...',
    id: 'dashboard.myregisters.button.load.more.loading'
  },
  button_load_more: {
    defaultMessage: 'Load more',
    id: 'dashboard.myregisters.button.load.more'
  },
  label_indication_code: {
    defaultMessage: 'Consultant code that indicated you',
    id: 'form.field.indication_label_code'
  },
  indication_name_label: {
    defaultMessage: 'You were indicated by:',
    id: 'form.field.indication_name_label'
  }
})

export default MESSAGES
