import { getDefaultSubstatus } from './getSubstatus.default'
import { getMalaysiaSubstatus } from './getSubstatus.mys'
import { getPeruSubstatus } from './getSubstatus.per'
import { getColombiaSubstatus } from './getSubstatus.col'

const strategies = {
  MYS: getMalaysiaSubstatus,
  PER: getPeruSubstatus,
  COL: getColombiaSubstatus,
  __default__: getDefaultSubstatus
}

export const getSubstatus = (localeId, error) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy(error)
}
