import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import IconCustom from './IconCustom'

import './Icon.styl'

class Icon extends PureComponent {
  render() {
    const { name, className, custom, size, ...rest } = this.props
    if (custom) return <IconCustom name={name} className={className} {...rest} />

    try {
      const icon = require(`src/assets/svgs/${name}.svg`)

      return (
        <i className={classNames('icon', className)} {...rest}>
          <svg width={size} height={size} viewBox={icon.default.viewBox} className="icon__svg">
            <use xlinkHref={`#${icon.default.id}`} />
          </svg>
        </i>
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('SVG Icon error: ', e)
      return null
    }
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  custom: PropTypes.bool
}

Icon.defaultProps = {
  custom: false,
  size: '100%'
}

export default Icon
