import React, { Fragment, PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import TagManager from 'react-gtm-module'

import Modal from 'src/base/Modal'
import Avatar from '@material-ui/core/Avatar'

import Checkbox from 'src/base/Checkbox'
import Loader from 'src/base/Loader'
import MESSAGES from './Indication.messages'

import './Indication.styl'

const bem = bemClassName.bind(null, 'indication_default__modal')

export class IndicationModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      indication: {},
      checkedIndication: {},
    }

    this.onConfirm = this.onConfirm.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.handleChecked = this.handleChecked.bind(this)
  }

  onCancel() {
    this.setState({ checkedIndication: {} }, this.handleCancel)
  }

  onConfirm() {
    this.setState(({ indication: indicationValue }) => {
      const indication = !this.error ? indicationValue : {}

      if(indication.name) {
        this.setDataLayer(indication.name)
      }

      return {
        indication,
        checkedIndication: {}
      }
    }, this.handleConfirm)
  }

  handleCancel() {
    const { consultants, onCancel } = this.props

    consultants.reset()
    onCancel(false)
  }

  handleConfirm() {
    const { onConfirm, consultants } = this.props

    consultants.reset()
    onConfirm(false, this.state.indication)
  }

  handleChecked(introducer) {
    this.setState( ({ checkedIndication: checked }) => {

      const { naturaCode } = introducer
      const lastState = checked[naturaCode]

      const indication = !lastState ? introducer : {}
      const checkedIndication = { [naturaCode]:  !lastState }

      return {
        indication,
        checkedIndication
      }})
  }

  initials(name) {
    if (!name) return null

    const names = name.trim().split(' ')
    const firstName = names[0]
    const lastName = names.length > 1 ? names[1] : ''

    const initailFirstName = firstName.charAt()
    const initailLastName = lastName.charAt()

    return `${initailFirstName}${initailLastName}`
  }

  get title() {
    const { loading, intl, messages } = this.props

    if (loading)
      return intl.formatMessage(messages.button_load_more_loading)

    if (this.error)
      return intl.formatMessage(messages.indication_modal_title_attention)

    return intl.formatMessage(messages[`introducerSellerId_indication_default_title`])
  }

  get acceptLabel() {
    const { intl, messages } = this.props

    if (this.error)
      return intl.formatMessage(messages.indication_modal_button_accept)

    return intl.formatMessage(messages.indication_modal_button_comfirm)
  }

  get disabledConfirm() {
    const { checkedIndication, indication: { id } } = this.state

    return !checkedIndication[id] && !this.error
  }

  get error() {
    const { consultants, error } = this.props

    return error || !consultants.items.length
  }

  setDataLayer(contextText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationIndicationSelected',
        contextText
      }
    })
  }

  render() {
    const { showModal, loading, intl, messages } = this.props

    return (
      <Modal
        className={bem()}
        key="errorModal"
        title={this.title}
        open={showModal}
        accept={!loading}
        acceptLabel={this.acceptLabel}
        onClose={this.onConfirm}
        onCancel={this.onCancel}
        cancelLabel={intl.formatMessage(messages['indication_modal_button_cancel'])}
        cancel={!this.error}
        disabledConfirm={this.disabledConfirm}>
        {this.renderBody()}
      </Modal>
    )
  }

  renderBody() {
    return (
      <div className={bem('wrapper')}>
        {this.renderLoader()}
        {this.renderError()}
        {this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const { consultants } = this.props

    if (!consultants.items.length) return

    return (
      <Fragment>
        <div className={bem('body')}>
          {consultants.items.length > 0 && this.renderModalItem(consultants.items[0])}
        </div>
      </Fragment>
    )
  }

  renderLoader() {
    const { loading } = this.props

    if (!loading) return

    return <Loader className={bem('loader')} />
  }

  renderError() {
    const { intl, messages, loading } = this.props

    if (loading || !this.error) return

    return (
      <span className={bem('error')}>
        {intl.formatMessage(messages.indication_modal_error_api)}
      </span>
    )
  }

  renderModalItem(introducer) {
    const { intl, messages } = this.props

    return (
      <div key={introducer.naturaCode} className={bem('body__item')}>
        <div className={bem('body__item__content', ['description'])}>
          <Avatar className={bem('body__item__content', ['avatar'])}>
            {this.initials(introducer.name)}
          </Avatar>
          <span className={bem('body__item__content', ['name'])}>
            {introducer.name}
            <span className={bem('body__item__content', ['code'])}>
              {`${intl.formatMessage(messages.indication_modal_header_code)}:
                ${introducer.naturaCode}`}
            </span>
          </span>
        </div>
        <div className={bem('body__item__content', ['checkbox'])}>
          <Checkbox
            onChange={() => this.handleChecked(introducer)}
            checked={!!this.state.checkedIndication[introducer.id]}
          />
        </div>
      </div>
    )
  }
}

IndicationModal.propTypes = {
  showModal: PropTypes.bool,
  messages: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onCheck: PropTypes.func,
  consultants: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedIntroducer: PropTypes.any,
  intl: PropTypes.any,
  personId: PropTypes.string,
  search: PropTypes.string,
  id: PropTypes.string,
}

IndicationModal.defaultProps = {
  introducers: [],
  messages: MESSAGES
}


export default injectIntl(IndicationModal)
