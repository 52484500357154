import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

const htmlPropsDefault = {
  autoComplete: 'no',
  autoFocus: false,
  tabIndex: '0'
}

export default class FieldSchema extends Entity {
  static SCHEMA = {
    id: adapter(yup.string().required()),
    label: adapter(yup.string()),
    sublabel: adapter(yup.string()),
    message: adapter(yup.string().nullable()),
    group: adapter(yup.object().shape({
      id: yup.string(),
      index: yup.number()
    }).nullable()),
    success: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    type: {
      validator: adapter(yup.string()),
      defaultValue: 'text'
    },
    offlineType: adapter(yup.string().nullable()),
    offlineLabel: adapter(yup.string()),
    defaultValue: adapter(yup.string()),
    mask: adapter(yup.mixed().nullable()),
    steps: adapter(yup.array().of(yup.object())),
    placeholder: adapter(yup.mixed().nullable()),
    noOptionsMessage:adapter(yup.mixed().nullable()),
    required: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    showAsterisk: {
      validator: adapter(yup.boolean()),
      defaultValue: true
    },
    isSearchable: {
      validator: adapter(yup.boolean()),
      defaultValue: true
    },
    isClearable: {
      validator: adapter(yup.boolean()),
      defaultValue: true
    },
    disabled: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    disabledBy: {
      validator: adapter(yup.array()),
      defaultValue: []
    },
    hidden: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    hiddenBy: {
      validator: adapter(yup.array()),
      defaultValue: []
    },
    customProps: {
      validator: adapter(yup.object()),
      defaultValue: {}
    },
    options: adapter(yup.array().nullable()),
    node: adapter(yup.object().nullable()),
    validations: adapter(yup.object().nullable()),
    htmlProps: {
      validator: adapter(yup.object().shape({
        autoComplete: yup.string(),
        autoFocus: yup.boolean(),
        tabIndex: yup.string(),
        accept: adapter(yup.string())
      })),
      defaultValue: htmlPropsDefault,
      builder: function(data) {
        return { ...htmlPropsDefault, ...data }
      }
    },
    onKeyDown: adapter(yup.mixed().nullable()),
    onPaste: adapter(yup.mixed().nullable()),
    onBlur: adapter(yup.mixed().nullable()),
    onChange: adapter(yup.mixed().nullable()),
    disableBackdropClick: {
      validator: adapter(yup.boolean()),
      defaultValue: true
    }
  }

  constructor(props) {
    super(props)

    this.applyRequiredValidation()
    this.validate = this.validate.bind(this)
  }

  get groupId() {
    if(!this.group) return null

    return `${this.parent}.${this.id}`
  }

  get parent() {
    if(!this.group) return null

    const { group: { id, index } } = this

    return `${id}[${index}]`
  }

  applyRequiredValidation() {
    if(!this.validations && !this.required) return

    if(this.required) {
      const schema = this.validations ? this.validations : yup.mixed()

      return Object.assign(this, { validations: schema.required() })
    }

    const { _exclusive: { required } = {}} = this.validations || {}

    return Object.assign(this, { required: !!required })
  }

  validate(value, { values, errors }) {
    try {
      this.validations && this.validations.validateSync(value, { context: { values, errors } })
      return false
    }
    catch(e) {
      return e.message
    }
  }
}
