import Personal from './Policy.personal'
import Authorization from './Policy.authorization'
import ConditionsPrivacy from './Policy.conditions.privacy'
import DigitalInvoice from './Policy.digital.invoice'

export default {
  Personal,
  Authorization,
  ConditionsPrivacy,
  DigitalInvoice
}
