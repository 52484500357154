import { defineMessages } from 'react-intl'

export default defineMessages({
  successAcceptLabel: {
    defaultMessage: 'Ok',
    id: 'ocr.modal.accept.label.success'
  },
  errorAcceptLabel: {
    defaultMessage: 'Ok',
    id: 'ocr.modal.accept.label.error'
  },
  expiredAcceptLabel: {
    defaultMessage: 'Ok',
    id: 'ocr.modal.accept.label.expired'
  },
  progressAcceptLabel: {
    defaultMessage: 'Cancel',
    id: 'ocr.modal.cancel.label.progress'
  },
  successTitle: {
    defaultMessage: 'Approved documentation',
    id: 'ocr.modal.success.title'
  },
  successContent: {
    defaultMessage: 'The sent document was validated successfully.',
    id: 'ocr.modal.success.content'
  },
  errorTitle: {
    defaultMessage: 'Invalid documentation',
    id: 'ocr.modal.invalid.title'
  },
  errorContent: {
    defaultMessage: 'We are sorry but the documentation has not been validated. Please make sure that the image has good lighting and the document presented corresponds to the same document number reported in the record, then try again.',
    id: 'ocr.modal.invalid.content'
  },
  expiredTitle: {
    defaultMessage: 'Service not available',
    id: 'ocr.modal.expired.title'
  },
  expiredContent: {
    defaultMessage: 'Please, try again later.',
    id: 'ocr.modal.expired.content'
  },
  progressTitle: {
    defaultMessage: 'Document validation',
    id: 'ocr.modal.progress.title'
  },
  progressContent: {
    defaultMessage: 'Wait while we validate the sent document.',
    id: 'ocr.modal.progress.content'
  },
})

