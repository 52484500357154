import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Modal from 'src/base/Modal'
import Icon from 'src/base/Icon'
import MESSAGES from 'src/base/ContactValidation/ContactValidation.messages'
import SmsTokenModalContent from 'src/base/ContactValidation/Sms'
import EmailModalContent from 'src/base/ContactValidation/Email'

import './ContactValidation.styl'

const bem = bemClassName.bind(null, 'contact-validation')

class ContactValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showVerifyTokenModal: false,
      showResendEmailModal: false
    }
  }

  openVerifyTokenModal = () => {
    this.setState({ showVerifyTokenModal: true })
  }

  openResendEmailModal = () => {
    this.setState({ showResendEmailModal: true })
  }

  closeModal = () => {
    this.setState({ showVerifyTokenModal: false, showResendEmailModal: false })
  }

  render() {
    return (
      <Fragment>
        {this.renderPhoneLabel()}
        {this.renderEmailLabel()}
        {this.renderVerifyTokenModal()}
        {this.renderResendEmailModal()}
      </Fragment>
    )
  }

  renderPhoneLabel(){
    const { phone, substatus: { pendingEmailVerification } } = this.props

    if (!phone || phone.isLine) return null

    const { verified } = phone

    if(verified && !pendingEmailVerification) {
      return (
        <Icon className={classNames(bem('icon'))} name="ui_checked" />
      )
    }

    return (
      <a
        className={classNames(bem('link'))}
        onClick={this.openVerifyTokenModal}
        data-testid="contact-validation-sms-link">
        <FormattedMessage {...MESSAGES.validate_contact} />
      </a>
    )
  }

  renderEmailLabel(){
    const { email, substatus: { pendingEmailVerification } } = this.props

    if (!email) return null

    const { verified } = email

    if (verified && !pendingEmailVerification) {
      return (
        <Icon className={classNames(bem('icon'))} name="ui_checked" />
      )
    }

    return (
      <a
        className={classNames(bem('link'))}
        onClick={this.openResendEmailModal}
        data-testid="contact-validation-sms-link">
        <FormattedMessage {...MESSAGES.validate_contact} />
      </a>
    )
  }

  renderVerifyTokenModal() {
    const { phone } = this.props
    const { showVerifyTokenModal } = this.state
    if (!phone) return

    return (
      <Modal open={showVerifyTokenModal} accept={false}>
        <SmsTokenModalContent
          phone={phone}
          closeModal={this.closeModal}
        />
      </Modal>
    )
  }

  renderResendEmailModal() {
    const { email } = this.props
    const { showResendEmailModal } = this.state
    if(!email) return

    return (
      <Modal
        className={bem('resendEmail')}
        open={showResendEmailModal}
        accept={false}
      >
        <EmailModalContent
          email={email}
          closeModal={this.closeModal}
        />
      </Modal>
    )
  }
}

ContactValidation.propTypes = {
  phone: PropTypes.object,
  email: PropTypes.object,
  substatus: PropTypes.object
}

export default ContactValidation
