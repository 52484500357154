export const ROUTES = {
  BASE: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  COUNTRY_SELECTION: '/country-selection',
  EMAIL_VALIDATION: '/register/confirmation/email',
  SELF_REGISTER_WITH_COUNTRY: '/:country/auto',
  SELF_REGISTER: '/auto',
  DASHBOARD: '/dashboard',
  REGISTER: '/dashboard/register',
  PERSONAL_DATA: '/dashboard/register/personal-data',
  REGISTER_LIST: '/dashboard/my-registers',
  PROFILE: '/dashboard/my-registers/show/:personId',
  MAINTENANCE: '/webfv/registration/maintenance',
  SSO: '/sso',
  EDIT_PERSON: '/edit-person/:personId',
  AUTO_PERSONAL_DATA: '/auto/personal-data',
  DIGITAL_STORE_STATUS: '/digital-store-status',
  PAYMENT_ACCOUNT_STATUS: '/:country/payment-account/:status',
  BRAZIL_DIGITAL_STORE_RENAME: '/alteracao-espaco-digital/:personId',
  AUTH: '/auth',
  MAINTENANCE_CONTACTS: '/maintenance-contacts'
}
