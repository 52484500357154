const GEOGRAPHICAL_LEVELS_BFF_URL = process.env.GEOGRAPHICAL_LEVELS_BFF_URL
const GEOGRAPHICAL_LEVELS_BFF_X_API_KEY = process.env.GEOGRAPHICAL_LEVELS_BFF_X_API_KEY
const URL_GLOBAL_REGISTERS_BFF = process.env.REGISTERS_URL
const REGISTERS_X_API_KEY = process.env.REGISTERS_X_API_KEY

export const BFF_APIKEYS = {
  ADDRESS_APIKEY: GEOGRAPHICAL_LEVELS_BFF_X_API_KEY,
  REGISTERS_APIKEY: REGISTERS_X_API_KEY
}

export const BFF_URLS = {
  ADDRESS_BFF: (params = '') =>
    `${GEOGRAPHICAL_LEVELS_BFF_URL}/commercial-structure/geographic-levels?${params}`,
  ALLOCATE_PERSON: (personId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/allocate-commercial-structure`,
  COMPLETE_ADDRESS_STEP: (personId) => `${URL_GLOBAL_REGISTERS_BFF}/address/complete/${personId}`,
}

export default {
  urls: BFF_URLS,
  apiKeys: BFF_APIKEYS,
}
