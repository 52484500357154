import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const ADDRESS_USE = {
  DELIVERY: 1,
  MAILLING: 2,
  ALTERNATIVE: 3
}

export const MAILING_ADDRESS = { id: ADDRESS_USE.MAILLING, description: 'Mailing Address' }
export const DELIVERY_ADDRESS = { id: ADDRESS_USE.DELIVERY, description: 'Delivery Address' }
export const ALTERNATIVE_ADDRESS = { id: ADDRESS_USE.ALTERNATIVE, description: 'Eventual Address' }

export default class AddressUse extends Entity {
  static SCHEMA = {
    id: adapter(yup.string().required()),
    description: adapter(yup.string().required())
  }
}
