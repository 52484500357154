import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const CNHIcon = ({ size, state }) => {
  const styles = {
    st0: {
      fill: StateColors[state]
    },
    st1: {
      fill: StateColors[state],
      opacity: 0.2
    },
    st2: {
      fill: 'none',
      stroke: StateColors[state],
      strokeMiterlimit: 10
    }
  }

  return (
    <svg width={size} height={size} viewBox="0 0 261.7 208.2">
      <g>
        <g>
          <path
            style={styles.st0}
            d="M35.4,14.1L35.4,14.1c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v10.1C37.4,13.2,36.5,14.1,35.4,14.1z"
          />
          <path
            style={styles.st0}
            d="M17.4,15.2L17.4,15.2c-0.8,0.8-2.1,0.8-2.8,0L7.4,8C6.7,7.2,6.7,6,7.4,5.2l0,0c0.8-0.8,2.1-0.8,2.8,0l7.2,7.2C18.2,13.1,18.2,14.4,17.4,15.2z"
          />
          <path
            style={styles.st0}
            d="M14.1,33.9L14.1,33.9c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2l0,0c0-1.1,0.9-2,2-2h10.1C13.2,31.9,14.1,32.8,14.1,33.9z"
          />
        </g>
        <g>
          <path
            style={styles.st0}
            d="M104.6,104.8l-48.2-4.5c-3.3-0.3-6.1,2.1-6.5,5.4l-5.7,61.1c5.4-4.6,13.9-6.3,18.4-6.9c0.4-0.1,0.7-0.4,0.8-0.8l0.3-2.9l-0.1-0.2c-1.3-2.1-2.2-4.4-2.7-6.9l0-0.2l-0.1-0.1c-1.5-1.8-2-4.5-2.2-5.9c-0.4-2.3-0.8-5.6,1.1-7.7l0.3-0.3l0-0.3c-0.1-0.7-0.1-1.4-0.1-2.2c-0.3-5.5,0.7-9.5,2.9-11.7c1.2-1.2,2.7-1.9,4.4-2l0.5,0l0.2-0.4c1.3-3.3,6.6-6.3,11-5c4.3,1.3,12.9,1.3,14.5,1.3c-0.1,1-0.6,1.9-1.4,2.6l-0.7,0.6l0.7,0.5c7,5.7,4.9,15.6,3.7,19.7l-0.1,0.3l0.2,0.4c1.5,2.4,0.5,5.6-0.3,7.8c-0.5,1.4-1.5,3.9-3.3,5.4l-0.1,0.1l-0.1,0.2c-0.9,2.3-2.2,4.4-3.9,6.2l-0.2,0.2l-0.3,2.9c0,0.4,0.2,0.8,0.6,0.9c4.1,1.4,11.6,4.4,16.2,9.5l5.6-60.4C110.2,108,107.8,105.1,104.6,104.8z"
          />
          <path
            style={styles.st1}
            d="M43.2,42.4c0.3-2.9,2.8-5,5.7-4.7l204.4,19l0.1-4.1c-0.7-0.3-1.4-0.5-2.2-0.6L44.6,32.8c-3.7-0.3-7,2.4-7.3,6.1l-6.6,71.3L28.9,173c-0.1,1.8,0.6,3.5,1.7,4.7L43.2,42.4z"
          />
          <path
            style={styles.st0}
            d="M29.1,177.9L41.7,42.3c0.3-3.7,3.6-6.4,7.3-6.1l206.6,19.2c3.7,0.3,6.4,3.6,6.1,7.3l-12.6,135.6c-0.3,3.7-3.6,6.4-7.3,6.1L35.2,185.2C31.5,184.9,28.7,181.6,29.1,177.9z M258.7,62.4c0.2-2.1-1.3-3.9-3.4-4.1L48.7,39.2c-2.1-0.2-3.9,1.3-4.1,3.4L32.1,178.1c-0.2,2.1,1.3,3.9,3.4,4.1l206.6,19.2c2.1,0.2,3.9-1.3,4.1-3.4L258.7,62.4z"
          />
          <path
            style={styles.st0}
            d="M87.4,61.4l0-0.3c0.2-1.8,1.8-3.2,3.6-3l155.7,14.8c1.8,0.2,3.2,1.8,3,3.6l0,0.3c-0.2,1.8-1.8,3.2-3.6,3L90.4,65C88.6,64.8,87.2,63.2,87.4,61.4z"
          />
          <path
            style={styles.st2}
            d="M243.6,107.6l-191-18.8c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l191,18.8c1.3,0.1,2.3,1.3,2.1,2.6l-0.9,9.3C246.1,106.7,244.9,107.7,243.6,107.6z"
          />
          <path
            style={styles.st2}
            d="M174.2,123.6l-53.8-5.3c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l53.8,5.3c1.3,0.1,2.3,1.3,2.1,2.6l-0.9,9.3C176.7,122.8,175.5,123.7,174.2,123.6z"
          />
          <path
            style={styles.st2}
            d="M240.4,130.1l-53.8-5.3c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l53.8,5.3c1.3,0.1,2.3,1.3,2.1,2.6L243,128C242.8,129.3,241.7,130.2,240.4,130.1z"
          />
          <path
            style={styles.st2}
            d="M147.4,180.5l-32.8-3.2c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l32.8,3.2c1.3,0.1,2.3,1.3,2.1,2.6l-0.9,9.3C149.9,179.7,148.7,180.6,147.4,180.5z"
          />
          <path
            style={styles.st2}
            d="M191,184.8l-32.8-3.2c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l32.8,3.2c1.3,0.1,2.3,1.3,2.1,2.6l-0.9,9.3C193.5,184,192.3,184.9,191,184.8z"
          />
          <path
            style={styles.st2}
            d="M234.6,189.1l-32.8-3.2c-1.3-0.1-2.3-1.3-2.1-2.6l0.9-9.3c0.1-1.3,1.3-2.3,2.6-2.1l32.8,3.2c1.3,0.1,2.3,1.3,2.1,2.6l-0.9,9.3C237,188.3,235.9,189.2,234.6,189.1z"
          />
          <path
            style={styles.st2}
            d="M236.6,167.9l-119.9-11.8c-1.3-0.1-2.3-1.3-2.1-2.6l2.6-26.5c0.1-1.3,1.3-2.3,2.6-2.1l119.9,11.8c1.3,0.1,2.3,1.3,2.1,2.6l-2.6,26.5C239.1,167.1,237.9,168,236.6,167.9z"
          />

          <ellipse
            transform="matrix(9.239054e-02 -0.9957 0.9957 9.239054e-02 -2.3254 115.39)"
            style={styles.st0}
            cx="62.1"
            cy="59"
            rx="8.9"
            ry="8.9"
          />
          <path
            style={styles.st0}
            d="M80.2,150.5C80.2,150.5,80.2,150.5,80.2,150.5C80.2,150.5,80.2,150.5,80.2,150.5C80.2,150.5,80.2,150.5,80.2,150.5z"
          />
          <path
            style={styles.st0}
            d="M93.2,140.1c-0.3-0.4-0.8-0.7-1.2-0.7c0.4-1.2,1.4-4.8-1.7-7.5l-0.2-0.2l-0.3,0c-2.7,0.3-7.1,0.1-12.8-3c-3.1-1.7-5.8-1.9-8.1-0.8c-3.9,1.9-5.3,7.5-5.6,8.9c-0.5-0.1-1.1,0.1-1.5,0.5c-0.7,0.8-0.9,2.6-0.4,5.2c0.6,3,1.3,4.7,2.2,5c0.3,4.3,3.1,10,8.4,12.4c1.1,0.5,2.3,0.8,3.4,0.9c1.2,0.1,2.4,0,3.6-0.3c5.4-1.4,9.4-6.4,10.5-10.7c1-0.1,2-1.6,3.1-4.6C93.6,142.8,93.8,141.1,93.2,140.1z M70.7,142c-1.1-0.1-1.9-1.1-1.8-2.2c0.1-1.1,1.1-1.8,2.2-1.8c1.1,0.2,2,1.1,1.8,2.3C72.8,141.3,71.8,142.1,70.7,142z M77.8,153.3c-2.6,0.8-5.1-0.8-5.7-3.4c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.3,0.4-0.3c1.4,0.1,2.8,0.3,4.2,0.4c0.9,0.1,1.8,0.2,2.7,0.2c0.3,0,0.8,0,1.1,0.1C81.8,150.7,78.6,153.1,77.8,153.3z M85.7,141.5c-0.2,1.1-1.3,1.8-2.3,1.6c-1-0.2-1.8-1.2-1.6-2.3c0.2-1.1,1.1-1.8,2.2-1.7C85,139.4,85.9,140.4,85.7,141.5z"
          />
          <g>
            <path
              style={styles.st0}
              d="M31.3,175.1l1.1-12.6L36,33.9c0.1-2.1,1.8-3.7,3.9-3.6l207.4,5.9c2.1,0.1,3.7,1.8,3.6,3.9l-0.4,15.5l3,0.3
  l0.4-15.7c0.1-3.7-2.8-6.8-6.6-6.9L40,27.3c-3.7-0.1-6.8,2.8-6.9,6.6L29.2,170C29.1,172,29.9,173.8,31.3,175.1z"
            />
            <path
              style={styles.st0}
              d="M249.5,178.9c0.4-0.8,0.6-1.7,0.6-2.6l0.2-6.9L249.5,178.9z"
            />
          </g>
        </g>
        <g>
          <path
            style={{ fill: '#fff' }}
            d="M185.7,32.6c-11.7-5.4-24.8-8.5-38.6-8.5c-12.1,0-23.7,2.3-34.2,6.6L185.7,32.6z"
          />
          <path
            style={{ fill: '#fff' }}
            d="M88.7,187.4c15.9,13,36.2,20.9,58.4,20.9c15.4,0,29.9-3.8,42.7-10.5L88.7,187.4z"
          />
        </g>
      </g>
    </svg>
  )
}

CNHIcon.defaultProps = {
  withWrapper: false
}

CNHIcon.propTypes = {
  size: PropTypes.number.isRequired,
  withWrapper: PropTypes.bool,
  state: PropTypes.string.isRequired
}

export default CNHIcon
