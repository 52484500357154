import { defineMessages } from 'react-intl'
import { DOCUMENTS } from 'src/models/Document'

const messages = defineMessages({
  type_label: {
    defaultMessage: 'Document',
    id: 'form.field.document.type'
  },
  type_citizen: {
    defaultMessage: 'Citizen Card',
    id: 'form.field.document.type.citizen'
  },
  type_foreign: {
    defaultMessage: 'Foreign Card',
    id: 'form.field.document.type.foreign'
  },
  type_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.document.type.placeholder'
  },
  type_dni: {
    defaultMessage: 'National Registry',
    id: 'form.field.document.type.dni'
  },
  type_ce: {
    defaultMessage: 'Foreign Registry',
    id: 'form.field.document.type.cd'
  },
  type_passport: {
    defaultMessage: 'Passport',
    id: 'form.field.document.type.ptp'
  },
  type_candidate: {
    defaultMessage: 'Candidate',
    id: 'form.field.document.type.candidate'
  },
  type_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.type.no.options.message'
  }
})

const dependencies = {
  messages
}

export const documentsEnum = {
  [DOCUMENTS.CITIZEN_CARD]: 'citizenCard',
  [DOCUMENTS.FOREIGN_CARD]: 'foreignCard',
  [DOCUMENTS.NATIONAL_REGISTRY]: 'nationalRegistry',
  [DOCUMENTS.FOREIGN_REGISTRY]: 'foreignRegistry',
  [DOCUMENTS.PASSPORT_PERU]: 'temporaryPermissionWork',
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id } = {} } = injection
  const options = defineOptionByLocale(intl)[id] || []

  return {
    id: 'type',
    label: intl.formatMessage(messages['type_label']),
    placeholder: intl.formatMessage(messages['type_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['type_no_options_message']),
    type: 'select',
    onChange: cleanDocument,
    options
  }
}

function defineOptionByLocale(intl) {
  return {
    PER: [
      {
        value: 19,
        label: intl.formatMessage(messages['type_dni'])
      },
      {
        value: 33,
        label: intl.formatMessage(messages['type_ce'])
      },
      {
        value: 32,
        label: intl.formatMessage(messages['type_passport'])
      }
    ],
    COL: [
      {
        value: 9,
        label: intl.formatMessage(messages['type_citizen'])
      },
      {
        value: 10,
        label: intl.formatMessage(messages['type_foreign'])
      }
    ]
  }

}

function cleanDocument(props) {
  const { schema: { parent, group: { id } }, setFieldValue, setFieldTouched } = props

  setFieldValue(`${parent}.document`, '', id)
  setFieldTouched(`${parent}.document`, false)
}
