import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import {
  DELIVERY_ADDRESS,
  MAILING_ADDRESS
} from '../../../../../../../../models/Address/AddressUse'

export const getEcuadorParsedData = ({ data }) => ({
  addresses: data.addresses.map(mapAddress)
})

const mapAddress = (address) => {
  const ZIP_CODE_LEVEL = 8
  const zipCode = address.geographicalLevels.find(
    ({ level }) => level === ZIP_CODE_LEVEL
  ).description

  const { lat, lng, sequence } = address.location
  const { geographicalLevels } = address

  const mapedAddress = {
    addressType: ADDRESS_TYPE.MAILING,
    addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS],
    geographicalLevels,
    zipCode,
    latitude: lat,
    longitude: lng
  }

  addSequence(mapedAddress, sequence)

  return mapedAddress
}

const addSequence = (address, sequence = undefined) => {
  if (sequence) {
    address.sequence = sequence
  }
}
