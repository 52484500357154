import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_CEASE_LOADING = 'natura-caduni/People/PROFILE_CEASE_LOADING'
export const PROFILE_CEASE_SUCCESS = 'natura-caduni/People/PROFILE_CEASE_SUCCESS'
export const PROFILE_CEASE_ERROR = 'natura-caduni/People/PROFILE_CEASE_ERROR'

const TYPES_CEASE = [PROFILE_CEASE_LOADING, PROFILE_CEASE_SUCCESS, PROFILE_CEASE_ERROR]

export function ceaseRole(personId, role) {
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  const sourceSystem = 7

  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext, businessModel: role })

  return dispatch({
    types: TYPES_CEASE,
    payload: {
      client: clientName,
      request: {
        method: 'PATCH',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload({ globalAppContext, businessModel }) {
    const {
      CEASE,
      CEASE_PERSON_ROLE_BFF,
      bffHeaders,
      headersAuthenticationInfo,
      shouldCeaseRoleFromBff,
      countryId,
      companyId,
      sellerId,
      hierarchyId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const { requestHeaders } = getRequestHeaders({ shouldCeaseRoleFromBff, headersAuthenticationInfo, businessModel })

    const requestUrl = shouldCeaseRoleFromBff ? CEASE_PERSON_ROLE_BFF : CEASE

    const clientName = shouldCeaseRoleFromBff
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { CEASE },
        default_bff_urls: { CEASE_PERSON_ROLE_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          tenantId,
          configs
        },
        user: {
          authentication: {
            xApikey,
            accessToken
          },
          sellerId,
          hierarchyId,
        },
      } = globalAppContext

      const shouldCeaseRoleFromBff = configs?.localization?.isHostedUIEnabled

      return {
        CEASE: CEASE(personId, role.personRolesId),
        CEASE_PERSON_ROLE_BFF: CEASE_PERSON_ROLE_BFF(personId, role.personRolesId),
        shouldCeaseRoleFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        countryId,
        companyId,
        tenantId,
        sellerId,
        hierarchyId,
      }
    }

    function getRequestHeaders({ shouldCeaseRoleFromBff, headersAuthenticationInfo, businessModel }){
      const { businessModelId, functionId, roleId } = businessModel
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const requestHeaders = shouldCeaseRoleFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        userId: sellerId,
        businessmodel: businessModelId,
        function: functionId,
        role: roleId,
        sourcesystem: sourceSystem,
        countryid: countryId,
        companyid: companyId,
        hierarchyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        businessModel: businessModelId,
        function: functionId,
        role: roleId,
        sourcesystem: sourceSystem,
        hierarchyId,
      }

      return { requestHeaders }
    }
  }
}
