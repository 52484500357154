import { ocrStartReducer, startOcr, types as ocrStartTypes } from './Ocr.start'
import {
  ocrSaveAttachmentsReducer,
  saveOCRAttachments,
  types as saveOCRAttachmentsTypes
} from './Ocr.saveAttachments'
import {
  ocrResetReducer,
  resetOcr,
  OCR_RESET as ocrResetType
} from './Ocr.reset'

import Ocr from 'src/viewModels/Ocr'

const dependencies = {
  startOcr,
  resetOcr,
  saveOCRAttachments
}

const initialState = {
  loading: false,
  error: false,
  ocr: new Ocr({}, dependencies)
}

export default function reducer(state = initialState, action = {}) {
  const { type } = action

  if (ocrStartTypes.includes(type))
    return ocrStartReducer(state, action, dependencies)

  if (saveOCRAttachmentsTypes.includes(type))
    return ocrSaveAttachmentsReducer(state, action, dependencies)

  if (ocrResetType.includes(type))
    return ocrResetReducer(state, action, dependencies)

  return state
}
