import Error, { ERROR_CODES_TO_INTERCEPT } from 'src/viewModels/Error'
import Person from 'src/models/Person'
import { getContext } from 'src/infra/Api/Api.context'
import { isEnabledAllocateBff } from 'src/config'

export const PERSON_ALLOCATE_LOADING = 'natura-caduni/Person/PERSON_ALLOCATE_LOADING'
export const PERSON_ALLOCATE_SUCCESS = 'natura-caduni/Person/PERSON_ALLOCATE_SUCCESS'
export const PERSON_ALLOCATE_ERROR = 'natura-caduni/Person/PERSON_ALLOCATE_ERROR'
export const PERSON_ALLOCATE_STATE = 'natura-caduni/Person/PERSON_ALLOCATE_STATE'

export const types = [
  PERSON_ALLOCATE_LOADING,
  PERSON_ALLOCATE_SUCCESS,
  PERSON_ALLOCATE_ERROR,
  PERSON_ALLOCATE_STATE
]

export function personAllocateReducer(state, action, personDependencies) {
  let { loading, error, person } = state
  const { payload, type, meta } = action

  const hasSuccess = type === PERSON_ALLOCATE_SUCCESS
  const hasError = type === PERSON_ALLOCATE_ERROR
  const isLoading = type === PERSON_ALLOCATE_LOADING
  const savedOnState = type === PERSON_ALLOCATE_STATE

  if (hasSuccess) {
    const {
      previousAction: {
        payload: { reviewStep }
      }
    } = meta

    person = new Person(
      { ...person, ...payload.data, reviewStep },
      personDependencies
    )
    loading = false
    error = defineError(type, error, payload.data)
  }

  if (hasError) {
    const {
      meta: {
        previousAction: {
          payload: {
            request: { data }
          }
        }
      }
    } = action

    person = new Person({ ...person, ...data }, personDependencies)
    loading = false
    error = new Error(action.error)
  }

  if (savedOnState) {
    person = new Person({ ...person, ...payload.data }, personDependencies)
  }

  return {
    ...state,
    loading: isLoading || loading,
    error,
    person
  }
}

function defineError(type, error, params) {
  const { 0: { code, details, message } = {} } = params

  if (
    code &&
    type === PERSON_ALLOCATE_SUCCESS &&
    ERROR_CODES_TO_INTERCEPT.includes(code)
  ) {
    return new Error({ response: [`${details}`], title: message })
  }

  return type === PERSON_ALLOCATE_ERROR && new Error(error)
}

export function allocatePerson(person) {
  const {
    dispatch,
    urls: { ALLOCATE_PERSON },
    bff_urls: { ALLOCATE_PERSON: ALLOCATE_PERSON_BFF },
    bff_apiKeys: { REGISTERS_APIKEY },
    locale: {
      id
    },
    user
  } = getContext()

  const {
    name: userName,
    sellerId,
    authentication: { xApikey, accessToken }
  } = user

  const {
    personId,
    name: personName,
    draft: shouldUpdate,
    businessModel: { businessModelId, functionId, roleId },
    addresses: [address],
    additionalInformation: { introducerSellerId }
  } = person

  let data = undefined
  if (isEnabledAllocateBff() && address) {
    data = {
      ...(address.geographicalLevels && { geographicalLevels: address.geographicalLevels }),
      personName,
      userName,
      ...(address.latitude && { latitude: address.latitude, longitude: address.longitude }),
      ...(introducerSellerId && { indicativePersonCode: introducerSellerId }),
      ...(sellerId && { registrantPersonCode: sellerId }),
    }
  }

  const allocateUrl = isEnabledAllocateBff() ? ALLOCATE_PERSON_BFF : ALLOCATE_PERSON
  const allocateApiKey = isEnabledAllocateBff() ? REGISTERS_APIKEY : xApikey

  let allocateHeaders = {
    'x-api-key': allocateApiKey,
    userId: sellerId,
    businessModel: businessModelId,
    function: functionId,
    role: roleId
  }

  if (user.authenticated) {
    allocateHeaders = {
      ...allocateHeaders,
      Authorization: accessToken,
      access_token: accessToken,
    }
  }

  return dispatch({
    types,
    payload: {
      client: id,
      personLocal: {
        shouldUpdate
      },
      request: {
        method: 'PATCH',
        url: allocateUrl(personId),
        headers: allocateHeaders,
        data
      }
    }
  })
}

