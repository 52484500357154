import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'

export function buildPreRegister(deps) {
  const { title, subtitle, label } = getSectionTitle(deps)
  const { loader: { personLoading, personVerifyLoading } } = deps

  return {
    disabledSubmit: true,
    formSchema: buildFormSchema(deps),
    loader: personLoading || personVerifyLoading,
    onSubmit: onSubmition(deps),
    shouldWaitForDeps: false,
    step: {
      label,
      subtitle,
      title
    },
    touchAfterReload,
  }
}
