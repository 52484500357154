
import verifySmsTokenReducer from './ContactValidation.verify'
import resendTokenReducer from './ContactValidation.resendToken'
import smsResendReducer from './ContactValidation.resendSms'

const dependencies = {
  verifySmsTokenReducer,
  resendTokenReducer,
  smsResendReducer
}

const initialState = {
  isValid: false,
  loading: false,
  error: false
}

export default function reducer(state = initialState, action = {}, _, injection = {}) {
  const { verifySmsTokenReducer, resendTokenReducer, smsResendReducer } = {
    ...injection,
    ...dependencies
  }
  const {
    isValid,
    loading: verifyLoading,
    error: verifyError
  } = verifySmsTokenReducer(state, action)

  const {
    resent: resentToken,
    loading: resendLoading,
    error: resendError
  } = resendTokenReducer(state, action)

  const {
    resent: resentSms,
    loading: resendSmsLoading,
    error: resendSmsError
  } = smsResendReducer(state, action)

  const error = resendError || verifyError || resendSmsError
  const loading = verifyLoading || resendLoading || resendSmsLoading
  const resent = resentSms || resentToken

  return {
    ...state,
    error,
    isValid,
    loading,
    resent,
    resendLoading
  }
}
