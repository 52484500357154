import { defineMessages } from 'react-intl'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { CONFIGS } from 'src/models/Attachment'

const FIELD_ID = 'termAndConditions'
const messages = defineMessages({
  observation: {
    defaultMessage: 'Observation',
    id: 'form.field.termAndConditions.observation'
  },
  observation_placeholder: {
    defaultMessage: 'Describe here if you cannot record audio',
    id: 'form.field.termAndConditions.observation.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection){
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id: localeId }, person: { attachments }} = injection

  const attachmentsIds = CONFIGS[localeId]
  const TYPE = attachmentsIds[FIELD_ID]

  return {
    id: 'termAndConditionsObservation',
    label: intl.formatMessage(messages['observation']),
    placeholder: intl.formatMessage(messages['observation_placeholder']),
    type: 'textarea',
    defaultValue: defineDefaultValue(attachments, TYPE).description,
  }
}
