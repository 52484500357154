import { getContext } from 'src/infra/Api/Api.context'
import Filters from 'src/viewModels/Filters'
import { getCompanyId } from '../../helpers/get-company-id'
import { CLIENT_NAMES } from '../../infra/Api/Api'

const FILTERS_GET_LOADING = 'natura-caduni/Filters/FILTERS_GET_LOADING'
const FILTERS_GET_SUCCESS = 'natura-caduni/Filters/FILTERS_GET_SUCCESS'
const FILTERS_GET_ERROR = 'natura-caduni/Filters/FILTERS_GET_ERROR'

const types = [
  FILTERS_GET_LOADING,
  FILTERS_GET_SUCCESS,
  FILTERS_GET_ERROR
]

const dependencies = {
  getFilters
}

const initialState = {
  filters: new Filters({}, dependencies),
  loading: false,
  error: false,
  success: false
}


export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  const loading = type == FILTERS_GET_LOADING
  const error = type == FILTERS_GET_ERROR

  if (type == FILTERS_GET_SUCCESS) {
    const success = true
    const { data } = payload
    const parsedData = parseFilters(data)

    const filters = new Filters(parsedData, dependencies)

    return {
      ...state,
      loading,
      error,
      success,
      filters
    }
  }

  return {
    ...state,
    loading,
    error,
  }
}

function parseFilters(data) {
  return data.items.reduce(reduceData, {})
}

function reduceData(accum, item ){
  const { statusId, substatuses } = item
  const { status = [], substatus = [] } = accum

  const newSubstatus = substatuses.map(mapSubstatus, { statusId })

  return {
    status:[ ...status, { statusId } ],
    substatus: [ ...substatus, ...newSubstatus ]}
}

function mapSubstatus({ substatusId }) {
  return {
    substatusId,
    parent: {
      status: this.statusId
    }
  }
}

export function getFilters() {
  const {
    dispatch,
    locale: { id },
    default_bff_urls: { REPORTS_STATUS_SUBSTATUS },
    bff_apiKeys: { REGISTERS_APIKEY },
    user: {
      sellerId,
      authentication: { accessToken }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: CLIENT_NAMES.DEFAULT_BFF,
      request: {
        method: 'GET',
        url: REPORTS_STATUS_SUBSTATUS,
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          'x-api-key': REGISTERS_APIKEY,
          country: id,
          companyId: getCompanyId()
        }
      }
    }
  })
}
