import {
  isEnableDigitalStoreStepForPeruFlowToggleActive,
  isEnableDigitalStoreStepForColombiaFlowToggleActive
} from '../../../../../../../config'

const strategies = {
  CO: isEnableDigitalStoreStepForColombiaFlowToggleActive,
  PE: isEnableDigitalStoreStepForPeruFlowToggleActive,
  __default__: false
}

export const shouldShowDigitalStoreSection = (countryCode = '') => {
  const countryUpperCase = countryCode.toUpperCase()
  return strategies[countryUpperCase] ? strategies[countryUpperCase]() : strategies.__default__
}
