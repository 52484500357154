import { getContext } from 'src/infra/Api/Api.context'

import Consultants, { PAGE_SIZE } from 'src/viewModels/Consultants'

const CONSULTANTS_NAME_LOADING = 'natura-caduni/Consultants/CONSULTANTS_NAME_LOADING'
const CONSULTANTS_NAME_SUCCESS = 'natura-caduni/Consultants/CONSULTANTS_NAME_SUCCESS'
const CONSULTANTS_NAME_ERROR = 'natura-caduni/Consultants/CONSULTANTS_NAME_ERROR'

export const types = [CONSULTANTS_NAME_LOADING, CONSULTANTS_NAME_SUCCESS, CONSULTANTS_NAME_ERROR]

export function consultantsByNameReducer(state, action, dependencies) {
  const { type, payload, error: actionError, meta } = action
  let { consultants, loading, error } = state

  const isLoading = type === CONSULTANTS_NAME_LOADING
  const isSuccess = type === CONSULTANTS_NAME_SUCCESS
  const isError = type === CONSULTANTS_NAME_ERROR

  if (isSuccess) {
    const { previousAction: { payload: { queryParams }}} = meta
    const { data } = payload

    const introducers = data ? consultants.items.concat(data) : []
    const hasMore = data.length === PAGE_SIZE

    const deps = {
      ...dependencies,
      queryParams,
      hasMore
    }

    error = false
    loading = false
    consultants = new Consultants(introducers, deps)
  }

  if (isError) {
    loading = false
    error = new Error(actionError)
  }

  return {
    loading: isLoading || loading,
    consultants,
    error
  }
}

export function consultantsByName({ queryParams, ...rest }){
  const {
    dispatch,
    urls: { CONSULTANTS },
    locale: { id },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      queryParams,
      request: {
        method: 'GET',
        url: CONSULTANTS.findByName(rest),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          'cache-control': 'no-cache',
          userId: sellerId,
        }
      }
    }
  })
}
