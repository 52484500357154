import views from './views'
import './styles/default.styl'
import { getTenantConfig, getLayoutsConfig } from './localization/strategies'

export default function configurationBuilder(tenantId) {
  return {
    layouts: getLayoutsConfig(tenantId),
    localization: getTenantConfig(tenantId),
    views
  }
}
