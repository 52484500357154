import * as yup from 'yup'
import moment from 'moment'

// Equality compare (email, passwords, etc)
export function equalTo(ref, message) {
  return this.when(ref.path, {
    is: (value) => !value,
    then: yup.string().oneOf([ref], message),
    otherwise: yup.string().oneOf([ref], message).required()
  })
}

// Date Transforms
export const apiFormat = 'YYYY-MM-DD'
export const yupFormat = 'MM/DD/YYYY'
export const invalidDate = new Date('')
export const atLeast18Years = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
export const noMoreThan100Years = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

export function parseApiDate(date, format) {
  return moment(date, apiFormat).format(format)
}

export function parseDateToApi(date, format) {
  const apiFormatRegex = /^[\d]{4}-[\d]{2}-[\d]{2}/
  if (apiFormatRegex.test(date)) {
    return date
  }
  return moment(date, format).format(apiFormat)
}

export function format(localeFormat) {
  return this.transform(function(value, originalValue) {
    const hasDate = value.toString() !== originalValue.toString()

    if(!hasDate) return value

    const date = moment(originalValue, localeFormat)

    if(originalValue.length !== localeFormat.length)
      return invalidDate

    return date.isValid() ? date.toDate() : invalidDate
  })
}
