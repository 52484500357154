import { store } from '../../../../../../../../App.state'
import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import { ADDRESS_USE } from '../../../../../../../../models/Address/AddressUse'

const COMPLEMENTARY_ADDRESS_LEVELS = {
  NUMBER: 7,
  STREET: 6,
  COMPLEMENT: 8,
  REFERENCE: 9,
  URBANIZATION: 5
}

export const getPeruParsedData = ({ data, configs }) => {
  const  {
    localization: {
      address: {
        levels
      }
    }
  } = configs
  const { person } = store.getState()

  const onlyDeliveryAddress = data.address.filter(address => {
    return address.addressUse.find(use => {
      return use.id === ADDRESS_USE.DELIVERY
    })
  })

  const addresses = onlyDeliveryAddress.map(mapAddress, { levels })
  const complementaryAddress = getComplementaryAddressFrom(person)

  localStorage.setItem('cumulativeAddress', getCumulativeAddress(addresses))

  addresses[0].geographicalLevels = [...addresses[0].geographicalLevels, ...complementaryAddress]

  return { addresses }
}

const getCumulativeAddress = (addresses) => {
  const {addressUse, geographicalLevels} = addresses[0]
  return JSON.stringify({ addressUse: buildAddressUse(addressUse), geographicalLevels})
}

const buildAddressUse = (addressUse) => {
  return addressUse.map((item) => {
    return {
      id: item.id,
      description: item.description
    }
  })
}

function mapAddress(address) {
  const { addresses: { addresses: addressCMM, addressesPeopleManagement }, locale: { locale } } = store.getState()
  const {
    addressUse,
    countryName: country,
    ...addressValues
  } = address

  const { configs: { localization: { shouldGetAddressFromBff }}} = locale

  const types = {
    ...addressValues,
    country
  }

  const addresses = shouldGetAddressFromBff ? addressesPeopleManagement : addressCMM

  const addressType = ADDRESS_TYPE.MAILING
  const addressesStructure = getStructure(types, addresses)

  const geographicalLevels = [
    { type: 'country', level: this.levels.COUNTRY },
    { type: 'region', level: this.levels.REGION },
    { type: 'department', level: this.levels.DEPARTMENT },
    { type: 'province', level: this.levels.PROVINCE },
    { type: 'district', level: this.levels.DISTRICT },
  ].reduce(reduceLevels, [{ types, addressesStructure }])

  return {
    addressType,
    addressUse,
    geographicalLevels
  }
}

function getComplementaryAddressFrom(personStructure) {
  const { person } = personStructure
  const results = []

  if( !person.addresses[0] ) return results

  const onlyDeliveryAddress = person.addresses.filter(address => {
    return address.addressUse.find(use =>  {
      return use.id === ADDRESS_USE.DELIVERY
    })
  })

  const geographicalLevels = onlyDeliveryAddress[0].geographicalLevels

  const levelsToFilter = [
    COMPLEMENTARY_ADDRESS_LEVELS.NUMBER,
    COMPLEMENTARY_ADDRESS_LEVELS.STREET,
    COMPLEMENTARY_ADDRESS_LEVELS.COMPLEMENT,
    COMPLEMENTARY_ADDRESS_LEVELS.REFERENCE,
    COMPLEMENTARY_ADDRESS_LEVELS.URBANIZATION
  ]

  geographicalLevels.forEach(geoLevel => {
    if( levelsToFilter.includes(geoLevel.level) ) {
      results.push({
        level: geoLevel.level,
        description: geoLevel.description,
      })
    }
  })
  return results
}

function reduceLevels(accum, { type, level }, index, array) {
  const lastItem = index === array.length - 1
  const [dependencies] = accum

  const addressLevel = builderLevel({ level, type, dependencies })

  if (lastItem) accum.shift()

  if (!addressLevel) return accum

  return [...accum, addressLevel]
}

function builderLevel({ level, type, dependencies }) {
  const { types, addressesStructure } = dependencies

  const fieldDescription = types[type]
  const addressesStructureSelected = addressesStructure[type]

  if (!fieldDescription) return undefined

  if (addressesStructureSelected) {
    return {
      level,
      code: addressesStructureSelected.value,
      description: addressesStructureSelected.label.toUpperCase()
    }
  }

  return { level, description: fieldDescription.toUpperCase() }
}

function getStructure(types, addresses) {
  const { departments, provinces, districts, regions } = addresses

  const { region, department, province, district, country } = types

  return {
    region: getGeoStructureValues(regions[country], region),
    department: getGeoStructureValues(departments[region], department),
    province: getGeoStructureValues(provinces[department], province),
    district: getGeoStructureValues(districts[province], district)
  }
}

function getGeoStructureValues(addresses, value) {
  return addresses.find(findAddress, { value })
}

function findAddress({ value }) {
  return value === this.value
}
