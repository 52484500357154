import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'

const messages = defineMessages({
  reference: {
    defaultMessage: 'Reference',
    id: 'form.field.reference'
  },
  references_placeholder: {
    defaultMessage: 'urbanization, between streets, etc...',
    id: 'form.field.references.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  const pushDataLayer = (values) => {
    const { value } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationLocationData',
        fieldName: intl.formatMessage(messages['reference']),
        value,
      }
    })
  }

  return {
    id: 'references',
    label: intl.formatMessage(messages['reference']),
    placeholder: intl.formatMessage(messages['references_placeholder']),
    type: 'input',
    required: true,
    showAsterisk: injection.showAsterisk,
    onBlur: pushDataLayer
  }
}
