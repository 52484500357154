import { COMPANY_ID } from "../../../../../models/Company"
import { TENANT_ID } from "../../../../../models/tenant/tenant-id"

const SOURCE_SYSTEM = 7
const COUNTRY = 'MX'
const COUNTRY_ID = 7
const LANGUAGE = 'es-MX'
const FLOW_TYPE = 'CT'
const URL_GLOBAL_PEOPLE_MANAGEMENT = process.env.GLOBAL_PEOPLE_BASE_URI
const URL_PEOPLE_MANAGEMENT = '/businessrelationship/peoplemanagement/v1'
const URL_PEOPLE = `${URL_PEOPLE_MANAGEMENT}/people`
const URL_GLOBAL_PEOPLE = `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people`
const APPLICATION_JSON = 'application/json'

export const AVON_MEXICO_CLIENT = {
  baseURL: process.env.BASE_URI,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
    client_id: process.env.CLIENT_ID,
    country: COUNTRY,
    countryId: COUNTRY_ID,
    language: LANGUAGE,
    sourceSystem: SOURCE_SYSTEM,
    flowType: FLOW_TYPE,
    tenantId: TENANT_ID.AVON_MEXICO,
    companyId: COMPANY_ID.AVON
  }
}

export const getAvonMexicoApiHeaders = () => {
  return {
    client: AVON_MEXICO_CLIENT,
    urlPeople: URL_PEOPLE,
    urlGlobalPeople: URL_GLOBAL_PEOPLE,
    urlGlobalPeopleManagement: URL_GLOBAL_PEOPLE_MANAGEMENT
  }
}


