import * as yup from 'yup'
import { Entity, Collection } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const FUNCTIONS = {
  BEAUTY_CONSULTANT: 1,
  BUSINESS_LEADER: 2,
}

export const ROLES = {
  CONSULTANT: 1,
}

export const BUSINESS_MODEL = {
  DIRECT_SALE: 1,
}

class Functions extends Entity {
  static SCHEMA = {
    functionId: adapter(yup.number().required()),
    functionName: adapter(yup.string().required()),
    parent: adapter(yup.object().shape({
      roleId: yup.number(),
      businessModelId: yup.number()
    }))
  }

  get value() { return this.functionId }

  get label() { return this.functionName }
}

class Roles extends Entity {
  static SCHEMA = {
    roleId: adapter(yup.number().required()),
    roleName: adapter(yup.string().required()),
    parent: adapter(yup.object().shape({
      businessModelId: yup.number()
    })),
    functions: {
      validator: adapter(yup.object()),
      type: Functions,
      defaultValue: {}
    }
  }

  get value() { return this.roleId }

  get label() { return this.roleName }
}

class BusinessModel extends Entity {
  static SCHEMA = {
    businessModelId: {
      validator: adapter(yup.number().required()),
      defaultValue: 0
    },
    businessModelName: {
      validator: adapter(yup.string().required()),
      defaultValue: ''
    },
    roles: {
      validator: adapter(yup.array().of(yup.object())),
      type: Roles,
      defaultValue: []
    },
    currentFunctionId: adapter(yup.number().required()),
  }

  get value() { return this.businessModelId }

  get label() { return this.businessModelName }
}

export default class BusinessModelList extends Collection {
  static TYPE = BusinessModel

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }
}
