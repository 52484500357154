import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import { defineMessages } from 'react-intl'
import { findAddress } from 'src/models/Address'
import { DELIVERY_ADDRESS, ALTERNATIVE_ADDRESS, ADDRESS_USE } from 'src/models/Address/AddressUse'

const messages = defineMessages({
  button_add_delivery_address: {
    defaultMessage: 'Add delivery address',
    id: 'form.field.button.add.delivery.address'
  },
  button_add_alternative_address: {
    defaultMessage: 'Add alternative address',
    id: 'form.field.button.add.alternative.address'
  }
})

const dependencies = {
  messages
}

const bem = bemClassName.bind(null, 'form__group__button__address')

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id:'add',
    node: (
      <AddressButton
        intl={intl}
        icon="add"
        messages={messages}
        onClick={addAddress}
      />
    )
  }
}

export function AddressButton(props) {
  const { disabled, hidden, icon, value } = props

  const hasDeliveryAddress = value.find(findAddress, { useId: ADDRESS_USE.DELIVERY })
  const hasAlternativeAddress = value.find(findAddress, { useId: ADDRESS_USE.ALTERNATIVE })

  const title = getTitle({ ...props, hasDeliveryAddress })
  const onClick = addAddress({ ...props, hasDeliveryAddress })

  if (hasDeliveryAddress && hasAlternativeAddress) return null

  return (
    <button
      {...props}
      className={bem({ disabled, hidden })}
      title={title}
      onClick={onClick}>
      <i className={bem('icon', [icon])} />
      {title}
    </button>
  )
}

function getTitle(props) {
  const { intl, messages, hasDeliveryAddress, disabled } = props

  if (hasDeliveryAddress && !disabled) {
    return intl.formatMessage(messages.button_add_alternative_address)
  }

  return intl.formatMessage(messages.button_add_delivery_address)
}

function addAddress(props) {
  const { form: { addItemToGroup, setFieldValue }, schema, value, hasDeliveryAddress } = props

  return function(event) {
    event.preventDefault()

    addItemToGroup(schema)

    if (hasDeliveryAddress) {
      return setFieldValue(`address[${value.length}].addressUse`, [ALTERNATIVE_ADDRESS])
    }

    return setFieldValue(`address[${value.length}].addressUse`, [DELIVERY_ADDRESS])
  }
}

AddressButton.propTypes = {
  intl: PropTypes.object,
  onClick: PropTypes.func,
  messages: PropTypes.object,
  value: PropTypes.array,
  titles: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  icon: PropTypes.string
}
