import moment from 'moment'
import { parseApiDate, apiFormat } from 'src/lib/SpeckAdapter/Yup.customs'

import { GENDERS } from 'src/models/Person'

export default function Builder(params) {
  const { intl, messages } = params
  const { name, code, gender, birthday, age } = parsePerson(params)

  return {
    id: 'profile-personal-info',
    title: intl.formatMessage(messages.box_personal_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      {
        label: intl.formatMessage(messages.box_personal_label_name),
        field: name || '-'
      },
      {
        label: intl.formatMessage(messages.box_personal_label_code),
        field: code || '-'
      },
      {
        label: intl.formatMessage(messages.box_personal_label_gender),
        field: gender || '-'
      },
      {
        label: intl.formatMessage(messages.box_personal_label_birthday),
        field: birthday || '-'
      },
      {
        label: intl.formatMessage(messages.box_personal_label_age),
        field: age || '-'
      }
    ]
  }
}

const genderEnum = {
  [GENDERS.MALE]: 'box_personal_value_gender_male',
  [GENDERS.FEMALE]: 'box_personal_value_gender_female',
  [GENDERS.OTHER]: 'box_personal_value_gender_other'
}

function parsePerson({ intl, messages, person, locale: { configs } }) {
  const { date: { format } } = configs.localization
  const { name = '-', cnCode: code } = person

  const genderMessage = messages[genderEnum[person.gender]]
  const years = intl.formatMessage(messages.box_personal_value_age_years)
  const birthday = person.birthday ? parseApiDate(person.birthday, format) : '-'
  const age = person.birthday && moment().diff(moment(person.birthday, apiFormat), 'years')
  const gender = genderMessage ? intl.formatMessage(genderMessage) : '-'

  return {
    name,
    code,
    gender,
    birthday,
    age: age ? `${age} ${years}` : '-'
  }
}
