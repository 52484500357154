import TYPES from 'src/configs/Fields'

export const getMalaysiaFieldsSchema = ({intl, dependencies}) => {
  const ADDRESS_DETAILS_MYS = commonParams(TYPES.ADDRESS_DETAILS_MYS(intl, dependencies))

  return [[ADDRESS_DETAILS_MYS]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
