import { defineMessages, FormattedMessage } from 'react-intl'
import React from 'react'

export const Messages = defineMessages({
  approved_title: {
    defaultMessage: 'Approved registration',
    id: 'completed.page.title.approved'
  },
})

export function getSectionTitle({ messages = Messages }) {

  const {
    page_title,
    subtitle_1,
    step_label
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />,
    ],
    label: <FormattedMessage {...step_label} />,
  }
}
