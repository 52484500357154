import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_REVALIDATE_LOADING = 'natura-caduni/People/PROFILE_REVALIDATE_LOADING'
export const PROFILE_REVALIDATE_SUCCESS = 'natura-caduni/People/PROFILE_REVALIDATE_SUCCESS'
export const PROFILE_REVALIDATE_ERROR = 'natura-caduni/People/PROFILE_REVALIDATE_ERROR'

const types = [PROFILE_REVALIDATE_LOADING, PROFILE_REVALIDATE_SUCCESS, PROFILE_REVALIDATE_ERROR]

export function revalidateProfile( personId ) {
  const {
    dispatch,
    urls: { REVALIDATE },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: REVALIDATE( personId ),
        headers: {
          access_token: accessToken,
          userId: sellerId
        },
        data: {
          steps: [{
            taskId: 2,
            subtaskId: 1,
          }]
        }
      }
    }
  })
}
