import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import classNames from 'classnames'

import './Label.styl'
import { isDefaultFlowToggleActive } from '../../../../config'

class Label extends PureComponent {

  constructor(props) {
    super(props)
    this.bem = isDefaultFlowToggleActive() ? bemClassName.bind(null, 'defaultLabel') : bemClassName.bind(null, 'label')
  }

  get content() {
    const { required, label, showAsterisk } = this.props

    if(!required || !showAsterisk) return label

    if(typeof label === 'string') {
      return `${label}*`
    }

    const children = label.props.children.slice(0)

    children.push('*')

    return React.cloneElement(label, { children })
  }

  render() {
    const { className, id, error, disabled } = this.props

    return (
      <label
        className={classNames(this.bem({ error, disabled }), className)}
        htmlFor={id}>
        {this.content}
      </label>
    )
  }
}

Label.propTypes = {
  className: PropTypes.string,
  showAsterisk: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool
}

Label.defaultProps = {
  showAsterisk: true
}

export default Label
