import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_SAVE_LOADING = 'natura-caduni/People/PROFILE_SAVE_LOADING'
export const PROFILE_SAVE_SUCCESS = 'natura-caduni/People/PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_ERROR = 'natura-caduni/People/PROFILE_SAVE_ERROR'
export const PROFILE_SAVE_STATE = 'natura-caduni/People/PROFILE_SAVE_STATE'

const TYPES_SAVE = [PROFILE_SAVE_LOADING, PROFILE_SAVE_SUCCESS, PROFILE_SAVE_ERROR]

export function savePerson(person, values) {
  const {
    dispatch,
    connectivity,
    urls: { PEOPLE },
    locale: { id },
    user: { sellerId, authentication: { accessToken }, hierarchyId }
  } = getContext()

  const { personId } = person
  const data = Object.keys(values).reduce(reduceFields, { person })

  if (connectivity.isOffline)
    return new Promise(offlineStep({ data, personId }))

  return dispatch({
    types: TYPES_SAVE,
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: PEOPLE(personId),
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          hierarchyId
        },
        data
      }
    }
  })
}

export function savePersonOnState(person, values) {
  const { dispatch } = getContext()

  const data = Object.keys(values)
    .reduce(reduceFields, { person })

  return dispatch({
    type: PROFILE_SAVE_STATE,
    payload: { data }
  })
}

function reduceFields(final, key, index, array) {
  const value = final.person[key]

  if(index === array.length -1)
    delete final.person

  return { ...final, [key]: value }
}

function offlineStep({ data, personId }) {
  const { peopleRegister, dispatch } = getContext()

  return function (resolve){
    dispatch({
      type: PROFILE_SAVE_STATE,
      payload: { data }
    })

    peopleRegister.savePerson({ ...data, personId })

    return resolve(data)
  }
}
