import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'city',
    label: intl.formatMessage(messages['city']),
    placeholder: intl.formatMessage(messages['city_plain_placeholder']),
    type: 'input',
    required: true,
    showAsterisk: false
  }
}
