import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'

export function buildBusinessModel(deps) {
  const { title, subtitle, label } = getSectionTitle(deps)
  const { personLoading: loader } = deps.loader || {}

  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    step: {
      title,
      subtitle,
      label
    },
    shouldWaitForDeps: false,
    loader
  }
}
