import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  title: {
    defaultMessage: 'New Register',
    id: 'register.title'
  },
  title_cno: {
    defaultMessage: 'New Indication',
    id: 'register.title.cno'
  },
  reject_title: {
    defaultMessage: 'Existing registry',
    id: 'pre-register.reject.title'
  },
  reject_role_title: {
    defaultMessage: 'Attention',
    id: 'pre-register.reject.role.title'
  },
  reject_message: {
    defaultMessage: 'There has already been an attempt to register the cell number: {document} and was rejected for the reason:',
    id: 'pre-register.reject.message'
  },
  reject_role_message: {
    defaultMessage: 'Rejected Natura Policies',
    id: 'pre-register.reject.role.message'
  },
  reject_question: {
    defaultMessage: 'Do you want to try again?',
    id: 'pre-register.reject.question'
  },
  reject_button_yes: {
    defaultMessage: 'Yes',
    id: 'pre-register.reject.button.yes'
  },
  reject_button_no: {
    defaultMessage: 'No',
    id: 'pre-register.reject.button.no'
  },
  modal_button_yes: {
    defaultMessage: 'I agree',
    id: 'register.complementary_data.error.blacklist'
  },
  modal_title_person_exists: {
    defaultMessage: 'This person is already in the Natura database',
    id: 'modal.title.person.exists'
  },
  modal_title_person_local_exists: {
    defaultMessage: 'This person is already in the Offline database',
    id: 'modal.title.person.local.exists'
  },
  modal_message_person_exists: {
    defaultMessage: 'What would you like to do?',
    id: 'modal.message.person.exists'
  },
  modal_accept_person_exists: {
    defaultMessage: 'See record',
    id: 'modal.accept.person.exists'
  },
  modal_cancel_person_exists: {
    defaultMessage: 'Back to search',
    id: 'modal.cancel.person.exists'
  },
  modal_error_title: {
    defaultMessage: 'Error',
    id: 'modal.error.title'
  },
  modal_error_email_already: {
    defaultMessage: 'The email is already registered in the natura database. please, report another email',
    id: 'modal.error.email.already'
  },
  modal_error_invalid_arguments: {
    defaultMessage: 'Invalid arguments',
    id: 'modal.error.invalid.arguments'
  },
  modal_error_tenant_id: {
    defaultMessage: 'Tenant ID is required',
    id: 'modal.error.tenant.id'
  },
  modal_error_invalid_user_id: {
    defaultMessage: 'Invalid user ID',
    id: 'modal.error.user.id'
  },
  modal_error_exists_active_person: {
    defaultMessage: 'Person not found',
    id: 'modal.error.active.person'
  },
  modal_error_tenant_logic_failed: {
    defaultMessage: 'Tenant logic failed',
    id: 'modal.error.tenant.logic.falied'
  },
  modal_error_tenant_not_found: {
    defaultMessage: 'Tenant not found',
    id: 'modal.error.tenant.found'
  },
  modal_error_type_error: {
    defaultMessage: 'Type ERROR',
    id: 'modal.error.type.error'
  },
  modal_error_create_person_validation: {
    defaultMessage: 'Create person validation error',
    id: 'modal.error.create.person.validation'
  },
  modal_error_allocation_does_not_exist: {
    defaultMessage: 'Allocation does not exist',
    id: 'modal.error.allocation.does.not.exist'
  },
  modal_error_cannot_get_data_from_gera: {
    defaultMessage: 'Cannot get data from gera',
    id: 'modal.error.cannot.get.data.from.gera'
  },
  modal_error_invalid_status: {
    defaultMessage: 'Invalid status',
    id: 'modal.error.invalid.status'
  },
  modal_error_cannot_update_contact_information: {
    defaultMessage: 'Cannot update contact information',
    id: 'modal.error.cannot.update.contact.information'
  },
  modal_error_fail_connect_with_cmm: {
    defaultMessage: 'Fail to connect with CMM',
    id: 'modal.error.fail.connect.with.cmm'
  },
  modal_error_person_role_already_ceased: {
    defaultMessage: 'Person role already ceased',
    id: 'modal.error.person.role.already.ceased'
  },
  modal_error_person_has_debts: {
    defaultMessage: 'Please contact our customer service center to verify your registration',
    id: 'modal.error.person.has.debts'
  },
  modal_error_person_role_already_active: {
    defaultMessage: 'Person role already active',
    id: 'modal.error.person.role.already.active'
  },
  modal_error_person_role_already_exists: {
    defaultMessage: 'Person role already exists',
    id: 'modal.error.person.role.already.exists'
  },
  modal_error_person_role_not_active: {
    defaultMessage: 'Person role not active',
    id: 'modal.error.person.role.not.active'
  },
  modal_error_person_role_not_found: {
    defaultMessage: 'Person role not found',
    id: 'modal.error.person.role.not.found'
  },
  modal_error_invalid_substatus: {
    defaultMessage: 'Invalid substatus',
    id: 'modal.error.invalid.substatus'
  },
  modal_error_title_email_already: {
    defaultMessage: 'Email already registered in Natura',
    id: 'modal.error.title.email.already'
  },
  modal_error_person_role_blacklisted: {
    defaultMessage: 'Person role is blacklisted',
    id: 'modal.error.person.role.blacklisted'
  },
  attention_title: {
    defaultMessage: 'Attention',
    id: 'attention.title'
  },
  attention_message: {
    defaultMessage: 'This person has the role of Consultant in their profile, so the alteration of address can have as a consequence the change of commercial structure.',
    id: 'attention.message'
  },
  modal_error_snac_message: {
    defaultMessage: 'This person is already registered in Natura.',
    id: 'modal.error.snac.message'
  },
  synchronize_registers: {
    defaultMessage: '...synchronize registers',
    id: 'dashboard.myregisters.synchronize.registers'
  },
  modal_success_title: {
    defaultMessage: 'The registration was saved successfully!',
    id: 'modal.success.title'
  },
  modal_success_message: {
    defaultMessage: 'Soon the candidate will receive a link in the email registered to attach identification document (RG or CNH) and finalize the registration.',
    id: 'modal.success.message'
  },
  smodal_success_accept_label: {
    defaultMessage: 'Ok, I got it',
    id: 'modal.success.accept.label'
  },
  modal_error_document_already_exists: {
    defaultMessage: 'The document: {document} already exists in the database, please report another',
    id: 'modal.error.document.already.exists'
  },
  modal_error_invalid_captcha_token: {
    defaultMessage: 'Invalid captcha token',
    id: 'modal.error.invalid.captcha.token'
  },
  modal_error_invalid_status_substatus_for_approval: {
    defaultMessage: 'Invalid status and substatus for person role approval',
    id: 'modal.error.invalid.status.substatus.for.approval'
  },
  modal_error_invalid_status_substatus_for_rejection: {
    defaultMessage: 'Invalid status and substatus for person role rejection',
    id: 'modal.error.invalid.status.substatus.for.rejection'
  },
  modal_error_rejection_reason_not_found: {
    defaultMessage: 'Rejection reason not found',
    id: 'modal.error.rejection.reason.not.found'
  },
  modal_error_system_unavailable: {
    defaultMessage: 'System unavailable',
    id: 'modal.error.system.unavailable'
  },
  error_forwarding_call_to_back_end_server: {
    defaultMessage: 'Error forwarding call to back-end server',
    id: 'modal.error.forwarding.call.to.back.end.server'
  },
  error_title: {
    defaultMessage: 'Error',
    id: 'modal.title.error'
  },
  invalid_tenant_application_messages_for_creat_person: {
    defaultMessage: 'Invalid tenant application messages for create person',
    id: 'modal.error.invalid.tenant.application.messages.for.create.person'
  },
  modal_error_person_already_active: {
    defaultMessage: 'Error person already active',
    id: 'modal.error.person.already.active'
  }
})

export default MESSAGES
