import { getContext } from 'src/infra/Api/Api.context'
import { parseDateToApi } from 'src/lib/SpeckAdapter/Yup.customs'
import Error, { ERROR_CODES_TO_INTERCEPT } from 'src/viewModels/Error'
import { PEOPLE_REGISTER_SAVE } from 'src/reducers/PeopleRegister/PeopleRegister.save'
import Person from 'src/models/Person'
import { CLIENT_NAMES } from '../../infra/Api/Api'
import { BEAUTY_CONSULTANT_PROFILE, getBusinessModelByProfile } from '../../configs/Locales/DEFAULT/NaturaProfiles'

export const PERSON_SAVE_LOADING = 'natura-caduni/Person/PERSON_SAVE_LOADING'
export const PERSON_SAVE_SUCCESS = 'natura-caduni/Person/PERSON_SAVE_SUCCESS'
export const PERSON_SAVE_ERROR = 'natura-caduni/Person/PERSON_SAVE_ERROR'
export const PERSON_SAVE_STATE = 'natura-caduni/Person/PERSON_SAVE_STATE'

export const types = [
  PERSON_SAVE_LOADING,
  PERSON_SAVE_SUCCESS,
  PERSON_SAVE_ERROR,
  PERSON_SAVE_STATE
]

export function personSaveReducer(state, action, personDependencies) {
  let { loading, error, person } = state
  const { payload, type, meta } = action

  const hasSuccess = type === PERSON_SAVE_SUCCESS
  const hasError = type === PERSON_SAVE_ERROR
  const isLoading = type === PERSON_SAVE_LOADING
  const savedOnState = type === PERSON_SAVE_STATE

  if (hasSuccess) {
    const {
      previousAction: {
        payload: { reviewStep }
      }
    } = meta

    person = new Person(
      { ...person, ...payload.data, reviewStep },
      personDependencies
    )
    loading = false
    error = defineError(type, error, payload.data)
  }

  if (hasError) {
    const {
      meta: {
        previousAction: {
          payload: {
            request: { data }
          }
        }
      }
    } = action

    person = new Person({ ...person, ...data }, personDependencies)
    loading = false
    error = new Error(action.error)
  }

  if (savedOnState) {
    person = new Person({ ...person, ...payload.data }, personDependencies)
  }

  return {
    ...state,
    loading: isLoading || loading,
    error,
    person
  }
}

export function savePerson(person, values) {
  const globalAppContext = getContext()
  const {
    dispatch,
    connectivity,
    locale: {
      configs: {
        localization: {
          date: { format }
        }
      }
    },
  } = globalAppContext

  const {
    draft: shouldUpdate,
  } = person

  const { clientName, requestUrl, requestHeaders, requestBody, reviewStep, parsedPersonData } = getRequestPayload({ globalAppContext, personData: person, formValues: values })

  if (connectivity.isOffline) return new Promise(offlineStep(requestBody, person))

  return dispatch({
    types,
    payload: {
      client: clientName,
      personLocal: {
        shouldUpdate,
        data: parsedPersonData
      },
      reviewStep,
      request: {
        method: 'PATCH',
        url: requestUrl,
        headers: requestHeaders,
        data: requestBody
      }
    }
  })

  function getRequestPayload({ globalAppContext, personData, formValues }) {
    const { personId } = personData
    const {
      PEOPLE_URL,
      UPDATE_PERSON_URL,
      bffHeaders,
      countryId,
      companyId,
      headersAuthenticationInfo,
      shouldUpdatePersonFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext, personId })
    const clientName = shouldUpdatePersonFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId
    const requestUrl = shouldUpdatePersonFromBff ? UPDATE_PERSON_URL : PEOPLE_URL
    const { requestBody, reviewStep, parsedPersonData } = getRequestBody({ personData, formValues })
    const { requestHeaders } = getRequestHeaders({ personData, shouldUpdatePersonFromBff, headersAuthenticationInfo, sellerId })

    return { clientName, requestUrl, requestBody, requestHeaders, reviewStep, parsedPersonData }

    function getNeededInformationFromGlobalContext({ globalAppContext, personId }){
      const {
        urls: { PEOPLE },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { UPDATE_PERSON },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldUpdatePersonFromBff = configs?.localization?.shouldUpdatePersonFromBff

      return {
        PEOPLE_URL: PEOPLE(personId),
        UPDATE_PERSON_URL: UPDATE_PERSON({ personId }),
        shouldUpdatePersonFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestBody({ personData, formValues }){
      const parsedPersonData = Object.keys(formValues).reduce(reduceFields, { person: personData })
      
      if (parsedPersonData.emails) {
        const convertingToLowerCaseEmail = (parsedPersonData.emails[0].email).toLowerCase()
        Object.assign(parsedPersonData.emails[0], { email: convertingToLowerCaseEmail })
      }

      if (parsedPersonData.birthday) {
        const birthday = parseDateToApi(parsedPersonData.birthday, format)
        Object.assign(parsedPersonData, { birthday })
      }

      const { reviewStep, ...personDataWithoutReviewStepProperty } = parsedPersonData

      const requestBody = personDataWithoutReviewStepProperty

      return { requestBody, reviewStep, parsedPersonData }
    }

    function getRequestHeaders({ personData, shouldUpdatePersonFromBff, headersAuthenticationInfo, sellerId }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo
      const { businessModel } = personData
      const {
        businessModelId,
        functionId,
        roleId
      } = businessModel
      const ConsultantBusinessModel = getBusinessModelByProfile(BEAUTY_CONSULTANT_PROFILE)
      const requestHeaders = shouldUpdatePersonFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        businessmodel: businessModelId || ConsultantBusinessModel.businessModelId,
        functionid: functionId || ConsultantBusinessModel.functionId,
        role: roleId || ConsultantBusinessModel.roleId,
        sourcesystem: '7',
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        businessModel: businessModelId,
        function: functionId,
        role: roleId,
      }

      return { requestHeaders }
    }
  }
}

function reduceFields(final, key, index, array) {
  const value = final.person[key]

  if (index === array.length - 1) delete final.person

  return { ...final, [key]: value }
}

function defineError(type, error, params) {
  const { 0: { code, details, message } = {} } = params

  if (
    code &&
    type === PERSON_SAVE_SUCCESS &&
    ERROR_CODES_TO_INTERCEPT.includes(code)
  ) {
    return new Error({ response: [`${details}`], title: message })
  }

  return type === PERSON_SAVE_ERROR && new Error(error)
}

export function savePersonOnState(person, values) {
  const {
    dispatch,
    locale: {
      configs: {
        localization: {
          date: { format }
        }
      }
    }
  } = getContext()

  const data = Object.keys(values).reduce(reduceFields, { person })

  if (data.birthday) {
    const birthday = parseDateToApi(data.birthday, format)
    Object.assign(data, { birthday })
  }

  return dispatch({
    type: PERSON_SAVE_STATE,
    payload: { data }
  })
}

function offlineStep(data, { personId }) {
  const { dispatch } = getContext()

  return function(resolve) {
    dispatch({ type: PERSON_SAVE_STATE, payload: { data } })
    dispatch({
      type: PEOPLE_REGISTER_SAVE,
      payload: { person: { ...data, personId } }
    })

    return resolve(data)
  }
}
