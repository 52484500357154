import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  mixed_default: {
    defaultMessage: 'Invalid',
    id: 'mixed.default'
  },
  mixed_required: {
    defaultMessage: 'Required field',
    id: 'mixed.required'
  },
  mixed_oneOf: {
    defaultMessage: 'Must be one of the following values: ${values}',
    id: 'mixed.oneOf'
  },
  mixed_notOneOf: {
    defaultMessage: 'Must not be one of the following values: ${values}',
    id: 'mixed.notOneOf'
  },
  string_length: {
    defaultMessage: 'Must be exactly ${length} characters',
    id: 'string.length'
  },
  string_min: {
    defaultMessage: 'Must be at least ${min} characters',
    id: 'string.min'
  },
  string_max: {
    defaultMessage: 'Must be at most ${max} characters',
    id: 'string.max'
  },
  string_matches: {
    defaultMessage: 'Must match the following: ${regex}',
    id: 'string.matches'
  },
  string_email: {
    defaultMessage: 'Must be a valid email',
    id: 'string.email'
  },
  string_url: {
    defaultMessage: 'Must be a valid URL',
    id: 'string.url'
  },
  string_trim: {
    defaultMessage: 'Must be a trimmed string',
    id: 'string.trim'
  },
  string_lowercase: {
    defaultMessage: 'Must be a lowercase string',
    id: 'string.lowercase'
  },
  string_uppercase: {
    defaultMessage: 'Must be a upper case string',
    id: 'string.uppercase'
  },
  number_min: {
    defaultMessage: 'Must be greater than or equal to ${min}',
    id: 'number.min'
  },
  number_max: {
    defaultMessage: 'Must be less than or equal to ${max}',
    id: 'number.max'
  },
  number_less: {
    defaultMessage: 'Must be less than ${less}',
    id: 'number.less'
  },
  number_more: {
    defaultMessage: 'Must be greater than ${more}',
    id: 'number.more'
  },
  number_notEqual: {
    defaultMessage: 'Must be not equal to ${notEqual}',
    id: 'number.notEqual'
  },
  number_positive: {
    defaultMessage: 'Must be a positive number',
    id: 'number.positive'
  },
  number_negative: {
    defaultMessage: 'Must be a negative number',
    id: 'number.negative'
  },
  number_integer: {
    defaultMessage: 'Must be an integer',
    id: 'number.integer'
  },
  date_min: {
    defaultMessage: 'Must be later than ${min}',
    id: 'date.min'
  },
  date_max: {
    defaultMessage: 'Must be at earlier than ${max}',
    id: 'date.max'
  },
  array_min: {
    defaultMessage: 'Must have at least ${min} items',
    id: 'array.min'
  },
  array_max: {
    defaultMessage: 'Must have less than or equal to ${max} items',
    id: 'array.max'
  }
})

export default MESSAGES
