import React from 'react'
import Icon from '../../../../../base/Icon'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import './Rejected.styl'
import Link from '../../../../../base/Link'

const bem = bemClassName.bind(null, 'completed-rejection')

const Rejected = props => {
  const { messages, intl, localizationConfig } = props
  const webPage = intl.formatMessage({ ...messages['web_page'] })

  return <>
    <div className={bem('container')}>
      <div className={bem('info')}>
        <Icon name="rejection_icon" className={bem('rejection_icon')} />
        <h4><FormattedMessage {...messages['rejection_title']} /></h4>
        <p className={bem('status_message')}>
          <FormattedMessage {...messages['rejection_message']} />
        </p>
        <p className={bem('message')}>
          {localizationConfig?.hasEmailContact 
            ? <FormattedHTMLMessage {...messages['contact_email']} />
            : <FormattedHTMLMessage {...messages['contact_number']} />
          }
          <br /><br />
          <span className={bem('web_page')}>
            <Link href={`https://${webPage}`}>
              {webPage}
            </Link>
          </span>
        </p>
      </div>
    </div>
  </>
}

Rejected.propTypes = {
  localizationConfig: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  intl: PropTypes.object
}

export default Rejected
