import {
  BEAUTY_CONSULTANT_PROFILE,
  BIG_CONSULTANT_PROFILE,
  BUSINESS_LEADER_PROFILE,
  BUSINESS_MANAGER_PROFILE,
  ADM_COLLABORATOR_PROFILE,
  ENTREPRENEUR_CONSULTANT_PROFILE,
  FINAL_CONSUMER_PROFILE,
  FRANCHISE_PROFILE,
  SALES_MANAGER_PROFILE,
  SNAC_PROFILE,
  THIRD_PARTY_PROFILE, CCC_PROFILE, MANAGER_PROFILE, DIRECTOR_PROFILE
} from '../../../../NaturaProfiles'
import {
  isChileWithLegoToggleActive,
  isEnableDigitalStoreStepForChileFlowToggleActive
} from '../../../../../../../config'
import { store } from 'src/App.state'

export const personalStepByProfile = {
  [BEAUTY_CONSULTANT_PROFILE]: 'personal-data',
  [BIG_CONSULTANT_PROFILE]: 'big-consultant-personal-data',
  [ENTREPRENEUR_CONSULTANT_PROFILE]: 'entrepreneur-consultant-personal-data',
  [FRANCHISE_PROFILE]: 'franchise-personal-data',
  [BUSINESS_LEADER_PROFILE]: 'business-leader-personal-data',
  [SALES_MANAGER_PROFILE]: 'sales-manager-personal-data',
  [BUSINESS_MANAGER_PROFILE]: 'business-manager-personal-data',
  [ADM_COLLABORATOR_PROFILE]: 'collaborator-personal-data',
  [THIRD_PARTY_PROFILE]: 'third-party-personal-data',
  [SNAC_PROFILE]: 'snac-personal-data',
  [FINAL_CONSUMER_PROFILE]: 'final-consumer-personal-data',
  [CCC_PROFILE]: 'ccc-personal-data',
  [MANAGER_PROFILE]: 'manager-personal-data',
  [DIRECTOR_PROFILE]: 'director-personal-data',
}

export const buildFlows = () => {
  const { user: { user } } = store.getState()
  const initialStep = 'pre-register'

  const shouldEnableBusinessModelSteps = user && user.isCSC && isChileWithLegoToggleActive()
  const businessModelStep = 'business-model'
  const defaultFlowSteps = [
    'address',
    'documentation',
    'documents',
    'indication'
  ]
  const digitalStoreStep = 'digital-store'
  const finalStep = 'completed'

  const getFlowSteps = profile => [initialStep]
    .concat(shouldEnableBusinessModelSteps ? [businessModelStep] : [])
    .concat([personalStepByProfile[profile]])
    .concat(defaultFlowSteps)
    .concat(isEnableDigitalStoreStepForChileFlowToggleActive() ? [digitalStoreStep] : [])
    .concat([finalStep])
    .filter(step => step !== null)

  if (shouldEnableBusinessModelSteps) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: getFlowSteps(BEAUTY_CONSULTANT_PROFILE),
      [BIG_CONSULTANT_PROFILE]: getFlowSteps(BIG_CONSULTANT_PROFILE),
      [ENTREPRENEUR_CONSULTANT_PROFILE]: getFlowSteps(ENTREPRENEUR_CONSULTANT_PROFILE),
      [FRANCHISE_PROFILE]: getFlowSteps(FRANCHISE_PROFILE),
      [BUSINESS_LEADER_PROFILE]: getFlowSteps(BUSINESS_LEADER_PROFILE),
      [SALES_MANAGER_PROFILE]: getFlowSteps(SALES_MANAGER_PROFILE),
      [BUSINESS_MANAGER_PROFILE]: getFlowSteps(BUSINESS_MANAGER_PROFILE),
      [ADM_COLLABORATOR_PROFILE]: getFlowSteps(ADM_COLLABORATOR_PROFILE),
      [THIRD_PARTY_PROFILE]: getFlowSteps(THIRD_PARTY_PROFILE),
      [SNAC_PROFILE]: getFlowSteps(SNAC_PROFILE),
      [FINAL_CONSUMER_PROFILE]: getFlowSteps(FINAL_CONSUMER_PROFILE),
      [CCC_PROFILE]: getFlowSteps(CCC_PROFILE),
      [MANAGER_PROFILE]: getFlowSteps(MANAGER_PROFILE),
      [DIRECTOR_PROFILE]: getFlowSteps(DIRECTOR_PROFILE)
    }
  } else {
    return { [BEAUTY_CONSULTANT_PROFILE]: getFlowSteps(BEAUTY_CONSULTANT_PROFILE) }
  }
}
