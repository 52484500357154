export const ACCEPT_IMAGE_FORMATS = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/tiff',
  'image/tif',
  'image/jpg',
]

const KILOBYTES = 1024
const MEGABYTES = 1024 * KILOBYTES

export const MAX_UPLOAD_SIZE = {
  NATIONAL_REGISTRY: 5 * MEGABYTES,
  DEFAULT: 10000000
}

export const VALID_IMAGE_FORMATS = /\.(png|gif|jpeg|tiff|tif|jpg)$/i

export const VALID_AUDIO_FORMATS = /\.(aac|aif|aifc|aiff|alac|amr|au|caf|flac|htk|iff|x-m4a|mat4|mat5|mp3|ogg|paf|pcm|pvf|raw|sd2|sf|snd|svx|voc|w64|wav|xi|wma|webm)$/i

export const ACCEPT_AUDIO_FORMATS = [
  'audio/aac',
  'audio/aif',
  'audio/aifc',
  'audio/aiff',
  'audio/alac',
  'audio/amr',
  'audio/au',
  'audio/caf',
  'audio/flac',
  'audio/htk',
  'audio/iff',
  'audio/x-m4a',
  'audio/mat4',
  'audio/mat5',
  'audio/mp3',
  'audio/ogg',
  'audio/paf',
  'audio/pcm',
  'audio/pvf',
  'audio/raw',
  'audio/sd2',
  'audio/sf',
  'audio/snd',
  'audio/svx',
  'audio/voc',
  'audio/w64',
  'audio/wav',
  'audio/xi',
  'audio/wma',
  'audio/webm',
  'video/webm',
]
