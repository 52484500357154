import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  attachmentOptionsTitle: {
    defaultMessage: 'Send photo',
    id: 'attachment.options.title'
  },
  attachmentOptionsInstructions: {
    defaultMessage: 'Please, take or attach a photo of the intention of your gallery',
    id: 'attachment.options.instructions'
  },
  attachmentOptionsSubInstructions: {
    defaultMessage: 'You will need to grant access permissions to the camera if the desired option is to take photo',
    id: 'attachment.options.sub.instructions'
  },
  attachmentOptionsTakePhoto: {
    defaultMessage: 'Take photo',
    id: 'attachment.options.take.photo'
  },
  attachmentOptionsAdjust: {
    defaultMessage: 'Adjust',
    id: 'attachment.options.adjust'
  },
  photoPreviewDiscart: {
    defaultMessage: 'Discart',
    id: 'photo.preview.discart'
  },
  photoPreviewConfirm: {
    defaultMessage: 'Confirm',
    id: 'photo.preview.confirm'
  },
})

export default MESSAGES
