import {store} from '../../../../../../App.state'
import { CountryIdAlphaCode3 } from '../../../../../../models/country/country-ids'
import { isChileWithLegoToggleActive, isColombiaWithLegoToggleActive } from '../../../../../../config'

export const localesUsingBRMLight = [
  CountryIdAlphaCode3.ARGENTINA
]
  .concat(isChileWithLegoToggleActive() ? [] : [CountryIdAlphaCode3.CHILE])
  .concat(isColombiaWithLegoToggleActive() ? [] : [CountryIdAlphaCode3.COLOMBIA])

export const getBusinessStructureFor = ({currentRole, localeId}) => {
  const rolesFromStore = getRolesFromStore()

  if (mustMapBusinessStructureFromStore({rolesFromStore, localeId})) {
    const currentRoleInStore = getRoleMatchingBusinessModel({rolesFromStore, currentRole})
    return currentRoleInStore?.salesHierarchyId || currentRole.salesHierarchyId
  }
  return currentRole.salesHierarchyId
}

const getRolesFromStore = () => {
  const rolesFromStore = store.getState()
    ?.person
    ?.person
    ?.roles
    ?.items
  return rolesFromStore || []
}

const mustMapBusinessStructureFromStore = ({rolesFromStore, localeId}) => {
  const localeUsesBRMLight = localesUsingBRMLight.find(item => item === localeId)
  const thereAreRolesInStore = rolesFromStore.length
  return localeUsesBRMLight && thereAreRolesInStore
}

const getRoleMatchingBusinessModel = ({rolesFromStore, currentRole}) => {
  return rolesFromStore.find(role => {
    return role.businessModelId === currentRole.businessModelId
      && role.functionId === currentRole.functionId
      && role.roleId === currentRole.roleId
      && role.substatus === currentRole.substatus
  })
}
