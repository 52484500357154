import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'

export function buildDigitalSpace(deps) {
  const { title, label, subtitle } = getSectionTitle(deps)
  const { loader: { digitalSpaceLoading } } = deps

  return {
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    touchAfterReload,
    step: {
      title,
      subtitle,
      label
    },
    loader: digitalSpaceLoading
  }
}
