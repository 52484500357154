import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { getUserBusinessModel } from 'src/infra/Api/DEFAULT/strategies/getRoles/getRoles'
import { getUserRole } from './strategies/userRoles'

const adapter = validatorAdapter('Yup', yup)

const dependencies = {}

class User extends Entity {
  static SCHEMA = {
    sellerId: adapter(yup.mixed().required()),
    personId: adapter(yup.mixed()),
    name: adapter(yup.string().required()),
    hierarchyId: adapter(yup.string()),
    salesHierarchyLevelId: adapter(yup.string()),
    roles: {
      validator: adapter(yup.object()),
      defaultValue: {}
    },
    authentication: {
      validator: adapter(
        yup
          .object()
          .shape({
            xApikey: yup.string().nullable(),
            accessToken: yup.string(),
            expiresIn: yup.number()
          })
          .required()
      ),
      defaultValue: {}
    },
    rolesByTenant: {
      validator: adapter(yup.object()),
      defaultValue: null
    }
  }


  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, { ...dependencies, ...injection })
  }

  get userRoleInstance() {
    this.rolesByTenant = getUserRole(this.roles)
    return this.rolesByTenant
  }

  get businessModel() {
    const countryRoles = getUserBusinessModel()
    const roleKey = Object.keys(this.roles)[0]
    return countryRoles[roleKey]
  }

  get isFV() {
    return this.userRoleInstance?.isFV
  }

  get isCN() {
    return !!this.roles.CN
  }

  get isCNO() {
    return !!this.roles.CNO
  }

  get isCSC() {
    return !!this.roles.CSC
  }

  get isBM() {
    return !!this.roles.BM
  }

  get isGV() {
    return this.userRoleInstance.isGV
  }

  get isGR() {
    return this.userRoleInstance?.isGR
  }

  get hasOnlySellerIdError() {
    return JSON.stringify(Object.keys(this.errors)) === '["sellerId"]'
  }

  get isValidCSC() {
    return this.hasOnlySellerIdError
  }

  get canRegister() {
    const isValid = this.valid || (this.userRoleInstance?.isCSC && this.isValidCSC)
    return !!isValid && !this.isCN && this.userRoleInstance?.canRegister === true
  }

  get shouldRedirectToRegister() {
    return this.userRoleInstance.shouldRedirectToRegister
  }

  get shouldRedirectToReports() {
    return this.userRoleInstance.shouldRedirectToReports
  }

  get authenticated() {
    const { authentication, name } = this.errors

    return !authentication && !name
  }

  get isSelfRegistration() {
    return this.sellerId === this.userRoleInstance?.user.SELF_REGISTRATION
  }

  get canDoSelfRegistration() {
    return this.userRoleInstance.canDoSelfRegistration
  }

  get shouldLoadCandidateInformation() {
    return this.userRoleInstance.shouldLoadCandidateInformation
  }

  get canEditInSelfRegistrationFlow() {
    return this.userRoleInstance.canEditInSelfRegistrationFlow
  }

  get canAddMultiplePhones() {
    return this.userRoleInstance.canAddMultiplePhones
  }
}

export default User
