import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const Messages = defineMessages({
  approved_title: {
    defaultMessage: 'Approved registration',
    id: 'completed.page.title.approved'
  },
  payment: {
    defaultMessage: 'Payment',
    id: 'completed.page.payment'
  },
  prepaid_payment: {
    defaultMessage: 'Prepaid',
    id: 'completed.page.prepaid_payment'
  },
  credit_payment: {
    defaultMessage: 'Credit',
    id: 'completed.page.credit_payment'
  },
  denied_payment: {
    defaultMessage: 'Denied',
    id: 'completed.page.denied_payment'
  },
  payment_condition: {
    defaultMessage: 'Payment Condition',
    id: 'completed.page.payment_condition'
  },
  subtitle_1: {
    defaultMessage: 'Wait for an email from Natura in your inbox',
    id: 'completed.page.subtitle_1'
  },
  next_steps: {
    defaultMessage: 'Next Steps',
    id: 'completed.page.next_steps'
  },
  step_1: {
    defaultMessage:
      'You will receive an email with <strong>your consultant code</strong> and <strong>password</strong> to enter the <strong>My Business website</strong> and <strong>My Business App.</strong>',
    id: 'completed.page.step_1'
  },
  step_2: {
    defaultMessage:
      'Enter <strong>minegocio.natura.com.pe</strong> or download the <strong>My Business app</strong> to make your first access with the user you received and find all the tools that will help you with your first order.',
    id: 'completed.page.step_2'
  },
  step_3: {
    defaultMessage:
      'Click on <strong>"New order"</strong>, add the products you want to buy and start having the best sales! In your first order you will receive your <strong>Natura Start Kit.</strong>',
    id: 'completed.page.step_3'
  },
  business_leader_title: {
    defaultMessage: 'Business Leader',
    id: 'completed.page.business_leader_title'
  },
  business_leader_content: {
    defaultMessage:
      'You have been assigned a <strong>Natura Business Leader</strong>, who is the right person to answer your questions and guide you as a consultant. <strong>Your Leader will contact you</strong> in the next few days.',
    id: 'completed.page.business_leader_content'
  },
  digital_store_title: {
    defaultMessage: 'Digital Store',
    id: 'completed.page.digital_store_title'
  },
  digital_store_content: {
    defaultMessage:
      'Natura takes charge of the dispatch, and you will receive the commission in your Mercado Pago account, to open your digital store it is necessary to <strong>link with your Mercado Pago account</strong>, we <strong>send you a message</strong> in your email registered in Natura to make the link. <p class="small-text"> This will be the domain of your store: </p> <p class="link-text"> <strong> www.natura.com.pe/consultoria/{digitalStoreName} </strong></p>',
    id: 'completed.page.digital_store_content'
  },
  digital_store_warning_title: {
    defaultMessage: 'Attention!',
    id: 'completed.page.digital_store_warning_title'
  },
  digital_store_warning_content: {
    defaultMessage:
      'It is important to link your account with Mercado Pago, because without linking your digital store will not open. <strong>If you did not receive the email</strong>, please check your spam or electronic junk box.',
    id: 'completed.page.digital_store_warning_content'
  },
  processing_title: {
    defaultMessage: 'In Process',
    id: 'completed.page.processing_title'
  },
  processing_message: {
    defaultMessage: 'Thanks for registering!',
    id: 'completed.page.processing_message'
  },
  processing_message_for_fv_user: {
    defaultMessage: 'Thank you for registering!',
    id: 'completed.page.processing_message_for_fv_user'
  },
  processing_instructions: {
    defaultMessage: 'We are reviewing your data and we\'ll send you a confirmation e-mail in the next 24 hours ;)',
    id: 'completed.page.processing_instructions'
  },
  processing_instructions_for_fv_user: {
    defaultMessage: 'We are reviewing your data and we\'ll send you a confirmation e-mail in the next 24 hours',
    id: 'completed.page.processing_instructions_for_fv_user'
  },
  rejection_title: {
    defaultMessage: 'Rejected',
    id: 'completed.page.rejection_title'
  },
  rejection_message: {
    defaultMessage:
      "Your registration was not approved since it did not attend the Natura's business policy",
    id: 'completed.page.rejection_message'
  },
  processing_subtitle_1: {
    defaultMessage:
      'Now it is up to us. <br/> We will verify your information and send you a confirmation email within 24 hours. <br/> okay? ;)',
    id: 'completed.page.processing_subtitle_1'
  },
  web_page: {
    defaultMessage: 'www.natura.com.br',
    id: 'completed.page.web_page'
  },
  web_page_pathname: {
    defaultMessage: '/atendimento',
    id: 'completed.page.web_page_pathname'
  },
  contact_email: {
    defaultMessage: 'If you have questions, contact <strong>register@natura.net</strong>.',
    id: 'completed.page.contact_email'
  },
  contact_number: {
    defaultMessage:
      'If you have questions, contact <strong>(01) 311 0031</strong> or contact NAT, our virtual assistant on <strong>WhatsApp</strong> at <strong>+55 32 9937-4604</strong>, Monday through Friday from 8am to 10pm, Saturdays and holidays from 9am to 4pm.',
    id: 'completed.page.contact_number'
  },
  contact_number_third_party: {
    defaultMessage:
      'In a short time the registration process will be completed. <br/> Remember to check it in your Registration Reports in MiNegocio<br/> Maximum processing time 12 hours, if after this time your registration is not yet approved call (01) 3110031, Monday to Friday from 8 a.m. to 10pm, Saturdays and holidays from 9am to 4pm.',
    id: 'completed.page.contact_number_third_party'
  },
  contact_number_third_party_for_fv_user: {
    defaultMessage:
      'In a short time the registration process will be completed.<br/> Remember to check it in your Registration Reports in MiNegocio<br/> Maximum processing time 12 hours, if after this time your registration is not yet approved call (01) 3110031, Monday to Friday from 8 a.m. to 10pm, Saturdays and holidays from 9am to 4pm',
    id: 'completed.page.contact_number_third_party_for_fv_user'
  },
  your_consultant_code: {
    defaultMessage: 'Your consultant code is:',
    id: 'completed.page.your_consultant_code'
  },
  payment_method: {
    defaultMessage: 'Payment Method:',
    id: 'completed.page.payment_method'
  },
  welcome_greeting: {
    defaultMessage: 'Welcome!',
    id: 'completed.page.welcome_greeting'
  },
  welcome_message: {
    defaultMessage: 'In a few moments you will receive an email from Natura.',
    id: 'completed.page.welcome_message'
  },
  download_app: {
    defaultMessage: 'Download the Mi Negocio App at:',
    id: 'completed.page.download_app'
  },
  google_play_banner: {
    defaultMessage: 'per_google_play.svg',
    id: 'completed.page.google_play_banner'
  },
  app_store_banner: {
    defaultMessage: 'per_app_store.svg',
    id: 'completed.page.app_store_banner'
  },
  indicator_action: {
    defaultMessage: 'I was indicated',
    id: 'completed.page.indicator_action'
  },
  indicator_label: {
    defaultMessage:
      'If you had an indication from a Natura Consultant, click on the button below:',
    id: 'completed.page.indicator_label'
  },
  indication_name_label: {
    defaultMessage: 'You were indicated by:',
    id: 'completed.page.indicator_name_label'
  }
})

export function getSectionTitle({ messages = Messages }, { title }) {
  return {
    title: <FormattedMessage {...messages[title]} />,
    messages
  }
}
