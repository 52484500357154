import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  title: {
    defaultMessage: 'Warning',
    id: 'deniedAccess.title'
  },
  warning: {
    defaultMessage: 'For the chosen option it is necessary to grant the requested permissions.',
    id: 'deniedAccess.warning'
  },
  changeBrowserPermittions: {
    defaultMessage: 'Access your browser settings and allow access to the camera or microphone device.',
    id: 'deniedAccess.changeBrowserPermittions'
  }
})

export default MESSAGES
