import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'neighborhood',
    label: intl.formatMessage(messages['neighborhood']),
    placeholder: intl.formatMessage(messages['neighborhood_placeholder']),
    noOptionsMessage: intl.formatMessage(
      messages['neighborhood_no_options_message']
    ),
    type: connect(mapStateToProps)(Select),
    required: true,
    onChange: clearMapAddress,
    disabledBy: ['address[].department', 'address[].city']
  }
}

function clearMapAddress(props) {
  const {
    schema: {
      parent,
      group: { id }
    },
    setFieldValue,
    setFieldTouched
  } = props

  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.complement`, false)
  setFieldTouched(`${parent}.references`, false)
}

const calleds = {}

function mapStateToProps({ addresses }, ownprops) {
  const {
    addressesPeopleManagement,
    loading: { neighborhoods: neighborhoodLoading }
  } = addresses
  const { neighborhoods, getLevels } = addressesPeopleManagement
  const {
    group: { index },
    form: {
      values: { address }
    }
  } = ownprops
  const { department, city } = address[index]

  const loading = neighborhoodLoading && neighborhoodLoading[city]
  const options = neighborhoods[city] || []

  const shouldGetNeighborhoods =
    !options.length && department && city && !loading

  if (shouldGetNeighborhoods && !calleds[city]) {
    calleds[city] = true

    getLevels({
      geoStructureType: 'neighborhoods',
      queryParams: `level=3&parentLevel=2&parentCode=${city}`,
      geoStructureCode: city
    })
  }

  return {
    ...ownprops,
    options,
    loading
  }
}
