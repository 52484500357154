import { defineMessages } from 'react-intl'
import { checkSpecialCharacters, lettersOnly, lettersOnlyPaste } from './Name.full'

const messages = defineMessages({
  lastName: {
    defaultMessage: 'Surname',
    id: 'form.field.lastName'
  },
  lastNameSpecialCharacters: {
    defaultMessage: 'Special characters are not allowed',
    id: 'form.field.last.name.special.characters'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { lastName: defaultValue }} = injection

  const messageSpecialCharacters = intl.formatMessage(messages['lastNameSpecialCharacters'])

  return {
    id: 'lastName',
    defaultValue,
    label: intl.formatMessage(messages['lastName']),
    onKeyDown: lettersOnly,
    onPaste: lettersOnlyPaste,
    validations: checkSpecialCharacters(messageSpecialCharacters),
  }
}
