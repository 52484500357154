import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import TagManager from 'react-gtm-module'
import Modal from 'src/base/Modal'
import Icon from 'src/base/Icon'
import LinearProgressBar from 'src/base/LinearProgressBar'
import MESSAGES from './OcrModals.messages'

import './OcrModals.styl'

const bem = bemClassName.bind(null, 'ocr-modals')

const TEN_SECONDS = 10 * 1000
const COMPLETED_VALUE = 100
const INITIAL_VALUE = 0

class OcrModals extends Component {
  constructor(props) {
    super(props)

    this.state = { completed: 0 }

    this.closeModal = this.closeModal.bind(this)
    this.validationStatus = this.validationStatus.bind(this)
  }

  componentDidMount() {
    const { isLegacy } = this.props

    this.progress(INITIAL_VALUE)

    if (isLegacy) {
      this.validationStatus()
      this.validateInterval = setInterval(this.validationStatus, TEN_SECONDS)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isLegacy, ocr, personId, loading, intl, open } = this.props

    const noPropsChange = (
      isLegacy === nextProps.isLegacy &&
      ocr === nextProps.ocr &&
      personId === nextProps.personId &&
      loading === nextProps.loading &&
      intl === nextProps.intl &&
      open === nextProps.open
    )

    const noStateChange = this.state === nextState

    return !(noPropsChange && noStateChange)
  }

  componentWillUnmount() {
    this.clear()
  }

  clear() {
    clearTimeout(this.progressTimeout)
    clearInterval(this.validateInterval)
  }

  validationStatus() {
    const { ocr, personId } = this.props
    const { checkOcr, progress } = ocr

    if (progress) return checkOcr(personId)

    return this.clear()
  }

  progress(percentageCompleted) {
    const { loading, ocr: { progress } } = this.props

    if ((percentageCompleted >= COMPLETED_VALUE)) {
      return this.setState(
        { expired: true, completed: COMPLETED_VALUE },
        this.clear
      )
    }

    if (loading || progress) {
      this.progressTimeout = setTimeout(() => {
        this.progress(percentageCompleted + 0.7)
      }, 770)

      this.setState({ completed: percentageCompleted })
    }

  }

  closeModal() {
    const { expired } = this.state

    this.clear()
    this.props.oncloseModal({ expired })
  }

  pushDataLayer(intl, status, success) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationDocumentValidation',
        contextText: intl.formatMessage(MESSAGES[`${status}Title`]),
        validationSuccess: success
      }
    })
  }

  render() {
    const {
      intl,
      open,
      ocr: { status, progress },
      loading
    } = this.props

    return (
      <Modal
        width={425}
        open={open}
        onClose={this.closeModal}
        accept={!(progress || loading)}
        acceptLabel={intl.formatMessage(MESSAGES[`${status}AcceptLabel`])}
      >
        {this.renderContentModal()}
      </Modal>
    )
  }

  renderContentModal() {
    const {
      ocr,
      loading,
    } = this.props
    const { progress } = ocr
    const { expired } = this.state


    if (expired) return this.renderExpiredModal()

    if (progress || loading) return this.renderProgressModal()

    return this.renderStatusModal()
  }

  renderExpiredModal() {
    const { intl } = this.props

    return (
      <div>
        {this.renderIcon()}
        <h3 className={classNames(bem('title', 'error'))}>
          {intl.formatMessage(MESSAGES.expiredTitle)}
        </h3>
        <div className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES.expiredContent)}
        </div>
      </div>
    )
  }

  renderStatusModal() {
    const { intl, ocr } = this.props
    const { status, success, error } = ocr

    this.pushDataLayer(intl, status, success)

    return (
      <div>
        {this.renderIcon()}
        <h3 className={classNames(bem('title', { error, success }))}>
          {intl.formatMessage(MESSAGES[`${status}Title`])}
        </h3>
        <div className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES[`${status}Content`])}
        </div>
      </div>
    )
  }

  renderProgressModal() {
    const { intl } = this.props

    return (
      <div>
        <h3 className={classNames(bem('title'))}>
          {intl.formatMessage(MESSAGES.progressTitle)}
        </h3>
        <div className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES.progressContent)}
        </div>
        {this.renderLinearProgressBar()}
      </div>
    )
  }

  renderIcon() {
    const {
      ocr: { success }
    } = this.props
    const name = success ? 'approved' : 'deny'

    return (
      <div className={classNames(bem('icon'))}>
        <Icon name={name} custom={true} size={75} />
      </div>
    )
  }

  renderLinearProgressBar() {
    const { completed } = this.state

    return <LinearProgressBar className={classNames(bem('linearProgressBar'))} completed={completed} />
  }
}

OcrModals.propTypes = {
  open: PropTypes.bool,
  isLegacy: PropTypes.bool,
  loading: PropTypes.bool,
  intl: PropTypes.object,
  ocr: PropTypes.object,
  personId: PropTypes.string,
  oncloseModal: PropTypes.func
}

export default injectIntl(OcrModals)
