import { ROLES } from 'src/viewModels/BusinessModel'

import { defineMessages } from 'react-intl'
import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'
import {getCountryByUrl} from "src/utils/getCountryByUrl"

const messages = defineMessages({
  business_model_role: {
    defaultMessage: 'Business Model Role',
    id: 'form.field.business.model.role'
  },
  business_model_role_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.business.model.role.placeholder'
  },
  business_model_role_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.business.model.role.no.options.message'
  },
  business_model_role_option_consultant: {
    defaultMessage: 'Consultant',
    id: 'form.field.business.model.role.option.consultant'
  }
})

const dependencies = {
  messages
}

const translateDependencies = {}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  Object.assign(translateDependencies, { intl })

  return {
    id: 'roleId',
    label: intl.formatMessage(messages['business_model_role']),
    placeholder: intl.formatMessage(messages['business_model_role_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['business_model_role_no_options_message']),
    type: connect(mapStateToProps)(Select),
    required: true,
    disabledBy: [ 'businessModel[].businessModelId' ],
    onChange
  }
}

function onChange({ setFieldValue, setFieldTouched, schema }) {
  const { parent, group: { id } } = schema

  setFieldValue(`${parent}.functionId`, '', id)
  setFieldTouched(`${parent}.functionId`, false)
}

function mapStateToProps(state, ownProps) {
  const { businessModel: { businessModel }, user: { user }, locale: { locale }, connectivity } = state
  const { form: { values: { businessModel: [formBusinessModel] } } } = ownProps

  const options = getOptions({
    formBusinessModel,
    businessModel,
    connectivity,
    locale,
    user
  })

  return {
    ...ownProps,
    options,
  }
}

function getOptions({ businessModel, formBusinessModel, connectivity, user, locale }) {
  const { configs: { localization } } = locale
  const { businessModelId } = formBusinessModel
  const { items } = businessModel
  const { intl } = translateDependencies
  const consultantRoleId = 1
  const availableCountries = process.env.DISABLE_REGISTER_CONSULTANT_OPTION?.split(
    ','
  )
  const isActivatedToCountry = availableCountries?.includes(getCountryByUrl())

  let { roles = [] } = items.find(findBusinessModel, { businessModelId }) || {}

  if (roles.length && isActivatedToCountry) {
    const filteredRoles = roles.filter(
      (item) => item.data.roleId !== consultantRoleId
    )
    roles = [...filteredRoles]
  }

  const shouldShowBusinessModelFromApi =
    localization.getBusinessModelFromApi &&
    !connectivity.isOffline &&
    user.isCSC

  if (shouldShowBusinessModelFromApi) {
    const deps = {
      intl,
      messages,
      filterType: 'business_model_role_option'
    }

    return roles.map(translateRoleOptions, deps)
  }

  return [{
    label: intl.formatMessage(messages.business_model_role_option_consultant),
    value: ROLES.CONSULTANT
  }]
}

function findBusinessModel({ businessModelId }) {
  return businessModelId === this.businessModelId
}

export function translateRoleOptions(option) {
  const { label, value } = option

  const entries = Object.entries(ROLES)
  const [ key ] = entries.find(findByName, { value }) || []

  if (!key) return { ...option, label, value }

  const message = this.messages[`${this.filterType}_${key.toLowerCase()}`]

  return { ...option, label: this.intl.formatMessage(message), value }
}

function findByName([, value]) {
  return value === this.value
}
