import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const DocumentBackPhotoIcon = ({ size, state }) => {
  const styles = {
    fill: StateColors[state]
  }
  return (
    <svg width={size} height={size} viewBox="0 0 244 259">
      <path
        style={styles}
        d="M222.5,216a3.5,3.5,0,0,1-3.5-3.5V25.5a3.5,3.5,0,0,1,7,0v187A3.5,3.5,0,0,1,222.5,216Z"
      />
      <path
        style={styles}
        d="M221-.06H92A23,23,0,0,0,69,23V122.5a3.5,3.5,0,0,0,3.5,3.5h0a3.5,3.5,0,0,0,3.5-3.5V23a16,16,0,0,1,16-16H221a16,16,0,0,1,16,16V215a16,16,0,0,1-16,16H155.5a3.5,3.5,0,0,0-3.5,3.5h0a3.5,3.5,0,0,0,3.5,3.5H221a23,23,0,0,0,23-23V23A23,23,0,0,0,221-.06Z"
      />
      <path
        style={styles}
        d="M71.69,166.68a35.43,35.43,0,1,0,35.44,35.43A35.47,35.47,0,0,0,71.69,166.68Zm0,63.36a27.93,27.93,0,1,1,27.94-27.94A28,28,0,0,1,71.69,230Z"
      />
      <path
        style={styles}
        d="M121.48,156.12h-7.84a7.34,7.34,0,0,1-6.16-4.34l-2.69-7.49A14.86,14.86,0,0,0,91.56,135H51.82a14.88,14.88,0,0,0-13.22,9.29l-2.69,7.5a7.34,7.34,0,0,1-6.16,4.33H21.91A21.87,21.87,0,0,0,.06,178v58.84a21.87,21.87,0,0,0,21.85,21.85h99.57a21.87,21.87,0,0,0,21.85-21.85V178A21.88,21.88,0,0,0,121.48,156.12Zm14.36,81,0,.4a14.33,14.33,0,0,1-14.34,13.68H21.91A14.37,14.37,0,0,1,7.55,236.81V178a14.37,14.37,0,0,1,14.35-14.35h7.84A14.86,14.86,0,0,0,43,154.32l2.69-7.49a7.37,7.37,0,0,1,6.17-4.34H91.57a7.36,7.36,0,0,1,6.16,4.34l2.69,7.49a14.86,14.86,0,0,0,13.22,9.29h7.83A14.37,14.37,0,0,1,135.84,178Z"
      />
      <path
        style={styles}
        d="M184.47,45.58a35.06,35.06,0,0,1,2,26,32.87,32.87,0,0,1-6.1,11.3c-2.6,3.13-5.71,5.89-7.42,9.66-3.72,8.17,1.19,15.85,7.07,21.42a43.22,43.22,0,0,1,4.44,4.44c1.76,2.24,2.86,4.13-.41,5-2.88.72-5.7,1.21-6,4.84-.26,3.45,2.89,4.84,5.22,6.62,3.6,2.75,8.52,9.84,1.43,11.83-3.54,1-8.35,0-12-.22-4.66-.34-9.31-.81-14-1.26-4.34-.42-8.68-.84-13-1.14-3.06-.21-6.55-.71-8.05,2.6s1,5.45,3.17,7.54c3.59,3.46,7.32,6.77,11.17,9.93A250.55,250.55,0,0,0,205.94,198c4.35,2,8.17-4.45,3.79-6.48A251.72,251.72,0,0,1,175.3,172.1c-5.71-3.83-11.28-7.87-16.62-12.2q-3.92-3.18-7.68-6.54c-1.45-1.3-.23-1.42,2.82-1.14,4.17.38,8.33.81,12.5,1.19,6.46.59,14,2,20.41.49,5.73-1.37,16.28-5.9,6.39-20-4.52-6.43-5.11-4.94-.39-7.19,3.47-1.65,1-7.65-.78-10.73-2.28-3.86-6-6.39-8.94-9.68-1.87-2.1-4-4.91-3.93-7.91.06-3.17,2.56-5.7,4.53-7.91,12.13-13.59,15.63-32.12,7.34-48.73-2.15-4.32-8.63-.53-6.48,3.79Z"
      />
    </svg>
  )
}

DocumentBackPhotoIcon.propTypes = {
  size: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
}

export default DocumentBackPhotoIcon
