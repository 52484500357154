import * as yup from 'yup'

import { documentValidation } from 'src/configs/Fields/Documents'
import createFields from './Search.fields'
import { DOCUMENTS } from 'src/models/Document'

export const CANDIDATE_ID = 'candidateId'
export const touchAfterReload = ['documents[0].type']

export function createSearchConfig(dependencies) {
  const DOCUMENTS = Builder(dependencies)

  return {
    touchAfterReload,
    fieldsSchema: [
      [DOCUMENTS]
    ],
    onSubmit: onSubmit(dependencies)
  }
}

export default function Builder(dependencies) {
  const { locale: { configs: { localization } }, people } = dependencies
  const { queryParams: { search: { document, documentTypeId, candidateId } } } = people

  const FIELDS = createFields(dependencies)

  return {
    id: 'documents',
    min: 1,
    max: 1,
    defaultValue: [{
      document: document || candidateId,
      type: documentTypeId || candidateId && CANDIDATE_ID
    }],
    fields: [FIELDS],
    validations: yup.array().of(yup.object().shape({
      document: documentValidation('type', localization),
      type: yup.mixed()
    }))
  }
}

const normalizationByDocumentTypeId = {
  [DOCUMENTS.DNI_ARGENTINA]: (maskedValue) => maskedValue.replace(/\./g, '')
}

function onSubmit({ people }) {
  return function ({ documents: [{ document, type: documentTypeId }] }) {
    if (documentTypeId === CANDIDATE_ID) {
      return people.search({ [CANDIDATE_ID]: document })
    }
    const normalizationFunction = normalizationByDocumentTypeId[documentTypeId]
    const normalizedDocument = normalizationFunction ? normalizationFunction(document) : document
    return people.search({ 'document': normalizedDocument, documentTypeId })
  }
}
