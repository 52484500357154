import {
  isChileWithLegoToggleActive,
  isPreRegisterMfeToggleActive
} from 'src/config'
import {
  buildFormSchema,
  touchAfterReload
} from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegister/FormSchema'
import { getSectionTitle } from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegister/Messages.js'
import { onSubmition } from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegister/Submit'
import { onSubmitionMfe } from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegister/SubmitMfe'
import { CountryIdAlphaCode2 } from 'src/models/country/country-ids'

export function buildPreRegisterProps(deps) {
  const { title, subtitle, label } = getSectionTitle(deps)
  const {
    loader: { personLoading, personVerifyLoading }
  } = deps

  return {
    disabledSubmit: true,
    formSchema: buildFormSchema(deps),
    loader: personLoading || personVerifyLoading,
    onSubmit: onSubmition(deps),
    shouldWaitForDeps: false,
    step: {
      title,
      subtitle,
      label
    },
    touchAfterReload
  }
}

export function buildPreRegisterPropsMicrofrontend(deps) {
  const {
    locale: { id, tenantId, companyId, region },
    user: { sellerId, authenticated, isCSC, authentication, roles }
  } = deps

  return {
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'preRegister',
      identityInfo: {
        countryId: id,
        tenantId,
        companyId,
        shouldCreatePerson:
          isPreRegisterMfeToggleActive() &&
          !shouldNotCreatePerson(authenticated, isCSC, region),
        authorization: authenticated ? authentication.accessToken : ''
      },
      authenticatedUser: {
        authenticated,
        sellerId,
        userRole: Object.keys(roles)[0]
      },
      onSubmit: isPreRegisterMfeToggleActive()
        ? onSubmitionMfe(deps)
        : onSubmition(deps)
    }
  }
}

export function shouldNotCreatePerson(authenticated, isCSC, region) {
  const getLocalesByToggle = () => {
    const locales = [CountryIdAlphaCode2.MALAYSIA]

    if (isChileWithLegoToggleActive()) {
      locales.push(CountryIdAlphaCode2.CHILE)
    }

    return locales
  }

  const locales = getLocalesByToggle()
  return authenticated && isCSC && locales.includes(region)
}
