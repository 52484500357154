import React, { Fragment } from 'react'
import { defineMessages } from 'react-intl'
import bemClassName from 'bem-classname'
import { isDefaultFlowToggleActive } from '../../../config'

const FIELD_ID = 'identificationCardFront'
const messages = defineMessages({
  identificationCardFront: {
    defaultMessage: 'Identification card front',
    id: 'form.field.identificationCardFront'
  },
  identificationCardFront_sublabel: {
    defaultMessage:
      'The photo must be taken from the original document (not photocopies).',
    id: 'form.field.identificationCardFront.sublabel'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const bem = bemClassName.bind(
    null,
    isDefaultFlowToggleActive() ? 'defaultFile' : 'file'
  )
  const messages = { ...dependencies.messages, ...injection.messages }
  const label = intl.formatMessage(messages[FIELD_ID])
  const sublabel = intl.formatMessage(messages[`${FIELD_ID}_sublabel`])

  return {
    node: (
      <Fragment>
        <div className={bem('title')}>{label}</div>
        <p className={bem('subtitle')}>{sublabel}</p>
      </Fragment>
    )
  }
}
