function mapOtherAddress(address) {
  const {
    addressType,
    geographicalLevels,
    addressUse,
    sequence
  } = address
  const mappedGeographicalLevels = [...geographicalLevels]
  mappedGeographicalLevels.forEach(level => {
    delete level.label
  })

  return {
    addressType: parseAddressTypeAndAddressUse(addressType),
    addressUse: addressUse.map(parseAddressTypeAndAddressUse),
    geographicalLevels: mappedGeographicalLevels,
    sequence
  }
}

function parseAddressTypeAndAddressUse({ id, description }) {
  return { id, description }
}

export function getOtherAddress(personAddresses, mailingSequence, deliverySequence) {
  const otherAddresses = personAddresses.filter(address =>
    address.sequence !== mailingSequence && address.sequence !== deliverySequence
  )

  const parsedOtherAddresses = otherAddresses.map(mapOtherAddress)
  return parsedOtherAddresses
}
