import { connect } from 'react-redux'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'region',
    label: intl.formatMessage(messages['region']),
    placeholder: intl.formatMessage(messages['region_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['region_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: validateFieldDependencies,
    required: true,
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.department`, '', id)
  setFieldValue(`${parent}.province`, '', id)
  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.street`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.department`, false)
  setFieldTouched(`${parent}.province`, false)
  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.street`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function mapStateToProps({ addresses, connectivity, locale }, ownProps) {
  const { addresses: { regions: regionsCMM, getAddressesCMM }, addressesPeopleManagement, loading, error } = addresses
  const { locale: {configs: { localization: { shouldGetAddressFromBff }}}}  = locale
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { countryName } = address[index] || {}
  const { getLevels, regions: regionsBFF } = addressesPeopleManagement

  const regions = shouldGetAddressFromBff ? regionsBFF : regionsCMM
  const options = regions[countryName]

  if (shouldGetRegions({ options, loading, error, countryName, connectivity })) {
    getRegions({ getAddressesCMM, getLevels, shouldGetAddressFromBff })
  }

  return {
    ...ownProps,
    options: options || [],
    loading: loading.regions,
    onChange: (value, label) => {
      getDepartmentsByRegion({ value, getLevels, getAddressesCMM, shouldGetAddressFromBff })
      onChange(value, label)
    }
  }
}

const shouldGetRegions = ({ options, loading, error, countryName, connectivity }) => {
  return hasNoOptions({ options, loading, error, countryName }) && !isOffline(connectivity)
}

const hasNoOptions = ({ options, loading, error, countryName }) => !options && !loading.regions && !error.addresses && countryName

const isOffline = (connectivity) => connectivity.isOffline

const getRegions = ({ getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if(shouldGetAddressFromBff) {
    getLevels({ geoStructureType: 'regions', queryParams: 'level=1' })
  } else {
    getAddressesCMM()
  }
}

const getDepartmentsByRegion = ({ value, getLevels, getAddressesCMM, shouldGetAddressFromBff }) => {
  if (!value) return

  if (shouldGetAddressFromBff) {
    getLevels({
      geoStructureType: 'departments',
      queryParams: `level=2&parentLevel=1&parentCode=${value}`,
      geoStructureCode: value
    })
  } else {
    getAddressesCMM({
      geoStructureType: 'departments',
      geoStructureCode: value
    })
  }
}
