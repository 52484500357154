import { getContext } from 'src/infra/Api/Api.context'
import Person from 'src/models/Person'

const PROFILE_RESET = 'natura-caduni/Person/PROFILE_RESET'
export const types = [PROFILE_RESET]

export function personResetReducer(state, action, dependencies) {
  return {
    loading: false,
    error: false,
    person: new Person({}, dependencies)
  }
}

export function resetPerson() {
  const { dispatch } = getContext()

  return dispatch({
    type: PROFILE_RESET
  })
}
