import * as yup from 'yup'

import { defineMessages } from 'react-intl'
import { ACCEPT_IMAGE_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'
import { messagesValidations } from 'src/configs/Fields/Attachments/Helpers'
import { CONFIGS } from 'src/models/Attachment'
import { MAX_UPLOAD_SIZE } from '../../../../viewModels/Upload/Upload.formats'

const MAX_FILE_SIZE = 5

const messages = defineMessages({
  identificationCardFront: {
    defaultMessage: 'Icon Front',
    id: 'icon.label.front'
  },
  identificationCardBack: {
    defaultMessage: 'Icon Back',
    id: 'icon.label.back'
  },
  ocrAction: {
    defaultMessage: 'Validate documentation',
    id: 'form.field.ocr.action'
  },
  ocr_invalid: {
    defaultMessage: 'The document is invalid',
    id: 'form.field.attachment.ocr.invalid'
  }
})
const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    locale: {
      id: localeId,
      configs: { localization }
    },
    person: { attachments, documents },
    required,
    cardId,
    title,
    errorMessage,
    subtitle,
    user
  } = injection

  const {
    documents: documentsConfigs,
    ocr: { validations = [] }
  } = localization

  const { type: documentType } =
    documents.find(findDocuments, { documents: documentsConfigs.personal }) ||
    {}

  const { attachmentsToValidate = [] } =
    validations.find(findOCRValidation, { documentType }) || {}

  const attachmentsIds = CONFIGS[localeId][documentType]
  const CARD_TYPE = attachmentsIds && attachmentsIds[cardId]

  const isOCRValidation = !!attachmentsToValidate.length && !user.isCSC
  const ocrIsValidMessage = intl.formatMessage(messages.ocr_invalid)

  const MESSAGES = {
    size: intl.formatMessage(messagesValidations['maxFileSizeInMB'], {
      maxFileSize: `${MAX_FILE_SIZE}mb`
    })
  }
  const newValidation = isOCRValidation
    ? {
      verified: yup.boolean().oneOf([true], ocrIsValidMessage).required(),
      size: yup.number().when('file', {
        is: (val) => val,
        then: yup
          .number()
          .lessThan(MAX_UPLOAD_SIZE.NATIONAL_REGISTRY, MESSAGES.size)
          .required()
      })
    }
    : {}

  return {
    id: cardId,
    type: 'fileUpload',
    offlineType: 'file',
    accept: ACCEPT_IMAGE_FORMATS.join(' '),
    defaultValue: {
      ...defineDefaultValue(attachments, CARD_TYPE),
      verified: attachmentsToValidate.every(everyAttachmentsVerified, {
        attachments
      })
    },
    success: true,
    customProps: {
      icon: 'frontPhoto',
      iconLabel: messages[cardId] && intl.formatMessage(messages[cardId]),
      isOCRValidation,
      subtitle,
      title,
      errorMessage,
      type: CARD_TYPE
    },
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_IMAGE_FORMATS,
      newValidation,
      required
    }).required()
  }
}

function findDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}

function findOCRValidation({ documentType }) {
  return documentType === this.documentType
}

function findAttachmentById({ type }) {
  return type === this.type
}

function everyAttachmentsVerified({ type }) {
  const attachment = this.attachments.find(findAttachmentById, { type })

  return attachment && attachment.verified
}
