import React from 'react'

export default function Builder(title) {
  const node = (
    <h6 className="form__group__title">
      {title}
    </h6>
  )

  return {
    node
  }
}
