import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

export const touchAfterReload = []

export function buildFormSchema(dependencies) {
  const { intl } = dependencies

  const DOCUMENTS = TYPES.DOCUMENTS(intl, dependencies)

  const fieldsSchema = [
    [DOCUMENTS]
  ]

  return new FormSchema({ fieldsSchema, name: 'PreRegister' })
}
