import { onSubmition } from './Submit'

export function buildPendingSignatureProps(deps) {
  const { locale: { id, tenantId, companyId } } = deps

  return {
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'pendingSignature',
      identityInfo: {
        countryId: id,
        tenantId,
        companyId,
      },
      onSubmit: onSubmition(deps),
    }
  }
}
