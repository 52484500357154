import { useEffect, useState, useRef } from 'react'

export const usePolling = ({
  callback = () => {},
  delay = 4500,
  timeout = 10000,
  timeoutCallback = () => {}
}) => {
  const savedCallback = useRef()
  const savedTimeoutCallback = useRef()
  const [stop, setStop] = useState(false)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    savedTimeoutCallback.current = timeoutCallback
  }, [timeoutCallback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null && !stop) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay, stop])

  useEffect(() => {
    function tick() {
      setStop(true)
      savedTimeoutCallback.current()
    }

    if (timeout !== null) {
      const id = setTimeout(tick, timeout)
      return () => clearTimeout(id)
    }
  }, [timeout])

  return {
    setStop
  }
}
