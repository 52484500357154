const SOURCE_SYSTEM = 7
const COUNTRY = 'MY'
const COUNTRY_ID = 25
const COMPANY_ID = 1
const LANGUAGE = 'en-MY'
const FLOW_TYPE = 'CT'
const URL_GLOBAL_PEOPLE_MANAGEMENT = process.env.GLOBAL_PEOPLE_BASE_URI
const URL_PEOPLE_MANAGEMENT = '/businessrelationship/peoplemanagement/v1'
const URL_PEOPLE = `${URL_PEOPLE_MANAGEMENT}/people`
const URL_GLOBAL_PEOPLE = `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people`
const TENANT_ID = 'fd11f2e7-b101-4e7e-9a92-0b0a76c05264'
const APPLICATION_JSON = 'application/json'

export const MYS_CLIENT = {
  baseURL: process.env.BASE_URI,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
    client_id: process.env.CLIENT_ID,
    country: COUNTRY,
    countryId: COUNTRY_ID,
    language: LANGUAGE,
    sourceSystem: SOURCE_SYSTEM,
    flowType: FLOW_TYPE,
    tenantId: TENANT_ID,
    companyId: COMPANY_ID
  }
}

export const getMalaysiaApiHeaders = () => {
  return {
    client: MYS_CLIENT,
    urlPeople: URL_PEOPLE,
    urlGlobalPeople: URL_GLOBAL_PEOPLE,
    urlGlobalPeopleManagement: URL_GLOBAL_PEOPLE_MANAGEMENT
  }
}


