import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SMSTokenModalContent from './smsTokenModalContent'

class SMSTokenModalContentState extends Component {
  constructor(props) {
    super(props)

    this.state = {
      success: null,
      serverError: false,
      empty: false,
      token: '',
      resendStatus: false,
    }
  }

  componentDidUpdate(prevProps){
    const { isValid, error, resent } = this.props

    if(prevProps.isValid !== isValid) this.setState({ success: isValid })

    if(prevProps.error !== error && error.code === 'VC-0001') return this.setState({ success: false })

    if(prevProps.error !== error) this.setState({ serverError: true })

    if(prevProps.resent !== resent) this.setState({ resendStatus: resent })
  }

  onChange = async value => {
    this.setState({ token: value.target.value })
    this.retry()
  }

  submit = (event) => {
    event.preventDefault()

    const { token } = this.state
    const { smsTokenValidation, person, phone } = this.props

    if (!token) {
      return this.setState({ empty: true })
    }

    smsTokenValidation({ token, person, phone })
  }

  resetResendStatus = () => {
    return this.setState({ resendStatus: false })
  }

  resend = () => {
    const { resendToken, person: { personId }, phone } = this.props

    resendToken({ personId, sequence: phone.sequence })
  }

  retry = () => {
    const initialState = { success: null, serverError: false, empty: false }
    const stateHasChanged = Object.entries(initialState).some(
      ([key, value]) => this.state[key] !== value,
    )

    if (stateHasChanged) this.setState(initialState)
  }

  render() {
    const  { phone: { phone }, closeModal, loading } = this.props
    const { onChange, retry, submit, resend, resetResendStatus } = this

    return React.createElement(SMSTokenModalContent, {
      ...this.state,
      phoneNumber: phone,
      closeModal,
      onChange,
      retry,
      submit,
      resend,
      loading,
      resetResendStatus
    })
  }
}

SMSTokenModalContentState.propTypes = {
  phone: PropTypes.object,
  closeModal: PropTypes.func,
  person: PropTypes.object.isRequired,
  smsTokenValidation: PropTypes.func.isRequired,
  resendToken: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
  resent: PropTypes.bool.isRequired,
}

export default SMSTokenModalContentState
