import { defineMessages } from 'react-intl'
import { ACCEPT_IMAGE_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { CONFIGS } from 'src/models/Attachment'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'

const FIELD_ID = 'proofEconomicLife'
const messages = defineMessages({
  proofEconomicLife: {
    defaultMessage: 'Proof Economic Life',
    id: 'form.field.proofEconomicLife.label'
  }
})
const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id: localeId }, person: { attachments }, required } = injection

  const attachmentsIds = CONFIGS[localeId]
  const TYPE = attachmentsIds[FIELD_ID]
  const defaultValue = defineDefaultValue(attachments, TYPE)

  return {
    id: FIELD_ID,
    type: 'file',
    defaultValue,
    success: true,
    label: intl.formatMessage(messages[FIELD_ID]),
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_IMAGE_FORMATS,
      required
    }),
    accept: ACCEPT_IMAGE_FORMATS.join(' '),
    customProps: {
      type: TYPE,
      icon: 'photo',
      doUpload: true,
    }
  }
}
