import { getSectionTitle } from './Messages'

export function buildCompletedProps(deps) {
  const { intl, locale: { id: localeId } } = deps

  const statusInfo = {
    icon: 'clock',
    title: 'processing_title',
    description: 'processing_description',
    label: 'processing_profile_title',
    loading: 'processing_profile_loading',
  }

  const { title, subtitle, messages } = getSectionTitle(deps, statusInfo)
  const { loader: { personLoading } } = deps

  return {
    statusInfo,
    messages,
    hasIndicationStep: false,
    step: {
      title,
      subtitle
    },
    loader: personLoading,
    intl,
    displayPendingInstructionMessage: false,
    pollingTimeout: getPollingTimeoutInSeconds(localeId)
  }
}

const getPollingTimeoutInSeconds = localeId => {
  const timeoutByLocaleConfig = {
    COL: 120,
    __default__: 90,
  }
  return timeoutByLocaleConfig[localeId] ?? timeoutByLocaleConfig['__default__']
}
