import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  deny_title: {
    defaultMessage: 'Reproved signup',
    id: 'register.completed.page.title.deny'
  },
  deny_description: {
    defaultMessage: 'Your subscription was not approved.',
    id: 'register.completed.page.description.deny'
  },
  approved_title: {
    defaultMessage: 'Approved signup',
    id: 'register.completed.page.title.approve'
  },
  approved_description: {
    defaultMessage: 'You subscription was successful. You will recieve an e-mail with more information and will be contacted by a representant for orientation to become a Natura consultant.',
    id: 'register.completed.page.description.approve'
  },
  processing_title: {
    defaultMessage: 'Processing Signup',
    id: 'register.completed.page.title.processing'
  },
  processing_profile_title: {
    defaultMessage: 'View register',
    id: 'register.completed.page.processing.profile.title'
  },
  processing_profile_loading: {
    defaultMessage: 'Loading...',
    id: 'register.completed.page.processing.profile.loading'
  },
  error_title: {
    defaultMessage: 'Error',
    id: 'register.completed.page.error.title'
  },
  processing_description: {
    defaultMessage: 'You subscription is being processed. You will recieve an e-mail with more information and will be contacted by a representant for orientation to become a Natura consultant.',
    id: 'register.completed.page.description.processing'
  },
  phone_number: {
    defaultMessage: 'Phone number to questions: 0800 7628 872',
    id: 'register.completed.page.phone.number'
  }
})

export function getSectionTitle({ messages = Messages }, { title }) {
  return {
    title: <FormattedMessage {...messages[title]} />,
    messages
  }
}
