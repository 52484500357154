import { getContext } from 'src/infra/Api/Api.context'
import Person from 'src/models/Person'

export const SAVE_PERSON_PAYMENT_PLAN_LOADING =
  'natura-caduni/PaymentPlan/SAVE_PERSON_PAYMENT_PLAN_LOADING'
export const SAVE_PERSON_PAYMENT_PLAN_SUCCESS =
  'natura-caduni/PaymentPlan/SAVE_PERSON_PAYMENT_PLAN_SUCCESS'
export const SAVE_PERSON_PAYMENT_PLAN_ERROR =
  'natura-caduni/PaymentPlan/SAVE_PERSON_PAYMENT_PLAN_ERROR'

export const types = [
  SAVE_PERSON_PAYMENT_PLAN_LOADING,
  SAVE_PERSON_PAYMENT_PLAN_SUCCESS,
  SAVE_PERSON_PAYMENT_PLAN_ERROR
]



export function personSavePaymentPlanReducer(state, action, personDependencies) {
  const { type , payload } = action

  let { person } = state

  const paymentPlanLoading = type === SAVE_PERSON_PAYMENT_PLAN_LOADING
  const isSuccess = type === SAVE_PERSON_PAYMENT_PLAN_SUCCESS

  if (isSuccess)
    person = new Person({ ...person, paymentPlan: { ...payload.data } }, personDependencies)


  return {
    ...state,
    person,
    loading: state.loading,
    paymentPlanLoading,
    error: state.error,
  }
}

export function savePersonPaymentPlan(person) {
  const { personId } = person

  const {
    dispatch,
    urls: { PAYMENT_PLAN },
    locale: {
      id
    },
    user: {
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  const url = PAYMENT_PLAN(personId)

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'POST',
        url,
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken
        }
      }
    }
  })
}

// TODO: add offline feature
