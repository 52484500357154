import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'

import { isCurrentLocationOnRegisterPage } from 'src/helpers/app-history'
import { GENDERS } from 'src/models/Person'
import { isDefaultFlowToggleActive } from 'src/config'

const messages = defineMessages({
  gender: {
    defaultMessage: 'Gender',
    id: 'form.field.gender'
  },
  gender_female: {
    defaultMessage: 'Female',
    id: 'form.field.gender.female'
  },
  gender_male: {
    defaultMessage: 'Male',
    id: 'form.field.gender.male'
  },
  gender_other: {
    defaultMessage: 'Other',
    id: 'form.field.gender.other'
  },
  gender_placeholder: {
    defaultMessage: 'Select Gender',
    id: 'form.field.gender.placeholder'
  },
  gender_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.gender.no.options.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    person: { gender: defaultValue }
  } = injection

  const options = [
    {
      value: GENDERS.FEMALE,
      label: intl.formatMessage(messages['gender_female'])
    },
    {
      value: GENDERS.MALE,
      label: intl.formatMessage(messages['gender_male'])
    },
    {
      value: GENDERS.OTHER,
      label: intl.formatMessage(messages['gender_other'])
    }
  ]
  const showAsterisk =
    !isDefaultFlowToggleActive() ||
    (isDefaultFlowToggleActive() && isCurrentLocationOnRegisterPage())

  const pushDataLayer = (values) => {
    const { label } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationDocumentationData',
        fieldName: intl.formatMessage(messages['gender']),
        value: label,
      }
    })
  }

  return {
    id: 'gender',
    label: intl.formatMessage(messages['gender']),
    validations: yup.number(),
    placeholder: intl.formatMessage(messages['gender_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['gender_no_options_message']),
    type: 'select',
    defaultValue,
    showAsterisk,
    options,
    onChange: pushDataLayer,
  }
}
