import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Documents',
    id: 'register.documents.label'
  },
  page_title: {
    defaultMessage: 'Sending documents',
    id: 'register.documents.page.title'
  },
  subtitle_1: {
    defaultMessage:
      'Please attach the following documentation. The images must be legible and match the information provided.',
    id: 'register.documents.subtitle_1'
  },
  subtitle_2: {
    defaultMessage:
      'Image files accepted PNG, GIF, JPEG, TIFF, JPG and audio files accepted AAP, AIF, AIFC, AIFF, ALAC, AMR, AU, CAF, FLAC, HTK, IFF, M4A, MAT4, MAT5, MP3, OGG, PAF, PCM, PVF, RAW, SD2, SF, SND, SVX, VOC, W64, WAV, XI, WMA, with a maximum of 10MB:',
    id: 'register.documents.subtitle_2'
  },
  error_title: {
    defaultMessage: 'Error',
    id: 'register.documents.error.title'
  },
  fileBack: {
    defaultMessage: 'Backs',
    id: 'register.documents.file.back'
  },
  fileFront: {
    defaultMessage: 'Front',
    id: 'register.documents.file.front'
  },
  fileSubtitle: {
    defaultMessage:
      'The files must be in image format and have a maximum of 5MB.',
    id: 'register.documents.file.subtitle'
  }
})

export function getSectionTitle({ messages = Messages }) {
  const { page_title, subtitle_1, subtitle_2, step_label } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />,
      <FormattedMessage {...subtitle_2} key="subtitle_2" />
    ],
    label: <FormattedMessage {...step_label} />
  }
}

export function getFileTitles({ messages = Messages }) {
  const { fileBack, fileFront } = messages

  return {
    fileBack: <FormattedMessage {...fileBack} />,
    fileFront: <FormattedMessage {...fileFront} />
  }
}

export function getFileSubtitle({ messages = Messages }) {
  const { fileSubtitle } = messages

  return {
    fileSubtitle: <FormattedMessage {...fileSubtitle} />
  }
}
