import { getContext } from 'src/infra/Api/Api.context'
import PeopleRegister from 'src/viewModels/PeopleRegister'

export const PEOPLE_REGISTER_REMOVE = 'natura-caduni/People/PEOPLE_REGISTER_REMOVE'

export function reducerPeopleRegisterRemove(state, action, dependencies) {
  const { payload: { person } } = action
  const { peopleRegister } = state

  const { items } = peopleRegister.remove(person)

  return {
    peopleRegister: new PeopleRegister(items, dependencies)
  }
}

export function removePerson(person) {
  const { dispatch } = getContext()

  return dispatch({
    type: PEOPLE_REGISTER_REMOVE,
    payload: {
      person
    }
  })
}
