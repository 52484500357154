import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Pre Register',
    id: 'register.personal.label'
  },
  page_title: {
    defaultMessage: 'Let\'s start',
    id: 'pre-register.default_title'
  },
  subtitle_1: {
    defaultMessage: 'To start signing up, fill your ID number below:',
    id: 'pre-register.default_subtitle_1'
  },
})

export function getSectionTitle({ messages = Messages }) {

  const {
    page_title,
    subtitle_1,
    step_label
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />,
    ],
    label: <FormattedMessage {...step_label} />,
  }
}

export default Messages
