import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

const bem = bemClassName.bind(null, 'record-audio')

const COUNTDOWN_SEQUENCE = [3, 2, 1]

export default function Countdown ({ activeCountdownNumber }) {
  return (
    <div className={bem('countdown')}>
      {COUNTDOWN_SEQUENCE.map(CountdownNumber, { activeCountdownNumber })}
    </div>
  )
}

function CountdownNumber (number) {
  const className = bem('countdown__step', { 'active-number': number === this.activeCountdownNumber })
  return <span className={className} key={number}>{number}</span>
}

Countdown.propTypes = {
  activeCountdownNumber: PropTypes.number
}
