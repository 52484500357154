import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  modal_button_ok: {
    defaultMessage: 'Ok',
    id: 'form.modal.ok'
  },
  cancel_label: {
    defaultMessage: 'Back',
    id: 'form.actions.cancel'
  },
  confirm_label: {
    defaultMessage: 'Next',
    id: 'form.actions.confirm'
  },
  modal_error_title: {
    defaultMessage: 'Error',
    id: 'modal.error.title'
  }
})
