import { getSectionTitle } from 'src/configs/Locales/DEFAULT/views/SelfRegister/Address/Messages'
import { buildFormSchema } from 'src/configs/Locales/DEFAULT/views/SelfRegister/Address/FormSchema'
import { onSubmition } from 'src/configs/Locales/DEFAULT/views/SelfRegister/Address/Submit'
import { touchAfterReload } from 'src/configs/Locales/DEFAULT/views/SelfRegister/Address/TouchAfterReload'
import { store } from 'src/App.state'
import omit from 'lodash/omit'
import { isAddressPageStatelessToggleActive } from 'src/config'

export function buildAddressProps(deps) {
  const { locale: { id, tenantId, companyId }, person, loader: { personLoading } } = deps
  const { user: { user } } = store.getState()
  const {
    personId,
    businessModel: { businessModelId, functionId, roleId },
    addresses
  } = person

  const { title, subtitle, label } = getSectionTitle(deps)
  const onSubmit = onSubmition(deps)

  let identityInfo = {
    shouldCallCompleteEndpoint: isAddressPageStatelessToggleActive(),
    personId,
    tenantId,
    countryId: id,
    companyId,
    sourceSystem: 7,
    role: roleId,
    businessModel: businessModelId,
    functionId
  }

  if (user.authenticated) {
    identityInfo = Object.assign(identityInfo,  { authorization: user.authentication.accessToken } )
  }

  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    onSubmit,
    disabledSubmit: true,
    step: {
      title,
      subtitle,
      label
    },
    loader: personLoading,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'address',
      identityInfo,
      authenticatedUser: {
        authenticated: user.authenticated,
      },
      person: { addresses },
      onSubmit
    }
  }
}

export function buildAddressPropsMicrofrontend(deps) {
  return omit(buildAddressProps(deps), ['formSchema'])
}
