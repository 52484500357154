import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_CREATE_LOADING = 'natura-caduni/Profile/PROFILE_CREATE_LOADING'
export const PROFILE_CREATE_SUCCESS = 'natura-caduni/Profile/PROFILE_CREATE_SUCCESS'
export const PROFILE_CREATE_ERROR = 'natura-caduni/Profile/PROFILE_CREATE_ERROR'

export const types = [PROFILE_CREATE_LOADING, PROFILE_CREATE_SUCCESS, PROFILE_CREATE_ERROR]

export function createPersonRole({ businessModel, personId }) {
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  const sourceSystem = 7
  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext, businessModel })

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'POST',
        url: requestUrl,
        headers: requestHeaders,
        data: {}
      }
    }
  })

  function getRequestPayload({ globalAppContext, businessModel }) {
    const {
      CREATE_PERSON_ROLE,
      CREATE_PERSON_ROLE_BFF,
      bffHeaders,
      headersAuthenticationInfo,
      shouldCreateRoleFromBff,
      countryId,
      companyId,
      sellerId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const { requestHeaders } = getRequestHeaders({ shouldCreateRoleFromBff, headersAuthenticationInfo, businessModel })

    const requestUrl = shouldCreateRoleFromBff ? CREATE_PERSON_ROLE_BFF : CREATE_PERSON_ROLE

    const clientName = shouldCreateRoleFromBff
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { CREATE_PERSON_ROLE },
        default_bff_urls: { CREATE_PERSON_ROLE_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          tenantId,
          configs
        },
        user: {
          authentication: {
            xApikey,
            accessToken
          },
          sellerId,
        },
      } = globalAppContext

      const shouldCreateRoleFromBff = configs?.localization?.isHostedUIEnabled

      return {
        CREATE_PERSON_ROLE: CREATE_PERSON_ROLE(personId),
        CREATE_PERSON_ROLE_BFF: CREATE_PERSON_ROLE_BFF(personId),
        shouldCreateRoleFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        countryId,
        companyId,
        tenantId,
        sellerId,
      }
    }

    function getRequestHeaders({ shouldCreateRoleFromBff, headersAuthenticationInfo, businessModel }){
      const { businessModelId, functionId, roleId } = businessModel
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const requestHeaders = shouldCreateRoleFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        userId: sellerId,
        businessModel: businessModelId,
        function: functionId,
        role: roleId,
        sourcesystem: sourceSystem,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        businessmodel: businessModelId,
        function: functionId,
        role: roleId,
        sourcesystem: sourceSystem,
      }

      return { requestHeaders }
    }
  }
}
