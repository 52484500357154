import { set, unset } from './Form.state.helpers'
import { get } from 'lodash'

export default class Toucheds {
  touchFields(fields, values) {
    const existentFields = fields.filter(filterValidFields, values)
    
    return existentFields.reduce(touchFields, this)
  }

  touchAllFields(schema) {
    const newToucheds = schema.fieldsSchema.reduce(touchFieldsDeep, {})

    Object.assign(this, newToucheds)

    return this
  }

  touchField(id, action) {
    return action ? set(this, id, action) : unset(this, id)
  }
}

function touchFieldsDeep(accum, schema) {
  if(Array.isArray(schema))
    return schema.reduce(touchFieldsDeep, accum)

  if(schema.list) {
    const list = schema.list.map(mapGroupList)

    return { ...accum, [schema.id]: list }
  }

  return { ...accum, [schema.id]: true }
}

function mapGroupList(field) {
  return field.reduce(touchGroupFields, [])
}

function touchGroupFields(accum, field) {
  if(Array.isArray(field)) {
    return field.reduce(touchGroupFields, accum)
  }

  return { ...accum, [field.id]: true }
}

function touchFields(accum, fieldId) {
  return set(accum, fieldId, true)
}

function filterValidFields(fieldId) {
  return !!get(this, fieldId)
}
