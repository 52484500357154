import { messages } from '../Messages'
import { connect } from 'react-redux'
import Select from '../../../../base/Form/Components/Select/Select'
import TagManager from 'react-gtm-module'
import { getLevel4Options } from './Level4.arg'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'level3',
    label: intl.formatMessage(messages['level3']),
    placeholder: intl.formatMessage(messages['level3_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['level_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    showAsterisk: false,
    htmlProps: { autoFocus: false },
    disabledBy: ['address[0].level2'],
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['level3']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

export function validateFieldDependencies({
  setFieldValue,
  setFieldTouched,
  schema
}) {
  const {
    group: { id },
    parent
  } = schema

  setFieldValue(`${parent}.level4`, '', id)

  setFieldTouched(`${parent}.level4`, false)
}

export const getLevel3Options = ({ value, getLevels }) => {
  if (!value) return

  return getLevels({
    geoStructureType: 'neighborhoods',
    queryParams: `level=3&parentCode=${value}`,
    geoStructureCode: value
  })
}

const called = {}

function mapStateToProps({ addresses }, ownProps) {
  const {
    addressesPeopleManagement: { neighborhoods: level3Options, getLevels },
    loading,
  } = addresses

  const {
    group: { index },
    form: {
      values: { address }
    },
    onChange
  } = ownProps
  const { level2 } = address[index] || {}
  const options = level3Options[level2] || []

  const shouldGetLevel3Options = !options.length && level2 && !loading.neighborhoods?.[level2]

  if (shouldGetLevel3Options && !called[level2]) {
    called[level2] = true

    getLevel3Options({ value: level2, getLevels })
  }

  return {
    ...ownProps,
    onChange: (value, label) => {
      getLevel4Options({ selectedLevel3Id: value, getLevels })
      onChange(value, label)
    },
    options: options || [],
    loading: Boolean(level2 && loading.neighborhoods && loading.neighborhoods[level2])
  }
}
