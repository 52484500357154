import { TYPES } from 'src/viewModels/ListSchema'

export function createItemsOfflineSchema(dependencies) {
  const { intl, messages, peopleRegister, connectivity: { isOffline } } = dependencies
  const message = isOffline ? intl.formatMessage(messages.button_edit_register) : intl.formatMessage(messages.button_review_register)

  return [
    {
      label: intl.formatMessage(messages.fields_name),
      key: 'register_name',
      type: TYPES.AVATAR
    },
    {
      label: intl.formatMessage(messages.fields_document),
      key: 'register_document'
    },
    {
      label: message,
      key: 'register_review',
      type: TYPES.BUTTON,
      connection: isOffline
    },
    {
      label: intl.formatMessage(messages.button_delete_register),
      key: 'register_delete',
      type: TYPES.BUTTON,
      onChange({ personId }) {
        return () => peopleRegister.removePerson({ personId })
      }
    }
  ]
}
