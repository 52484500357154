import { ROLES } from 'src/infra/Api/COL/roles.colombia'
import {
  isEnableHostedUiToggleActive,
  isColombiaWithLegoToggleActive,
  isEnableThemeElo
} from '../../../../../config'
import { ROUTES } from 'src/App.routes'

export default () => ({
  countryName: 'COLOMBIA',
  preRegister: {
    useCreatePersonFromBFF: isEnableHostedUiToggleActive()
  },
  businessModel: {
    shouldHideButton: true
  },
  personalData: {
    hasFullNameInfoText: false,
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: false
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  agreements: {
    linkPDF: null,
    linkAudio: null,
    hasEnvironmentsFiles: false
  },
  address: {
    levels: {
      CITY: 2,
      COMPLEMENT: 6,
      COUNTRY: 0,
      NEIGHBORHOOD: 3,
      NUMBER: 5,
      REFERENCES: 7,
      REGION: 1,
      STREET: 4
    },
    hasZipCode: false
  },
  appStoreUrls: {
    android: 'https://play.google.com/store/apps/details?id=net.natura.cn',
    iOS: 'https://apps.apple.com/co/app/mi-negocio-natura/id1197578002'
  },
  authenticationLegacy: false,
  callEndpointComplete: false,
  contactValidationLegacy: false,
  date: {
    format: 'DD/MM/YYYY'
  },
  documents: {
    lengthValidation: {
      citizenCard: {
        max: 10,
        min: 4
      },
      foreignCard: {
        max: 10,
        min: 4
      }
    },
    mask: {
      citizenCard: '9999999999',
      foreignCard: '9999999999'
    },
    personal: {
      citizenCard: 9,
      foreignCard: 10
    }
  },
  featureLite: false,
  getBusinessModelFromApi: false,
  getFiltersFromApi: true,
  map: {
    center: {
      lat: 4.624335,
      lng: -74.063644
    }
  },
  ocr: {
    fields: ['identificationCardFront', 'identificationCardBack'],
    validations: [
      {
        attachmentsToValidate: [
          {
            field: 'identificationCardFront',
            type: 7
          },
          {
            field: 'identificationCardBack',
            type: 8
          }
        ],
        document: 'citizenCard',
        documentType: 9
      },
      {
        attachmentsToValidate: [
          {
            field: 'identificationCardFront',
            type: 9
          }
        ],
        document: 'foreignCard',
        documentType: 10
      }
    ]
  },
  offlineMode: false,
  phone: {
    areaCodeCharCount: {
      line: 1,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '57',
    customValidation: {
      invalid: /^[1-9]/,
      line: /^[1-9]{2}/,
      mobile: {
        start_code: {
          number: 3,
          validation: /^3/
        },
        start_number_negation: {
          number: 0,
          validation: /^.{3}[1-9]/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 8
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '9 9999999',
      mobile: '999-9999999'
    },
    placeholder: {
      line: '0 0000000',
      mobile: 'XXX-XXXXXXX'
    }
  },
  policies: [
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'authorization',
      id: 3,
      name: 'Aceptación de las condiciones de Natura',
      sequence: '2544b0ad-e4b3-4ab1-b5c9-7894848e9d62',
      version: 1
    },
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'personal_data',
      id: 4,
      name: 'Autorización de uso de datos personales',
      sequence: '6f2ecd27-bf00-446e-83ab-ceaf18062749',
      version: 1
    },
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 5,
      fieldId: 'conditions_privacy',
      id: 5,
      name: 'Autorización de la consulta del estado de crédito',
      sequence: '47387872-f2f6-4572-aa24-266bfa59b4a6',
      version: 1
    },
    {
      acceptanceRequired: false,
      businessModel: 1,
      country: 5,
      fieldId: 'invoice',
      id: 6,
      name: 'Aceptación recepción de factura digital',
      sequence: '5a278b2d-5825-4d32-805d-b9ffcb21c871',
      version: 1
    }
  ],
  selectPaymentPlan: true,
  shouldCallCreatePaymentPlanInPaymentView: true,
  shouldCallGetPersonInVerifyContacts: false,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: true,
  showProfileEditButton: false,
  hasLego: isColombiaWithLegoToggleActive(),
  hasDigitalStore: false,
  shouldCallCompleteAddressStepUsingBff: isEnableHostedUiToggleActive(),
  shouldUpdatePersonFromBff: isEnableHostedUiToggleActive(),
  shouldUpdateRegistrationSubstatusFromBff: isEnableHostedUiToggleActive(),
  shouldGetPersonDetailsFromBff: isEnableHostedUiToggleActive(),
  shouldGetEstablishmentsFromBff: isEnableHostedUiToggleActive(),
  shouldGetBusinessModelFromBff: isEnableHostedUiToggleActive(),
  shouldGetRejectionReasonsFromBff: isEnableHostedUiToggleActive(),
  shouldGetAddressFromBff: isEnableHostedUiToggleActive(),
  shouldIgnorePeopleContextResponseOnLogin: false,
  isHostedUIEnabled: isEnableHostedUiToggleActive(),
  shouldCompleteRegisterWithBff: isEnableHostedUiToggleActive(),
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: false,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: ROUTES.SELF_REGISTER_WITH_COUNTRY.replace(':country', 'co'),
      external: false,
      shouldSSO: false
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: ROUTES.DASHBOARD,
      external: false,
      shouldSSO: false
    }
  },
  social: isEnableThemeElo() && process.env.ENABLE_THEME_ELO_TO_COUNTRY.split(',').includes('CO')
    ? {
      natura: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/natura.colombia',
          label: 'Facebook Natura'
        },
        {
          type: 'youtube',
          link: 'https://www.youtube.com/user/NaturaCoOficial',
          label: 'Youtube Natura'
        },
        {
          type: 'twitter',
          link: 'https://twitter.com/natura_colombia',
          label: 'Twitter Natura'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/natura.colombia/',
          label: 'Instagram Natura'
        }
      ],
      avon: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/avonperu',
          label: 'Facebook Avon'
        },
        {
          type: 'youtube',
          link: 'https://www.youtube.com/channel/UCqr4HH2NNd-NcFMyJsmjFuQ',
          label: 'Youtube Avon'
        },
        {
          type: 'twitter',
          link: 'https://twitter.com/avonperu',
          label: 'Twitter Avon'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/avon_per/',
          label: 'Instagram Avon'
        }
      ]
    }
    : [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/natura.colombia',
        label: 'Facebook Natura'
      },
      {
        type: 'youtube',
        link: 'https://www.youtube.com/user/NaturaCoOficial',
        label: 'Youtube Natura'
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/natura_colombia',
        label: 'Twitter Natura'
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/natura.colombia/',
        label: 'Instagram Natura'
      }
    ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
