import TYPES from 'src/configs/Fields'
import Policies from 'src/configs/Locales/DEFAULT/views/SelfRegister/Policies'
import termsAndConditions from './termsAndConditions.mys'

export const getMalaysiaFieldsSchema = ({ intl, dependencies }) => {
  const ETHNICITY = commonParams(TYPES.ETHNICITY(intl, dependencies), false)

  const POLICIES = commonParams(
    Policies(intl, dependencies, TYPES.POLICIES, termsAndConditions),
    false
  )

  return [[ETHNICITY], [POLICIES]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
