const TERM_AND_CONDITIONS_OBSERVATION = 12

export function onSubmition(deps) {
  const { person, upload, connectivity } = deps

  return async function(data, callback) {
    const { termAndConditionsObservation: description, ...rest } = data

    const attachmentsValues = Object.values(rest)
    const buildAttachmentDeps = { person, upload, description }

    const attachments = connectivity.isOffline
      ? attachmentsValues.filter(({ file }) => file)
      : await Promise.all(
        attachmentsValues.map(buildAttachmentList, buildAttachmentDeps)
      )

    const attachmentsFiltered = attachments.filter(attach => attach)

    if (!attachmentsFiltered.length) return callback()

    return person.saveAttachments(
      { attachments: attachmentsFiltered },
      callback
    )
  }
}

async function buildAttachmentList(value) {
  const {
    upload,
    person: { personId, attachments },
    description
  } = this

  if (!value || !value.type) return

  const { type, downloadUrl, file } = value

  const personAttachment = attachments.find(findAttachment, { type }) || {}
  const sequence = value.sequence || personAttachment.sequence

  if (downloadUrl) {
    return buildAttachment({ sequence, type, url: downloadUrl }, description)
  }

  const {
    payload: {
      data: [{ uploadUrl, downloadUrl: newDownloadUrl }] = [{}],
      error: errorUploadCreate
    } = {}
  } = await upload.create(personId, { type, value: file })

  const { error: errorUploadSave } = await upload.save({
    uploadUrl,
    type,
    file
  })

  if (errorUploadCreate || errorUploadSave) return

  return buildAttachment({ sequence, type, url: newDownloadUrl }, description)
}

function buildAttachment(attachment, description) {
  const hasObservation = attachment.type === TERM_AND_CONDITIONS_OBSERVATION

  return hasObservation ? { ...attachment, description } : attachment
}

function findAttachment({ type }) {
  return type === this.type
}
