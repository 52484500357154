import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const FrontPhoto = ({ size, state }) => {
  const styles = {
    fill: StateColors[state]
  }

  return (
    <svg width={size} height={size} viewBox="0 0 244 259">
      <path
        style={styles}
        d="M214,177.5H155a3.5,3.5,0,0,1,0-7h59a3.5,3.5,0,0,1,0,7Z"
      />
      <path
        style={styles}
        d="M214.5,202h-59a3.5,3.5,0,0,1,0-7h59a3.5,3.5,0,0,1,0,7Z"
      />
      <path
        style={styles}
        d="M144.29,106.5c-6.6.83-14.34,2-19.88,6-5.33,3.86-6.1,10.11-6.23,16.25-.08,3.86,5.92,3.86,6,0,.11-5.18.53-9.9,5.6-12.5,4.33-2.23,9.73-3.16,14.51-3.76C148.07,112,148.11,106,144.29,106.5Z"
      />
      <path
        style={styles}
        d="M193.05,112.51c-5.55-4-13.27-5.17-19.88-6-3.82-.48-3.79,5.52,0,6,4.77.6,10.18,1.54,14.51,3.76,5.05,2.59,5.49,7.34,5.6,12.5.08,3.86,6.08,3.87,6,0C199.15,122.61,198.4,116.37,193.05,112.51Z"
      />
      <path
        style={styles}
        d="M179.57,87.72A10.12,10.12,0,0,0,185,79.27c.4-4.4-1.44-7.07-3.35-8.49l.43-5.64-.17-.24.22.22c2.64-2.64,6.55-9.5,2.48-16.61C179.68,39.89,163.73,38,158,38c-1.45,0-2.88-.13-4.26-.26-4.19-.39-8.93-.82-12.14,3.46a29.92,29.92,0,0,1-3.22,3.68,35.3,35.3,0,0,0-3.78,4.32c-3.71,4.94.23,15.57,1.59,18.86l.21,2.72c-1.91,1.42-3.75,4.09-3.35,8.49a10.12,10.12,0,0,0,5.42,8.45c4.39,17.71,15.35,19.37,19.25,19.37h.38q.94,0,1.87,0C163.48,107.16,175,106,179.57,87.72ZM139.4,52.8a29.92,29.92,0,0,1,3.22-3.68,35.3,35.3,0,0,0,3.78-4.32c1.1-1.46,2.73-1.46,6.79-1.08,1.45.13,3.08.28,4.81.28,6.9,0,18.48,2.38,21.4,7.49,2.29,4-.08,7.64-1.11,8.92-2.06-2.21-4.83-4.63-7.56-5.32a17.65,17.65,0,0,0-5.9-.12c-3.2.3-5.25.37-6.71-1.09-4.49-4.49-9.18-3.06-11.24-1L140,60.52C139,56.88,138.7,53.73,139.4,52.8Zm20.75,48.29q-1.11,0-2.21,0c-3.14.1-10.77-1.31-14-16.22l-.4-1.88-1.88-.42S139.33,82,139,78.73c-.23-2.49.87-3.16,1.3-3.33l2.41-.43-.61-7.92,9-9.91c.85-.64,2,.17,2.78,1,3.56,3.56,8.15,3.14,11.5,2.83a13.53,13.53,0,0,1,3.89,0c1.77.44,4.77,3.57,6.65,6l-.61,7.89,2.44.62s1.51.47,1.26,3.32c-.28,3-2.32,3.75-2.66,3.84l-1.88.42-.4,1.88C170.87,99.78,163.27,101.18,160.15,101.09Z"
      />
      <path
        style={styles}
        d="M221-.06H92A23,23,0,0,0,69,23V122.5a3.5,3.5,0,0,0,3.5,3.5h0a3.5,3.5,0,0,0,3.5-3.5V23a16,16,0,0,1,16-16H221a16,16,0,0,1,16,16V215a16,16,0,0,1-16,16H155.5a3.5,3.5,0,0,0-3.5,3.5h0a3.5,3.5,0,0,0,3.5,3.5H221a23,23,0,0,0,23-23V23A23,23,0,0,0,221-.06Z"
      />
      <path
        style={styles}
        d="M71.69,166.68a35.43,35.43,0,1,0,35.44,35.43A35.47,35.47,0,0,0,71.69,166.68Zm0,63.36a27.93,27.93,0,1,1,27.94-27.94A28,28,0,0,1,71.69,230Z"
      />
      <path
        style={styles}
        d="M121.48,156.12h-7.84a7.34,7.34,0,0,1-6.16-4.34l-2.69-7.49A14.86,14.86,0,0,0,91.56,135H51.82a14.88,14.88,0,0,0-13.22,9.29l-2.69,7.5a7.34,7.34,0,0,1-6.16,4.33H21.91A21.87,21.87,0,0,0,.06,178v58.84a21.87,21.87,0,0,0,21.85,21.85h99.57a21.87,21.87,0,0,0,21.85-21.85V178A21.88,21.88,0,0,0,121.48,156.12Zm14.36,81,0,.4a14.33,14.33,0,0,1-14.34,13.68H21.91A14.37,14.37,0,0,1,7.55,236.81V178a14.37,14.37,0,0,1,14.35-14.35h7.84A14.86,14.86,0,0,0,43,154.32l2.69-7.49a7.37,7.37,0,0,1,6.17-4.34H91.57a7.36,7.36,0,0,1,6.16,4.34l2.69,7.49a14.86,14.86,0,0,0,13.22,9.29h7.83A14.37,14.37,0,0,1,135.84,178Z"
      />
    </svg>
  )
}

FrontPhoto.propTypes = {
  size: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
}

export default FrontPhoto
