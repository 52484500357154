import { getMalaysiaFieldsSchema } from './MYS/getFieldsSchema.mys'
import { getPeruFieldsSchema } from './PER/getFieldsSchema.per'
import { getDefaultFieldsSchema } from './DEFAULT/getFieldsSchema.default'
import { getChileFieldsSchema } from './CHL/getFieldsSchema.chl'


const strategies = {
  MYS: getMalaysiaFieldsSchema,
  PER: getPeruFieldsSchema,
  CHL: getChileFieldsSchema,
  __default__: getDefaultFieldsSchema
}

export const getFieldsSchema = (localeId, { intl, dependencies }) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy({ intl, dependencies })
}
