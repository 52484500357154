import { ADDRESS_USE } from 'src/models/Address/AddressUse'
import { findAddress } from 'src/models/Address'

const messagesMapper = {
  COUNTRY: 'box_address_label_country',
  LEVEL1: 'box_address_label_level1',
  LEVEL2: 'box_address_label_level2',
  LEVEL3: 'box_address_label_level3',
  LEVEL4: 'box_address_label_level4',
  REGION: 'box_address_label_region',
  CITY: 'box_address_label_city',
  DEPARTMENT: 'box_address_label_department',
  PROVINCE: 'box_address_label_province',
  DISTRICT: 'box_address_label_district',
  STREET: 'box_address_label_street',
  NEIGHBORHOOD: 'box_address_label_neighborhood',
  NUMBER: 'box_address_label_number',
  COMPLEMENT: 'box_address_label_complement',
  REFERENCES: 'box_address_label_reference',
}

export default function Builder(params) {
  const { intl, messages, person } = params
  const { addresses } = person

  const homeAddress = addresses.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })

  const fields = homeAddress
    ? parseAddress(homeAddress, params)
    : [
      {
        field: intl.formatMessage(messages.box_address_value_empty)
      }
    ]

  return {
    id: 'profile-contact-address-home',
    title: intl.formatMessage(messages.box_address_home_title),
    icon: 'ui_home',
    wrapper: 'default',
    content: 'list',
    fields
  }
}

export function parseAddress(address, params) {
  const {
    intl,
    messages,
    locale: {
      configs: {
        localization: {
          address: { levels, hasZipCode }
        }
      }
    }
  } = params

  const { geographicalLevels, zipCode } = address
  const reducedGeographicLevels = geographicalLevels.reduce(
    reduceGeographicLevels,
    {}
  )

  const sortedLevelsKeys = Object.keys(levels).sort((a,b) => levels[a]-levels[b])
  const fields = sortedLevelsKeys.map((levelKey) => {
    const messageKey = messagesMapper[levelKey]
    if (messageKey) {
      return createField(
        intl.formatMessage(messages[messageKey]),
        reducedGeographicLevels,
        levels[levelKey]
      )
    }
    return undefined
  }).filter(field => Boolean(field))

  if (hasZipCode) {
    const fieldsWithZipCode = concatZipCodeField(
      intl.formatMessage(messages.box_address_label_zipcode),
      zipCode, 
      fields
    )
    return fieldsWithZipCode
  }

  return fields
}

function concatZipCodeField(label, zipCode, fields){
  return fields.concat(
    {
      label,
      field: zipCode || '-'
    }
  )
}

function createField(label, fieldValues, fieldKey) {
  return {
    label,
    field: fieldValues[fieldKey] || '-'
  }
}

function reduceGeographicLevels(accum, { level, description }) {
  return {
    ...accum,
    [level]: description
  }
}
