import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'

import Select from 'src/base/Form/Components/Select'
import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'province',
    label: intl.formatMessage(messages['province']),
    placeholder: intl.formatMessage(messages['province_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['province_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    disabledBy: [ 'address[].region', 'address[].department' ],
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['province']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getDistrictWithProvince(params) {
  const { value, getAddress } = params

  return value && getAddress({
    geoStructureCode: value,
    geoStructureType: 'districts',
    queryParams: `level=4&parentLevel=3&parentCode=${value}`,
  })
}

const calleds = {}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, loading: { provinces: provincesloading }, addressesPeopleManagement }, locale: { locale } } = state
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { provinces: provincesCMM, getAddressesCMM } = addresses
  const { department } = address[index] || {}

  const { getLevels, provinces: provincesBFF } = addressesPeopleManagement
  const { configs: { localization: { shouldGetAddressFromBff }}} = locale

  const provinces = shouldGetAddressFromBff ? provincesBFF : provincesCMM

  const options = provinces[department] || []
  const loading = provincesloading && provincesloading[department]
  const shouldGetProvinces = !options.length && !loading && department

  const getAddress = shouldGetAddressFromBff ? getLevels : getAddressesCMM

  if(shouldGetProvinces && !calleds[department]) {
    calleds[department] = true

    getAddress({
      geoStructureCode: department,
      geoStructureType: 'provinces',
    })
  }

  return {
    ...ownProps,
    loading,
    options,
    onChange: function(value, label) {
      getDistrictWithProvince({ value, getAddress })
      onChange(value, label)
    }
  }
}
