import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'city',
    label: intl.formatMessage(messages['city']),
    placeholder: intl.formatMessage(messages['city_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['city_no_options_message']),
    type: connect(mapStateToProps)(Select),
    onChange: validateFieldDependencies,
    required: true,
    disabledBy: ['address[].department']
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.city`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.complement`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getNeighborhoodByCity({ value, getLevels }) {
  if (!value) return

  return getLevels({
    geoStructureType: 'neighborhoods',
    queryParams: `level=3&parentLevel=2&parentCode=${value}`,
    geoStructureCode: value
  })
}

const calleds = {}

function mapStateToProps({ addresses }, ownProps) {
  const { addressesPeopleManagement, loading: { cities: citiesloading } } = addresses
  const { cities, getLevels } = addressesPeopleManagement
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { department } = address[index]

  const loading = citiesloading && citiesloading[department]
  const options = cities[department] || []

  const shouldGetCities = !options.length && department && !loading

  if (shouldGetCities && !calleds[department]) {
    calleds[department] = true

    getLevels({
      geoStructureType: 'cities',
      queryParams: `level=2&parentLevel=1&parentCode=${department}`,
      geoStructureCode: department
    })
  }

  return {
    ...ownProps,
    options,
    loading,
    onChange: function (value) {
      getNeighborhoodByCity({ value, getLevels })
      onChange(value)
    }
  }
}
