import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { normalize } from 'locale-id'
import { getCompanyNameByBrowserUrl } from '../../helpers/get-company-id'

const adapter = validatorAdapter('Yup', yup)

const dependencies = {
  normalize
}

// Can be instantiated with:
//  new Locale('pt-BR')
//  new Locale(LOCALES[0])
class Locale extends Entity {
  static SCHEMA = {
    id: adapter(yup.string()),
    name: adapter(yup.string()),
    countryId: adapter(yup.number()),
    locale: adapter(yup.string()),
    region: adapter(yup.string()),
    language: adapter(yup.string()),
    tenantId: adapter(yup.string()),
    login: adapter(yup.boolean()),
    registration: adapter(yup.boolean()),
    configs: {
      validator: adapter(yup.object()),
      defaultValue: {}
    }
  }

  constructor(props, injection) {
    super(props, injection)

    const deps = { ...dependencies, ...injection }

    if (typeof props === 'object' && props.tenantId) {
      this.findValidLocaleByTenant(props, deps)
    } else if (typeof props === 'string') {
      this.findValidLocale(props, deps)
    } else if (typeof props === 'object' && props.isRegion) {
      this.findValidLocaleByRegion(props.country, props.company, deps)
    } else if (this.locale) {
      this.findValidLocale(this.locale, deps)
    }
  }

  get countryName() {
    return this.name.toUpperCase()
  }

  getParams() {
    const searchParams = new URLSearchParams(location.search)
    const companyFromParam =
      searchParams.get('company') || getCompanyNameByBrowserUrl()
    const countryFromParam = searchParams.get('country')

    return { companyFromParam, localeFromParam: countryFromParam }
  }

  findLocaleByCompanyAndCountry(LOCALES) {
    const { companyFromParam, localeFromParam } = this.getParams()

    return LOCALES.find(
      ({ id, locale: localeElement, subCompany }) =>
        (id.toLowerCase() === localeFromParam?.toLowerCase() ||
          localeElement
            ?.toLowerCase()
            .includes(localeFromParam?.toLowerCase())) &&
        companyFromParam?.toLowerCase() === subCompany?.toLowerCase()
    )
  }

  findLocaleByOnlyCompany(LOCALES) {
    const { companyFromParam } = this.getParams()

    return LOCALES.find(({ companyId }) => companyFromParam === companyId)
  }

  findValidLocale(locale, deps) {
    const { normalize, LOCALES = [], DEFAULT_LOCALE = {} } = deps
    const normalized = normalize(locale)
    const foundLocale =
      this.findLocaleByCompanyAndCountry(LOCALES) ||
      this.findLocaleByOnlyCompany(LOCALES, normalized)
    const validLocale = foundLocale || DEFAULT_LOCALE

    Object.assign(this, { ...validLocale })
  }

  findValidLocaleByTenant(locale, deps) {
    const { LOCALES = [], DEFAULT_LOCALE = {} } = deps
    const foundLocale = LOCALES.find(
      ({ tenantId }) => tenantId === locale.tenantId
    )
    const validLocale = foundLocale || DEFAULT_LOCALE

    Object.assign(this, { ...validLocale })
  }

  findValidLocaleByRegion(region, company, deps) {
    const { LOCALES = [], DEFAULT_LOCALE = {} } = deps
    if (typeof region !== 'string') {
      throw new Error(
        'Could not get region data. Please check if you are running with the correct env values'
      )
    }

    const foundLocale = LOCALES.find((el) => {
      return (
        [el.region, el.id].includes(region.toUpperCase()) &&
        el.companyId === company
      )
    })

    const validLocale = foundLocale || DEFAULT_LOCALE
    Object.assign(this, { ...validLocale })
  }
}

export default Locale
