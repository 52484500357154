import { getContext } from '../../infra/Api/Api.context'

export const PERSON_SET_REGISTRATION_STATUS = 'natura-caduni/Person/PERSON_SET_REGISTRATION_STATUS'

export const types = [
  PERSON_SET_REGISTRATION_STATUS,
]

export function personSetRegistrationStatusReducer(state, action) {
  const { payload: { registrationStatus }} = action
  return {
    ...state,
    registrationStatus
  }
}

export function setPersonRegistrationStatus(registrationStatus) {
  const { dispatch } = getContext()

  return dispatch({
    type: PERSON_SET_REGISTRATION_STATUS,
    payload: {
      registrationStatus
    }
  })
}
