import { Messages } from 'src/viewModels/CreditModality/Messages'
import { buildFormSchema } from './FormSchema'
import { onSubmition } from './Submit'

import CreditModality from 'src/viewModels/CreditModality/CreditModality'

export function buildPayment(deps) {
  const messages = Messages
  const { person, locale: { configs }, loader: { personLoading } } = deps

  const creditModality = new CreditModality({ person, configs, messages })

  return {
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    messages,
    creditModality,
    loader: personLoading
  }
}
