import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'

import Select from 'src/base/Form/Components/Select'
import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'district',
    label: intl.formatMessage(messages['district']),
    placeholder: intl.formatMessage(messages['district_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['district_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    disabledBy: [
      'address[].region',
      'address[].department' ,
      'address[].province'
    ],
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['district']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

const calleds = {}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, loading: { districts: districtLoading }, addressesPeopleManagement }, locale: { locale } } = state
  const { group: { index }, form: { values: { address } }} = ownProps
  const { districts: districtsCMM, getAddressesCMM } = addresses
  const { province } = address[index] || {}

  const { getLevels, districts: districtsBFF } = addressesPeopleManagement
  const { configs: { localization: { shouldGetAddressFromBff }}}  = locale

  const getAddress = shouldGetAddressFromBff ? getLevels : getAddressesCMM
  const districts =  shouldGetAddressFromBff ? districtsBFF : districtsCMM

  const options = districts[province] || []
  const loading = districtLoading && districtLoading[province]
  const shouldGetDistricts = !options.length && !loading && province

  if(shouldGetDistricts && !calleds[province]) {
    calleds[province] = true

    getAddress({
      geoStructureCode: province,
      geoStructureType: 'districts',
      queryParams: 'level=1'
    })
  }

  return {
    ...ownProps,
    loading,
    options,
  }
}
