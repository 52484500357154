import {
  cloneDeep,
  set as lodashSet,
  unset as lodashUnset,
  curry
} from 'lodash'

import clean from 'lodash-clean'

const MARGIN_TOP = 100

export const set = curry(function(obj, path, value) {
  return lodashSet(cloneDeep(obj), path, value)
})

export const unset = curry(function(obj, path) {
  const newObj = cloneDeep(obj)

  lodashUnset(newObj, path)

  return clean(newObj) || {}
})

export function focusOnFirstError(formName) {
  const form = document.forms[formName]

  if(!form) return

  const errorElement = form.querySelector('[data-error="true"]')

  if(!errorElement) return

  const { offsetTop } = errorElement
  const marginTopElement = getMarginTop(errorElement)

  window.scrollTo(0, (offsetTop - marginTopElement - MARGIN_TOP))

  const element = errorElement.querySelector('input, checkbox')

  element && element.focus()
}

const findBrackets = /\[\d*\]/g
const findNumber = /\d+/g
const findExclamationMark = /^!/

export function splitIdParts(origin, schemaIndex) {
  const isNegative = findExclamationMark.test(origin)
  const [groupPart, fieldPart] = origin.split(findBrackets)
  const group = groupPart.replace('!', '')

  if(!fieldPart) {
    return {
      fieldId: group,
      errorIdField: group,
      errorIdGroup: false,
      isNegative
    }
  }

  const field = fieldPart.replace('!', '')
  const [index] = origin.match(findNumber) || [schemaIndex]

  const fieldId = `${group}[${index}]${field}`
  const errorIdField = `${group}.fields[${index}]${fieldPart}`
  const errorIdGroup = `${group}.group`

  return {
    fieldId,
    errorIdField,
    errorIdGroup,
    isNegative
  }
}

function getMarginTop(element) {
  const { marginTop } = getComputedStyle(element)

  return parseFloat(marginTop)
}
