import { getDocumentsFields as getChileDocumentsFields } from './getDocumentsFields.chl'
import { getDocumentsFields as getPeruDocumentsFields } from './getDocumentsFields.per'
import { getDocumentsFields as getMalaysiaDocumentsFields } from './getDocumentsFields.mys'
import { getDocumentsFields as getColombiaDocumentsFields } from './getDocumentsFields.col'
import { getDocumentsFields as getArgentinaDocumentsFields } from './getDocumentsFields.arg'

const strategies = {
  CHL: getChileDocumentsFields,
  PER: getPeruDocumentsFields,
  MYS: getMalaysiaDocumentsFields,
  COL: getColombiaDocumentsFields,
  ARG: getArgentinaDocumentsFields
}

export const getDocumentsFields = ({ localeId, intl, nationalityId, messages, documents, attachments }) => {
  const strategy = strategies[localeId] || throwNotImplementedError(localeId)
  return strategy({ intl, messages, nationalityId, documents, attachments })

  function throwNotImplementedError(localeId) {
    throw new Error(`getDocumentsFields strategy for locale ${localeId} not implemented`)
  }
}
