import { getContext } from 'src/infra/Api/Api.context'
import Consultants from 'src/viewModels/Consultants'

export const CONSULTANTS_RESET = 'natura-caduni/CONSULTANTS/CONSULTANTS_RESET'
export const types = [CONSULTANTS_RESET]

export function consultantsResetReducer(state, action, dependencies) {
  const consultants = new Consultants([], dependencies)

  return {
    loading: false,
    error: false,
    isVerified: false,
    consultants
  }
}

export function resetConsultants() {
  const { dispatch } = getContext()

  return dispatch({
    type: CONSULTANTS_RESET
  })
}
