import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'
import { store } from '../../../../../../../App.state'

export const buildFlows = () => {
  const { user: { user } } = store.getState()
  if (user && user.isCSC) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: [
        'pre-register',
        'business-model',
        'personal-data',
        'address',
        'documentation',
        'digital-store',
        'completed'
      ],
      '1/6/17': [
        'pre-register',
        'business-model',
        'business-manager-personal-data',
        'address',
        'documentation',
        'completed'
      ],
      '1/6/21': [
        'pre-register',
        'business-model',
        'head-of-commercial-personal-data',
        'address',
        'documentation',
        'completed'
      ],
      '*/*/*': [
        'pre-register',
        'business-model',
        'personal-data',
        'address',
        'documentation',
        'completed'
      ]
    }
  }


  return {
    [BEAUTY_CONSULTANT_PROFILE]: [
      'pre-register',
      'personal-data',
      'address',
      'documentation',
      'digital-store',
      'completed'
    ]
  }
}
