import React from 'react'
import PropTypes from 'prop-types'

const DenyIcon = ({ size, color }) => {
  const styles = {
    st0: {
      fill: 'none',
      stroke: color,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '4.5px',
    },
  }

  return (
    <svg width={size} height={size} viewBox="0 0 255 255">
      <path
        style={styles.st0}
        d="M172.14,117.36l34.24-34.24a24.46,24.46,0,0,0,0-34.5h0a24.46,24.46,0,0,0-34.5,0L130.5,90a4.26,4.26,0,0,1-6,0L83.66,49.15c-9-9-23.45-10.21-33.24-2.17a24.46,24.46,0,0,0-1.8,36.14L90,124.5a4.26,4.26,0,0,1,0,6L48.62,171.88a24.46,24.46,0,0,0,0,34.5h0a24.46,24.46,0,0,0,34.5,0L124.5,165a4.26,4.26,0,0,1,6,0l41.38,41.38a24.46,24.46,0,0,0,36.14-1.8c8-9.79,6.8-24.27-2.17-33.23l-27-27a23.86,23.86,0,0,0-33.75,0h0l-20.67,20.67"
      />
    </svg>
  )
}


DenyIcon.defaultProps = {
  color: '#e03726',
}

DenyIcon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default DenyIcon
