import * as yup from 'yup'
import TagManager from 'react-gtm-module'

import { isCurrentLocationOnRegisterPage } from 'src/helpers/app-history'
import { messages, nationalitiesMessages } from './Nationality.messages'
import { nationalities, nationalityIdByContryId } from './Nationality.list'
import { isDefaultFlowToggleActive } from 'src/config'

const dependencies = {
  messages,
  nationalities,
  nationalitiesMessages
}

export default function Builder(intl, injection) {
  const { messages, nationalities, nationalitiesMessages } = {
    ...dependencies,
    ...injection
  }
  const {
    locale: { id: localeId } = {},
    person: { nationalityId } = {}
  } = injection

  const options = Object.entries(nationalities)
    .map(createOptions, { intl, nationalitiesMessages })
    .sort(sortByLanguage)

  const nationalityIndex = options.findIndex(
    ({ value }) => value === nationalityIdByContryId[localeId]
  )
  const [nationality] = options.splice(nationalityIndex, 1)
  options.unshift(nationality)

  const defaultValue = Object.values(nationalities).find(findNationalityById, {
    nationalityId
  })
  const showAsterisk =
    !isDefaultFlowToggleActive() ||
    (isDefaultFlowToggleActive() && isCurrentLocationOnRegisterPage())

  const pushDataLayer = (values) => {
    const { label } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationDocumentationData',
        fieldName: intl.formatMessage(messages['nationality_label']),
        value: label,
      }
    })
  }

  return {
    id: 'nationalityId',
    label: intl.formatMessage(messages['nationality_label']),
    validations: yup.string(),
    placeholder: intl.formatMessage(messages['nationality_placeholder']),
    noOptionsMessage: intl.formatMessage(
      messages['nationality_no_options_message']
    ),
    type: 'select',
    showAsterisk,
    customProps: {
      className: 'highlight-first'
    },
    defaultValue,
    options,
    onChange: pushDataLayer
  }
}

function createOptions([key, value]) {
  const label = this.intl.formatMessage(nationalitiesMessages[key])

  return {
    value,
    label
  }
}

function sortByLanguage(a, b) {
  return a.label.localeCompare(b.label)
}

function findNationalityById(value) {
  return value === this.nationalityId
}
