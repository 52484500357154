import * as yup from 'yup'
import { Entity } from 'speck-entity'

import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { getAttachmentsByLocale } from './strategies/attachments.js'

const adapter = validatorAdapter('Yup', yup)

export const CONFIGS = {
  ARG: getAttachmentsByLocale('ARG'),
  COL: getAttachmentsByLocale('COL'),
  PER: getAttachmentsByLocale('PER'),
  MYS: getAttachmentsByLocale('MYS'),
  CHL: getAttachmentsByLocale('CHL'),
}

export default class Attachment extends Entity {
  static SCHEMA = {
    type: adapter(yup.number().required()),
    name: adapter(yup.string().nullable()),
    size: adapter(yup.number().nullable()),
    url: adapter(yup.string().required()),
    sequence: adapter(yup.string().required()),
    description: adapter(yup.string().nullable()),
    base64: adapter(yup.string()),
    verified: {
      validator: adapter(yup.bool()),
      defaultValue: false
    }
  }
}
