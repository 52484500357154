import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'

export const SMS_TOKEN_RESEND_LOADING = 'natura-caduni/People/SMS_TOKEN_RESEND_LOADING'
export const SMS_TOKEN_RESEND_SUCCESS = 'natura-caduni/People/SMS_TOKEN_RESEND_SUCCESS'
export const SMS_TOKEN_RESEND_ERROR = 'natura-caduni/People/SMS_TOKEN_RESEND_ERROR'

export default function resendTokenReducer(state, action) {
  const { type, error } = action

  return {
    loading: type === SMS_TOKEN_RESEND_LOADING,
    error: type === SMS_TOKEN_RESEND_ERROR && new Error(error),
    resent: type ===SMS_TOKEN_RESEND_SUCCESS
  }
}

export function ResendToken({ personId, sequence }) {
  const {
    dispatch,
    urls: { SMS_TOKEN_RESEND },
    locale: {
      id,
      configs: {
        localization: {
          contactValidationLegacy
        }
      }
    },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  const method = contactValidationLegacy ? 'POST' : 'PATCH'

  return dispatch({
    types: [SMS_TOKEN_RESEND_LOADING, SMS_TOKEN_RESEND_SUCCESS, SMS_TOKEN_RESEND_ERROR],
    payload: {
      client: id,
      request: {
        method,
        url: SMS_TOKEN_RESEND({ personId, sequence }),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId
        },
        data: {}
      }
    }
  })
}
