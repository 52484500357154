import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class Email extends Entity {
  static SCHEMA = {
    type: adapter(yup.number().nullable()),
    email: adapter(yup.string().nullable()),
    verified: adapter(yup.boolean().nullable()),
    sequence: adapter(yup.string().nullable())
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }
}
