import { defineMessages } from 'react-intl'

export default defineMessages({
  userMediaUploadType_send: {
    defaultMessage: 'Send',
    id: 'form.field.userMediaUploadType.send'
  },
  userMediaUploadType_edit: {
    defaultMessage: 'Edit',
    id: 'form.field.userMediaUploadType.edit'
  }
})
