import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'

import { isDefaultFlowToggleActive } from '../../../config'

import { getEmailInputInfoText } from './strategies/getEmailInputInfoText'

export const messages = defineMessages({
  email: {
    defaultMessage: 'E-mail',
    id: 'form.field.email'
  },
  email_placeholder: {
    defaultMessage: 'email@example.com',
    id: 'form.field.email.placeholder'
  },
  email_info_text: {
    defaultMessage: 'If you have a Mercado Pago account, use the same email.',
    id: 'form.field.email.info_text'
  },
  string_email: {
    defaultMessage: 'Must be a valid email.',
    id: 'string.email'
  },
  email_required: {
    defaultMessage: 'Required field',
    id: 'form.field.email.required_error'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { emails }, customProps, showAsterisk, locale: { id: countryId } } = { ...injection }
  const emailInputProps = { ...customProps, infoText: getEmailInputInfoText({ messages, intl, countryId }) }

  const pushDataLayer = (values) => {
    const { value } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationPersonalData',
        fieldName: intl.formatMessage(messages['email']),
        value,
      }
    })
  }

  const EMAIL = {
    id: 'email',
    label: intl.formatMessage(messages['email']),
    placeholder: isDefaultFlowToggleActive() ? intl.formatMessage(messages['email_placeholder']) : null,
    customProps: emailInputProps,
    showAsterisk,
    onBlur: pushDataLayer,
  }

  const [email] = emails.map(mapEmails)
  const baseValue = [mapEmails({ email: '' })]

  // eslint-disable-next-line no-control-regex
  const emailRegex = /^(?:[a-z0-9_-]+(?:\.[a-z0-9_-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
  const emailErrorMessage = intl.formatMessage(messages['string_email'])

  return {
    id: 'emails',
    min: 1,
    max: 1,
    baseValue,
    defaultValue: [email],
    fields: [
      [EMAIL],
    ],
    validations: yup.array().of(yup.object().shape({
      email: yup.string().lowercase().matches(emailRegex, emailErrorMessage).required()
    })).min(1, intl.formatMessage(messages['email_required'])),
  }
}

function mapEmails({ email }) {
  return { email }
}
