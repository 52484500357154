import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

export const touchAfterReload = [
  'businessModel[0].businessModelId',
  'businessModel[0].roleId',
  'businessModel[0].functionId'
]

export function buildFormSchema(dependencies) {
  const { intl } = dependencies
  const BUSINESS_MODEL = TYPES.BUSINESS_MODEL(intl, dependencies)

  const fieldsSchema = [
    [BUSINESS_MODEL]
  ]

  return new FormSchema({ fieldsSchema, name: 'PersonalInfo' })
}
