import { COMPANY_ID, COMPANY_NAME, COMPANY_NAME_BY_ID } from '../models/Company'

export const LOCAL_STORAGE_KEYS = {
  OVERRIDE_COMPANY_ID: 'OVERRIDE_COMPANY_ID'
}

const getCompanyIdByBrowserUrl = () => {
  const url = window?.location?.origin
  return url?.toUpperCase().includes(COMPANY_NAME.AVON)
    ? COMPANY_ID.AVON
    : COMPANY_ID.NATURA
}

export const getCompanyNameByBrowserUrl = () => {
  return COMPANY_NAME_BY_ID[getCompanyIdByBrowserUrl()]
}

const getCompanyByEnv = () => {
  return Number.parseInt(process.env.CURRENT_COMPANY_ID)
}

export const getCompanyId = () =>
  getCompanyByEnv() || getCompanyIdByBrowserUrl()
