import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class OtherInformation extends Entity {
  static SCHEMA = {
    stateOfBirth: {
      validator: adapter(yup.string().nullable()),
      defaultValue: null
    }
  }
}