import { getDefaultSubstatus } from './getSubstatus.default'
import { getMalaysiaSubstatus } from './getSubstatus.mys'
import { getPeruSubstatus } from './getSubstatus.per'

const strategies = {
  MYS: getMalaysiaSubstatus,
  PER: getPeruSubstatus,
  __default__: getDefaultSubstatus
}

export const getSubstatus = (localeId) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy()
}
