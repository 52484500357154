import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Label from 'src/base/Form/Components/Label'
import Message from 'src/base/Form/Components/Message'

import './Textarea.styl'

const bem = bemClassName.bind(null, 'textarea')

class Textarea extends PureComponent {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(event) {
    const { onChange } = this.props
    const { value } = event.target

    onChange && onChange(value, event)
  }

  onBlur(event) {
    const { onBlur } = this.props
    const { value } = event.target

    onBlur && onBlur(value, event)
  }

  get value() {
    const { value } = this.props

    return (typeof value === 'string') ? value : ''
  }

  get input() {
    return this.renderInput()
  }

  render() {
    const { className, disabled, error, hidden, success } = this.props

    return (
      <div className={classNames(bem({ disabled, error, success, hidden }), className)}>
        {this.renderLabel()}
        {this.input}
        {this.renderMessage()}
      </div>
    )
  }

  renderLabel() {
    const { id, label, required, error, disabled } = this.props

    if (!label) return null

    return (
      <Label
        className={bem('label')}
        id={id}
        label={label}
        required={required}
        disabled={disabled}
        error={!!error}
      />
    )
  }

  renderInput() {
    const {
      id,
      disabled,
      placeholder,
      htmlProps,
      onKeyDown,
      onPaste
    } = this.props

    return (
      <textarea
        className={bem('component')}
        id={id}
        name={id}
        data-testid={id}
        placeholder={placeholder}
        disabled={!!disabled}
        value={this.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        {...htmlProps}
      />
    )
  }

  renderMessage() {
    const { message, error, disabled } = this.props

    if (!message && !error) return null

    return <Message
      className={bem('message')}
      message={message}
      error={error || ''}
      disabled={disabled}
    />
  }
}

Textarea.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  success: PropTypes.bool,
  htmlProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func
}

Textarea.defaultProps = {
  type: 'textarea',
  maskChar: null,
  mask: false
}

export default Textarea
