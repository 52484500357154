import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'

export const getMalaysiaParsedData = ({ data, configs, person }) => {
  const {
    localization: {
      address: {
        levels
      }
    }
  } = configs

  const cumulativeAddress = JSON.parse(localStorage.getItem('cumulativeAddress'))

  const addresses = parseData(data, levels)

  if(addresses.length > 0) {
    for (let i = 0; i < addresses[0].geographicalLevels.length; i++) {
      cumulativeAddress.geographicalLevels.push(addresses[0].geographicalLevels[i])
    }

    addresses[0].geographicalLevels = cumulativeAddress.geographicalLevels
    addresses[0].addressUse = cumulativeAddress.addressUse
  }
  addresses[0].zipCode = cumulativeAddress.geographicalLevels[1].description

  return { ...person, addresses }
}

function parseData(data, levels) {
  return data.address.map(mapAddress, { levels })
}

function mapAddress(address) {
  const {
    addressUse,
    streetAddressLine1,
    streetAddressLine2,
    city,
    state
  } = address


  const addressType = ADDRESS_TYPE.MAILING

  const geographicalLevels = [
    { level: this.levels.CITY, description: city },
    { level: this.levels.STATE, description: state },
    { level: this.levels.STREET_ADDRESS_LINE_1, description: streetAddressLine1 },
  ]

  if (streetAddressLine2) {
    geographicalLevels.push(
      { level: this.levels.STREET_ADDRESS_LINE_2, description: streetAddressLine2},
    )
  }

  return {
    addressType,
    addressUse,
    geographicalLevels
  }
}
