import PersonalInfoBuilder from './PersonalInfo'
import ContactBuilder from './Contact'
import DocumentsBuilder from './Documents'
import StatusBuilder from './Status'
import AddressHomeBuilder from './AddressHome'
import AddressDeliveryBuilder from './AddressDelivery'
import ManagementBuilder from './Management'
import DigitalSpaceBuilder from './DigitalSpace'

export function ProfileSchemaBuilder(params) {
  const PERSONAL_INFO = PersonalInfoBuilder(params)
  const CONTACT = ContactBuilder(params)
  const DOCUMENTS = DocumentsBuilder(params)
  const STATUS = StatusBuilder(params)
  const ADDRESS_DELIVERY = AddressDeliveryBuilder(params)
  const ADDRESS_HOME = AddressHomeBuilder(params)
  const DIGITAL_SPACE = DigitalSpaceBuilder(params)
  const MANAGEMENT = ManagementBuilder(params)

  return [
    PERSONAL_INFO,
    CONTACT,
    DOCUMENTS,
    STATUS,
    ADDRESS_DELIVERY,
    ADDRESS_HOME,
    DIGITAL_SPACE,
    MANAGEMENT
  ]
}
