import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'

export const getDefaultParsedData = ({ data, configs, person }) => {
  const {
    localization: {
      address: {
        levels
      }
    }
  } = configs

  const addresses = parseData(data, levels)

  const cumulativeAddress = JSON.parse(localStorage.getItem('cumulativeAddress'))
  if(addresses.length > 0) {
    for (let i = 0; i < addresses[0].geographicalLevels.length; i++) {
      cumulativeAddress.geographicalLevels.push(addresses[0].geographicalLevels[i])
    }

    cumulativeAddress.geographicalLevels.push({level: levels.NEIGHBORHOOD, description: 'Sin urbanizacion'})
    addresses[0].geographicalLevels = cumulativeAddress.geographicalLevels
    addresses[0].addressUse = cumulativeAddress.addressUse
  }

  return { ...person, addresses }
}

function parseData(data, levels) {
  return data.address.map(mapAddress, { levels })
}

function mapAddress(address) {
  const {
    number,
    addressUse,
    complement,
    references,
    street
  } = address


  const addressType = ADDRESS_TYPE.MAILING

  const geographicalLevels = [
    { level: this.levels.NUMBER, description: number},
    { level: this.levels.STREET, description: street},
    { level: this.levels.REFERENCES, description: references }
  ]

  if (complement) {
    geographicalLevels.push(
      { level: this.levels.COMPLEMENT, description: complement }
    )
  }

  return {
    addressType,
    addressUse,
    geographicalLevels
  }
}
