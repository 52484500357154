import { getCompanyId } from '../../helpers/get-company-id'
import { COMPANY_ID } from 'src/models/Company/Company'

const CHANGE_TITLE = 'natura-caduni/Title/CHANGE_TITLE'

function buildTitle(title, headerTitle) {
  if(title === "Registro") {
    const document = `${title} Natura y Avon`

    return {
      header: headerTitle !== false ? headerTitle || title : '',
      document
    }
  }
  const signature = getCompanyId() === COMPANY_ID.NATURA ? 'Natura' : 'Avon'
  const document = title ? `${title} - ${signature}` : signature

  return {
    header: headerTitle !== false ? headerTitle || title : '',
    document
  }
}

const initialState = {
  title: buildTitle()
}

export default function reducer(state = initialState, action = {}) {
  return {
    ...state,
    ...action.payload
  }
}

export function ChangeTitle(title, displayOnHeader) {
  return {
    type: CHANGE_TITLE,
    payload: {
      title: buildTitle(title, displayOnHeader)
    }
  }
}
