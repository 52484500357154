export function onSubmition(deps) {
  const { person, connectivity, peopleRegister, personVerify } = deps
  const { saveOnState, draft, personId, reviewStep } = person
  const { isOffline } = connectivity

  return function(data, callback) {
    const params = {
      ...data,
      draft,
      personId,
      reviewStep: { ...reviewStep, step: 'business-model' },
    }

    saveOnState(data)

    return personVerify.find(params, function(result) {
      const { payload: { data } = {} } = result
      const shouldUpdatePersonLocal = !isOffline && draft && !data.length

      if (shouldUpdatePersonLocal)
        peopleRegister.savePerson({ ...params, personId })

      return callback(result)
    })
  }
}
