import axios from 'axios'

const AUTHENTICATION_URL = process.env.AUTHENTICATION_URL

const AUTHENTICATION_CLIENT = {
  baseURL: AUTHENTICATION_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

const URLS = {
  AUTH: '/realms/Natura/protocol/openid-connect/token'
}

export default {
  client: axios.create(AUTHENTICATION_CLIENT),
  urls: URLS
}
