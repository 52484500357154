import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_REJECTION_LOADING = 'natura-caduni/People/PROFILE_REJECTION_LOADING'
export const PROFILE_REJECTION_SUCCESS = 'natura-caduni/People/PROFILE_REJECTION_SUCCESS'
export const PROFILE_REJECTION_ERROR = 'natura-caduni/People/PROFILE_REJECTION_ERROR'

const TYPES_REJECTION = [PROFILE_REJECTION_LOADING, PROFILE_REJECTION_SUCCESS, PROFILE_REJECTION_ERROR]

export function rejectPerson(personId, personRoleId, rejectionReasonId) {
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  const sourceSystem = 7
  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext })

  return dispatch({
    types: TYPES_REJECTION,
    payload: {
      client: clientName,
      request: {
        method: 'PATCH',
        url: requestUrl,
        headers: requestHeaders,
        data: {
          rejectionReasonId
        }
      }
    }
  })

  function getRequestPayload({ globalAppContext }) {
    const {
      REJECTION,
      REJECTION_BFF,
      bffHeaders,
      headersAuthenticationInfo,
      shouldRejectRoleFromBff,
      countryId,
      companyId,
      sellerId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const { requestHeaders } = getRequestHeaders({ shouldRejectRoleFromBff, headersAuthenticationInfo })

    const requestUrl = shouldRejectRoleFromBff ? REJECTION_BFF : REJECTION

    const clientName = shouldRejectRoleFromBff
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { REJECTION },
        default_bff_urls: { REJECTION_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          tenantId,
          configs
        },
        user: {
          authentication: {
            xApikey,
            accessToken
          },
          sellerId,
        },
      } = globalAppContext

      const shouldRejectRoleFromBff = configs?.localization?.isHostedUIEnabled

      return {
        REJECTION: REJECTION(personId, personRoleId),
        REJECTION_BFF: REJECTION_BFF(personId, personRoleId),
        shouldRejectRoleFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        countryId,
        companyId,
        tenantId,
        sellerId,
      }
    }

    function getRequestHeaders({ shouldRejectRoleFromBff, headersAuthenticationInfo }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const requestHeaders = shouldRejectRoleFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        userId: sellerId,
        sourcesystem: sourceSystem,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        sourcesystem: sourceSystem,
        countryId,
      }

      return { requestHeaders }
    }
  }
}
