import { AddressParser } from './AddressParser'
import { store } from '../../../../../../../../App.state'
import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import {
  ADDRESS_USE,
  DELIVERY_ADDRESS,
  MAILING_ADDRESS
} from '../../../../../../../../models/Address/AddressUse'

export class ColombiaAddressParser extends AddressParser {
  getParsedData({ data, configs }) {
    const {
      localization: {
        address: { levels }
      }
    } = configs
    const { person } = store.getState()

    const dataMailingAddress = data.address?.find((address) => address.addressUse.find(({ id }) => id === ADDRESS_USE.MAILLING))

    if (!dataMailingAddress) {
      const addresses = []
      localStorage.setItem('cumulativeAddress', this.getCumulativeAddress(addresses))
      return { addresses }
    }

    const mailingAddress = this.mapAddress({ address: dataMailingAddress, levels })
    const previousSavedMailingAddressLevels = this.getComplementaryAddressFrom(person, levels)

    localStorage.setItem('cumulativeAddress', this.getCumulativeAddress([mailingAddress]))

    mailingAddress.geographicalLevels = [
      ...mailingAddress.geographicalLevels,
      ...previousSavedMailingAddressLevels
    ]

    const filledAddresses = this.getAllFilledAddresses(person, mailingAddress)
    const otherAddress = this.getOtherAddress(filledAddresses, person)

    return { addresses: [ ...filledAddresses, ...otherAddress]}
  }

  getOtherAddress(filledAddresses, person) {
    const filledSequences = filledAddresses.map(address => address.sequence).filter(sequence => sequence)
    const otherAddresses = filledSequences.length > 0 ? person.person.addresses.filter(address => !filledSequences.includes(address.sequence)) : []

    const parsedOtherAddresses = otherAddresses.map(address => this.parseAddress(address)).filter(address => address.geographicalLevels.length !== 0)

    return parsedOtherAddresses
  }

  getAllFilledAddresses(person, mailingAddress) {
    const deliveryAddress = person.person.addresses.find(address => address.addressUse.find(({ id }) => id === DELIVERY_ADDRESS.id))
    const personDontHasDeliveryAddress = !deliveryAddress?.geographicalLevels?.length
    const personHasLessThanTwoAddresses = person.person.addresses.length < 2
    if (personDontHasDeliveryAddress || personHasLessThanTwoAddresses) {
      return [mailingAddress]
    }
    const parsedMailingAddress = {
      ...mailingAddress,
      addressType: ADDRESS_TYPE.MAILING,
      addressUse: [MAILING_ADDRESS]
    }

    const parsedDeliveryAddressGeographicalLevels = (
      deliveryAddress.geographicalLevels.map(({ code, level, description }) => {
        const parsedGeographicalLevel = { level, description }
        if (code) {
          parsedGeographicalLevel.code = code
        }
        return parsedGeographicalLevel
      })
    )

    const parsedDeliveryAddress = {
      ...deliveryAddress,
      addressType: ADDRESS_TYPE.DELIVERY,
      addressUse: [DELIVERY_ADDRESS],
      geographicalLevels: parsedDeliveryAddressGeographicalLevels
    }

    return [parsedMailingAddress, parsedDeliveryAddress]
  }

  parseAddress(address) {
    const parsedAddressGeographicalLevels =  address.geographicalLevels.map(({ code, level, description }) => {
      const parsedGeographicalLevel = { level, description }
      if (code) {
        parsedGeographicalLevel.code = code
      }
      return parsedGeographicalLevel
    })
    return {
      ...address,
      geographicalLevels: parsedAddressGeographicalLevels
    }
  }

  buildGeographicalLevels({ addressesStructure, country, levels }) {
    const { department, city, neighborhood } = addressesStructure
    return [
      { level: levels.COUNTRY, description: country },
      {
        level: levels.REGION,
        code: department.geoStructureCode,
        description: department.structureLevelName
      },
      {
        level: levels.CITY,
        code: city.geoStructureCode,
        description: city.structureLevelName
      },
      {
        level: levels.NEIGHBORHOOD,
        code: neighborhood.geoStructureCode,
        description: neighborhood.structureLevelName
      }
    ]
  }

  getAddressStructure({ types, addresses }) {
    const { departments, cities, neighborhoods } = addresses
    const { country, department, city, neighborhood } = types

    return {
      department: this.getGeoStructureValues(departments[country], department),
      city: this.getGeoStructureValues(cities[department], city),
      neighborhood: this.getGeoStructureValues(neighborhoods[city], neighborhood)
    }
  }

  getComplementaryAddressFrom(personStructure, levels) {
    const { person } = personStructure
    const results = []

    if (!person.addresses.length) return results

    const onlyMailingAddress = person.addresses.filter((address) => {
      return address.addressUse.find((use) => {
        return use.id === ADDRESS_USE.MAILLING
      })
    })

    if(onlyMailingAddress[0]?.geographicalLevels){
      const geographicalLevels = onlyMailingAddress[0].geographicalLevels

      const levelsToFilter = [
        levels.STREET,
        levels.NUMBER,
        levels.COMPLEMENT,
        levels.REFERENCES
      ]

      geographicalLevels.forEach((geoLevel) => {
        if (levelsToFilter.includes(geoLevel.level)) {
          results.push({
            level: geoLevel.level,
            description: geoLevel.description,
          })
        }
      })
    }
    return results
  }
}
