import { messages } from './Indication.messages'

export default function(intl) {
  return {
    COL: {
      typeOptions: [
        {
          value: '10',
          label: intl.formatMessage(messages['indication_search_type_cedula_extranjeira'])
        },
        {
          value: '9',
          label: intl.formatMessage(messages['indication_search_type_cedula_cidadania'])
        },
        {
          value: 'code',
          label: intl.formatMessage(messages['indication_search_type_code'])
        },
        {
          value: 'name',
          label: intl.formatMessage(messages['indication_search_type_name'])
        }
      ],
      indicationOptions: [
        {
          value: false,
          label: intl.formatMessage(messages['has_Indication_not'])
        },
        {
          value: true,
          label: intl.formatMessage(messages['has_Indication_yes'])
        },
      ]
    },
    PER: {
      typeOptions: [
        {
          value: '19',
          label: intl.formatMessage(messages['indication_search_type_dni'])
        },
        {
          value: '33',
          label: intl.formatMessage(messages['indication_search_type_carnet_foreign'])
        },
        {
          value: '32',
          label: intl.formatMessage(messages['indication_search_type_passport'])
        },
        {
          value: 'code',
          label: intl.formatMessage(messages['indication_search_type_code'])
        },
      ],
      indicationOptions: [
        {
          value: false,
          label: intl.formatMessage(messages['has_Indication_not'])
        },
        {
          value: true,
          label: intl.formatMessage(messages['has_Indication_yes'])
        },
      ]
    }
  }
}
