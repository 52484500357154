import { store } from 'src/App.state'
import { ADDRESS_TYPE } from 'src/models/Address/AddressType'
import { isAllocationCallToggleActive } from '../../../../../../config'

export function onSubmition({
  person,
  locale: {
    configs: {
      localization: {
        address: {
          levels
        }
      }
    }
  }
}) {
  return function(data, callback) {
    const addresses = parseData(data, levels)

    const reviewStep = {
      ...person.reviewStep,
      step: 'payment',
      address: true
    }

    return person.save({ addresses, reviewStep }, allocatePerson(person, callback))
  }
}

function allocatePerson(person, callback) {
  return function () {
    if (isAllocationCallToggleActive()) {
      person.allocate()
    }
    callback()
  }
}

function parseData(data, levels) {
  return data.address.map(mapAddress, { levels })
}

function mapAddress(address) {
  const {
    addresses: {
      addresses,
      addressesPeopleManagement
    },
    locale: {
      locale
    }
  } = store.getState()

  const {
    number,
    addressUse,
    countryName: country,
    street,
    ...addressValues
  } = address

  const types = {
    ...addressValues,
    country,
    number,
    street
  }

  const addressType = ADDRESS_TYPE.MAILING
  const addressesStructure = getStructure({ types, addressesCMM: addresses, addressesBFF: addressesPeopleManagement, locale })

  const geographicalLevels = [
    { type: 'country', level: this.levels.COUNTRY },
    { type: 'region', level: this.levels.REGION },
    { type: 'department', level: this.levels.DEPARTMENT },
    { type: 'province', level: this.levels.PROVINCE },
    { type: 'district', level: this.levels.DISTRICT },
    { type: 'neighborhood', level: this.levels.NEIGHBORHOOD },
    { type: 'street', level: this.levels.STREET },
    { type: 'number', level: this.levels.NUMBER },
    { type: 'complement', level: this.levels.COMPLEMENT },
    { type: 'references', level: this.levels.REFERENCES },
  ].reduce(reduceLevels, [{ types, addressesStructure }])

  return {
    addressType,
    addressUse,
    geographicalLevels
  }
}

function getStructure({ types, addressesCMM, addressesBFF, locale }) {
  const { configs: { localization: { shouldGetAddressFromBff }}} = locale
  const address = shouldGetAddressFromBff ? addressesBFF : addressesCMM

  const { region, department, province, district, country } = types

  return {
    region: getGeoStructureValues(address.regions[country], region),
    department: getGeoStructureValues(address.departments[region], department),
    province: getGeoStructureValues(address.provinces[department], province),
    district: getGeoStructureValues(address.districts[province], district),
  }
}

function reduceLevels(accum, { type, level }, index, array) {
  const lastItem = index === array.length - 1
  const [dependencies] = accum

  const addressLevel = builderLevel({ level, type, dependencies })

  if (lastItem) accum.shift()

  if (!addressLevel) return accum

  return [ ...accum, addressLevel]
}

function builderLevel({ level, type, dependencies }) {
  const { types, addressesStructure } = dependencies

  const fieldDescription = types[type]
  const addressesStructureSelected = addressesStructure[type]

  if (!fieldDescription) return undefined

  if (addressesStructureSelected) {
    return {
      level,
      code: addressesStructureSelected.value,
      description: addressesStructureSelected.label.toUpperCase()
    }
  }

  return { level, description: fieldDescription.toUpperCase() }
}

function getGeoStructureValues(addresses, value) {
  return addresses.find(findAddress, { value })
}

function findAddress({ value }) {
  return value === this.value
}
