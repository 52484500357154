const ACTIVE_STATUS = 'ACTIVE'

function findPermittedRole({ id }) {
  return id === this.permittedRole
}

function reducePersonPermission({ allowed, rolesList }, permittedRole) {
  if (allowed) return { allowed, rolesList }

  const activeRolesList = rolesList.filter(({ status }) => status === ACTIVE_STATUS)

  return {
    allowed: !!activeRolesList.find(findPermittedRole, { permittedRole }),
    rolesList,
  }
}

export default function checkPersonPermission({ allowedRoles, rolesList }) {
  return allowedRoles.reduce(reducePersonPermission, { allowed: false, rolesList })
}
