import { defineMessages } from 'react-intl'

export default defineMessages({
  file_button_send: {
    defaultMessage: 'Send',
    id: 'documents.file.button.send'
  },
  file_button_edit: {
    defaultMessage: 'Edit',
    id: 'documents.file.button.edit'
  }
})
