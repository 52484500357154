export default function Builder(intl, injection) {
  const {
    person,
    locale: {
      configs: { localization }
    },
    user
  } = injection
  const {
    documents: documentsConfigs,
    ocr: { fields, validations = [] }
  } = localization

  const { type: documentType } =
    person.documents.find(findDocuments, {
      documents: documentsConfigs.personal
    }) || {}

  const { attachmentsToValidate = [] } =
    validations.find(findOCRValidation, { documentType }) || {}

  return {
    id: 'ocr',
    type: 'ocr',
    customProps: {
      intl,
      fields,
      attachmentsToValidate,
      shouldValidateAttachments: !user.isCSC
    }
  }
}

function findDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}

function findOCRValidation({ documentType }) {
  return documentType === this.documentType
}
