export default function wrapAsync(functionToWrap) {
  return function(parameters) {
    return functionToWrap(parameters).then(successHandler).catch(errorHandler)
  }
}

function errorHandler ({ response = {} }) {
  return {
    error: {
      message: response.data,
      status: response.status
    }
  }
}

function successHandler ({ data }) {
  return { data }
}
