import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import User from 'src/models/User'
import { STATUS } from 'src/models/Status'
import { SUBSTATUS } from 'src/models/Substatus'

const adapter = validatorAdapter('Yup', yup)

export default class Management extends Entity {
  static SCHEMA = {
    user: {
      validator: adapter(yup.object()),
      type: User,
      defaultValue: {}
    },
    status: adapter(yup.number().nullable()),
    substatus: adapter(yup.number().nullable()),
    suggestedSalesHierarchyId: adapter(yup.number().nullable()),
    cnoList: {
      validator: adapter(yup.array().of(yup.object())),
      defaultValue: []
    }
  }

  get showApproveAndRejectButton() {
    const { substatus, user, suggestedSalesHierarchyId } = this
    const sameHierarchy = user.hierarchyId === suggestedSalesHierarchyId

    const PENDING_GR_APPROVAL = substatus === SUBSTATUS.PENDING_GR_APPROVAL
    const PENDING_GV_APPROVAL = substatus === SUBSTATUS.PENDING_GV_APPROVAL
    const PENDING_ANALISYS_RESTART = substatus === SUBSTATUS.PENDING_RESTART_REVIEW
    const PENDING_ANALISYS_ADDRESS = substatus === SUBSTATUS.PENDING_ADDRESS_REVIEW

    const shouldShowToCSC =
       user.isCSC &&
       !PENDING_ANALISYS_RESTART &&
       !PENDING_GV_APPROVAL &&
       !PENDING_GR_APPROVAL &&
       !PENDING_ANALISYS_ADDRESS

    const shouldShowToGR = user.isGR && (!PENDING_GR_APPROVAL || !sameHierarchy)
    const shouldShowToGV = user.isGV && !PENDING_GV_APPROVAL

    if (user.isCNO) return false
    if (shouldShowToGR) return false
    if (shouldShowToGV) return false
    if (shouldShowToCSC) return false

    return true
  }

  get showRevalidateButton() {
    return this.user.isCSC && this.substatus === SUBSTATUS.PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED
  }

  get showRemovePendencyButton() {
    return this.user.isCSC && this.substatus === SUBSTATUS.PENDING_PREPAID
  }

  get showCNOlist() {
    const { user, status, cnoList, suggestedSalesHierarchyId } = this

    const APPROVED = status === STATUS.APPROVED
    const sameHierarchy = user.hierarchyId === suggestedSalesHierarchyId

    const shouldShowCNOList = user.isCNO || !cnoList.length || APPROVED
    const isGRAndNoHasSameHierarchy = user.isGR && !sameHierarchy

    if (shouldShowCNOList) return false
    if (isGRAndNoHasSameHierarchy) return false

    return true
  }
}
