import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import MaterialCheckbox from '@material-ui/core/Checkbox'
import Icon from 'src/base/Icon'

import './Checkbox.styl'
import { isDefaultFlowToggleActive } from '../../config'

const bem = bemClassName.bind(null, 'checkbox')

export const TYPES = {
  LIGHT: 'light',
  DARK: 'dark'
}

class Checkbox extends PureComponent {
  render() {
    const { className, type, error, disabled, checked, id, onBlur, onChange } = this.props

    return (
      <MaterialCheckbox
        disableRipple={false}
        color="primary"
        className={classNames(bem({ [type]: true, error: !!error, disabled }), className)}
        checkedIcon={isDefaultFlowToggleActive() ? undefined : <Icon name="form_checkbox_filled" className={bem('icon', [ 'filled' ])} />}
        icon={isDefaultFlowToggleActive() ? undefined : <Icon name="form_checkbox_unfilled" className={bem('icon', [ 'unfilled' ])} />}
        id={id}
        data-testid={id}
        checked={checked}
        onBlur={onBlur}
        onChange={onChange}
        data-error={!!error}
        disabled={disabled}
      />
    )
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  checked: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  type: TYPES.DARK,
  error: false
}

export default Checkbox
