import * as yup from 'yup'
import { store } from 'src/App.state'
import Region from './Region'
import Department from './Department'
import Province from './Province'
import District from './District'
import Neighborhood from './Neighborhood'
import Street from './Street'
import NumberAddress from '../Number'
import Complement from '../Complement'
import References from '../References'
import ButtonAddConfig from '../AddressAdd'
import ButtonRemoveConfig from '../AddressRemove'
import CopyAddress, { getValidationCopyAddress } from '../AddressCopy'
import FormTitle from 'src/configs/Fields/Address/AddressTitle'
import { messages } from '../Messages'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    person: { addresses = [] },
    connectivity
  } = injection

  const baseValue = {
    copyAdress: true,
    countryName: 'PERU'
  }

  const copyAddressRequired = intl.formatMessage(messages.address_required)
  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)
  const autoFocus = { autoFocus: false }

  const placeholder = !connectivity.isOffline
    ? intl.formatMessage(messages['district_placeholder'])
    : null

  const REGION = {
    ...Region(intl, injection),
    htmlProps: autoFocus,
    placeholder
  }
  const DEPARTMENT = { ...Department(intl, injection), placeholder, htmlProps: autoFocus }
  const PROVINCE = { ...Province(intl, injection), placeholder, htmlProps: autoFocus }
  const DISTRICT = { ...District(intl, injection), placeholder, htmlProps: autoFocus }
  const STREET = { ...Street(intl, injection) }
  const NEIGHBORHOOD = {
    ...Neighborhood(intl, injection)
  }
  const NUMBER_ADDRESS = {
    ...NumberAddress(intl, injection)
  }
  const COMPLEMENT = { ...Complement(intl, injection) }
  const REFERENCES = { ...References(intl, injection) }
  const COPY_ADDRESS = CopyAddress(intl, injection)
  const BUTTON_ADD = ButtonAddConfig(intl, injection)
  const BUTTON_REMOVE = ButtonRemoveConfig(intl, injection)
  const GROUP_TITLE = FormTitle(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    addButton: BUTTON_ADD,
    removeButton: BUTTON_REMOVE,
    itemTitle: GROUP_TITLE,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [REGION],
      [DEPARTMENT],
      [PROVINCE],
      [DISTRICT],
      [STREET],
      [NEIGHBORHOOD],
      [NUMBER_ADDRESS],
      [COMPLEMENT],
      [REFERENCES],
      [COPY_ADDRESS]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          region: yup.string().required(),
          department: yup.string().required(),
          province: yup.string().required(),
          district: yup.string().required(),
          neighborhood: yup.string().required(),
          street: yup.string().required(),
          number: yup.string().max(15).required(),
          complement: yup.string().max(100),
          references: yup.string().max(100).required(),
          copyAdress: getValidationCopyAddress(copyAddressRequired)
        })
      )
      .min(1)
      .max(20)
  }
}

function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)

    return connectivity.isOffline
      ? addresses.reduce(reduceAddressOffline, [baseValue])
      : addresses.reduce(reduceAddress, [baseValue])
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress = deliveryAddress && !mailingAddress.isUseToDelivery

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

function reduceAddress(accum, address, index, array) {
  const {
    addresses: { addressesPeopleManagement }
  } = store.getState()
  const { regions, departments, provinces, districts } = addressesPeopleManagement
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    region,
    department,
    province,
    district,
    neighborhood,
    street,
    addressNumber,
    addressComplement,
    references,
    addressUse,
    isUseToDelivery
  } = address

  const { geoStructureCode: regionCode } = getStructure(
    regions[countryName],
    region
  )

  const { geoStructureCode: departmentCode } = getStructure(
    departments[regionCode],
    department
  )

  const { geoStructureCode: provinceCode } = getStructure(
    provinces[departmentCode],
    province
  )

  const { geoStructureCode: districtCode } = getStructure(
    districts[provinceCode],
    district
  )

  const neighborhoodDescription = neighborhood && neighborhood.description
  const addressNumberDescription = addressNumber && addressNumber.description
  const addressComplementDescription =
    addressComplement && addressComplement.description
  const referencesDescription = references && references.description
  const streetDescription = street && street.description

  const newAddress = {
    region: regionCode,
    department: departmentCode,
    province: provinceCode,
    district: districtCode,
    neighborhood: neighborhoodDescription,
    number: addressNumberDescription,
    complement: addressComplementDescription,
    references: referencesDescription,
    street: streetDescription,
    addressUse,
    countryName,
    copyAdress: isUseToDelivery
  }

  return [...accum, newAddress]
}

function reduceAddressOffline(accum, address, index, array) {
  const [{ countryName, copyAdress }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    region,
    department,
    province,
    district,
    neighborhood,
    street,
    addressNumber,
    addressComplement,
    references
  } = address

  const newAddress = {
    region,
    department,
    province,
    district,
    neighborhood,
    number: addressNumber,
    complement: addressComplement,
    references,
    street,
    countryName,
    copyAdress
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  return value.toString() === this.code
}
