import { getContext } from 'src/infra/Api/Api.context'
import EmailValidation from 'src/viewModels/EmailValidation'

const EMAIL_VALIDATION_RESET = 'natura-caduni/EMAIL_VALIDATION_RESET'
export const types = [EMAIL_VALIDATION_RESET]

export function emailResetReducer(state, action, dependencies) {
  return {
    loading: false,
    error: false,
    emailValidation: new EmailValidation({}, dependencies)
  }
}

export function resetEmail() {
  const { dispatch } = getContext()

  return dispatch({
    type: EMAIL_VALIDATION_RESET
  })
}
