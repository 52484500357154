import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import { get } from 'lodash'
import TagManager from 'react-gtm-module'
import { isDefaultFlowToggleActive } from '../../../config'
import { getPhoneOptions } from './Phone.type'

import { PHONE_TYPES } from 'src/models/Phone'
import { isCurrentLocationOnRegisterPage } from 'src/helpers/app-history'

const MESSAGES = defineMessages({
  phone: {
    defaultMessage: 'Phone',
    id: 'form.field.phone'
  },
  phone_error_invalid: {
    defaultMessage: 'Invalid phone',
    id: 'form.field.phone.error.invalid'
  },
  phone_error_start_code: {
    defaultMessage: 'Identification code must be started with {number}',
    id: 'form.field.phone.error.start.code'
  },
  phone_line_error_custom_char: {
    defaultMessage: 'The phone should not start with 0',
    id: 'form.field.phone.line.error.custom.char'
  },
  phone_message: {
    defaultMessage: 'We recommend the registration of your mobile phone',
    id: 'form.field.phone.message'
  },
  phone_email_error: {
    defaultMessage: 'If you do not complete the e-mail field, it is mandatory to fill a mobile phone',
    id: 'form.field.phone.email.error'
  }
})

const dependencies = {
  messages: MESSAGES
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    locale: {
      configs: {
        localization: {
          phone
        }
      }
    },
    customProps = {},
    showAsterisk
  } = injection

  const pushDataLayer = (values) => {
    const options = getPhoneOptions(intl)
    const { value, values: { phones } } = values

    const phoneTypeId = phones[0].type
    const selectedPhoneType = options.filter((phoneType) => phoneType.value === phoneTypeId)[0]

    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationPersonalData',
        fieldName: intl.formatMessage(messages['phone']),
        phoneType: selectedPhoneType.label,
        value,
      }
    })
  }

  const { mask, placeholder } = phone

  const phoneMessage = !isDefaultFlowToggleActive() && (customProps.phoneMessage || intl.formatMessage(messages['phone_message']))

  return {
    id: 'phone',
    label: isDefaultFlowToggleActive() && !isCurrentLocationOnRegisterPage() ? ' ' : intl.formatMessage(messages['phone']),
    message: phoneMessage,
    mask: getMaskAndPlaceholder(mask),
    placeholder: getMaskAndPlaceholder(placeholder),
    showAsterisk,
    customProps,
    onBlur: pushDataLayer,
  }
}

export function getMaskAndPlaceholder({ mobile, line }) {
  return function ({ values }, id) {
    const parts = id.split('.')
    const key = parts[0]
    const currentGroup = get(values, key)

    if (!currentGroup) return mobile

    const types = {
      [PHONE_TYPES.MOBILE]: mobile,
      [PHONE_TYPES.LINE]: line
    }

    return types[currentGroup.type] || mobile
  }
}

export function getPhoneValidation(params) {
  const {
    messages = MESSAGES,
    intl,
    customValidation,
    lengthValidation,
    emailRequired = true,
    errorMessage
  } = params
  const invalidMsg = intl.formatMessage(messages['phone_error_invalid'])
  const charErrorLineMsg = intl.formatMessage(messages['phone_line_error_custom_char'])

  const baseValidation = yup
    .string()
    .transform(function (value) {
      return value
        .replace(' ', '')
        .replace('-', '')
        .replace(' ', '')
    })

  const mobileValidation = buildMobileValidation(baseValidation, params)

  const lineValidation = baseValidation
    .min(lengthValidation.line.min)
    .matches(customValidation.line, charErrorLineMsg)
    .matches(customValidation.invalid, invalidMsg)
    .test({
      name: 'emailRequired',
      message: intl.formatMessage(messages.phone_email_error),
      test() {
        if (!emailRequired) return true

        const { path, options: { context: { values: { phones, emails = [] }, errors } } } = this
        const [rawIndex] = path.match(/\d+/g)
        const index = +rawIndex
        const current = phones[index]
        const other = phones[index + 1] || phones[0]
        const emailError = errors.emails && errors.emails.fields[0].email
        const hasValidEmail = emails[0] && emails[0].email && !emailError
        const currentIsMobile = phoneParser(current)
        const otherIsMobile = phoneParser(other)

        if (!hasValidEmail) return currentIsMobile || otherIsMobile

        return true
      }
    })

  return yup
    .string()
    .when('type', {
      is: PHONE_TYPES.MOBILE,
      then: mobileValidation,
      otherwise: lineValidation.required(errorMessage)
    })
}

function phoneParser(field) {
  return field.type === PHONE_TYPES.MOBILE
}

function buildMobileValidation(base, params) {
  const { lengthValidation, customValidation, intl, messages = MESSAGES, errorMessage } = params
  const { mobile: { min } } = lengthValidation
  const { mobile: { start_code } } = customValidation
  const { formatMessage } = intl

  const startCodeMessage = formatMessage(messages['phone_error_start_code'], { number: start_code.number })

  return base
    .min(min)
    .matches(start_code.validation, startCodeMessage)
    .required(errorMessage)
}
