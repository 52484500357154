import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  rg_label: {
    defaultMessage: 'RG',
    id: 'form.field.rg'
  },
  rg_invalid: {
    defaultMessage: 'Invalid RG',
    id: 'form.field.rg.invalid'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { documents } = {} } = injection
  const { document: defaultValue } = documents && documents.find(({ type }) => type == 2) || {}

  const invalidRG = intl.formatMessage(messages['rg_invalid'])

  return {
    id: 'rg',
    label: intl.formatMessage(messages['rg_label']),
    placeholder: '00.000.000-0',
    mask: '99999999999999',
    defaultValue,
    validations: yup.string().min(8, invalidRG)
  }
}
