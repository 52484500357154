import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)
const OCR_TASK_ID = 4
const OCR_TYPE_ID = 5

const SUCCESSFUL_STATUS_ID = 3
const ERROR_STATUS_ID = 4

export default class Workflow extends Entity {
  static SCHEMA = {
    businessModelId: adapter(yup.number().nullable()),
    functionId: adapter(yup.number().nullable()),
    roleId: adapter(yup.number().nullable()),
    companyId: adapter(yup.number().nullable()),
    countryId: adapter(yup.number().nullable()),
    flowType: adapter(yup.string().nullable()),
    isFullyBlocked: adapter(yup.bool().nullable()),
    workflowBeginDate: adapter(yup.date().nullable()),
    workflowEndDate: adapter(yup.date().nullable()),
    workflowId: adapter(yup.number().nullable()),
    workflowStatus: adapter(yup.number().nullable()),
    workflowStatusDescription: adapter(yup.string().nullable()),
    workflowTasks: adapter(yup.array().of(yup.object().shape({
      beginDate: yup.date().nullable(),
      endDate: yup.date().nullable(),
      description: yup.string().nullable(),
      statusDescription: yup.string().nullable(),
      statusId: yup.number().nullable(),
      taskId: yup.number().nullable(),
      step: yup.number().nullable(),
      type: yup.number().nullable()
    }))),
    workflowVersion: adapter(yup.number().nullable())
  }

  get validationAttachmentsTask() {
    return this.workflowTasks.find(findValidationAttachmentsTask)
  }

  get validationAttachmentsTaskIsSuccess() {
    return this.validationAttachmentsTask.statusId === SUCCESSFUL_STATUS_ID
  }

  get validationAttachmentsTaskIsError() {
    return this.validationAttachmentsTask.statusId === ERROR_STATUS_ID
  }
}

function findValidationAttachmentsTask({ type, taskId }) {
  return taskId === OCR_TASK_ID && type == OCR_TYPE_ID
}
