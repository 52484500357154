import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { isEqual } from 'lodash'

import { DOCUMENTS } from 'src/models/Document'
import { documentsEnum } from 'src/configs/Fields/Documents/Documents.type'
import IndicationError from './Indication.error'

const ENTER = 13

export class IndicationState extends Component {
  constructor(props){
    super(props)

    this.state = {
      search: '',
      searchType: 'code',
      searchOffline: '',
      showModal: false,
      hasIndication: false,
      introducerSellerId: '',
      changeIntroducer: false,
      update: false,
      introducerSellerName: '',
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleCancelModal = this.handleCancelModal.bind(this)
    this.handleConfirmModal = this.handleConfirmModal.bind(this)
    this.handleModal = this.handleModal.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, customProps: { personId, shouldReviewPerson } } = this.props
    const { defaultValue: prevDefaultValue, form, id, customProps } = prevProps
    const { personId: prevPersonId } = customProps
    const { setFieldValue } = form
    const { changeIntroducer, update } = this.state

    const isEqualPersonId = isEqual(prevPersonId, personId)
    const isEqualDefaultValue = isEqual(prevDefaultValue, defaultValue)

    const shouldReset = !isEqualPersonId && changeIntroducer
    const isReviewPerson = !!defaultValue && shouldReviewPerson && !update
    const shouldSetDefaultValue = !!defaultValue && !isEqualDefaultValue && !shouldReviewPerson
    const shouldSetDefaultValueWhenUpdate = !!defaultValue && isEqualDefaultValue && !update && !shouldReviewPerson

    if (shouldReset) {
      this.setState({
        hasIndication: false,
        searchType: null,
        search: null,
        introducerSellerId: null,
      })
    }

    if (shouldSetDefaultValue || shouldSetDefaultValueWhenUpdate) {
      this.setState({
        hasIndication: true,
        search: defaultValue,
        changeIntroducer: true,
        searchType: 'code',
        update: true
      })

      setFieldValue(id, defaultValue)
    }

    if (isReviewPerson) {
      this.setState({
        hasIndication: true,
        search: defaultValue,
        changeIntroducer: false,
        searchType: 'code',
        update: true
      })

      setFieldValue(id, false)
    }
  }

  onKeyDown(event) {
    const pressedEnter = event.keyCode === ENTER

    pressedEnter && event.preventDefault()
  }

  handleChangeSearch(value) {
    const { form: { setFieldValue, setFieldTouched }, id } = this.props

    this.setState({ search: value, changeIntroducer: false })
    const { customProps: { showSearchButton } } = this.props

    const valueWithoutModal = showSearchButton || value
    setFieldValue(id, valueWithoutModal)
    setFieldTouched(id)
  }

  handleBlur(value) {
    const { consultants: { consultants }, customProps : { pushDataLayer } } = this.props

    pushDataLayer(value)
    consultants.indicationVerify({ isVerified: false })
  }

  handleCancelModal(showModal) {
    this.setState({ search: '' })
    this.setState({ showModal, introducerSellerId: '' })
  }

  handleConfirmModal(showModal, { naturaCode, name }) {
    const { form: { setFieldValue, setFieldTouched }, id } = this.props

    const params = {
      showModal,
      introducerSellerId: naturaCode ? naturaCode.toString() : '',
      changeIntroducer: !!naturaCode,
      introducerSellerName: name || '',
    }

    if (!naturaCode) {
      this.setState({ search: '' })
    }

    setFieldValue(id, params.introducerSellerId)
    setFieldValue('introducerSellerName', name)
    setFieldTouched(id, true)

    this.setState(params)
  }

  handleModal() {
    const { search, searchType } = this.state
    const { customProps, consultants } = this.props
    const { handleGetConsultants, personId } = customProps
    const params = {
      search,
      searchType,
      consultants,
      personId,
    }
    handleGetConsultants(params)
    this.setState({ showModal: true })
  }

  get documentValidation() {
    const { customProps: { configs: { documents: { lengthValidation } } } } = this.props
    const { searchType, search } = this.state

    const size = lengthValidation[documentsEnum[searchType]] || {}

    const isSearchingDocuments = isDocument(searchType)
    const searchDocMin = search && search.length < size.min
    const error = isSearchingDocuments && searchDocMin

    return {
      size,
      error
    }
  }

  get disabledButton() {
    const { search, hasIndication, changeIntroducer } = this.state
    const { disabled, value: introducerSellerId, customProps } = this.props
    const { hiddenIndicationQuestion } = customProps

    const isEmpty = !hiddenIndicationQuestion && !hasIndication
    const hasIntroducerSellerId = !!introducerSellerId && !changeIntroducer

    const shouldDisabled = disabled || !search || this.documentValidation.error

    if (shouldDisabled) return true
    if (hasIntroducerSellerId) return false
    if (isEmpty || introducerSellerId) return true

    return false
  }

  get disabledTypeSearch() {
    const { hasIndication } = this.state
    const { disabled, customProps: { hiddenIndicationQuestion } } = this.props

    return disabled || !hasIndication && !hiddenIndicationQuestion
  }

  get searchSuccess() {
    const { introducerSellerId, changeIntroducer } = this.state
    const { disabled } = this.props

    return !!introducerSellerId && changeIntroducer && !disabled
  }

  get mask() {
    const { customProps: { configs: { documents: { mask } } }, mask: codeMask } = this.props
    const { searchType } = this.state

    if (searchType === 'code') return codeMask

    return mask[documentsEnum[searchType]]
  }

  render() {
    return (
      <IndicationError
        {...this.props}
        search={this.state.search}
        searchOffline={this.state.searchOffline}
        showModal={this.state.showModal}
        searchType={this.state.searchType}
        hasIndication={this.state.hasIndication}
        changeIntroducer={this.state.changeIntroducer}
        introducerSellerId={this.state.introducerSellerId}
        introducerSellerName={this.state.introducerSellerName}
        onKeyDown={this.onKeyDown}
        handleChangeHasIndication={this.handleChangeHasIndication}
        handleChangeSearch={this.handleChangeSearch}
        handleCancelModal={this.handleCancelModal}
        handleConfirmModal={this.handleConfirmModal}
        disabledButton={this.disabledButton}
        disabledSearch={false}
        disabledTypeSearch={this.disabledTypeSearch}
        searchSuccess={this.searchSuccess}
        documentValidation={this.documentValidation}
        handleModal={this.handleModal}
        handleBlur={this.handleBlur}
        mask={this.mask}
      />
    )
  }
}

IndicationState.propTypes = {
  intl: PropTypes.any,
  id: PropTypes.string,
  form: PropTypes.object,
  options: PropTypes.object,
  consultants: PropTypes.object,
  mask: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customProps: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

function mapStateToProps(state, ownProps) {
  const { connectivity, consultants, person: { loading } } = state

  return {
    ...ownProps,
    consultants,
    connectivity,
    loading
  }
}

function isDocument(type) {
  const documents =  Object.values(DOCUMENTS)

  return documents.includes(+type)
}

export default injectIntl(connect(mapStateToProps)(IndicationState))
