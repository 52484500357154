import { getSubstatus } from "./strategies/get-substatus"
import { shouldSaveSubstatus } from "./strategies/should-save-substatus"

export function onSubmition(deps) {
  const {
    person,
    peopleRegister,
    locale: { id: localeId }
  } = deps

  return function (data, callback) {
    const parsedData = parseData({ data, person })
    person.draft = true

    const updatePerson = person.save(parsedData, updateLocalPerson({ callback, person, localeId }))

    peopleRegister.savePerson({ ...person, ...parsedData })

    return updatePerson
  }
}

const updateLocalPerson = ({ callback, person, localeId }) => {
  return function (newPerson, result) {
    if (shouldSavePersonSubstatus({ localeId, hasError: Boolean(result?.error) })) {
      person.saveStatus({ substatus: getSubstatus(localeId) })
    }
    callback()
    return newPerson

    function shouldSavePersonSubstatus({ localeId, hasError }) {
      return shouldSaveSubstatus(localeId) && !hasError
    }
  }
}


export function parseData({ data, person }) {
  const { introducerSellerId, introducerSellerName } = data

  const additionalInformation = {
    ...person.additionalInformation
  }
  if (introducerSellerId && typeof introducerSellerId === 'string') {
    additionalInformation.introducerSellerId = introducerSellerId
    additionalInformation.introducerSellerName = introducerSellerName
  } else {
    additionalInformation.introducerSellerId = null
    additionalInformation.introducerSellerName = null
  }

  return { additionalInformation }
}
