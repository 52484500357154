import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'
import { messages } from '../Messages'
import TagManager from 'react-gtm-module'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'commune',
    label: intl.formatMessage(messages['commune']),
    placeholder: intl.formatMessage(messages['commune_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['commune_no_options_message']),
    type: connect(mapStateToProps)(Select),
    onChange: onChangeCommune(intl),
    required: true,
    showAsterisk: false,
    disabledBy: ['address[].region']
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['commune']),
      value: label
    }
  })
}

const onChangeCommune = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

export function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.complement`, false)
  setFieldTouched(`${parent}.references`, false)
}

const calleds = {}

export function mapStateToProps({ addresses }, ownProps) {
  const { addressesPeopleManagement, loading: { communes: communesloading } } = addresses
  const { communes, getLevels } = addressesPeopleManagement
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { region } = address[index]

  const loading = communesloading && communesloading[region]
  const options = communes[region] || []

  const shouldGetCities = !options.length && region && !loading

  if (shouldGetCities && !calleds[region]) {
    calleds[region] = true

    getLevels({
      geoStructureType: 'communes',
      queryParams: `level=2&parentLevel=1&parentCode=${region}`,
      geoStructureCode: region
    })
  }

  return {
    ...ownProps,
    options,
    loading,
    onChange: function (value, label) {
      onChange(value, label)
    }
  }
}
