import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './WrappedStepper.styl'
import StepperBase from 'src/base/Stepper'

class WrappedStepper extends PureComponent {
  render() {
    const { step: { name, currentStep, steps } } = this.props
    const key = `${name}__stepper`

    return (
      <div className={'wrapped_stepper'}>
        <StepperBase
          className={key}
          key={key}
          currentStep={currentStep}
          steps={steps}
        />
      </div>
    )
  }
}

WrappedStepper.propTypes = {
  className: PropTypes.string,
  step: PropTypes.object.isRequired
}

export default WrappedStepper
