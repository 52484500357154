import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { default as LoaderBase } from 'src/base/Loader'

class Loader extends PureComponent {
  render() {
    const { loader } = this.props

    if (!loader) return null

    return <LoaderBase className="register__loader" />
  }
}

Loader.propTypes = {
  className: PropTypes.string,
  loader: PropTypes.bool.isRequired
}

export default Loader
