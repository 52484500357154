import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from '@material-ui/core/styles'
import bemClassName from 'bem-classname'
import './Tooltip.styl'

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      color: '#fff',
      '&:after': {
        borderWidth: '0 6px 6px 6px',
        borderColor: `transparent transparent #fff transparent`,
        marginTop: '-6px'
      },
      '&:before': {
        borderWidth: '0 7px 7px 7px',
        borderColor: `transparent transparent ${color} transparent`,
        marginTop: '-1px'
      }
    }
  }
}

const styles = () => ({
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    },
    '&::after': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  htmlPopper: arrowGenerator('#dadde9')
})

const CustomTooltip = ({ subtitle, classes, children }) => {
  const [isOpen, setOpen] = useState(false)
  const [arrowRef, setArrowRef] = useState(null)
  const bem = bemClassName.bind(null, 'defaultTooltip')

  return (
    <Tooltip
      classes={{
        popper: classes.htmlPopper,
        tooltip: bem('tooltip')
      }}
      onClose={() => setOpen(false)}
      open={isOpen}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      title={
        <React.Fragment>
          <div className={bem('subtitle')}>{subtitle}</div>
          <span className={classes.arrow} ref={(node) => setArrowRef(node)} />
        </React.Fragment>
      }
    >
      <span onMouseOver={() => setOpen(true)} onClick={() => setOpen(true)}>
        {children}
      </span>
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
  subtitle: PropTypes.object
}

export default withStyles(styles)(CustomTooltip)
