import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { LinearProgress as LinearProgressDS } from '@naturacosmeticos/natds-web'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isDesignSystemToggleActive } from '../../config'

import './LinearProgressBar.styl'

const LinearProgressBar = ({ className, completed, variant }) =>
  isDesignSystemToggleActive() ? (
    <LinearProgressDS value={completed} variant={variant} />
  ) : (
    <LinearProgress
      className={classNames('linear-progress-bar', className)}
      value={completed}
      variant={variant}
    />
  )

export default LinearProgressBar

LinearProgressBar.defaultProps = {
  className: '',
  completed: 0,
  variant: 'determinate'
}

LinearProgressBar.propTypes = {
  className: PropTypes.string,
  completed: PropTypes.number.isRequired,
  variant: PropTypes.string.isRequired
}
