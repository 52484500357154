export class RegistrationTeamGateway {

  constructor({apiContext, defaultBFFApiContext}) {
    this.apiContext = apiContext
    this.defaultBFFApiContext = defaultBFFApiContext
  }

  isRegistrationTeamMember ({ accessToken, sellerId }) {
    const url = this.defaultBFFApiContext.urls.REPORTS_REGISTRATION_TEAM
    return this.defaultBFFApiContext.client.get(
      url, {
        headers: { Authorization: accessToken },
        params: { user: sellerId }
      }
    )
  }

  getPersonBySellerId ({ accessToken, sellerId }) {
    const url = this.apiContext.urls.ROLES()
    const params = {
      _offset: 0,
      _limit: 20,
      candidateId: sellerId,
      relations: 'approvedCandidate,roles'
    }
    return this.apiContext.client.get(
      url, {
        headers: {Authorization: accessToken},
        params
      }
    )
  }

}
