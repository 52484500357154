import { buildFlows as buildDefaultFlows } from './buildFlows.default'
import { buildFlows as buildChileFlows } from './buildFlows.chl'
import { buildFlows as buildColombiaFlows } from './buildFlows.col'
import { buildFlows as buildMalaysiaFlows } from './buildFlows.mys'
import { buildFlows as buildPeruFlows } from './buildFlows.per'
import { buildFlows as buildArgentinaFlows } from './buildFlows.arg'
import { buildFlowsAvon as buildAvonMexicoFlows } from './buildFlows.avon.mex'
import { buildFlowsNaturaMexico } from './buildFlows.natura.mex'
import { buildFlowsAvonBrazil as buildAvonBrazilFlows } from './buildFlows.avon.bra'
import { buildFlows as buildFlowsNaturaEcuador } from './buildFlows.natura.ecu'
import { TENANT_ID } from '../../../../../../../models/tenant/tenant-id'

const strategies = {
  [TENANT_ID.NATURA_CHILE]: buildChileFlows,
  [TENANT_ID.NATURA_COLOMBIA]: buildColombiaFlows,
  [TENANT_ID.NATURA_ARGENTINA]: buildArgentinaFlows,
  [TENANT_ID.NATURA_MALAYSIA]: buildMalaysiaFlows,
  [TENANT_ID.NATURA_PERU]: buildPeruFlows,
  [TENANT_ID.AVON_MEXICO]: buildAvonMexicoFlows,
  [TENANT_ID.AVON_BRAZIL]: buildAvonBrazilFlows,
  [TENANT_ID.NATURA_MEXICO]: buildFlowsNaturaMexico,
  [TENANT_ID.NATURA_ECUADOR]: buildFlowsNaturaEcuador,
  __default__: buildDefaultFlows
}

export const buildFlows = (tenantId) => {
  const strategy = strategies[tenantId]
    ? strategies[tenantId]
    : strategies.__default__
  return strategy()
}
