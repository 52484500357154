import { getMalaysiaFieldsSchema } from './getFieldsSchema.mys'
import { getPeruFieldsSchema } from './getFieldsSchema.per'
import { getDefaultFieldsSchema } from './getFieldsSchema.default'
import { getChileFieldsSchema } from './getFieldsSchema.chl'
import { getColombiaFieldsSchema } from './getFieldsSchema.col'
import { getArgentinaFieldsSchema } from './getFieldsSchema.arg'

const strategies = {
  MYS: getMalaysiaFieldsSchema,
  PER: getPeruFieldsSchema,
  CHL: getChileFieldsSchema,
  COL: getColombiaFieldsSchema,
  ARG: getArgentinaFieldsSchema, //TODO Remove after migrating to MFE
  __default__: getDefaultFieldsSchema
}

export const getFieldsSchema = (localeId, { intl, dependencies }) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy({ intl, dependencies })
}
