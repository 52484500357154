import { SUBSTATUS } from 'src/models/Substatus/Substatus'

const strategies = {
  MYS: SUBSTATUS.PENDING_DIGITAL_SPACE,
  __default__: SUBSTATUS.ATTACHMENT_MISSING
}

export const getSubstatus = (localeId) => {
  return strategies[localeId] ? strategies[localeId] : strategies.__default__
}
