import Locale from 'src/viewModels/Locale'

const CHANGE_LOCALE = 'natura-caduni/Locale/CHANGE_LOCALE'
const REHYDRATE = 'persist/REHYDRATE'

import { LOCALES, DEFAULT_LOCALE } from 'src/configs/Locales'

const dependencies = {
  LOCALES,
  DEFAULT_LOCALE
}

const initialState = {
  locale: new Locale(userLocale(), dependencies)
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  if ((type === REHYDRATE) && payload) {
    return {
      ...state,
      locale: new Locale(payload.locale.locale, dependencies)
    }
  }

  if ((type === CHANGE_LOCALE) && payload) {
    return {
      ...state,
      locale: new Locale(payload.locale, dependencies)
    }
  }

  return state
}

export function ChangeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    payload: {
      locale
    }
  }
}

function userLocale() {
  const { language, languages, userLanguage } = navigator

  return (languages && languages[0]) || language || userLanguage
}
