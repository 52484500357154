import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  label: {
    defaultMessage: 'The intention accepts and authorizes ##the query and report to risk centers## that Natura Cosméticos makes',
    id: 'form.field.policy.authorization.label'
  },
  modal_title: {
    defaultMessage: 'Authorization to consult the credit status',
    id: 'form.field.policy.authorization.modal.title'
  },
  modal_button: {
    defaultMessage: 'Accept',
    id: 'form.field.policy.authorization.modal.button'
  }
})

const dependencies = {
  messages,
  authorizationMessages: []
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { authorizationMessages } = { ...dependencies, ...injection }

  return {
    id: 'authorization',
    type: 'checkboxModal',
    label: intl.formatMessage(messages.label),
    customProps: {
      linkIndexes: [1],
      modal: [{
        title: intl.formatMessage(messages.modal_title),
        message: authorizationMessages,
        acceptLabel: intl.formatMessage(messages.modal_button)
      }]
    }
  }
}
