import { DOCUMENTS } from 'src/models/Document'

export const createDocumentTypeOptions = ({ intl, messages, candidateId }) => {
  return [
    {
      value: DOCUMENTS.NATIONAL_REGISTRY,
      label: intl.formatMessage(messages['type_dni']),
    },
    {
      value: DOCUMENTS.FOREIGN_REGISTRY,
      label: intl.formatMessage(messages['type_ce'])
    },
    {
      value: DOCUMENTS.PASSPORT_PERU,
      label: intl.formatMessage(messages['type_passport'])
    },
    {
      value: candidateId,
      label: intl.formatMessage(messages['type_candidate']),
    }
  ]
}
