import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

import { Messages } from './Messages'

const HIDDEN_BY = ['nationalityId']

export function buildFormSchema(dependencies) {
  const { intl } = dependencies

  const TITLE_COMPLEMENTARY = TYPES.GROUP(intl.formatMessage(Messages['page_complementary']))
  const NATIONALITY = TYPES.NATIONALITY(intl, dependencies)
  const DOCUMENT_RG = commonParams(TYPES.DOCUMENT_RG(intl, dependencies))
  const REGION_RG = commonParams(TYPES.REGION(intl, dependencies))
  const NAME_MOTHER = TYPES.NAME_MOTHER(intl, dependencies)

  const ADDRESS = TYPES.ADDRESS.BRA(intl, dependencies)

  const fieldsSchema = [
    [TITLE_COMPLEMENTARY],
    [NATIONALITY],
    [DOCUMENT_RG, REGION_RG],
    [NAME_MOTHER],
    [ADDRESS]
  ]

  return new FormSchema({ fieldsSchema, name: 'Address' })
}

function commonParams(field, hiddenBy = HIDDEN_BY) {
  return {
    ...field,
    hiddenBy
  }
}
