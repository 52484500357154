const SOURCE_SYSTEM = 7
const COUNTRY = 'CL'
const COUNTRY_ID = 25
const COMPANY_ID = 1
const LANGUAGE = 'es-CL'
const FLOW_TYPE = 'CT'
const URL_GLOBAL_PEOPLE_MANAGEMENT = process.env.GLOBAL_PEOPLE_BASE_URI
const URL_PEOPLE_MANAGEMENT = '/businessrelationship/peoplemanagement/v1'
const URL_PEOPLE = `${URL_PEOPLE_MANAGEMENT}/people`
const URL_GLOBAL_PEOPLE = `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people`
const TENANT_ID = '51326ed9-1db3-4af0-bf3e-40190a68e98f'
const APPLICATION_JSON = 'application/json'

export const CHL_CLIENT = {
  baseURL: process.env.BASE_URI,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
    client_id: process.env.CLIENT_ID,
    country: COUNTRY,
    countryId: COUNTRY_ID,
    language: LANGUAGE,
    sourceSystem: SOURCE_SYSTEM,
    flowType: FLOW_TYPE,
    tenantId: TENANT_ID,
    companyId: COMPANY_ID
  }
}

export const getChileApiHeaders = () => {
  return {
    client: CHL_CLIENT,
    urlPeople: URL_PEOPLE,
    urlGlobalPeople: URL_GLOBAL_PEOPLE,
    urlGlobalPeopleManagement: URL_GLOBAL_PEOPLE_MANAGEMENT
  }
}


