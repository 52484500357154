import axios from 'axios'

export const getDigitalStoreStatus = async (params = {}) => {
  const { countryId, lang, personIds, companyId } = params
  const fetchDigitalStoreStatusUrl = `${process.env.REGISTERS_URL}/digital-store/fetch-statuses`
  try {
    const response = await axios.get(fetchDigitalStoreStatusUrl, {
      headers: {
        countryId,
        lang,
        companyId,
        'x-api-key': process.env.REGISTERS_X_API_KEY
      },
      params: {
        personIds
      }
    })

    return response?.data
  }
  catch (e) {
    return {}
  }
}
