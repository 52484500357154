import * as yup from 'yup'
import { defineMessages } from 'react-intl'

import PhoneConfig, { getPhoneValidation } from 'src/configs/Fields/Phones/Phone.number'
import PhoneTypeConfig from 'src/configs/Fields/Phones/Phone.type'
import ButtonAddConfig from 'src/configs/Fields/Buttons/Button.add'
import ButtonRemoveConfig from 'src/configs/Fields/Buttons/Button.remove'


const messages = defineMessages({
  button_add: {
    defaultMessage: 'add phone',
    id: 'form.field.button.add.phone'
  },
  button_remove: {
    defaultMessage: 'remove phone',
    id: 'form.field.button.remove'
  }
})

function getMostUpdatePhone(phones) {
  const phone = phones.find(phone => {
    phone.updatedAt === undefined
  })

  if (phone) return phone

  return phones.sort((current, next) => {
    const currentDate = current.updatedAt && new Date(current.updatedAt)
    const nextDate = next.updatedAt && new Date(next.updatedAt)

    if (currentDate > nextDate) {
      return -1
    }

    if (currentDate < nextDate) {
      return 1
    }
    return 0
  })[0]
}

export default function Builder(intl, injection) {

  const {
    locale:
      {
        configs: {
          localization: { phone }
        }
      },
    person: { phones },
    user: {
      canAddMultiplePhones
    }
  } = injection

  const phoneValidation = getPhoneValidation({ ...phone, intl })

  const PHONE = PhoneConfig(intl, injection)
  const PHONE_TYPE = PhoneTypeConfig(intl, injection)
  const BUTTON_ADD = ButtonAddConfig(intl, { ...injection, messages })
  const BUTTON_REMOVE = ButtonRemoveConfig(intl, { ...injection, messages })

  const baseValue = {
    type: PHONE_TYPE.options[0].value,
    countryCode: phone.countryCode,
    phone: ''
  }

  if(!phones.length) {
    phones.push(baseValue)
  }

  let filteredPhones = []

  if(!canAddMultiplePhones) {
    filteredPhones.push(getMostUpdatePhone(phones))
  } else {
    filteredPhones = phones
  }

  return {
    id: 'phones',
    min: 1,
    max: 2,
    addButton: canAddMultiplePhones && BUTTON_ADD,
    removeButton: BUTTON_REMOVE,
    baseValue,
    defaultValue: filteredPhones.map(mapPhones, { phone }),
    fields: [
      [PHONE_TYPE, PHONE]
    ],
    validations: yup.array().of(yup.object().shape({
      phone: phoneValidation,
      type: yup.number().required()
    }))
  }
}

function mapPhones({ phone, type, sequence }) {
  if (this.phone.prefixPhoneWithCountryCode && !!phone) {
    return {
      type,
      phone: `${this.phone.countryCode}${phone}`,
      sequence,
      countryCode: this.phone.countryCode,
    }
  }

  return {
    type,
    phone,
    sequence,
    countryCode: this.phone.countryCode,
  }
}
