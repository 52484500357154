import React from 'react'
import PropTypes from 'prop-types'
import { withGetUserMedia } from 'react-get-user-media'

import DeniedAccess from '../DeniedAccess'
import AttachmentOptions from './AttachmentOptions'
import CameraPreview from './CameraPreview'
import PhotoPreview from './PhotoPreview'

import './TakePhoto.styl'

const CAMERA_OPENED = Symbol('CAMERA_OPENED')
const PHOTO_TAKEN = Symbol('PHOTO_TAKEN')

const STREAM_ELEMENT_ID = 'PHOTO_STREAM'

export class TakePhotoState extends React.Component {
  constructor(props) {
    super(props)

    this.state = { step: null }

    this.takeFromCamera = this.takeFromCamera.bind(this)
    this.takePhoto = this.takePhoto.bind(this)
    this.setAttachment = this.setAttachment.bind(this)
  }

  takeFromCamera() {
    // TODO use as then/catch asking browser permissions, needs to change react-get-user-media
    // TODO expose a function to bind stream into video element by REF instead automatic bind by ID.
    this.setState({ step: CAMERA_OPENED }, this.props.getUserMedia)
  }

  takePhoto() {
    // TODO: make takePhoto and stopStream a promise or allow to receive a callback
    this.props.takePhoto()
    setTimeout(this.props.stopStream, 100)
    setTimeout(() => this.setState({ step: PHOTO_TAKEN }), 150)
  }

  setAttachment() {
    // TODO receive a function as prop to set photoBlob as file to upload.
    this.props.setAttachment(this.props.photoBlob)
  }

  render() {
    const { step } = this.state
    const { asked, permitted, closeParentModal } = this.props

    if (asked && !permitted) return <DeniedAccess closeParentModal={closeParentModal} />

    if (step === CAMERA_OPENED) return <CameraPreview takePhoto={this.takePhoto} streamElementId={STREAM_ELEMENT_ID} />

    if (step === PHOTO_TAKEN) {
      return <PhotoPreview photo={this.props.photo} accept={this.setAttachment} discard={this.takeFromCamera} />
    }

    return (
      <AttachmentOptions takeFromCamera={this.takeFromCamera} uploadFile={this.props.uploadFile} />
    )
  }
}

TakePhotoState.propTypes = {
  asked: PropTypes.bool,
  closeParentModal: PropTypes.func.isRequired,
  getUserMedia: PropTypes.func,
  permitted: PropTypes.bool,
  photo: PropTypes.string,
  photoBlob: PropTypes.instanceOf(Blob),
  setAttachment: PropTypes.func,
  stopStream: PropTypes.func,
  takeFromCamera: PropTypes.func,
  takePhoto: PropTypes.func,
  uploadFile: PropTypes.func
}

export default withGetUserMedia({ streamElementId: STREAM_ELEMENT_ID })(TakePhotoState)
