import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  digital_space_required: {
    "id": "register.digital.required",
    "defaultMessage": "Required field"
  }
})

const dependencies = {
  messages
}


export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const digitalSpaceRequired = intl.formatMessage(messages['digital_space_required'])

  //const defaultValue = {
  //  digitalName: '',
  //  confirmed: false,
  //}

  return {
    id: 'digitalSpace',
    type: 'digitalSpace',
    //defaultValue,
    validations: yup.object().shape({
      digitalName: yup.string().required().nullable(),
      confirmed: yup.boolean().oneOf([true], digitalSpaceRequired).required()
    }).required()
  }
}
