import RegisterSchema from 'src/viewModels/RegisterSchema'

import { buildSteps as buildFormSteps } from './strategies/buildSteps'
import { buildFlows } from './strategies/buildFlows'

export function buildSteps({ basePath, tenantId }) {
  const steps = buildFormSteps(tenantId, { basePath })

  return steps
}

export function SelfRegisterSchemaBuilder(deps) {
  const {
    locale: { tenantId },
  } = deps

  const flows = buildFlows(tenantId)
  const context = { tenantId, ...(deps.context && deps.context) }

  const list = buildSteps(context).map(buildStepProps, deps)
  const dependencies = { ...deps, steps: list, flows }
  const steps = list.map(buildStepProps, dependencies)

  return new RegisterSchema(steps, { flows })
}

function buildStepProps(step) {
  const { componentsPropsBuilder } = step

  const { step: stepProps, ...rest } = componentsPropsBuilder(this)
  const componentsProps = { ...rest }

  Object.assign(step, {
    ...stepProps,
    componentsProps
  })

  return step
}
