export const ROLES = {
  GDN: {
    roleId: 6,
    functionId: 17,
    businessModelId: 1
  },
  LEADER: {
    roleId: 6,
    functionId: 4,
    businessModelId: 1
  },
  CN: {
    roleId: 1,
    functionId: 1,
    businessModelId: 1
  },
  GV: {}
}
