export class PersonRolesGateway {

  constructor(apiContext) {
    this.apiContext = apiContext
  }

  getPersonById({ accessToken, personId }) {
    const url = this.apiContext.urls.ROLES(personId)
    const params = {
      relations: 'approvedCandidate,roles'
    }
    return this.apiContext.client.get(url, { headers: { Authorization: accessToken }, params })
  }

}
