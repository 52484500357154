import React from 'react'
import PropTypes from 'prop-types'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'localforage'

import Api from 'src/infra/Api'

import locale from 'src/reducers/Locale'
import language from 'src/reducers/Language'
import user from 'src/reducers/User'
import person from 'src/reducers/Person'
import profile from 'src/reducers/Profile'
import warning from 'src/reducers/Warning'
import title from 'src/reducers/Title'
import people from 'src/reducers/People'
import upload from 'src/reducers/Upload'
import addresses from 'src/reducers/Addresses'
import consultants from 'src/reducers/Consultants'
import contactValidation from 'src/reducers/ContactValidation'
import emailValidation from 'src/reducers/EmailValidation'
import businessModel from 'src/reducers/BusinessModel'
import establishments from 'src/reducers/Establishments'
import ocr from 'src/reducers/Ocr'
import filters from 'src/reducers/Filters'
import personVerify from 'src/reducers/PersonVerify'
import statusValidation from 'src/reducers/StatusValidation'
import peopleRegister from 'src/reducers/PeopleRegister'
import connectivity from 'src/reducers/Connectivity'
import digitalSpace from 'src/reducers/DigitalSpace'
import loader from 'src/reducers/loader'
import flowDirection from 'src/reducers/FlowDirection'

const COMPOSE_ENHANCERS =
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true
    }) : compose

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'locale',
    'language',
    'user',
    'people',
    'peopleRegister',
    'connectivity',
    'step'
  ]
}

export const reducers = (state = {}, action = {}) => {
  return {
    locale: locale(state.locale, action, state),
    language: language(state.language, action, state),
    user: user(state.user, action, state),
    connectivity: connectivity(state.connectivity, action),
    warning: warning(state.warning, action, state),
    title: title(state.title, action, state),
    profile: profile(state.profile, action, state),
    person: person(state.person, action, state),
    peopleRegister: peopleRegister(state.peopleRegister, action, state),
    people: people(state.people, action, state),
    addresses: addresses(state.addresses, action, state),
    consultants: consultants(state.consultants, action),
    upload: upload(state.upload, action, state),
    establishments: establishments(state.establishments, action),
    contactValidation: contactValidation(
      state.contactValidation,
      action,
      state
    ),
    emailValidation: emailValidation(state.emailValidation, action, state),
    businessModel: businessModel(state.businessModel, action, state),
    ocr: ocr(state.ocr, action, state),
    filters: filters(state.filters, action, state),
    statusValidation: statusValidation(state.statusValidation, action, state),
    digitalSpace: digitalSpace(state.digitalSpace, action, state),
    personVerify: personVerify(state.personVerify, action),
    loader: loader(state.loader, action),
    flowDirection: flowDirection(state.flowDirection, action)
  }
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store =
  process.env.NODE_ENV === 'production'
    ? createStore(persistedReducer, applyMiddleware(Api))
    : createStore(persistedReducer, COMPOSE_ENHANCERS(applyMiddleware(Api)))

const persistor = persistStore(store)

export default function AppState({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

AppState.propTypes = {
  children: PropTypes.node
}
