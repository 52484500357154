import React from 'react'
import PropTypes from 'prop-types'
import { withGetUserMedia } from 'react-get-user-media'

import DeniedAccess from '../DeniedAccess'
import AttachmentOptions from './AttachmentOptions'
import RecordTerms from './RecordTerms'
import PreviewRecording from './PreviewRecording'

import './RecordAudio.styl'

const ONE_SECOND = 1000
const MAX_DURATION_IN_SECONDS = 50

const RECORD_MESSAGE = Symbol('RECORD_MESSAGE')
const PREVIEW_RECORDING = Symbol('PREVIEW_RECORDING')

export class RecordAudioState extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: null,
      activeCountdownNumber: 0,
      recordDuration: 0
    }

    this.countdown = this.countdown.bind(this)
    this.increaseRecordDuration = this.increaseRecordDuration.bind(this)
    this.setAttachment = this.setAttachment.bind(this)
    this.startCountdown = this.startCountdown.bind(this)
    this.stepListenRecordedAudio = this.stepListenRecordedAudio.bind(this)
    this.stepRecordAudio = this.stepRecordAudio.bind(this)
  }

  stepRecordAudio() {
    // TODO use as then/catch asking browser permissions, needs to change react-get-user-media lib
    this.setState({ step: RECORD_MESSAGE }, this.props.getUserMedia)
  }

  stepListenRecordedAudio() {
    // TODO use as then/catch asking browser permissions, needs to change react-get-user-media lib
    clearInterval(this.recordDurationInterval)

    this.setState({ step: PREVIEW_RECORDING }, () => {
      this.props.stopRecording()
      this.props.stopStream()
    })
  }

  startCountdown () {
    if (!this.props.permitted) return
    clearInterval(this.countdownInterval)
    this.setState({ activeCountdownNumber: 3 }, () => this.countdownInterval = setInterval(this.countdown, ONE_SECOND))
  }

  countdown () {
    const { activeCountdownNumber } = this.state
    if (activeCountdownNumber <= 1) return this.startRecordingDuration()
    this.setState({ activeCountdownNumber: activeCountdownNumber - 1 })
  }

  startRecordingDuration () {
    clearInterval(this.countdownInterval)

    this.setState(
      { activeCountdownNumber: 0, recordDuration: 0, recording: true },
      () => {
        this.props.startRecording()
        this.recordDurationInterval = setInterval(this.increaseRecordDuration, ONE_SECOND)
      }
    )
  }

  increaseRecordDuration () {
    const { recordDuration } = this.state

    if (recordDuration >= MAX_DURATION_IN_SECONDS) {
      return this.stepListenRecordedAudio()
    }

    this.setState({ recordDuration: recordDuration + 1 })
  }

  setAttachment() {
    // TODO receive a function as prop to set photoBlob as file to upload.
    this.props.setAttachment(this.props.mediaBlob)
  }

  render() {
    const {
      state: { step },
      props: { asked, permitted, person, closeParentModal, personalDocument }
    } = this

    if (asked && !permitted) return <DeniedAccess closeParentModal={closeParentModal} />

    if (step === RECORD_MESSAGE) {
      const stopRecording = this.state.recordDuration < 3
        ? false
        : this.stepListenRecordedAudio

      const recordTermsProps = {
        activeCountdownNumber: this.state.activeCountdownNumber,
        maxDurationInSeconds: MAX_DURATION_IN_SECONDS,
        recordAudio: this.startCountdown,
        recordDuration: this.state.recordDuration,
        recording: this.props.recording,
        stopRecording: stopRecording,
        personalDocument,
        person,
        permitted
      }

      return <RecordTerms {...recordTermsProps} />
    }

    if (step === PREVIEW_RECORDING) {
      return (
        <PreviewRecording
          accept={this.setAttachment}
          discard={this.stepRecordAudio}
          mediaObjectURL={this.props.mediaObjectURL}
          recordDuration={this.state.recordDuration} />
      )
    }

    return <AttachmentOptions recordAudio={this.stepRecordAudio} uploadFile={this.props.uploadFile} />
  }
}

RecordAudioState.propTypes = {
  asked: PropTypes.bool,
  closeParentModal: PropTypes.func.isRequired,
  getUserMedia: PropTypes.func,
  mediaBlob: PropTypes.instanceOf(Blob),
  mediaObjectURL: PropTypes.string,
  permitted: PropTypes.bool,
  person: PropTypes.object,
  recording: PropTypes.bool,
  setAttachment: PropTypes.func,
  startRecording: PropTypes.func,
  stopRecording: PropTypes.func,
  stopStream: PropTypes.func,
  uploadFile: PropTypes.func,
  personalDocument: PropTypes.object
}

export default withGetUserMedia({ constraints: { audio: true } })(RecordAudioState)
