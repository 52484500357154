export class RegistrationTeamService {
  static errors = {
    missingParameters: {
      message: 'Missing required parameters'
    },
    unauthorizedRole: {
      message: 'Unauthorized role'
    },
  }

  constructor({ gateway, roles, allowConsultantUserLoginToSeeDigitalStoreStatus = false } = {}) {
    this.gateway = gateway
    this.roles = roles
    this.allowConsultantUserLoginToSeeDigitalStoreStatus = allowConsultantUserLoginToSeeDigitalStoreStatus
  }

  async execute({ accessToken, sellerId }) {
    if (!accessToken || !sellerId) {
      return { error: RegistrationTeamService.errors.missingParameters }
    }
    let isRegistrationTeam

    try {
      const isRegistrationTeamResponse = await this.gateway.isRegistrationTeamMember({ accessToken, sellerId })
      isRegistrationTeam = isRegistrationTeamResponse.data.isRegistrationTeam
    } catch(error) {
      return { error }
    }

    if (isRegistrationTeam) {
      return {
        name: 'CSC',
        roles: { CSC: true },
        sellerId
      }
    }

    return this.retrieveUserData({ accessToken, sellerId })
  }

  async retrieveUserData ({ accessToken, sellerId }) {
    let getPersonData

    try {
      const getPersonByIdResponse = await this.gateway.getPersonBySellerId({accessToken, sellerId})
      getPersonData = getPersonByIdResponse.data
    }
    catch(error) {
      return { error: RegistrationTeamService.errors.unauthorizedRole }
    }

    const [{ name, roles: userRoles }] = getPersonData

    const user = this.generateManagerUserData(userRoles)

    if (user.isValid) {
      return {
        name,
        sellerId,
        roles: user.roles
      }
    }

    return { error: RegistrationTeamService.errors.unauthorizedRole }
  }

  generateManagerUserData (userRoles) {
    if (userRoles.some(findRole, this.roles.GDN)) {
      return {
        isValid: true,
        roles: { GDN: true }
      }
    }

    if (userRoles.some(findRole, this.roles.GV)) {
      return {
        isValid: true,
        roles: { GV: true }
      }
    }

    if (userRoles.some(findRole, this.roles.LEADER)) {
      return {
        isValid: true,
        roles: { LEADER: true }
      }
    }

    if (this.allowConsultantUserLoginToSeeDigitalStoreStatus && userRoles.some(findRole, this.roles.CN)) {
      return {
        isValid: true,
        roles: { CN: true }
      }
    }

    return { isValid: false }
  }

}

function findRole ({ functionId, roleId }) {
  return functionId === this.functionId && roleId === this.roleId
}
