import * as yup from 'yup'
import { Entity, Collection } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

class Establishment extends Entity {
  static SCHEMA = {
    id: adapter(yup.number().nullable()),
    description: adapter(yup.string().nullable()),
  }

  get label() {
    return this.description
  }

  get value() {
    return this.id
  }
}

export default class Establishments extends Collection {
  static TYPE = Establishment

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }
}
