import { connect } from 'react-redux'
import File from './File'

function mapStateToProps(state, ownProps) {
  const {
    connectivity
  } = state

  return {
    ...ownProps,
    connectivity,
  }
}

export default connect(mapStateToProps)(File)
