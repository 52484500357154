import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import XHRBackend from 'i18next-xhr-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

// TODO: Get LANGUAGES from Locale and make fallbackLng more simple
const LANGUAGES = [
  'en-US',
  'pt-BR',
  'es-AR',
  'es-CO',
  'es-PE',
  'en-MY',
  'es-EC',
]

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    partialBundledLanguages: true,
    cleanCode: true,
    whitelist: LANGUAGES,
    fallbackLng: {
      'es-MX': [LANGUAGES[2]],
      'es-CL': [LANGUAGES[3]],
    },
    interpolation: {
      escapeValue: false
    },
    backend: {
      backends: [
        LocalStorageBackend,
        XHRBackend
      ],
      backendOptions: [{}, {
        loadPath: '/{{lng}}.json'
      }]
    }
  })

export default i18n
