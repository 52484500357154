import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_GET_LOCAL = 'natura-caduni/Person/PROFILE_GET_LOCAL'
export const types = [PROFILE_GET_LOCAL]

export function getProfileLocal({ personId }) {
  const { dispatch } = getContext()

  return dispatch({
    type: PROFILE_GET_LOCAL,
    payload: {
      person: { personId }
    }
  })
}
