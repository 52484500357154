import React from 'react'
import PropTypes from 'prop-types'

import { create } from 'jss'
import JssProvider from 'react-jss/lib/JssProvider'
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import {
  Provider as ThemeProviderDS
} from '@naturacosmeticos/natds-web'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as FVThemeProvider } from '@naturacosmeticos/natds-react'
import { useFVTheme } from './hooks/useFVTheme'
import GlobalStyles from './styles/ds-styles/global-styles'
import { getTheme } from './utils/themes'

import { isDesignSystemToggleActive } from './config'

import 'src/styles/fonts.styl'
import 'src/styles/base.styl'
import { enableThemeElo } from './utils'
import { COMPANY_ID } from './models/Company'

const styleNode = document.createComment('insertion-point-jss')
document.head.insertBefore(styleNode, document.head.firstChild)

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())
jss.options.insertionPoint = 'insertion-point-jss'
export default function Theme({ children, companyId }) {
  const currentTheme = getTheme(companyId)
  let fvTheme = null
  if(isDesignSystemToggleActive()){
    fvTheme = useFVTheme(companyId)
  }
  const themeObject = enableThemeElo() || companyId === COMPANY_ID.AVON ? {
    fontFamily: 'Roboto, sansserif',
    palette: {
      primary: {
        main: currentTheme.color.primary,
        contrastText: currentTheme.color.onPrimary
      },
      secondary: {
        main: currentTheme.color.secondary
      }
    }
  } : {
    fontFamily: 'Roboto, sansserif',
    palette: {
      primary: {
        main: '#f3971f'
      },
      secondary: {
        main: '#f04f23'
      }
    }
  }
  const theme = createMuiTheme(themeObject)
  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      {isDesignSystemToggleActive() ? (
        <ThemeProviderDS theme={currentTheme}>
          <FVThemeProvider theme={fvTheme}>
            <StyledThemeProvider theme={currentTheme}>
              <GlobalStyles />
              {children}
            </StyledThemeProvider>
          </FVThemeProvider>
        </ThemeProviderDS>
      ) : (
        <FVThemeProvider theme={currentTheme}>
          <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </FVThemeProvider>
      )}
    </JssProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.node,
  companyId: PropTypes.string,
}