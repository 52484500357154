import React from 'react'
import PropTypes from 'prop-types'

const ApprovedIcon = ({ size, color }) => {
  const styles = {
    st0: {
      fill: 'none',
      stroke: color,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '4.5px',
    },
  }

  return (
    <svg width={size} height={size} viewBox="0 0 255 255">
      <path
        style={styles.st0}
        d="M85.77,173,210.59,48.19a24.63,24.63,0,0,1,34.74,0h0a24.63,24.63,0,0,1,0,34.74L121.46,206.81a24.64,24.64,0,0,1-34.75,0L69.34,189.44"
      />
      <path style={styles.st0} d="M52,172.07h0a24.57,24.57,0,0,0,34.74,0l9.19-9.19" />
      <path
        style={styles.st0}
        d="M121.46,206.81h0a24.64,24.64,0,0,1-34.75,0l-77-77A24.64,24.64,0,0,1,9.67,95h0a24.64,24.64,0,0,1,34.74,0l42.43,42.43"
      />
    </svg>
  )
}


ApprovedIcon.defaultProps = {
  color: '#80ba41',
}

ApprovedIcon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default ApprovedIcon
