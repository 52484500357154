export default class CreditModality {
  constructor(params) {
    Object.assign(this, params)
  }

  get title() {
    const { messages, person: { paymentPlan, status: { isRejected } }, configs, user = {} } = this
    const { isDenied, isPrePaid, isCredit } = paymentPlan
    const { localization: { selectPaymentPlan }} = configs

    if ((isDenied || isRejected) && user.isSelfRegistration) return messages.title_attention

    if (isPrePaid) return messages.title_pre_paid

    if (isCredit) return messages.title_credit

    if (selectPaymentPlan && !user.isSelfRegistration) return messages.title_select_payment

    return messages.title_analysis
  }
}
