import { store } from 'src/App.state'

export function buildDigitalSignaturePropsMicrofrontend(deps) {
  const { locale: { id, tenantId, companyId }, person, loader: { personLoading } } = deps
  const { user: { user } } = store.getState()
  const {
    personId,
    businessModel: { businessModelId, functionId, roleId },
    addresses
  } = person

  return {
    disabledSubmit: true,
    loader: personLoading,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'digitalSignaturePage',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
      },
      authenticatedUser: {
        authenticated: user.authenticated,
      },
      person: { addresses },
    }
  }
}
