import { defineMessages } from 'react-intl'

const messages = defineMessages({
  references: {
    defaultMessage: 'References',
    id: 'form.field.references'
  },
  references_message: {
    defaultMessage: 'E.g. "blue door house", "between streets"...',
    id: 'form.field.references.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'references',
    label: intl.formatMessage(messages['references']),
    type: 'input',
    message: intl.formatMessage(messages['references_message']),
    required: true
  }
}
