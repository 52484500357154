class ColombiaUserRole {

  constructor(roles) {
    this.roles = {}
    if (roles) {
      this.roles = roles
    }
  }

  USER_ROLES = {
    DIRECTOR: 17,
    VIP: 17,
    GM: 8,
    GV: 7,
    GR: 5,
    CNO: 19,
    CNE: 1,
    CN: 2,
    COLABORATOR: 12,
    FIELD_ASSISTANT: 26,
    APPROVED_CANDIDATE: 4
  }

  USER = {
    SELF_REGISTRATION: 'self-registration'
  }

  get canRegister() {
    return !this.isGV
  }

  get shouldRedirectToRegister() {
    return false
  }

  get shouldRedirectToReports() {
    return false
  }
  
  get isCNO() {
    return !!this.roles.CNO
  }

  get isGR() {
    return !!this.roles.GR
  }

  get isGV() {
    return !!this.roles.GV
  }

  get isGDN() {
    return !!this.roles.GDN
  }

  get isLEADER() {
    return !!this.roles.LEADER
  }

  get isCSC() {
    return !!this.roles.CSC
  }

  get isFV() {
    return this.isLEADER || this.isGV || this.isGDN
  }

  get canDoSelfRegistration() {
    return this.isFV || this.isCSC
  }

  get shouldLoadCandidateInformation() {
    return this.isFV || this.isCSC
  }

  get canEditInSelfRegistrationFlow() {
    return this.isFV || this.isCSC
  }

  get user() {
    return this.USER
  }

  get canAddMultiplePhones() {
    return false
  }

  get userRoles() {
    return this.USER_ROLES
  }
}


export default ColombiaUserRole
