import { ProfileSchemaBuilder } from './Profile'
import { RegisterSchemaBuilder } from './Register'
import { SelfRegisterSchemaBuilder } from './SelfRegister'
import {
  MyRegistersSchemaBuilder,
  parsePerson,
  parsePersonOffline,
  touchAfterReload
} from './MyRegisters'

export default {
  MyRegisters: {
    MyRegistersSchemaBuilder,
    parsePerson,
    parsePersonOffline,
    touchAfterReload
  },
  Profile: {
    ProfileSchemaBuilder
  },
  Register: {
    RegisterSchemaBuilder
  },
  SelfRegister: {
    RegisterSchemaBuilder: SelfRegisterSchemaBuilder
  }
}
