import Person from 'src/models/Person'

import { personSaveStatusReducer, savePersonStatus, types as personSaveStatusTypes } from './Person.saveStatus'
import { personAllocateReducer, allocatePerson, types as personAllocateTypes } from './Person.allocate'
import { personRehydrateReducer, types as personRehydrateTypes } from './Person.rehydrate'
import { personCreateReducer, createPerson, types as createPersonTypes } from './Person.create'
import { personGetReducer, getPerson, types as getPersonTypes } from './Person.get'
import { personGetLocalReducer, getPersonLocal, types as getPersonLocalTypes } from './Person.getLocal'
import { personFindReducer, findPerson, types as findPersonTypes } from './Person.find'
import { personResetReducer, resetPerson, types as resetPersonTypes } from './Person.reset'
import { personResetErrorsReducer, resetPersonErrors, types as resetPersonErrorsTypes } from './Person.resetErrors'
import { personSaveReducer, savePersonOnState, savePerson, types as savePersonTypes } from './Person.save'
import { personSaveAttachmentsReducer, savePersonAttachments, types as saveAttachmentsTypes } from './Person.saveAttachments'
import { personSavePaymentPlanReducer, savePersonPaymentPlan, types as savePaymentPlanTypes } from './Person.savePaymentPlan'
import { personCompleteReducer, completePerson, types as completeTypes } from './Person.complete'
import { savePerson as savePersonLocal } from 'src/reducers/PeopleRegister/PeopleRegister.save'
import { personSetErrorReducer, setPersonError, types as setPersonErrorTypes } from './Person.setError'
import { personSetRegistrationStatusReducer, setPersonRegistrationStatus, types as setPersonRegistrationStatusTypes } from './Person.setRegistrationStatus'
import { REGISTER_STATUS } from '../../views/Register/RegisterStatus'

const dependencies = {
  personGetReducer,
  personGetLocalReducer,
  personFindReducer,
  personCreateReducer,
  personSaveReducer,
  personResetReducer,
  personResetErrorsReducer,
  personRehydrateReducer,
  personSaveAttachmentsReducer,
  personSavePaymentPlanReducer,
  personCompleteReducer,
  personAllocateReducer,
  personSaveStatusReducer,
  personSetErrorReducer,
  personSetRegistrationStatusReducer
}

const personDependencies = {
  allocatePerson,
  createPerson,
  completePerson,
  getPerson,
  getPersonLocal,
  findPerson,
  savePerson,
  savePersonLocal,
  savePersonOnState,
  savePersonAttachments,
  savePersonPaymentPlan,
  savePersonStatus,
  resetPerson,
  resetPersonErrors,
  setPersonError,
  setPersonRegistrationStatus
}

const initialState = {
  person: new Person({}, personDependencies),
  loading: false,
  error: false,
  registrationStatus: REGISTER_STATUS.PENDING
}

export default function reducer(state = initialState, action = {}, rootState = {}, injection = {}) {
  const {
    personGetReducer,
    personGetLocalReducer,
    personCreateReducer,
    personSaveReducer,
    personResetReducer,
    personResetErrorsReducer,
    personRehydrateReducer,
    personSaveAttachmentsReducer,
    personSavePaymentPlanReducer,
    personAllocateReducer,
    personSaveStatusReducer,
    personSetErrorReducer,
    personSetRegistrationStatusReducer
  } = { ...injection, ...dependencies }

  const {
    locale: { locale: { configs = {} } = {} } = {},
    peopleRegister: { peopleRegister = {} } = {},
  } = rootState

  const { localization: { policies = [], address = {} } = {} } = configs

  const { type } = action

  Object.assign(personDependencies, { policies, address })

  if([].concat(personRehydrateTypes).includes(type))
    return personRehydrateReducer(state, action, personDependencies)

  if([].concat(createPersonTypes).includes(type))
    return personCreateReducer(state, action, personDependencies, rootState)

  if([].concat(getPersonTypes).includes(type))
    return personGetReducer(state, action, personDependencies)

  if([].concat(getPersonLocalTypes).includes(type))
    return personGetLocalReducer(state, action, personDependencies, peopleRegister)

  if([].concat(findPersonTypes).includes(type))
    return personFindReducer(state, action, personDependencies, rootState)

  if([].concat(savePersonTypes).includes(type))
    return personSaveReducer(state, action, personDependencies, rootState)

  if([].concat(resetPersonTypes).includes(type))
    return personResetReducer(state, action, personDependencies)

  if([].concat(resetPersonErrorsTypes).includes(type))
    return personResetErrorsReducer(state, action, personDependencies)

  if([].concat(saveAttachmentsTypes).includes(type))
    return personSaveAttachmentsReducer(state, action, personDependencies)

  if([].concat(savePaymentPlanTypes).includes(type))
    return personSavePaymentPlanReducer(state, action, personDependencies)

  if([].concat(completeTypes).includes(type))
    return personCompleteReducer(state, action, personDependencies)

  if([].concat(personAllocateTypes).includes(type))
    return personAllocateReducer(state, action, personDependencies)

  if([].concat(personSaveStatusTypes).includes(type))
    return personSaveStatusReducer(state, action, personDependencies)

  if([].concat(setPersonErrorTypes).includes(type))
    return personSetErrorReducer(state, action, personDependencies)

  if([].concat(setPersonRegistrationStatusTypes).includes(type))
    return personSetRegistrationStatusReducer(state, action, personDependencies)

  return state
}
