import { store } from '../../../../../../../../App.state'
import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'

export class AddressParser {
  abstractMethodError = new Error('Abstract method. Extend this class and implement this method')

  getParsedData() {
    throw this.abstractMethodError
  }

  getCumulativeAddress(addresses) {
    if (!addresses.length) {
      return JSON.stringify({ addressUse: [], geographicalLevels: [] })
    }

    const [{ sequence, addressUse, geographicalLevels }] = addresses

    return JSON.stringify({
      addressUse: this.buildAddressUse(addressUse),
      geographicalLevels,
      sequence
    })
  }

  buildAddressUse(addressUse) {
    return addressUse.map((item) => {
      return {
        id: item.id,
        description: item.description
      }
    })
  }

  mapAddress({ address, levels }) {
    const {
      addresses: { addressesPeopleManagement }
    } = store.getState()
    const { addressUse, countryName: country, sequence, ...addressValues } = address

    const types = {
      ...addressValues,
      country
    }

    const addressType = ADDRESS_TYPE.MAILING
    const addressesStructure = this.getAddressStructure({ types, addresses: addressesPeopleManagement })
    const geographicalLevels = this.buildGeographicalLevels({ addressesStructure, country, levels })

    return {
      addressType,
      addressUse,
      geographicalLevels,
      sequence
    }
  }

  getAddressStructure() {
    throw this.abstractMethodError
  }

  buildGeographicalLevels() {
    throw this.abstractMethodError
  }

  getComplementaryAddressFrom() {
    throw this.abstractMethodError
  }

  getGeoStructureValues(addresses, geoStructureCode) {
    return addresses.find(this.findAddress, { geoStructureCode })
  }

  findAddress({ geoStructureCode }) {
    return geoStructureCode === this.geoStructureCode
  }
}

