import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

import Policies from '../Policies'
import messages from './Messages'

export const touchAfterReload = [
  'emails[0].email'
]

export function buildFormSchema(dependencies) {
  const { intl, person, connectivity, user } = dependencies

  const shouldReviewPerson =
    !person.reviewStep.personData &&
    !connectivity.isOffline &&
    person.draft

  const reviewFields = [{
    fields: [
      {
        label: intl.formatMessage(messages.review_registrant_id),
        value: person.additionalInformation.registrantId,
      },
      {
        label: intl.formatMessage(messages.review_introducer_sellerId),
        value: person.additionalInformation.introducerSellerId
      }
    ]
  }]

  const REVIEW = TYPES.REVIEW(intl, { ...dependencies, reviewFields, shouldReviewPerson })
  const DOCUMENTS = commonParams(TYPES.DOCUMENTS(intl, dependencies), true, ['DISABLED_DOCUMENT'])
  const RUC = commonParams(TYPES.RUC(intl, dependencies), false)
  const NAME_FIRST = commonParams(TYPES.NAME_FIRST(intl, dependencies))
  const NAME_LAST = commonParams(TYPES.NAME_LAST(intl, dependencies))
  const NAME_MESSAGE = TYPES.NAME_MESSAGE(intl, dependencies)
  const GENDER = commonParams(TYPES.GENDER(intl, dependencies))
  const BIRTHDAY = commonParams(TYPES.BIRTHDAY(intl, dependencies))
  const NATIONALITY = commonParams(TYPES.NATIONALITY(intl, dependencies))
  const PHONES = commonParams(TYPES.PHONES(intl, dependencies))
  const INDICATION = commonParams(TYPES.INDICATION(intl, { ...dependencies, shouldReviewPerson }), false)
  const REGISTRANT = commonParams(TYPES.REGISTRANT(intl, { ...dependencies, shouldReviewPerson }))
  const POLICIES = commonParams(Policies(intl, dependencies, TYPES.POLICIES), false)
  const EMAIL_FIELD = commonParams(TYPES.EMAIL(intl, dependencies))

  const fieldsSchema = [
    [REVIEW],
    [DOCUMENTS],
    [RUC],
    [NAME_FIRST, NAME_LAST],
    [NAME_MESSAGE],
    [GENDER, BIRTHDAY],
    [NATIONALITY],
    [EMAIL_FIELD],
    [PHONES],
    [INDICATION],
    user.isCSC ? [REGISTRANT] : [],
    [POLICIES]
  ]

  return new FormSchema({ fieldsSchema, name: 'PersonalInfo' })
}

function commonParams(field, required = true, disabledBy = []) {
  return {
    ...field,
    disabledBy,
    required
  }
}
