import { set, unset } from './Form.state.helpers'
import { get } from 'lodash'

import FormGroupSchema from './Schemas/FormGroupSchema'
import FormFieldSchema from './Schemas/FormFieldSchema'

export default class Errors {
  constructor(props) {
    Object.assign(this, { ...props })
  }

  get hasErrors() {
    return Object.keys(this).length > 0
  }

  validateAll({ values, schema }) {
    const props = { values, schema, errors: this }
    const errors = schema.fieldsSchema.reduce(runValidationsForAllFields, props)

    delete errors.values
    delete errors.schema
    delete errors.errors

    return new Errors({ ...errors })
  }

  validate({ id, groupId, values, schema }) {
    if(!id && !groupId) return this

    const newErrors = groupId
      ? this.validateGroup({ schema, values, groupId })
      : this.validateField({ schema, values, id })

    return new Errors({ ...newErrors })
  }

  validateGroup({ schema, values, groupId }) {
    const groupValue = values[groupId]
    const groupSchema = schema.fieldsSchema.reduce(findSchema, { id: groupId })

    if(!(groupSchema instanceof FormGroupSchema)) return this

    return this.runSchemaValidation(groupSchema, groupValue, values)
  }

  validateField({ schema, values, id }) {
    const fieldValue = get(values, id)
    const fieldSchema = schema.fieldsSchema.reduce(findSchema, { id })

    if(!(fieldSchema instanceof FormFieldSchema)) return this

    return this.runSchemaValidation(fieldSchema, fieldValue, values)
  }

  runSchemaValidation({ validate, id }, value, values) {
    const error = validate && validate(value, { values, errors: this })

    return this.setError(id, error)
  }

  setError(id, error) {
    return error ? set(this, id, error) : unset(this, id)
  }
}

function findSchema(accum, schema) {
  const { id } = accum

  if(Array.isArray(schema))
    return schema.reduce(findSchema, accum)

  if(schema.id === id)
    return schema

  return accum
}

function runValidationsForAllFields(accum, fieldSchema) {
  if(Array.isArray(fieldSchema))
    return fieldSchema.reduce(runValidationsForAllFields, accum)

  const { values, schema, errors } = accum
  const { list, id } = fieldSchema
  const groupId = list && id

  const error = errors.validate({ id, groupId, values, schema })

  return { ...accum, ...error }
}
