import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

const WHITE_SPACE = /\s/g

export const PHONE_TYPES = {
  MOBILE: 1,
  LINE: 2
}

export default class Phone extends Entity {
  static SCHEMA = {
    type: adapter(yup.number().required()),
    areaCode: adapter(yup.string().required()),
    countryCode: adapter(yup.string().required()),
    phoneNumber: adapter(yup.string().required()),
    verified: adapter(yup.bool().nullable()),
    sequence: adapter(yup.string().nullable()),
    updatedAt: adapter(yup.string().nullable()),
  }

  get isMobile() {
    return this.type === PHONE_TYPES.MOBILE
  }

  get isLine() {
    return this.type === PHONE_TYPES.LINE
  }

  get phone() {
    const { phoneNumber = '', areaCode = '' } = this

    return `${areaCode} ${phoneNumber}`.trim()
  }
}

export function normalizePhones(phone) {
  const {
    configs: {
      localization: {
        phone: { areaCodeCharCount, countryCode, prefixPhoneWithCountryCode }
      }
    },
    phones = []
  } = this
  const { type, phone: typedPhone } = phone
  const phoneWithAreaCode = removeDashes(removeWhiteSpaces(typedPhone))
  const charCount = defineAreaCodeCharCount(type, areaCodeCharCount)

  let sliceConfigs

  if (prefixPhoneWithCountryCode) {
    sliceConfigs = {
      countryCodeSlice: phoneWithAreaCode.slice(0, countryCode.length),
      areaCode: phoneWithAreaCode.slice(countryCode.length, charCount + countryCode.length),
      phoneNumber: phoneWithAreaCode.slice(charCount + countryCode.length),
      number: phoneWithAreaCode.slice(charCount + countryCode.length)
    }
  } else {
    sliceConfigs = {
      countryCodeSlice: countryCode,
      areaCode: phoneWithAreaCode.slice(0, charCount),
      phoneNumber: phoneWithAreaCode.slice(charCount),
      number: phoneWithAreaCode.slice(charCount)
    }
  }
  
  const { countryCodeSlice, areaCode, phoneNumber, number} = sliceConfigs

  const { sequence } =
    phones.find(findCurrentPhone, { countryCode, areaCode, phoneNumber }) || {}

  return {
    ...phone,
    type,
    areaCode,
    countryCode: countryCodeSlice,
    phoneNumber,
    number,
    sequence
  }
}

function removeDashes(string) {
  return string.replace('-', '')
}

function removeWhiteSpaces(string) {
  return string.replace(WHITE_SPACE, '')
}

function findCurrentPhone(phone) {
  return (
    phone.areaCode === this.areaCode && phone.phoneNumber === this.phoneNumber
  )
}

function defineAreaCodeCharCount(type, { mobile, line }) {
  const isLine = type === PHONE_TYPES.LINE

  return isLine ? line : mobile
}
