import { DOCUMENTS } from 'src/models/Document'
import {ATTACHMENT_TYPES} from '../../../configs/AttachmentTypes'

export default {
  [DOCUMENTS.RUT]: {
    identificationCardFront: ATTACHMENT_TYPES.RUT_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.RUT_BACK
  },
  termAndConditions: ATTACHMENT_TYPES.TERMS_AND_CONDITIONS_ACCEPTANCE,
}
