import { getSectionTitle } from './Messages'
import { buildFormSchema } from './FormSchema'
import { onSubmition } from './Submit'
import { onSubmitionMfe } from './SubmitMfe'
import { isIndicationStatelessWithBypassToggleActive } from 'src/config'

export function buildIndicationProps(deps) {
  const { title, subtitle } = getSectionTitle(deps)
  const {
    person,
    locale: { id, tenantId },
    loader: { personLoading, resendLoading }
  } = deps

  const onSubmit = onSubmition(deps)

  return {
    formSchema: buildFormSchema(deps),
    onSubmit,
    disabledSubmit: false,
    step: {
      title,
      subtitle
    },
    loader: personLoading || resendLoading,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'indication',
      identityInfo: {
        countryId: id,
        tenantId,
        companyId: 1,
        personId: person.personId,
        shouldCallCompleteEndpoint: isIndicationStatelessWithBypassToggleActive(),
        isPossibleToBypass: isIndicationStatelessWithBypassToggleActive(),
      },
      person: {
        introducerSellerInformation: {
          introducerSellerId:
          person.additionalInformation.introducerSellerId,
        }
      },
      onSubmit: isIndicationStatelessWithBypassToggleActive() ? onSubmitionMfe(deps) : onSubmit,
    }
  }
}
