import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Select from 'src/base/Form/Components/Select'
import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'region',
    label: intl.formatMessage(messages['region']),
    placeholder: intl.formatMessage(messages['region_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['region_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    showAsterisk: false
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['region']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

export function validateFieldDependencies({
  setFieldValue,
  setFieldTouched,
  schema
}) {
  const {
    group: { id },
    parent
  } = schema

  setFieldValue(`${parent}.department`, '', id)
  setFieldValue(`${parent}.province`, '', id)
  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.department`, false)
  setFieldTouched(`${parent}.province`, false)
  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

export function mapStateToProps({ addresses, connectivity, locale }, ownProps) {
  const {
    addresses: { regions: regionsCMM, getAddressesCMM },
    loading,
    error,
    addressesPeopleManagement
  } = addresses
  const {
    group: { index },
    form: {
      values: { address }
    },
    onChange
  } = ownProps
  const { countryName } = address[index] || {}

  const { getLevels, regions: regionsBFF } = addressesPeopleManagement
  const { locale: {configs: { localization: { shouldGetAddressFromBff }}}}  = locale

  const regions = shouldGetAddressFromBff ? regionsBFF : regionsCMM
  const options = regions[countryName]
  const shouldGetRegionAndDepartments =
    !options && !loading.regions && !error.addresses && countryName


  if (shouldGetRegionAndDepartments && !connectivity.isOffline) {
    if(shouldGetAddressFromBff) getLevels({ geoStructureType: 'regions', queryParams: 'level=1' })
    else getAddressesCMM()
  }

  return {
    ...ownProps,
    options: options || [],
    loading: loading.regions,
    onChange: (value, label) => {
      getDepartmentsByRegion({ value, getLevels })
      onChange(value, label)
    }
  }
}


function getDepartmentsByRegion({ value, getLevels }) {
  if (!value) return

  return getLevels({
    geoStructureType: 'departments',
    queryParams: `level=2&parentLevel=1&parentCode=${value}`,
    geoStructureCode: value
  })
}
