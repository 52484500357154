import Error from 'src/viewModels/Error'
import Person from 'src/models/Person'
import { getContext } from 'src/infra/Api/Api.context'

export const PERSON_GET_LOADING = 'natura-caduni/Person/PERSON_GET_LOADING'
export const PERSON_GET_SUCCESS = 'natura-caduni/Person/PERSON_GET_SUCCESS'
export const PERSON_GET_ERROR = 'natura-caduni/Person/PERSON_GET_ERROR'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const types = [PERSON_GET_LOADING, PERSON_GET_SUCCESS, PERSON_GET_ERROR]

const relationsFields = [
  'additionalInformation',
  'addresses',
  'approvedCandidate',
  'attachments',
  'contactReferences',
  'documents',
  'emails',
  'paymentPlan',
  'personPoliciesAccepts',
  'roles',
  'telephones'
]

export function personGetReducer(state, action, dependencies) {
  let { loading, error, person } = state
  const { payload, type } = action

  const hasSuccess = type === PERSON_GET_SUCCESS
  const hasError = type === PERSON_GET_ERROR
  const isLoading = type === PERSON_GET_LOADING

  if(hasSuccess) {
    error = false
    loading = false
    const personData = payload.data?.person ? {...payload.data?.person} : {...payload.data}
    person = new Person({ ...person, ...personData}, dependencies)
  }

  if(hasError) {
    loading = false
    error = new Error(action.error)
  }

  return {
    ...state,
    loading: isLoading || loading,
    error,
    person
  }
}

export function getPerson({ personId, businessModel }) {
  const globalAppContext = getContext()

  const {
    dispatch
  } = globalAppContext

  const { clientName, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext, personId, businessModel })

  const relations = `?relations=${relationsFields.join(',')}`

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'GET',
        url: `${requestUrl}${relations}`,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload ({ globalAppContext, personId, businessModel }){
    const {
      PEOPLE_URL,
      GET_PERSON_URL,
      countryId,
      companyId,
      bffHeaders,
      headersAuthenticationInfo,
      shouldGetPersonDetailsFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext, personId })

    const headersData = { companyId, countryId, businessModel, bffHeaders, sellerId }

    const clientName = shouldGetPersonDetailsFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId

    const { requestHeaders } = getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData })

    const requestUrl = shouldGetPersonDetailsFromBff ? GET_PERSON_URL : PEOPLE_URL

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({globalAppContext, personId}){
      const {
        urls: { PEOPLE },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { GET_PERSON, GET_PEOPLE },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetPersonDetailsFromBff = configs?.localization?.shouldGetPersonDetailsFromBff

      return {
        PEOPLE_URL: PEOPLE(personId),
        GET_PERSON_URL: personId ? GET_PERSON({ personId }): GET_PEOPLE(),
        shouldGetPersonDetailsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const { countryId, companyId, businessModel, bffHeaders, sellerId } = headersData

      const { businessModelId, functionId, roleId  } = businessModel

      const requestHeaders = shouldGetPersonDetailsFromBff ? {
        ...bffHeaders,
        Authorization: userAccessToken,
        'x-api-key': registersBffApiKey,
        country: countryId,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        businessModel: businessModelId,
        function: functionId,
        role: roleId,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}




export function getPersonFromCreate(result) {
  if (!result || !result.payload) return null

  const { data: { personId } = {} } = result.payload || {}

  return getPerson({ personId })
}
