import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class ContactReference extends Entity {
  static SCHEMA = {
    firstName: adapter(yup.string().required()),
    lastName: adapter(yup.string().required()),
    typeId: adapter(yup.number().required()),
    areaCode: adapter(yup.number().required()),
    countryCode: adapter(yup.number().required()),
    number: adapter(yup.number().required())
  }

  constructor(props) {
    super(props)

    this.normalizeData(props)
  }

  normalizeData(data) {
    const {
      countryCode,
      type,
      typeId,
      areaCode,
      number
    } = data

    Object.assign(this, {
      areaCode: areaCode,
      countryCode: countryCode,
      type: type || typeId,
      typeId: type || typeId,
      number: number
    })
  }

  get phone() {
    const { number = '', areaCode = '' } = this

    return `${areaCode}${number}`
  }
}
