import { shouldNotCreatePerson } from './PreRegisterProps'

export function onSubmitionMfe(deps) {
  return function(data) {
    const {
      person,
      peopleRegister,
      user: {
        authenticated,
        isCSC
      },
      personVerify,
      connectivity,
      locale: {
        region
      }
    } = deps

    const { saveOnState, draft, personId, reviewStep } = person
    
    if (shouldNotCreatePerson(authenticated, isCSC, region)) {
      const { isOffline } = connectivity
      const params = {
        ...data,
        draft,
        personId,
        reviewStep: { ...reviewStep, step: 'business-model' }
      }

      saveOnState(data)

      return personVerify.find(params, function(result) {
        const { payload: { data } = {} } = result
        const shouldUpdatePersonLocal = !isOffline && draft && !data.length

        if (shouldUpdatePersonLocal)
          peopleRegister.savePerson({ ...params })
      })
    }

    saveOnState(data)
    peopleRegister.savePerson(data)
  }
}
