import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import Upload from 'src/viewModels/Upload'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const URL_LOADING = 'natura-caduni/People/URL_LOADING'
export const URL_SUCCESS = 'natura-caduni/People/URL_SUCCESS'
export const URL_ERROR = 'natura-caduni/People/URL_ERROR'

export const types = [URL_LOADING, URL_SUCCESS, URL_ERROR]

export function createUrlReducer(state, action, dependencies) {
  const { payload, type, meta } = action
  let { loading, error, upload } = state

  const hasSuccess = type === URL_SUCCESS
  const hasError = type === URL_ERROR
  const isLoading = type === URL_LOADING

  if (isLoading) {
    const { file } = payload

    loading = { ...loading, [file]: true }
    error = false
  }

  if(hasSuccess) {
    const { previousAction: { payload: { file } }} = meta
    const attachments = {
      ...upload.attachments,
      [file]: {
        ...upload.attachments[file],
        ...payload.data[0]
      }
    }

    upload = new Upload({ attachments }, dependencies)
    loading = { ...loading, [file]: false }
    error = false
  }

  if(hasError) {
    const { previousAction: { payload: { file } }} = meta

    loading = { ...loading, [file]: false }
    error = new Error(action.error)
  }

  return {
    loading,
    error,
    upload,
  }
}

const getFileExtenson = ({ name } ) => {
  if (!name || !name.length) return null
  const sufix = name.split('.').pop()
  return sufix && sufix.length ? `.${sufix}` : null
}

export function createUrl(personId, { type, value }) {
  const types = [URL_LOADING, URL_SUCCESS, URL_ERROR]
  const keySufix = getFileExtenson(value)
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext })

  return dispatch({
    types,
    payload: {
      client: clientName,
      file: type,
      request: {
        method: 'POST',
        url: requestUrl,
        headers: requestHeaders,
        data: [{ type: type, keySufix }]
      }
    }
  })

  function getRequestPayload({ globalAppContext }) {
    const {
      DOCUMENTS_URL,
      DOCUMENTS_BFF_URL,
      bffHeaders,
      headersAuthenticationInfo,
      shouldDocumentsFromBff,
      countryId,
      tenantId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const { requestHeaders } = getRequestHeaders({ shouldDocumentsFromBff, headersAuthenticationInfo })

    const requestUrl = shouldDocumentsFromBff ? DOCUMENTS_BFF_URL : DOCUMENTS_URL

    const clientName = shouldDocumentsFromBff
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { DOCUMENTS },
        bff_urls: { DOCUMENTS_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          tenantId,
          configs
        },
        user: {
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext

      const shouldDocumentsFromBff = configs?.localization?.isHostedUIEnabled

      return {
        DOCUMENTS_URL: DOCUMENTS(personId),
        DOCUMENTS_BFF_URL: DOCUMENTS_BFF(personId),
        shouldDocumentsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        countryId,
        companyId,
        tenantId,
      }
    }

    function getRequestHeaders({ shouldDocumentsFromBff, headersAuthenticationInfo }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo
      const requestHeaders = shouldDocumentsFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        tenantid: tenantId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
      }

      return { requestHeaders }
    }
  }
}
