import { ADDRESS_TYPE } from 'src/models/Address/AddressType'
import { store } from 'src/App.state'
import { ADDRESS_USE, DELIVERY_ADDRESS, MAILING_ADDRESS } from 'src/models/Address/AddressUse'
import { pickAddressByUse } from 'src/models/Address/AddressPicker'
import { getOtherAddress } from './getOtherAddress'

export const getColombiaParsedData = ({ data, configs, person }) => {
  const {
    localization: {
      address: {
        levels
      },
      countryName
    }
  } = configs

  const { addresses: personAddresses } = person
  const mailing = pickAddressByUse(personAddresses, ADDRESS_USE.MAILLING)

  const mailingSequence = mailing?.sequence
  const delivery = pickAddressByUse(personAddresses, ADDRESS_USE.DELIVERY)
  const deliverySequence = mailingSequence === delivery?.sequence ? undefined : delivery.sequence

  const filledAddresses = data.copyAddress
    ? buildWithSingleAddress({ data, levels, mailingSequence })
    : buildWithDeliveryAddress({ data, levels, countryName, mailingSequence, deliverySequence })

  const otherAddress = getOtherAddress(personAddresses, mailingSequence, deliverySequence)

  return { ...person, addresses: [ ...filledAddresses, ...otherAddress ] }
}

function buildWithSingleAddress({ data, levels, mailingSequence }) {
  const address = {
    ...buildAddress({ data, levels }),
    addressUse: [DELIVERY_ADDRESS, MAILING_ADDRESS],
    sequence: mailingSequence
  }
  return [address]
}

function buildWithDeliveryAddress({ data, levels, countryName, mailingSequence, deliverySequence }) {
  const parsedAddress = buildAddress({ data, levels })
  const homeAddress = {
    ...parsedAddress,
    addressType: ADDRESS_TYPE.MAILING,
    addressUse: [MAILING_ADDRESS],
    sequence: mailingSequence
  }
  const deliveryAddress = {
    addressType: ADDRESS_TYPE.DELIVERY,
    addressUse: [DELIVERY_ADDRESS],
    geographicalLevels: buildGeographicLevels({ data, levels, countryName }),
    sequence: deliverySequence
  }
  return [
    homeAddress,
    deliveryAddress
  ]
}

function buildAddress({ data, levels }) {
  const address = parseData(data, levels)
  const cumulativeAddress = JSON.parse(localStorage.getItem('cumulativeAddress'))

  address.geographicalLevels = [...cumulativeAddress.geographicalLevels, ...address.geographicalLevels]

  return address
}

function buildGeographicLevels({ data, levels, countryName }) {
  const {
    deliveryNumber,
    deliveryComplement,
    deliveryReferences,
    deliveryStreet,
    city,
    department,
    neighborhood
  } = data.address[0]

  const { parsedDepartment, parsedCity, parsedNeighborhood } = buildGeoCommercialStructureLevels({
    department,
    city,
    neighborhood
  })

  const geographicalLevels = [
    { level: levels.COUNTRY, description: countryName },
    { level: levels.NUMBER, description: deliveryNumber },
    { level: levels.STREET, description: deliveryStreet },
    { level: levels.REFERENCES, description: deliveryReferences },
    { level: levels.REGION, code: parsedDepartment.code, description: parsedDepartment.description },
    { level: levels.CITY, code: parsedCity.code, description: parsedCity.description },
    { level: levels.NEIGHBORHOOD, code: parsedNeighborhood.code, description: parsedNeighborhood.description }
  ]

  if (deliveryComplement) {
    geographicalLevels.push({ level: levels.COMPLEMENT, description: deliveryComplement })
  }

  return geographicalLevels
}

function buildGeoCommercialStructureLevels({ department, city, neighborhood }) {
  const geoCommercialStructuresAreParsedAlready = () => department.description
    && city.description
    && neighborhood.description

  if (geoCommercialStructuresAreParsedAlready()) {
    return {
      parsedDepartment: department,
      parsedCity: city,
      parsedNeighborhood: neighborhood,
    }
  }

  const { addresses: { addressesPeopleManagement } } = store.getState()

  const departmentDescription = addressesPeopleManagement.departments.COLOMBIA.find((d) => {
    return d.geoStructureCode === department
  }).structureLevelName

  const cityDescription = addressesPeopleManagement.cities[department].find((c) => {
    return c.geoStructureCode === city
  }).structureLevelName

  const neighborhoodDescription = addressesPeopleManagement.neighborhoods[city].find((n) => {
    return n.geoStructureCode === neighborhood
  }).structureLevelName

  return {
    parsedDepartment: {
      code: department,
      description: departmentDescription
    },
    parsedCity: {
      code: city,
      description: cityDescription
    },
    parsedNeighborhood: {
      code: neighborhood,
      description: neighborhoodDescription
    }
  }
}

function parseData(data, levels) {
  const firstAddress = data.address[0]
  return mapAddress({ address: firstAddress, levels })
}

function mapAddress({ address, levels }) {
  const {
    number,
    complement,
    references,
    street
  } = address

  const addressType = ADDRESS_TYPE.MAILING
  const processedNumber = number === 'S/N' ? '' : number

  const geographicalLevels = [
    { level: levels.NUMBER, description: processedNumber },
    { level: levels.STREET, description: street },
    { level: levels.REFERENCES, description: references }
  ]

  if (complement) {
    geographicalLevels.push(
      { level: levels.COMPLEMENT, description: complement }
    )
  }

  return {
    addressType,
    geographicalLevels
  }
}
