import React, { Component } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Input from 'src/base/Form/Components/Input'
import Button, { TYPES } from 'src/base/Button'
import Message from 'src/base/Form/Components/Message'

import messages from './DigitalSpace.messages'

import './DigitalSpace.styl'

const bem = bemClassName.bind(null, 'digitalSpace')
class DigitalSpace extends Component {
  constructor(props) {
    super(props)

    this.state = {
      digitalName: '',
      wasCheckedDigitalSpace: false,
      available: false,
      disabled: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.skipButton = this.skipButton.bind(this)
  }

  componentDidUpdate(prevProps) {
    this.setAvailable(prevProps)
    this.getDigitalSpace(prevProps)
    this.setInitialDigitalNameValue(prevProps)
  }

  setAvailable(prevProps) {
    const { id, value, digitalSpace: { available }, form: { setFieldTouched, setFieldValue } } = this.props
    const { digitalSpace: { available: prevAvailable } } = prevProps

    const isAvailable = available && available !== prevAvailable

    if (isAvailable) {
      this.setState({ available: true })
      setFieldTouched(id, true)
      setFieldValue(id, { ...value, confirmed: available })
    }
  }

  getDigitalSpace(prevProps) {
    const { person: { personId }, digitalSpace: { get } } = this.props

    const shouldGetDigitalSpace = personId && personId !== prevProps.person.personId

    if (shouldGetDigitalSpace)
      get(personId)
  }

  setInitialDigitalNameValue(prevProps) {
    const {
      id,
      value,
      digitalSpace: { digitalStoreName: digitalName },
      form: {
        setFieldTouched,
        setFieldValue
      }
    } = this.props

    const { digitalSpace: { digitalStoreName } } = prevProps

    const hasDigitalName = digitalName && digitalName !== digitalStoreName

    if (hasDigitalName) {
      this.setState({
        digitalName,
        disabled: true,
        available: false,
        wasCheckedDigitalSpace: false
      })

      setFieldTouched(id, true)
      setFieldValue(id, { ...value, digitalName, confirmed: true })
    }
  }

  skipButton() {
    return this.props.person.save({ isRegistrationComplete: true })
  }

  get error() {
    const { id, intl, error, form: { toucheds }, digitalSpace: { available } } = this.props
    const { digitalName, wasCheckedDigitalSpace } = this.state

    const isToutched = toucheds[id]
    const minimalCharacters = !!digitalName && digitalName.length < 3
    const digitalSpaceIsAvailable = !!digitalName && available && isToutched || !wasCheckedDigitalSpace

    if (minimalCharacters)
      return intl.formatMessage(messages['digital_space_characters_minimum_error'])

    if (!digitalSpaceIsAvailable)
      return `${this.message} ${intl.formatMessage(messages['digital_space_not_available'])}`

    return error
  }

  get success() {
    const { digitalSpace } = this.props
    const { wasCheckedDigitalSpace } = this.state

    return digitalSpace.available && wasCheckedDigitalSpace
  }

  get message() {
    const { digitalName } = this.state

    return `natura.com.br/consultoria/${digitalName}`
  }

  handleChange(digitalName) {
    const { id, form: { setFieldValue, setFieldTouched } } = this.props

    this.setState({ digitalName, wasCheckedDigitalSpace: false, available: false })

    setFieldValue(id, { digitalName, confirmed: false })
    setFieldTouched(id, !digitalName)
  }

  async handleClick() {
    const {
      id,
      person: { personId },
      digitalSpace: { check },
      value: { digitalName },
      form: { setFieldTouched }
    } = this.props

    await check(digitalName, personId)

    this.setState({ wasCheckedDigitalSpace: true })
    setFieldTouched(id, true)
  }

  render() {
    const { digitalName, available, disabled } = this.state
    const { intl, id } = this.props

    return (
      <div className={bem()}>
        <Input
          id={id}
          error={this.error}
          success={this.success}
          message={this.message}
          label={intl.formatMessage(messages['digital_space_label'])}
          value={digitalName}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <div className={bem('buttons')}>
          <Button
            disabled={disabled}
            onClick={this.skipButton}
            type={TYPES.TEXT_PRIMARY}
          >
            {intl.formatMessage(messages['digital_space_skip'])}
          </Button>
          <Button
            disabled={disabled}
            onClick={this.handleClick}
            type={TYPES.PRIMARY}
          >
            {intl.formatMessage(messages['digital_space_verify'])}
          </Button>
        </div>
        <Message
          className={bem('available', { hidden: !available })}
          message={intl.formatMessage(messages['digital_space_confirm_email_phone'])}
          emphasis={intl.formatMessage(messages['digital_space'])}
          primary
        />
      </div>
    )
  }
}

DigitalSpace.propTypes = {
  id: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  digitalSpace: PropTypes.shape({
    digitalStoreName: PropTypes.string,
    check: PropTypes.func,
    get: PropTypes.func,
    available: PropTypes.bool
  }),
  personId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    toucheds: PropTypes.array
  }),
  intl: PropTypes.object,
  defaultValue: PropTypes.object,
  person: PropTypes.shape({
    personId: PropTypes.string,
    save: PropTypes.func
  })
}

export default DigitalSpace
