import TYPES from 'src/configs/Fields'

export const getChileFieldsSchema = ({intl, dependencies}) => {
  const ADDRESS_STRUCTURE_CHL = commonParams(TYPES.ADDRESS_STRUCTURE_CHL(intl, dependencies))

  return [[ADDRESS_STRUCTURE_CHL]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
