import * as yup from 'yup'
import { store } from 'src/App.state'
import City from './City'
import Department from './Department'
import Neighborhood from './Neighborhood'
import Maps from '../Maps'
import NumberAddress from '../Number'
import Complement from '../Complement'
import References from '../References'
import ButtonAddConfig from '../AddressAdd'
import ButtonRemoveConfig from '../AddressRemove'
import CopyAddress, { getValidationCopyAddress } from '../AddressCopy'
import FormTitle from 'src/configs/Fields/Address/AddressTitle'
import { messages } from '../Messages'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'

const dependencies = {
  messages
}

const hiddenBy = [
  'address[].department',
  'address[].city',
  'address[].neighborhood'
]

const hiddenByMaps = [...hiddenBy, 'address[].gmap']

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    person: { addresses = [] }
  } = injection
  const autoFocus = { autoFocus: true }

  const baseValue = {
    copyAdress: true,
    countryName: 'COLOMBIA'
  }

  const copyAddressRequired = intl.formatMessage(messages.address_required)
  const defaultValue = parsedDefaultValues(addresses, baseValue)

  const DEPARTMENT = { ...Department(intl, injection), htmlProps: autoFocus }
  const CITY = City(intl, injection)
  const NEIGHBORHOOD = Neighborhood(intl, injection)
  const MAPS = { ...Maps(intl, injection), hiddenBy, onChange: onMapChange }
  const NUMBER_ADDRESS = {
    ...NumberAddress(intl, injection),
    hiddenBy: hiddenByMaps
  }
  const COMPLEMENT = { ...Complement(intl, injection), hiddenBy: hiddenByMaps }
  const REFERENCES_ADDRESS = {
    ...References(intl, injection),
    hiddenBy: hiddenByMaps
  }
  const COPY_ADDRESS = CopyAddress(intl, injection)
  const BUTTON_ADD = ButtonAddConfig(intl, injection)
  const BUTTON_REMOVE = ButtonRemoveConfig(intl, injection)
  const GROUP_TITLE = FormTitle(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    addButton: BUTTON_ADD,
    removeButton: BUTTON_REMOVE,
    itemTitle: GROUP_TITLE,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [DEPARTMENT],
      [CITY],
      [NEIGHBORHOOD],
      [MAPS],
      [NUMBER_ADDRESS],
      [COMPLEMENT],
      [REFERENCES_ADDRESS],
      [COPY_ADDRESS]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          department: yup.string().required(),
          city: yup.string().required(),
          neighborhood: yup.string().required(),
          gmap: yup
            .object()
            .shape({
              street: yup
                .string()
                .required()
                .nullable(),
              location: yup
                .object()
                .required()
                .nullable(),
              confirmed: yup.bool().required()
            })
            .required()
            .nullable(),
          number: yup.string().required(),
          complement: yup.string().nullable(),
          references: yup.string().required(),
          copyAdress: getValidationCopyAddress(copyAddressRequired)
        })
      )
      .min(1)
      .max(20)
  }
}

function parsedDefaultValues(values, baseValue) {
  if (values.length) {
    const addresses = sortAddresses(values)

    return addresses.reduce(reduceAddress, [{ ...baseValue }])
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress = deliveryAddress && !mailingAddress.isUseToDelivery

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

function reduceAddress(accum, address, index, array) {
  const {
    addresses: { addressesPeopleManagement }
  } = store.getState()
  const { departments, cities, neighborhoods } = addressesPeopleManagement
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    department,
    city,
    neighborhood,
    location,
    street,
    addressNumber,
    addressComplement,
    references,
    addressUse,
    isUseToDelivery
  } = address

  const { geoStructureCode: departmentCode } = getLevel(
    departments[countryName],
    department
  )

  const { geoStructureCode: cityCode } = getLevel(cities[departmentCode], city)

  const { geoStructureCode: neighborhoodCode } = getLevel(
    neighborhoods[cityCode],
    neighborhood
  )

  const streetDescription = street && street.description

  const addressNumberDescription = addressNumber && addressNumber.description
  const addressComplementDescription =
    addressComplement && addressComplement.description
  const referencesDescription = references && references.description
  const confirmed = streetDescription && !!location.lat && !!location.lng

  const newAddress = {
    department: departmentCode,
    city: cityCode,
    neighborhood: neighborhoodCode,
    number: addressNumberDescription,
    complement: addressComplementDescription,
    references: referencesDescription,
    gmap: {
      location,
      street: streetDescription,
      confirmed
    },
    addressUse,
    countryName,
    copyAdress: isUseToDelivery
  }

  return [...accum, newAddress]
}

export function getLevel(array = [], address = {}) {
  return array.find(level => level.geoStructureCode === address.code) || {}
}

function onMapChange(props) {
  const {
    schema: {
      parent,
      group: { id }
    },
    setFieldValue,
    setFieldTouched
  } = props

  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.complement`, false)
  setFieldTouched(`${parent}.references`, false)
}
