import { defineMessages } from 'react-intl'
import { isDefaultFlowToggleActive } from '../../../config'

export const messages = defineMessages({
  defaultLabel: {
    defaultMessage: 'I have read and agreed with ##Natura Terms## ',
    id: 'form.field.policy.personal.defaultLabel'
  },
  label: {
    defaultMessage: 'The intention accepts and authorizes the ##processing of your personal data##',
    id: 'form.field.policy.personal.label'
  },
  modal_title: {
    defaultMessage: 'Authorization for the use of personal data',
    id: 'form.field.policy.personal.modal.title'
  },
  default_modal_title: {
    defaultMessage: "Natura terms",
    id: 'form.field.policy.personal.default_modal.title'
  },
  modal_button: {
    defaultMessage: 'Accept',
    id: 'form.field.policy.personal.modal.button'
  },
  default_modal_close_button: {
    defaultMessage: 'Close',
    id: 'form.field.policy.personal.default_modal.close_button'
  }
})

const dependencies = {
  messages,
  personalMessages: []
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { personalMessages } = { ...dependencies, ...injection }
  const agreements = injection?.locale?.configs?.localization?.agreements

  return {
    id: 'personal_data',
    type: 'checkboxModal',
    label: isDefaultFlowToggleActive() ? intl.formatMessage(messages.defaultLabel) : intl.formatMessage(messages.label),
    required: false,
    customProps: {
      linkIndexes: [1],
      modal: [{
        title: isDefaultFlowToggleActive() ? intl.formatMessage(messages.default_modal_title) : intl.formatMessage(messages.modal_title),
        message: personalMessages,
        agreements,
        acceptLabel: intl.formatMessage(messages.modal_button),
        cancel: isDefaultFlowToggleActive(),
        cancelLabel: isDefaultFlowToggleActive() ? intl.formatMessage(messages.default_modal_close_button) : '',
      }]
    },
  }
}
