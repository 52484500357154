import 'core-js/modules/es.promise'
import 'core-js/modules/es.array.iterator'
import React from 'react'
import ReactDOM from 'react-dom'
import { start } from 'single-spa'


import App from './App'

const rootElement = document.getElementById('root')

ReactDOM.render(<App />, rootElement)

if (module.hot) {
  module.hot.accept('./App', function () {
    const NextApp = require('./App')

    ReactDOM.render(<NextApp />, rootElement)
  })
}

start()
