import { buildSteps as buildDefaultSteps } from './buildSteps.default'
import { buildSteps as buildArgentinaSteps } from './buildSteps.arg'
import { buildSteps as buildChileSteps } from './buildSteps.chl'
import { buildSteps as buildColombiaSteps } from './buildSteps.col'
import { buildSteps as buildMalaysiaSteps } from './buildSteps.mys'
import { buildSteps as buildPeruSteps } from './buildSteps.per'
import { buildStepsAvon as buildAvonMexico } from './buildSteps.avon.mex'
import { buildStepsNaturaMexico } from './buildSteps.natura.mex'
import { buildStepsAvonBrazil as buildAvonBrazilSteps } from './buildSteps.avon.bra'
import { TENANT_ID } from '../../../../../../../models/tenant/tenant-id'

const strategies = {
  [TENANT_ID.NATURA_ARGENTINA]: buildArgentinaSteps,
  [TENANT_ID.NATURA_CHILE]: buildChileSteps,
  [TENANT_ID.NATURA_COLOMBIA]: buildColombiaSteps,
  [TENANT_ID.NATURA_MALAYSIA]: buildMalaysiaSteps,
  [TENANT_ID.NATURA_PERU]: buildPeruSteps,
  [TENANT_ID.AVON_MEXICO]: buildAvonMexico,
  [TENANT_ID.AVON_BRAZIL]: buildAvonBrazilSteps,
  [TENANT_ID.NATURA_MEXICO]: buildStepsNaturaMexico,
  __default__: buildDefaultSteps
}

export const buildSteps = (tenantId, { basePath }) => {
  const strategy = strategies[tenantId]
    ? strategies[tenantId]
    : strategies.__default__
  return strategy({ basePath })
}
