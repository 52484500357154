import { getContext } from 'src/infra/Api/Api.context'
import BusinessModel from 'src/viewModels/BusinessModel'
import { CLIENT_NAMES } from '../../infra/Api/Api'

const BUSINESS_MODEL_GET_LOADING = 'natura-caduni/Person/BUSINESS_MODEL_GET_LOADING'
const BUSINESS_MODEL_GET_SUCCESS = 'natura-caduni/Person/BUSINESS_MODEL_GET_SUCCESS'
const BUSINESS_MODEL_GET_ERROR = 'natura-caduni/Person/BUSINESS_MODEL_GET_ERROR'

export const types = [BUSINESS_MODEL_GET_LOADING, BUSINESS_MODEL_GET_SUCCESS, BUSINESS_MODEL_GET_ERROR]

export function businessModelGetReducer(state, action, dependencies) {
  const { payload, type } = action

  const loading = type === BUSINESS_MODEL_GET_LOADING
  const error = type === BUSINESS_MODEL_GET_ERROR

  if (type === BUSINESS_MODEL_GET_SUCCESS) {
    const success = true
    const { data } = payload
    const parsedData = parseBusinessModel(data)

    const businessModel = new BusinessModel(parsedData, dependencies)

    return {
      ...state,
      loading,
      error,
      success,
      businessModel
    }
  }

  return {
    ...state,
    loading,
    error
  }
}

export function getBusinessModel() {
  const globalAppContext = getContext()
  const {
    dispatch,
    connectivity,
  } = globalAppContext

  if (connectivity.isOffline) return

  const { requestClientName, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext })

  return dispatch({
    types,
    payload: {
      client: requestClientName,
      request: {
        method: 'GET',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload ({ globalAppContext }){
    const {
      BUSINESS_MODEL_URL,
      BUSINESS_MODEL_BFF_URL,
      countryId,
      companyId,
      bffHeaders,
      headersAuthenticationInfo,
      shouldGetBusinessModelFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const headersData = { companyId, countryId, bffHeaders, sellerId }
    const requestClientName = shouldGetBusinessModelFromBff ? CLIENT_NAMES.DEFAULT_BFF : `${countryId}_CMM`
    const { requestHeaders } = getRequestHeaders({ shouldGetBusinessModelFromBff, headersAuthenticationInfo, headersData })
    const requestUrl = shouldGetBusinessModelFromBff ? BUSINESS_MODEL_BFF_URL : BUSINESS_MODEL_URL

    return { requestClientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        cmm_urls: { BUSINESS_MODEL },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { BUSINESS_MODEL: BUSINESS_MODEL_BFF },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetBusinessModelFromBff = configs?.localization?.shouldGetBusinessModelFromBff

      return {
        BUSINESS_MODEL_URL: BUSINESS_MODEL,
        BUSINESS_MODEL_BFF_URL: BUSINESS_MODEL_BFF(),
        shouldGetBusinessModelFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetBusinessModelFromBff, headersAuthenticationInfo, headersData }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const { countryId, companyId, bffHeaders, sellerId } = headersData

      const requestHeaders = shouldGetBusinessModelFromBff ? {
        ...bffHeaders,
        Authorization: userAccessToken,
        'x-api-key': registersBffApiKey,
        country: countryId,
        companyid: companyId,
        'group-roles': true,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        'group-roles': true,
      }

      return { requestHeaders }
    }

  }
}

function parseBusinessModel(data) {
  return data.map(parseData)
}

function parseData(data) {
  const { businessModelId } = data
  const roles = data.roles.map(parseRole, { businessModelId })

  return { ...data, roles }
}

function parseRole(role) {
  const { roleId } = role
  const functions = role.functions.map(parseFunctions, { roleId, businessModelId: this.businessModelId })

  return { ...role, parent: { businessModelId: this.businessModelId }, functions }
}

function parseFunctions(fn) {
  const parent = {
    rolesId: this.roleId,
    businessModelId: this.businessModelId
  }

  return { ...fn, parent }
}
