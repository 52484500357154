import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

export const Messages = defineMessages({
  page_title: {
    defaultMessage: 'Registration of New Consultants [Brazil]',
    id: 'register.address.bra.page.title'
  },
  page_subtitle_1: {
    defaultMessage: 'Complete the registration with more information.',
    id: 'register.address.bra.subtitle_1'
  },
  page_subtitle_2: {
    defaultMessage: 'It is very important to have the complete registration.',
    id: 'register.address.bra.subtitle_2'
  },
  page_label: {
    defaultMessage: 'Additional data',
    id: 'register.address.bra.label'
  },
  page_complementary: {
    defaultMessage: 'Complementary Data',
    id: 'form.field.address.bra.complementary'
  }
})

export function getSectionTitle({ messages = Messages }) {
  const {
    page_title,
    page_subtitle_1,
    page_subtitle_2,
    page_label
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...page_subtitle_1} key="subtitle_1" />,
      <FormattedMessage {...page_subtitle_2} key="subtitle_2" />
    ],
    label: <FormattedMessage {...page_label} />,
  }
}
