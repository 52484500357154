import { defineMessages } from 'react-intl'

export const messagesValidations = defineMessages({
  maxFileSizeInMB: {
    defaultMessage: 'The maximum allowed size is {maxFileSize}',
    id: 'form.field.attachment.maxFileSizeInMB'
  },
  validFormats: {
    defaultMessage: 'Invalid file format, formats accepted: {validFormats}',
    id: 'form.field.attachment.validFormats'
  }
})

export const defineDefaultValue = function(attachments, type) {
  const attachment = attachments.find(attach => attach.type === type )

  if (attachment?.base64) {
    const { base64, url, type, description, name, size, sequence, verified } = attachment

    const downloadUrl = url && url.split('?')[0]
    const blob = base64toBlob(base64)

    return {
      verified,
      downloadUrl,
      file: blob,
      description,
      sequence,
      name,
      size,
      type
    }
  }

  return {}
}

function base64toBlob(b64Data) {
  if (!b64Data) return null

  const block = b64Data.split(";")
  const contentType = block[0].split(":")[1]
  const realData = block[1].split(",")[1]
  const sliceSize = 512

  const byteCharacters = atob(realData)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}
