import { PAYMENT_PLANS } from "src/models/PaymentPlan/PaymentPlan"
import { getParsedData } from './strategies/getParsedData'
import { getSubstatus } from './strategies/getSubstatus/getSubstatus'
import { CountryIdAlphaCode3 } from 'src/models/country/country-ids'

export function onSubmition({
  person,
  peopleRegister,
  locale: {
    configs,
    id: localeId
  }
}) {
  return function(data, callback) {
    const parsedPayload = getParsedData(localeId, { data, configs, person })

    peopleRegister.savePerson(parsedPayload)

    const personData = getPersonData({ person, parsedPayload }, localeId)

    return person.save(personData, savePersonStatus(localeId, person, callback))
  }
}

const getPersonData = ({ person, parsedPayload }, localeId) => {
  const prePaidPaymentPlan = {
    id: PAYMENT_PLANS.PRE_PAID_PAYMENT
  }
  const personData = {
    addresses: parsedPayload.addresses
  }

  const response = person.paymentPlan?.id || localeId === CountryIdAlphaCode3.COLOMBIA
    ? personData
    : { ...personData, paymentPlan: prePaidPaymentPlan }

  return response
}

function savePersonStatus(localeId, person, callback) {
  return function (newPerson, result) {
    if (!result.error) {
      person.saveStatus({ substatus: getSubstatus(localeId)  })
    }
    callback()
  }
}

