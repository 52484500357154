import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_SAVE_CONTACT_INFORMATION_LOADING = 'natura-caduni/Person/PROFILE_SAVE_CONTACT_INFORMATION_LOADING'
export const PROFILE_SAVE_CONTACT_INFORMATION_SUCCESS = 'natura-caduni/Person/PROFILE_SAVE_CONTACT_INFORMATION_SUCCESS'
export const PROFILE_SAVE_CONTACT_INFORMATION_ERROR = 'natura-caduni/Person/PROFILE_SAVE_CONTACT_INFORMATION_ERROR'

export const types = [PROFILE_SAVE_CONTACT_INFORMATION_LOADING, PROFILE_SAVE_CONTACT_INFORMATION_SUCCESS, PROFILE_SAVE_CONTACT_INFORMATION_ERROR]

export function savePersonContactInformation(person, data) {
  const {
    dispatch,
    urls: { CONTACT_INFORMATION },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  const {
    personId,
    businessModel: {
      businessModelId,
      functionId,
      roleId
    }
  } = person

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'POST',
        url: CONTACT_INFORMATION(personId),
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          businessModel: businessModelId,
          function: functionId,
          role: roleId,
        },
        data
      }
    }
  })
}
