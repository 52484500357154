import { getSectionTitle } from './Messages'
import { store } from 'src/App.state'

export function buildCompletedMicrofrontendProps(deps) {
  const { user: { user } } = store.getState()
  const {
    intl,
    person,
    locale: {
      id,
      tenantId,
      companyId,
      configs: {
        localization: {
          hasDigitalStore,
          completedStep: {
            shouldSearchIndication,
            shouldCheckStatus,
            shouldOnlyShowConfirmation,
          },
        },
      },
    },
    user: { roles },
  } = deps
  const { personId } = person
  const { title, subtitle, messages } = getSectionTitle(deps)
  const { authentication } = user

  const { businessModelId, functionId, roleId } = getRoles(person)
  const {
    loader: { personLoading }
  } = deps

  return {
    messages,
    step: {
      title,
      subtitle
    },
    loader: personLoading,
    intl,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'completed',
      authenticatedUser: {
        name: user.name,
        authenticated: user.authenticated,
        canEditInSelfRegistrationFlow: user.canEditInSelfRegistrationFlow,
        canRegister: user.canRegister,
        isCN: user.isCN,
        isCNO: user.isCNO,
        isCSC: user.isCSC,
        isFV: user.isFV,
        isSelfRegistration: user.isSelfRegistration,
        sellerId: user.sellerId,
        userRole: Object.keys(roles)[0],
      },
      identityInfo: {
        personId,
        countryId: id,
        tenantId,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
        authorization: authentication.accessToken ? authentication.accessToken : ''
      },
      completedInformation: {
        shouldSearchIndication,
        shouldCheckStatus,
        shouldOnlyShowConfirmation
      },
      person: {
        introducerSellerInformation: {
          introducerSellerId: person.additionalInformation.introducerSellerId,
        },
        paymentPlan: person.paymentPlan
      },
      hasDigitalStore
    }
  }
}

const getRoles = ({ businessModel, roles }) => {
  if (!isValidBusinessModel(businessModel)) {
    return getRolesFirstItem(roles)
  }

  return {
    businessModelId: businessModel.businessModelId,
    functionId: businessModel.functionId,
    roleId: businessModel.roleId
  }
}

const isValidBusinessModel = ({ businessModelId, functionId, roleId }) => businessModelId && functionId && roleId

const getRolesFirstItem = (roles) => {
  const firstRole = 0

  if (!roles.items.length) {
    return { businessModelId: undefined, functionId: undefined, roleId: undefined }
  }

  const firstItem = roles.items[firstRole]
  return {
    businessModelId: firstItem.businessModelId,
    functionId: firstItem.functionId,
    roleId: firstItem.roleId
  }
}
