import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_CNO_LOADING = 'natura-caduni/People/PROFILE_CNO_LOADING'
export const PROFILE_CNO_SUCCESS = 'natura-caduni/People/PROFILE_CNO_SUCCESS'
export const PROFILE_CNO_ERROR = 'natura-caduni/People/PROFILE_CNO_ERROR'

const TYPES_CNO = [PROFILE_CNO_LOADING, PROFILE_CNO_SUCCESS, PROFILE_CNO_ERROR]

export function getCNOList(salesHierarchyId) {
  const {
    dispatch,
    urls: { SELLERS_NATURA },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  return dispatch({
    types: TYPES_CNO,
    payload: {
      client: id,
      request: {
        method: 'GET',
        url: SELLERS_NATURA(salesHierarchyId),
        headers: {
          access_token: accessToken,
          userId: sellerId,
        }
      }
    }
  })
}
