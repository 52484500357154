import React from 'react'
import Icon from 'src/base/Icon'

export default function Builder(params) {
  const { intl, messages } = params

  return {
    id: 'digital-space',
    title: intl.formatMessage(messages.box_digital_space),
    wrapper: 'default',
    content: 'list',
    fields: [
      {
        label: intl.formatMessage(messages.box_digital_space_address),
        field: '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_status),
        field: intl.formatMessage(messages.box_digital_space_status_empty)
      },
      {
        field: (
          <div className="profile__digital__space__message">
            <Icon name="warning_circle" />
            <p>{intl.formatMessage(messages.box_digital_space_orientation_option_1)}</p>
          </div>
        )
      }
    ]
  }
}
