import TYPES from 'src/configs/Fields'

export const getPeruFieldsSchema = ({ intl, dependencies }) => {
  const ADDRESS_STRUCTURE_PER = commonParams(TYPES.ADDRESS_STRUCTURE_PER(intl, dependencies))

  return [[ADDRESS_STRUCTURE_PER]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
