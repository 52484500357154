import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'

const bem = bemClassName.bind(null, 'record-audio')

const ONE_SECOND = 1000

export default class PreviewRecording extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      playing: false,
      recordCounter: 0
    }

    this.playAudio = this.playAudio.bind(this)
    this.stopAudio = this.stopAudio.bind(this)
    this.increaseRecordCounter = this.increaseRecordCounter.bind(this)
  }

  componentDidMount() {
    this.audio.addEventListener('ended', this.stopAudio)
  }

  componentWillUnmount() {
    clearInterval(this.recordCounterInterval)
  }

  playAudio () {
    clearInterval(this.recordCounterInterval)

    this.audio.play()
    this.setState({ playing: true })

    this.recordCounterInterval = setInterval(this.increaseRecordCounter, ONE_SECOND)
  }

  stopAudio () {
    clearInterval(this.recordCounterInterval)

    this.audio.pause()
    this.audio.currentTime = 0

    this.setState({ playing: false, recordCounter: 0 })
  }

  increaseRecordCounter () {
    this.setState({ recordCounter: this.state.recordCounter + 1 })
  }

  render () {
    const {
      props: { recordDuration, mediaObjectURL, accept, discard },
      state: { playing, recordCounter },
      playAudio,
      stopAudio
    } = this

    const PLAYING_STATUS_ICON = playing ? 'audio_stop' : 'audio_play'
    const DD = playing ? stopAudio : playAudio

    return (
      <div className={bem()}>
        <header>
          <h3 className={bem('title')}>Aceptación de Términos y Condiciones</h3>
        </header>

        <section className={bem('content', 'preview-recording')}>
          <audio
            ref={node => this.audio = node}
            controls
            src={mediaObjectURL}
            preload="auto"
            hidden />
          <Icon name={PLAYING_STATUS_ICON} className={bem('actions__icon', 'start-stop')} onClick={DD} />
          {`00:${recordCounter.toString().padStart(2, 0)} / 00:${recordDuration.toString().padStart(2, 0)}`}
          <br />
          Haga clic para escuchar.
        </section>

        <footer className={bem('actions')}>
          <button className={bem('actions__button')} onClick={discard}>
            <Icon name="trash" className={bem('actions__icon', 'microphone')} />
            Descartar
          </button>
          <button className={bem('actions__button')} onClick={accept}>
            <Icon name="check" className={bem('actions__icon', 'microphone')} />
            Confirmar
          </button>
        </footer>
      </div>
    )
  }
}

PreviewRecording.defaultProps = {
  recordDuration: 0
}

PreviewRecording.propTypes = {
  accept: PropTypes.func,
  discard: PropTypes.func,
  listenRecordedAudio: PropTypes.func,
  mediaObjectURL: PropTypes.string,
  playing: PropTypes.bool,
  recordDuration: PropTypes.number
}
