import {nationalities} from '../../../../../../../Fields/Nationality/Nationality.list'
import {nationalitiesMessages} from '../../../../../../../Fields/Nationality/Nationality.messages'
import React from 'react'
import {ATTACHMENT_TYPES} from '../../../../../../../AttachmentTypes'

export class DocumentFieldsMapper {
  constructor({intl, messagesId, attachmentTypeToMessageIdsEnum}) {
    this._messagesId = messagesId
    this._intl = intl
    this._attachmentTypeToMessageIdsEnum = attachmentTypeToMessageIdsEnum
  }

  mapNationalityField({ nationalityId, messages }) {
    const nationalitiesList = Object.entries(nationalities)
    const [nationalityKey] = nationalitiesList.find(([, value]) => value === nationalityId) || []
    const label = this._intl.formatMessage(messages.box_documents_label_nationality)
    const field = nationalityKey
      ? this._intl.formatMessage(nationalitiesMessages[nationalityKey]) || '-'
      : '-'

    return {
      label, field
    }
  }

  mapDocuments({ documents, messages }) {
    return documents.map(({ document, type }) => {
      const messageId = this._messagesId[type]
      const message = messages[messageId]
      const formattedMessage = this._intl.formatMessage(message)
      return ({
        label: formattedMessage,
        field: document
      })
    })
  }

  mapAttachmentsField({ attachments, messages, documentsFields }) {
    const [{ label } = {}] = documentsFields

    const attachmentLinks = attachments
      .filter(attachment => this.hasValidAttachmentType({attachment, messages}))
      .map(attachment => {
        const attachmentLabelId = this._attachmentTypeToMessageIdsEnum[attachment.type]
        const attachmentLabelMessageId = messages[attachmentLabelId]
        const attachmentLabel = this._intl.formatMessage(attachmentLabelMessageId, { doc: label })
        return (
          <a
            className="profile__attachment__link"
            href={attachment.url}
            key={attachment.key}
            target="_blank"
            rel="noopener noreferrer">
            {attachmentLabel}
          </a>
        )
      })
    const field = attachmentLinks.length
      ? <div key="profile_attachments" className="profile__attachments">{attachmentLinks}</div>
      : '-'

    return {
      label: this._intl.formatMessage(messages.box_documents_label_attachments),
      field
    }
  }

  hasValidAttachmentType({messages, attachment}) {
    const attachmentMessageId = this._attachmentTypeToMessageIdsEnum[attachment.type]
    const message = messages[attachmentMessageId]
    return !!message
  }

  mapTermsField({ attachments, messages }) {
    const term = attachments.find(attachment => {
      return attachment.type === ATTACHMENT_TYPES.TERMS_AND_CONDITIONS_ACCEPTANCE
    }) || {}

    return {
      label: this._intl.formatMessage(messages.box_documents_label_attachments_terms_description),
      field: term.description
    }
  }
}
