import { getMalaysiaApiHeaders } from './getApiHeaders.mys'
import { getChileApiHeaders } from './getApiHeaders.chl'
import { TENANT_ID } from '../../../../../models/tenant/tenant-id'
import { getAvonMexicoApiHeaders } from './getApiHeaders.mex'

const strategies = {
  [TENANT_ID.NATURA_MALAYSIA]: getMalaysiaApiHeaders,
  [TENANT_ID.NATURA_CHILE]: getChileApiHeaders,
  [TENANT_ID.AVON_MEXICO]: getAvonMexicoApiHeaders
}

export const getApiHeaders = (tenantId) => {
  const strategy = strategies[tenantId]
  return strategy()
}
