import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import { ADDRESS_USE } from 'src/models/Address/AddressUse'
import messages from './Messages'

export function buildFormSchema(dependencies) {
  const { intl, person, connectivity: { isOffline } } = dependencies
  const { draft, reviewStep, addresses } = person

  const shouldReviewPerson = draft && !reviewStep.address && !isOffline
  const reviewFields = addresses.reduce(parsedReviewAddresses, [{ intl, messages }])

  const REVIEW = TYPES.REVIEW(intl, {...dependencies, reviewFields, shouldReviewPerson})
  const ADDRESS = TYPES.ADDRESS.PER(intl, { ...dependencies, shouldReviewPerson })

  const fieldsSchema = [
    [REVIEW],
    [ADDRESS]
  ]

  return new FormSchema({ fieldsSchema, name: 'Address' })
}

function parsedReviewAddresses(accum, address, index, array) {
  const [{ intl, messages }] = accum
  const lastItem = index === array.length -1

  const fields = getFields(intl, messages, address)
  const title = getTitle(intl, messages, address)

  if (lastItem) accum.shift()

  return [...accum, { title, fields }]
}

function getTitle(intl, messages, { addressUse }) {
  const isMailingAddress = addressUse.find(findDeliveryAddress, { id: ADDRESS_USE.MAILLING  })

  return isMailingAddress
    ? intl.formatMessage(messages.review_address_residential)
    : intl.formatMessage(messages.review_address_delivery)
}

function getFields(intl, messages, address) {
  const {region, department, province, district, neighborhood, street, addressNumber, references} = address

  return [
    {
      label: intl.formatMessage(messages.review_region),
      value: region
    },
    {
      label: intl.formatMessage(messages.review_department),
      value: department
    },
    {
      label: intl.formatMessage(messages.review_province),
      value: province
    },
    {
      label: intl.formatMessage(messages.review_district),
      value: district
    },
    {
      label: intl.formatMessage(messages.review_gmap),
      value: street
    },
    {
      label: intl.formatMessage(messages.review_neighborhood),
      value: neighborhood
    },
    {
      label: intl.formatMessage(messages.review_number),
      value: addressNumber
    },
    {
      label: intl.formatMessage(messages.review_references),
      value: references
    }
  ]
}

function findDeliveryAddress({ id }) {
  return id == this.id
}
