import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import BaseCheckbox from 'src/base/Checkbox'
import Label from 'src/base/Form/Components/Label'
import Message from 'src/base/Form/Components/Message'

import './Checkbox.styl'

const bem = bemClassName.bind(null, 'form-checkbox')

class Checkbox extends PureComponent {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(event) {
    return this.props.onChange(event.target.checked)
  }

  onBlur(event) {
    return this.props.onBlur(event.target.checked)
  }

  render() {
    const { className, id, type, value, error, disabled } = this.props

    return (
      <div className={classNames(bem({ disabled, error }), className)}>
        <BaseCheckbox
          className={bem('component')}
          id={id}
          type={type}
          error={!!error}
          disabled={disabled}
          checked={!!value}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        <div className={bem('content')}>
          {this.renderLabel()}
          {this.renderMessage()}
        </div>
      </div>
    )
  }

  renderLabel() {
    const { id, label, required, error, disabled, showAsterisk } = this.props

    if (!label) return null

    return (
      <Label
        className={bem('label', { error: !!error })}
        id={id}
        label={label}
        showAsterisk={showAsterisk}
        required={required}
        disabled={disabled}
        error={!!error}
      />
    )
  }

  renderMessage() {
    const { message, error, disabled } = this.props

    if (!message && !error) return null

    return <Message
      className={bem('message', { error: !!error })}
      message={message}
      error={error}
      disabled={disabled}
    />
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  message: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default Checkbox
