export const touchAfterReload = [
  'address[0].region', 'address[0].department', 'address[0].province', 'address[0].district', 'address[0].gmap',
  'address[1].region', 'address[1].department', 'address[1].province', 'address[1].district', 'address[1].gmap',
  'address[2].region', 'address[2].department', 'address[2].province', 'address[2].district', 'address[2].gmap',
  'address[3].region', 'address[3].department', 'address[3].province', 'address[3].district', 'address[3].gmap',
  'address[4].region', 'address[4].department', 'address[4].province', 'address[4].district', 'address[4].gmap',
  'address[5].region', 'address[5].department', 'address[5].province', 'address[5].district', 'address[5].gmap',
  'address[6].region', 'address[6].department', 'address[6].province', 'address[6].district', 'address[6].gmap',
  'address[7].region', 'address[7].department', 'address[7].province', 'address[7].district', 'address[7].gmap',
  'address[8].region', 'address[8].department', 'address[8].province', 'address[8].district', 'address[8].gmap',
  'address[9].region', 'address[9].department', 'address[9].province', 'address[9].district', 'address[9].gmap',
  'address[10].region', 'address[10].department', 'address[10].province', 'address[10].district', 'address[10].gmap',
  'address[11].region', 'address[11].department', 'address[11].province', 'address[11].district', 'address[11].gmap',
  'address[12].region', 'address[12].department', 'address[12].province', 'address[12].district', 'address[12].gmap',
  'address[13].region', 'address[13].department', 'address[13].province', 'address[13].district', 'address[13].gmap',
  'address[14].region', 'address[14].department', 'address[14].province', 'address[14].district', 'address[14].gmap',
  'address[15].region', 'address[15].department', 'address[15].province', 'address[15].district', 'address[15].gmap',
  'address[16].region', 'address[16].department', 'address[16].province', 'address[16].district', 'address[16].gmap',
  'address[17].region', 'address[17].department', 'address[17].province', 'address[17].district', 'address[17].gmap',
  'address[18].region', 'address[18].department', 'address[18].province', 'address[18].district', 'address[18].gmap',
  'address[19].region', 'address[19].department', 'address[19].province', 'address[19].district', 'address[19].gmap',
  'address[20].region', 'address[20].department', 'address[20].province', 'address[20].district', 'address[20].gmap'
]
