import { getContext } from 'src/infra/Api/Api.context'
import Addresses, { NO_INFORMATION } from 'src/viewModels/Addresses'

export const ADDRESSES_LOADING = 'natura-caduni/People/ADDRESSES_LOADING'
export const ADDRESSES_SUCCESS = 'natura-caduni/People/ADDRESSES_SUCCESS'
export const ADDRESSES_ERROR = 'natura-caduni/People/ADDRESSES_ERROR'

export const types = [
  ADDRESSES_LOADING,
  ADDRESSES_SUCCESS,
  ADDRESSES_ERROR
]

export function addressesCMMReducer(state, action, injection) {
  const { loading, error, addresses } = state
  const { payload, type, meta } = action

  const hasSuccess = type === ADDRESSES_SUCCESS
  const hasError = type === ADDRESSES_ERROR
  const isLoading = type === ADDRESSES_LOADING

  if(isLoading) {
    const { params } = payload
    const loadingStructure = builderLoadingStructure(params, true, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(error, { addresses: false })
  }

  if (hasSuccess) {
    const { previousAction: { payload: { params } }} = meta

    const dataStructure = builderDataStructure(payload, params, addresses)
    const loadingStructure = builderLoadingStructure(params, false, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(addresses, { ...dataStructure })
    Object.assign(error, { addresses: false })

  }

  if (hasError) {
    const { previousAction: { payload: { params } }} = meta
    const loadingStructure = builderLoadingStructure(params, false, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(error, { addresses: true })
  }

  return {
    ...state,
    addresses: new Addresses(addresses, { ...injection }),
    loading,
    error
  }
}

export function getAddressesCMM(params = {}) {
  const { geoStructureCode } = params

  const {
    dispatch,
    cmm_urls: { ADDRESS },
    locale: { id, name: countryName },
    user: {
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()


  const queryString = geoStructureCode ? `?geo-structure-code=${geoStructureCode}` : ''
  Object.assign(params, { countryName })

  return dispatch({
    types,
    payload: {
      client: `${id}_CMM`,
      params,
      request: {
        method: 'GET',
        url: ADDRESS(queryString),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
        }
      }
    }
  })
}

function builderLoadingStructure({ geoStructureType, geoStructureCode }, value, loading) {
  if (!geoStructureType) return { regions: value }

  return {
    [geoStructureType]: {
      ...loading[geoStructureType],
      [geoStructureCode]: value
    }
  }
}

function builderDataStructure({ data }, addressParams, addresses) {
  const { geoStructureCode, geoStructureType, countryName } = addressParams
  const [{ nextLevel = [] } = {}] = data

  if (!geoStructureType) {
    const departments = data.reduce(reduceDepartments, {})
    const country = countryName.toUpperCase()

    return {
      regions: {
        ...addresses.regions,
        [country]: data
      },
      departments: {
        ...addresses.departments,
        ...departments
      }
    }
  }

  return {
    [geoStructureType]: {
      ...addresses[geoStructureType],
      [geoStructureCode]: nextLevel.length ? nextLevel : NO_INFORMATION
    }
  }
}

function reduceDepartments(accum, { geoStructureCode, nextLevel } ) {
  return { ...accum, [geoStructureCode]: nextLevel }
}
