import React from 'react'
import { defineMessages } from 'react-intl'
import bemClassName from 'bem-classname'

const messages = defineMessages({
  button_remove: {
    defaultMessage: 'Remove',
    id: 'form.field.button.remove'
  }
})

const dependencies = {
  messages
}

const bem = bemClassName.bind(null, 'form__group__button')

export default function Builder(intl, injection) {
  const { messages, actions } = { ...dependencies, ...injection }

  const label = intl.formatMessage(messages['button_remove'])

  const node = (
    <button
      {...actions}
      className="form__group__button form__group__button--remove"
      title={label}>
      <i className={bem('icon', 'remove')} />
      {label}
    </button>
  )

  return {
    node
  }
}
