import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import SectionTitleBase from 'src/base/SectionTitle'
import { isDefaultFlowToggleActive } from '../../../../config'

class SectionTitle extends PureComponent {
  render() {
    const { step: { name, title, subtitle } } = this.props
    const key = `${name}__section-title`

    if(isDefaultFlowToggleActive()) { return null }

    return (
      <SectionTitleBase
        className={key}
        key={key}
        title={title}
        subtitle={subtitle}
      />
    )
  }
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  step: PropTypes.object.isRequired
}

export default SectionTitle
