import { AddressParser } from './AddressParser'
import { store } from 'src/App.state'
import { ADDRESS_TYPE } from 'src/models/Address/AddressType'
import { ADDRESS_USE, DELIVERY_ADDRESS, MAILING_ADDRESS } from 'src/models/Address/AddressUse'

export class ArgentinaAddressParser extends AddressParser {
  getParsedData({ data, configs }) {
    const {
      localization: {
        address: { levels }
      }
    } = configs
    const { person } = store.getState()

    const dataMailingAddress = this.findAddressByUse(data.address, ADDRESS_USE.MAILLING)

    if (!dataMailingAddress) {
      const addresses = []
      localStorage.setItem('cumulativeAddress', this.getCumulativeAddress(addresses))
      return { addresses }
    }

    const mailingAddress = this.mapAddress({ address: dataMailingAddress, levels })
    const {
      geographicLevels: previousSavedMailingGeographicLevels,
      zipCode: previousSavedZipCode
    } = this.getComplementaryAddressFrom(person, levels)

    localStorage.setItem('cumulativeAddress', this.getCumulativeAddress([mailingAddress]))

    mailingAddress.geographicalLevels = [
      ...mailingAddress.geographicalLevels,
      ...previousSavedMailingGeographicLevels
    ]

    mailingAddress.zipCode = previousSavedZipCode

    return this.getAllFilledAddresses(person, mailingAddress)
  }

  getAllFilledAddresses(person, mailingAddress) {
    const deliveryAddress = this.findAddressByUse(person.person.addresses, ADDRESS_USE.DELIVERY)

    const shouldReturnMailingAddressOnly = () => {
      const personHasNoDeliveryAddress = !deliveryAddress?.geographicalLevels?.length
      const personHasLessThanTwoAddresses = person.person.addresses.length < 2
      return personHasNoDeliveryAddress || personHasLessThanTwoAddresses
    }

    if (shouldReturnMailingAddressOnly()) {
      return { addresses: [mailingAddress] }
    }

    return this.mailingAndDeliveryAddress(mailingAddress, deliveryAddress)
  }

  mailingAndDeliveryAddress(mailingAddress, deliveryAddress) {
    const buildMailingAddress = () => ({
      ...mailingAddress,
      addressType: ADDRESS_TYPE.MAILING,
      addressUse: [MAILING_ADDRESS]
    })
    const buildDeliveryAddress = () => {
      const removeCodeFieldIfFalsy = ({ code, level, description }) => {
        if (code) {
          return { level, description, code }
        }
        return { level, description }
      }
      return {
        addressType: ADDRESS_TYPE.DELIVERY,
        addressUse: [DELIVERY_ADDRESS],
        geographicalLevels: deliveryAddress.geographicalLevels.map(removeCodeFieldIfFalsy),
        zipCode: deliveryAddress.zipCode,
      }
    }

    return { addresses: [buildMailingAddress(), buildDeliveryAddress()] }
  }

  buildGeographicalLevels({ addressesStructure, country, levels }) {
    const { level1, level2, level3, level4 } = addressesStructure
    return [
      { level: levels.COUNTRY, description: country },
      {
        level: levels.LEVEL1,
        code: level1.geoStructureCode,
        description: level1.structureLevelName
      },
      {
        level: levels.LEVEL2,
        code: level2.geoStructureCode,
        description: level2.structureLevelName
      },
      {
        level: levels.LEVEL3,
        code: level3.geoStructureCode,
        description: level3.structureLevelName
      },
      {
        level: levels.LEVEL4,
        code: level4.geoStructureCode,
        description: level4.structureLevelName
      },
    ]
  }

  getAddressStructure({ types, addresses }) {
    const { departments, cities, neighborhoods, communes } = addresses
    const { country, level1, level2, level3, level4 } = types

    return {
      level1: this.getGeoStructureValues(departments[country], level1),
      level2: this.getGeoStructureValues(cities[level1], level2),
      level3: this.getGeoStructureValues(neighborhoods[level2], level3),
      level4: this.getGeoStructureValues(communes[level3], level4)
    }
  }

  getComplementaryAddressFrom(personStructure, levels) {
    const { person: { addresses } } = personStructure

    if (!addresses.length) {
      return {
        geographicLevels: [],
      }
    }

    const { geographicalLevels, zipCode } = this.findAddressByUse(addresses, ADDRESS_USE.MAILLING)

    const levelsToFilter = [
      levels.STREET,
      levels.NUMBER,
      levels.COMPLEMENT,
      levels.REFERENCES
    ]

    const geographicLevelsResult = []

    geographicalLevels.forEach((geoLevel) => {
      if (levelsToFilter.includes(geoLevel.level)) {
        geographicLevelsResult.push({
          level: geoLevel.level,
          description: geoLevel.description,
        })
      }
    })
    return {
      geographicLevels: geographicLevelsResult,
      zipCode,
    }
  }

  findAddressByUse(addresses, addressUse) {
    return addresses?.find((address) => address.addressUse.find(({ id }) => id === addressUse))
  }
}
