import {BuildStepsMapper} from './BuildStepsMapper'

export const buildSteps = ({ basePath }) => {
  const buildStepsMapper = new BuildStepsMapper({basePath, totalSteps: 7})

  return [
    buildStepsMapper.mapPreRegister({ currentStep: 1 }),
    buildStepsMapper.mapPersonalData({ currentStep: 2 }),
    buildStepsMapper.mapAddress({currentStep: 3}),
    buildStepsMapper.mapDocumentation({ currentStep: 4 }),
    buildStepsMapper.mapDocumentAttachment({currentStep: 5 }),
    buildStepsMapper.mapIndication({currentStep: 6}),
    buildStepsMapper.mapCompleted({currentStep: 7}),
  ]
}
