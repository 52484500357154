import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

const searchButtonStrategies = {
  MYS: true,
  PER: true,
  CHL: true,
  __default__: true
}

export function buildFormSchema(dependencies) {
  const { intl, locale: { id: localeId } } = dependencies
  const strategyShowSearchButton = searchButtonStrategies[localeId] !== undefined
    ? searchButtonStrategies[localeId]
    : searchButtonStrategies.__default__

  const INDICATION = commonParams(TYPES.INDICATION_DEFAULT(intl, {
    ...dependencies,
    showSearchButton: strategyShowSearchButton
  }), true)

  const fieldsSchema = [
    [INDICATION]
  ]

  return new FormSchema({ fieldsSchema, name: 'Indication'})
}

function commonParams(field, required = true) {
  return {
    ...field,
    required
  }
}
