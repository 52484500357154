import Addresses from 'src/viewModels/Addresses'
import AddressesPeopleManagement from 'src/viewModels/AddressesPeopleManagement'

import {
  addressesLevelsReducer,
  getLevels,
  types as LevelsTypes
} from './Addresses.getLevels'

import {
  addressesCMMReducer,
  getAddressesCMM,
  types as addressesLatamTypes
} from './Addresses.CMM'

const dependencies = {
  addressesLevelsReducer
}

const addressesDependencies = {
  getLevels
}

const initialState = {
  addressesPeopleManagement: new AddressesPeopleManagement({}, addressesDependencies),
  addresses: new Addresses({}, { getAddressesCMM }),
  loading: {},
  error: {}
}

export default function reducer(
  state = initialState,
  action = {},
  _localState,
  injection = {}
) {
  const { addressesLevelsReducer } = { ...injection, ...dependencies }

  if (addressesLatamTypes.includes(action.type))
    return addressesCMMReducer(state, action, { getAddressesCMM })

  if (LevelsTypes.includes(action.type))
    return addressesLevelsReducer(state, action, addressesDependencies)

  return state
}
