import { defineMessages } from 'react-intl'

export default defineMessages({
  digital_space: {
    "id": "register.digital.space",
    "defaultMessage": "Digital Space"
  },
  digital_space_confirm_email_phone: {
    "id": "register.digital.space.confirm.email.phone",
    "defaultMessage": "Confirm your phone and email, remember that this data is necessary to open the "
  },
  digital_space_label: {
    "id": "register.digital.space.label",
    "defaultMessage": "Digital Space Name"
  },
  digital_space_characters_minimum_error: {
    "id": "register.digital.space.characters.minimum.error",
    "defaultMessage": "Must be at least 3 characters"
  },
  digital_space_not_available: {
    "id": "register.digital.space.not.available",
    "defaultMessage": "is not available"
  },
  digital_space_available: {
    "id": "register.digital.space.available",
    "defaultMessage": "is available"
  },
  digital_space_skip: {
    "id": "register.digital.space.skip",
    "defaultMessage": "Skip"
  },
  digital_space_verify: {
    "id": "register.digital.space.verify",
    "defaultMessage": "Verify"
  }
})
