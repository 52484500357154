import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { isString, isArray, isObject, has } from 'lodash'

const adapter = validatorAdapter('Yup', yup)

export const ERROR_CODES_TO_INTERCEPT = ['15', '35', '36', '37']
const RESPONSE_KEY = 'response'
const REQUEST_KEY = 'request'
const DATA_KEY = 'data'

export default class Error extends Entity {
  static SCHEMA = {
    title: adapter(yup.string().nullable()),
    message: adapter(yup.string().nullable()),
    messages: adapter(yup.array().of(yup.string()).nullable()),
    code: adapter(yup.string().nullable()),
    status: adapter(yup.string().nullable())
  }

  constructor(props) {
    super(props)

    this.originalError = props
    this.parseProps(props)
  }

  parseProps(props) {
    if(isString(props))
      return this.createFromString(props)

    if(isArray(props))
      return this.createFromArray(props)

    if(isObject(props)) {
      if(has(props, REQUEST_KEY))
        this.setStatus(props[REQUEST_KEY])

      if(has(props, RESPONSE_KEY))
        return this.parseProps(props[RESPONSE_KEY])

      if(has(props, DATA_KEY)) {
        const validatedData = this.detectErrorAsHtml(props[DATA_KEY], props)

        return this.parseProps(validatedData)
      }

      return this.createFromObject(props)
    }
  }

  createFromString(string, array) {
    this.title = string
    this.message = string
    this.messages = array || [string]
  }

  detectErrorAsHtml(data, { status, statusText }) {
    if(isString(data) && data.indexOf('<!') >= 0) {
      return { code: status, message: `${statusText} (${status})` }
    }

    return data
  }

  createFromObject({ message, details, code }, array) {
    this.title = this.parseMessage(message)
    this.code = code
    this.message = details
    this.messages = array
      ? array.map(this.createMessageFromObject, this)
      : [this.message]
  }

  createMessageFromObject({ details = '' }) {
    return details
  }

  createFromArray(array) {
    const firstMessage = array[0]

    if(isString(firstMessage))
      return this.createFromString(firstMessage, array)

    if(isObject(firstMessage))
      return this.createFromObject(firstMessage, array)

    return firstMessage
  }

  parseMessage(message) {
    if(typeof message === 'undefined') {
      return null
    }
    if(typeof message === 'string') {
      return message
    }
    if(message.hasOwnProperty('message')) {
      return message.message
    }
  }

  setStatus({ status }) {
    this.status = status
  }

  get rejected() {
    return ERROR_CODES_TO_INTERCEPT.includes(this.code)
  }
}
