import BusinessModel from 'src/viewModels/BusinessModel'

import { businessModelGetReducer, getBusinessModel, types as businessModelTypes } from './BusinessModel.get'
import { businessModelRehydrateReducer, types as businessModelRehydrate } from './BusinessModel.rehydrate'

const dependencies = {
  businessModelGetReducer,
  businessModelRehydrateReducer
}

const businessModelDependencies = { getBusinessModel }

const initialState = {
  businessModel: new BusinessModel({}, businessModelDependencies),
  loading: false,
  error: false
}

export default function reducer(state = initialState, action = {}, _, injection = {}) {
  const { type } = action

  const {
    businessModelGetReducer,
    businessModelRehydrateReducer
  } = { ...dependencies, ...injection }

  if (businessModelTypes.includes(type))
    return businessModelGetReducer(state, action, businessModelDependencies)

  if (businessModelRehydrate.includes(type))
    return businessModelRehydrateReducer(state, action, businessModelDependencies)

  return state
}
