import { emailResendReducer, resendEmail, types as emailResendReducerTypes } from './EmailValidation.resend'
import { emailVerifyReducer, verifyEmail, types as emailVerifyReducerTypes } from './EmailValidation.verify'
import { emailResetReducer, resetEmail, types as emailResetReducerTypes } from './EmailValidation.reset'

import EmailValidation from 'src/viewModels/EmailValidation'

const dependences = {
  resendEmail,
  verifyEmail,
  resetEmail
}

const initialState = {
  emailValidation: new EmailValidation({}, dependences),
  loading: false,
  error: false,
}

export default function reducer(state = initialState, action = {}) {
  const { type } = action

  if (emailResendReducerTypes.includes(type))
    return emailResendReducer(state, action, dependences)

  if (emailVerifyReducerTypes.includes(type))
    return emailVerifyReducer(state, action, dependences)

  if (emailResetReducerTypes.includes(type))
    return emailResetReducer(state, action, dependences)

  return state
}
