import { COMPANY_ID } from '../../models/Company'
import { CountryIdAlphaCode2 } from '../../models/country'
import { HOTJAR_ID } from '../../models/hotjar/hotjar-id'
import { TENANT_ID } from '../../models/tenant'

const injectHotjarScript = ({ hotjarId }) =>
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: hotjarId, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

const getTenantIdFromCompanyAndPathName = ({ companyId }) => {
  const pathName = window.location.pathname

  if (companyId === COMPANY_ID.AVON) {
    if (pathName.includes(`/${CountryIdAlphaCode2.MEXICO.toLowerCase()}`)) {
      return TENANT_ID.AVON_MEXICO
    }
  }
}

const isTheCurrentTenant = ({ tenantIdWillBeValidated, companyId }) => {
  const currentTenantId = getTenantIdFromCompanyAndPathName({ companyId })

  if (currentTenantId && currentTenantId === tenantIdWillBeValidated) {
    return true
  }

  return false
}

const removePreviousHotjar = () => {
  document.querySelectorAll('script').forEach((script) => {
    script.src.includes('hotjar') ? script.remove() : null
  })
  document.querySelectorAll('iframe').forEach((iframe) => {
    iframe.src.includes('hotjar') ? iframe.remove() : null
  })
}

const getHotjarIdFromTenantAndEnvironment = ({ companyId }) => {
  const isProductionEnvironment =
    process.env.ENVIRONMENT === 'prod' || process.env.ENVIRONMENT === 'prd'
  const isHomologationEnvironment = process.env.ENVIRONMENT === 'hml'

  const isAvonMexico = isTheCurrentTenant({
    tenantIdWillBeValidated: TENANT_ID.AVON_MEXICO,
    companyId
  })

  if (isProductionEnvironment) {
    if (isAvonMexico) {
      return HOTJAR_ID.AVON.MEXICO.PROD
    }

    return
  }

  if (isHomologationEnvironment) {
    if (isAvonMexico) {
      return HOTJAR_ID.AVON.MEXICO.HML
    }

    return
  }
}

export const setHotjarConfig = ({ companyId }) => {
  removePreviousHotjar()
  const hotjarId = getHotjarIdFromTenantAndEnvironment({ companyId })

  if (hotjarId) {
    injectHotjarScript({ hotjarId })
  }
}
