import React from 'react'
import PropTypes from 'prop-types'
import './Link.styl'

const Link = ({children, href}) => {
  return <a href={href} className={'link'}>
    {children}
  </a>
}

Link.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
}

export default Link
