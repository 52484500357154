import { messages } from '../Messages'
import { connect } from 'react-redux'
import Select from '../../../../base/Form/Components/Select/Select'
import TagManager from 'react-gtm-module'
import { getLevel3Options } from './Level3.arg'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'level2',
    label: intl.formatMessage(messages['level2']),
    placeholder: intl.formatMessage(messages['level2_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['level_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    showAsterisk: false,
    htmlProps: { autoFocus: false },
    disabledBy: ['address[0].level1'],
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['level2']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

export function validateFieldDependencies({
  setFieldValue,
  setFieldTouched,
  schema
}) {
  const {
    group: { id },
    parent
  } = schema

  setFieldValue(`${parent}.level3`, '', id)
  setFieldValue(`${parent}.level4`, '', id)

  setFieldTouched(`${parent}.level3`, false)
  setFieldTouched(`${parent}.level4`, false)
}

export const getLevel2Options = ({ value, getLevels }) => {
  if (!value) return

  return getLevels({
    geoStructureType: 'cities',
    queryParams: `level=2&parentCode=${value}`,
    geoStructureCode: value
  })
}

const called = {}

function mapStateToProps({ addresses }, ownProps) {
  const {
    addressesPeopleManagement: { cities: level2Options, getLevels },
    loading,
  } = addresses

  const {
    group: { index },
    form: {
      values: { address }
    },
    onChange
  } = ownProps

  const { level1 } = address[index] || {}
  const options = level2Options[level1] || []

  const shouldGetLevel2Options = !options.length && level1 && !loading.cities?.[level1]

  if (shouldGetLevel2Options && !called[level1]) {
    called[level1] = true

    getLevel2Options({ value: level1, getLevels })
  }

  return {
    ...ownProps,
    onChange: (value, label) => {
      getLevel3Options({ selectedLevel2Id: value, getLevels })
      onChange(value, label)
    },
    options: options || [],
    loading: Boolean(level1 && loading.cities && loading.cities[level1])
  }
}
