import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const COMMERCIAL_STRUCTURE = {
  GM: 1,
  GV: 3,
  SECTOR: 4,
  GROUP: 5,
}

export default class SuggestedSalesHierarchy extends Entity {
  static SCHEMA = {
    id: adapter(yup.number().nullable()),
    salesHierarchyCycle: adapter(yup.string().nullable()),
    typeId: adapter(yup.number().nullable()),
    updatedAt: adapter(yup.date().nullable())
  }

  get group() {
    const { typeId, id } = this

    return typeId === COMMERCIAL_STRUCTURE.GROUP && id
  }
}
