import * as yup from 'yup'
import { defineMessages } from 'react-intl'

import { Regions } from './State.list'

const messages = defineMessages({
  region_label: {
    defaultMessage: 'Regions',
    id: 'form.field.regions.label'
  },
  region_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.regions.placeholder'
  },
  region_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.regions.no.options.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { documents } = {} } = injection
  const { extraInfos } = documents && documents.find(({ type }) => type === 2) || {}
  const { extraInfo: defaultValue } = extraInfos && extraInfos.find(({ type }) => type === 'UF') || {}

  const options = Object.entries(Regions).map(createOptions)

  return {
    id: 'region',
    label: intl.formatMessage(messages['region_label']),
    placeholder: intl.formatMessage(messages['region_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['region_no_options_message']),
    validations: yup.string(),
    type: 'select',
    defaultValue,
    options
  }
}

function createOptions([key, value]) {
  const label = Regions[key]

  return { value, label }
}


