import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'
import { DOCUMENTS } from 'src/models/Document'
import { isMalaysiaFlowToggleActive } from 'src/config'
import { getDocumentsTypeByLocale } from './DocumentsType/strategies/Documents.type'

const messages = defineMessages({
  type_label: {
    defaultMessage: 'Document',
    id: 'form.field.document.type'
  },
  type_citizen: {
    defaultMessage: 'Citizen Card',
    id: 'form.field.document.type.citizen'
  },
  type_foreign: {
    defaultMessage: 'Foreign Card',
    id: 'form.field.document.type.foreign'
  },
  type_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.document.type.placeholder'
  },
  type_dni: {
    defaultMessage: 'National Registry',
    id: 'form.field.document.type.dni'
  },
  type_ce: {
    defaultMessage: 'Foreign Registry',
    id: 'form.field.document.type.cd'
  },
  type_passport: {
    defaultMessage: 'Passport',
    id: 'form.field.document.type.ptp'
  },
  type_mykad: {
    defaultMessage: 'MyKad',
    id: 'form.field.document.type.mykad'
  },
  type_passport_malaysia: {
    defaultMessage: 'Passport',
    id: 'form.field.document.type.passportMalaysia'
  },
  type_candidate: {
    defaultMessage: 'Candidate',
    id: 'form.field.document.type.candidate'
  },
  type_rut: {
    defaultMessage: 'RUT',
    id: 'form.field.document.type.rut'
  },
  type_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.type.no.options.message'
  }
})

const dependencies = {
  messages
}

const malaysiaDocumentsEnum = {
  [DOCUMENTS.MYKAD]: 'myKad',
  [DOCUMENTS.PASSPORT_MALAYSIA]: 'passportMalaysia'
}

export const documentsEnum = {
  [DOCUMENTS.CITIZEN_CARD]: 'citizenCard',
  [DOCUMENTS.FOREIGN_CARD]: 'foreignCard',
  [DOCUMENTS.NATIONAL_REGISTRY]: 'nationalRegistry',
  [DOCUMENTS.FOREIGN_REGISTRY]: 'foreignRegistry',
  [DOCUMENTS.PASSPORT_PERU]: 'temporaryPermissionWork',
  [DOCUMENTS.RUT]: 'rut',
  [DOCUMENTS.DNI_ARGENTINA]: 'dni',
  ...(isMalaysiaFlowToggleActive() && malaysiaDocumentsEnum)
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id } = {} } = injection
  const options = defineOptionByLocale(intl)[id] || []

  return {
    id: 'type',
    label: intl.formatMessage(messages['type_label']),
    placeholder: intl.formatMessage(messages['type_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['type_no_options_message']),
    type: 'select',
    onChange: cleanDocument,
    options
  }
}

export function defineOptionByLocale(intl) {

  return {
    ARG: getDocumentsTypeByLocale('ARG', { messages, intl }),
    CHL: getDocumentsTypeByLocale('CHL', { messages, intl }),
    COL: getDocumentsTypeByLocale('COL', { messages, intl }),
    MYS: getDocumentsTypeByLocale('MYS', { messages, intl }),
    PER: getDocumentsTypeByLocale('PER', { messages, intl })
  }
}

export function cleanDocument(props) {
  const {
    schema: {
      options,
      parent,
      group: { id }
    },
    setFieldValue,
    setFieldTouched,
    value
  } = props

  const documentType = options.filter(
    (documentType) => documentType.value === value
  )[0]

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationDocuments',
      document: documentType.label
    }
  })

  setFieldValue(`${parent}.document`, '', id)
  setFieldTouched(`${parent}.document`, false)
}
