export function createSearchConfig(dependencies) {
  const DOCUMENTS = Builder(dependencies)

  return {
    fieldsSchema: [
      [DOCUMENTS]
    ],
    onSubmit: () => { }
  }
}

export default function Builder(dependencies) {

  const SECTOR = createSector(dependencies)
  const GROUP = createGroup(dependencies)
  const DOCUMENT = createDocument(dependencies)

  return {
    id: 'documents',
    min: 1,
    max: 1,
    defaultValue: [{}],
    fields: [
      [SECTOR, GROUP, DOCUMENT]
    ]
  }
}

function createSector(dependencies) {
  const { intl, messages } = dependencies

  return {
    id: 'sector',
    placeholder: intl.formatMessage(messages.search_document_sector),
  }
}

function createGroup(dependencies) {
  const { intl, messages } = dependencies

  return {
    id: 'group',
    placeholder: intl.formatMessage(messages.search_document_group),
  }
}

function createDocument(dependencies) {
  const { intl, messages } = dependencies

  return {
    id: 'document',
    placeholder: intl.formatMessage(messages.search_document_cpf),
  }
}
