import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Indication from './Indication'
import messages from './Indication.messages'

export default class IndicationError extends Component {
  get errorHasIndication() {
    const { intl, hasIndication } = this.props
    const message = intl.formatMessage(messages.indication_error_required)

    return hasIndication === undefined ? message : null
  }

  get errorSearchType() {
    const { id, intl, customProps, form, hasIndication, searchType, disabledTypeSearch, required } = this.props
    const { hiddenIndicationQuestion, toucheds } = { ...form, ...customProps }

    const isEmpty = !searchType && toucheds[id]
    const isRegistrantRequired = hiddenIndicationQuestion && required
    const isSearchTypeRequired = hasIndication || isRegistrantRequired
    const hasSearchTypeError = isSearchTypeRequired && isEmpty && !disabledTypeSearch

    return hasSearchTypeError ? intl.formatMessage(messages.indication_error_required) : null
  }

  get errorSearch() {
    const { intl, form: { toucheds }, customProps, id, documentValidation } = this.props
    const { searchType, search, hasIndication, disabledSearch, changeIntroducer } = this.props
    const { hiddenIndicationQuestion} = customProps


    const isSearchTypeRequired = hasIndication || hiddenIndicationQuestion
    const isEmpty = isSearchTypeRequired && searchType && !search
    const touched = toucheds[id] && !disabledSearch

    const isRequired = isEmpty && touched
    const hasSizeIncorrect = documentValidation.error && touched
    const noSelectedIndication = isSearchTypeRequired && !changeIntroducer && touched

    if (isRequired) return intl.formatMessage(messages.indication_error_required)
    if (hasSizeIncorrect) return intl.formatMessage(messages.indication_search_error_size, documentValidation.size)
    if (noSelectedIndication) return intl.formatMessage(messages.indication_search_error)

    return null
  }

  render() {
    return (
      <Indication
        {...this.props}
        errorHasIndication={this.errorHasIndication}
        errorSearchType={this.errorSearchType}
        errorSearch={this.errorSearch}
      />
    )
  }
}

IndicationError.propTypes ={
  intl: PropTypes.object,
  hasIndication: PropTypes.bool,
  disabledTypeSearch: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  customProps: PropTypes.object,
  documentValidation: PropTypes.object,
  form: PropTypes.object,
  searchType: PropTypes.string,
  search: PropTypes.string,
  disabledSearch: PropTypes.bool,
  changeIntroducer: PropTypes.bool,
}
