import { COMPANY_ID } from '../../models/Company'
import { themes } from '@naturacosmeticos/natds-web'
import { enableThemeElo } from '../eloTheme'
import { buildTheme } from '@naturacosmeticos/natds-react'

export const getTheme = (companyId) => {
  const company = companyId ?? COMPANY_ID.NATURA 

  if (enableThemeElo()) {
    const beautyConsultantTheme = buildTheme('consultoriaDeBeleza', 'light')
    const theme = themeMap(themes.natura.light, beautyConsultantTheme)
    return theme
  }

  if (company === COMPANY_ID.AVON) {
    const avonTheme = buildTheme('avon_v2', 'light')
    const theme = themeMap(themes.avon.light, avonTheme)
    return theme
  }

  return company === COMPANY_ID.NATURA ? themes.natura.light : themes.avon.light
}
 
export const themeMap = (themeWeb, themeReact) => {
  themeWeb.size = themeReact.size
  themeWeb.asset = themeReact.asset
  themeWeb.color = { ...themeWeb.color, ...themeReact.color }
  themeWeb.palette.primary.main = themeReact.color.primary
  themeWeb.palette.primary.dark = themeReact.color.primaryDark
  themeWeb.palette.secondary.main = themeReact.color.secondary
  return themeWeb
}