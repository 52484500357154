import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'

import { CONFIGS } from 'src/models/Attachment/Attachment'

export function buildDocuments(deps) {
  const { title, subtitle, label } = getSectionTitle(deps)
  const { loader: { personLoading, uploadLoading } } = deps

  const {
    proofOfAddress,
    termAndConditions,
    self
  } = CONFIGS.PER

  return {
    formSchema: buildFormSchema({ ...deps }),
    onSubmit: onSubmition(deps),
    touchAfterReload,
    step: {
      title,
      subtitle,
      label
    },
    loader:
      !!personLoading
      || !!uploadLoading[proofOfAddress]
      || !!uploadLoading[termAndConditions]
      || !!uploadLoading[self]
  }
}
