import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import { getFieldsSchema } from './strategies/getFieldsSchema'

export const touchAfterReload = [
  'emails[0].email'
]

export function buildFormSchema(dependencies) {
  const showAsterisk = false
  const { intl,
    locale: { id: localeId }
  } = dependencies

  const NAME_FULL = commonParams(TYPES.NAME_FULL(intl, dependencies))
  const PHONES = commonParams(TYPES.PHONES(intl, { ...dependencies, showAsterisk }))
  const EMAIL = commonParams(TYPES.EMAIL(intl, {...dependencies, showAsterisk}))

  const defaultFieldsSchema = [[NAME_FULL], [PHONES], [EMAIL]]
  const additionalFieldsSchema = getFieldsSchema(localeId, {
    intl,
    dependencies
  })
  const fieldsSchema = [...defaultFieldsSchema, ...additionalFieldsSchema]

  return new FormSchema({ fieldsSchema, name: 'PersonalInfo' })
}

function commonParams(field, required = true, showAsterisk = false) {
  return {
    ...field,
    required,
    showAsterisk
  }
}
