import { useHistory } from 'react-router-dom'
import { useEffect } from "react"

let currentLocation = {}

export const usePreventBrowserBackButton = () => {
  const history = useHistory()

  useEffect(() => {
    pushLocation(history.location, history)

    const unregisterHistoryListener = history.listen(historyListener)

    return () => {
      unregisterHistoryListener()
    }

    function pushLocation(location) {
      history.push({
        ...location
      })
    }

    function historyListener(newLocation, action) {
      if (
        action === "PUSH" &&
        isDifferentLocation(currentLocation, newLocation)
      ) {
        currentLocation = newLocation
        pushLocation(newLocation)
      } else {
        history.go(1)
      }

      function isDifferentLocation(currentLocation, newLocation) {
        return (
          newLocation.pathname !== currentLocation.pathname ||
          newLocation.search !== currentLocation.search
        )
      }
    }
  }, [])

  return { history }
}

