import ListSchema from 'src/viewModels/ListSchema'
import { buildSteps } from 'src/configs/Locales/DEFAULT/views/Register'
import { APP_ROUTES } from 'src/App'

import { createItemsSchema } from './Items'
import { createItemsOfflineSchema } from './Items/ItemsOffline'
import { createSearchConfig } from './Search'
import { createFiltersSchema } from './Filters'
import { getUserRole } from '../../../../../models/User/strategies/userRoles'

export const touchAfterReload = ['documentTypeId']

export function MyRegistersSchemaBuilder(dependencies) {
  const items = createItemsSchema(dependencies)
  const itemsOffline = createItemsOfflineSchema(dependencies)
  const search = createSearchConfig(dependencies)
  const filters = createFiltersSchema(dependencies)

  return new ListSchema({
    items,
    itemsOffline,
    search,
    filters
  })
}

export function parsePerson(person) {
  const { cnoFallbackMessage = '', personPagePath = '' } = this
  const {
    name,
    origin,
    personId,
    roles: {
      cn: {
        startDate
      }
    },
    approvedCandidate: {
      candidateId
    }
  } = person

  const path = createLink(personPagePath, personId)
  const rolesByTenant = getUserRole()

  return {
    avatar: {
      label: name,
      path
    },
    date_house: {
      label: startDate,
      path
    },
    origin: {
      label: origin === rolesByTenant.user.SELF_REGISTRATION ? cnoFallbackMessage : origin,
      path
    },
    cnCode: {
      label: candidateId,
      path
    }
  }
}

export function parsePersonOffline(peopleRegister) {
  const { personPagePath = '', connection } = this
  const { name, personId, documents, draft, reviewStep,
    businessModel: { businessModelAsUrl }
  } = peopleRegister

  const pathProfile = createLink(personPagePath, personId)
  const pathRegister = editOffline()

  function editOffline() {
    const isReview = draft && !connection

    if (isReview) {
      const step = reviewStep.step || 'pre-register'
      const basePath = APP_ROUTES.REGISTER
      const businessModelParams = businessModelAsUrl

      const { path } = buildSteps({ basePath, businessModelParams }).find(findStep, { step })
      return `${path}/${personId}`
    }

    if (businessModelAsUrl)
      return `/dashboard/register/${businessModelAsUrl}/personal-data/${personId}`

    return `/dashboard/register/business-model/${personId}`
  }

  return {
    register_name: {
      label: name,
      path: pathProfile
    },
    register_document: {
      label: documents[0].document,
      path: pathProfile
    },
    register_review: {
      label: 'edit',
      path: pathRegister,
      connection
    },
    register_delete: {
      label: 'stop',
      personId
    }
  }
}

function createLink(path, id) {
  return `${path}/${id}`
}

function findStep({ name }) {
  return name === this.step
}
