import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'

import { isCurrentLocationOnRegisterPage } from 'src/helpers/app-history'
import { parseApiDate, atLeast18Years, noMoreThan100Years } from 'src/lib/SpeckAdapter/Yup.customs'
import { isDefaultFlowToggleActive } from '../../../config'

const messages = defineMessages({
  birthday: {
    defaultMessage: 'Date of birth',
    id: 'form.field.birthday'
  },
  birthday_error_invalid: {
    defaultMessage: 'Invalid date',
    id: 'form.field.birthday.error.invalid'
  },
  birthday_error_min: {
    defaultMessage: 'You need to be less than 100 years old',
    id: 'form.field.birthday.error.min'
  },
  birthday_error_max: {
    defaultMessage: 'You must be over 18 years old',
    id: 'form.field.birthday.error.max'
  },
  birthday_placeholder: {
    defaultMessage: 'MM/DD/YYYY',
    id: 'form.field.birthday.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    person: { birthday },
    locale: { configs: { localization: { date: { format } } } },
    customProps
  } = injection

  const defaultValue = birthday ? parseApiDate(birthday, format) : ''
  const showAsterisk = !isDefaultFlowToggleActive() || (isDefaultFlowToggleActive() && isCurrentLocationOnRegisterPage())

  const pushDataLayer = (values) => {
    const { value } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationDocumentationData',
        fieldName: intl.formatMessage(messages['birthday']),
        value,
      }
    })
  }

  return {
    id: 'birthday',
    defaultValue,
    label: intl.formatMessage(messages['birthday']),
    placeholder: intl.formatMessage(messages['birthday_placeholder']),
    mask: '99/99/9999',
    onChange: handleBirthdayChange,
    customProps,
    showAsterisk,
    onBlur: pushDataLayer,
    validations: yup
      .date()
      .format(format)
      .typeError(intl.formatMessage(messages['birthday_error_invalid']))
      .min(noMoreThan100Years, intl.formatMessage(messages['birthday_error_min']))
      .max(atLeast18Years, intl.formatMessage(messages['birthday_error_max']))
  }
}


function handleBirthdayChange(props) {
  const { runFieldValidation } = props

  runFieldValidation('birthday')
  runFieldValidation('admissionDate')
}
