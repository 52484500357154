import { getContext } from 'src/infra/Api/Api.context'
import Ocr from 'src/viewModels/Ocr'

export const OCR_RESET = 'natura-caduni/OCR_RESET'

export function ocrResetReducer(state, action, dependencies) {
  return {
    ...state,
    ocr: new Ocr({}, dependencies),
    error: false,
    loading: false
  }
}

export function resetOcr() {
  const { dispatch } = getContext()

  return dispatch({
    type: OCR_RESET
  })
}
