import { useState, useEffect } from "react"

import { getDigitalStoreStatus } from 'src/infra/Api/DEFAULT/registers-api/get-digital-store-status'


export const useGetDigitalStoreStatus = ({ countryId, lang, personIds, companyId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    loadDigitalStoreStatus()

    async function loadDigitalStoreStatus() {
      setIsLoading(true)
      const responseData = await getDigitalStoreStatus({ countryId, lang, personIds, companyId })
      setData(responseData)
      setIsLoading(false)
    }
  }, [])

  return {
    isLoading,
    data: data[personIds] || {}
  }
}
