import { PAYMENT_PLANS, SORT, SOURCE_SYSTEM } from './FilterOptions'

import { translateRoleOptions } from 'src/configs/Fields/BusinessModel/RoleId'
import { translateFunctionOptions } from 'src/configs/Fields/BusinessModel/FunctionId'

const getFilterDefaultValues = filters => ({
  businessModelId: filters.businessModelId ?? [],
  functionId: filters.functionId ?? [],
  rolesId: filters.rolesId ?? [],
  substatus: filters.substatus ?? [],
  status: filters.status ?? [],
  paymentPlan: filters.paymentPlan ?? [],
  sourceSystem: filters.sourceSystem ?? [],
})

const getSearchFields = (dependencies, filters) => {
  const {
    intl,
    messages,
    businessModel: { businessModel },
    locale: {
      configs: {
        localization: {
          hasLego
        }
      }
    },
  } = dependencies

  const {
    businessModelId,
    functionId,
    rolesId,
    substatus,
    status,
    paymentPlan,
    sourceSystem
  } = getFilterDefaultValues(filters)

  const businessModelField = {
    id: 'businessModelId',
    placeholder: intl.formatMessage(messages.filters_label_business_model_id),
    defaultValue: businessModelId,
    isMulti: true,
    options: businessModel.items
  }
  const rolesField = {
    id: 'rolesId',
    placeholder: intl.formatMessage(messages.filters_label_business_model_roles_id),
    defaultValue: rolesId,
    isMulti: true,
    hasParent: true,
    disabledBy: ['businessModelId'],
    options: getRoles(dependencies).sort(sort)
  }
  const functionField = {
    id: 'functionId',
    placeholder: intl.formatMessage(messages.filters_label_business_model_function_id),
    defaultValue: functionId,
    isMulti: true,
    hasParent: true,
    disabledBy: ['rolesId'],
    options: getFunctions(dependencies).sort(sort)
  }
  const statusField = {
    id: 'status',
    placeholder: intl.formatMessage(messages.filters_label_status),
    defaultValue: status,
    isMulti: true,
    options: getStatus(dependencies).sort(sort)
  }
  const substatusField = {
    id: 'substatus',
    placeholder: intl.formatMessage(messages.filters_label_substatus),
    defaultValue: substatus,
    hasParent: true,
    isMulti: true,
    options: getSubstatus(dependencies).sort(sort)
  }
  const paymentPlanField = {
    id: 'paymentPlan',
    placeholder: intl.formatMessage(messages.filters_label_payment_plan),
    defaultValue: paymentPlan,
    isMulti: true,
    options: getPayments(dependencies).sort(sort)
  }
  const sourceSystemField = {
    id: 'sourceSystem',
    placeholder: intl.formatMessage(messages.filters_label_source_system),
    isMulti: false,
    defaultValue: sourceSystem,
    options: getSourceSystem(dependencies).sort(sort)
  }
  if (hasLego) {
    return [
      businessModelField,
      rolesField,
      functionField,
      statusField,
      substatusField,
      paymentPlanField,
      sourceSystemField
    ]
  }
  return [
    statusField,
    substatusField,
    paymentPlanField,
    sourceSystemField
  ]
}

export function createFiltersSchema(dependencies) {
  const {
    intl,
    messages,
    user,
    people,
  } = dependencies

  const { queryParams: { filters } } = people

  let _sort = []

  if (filters._sort && filters._sort.length) {
    _sort = filters._sort
  }

  const fields = [
    {
      title: intl.formatMessage(messages.filters_title_list_view),
      fields: [{
        id: '_sort',
        placeholder: intl.formatMessage(messages.filters_label_order),
        isMulti: false,
        isDefault: true,
        fallbackValue: [{ value: SORT.REGISTRATION_DATE_DESC }],
        defaultValue: _sort,
        options: getSort(dependencies)
      }]
    },
    {
      title: intl.formatMessage(messages.filters_title_list_filter),
      fields: getSearchFields(dependencies, filters)
    }
  ]

  return {
    fields,
    custom: getCustomFilters(user)
  }
}

function getCustomFilters(user) {
  return function ({ sourceSystem }) {
    const { value } = (sourceSystem && sourceSystem.length ? sourceSystem[0] : sourceSystem) || {}
    const registerByMe = value === SOURCE_SYSTEM.REGISTERED_BY_ME

    if (registerByMe) return getMyRegistersFilter(user)

    return { registrationResponsible: null }
  }
}

function getMyRegistersFilter({ sellerId }) {
  return {
    registrationResponsible: formatCustomFilter(sellerId),
    leaderId: [],
    salesHierarchyId: [],
    salesHierarchyLevelId: []
  }
}

function formatCustomFilter(value) {
  return [{ value }]
}

function getSort({ intl, messages }) {
  return [
    { value: SORT.NAME_ASC, label: 'A-Z' },
    { value: SORT.NAME_DESC, label: 'Z-A' },
    {
      value: SORT.REGISTRATION_DATE_DESC,
      label: intl.formatMessage(messages.filters_option_order_NewestToOldest)
    },
    {
      value: SORT.REGISTRATION_DATE_ASC,
      label: intl.formatMessage(messages.filters_option_order_OldestToNewest)
    },
  ]
}

function getPayments({ intl, messages }) {
  return [
    {
      value: PAYMENT_PLANS.CREDIT_PAYMENT,
      label: intl.formatMessage(messages.filters_options_payment_credit)
    },
    {
      value: PAYMENT_PLANS.PREPAID_PAYMENT,
      label: intl.formatMessage(messages.filters_options_payment_prepaid)
    }
  ]
}

function getStatus({ intl, messages, filters: { filters } }) {
  const deps = { filterType: 'status', intl, messages }

  return filters.status.reduce(translateReduce, [deps])
}

function getRoles({ intl, messages, businessModel: { businessModel } }) {
  const deps = {
    messages,
    filterType: 'filters_option_role',
    intl
  }

  return businessModel.items
    .reduce(reduceRoles, [])
    .map(translateRoleOptions, deps)
}

function reduceRoles(accum, { roles }) {
  return !roles.length ? accum : accum.concat(roles)
}

function getFunctions({ intl, messages, businessModel: { businessModel } }) {
  const deps = {
    messages,
    filterType: 'filters_option_function',
    intl
  }

  return businessModel.items
    .reduce(reduceFunctions, [])
    .map(translateFunctionOptions, deps)
}

function reduceFunctions(accum, { roles }) {
  if (!roles.length) return accum

  const functions = roles.reduce(reduceFunctionOptions, [])

  return accum.concat(functions)
}

function reduceFunctionOptions(accum, { functions }) {
  return !functions.length ? accum : accum.concat(functions)
}

function getSourceSystem({ intl, messages }) {
  return [
    {
      value: SOURCE_SYSTEM.THIRD_PARTY_REGISTER,
      exclude: true,
      label: intl.formatMessage(messages.filters_option_source_third)
    },
    {
      value: SOURCE_SYSTEM.REGISTERED_BY_ME,
      exclude: true,
      label: intl.formatMessage(messages.filters_option_source_me)
    }
  ]
}

function getSubstatus({ intl, messages, filters: { filters } }) {
  const deps = { filterType: 'substatus', intl, messages }

  return filters.substatus.reduce(translateReduce, [deps])
}

function sort(a, b) {
  if (!a.label || !b.label) return

  return a.label.localeCompare(b.label)
}

function translateReduce(accum, item, index, array) {
  const [{ filterType, intl, messages }] = accum
  const { label, value, parent } = item

  const lastItem = index === array.length - 1
  const message = messages[`filters_option_${filterType}_${label}`]

  if (lastItem) accum.shift()

  if (!message) return [...accum, { parent, label, value }]

  return [...accum, { parent, value, label: intl.formatMessage(message) }]
}
