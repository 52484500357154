function menu(base, state) {
  const style = {
    bottom: {
      marginTop: '-.2rem',
      borderRadius: '0 0 .4rem .4rem!important'
    },
    top: {
      marginBottom: '-.4rem',
      borderRadius: '.4rem .4rem 0 0'
    }
  }

  return {
    ...base,
    ...style[state.placement]
  }
}

function dropdownIndicator(base, state) {
  return {
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
  }
}

export const STYLES = { menu, dropdownIndicator }
