export default {
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/natura.br/',
      label: 'Facebook Natura'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/naturabemestarbem',
      label: 'Youtube Natura'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/naturanet',
      label: 'Twitter Natura'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/naturabrasil/',
      label: 'Instagram Natura'
    },
    {
      type: 'vimeo',
      link: 'https://vimeo.com/user1804201',
      label: 'Vimeo Natura'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'https://play.google.com/store/apps/details?id=net.natura.cn',
      label: 'Baixar na Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'https://itunes.apple.com/br/app/consultoria-natura/id1069858846?mt=8',
      label: 'Baixar na AppStore',
      image: require('src/assets/images/app-store.png')
    }
  ]
}
