import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { classNameBuilder } from 'src/base/Form/Form'
import { get } from 'lodash'

import FormField from 'src/base/Form/Form.Field'

export class FormGroup extends Component {
  constructor(props) {
    super(props)

    this.onRemove = this.onRemove.bind(this)
    this.onAdd = this.onAdd.bind(this)
  }

  onAdd(event) {
    event && event.preventDefault()

    const { form: { addItemToGroup }, schema } = this.props

    addItemToGroup(schema)
  }

  onRemove(event) {
    event && event.preventDefault()

    const { form: { removeItemFromGroup }, schema } = this.props

    return removeItemFromGroup(schema)
  }

  render() {
    const { className, schema: { id, title, disabled, list } } = this.props
    const idClass = id && id.toLowerCase()

    const classes = classNameBuilder(className, 'fieldset', {
      ['group']: true,
      [idClass]: !!idClass,
      disabled
    })

    return (
      <div className={classes} key={id} data-testid={id}>
        {title && title.node}
        {list.map(this.renderGroupItem, this)}
        {this.renderGroupsActions()}
      </div>
    )
  }

  renderGroupItem(groupItem, key) {
    const { className, schema: { itemTitle } } = this.props
    const classes = classNameBuilder(className, 'fieldset', ['group-item'])

    if(!Array.isArray(groupItem) || !groupItem.length) return null

    this.index = key

    return (
      <div key={key} className={classes}>
        {this.renderItemTitle(itemTitle, key)}
        {groupItem.map(this.renderGroupFieldset, this)}
      </div>
    )
  }

  renderItemTitle(item, index) {
    const { value } = this.props

    if(!item || !item.node) return null

    return React.cloneElement(item.node, { value, index })
  }

  renderGroupFieldset(fields, key) {
    const { className } = this.props
    const classes = classNameBuilder(className, 'fieldset', ['group-field'])

    if(!Array.isArray(fields) || !fields.length) return null

    return (
      <div key={key} className={classes}>
        {fields.map(this.renderField, this)}
      </div>
    )
  }

  renderField(fieldSchema, key) {
    const {
      className,
      schema,
      value,
      touched,
      form,
      error,
      offline
    } = this.props

    const comparator = [this.index, fieldSchema.id]
    const errors = error && (get(error.fields, comparator) || error.group)

    return (
      <FormField
        key={key}
        offline={offline}
        schema={fieldSchema}
        groupSchema={schema}
        className={className}
        form={form}
        value={value && get(value, comparator)}
        error={errors}
        touched={touched && get(touched, comparator)}
      />
    )
  }

  renderGroupsActions() {
    const { className, schema } = this.props
    const { canAdd, canRemove } = schema

    if(!canAdd && !canRemove) return null

    return (
      <div className={classNameBuilder(className, 'fieldset', 'group-actions')}>
        {canRemove && this.renderRemoveButton()}
        {canAdd && this.renderAddButton()}
      </div>
    )
  }

  renderAddButton() {
    const { schema: { addButton: { id, node, disabled, hidden } }, value, form, schema  } = this.props

    return (
      React.cloneElement(node, {
        id,
        key: 'add',
        onClick: this.onAdd,
        form,
        schema,
        value,
        disabled,
        hidden
      })
    )
  }

  renderRemoveButton() {
    const { schema: { removeButton: { id, node, disabled, hidden } }, value, form, schema } = this.props

    return (
      React.cloneElement(node, {
        id,
        key: 'remove',
        onClick: this.onRemove,
        form,
        schema,
        value,
        disabled,
        hidden
      })
    )
  }
}

FormGroup.propTypes = {
  className: PropTypes.string,
  schema: PropTypes.object.isRequired,
  success: PropTypes.bool,
  value: PropTypes.array,
  error: PropTypes.object,
  touched: PropTypes.array,
  offline: PropTypes.bool,
  form: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    toucheds: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldError: PropTypes.func,
    runFieldValidation: PropTypes.func,
    addItemToGroup: PropTypes.func,
    removeItemFromGroup: PropTypes.func
  })
}

export default FormGroup
