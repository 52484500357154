import {DocumentFieldsMapper} from './DocumentFieldsMapper'
import {ATTACHMENT_TYPES} from '../../../../../../../AttachmentTypes'
import {DOCUMENTS} from '../../../../../../../../models/Document/Document'

export const MESSAGES_ID = {
  [DOCUMENTS.DNI_ARGENTINA]: 'box_documents_label_document_dni',
}

const attachmentTypeToMessageIdsEnum = {
  [ATTACHMENT_TYPES.DNI_ARGENTINA_FRONT]: 'box_documents_label_document_dni_front',
  [ATTACHMENT_TYPES.DNI_ARGENTINA_BACK]: 'box_documents_label_document_dni_back',
}

export const getDocumentsFields = ({ intl, nationalityId, messages, documents, attachments }) => {
  const documentFieldsMapper = new DocumentFieldsMapper({
    intl,
    messagesId: MESSAGES_ID,
    attachmentTypeToMessageIdsEnum
  })
  const nationality = documentFieldsMapper.mapNationalityField({ nationalityId, messages })
  const documentsFields = documentFieldsMapper.mapDocuments({ documents, messages })
  const attachmentsField = documentFieldsMapper.mapAttachmentsField({ attachments, messages, documentsFields })
  const termsField = documentFieldsMapper.mapTermsField({ attachments, messages })

  return [nationality, ...documentsFields, attachmentsField, termsField]
}
