import { Collection } from 'speck-entity'
import Person from 'src/models/Person'

export default class PeopleRegister extends Collection {
  static TYPE = Person

  constructor(props, dependencies = {}) {
    super(props, dependencies)

    Object.assign(this, dependencies)

    this.save = this.save.bind(this)
    this.remove = this.remove.bind(this)
  }

  save(data) {
    const existentPerson = this.get(data)

    if(existentPerson) {
      Object.assign(existentPerson, data)

      return this
    }

    this.items.push(new Person(data))

    return this
  }

  remove({ personId }) {
    return this.reject(findPerson({ personId }))
  }

  get({ personId }) {
    return this.find(findPerson({ personId }))
  }

  draft(data) {
    return this.filter(findPerson({ ...data, draft: true }))
  }
}

function findPerson(data) {
  return data
}


