import { defineMessages } from 'react-intl'

const messages = defineMessages({
  state_label: {
    defaultMessage: 'State',
    id: 'form.field.state.label'
  },
  state_placeholder: {
    defaultMessage: 'Insert state here',
    id: 'form.field.state.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'state',
    label: intl.formatMessage(messages['state_label']),
    placeholder: intl.formatMessage(messages['state_placeholder']),
    type: 'input',
    required: true,
    showAsterisk: false
  }
}
