import Person from 'src/models/Person'
import Error from 'src/viewModels/Error'

import { getContext } from 'src/infra/Api/Api.context'

import { getPerson, PROFILE_GET_SUCCESS, PROFILE_GET_LOADING, PROFILE_GET_ERROR } from './Profile.get'
import { getProfileLocal, PROFILE_GET_LOCAL } from './Profile.getLocal'
import { personResetReducer, resetPerson, types as resetPersonTypes } from './Profile.reset'
import { savePerson, savePersonOnState, PROFILE_SAVE_SUCCESS, PROFILE_SAVE_ERROR, PROFILE_SAVE_STATE } from './Profile.save'
import { createPersonRole, PROFILE_CREATE_SUCCESS, PROFILE_CREATE_LOADING, PROFILE_CREATE_ERROR } from './Profile.createPersonRole'
import { savePersonContactInformation, PROFILE_SAVE_CONTACT_INFORMATION_LOADING, PROFILE_SAVE_CONTACT_INFORMATION_SUCCESS, PROFILE_SAVE_CONTACT_INFORMATION_ERROR } from './Profile.saveContactInformation'

import { ceaseRole, PROFILE_CEASE_LOADING, PROFILE_CEASE_SUCCESS, PROFILE_CEASE_ERROR } from './Profile.cease'
import { revalidateProfile, PROFILE_REVALIDATE_SUCCESS, PROFILE_REVALIDATE_LOADING } from './profile.revalidate'

import { getRejectionReasons, PROFILE_REJECTION_REASONS_SUCCESS, PROFILE_REJECTION_REASONS_LOADING } from './Profile.rejectionReasons'
import { rejectPerson, PROFILE_REJECTION_SUCCESS, PROFILE_REJECTION_LOADING } from './Profile.rejection'
import { approvePerson, PROFILE_APPROVATION_SUCCESS, PROFILE_APPROVATION_LOADING } from './Profile.approvation'
import { removePersonPendency, PROFILE_REMOVE_PENDENCY_SUCCESS, PROFILE_REMOVE_PENDENCY_LOADING, PROFILE_REMOVE_PENDENCY_ERROR } from './Profile.removePendency'

import { getCNOList, PROFILE_CNO_SUCCESS, PROFILE_CNO_LOADING } from './Profile.cnos'

import { personCompleteReducer, completePerson, types as completeTypes } from 'src/reducers/Person/Person.complete'

import {
  getConsultantsById,
  TYPES_ORIGEN,
  TYPES_LEADER,
  TYPES_INTRODUCER,
  TYPES_REGISTRANT,
  PROFILE_INTRODUCER_SUCCESS,
  PROFILE_REGISTRANT_SUCCESS,
  PROFILE_ORIGEN_SUCCESS,
  PROFILE_LEADER_SUCCESS,
  PROFILE_INTRODUCER_LOADING,
  PROFILE_REGISTRANT_LOADING,
  PROFILE_ORIGEN_LOADING,
  PROFILE_LEADER_LOADING,
} from './Profile.consultants'

export const PROFILE_DEPS_LOADED = 'natura-caduni/People/PROFILE_DEPS_LOADED'
export const PROFILE_ACTIVE_DEBT_ERROR = 'natura-caduni/People/PROFILE_ACTIVE_DEBT_ERROR'

const ADDITIONAL_INFORMATION_UPDATES = [
  PROFILE_INTRODUCER_SUCCESS,
  PROFILE_REGISTRANT_SUCCESS,
  PROFILE_ORIGEN_SUCCESS,
  PROFILE_LEADER_SUCCESS,
  PROFILE_REJECTION_SUCCESS,
  PROFILE_CNO_SUCCESS,
  PROFILE_REVALIDATE_SUCCESS,
  PROFILE_CEASE_SUCCESS,
  PROFILE_SAVE_CONTACT_INFORMATION_SUCCESS,
  PROFILE_APPROVATION_SUCCESS,
  PROFILE_REJECTION_REASONS_SUCCESS,
  PROFILE_REMOVE_PENDENCY_SUCCESS
]

const ADDITIONAL_INFORMATION_LOADING = [
  PROFILE_INTRODUCER_LOADING,
  PROFILE_REGISTRANT_LOADING,
  PROFILE_ORIGEN_LOADING,
  PROFILE_LEADER_LOADING,
  PROFILE_CNO_LOADING,
  PROFILE_REVALIDATE_LOADING,
  PROFILE_CEASE_LOADING,
]

const ADDITIONAL_INFORMATION_ERRORS = [
  PROFILE_GET_ERROR,
  PROFILE_SAVE_ERROR,
  PROFILE_CREATE_ERROR,
  PROFILE_CEASE_ERROR,
  PROFILE_SAVE_CONTACT_INFORMATION_ERROR,
  PROFILE_REMOVE_PENDENCY_ERROR,
  PROFILE_ACTIVE_DEBT_ERROR
]

const ADDITIONAL_INFORMATION_FIELDS = {
  [PROFILE_INTRODUCER_SUCCESS]: 'introducerSellerName',
  [PROFILE_REGISTRANT_SUCCESS]: 'registrantName',
  [PROFILE_ORIGEN_SUCCESS]: 'createdByName',
  [PROFILE_LEADER_SUCCESS]: 'leaderName'
}

const PROFILE_LOADING = [
  PROFILE_CREATE_LOADING,
  PROFILE_GET_LOADING,
  PROFILE_APPROVATION_LOADING,
  PROFILE_REJECTION_LOADING,
  PROFILE_REMOVE_PENDENCY_LOADING,
  PROFILE_REJECTION_REASONS_LOADING,
  PROFILE_SAVE_CONTACT_INFORMATION_LOADING
]

const ALLOWED_COMMERCIAL_SITUATIONS = [1, 2]
const ACTIVE_DEBT_CODE = 'PR-0010'

const dependencies = {
  getProfileLocal,
  getProfile,
  ceaseRole,
  savePerson,
  createPersonRole,
  resetPerson,
  savePersonContactInformation,
  revalidateProfile,
  savePersonOnState,
  rejectPerson,
  approvePerson,
  removePersonPendency,
  completePerson
}

const initialState = {
  person: new Person({}, dependencies),
  loading: true,
  error: false,
  depsLoading: true,
  cnoList: [],
  rejectionReasonList: []
}

// TODO: This should be all refactored using login approach
// (and it's completely tight up to colombia flow)
export default function reducer(state = initialState, action = {}, rootState) {
  const { peopleRegister: { peopleRegister } = {} } = rootState
  const { payload, type, error = {} } = action
  const fetched = type === PROFILE_GET_SUCCESS
  const fetchedLocal = type === PROFILE_GET_LOCAL
  const params = state.person

  let { cnoList, rejectionReasonList } = state
  let depsLoading = ADDITIONAL_INFORMATION_LOADING.includes(type)

  if (fetched) {
    const data = payload.data?.person || payload.data
    cnoList = []
    depsLoading = true

    Object.assign(params, data)
  }

  if (ADDITIONAL_INFORMATION_UPDATES.includes(type)) {
    const additionalInformation = updateInfo(payload, state, ADDITIONAL_INFORMATION_FIELDS[type])

    Object.assign(params, { additionalInformation })
  }

  if ([].concat(rejectPerson).includes(type))
    return rejectPerson(state, action, dependencies)

  if ([].concat(approvePerson).includes(type))
    return approvePerson(state, action, dependencies)

  if ([].concat(removePersonPendency).includes(type))
    return removePersonPendency(state, action, dependencies)

  if ([].concat(completeTypes).includes(type))
    return personCompleteReducer(state, action, dependencies)

  if (type === PROFILE_REJECTION_REASONS_SUCCESS) {
    const { data } = payload
    const { additionalInformation: { rejectionReasonId } } = params

    rejectionReasonList = data.items
      ? data.items.map(mapRejectionReason)
      : data.map(mapRejectionReason)

    if (rejectionReasonId) {
      const rejectionReasonName = rejectionReasonList.find(filterRejectReason, { rejectionReasonId })
      const additionalInformation = {
        ...params.additionalInformation,
        rejectionReasonName: rejectionReasonName && rejectionReasonName.label
      }

      Object.assign(params, { additionalInformation })
    }
  }

  if (fetchedLocal) {
    const { person: { personId } } = payload
    const data = peopleRegister.get({ personId })
    cnoList = []

    Object.assign(params, data)
  }

  if (type === PROFILE_SAVE_SUCCESS) {
    const { previousAction: { payload: { request: { data } = {} } = {} } = {} } = action.meta || {}

    data && Object.assign(params, data)
  }

  if (type === PROFILE_CNO_SUCCESS) {
    cnoList = payload.data.reduce(reduceComercialSituation, [])
  }

  if (type === PROFILE_DEPS_LOADED) {
    depsLoading = false
  }

  if (type === PROFILE_SAVE_STATE || type === PROFILE_CREATE_SUCCESS) {
    const { data } = payload

    Object.assign(params, { ...data })
  }

  if ([].concat(resetPersonTypes).includes(type))
    return personResetReducer(state, action, dependencies)

  if (type === PROFILE_ACTIVE_DEBT_ERROR) {
    Object.assign(error, { code: ACTIVE_DEBT_CODE, message: 'Person has debt' })
  }

  return {
    ...state,
    error: ADDITIONAL_INFORMATION_ERRORS.includes(type) && new Error(error),
    loading: PROFILE_LOADING.includes(type) && !fetched,
    person: new Person(params, dependencies),
    cnoList,
    rejectionReasonList,
    depsLoading
  }
}

async function getProfile(id, data, shouldGetCNOList, shouldVerifyDebt) {
  const { connectivity } = getContext()
  let person = data

  if (!person) {
    const { payload: { data } = {} } = await getPerson(id)

    if (!data) return

    person = new Person(data, dependencies)
  }

  if (shouldVerifyDebt) {
    depsLoaded()
    setActiveDebtError()

    return person
  }

  const {
    additionalInformation: { leaderId, introducerSellerId, registrantId } = {},
    hierarchyId,
    createdBy,
    personId,
    status: { status, isApproved }
  } = person

  if (connectivity.isOffline) return depsLoaded()

  await getRejectionReasons()

  leaderId && await getConsultantsById(personId, leaderId, TYPES_LEADER)

  createdBy && await getConsultantsById(personId, createdBy, TYPES_ORIGEN)

  introducerSellerId && await getConsultantsById(personId, introducerSellerId, TYPES_INTRODUCER)

  registrantId && await getConsultantsById(personId, registrantId, TYPES_REGISTRANT)

  status && !isApproved && shouldGetCNOList && await getCNOList(hierarchyId)

  depsLoaded()
}

function reduceComercialSituation(accum, { comercialSituation, name, code }) {
  if (!ALLOWED_COMMERCIAL_SITUATIONS.includes(comercialSituation))
    return accum

  return [...accum, { value: code, label: name }]
}

export function depsLoaded() {
  const { dispatch } = getContext()

  return dispatch({ type: PROFILE_DEPS_LOADED })
}

function setActiveDebtError() {
  const { dispatch } = getContext()

  return dispatch({ type: PROFILE_ACTIVE_DEBT_ERROR })
}

function updateInfo({ data }, { person }, key) {
  const { additionalInformation } = person

  if (!data) return additionalInformation

  if (Array.isArray(data)) return updateInfo({ data: data[0] }, { person }, key)

  return {
    ...additionalInformation,
    [key]: data.name
  }
}

function filterRejectReason({ value }) {
  return value === this.rejectionReasonId
}

function mapRejectionReason({ reasonId: value, reasonName: label }) {
  return { label, value }
}
