// https://www.malaysia.gov.my/portal/content/30114

export const MALAYSIA_ETHNICITIES = {
  MALAY: 1,
  BUMIPUTERA: 2,
  CHINESE: 3,
  INDIAN: 4,
  OTHERS: 5
}

export const MalaysiaOptionsStrategy = ({ intl, messages }) => {
  return [
    {
      value: MALAYSIA_ETHNICITIES.MALAY,
      label: intl.formatMessage(messages['ethnicity_malay'])
    },
    {
      value: MALAYSIA_ETHNICITIES.BUMIPUTERA,
      label: intl.formatMessage(messages['ethnicity_bumiputera'])
    },
    {
      value: MALAYSIA_ETHNICITIES.CHINESE,
      label: intl.formatMessage(messages['ethnicity_chinese'])
    },
    {
      value: MALAYSIA_ETHNICITIES.INDIAN,
      label: intl.formatMessage(messages['ethnicity_indian'])
    },
    {
      value: MALAYSIA_ETHNICITIES.OTHERS,
      label: intl.formatMessage(messages['ethnicity_others'])
    }
  ]
}
