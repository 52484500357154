import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const DOCUMENTS = {
  CPF: 1,
  RG: 2,
  RNE: 3,
  PASSPORT_BRAZIL: 4,
  CITIZEN_CARD: 9,
  FOREIGN_CARD: 10,
  NATIONAL_REGISTRY: 19,
  RUT: 20,
  RUC: 27,
  PASSPORT_PERU: 32,
  FOREIGN_REGISTRY: 33,
  MYKAD: 34,
  PASSPORT_MALAYSIA: 35,
  DNI_ARGENTINA: 36,
}

export default class Document extends Entity {
  static SCHEMA = {
    type: adapter(yup.number().required()),
    document: adapter(yup.string().required()),
    extraInfos: adapter(yup.array().of(yup.object()))
  }
}
