import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

export function buildFormSchema(dependencies) {
  const { person: { paymentPlan, status: { isRejected }}, intl } = dependencies
  const { isDenied, isCredit, isPrePaid } = paymentPlan

  const PRE_PAID = TYPES.PRE_PAID(intl, dependencies)
  const ANALYSIS = TYPES.ANALYSIS(intl, dependencies)
  const DISAPPROVED_MSG = TYPES.DISAPPROVED(intl, dependencies)
  const name = 'Credit'

  let fieldsSchema = [[ ANALYSIS ]]

  if (isDenied || isRejected) {
    fieldsSchema = [[ DISAPPROVED_MSG ]]
  }

  if (isCredit) {
    fieldsSchema = []
  }

  if (isPrePaid) {
    fieldsSchema = [[ PRE_PAID ]]
  }

  return new FormSchema({ fieldsSchema, name })
}
