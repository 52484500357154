import * as yup from 'yup'
import { Entity, Collection } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import moment from 'moment'

const adapter = validatorAdapter('Yup', yup)

export class Policy extends Entity {
  static SCHEMA = {
    id: adapter(yup.number().required()),
    version: adapter(yup.number().required()),
    acceptedDate: adapter(yup.date().nullable()),
    fieldId: adapter(yup.string().nullable()),
  }

  get accepted() {
    return !!this.acceptedDate
  }
}

export default class PoliciesAccepted extends Collection {
  static TYPE = Policy

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    this.buildFieldId()
  }

  filterAccepteds(accepteds) {
    return this.policies.reduce(findAndParseAccepteds, accepteds)
  }

  buildFieldId() {
    const { policies = [] } = this

    this.items.map(buildFieldIdFromPolicies, { policies })
  }

  get policiesAcceptedGroup() {
    return this.items.reduce(reducePolicies, {})
  }
}

function reducePolicies(accum, policy) {
  const { fieldId, accepted } = policy

  if (!fieldId) return accum

  return { ...accum, [fieldId]: accepted }
}

function buildFieldIdFromPolicies(policy) {
  const { id } = policy
  const { fieldId } = this.policies.find(findPolicy, { id }) || {}

  Object.assign(policy, { fieldId })
}

function findPolicy({ id }) {
  return id === this.id
}

function findAndParseAccepteds(accum, policy, index, array) {
  const [value] = accum
  const accepted = value && value[policy.fieldId]

  if(index === array.length - 1)
    accum.splice(0, 1)

  if(accepted) {
    const { id, version } = policy

    const date = new Date()
    const acceptedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss')

    accum.push({ id, version, acceptedDate})
  }

  return accum
}
