import {
  isSaveDigitalStorePendingSubstatusToggleActive,
  isEnableDigitalStoreStepForColombiaFlowToggleActive
} from '../../../../../../../../config'

const strategies = {
  COL: isEnableDigitalStoreStepForColombiaFlowToggleActive,
  PER: isSaveDigitalStorePendingSubstatusToggleActive,
  __default__: false
}

export const shouldSaveSubstatus = (localeId) => {
  return strategies[localeId] ? strategies[localeId]() : strategies.__default__
}
