import axios from 'axios'

const APPLICATION_JSON = 'application/json'
const URL_GLOBAL_REGISTERS_BFF = process.env.REGISTERS_URL
const REGISTERS_X_API_KEY = process.env.REGISTERS_X_API_KEY
const GEOGRAPHICAL_LEVELS_BFF_X_API_KEY = process.env.GEOGRAPHICAL_LEVELS_BFF_X_API_KEY
const GEOGRAPHICAL_LEVELS_BFF_URL = process.env.GEOGRAPHICAL_LEVELS_BFF_URL

export const BFF_CLIENT = {
  baseURL: URL_GLOBAL_REGISTERS_BFF,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
  }
}

export const BFF_APIKEYS = {
  ADDRESS_APIKEY: GEOGRAPHICAL_LEVELS_BFF_X_API_KEY,
  REGISTERS_APIKEY: REGISTERS_X_API_KEY,
}

export const BFF_URLS = {
  ALLOCATE_PERSON: (personId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/allocate-commercial-structure`,
  ADDRESS_BFF: (params = '') =>
    `${GEOGRAPHICAL_LEVELS_BFF_URL}/commercial-structure/geographic-levels?${params}`,
}

export default {
  client: axios.create(BFF_CLIENT),
  urls: BFF_URLS,
  apiKeys: BFF_APIKEYS,
}
