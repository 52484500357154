import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  data_validation: {
    defaultMessage: 'We are validating your data',
    id: 'loader.validating'
  },
  hold_on_and_do_not_leave: {
    defaultMessage: 'Hold on and do not leave this page',
    id: 'loader.hold_on_and_do_not_leave'
  },
  estimated_time: {
    defaultMessage: 'Estimated time: 90 seconds',
    id: 'loader.estimated_time'
  },
  slide_1_title: {
    defaultMessage: 'Beware of the origin',
    id: 'loader.slide_1_title'
  },
  slide_1_message: {
    defaultMessage: 'Sustainable practices, income opportunities and care for the social development of small producers',
    id: 'loader.slide_1_message'
  },
  slide_2_title: {
    defaultMessage: 'Natural formulas',
    id: 'loader.slide_2_title'
  },
  slide_2_message: {
    defaultMessage: 'Technology and performance with natural, renewable and Amazonian socio-biodiversity',
    id: 'loader.slide_2_message'
  },
  slide_3_title: {
    defaultMessage: 'No animal testing',
    id: 'loader.slide_3_title'
  },
  slide_3_message: {
    defaultMessage: 'We do not test and purchase inputs or ingredients that have been tested on animals',
    id: 'loader.slide_3_message'
  },
  slide_4_title: {
    defaultMessage: 'Safe for you and the environment',
    id: 'loader.slide_4_title'
  },
  slide_4_message: {
    defaultMessage: 'Only safe ingredients, according to science international standards',
    id: 'loader.slide_4_message'
  },
  slide_5_title: {
    defaultMessage: 'Commitment to the climate',
    id: 'loader.slide_5_title'
  },
  slide_5_message: {
    defaultMessage: 'We neutralize carbon from the extraction of ingredients to the disposal of products and factory activities',
    id: 'loader.slide_5_message'
  },
  slide_6_title: {
    defaultMessage: 'Eco-friendly packaging',
    id: 'loader.slide_6_title'
  },
  slide_6_message: {
    defaultMessage: 'Beautiful and functional, made from post-consumer recycled materials and renewable source',
    id: 'loader.slide_6_message'
  },
})
