import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import { FormattedMessage } from 'react-intl'

import { APP_ROUTES } from 'src/App'
import Modal from 'src/base/Modal/Modal'

import './Blacklisted.styl'

const bem = bemClassName.bind(null, 'blacklisted')

class Blacklisted extends PureComponent {
  constructor(props) {
    super(props)

    this.state = { isOpen: false }

    this.onAcceptEdit = this.onAcceptEdit.bind(this)
    this.onRecuseEdit = this.onRecuseEdit.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { person: { roles: { cn: { substatus: prevSubstatus } } } } = prevProps
    const { person: { roles: { cn: { substatus, pendingAddressBlacklistReview } } } } = this.props

    const shouldOpenModal =
      prevSubstatus !== substatus &&
      pendingAddressBlacklistReview

    if (shouldOpenModal) {
      return this.setState({ isOpen: true })
    }
  }

  onAcceptEdit() {
    this.setState({ isOpen: false })
  }

  onRecuseEdit() {
    this.setState({
      isOpen: false
    }, this.redirectToNewRegister)
  }

  redirectToNewRegister() {
    const { history, person } = this.props
    const basePath = APP_ROUTES.REGISTER

    history.push(basePath)

    return person.reset()
  }

  render() {
    const { isOpen } = this.state
    const { messages, intl, person } = this.props
    const [{ document } = {}] = person.documents || []

    return (
      <Modal
        key="blacklisted"
        title={intl.formatMessage(messages.attention_title)}
        open={isOpen}
        cancel
        onCancel={this.onRecuseEdit}
        onClose={this.onAcceptEdit}
        cancelLabel={intl.formatMessage(messages.reject_button_no)}
        acceptLabel={intl.formatMessage(messages.reject_button_yes)}>
        <p className={bem('modal-message')}>
          <FormattedMessage {...messages.reject_message} values={{ document }} />
        </p>
        <p className={bem('modal-warning')}>
          <FormattedMessage {...messages.reject_role_message} />
        </p>
        <p className={bem('modal-message')}>
          <FormattedMessage {...messages.reject_question} />
        </p>
      </Modal>
    )
  }
}

Blacklisted.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  person: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAcceptEdit: PropTypes.func,
  onRecuseEdit: PropTypes.func,
  redirectToNewRegister: PropTypes.func
}

export default Blacklisted
