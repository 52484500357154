import { connect } from 'react-redux'

export function mapStateToProps(state, ownProps) {
  const {
    profile: { person },
    emailValidation: { emailValidation, error, loading }
  } = state

  return {
    ...ownProps,
    person,
    loading,
    error,
    emailValidation
  }
}
export default connect(mapStateToProps)
