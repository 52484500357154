import { isChileWithLegoToggleActive } from 'src/config'
import TYPES from 'src/configs/Fields'
import Policies from 'src/configs/Locales/DEFAULT/views/SelfRegister/Policies'
import termsAndConditions from './termsAndConditions.chl.terms'

export const getChileFieldsSchema = ({ intl, dependencies }) => {
  const {
    person: {
      businessModel: { isCollaborator }
    }
  } = dependencies
  const shouldShowEstablishment =
    isCollaborator && isChileWithLegoToggleActive()
  const ESTABLISHMENT = commonParams(TYPES.ESTABLISHMENT(intl, dependencies))
  const ADMISSION = commonParams(TYPES.ADMISSION(intl, dependencies))

  const POLICIES = commonParams(
    Policies(intl, dependencies, TYPES.POLICIES, termsAndConditions),
    false
  )

  return [
    (shouldShowEstablishment ? [ESTABLISHMENT, ADMISSION] : []).concat([
      POLICIES
    ])
  ]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
