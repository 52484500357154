import { defineMessages } from 'react-intl'

import { ACCEPT_IMAGE_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { CONFIGS } from 'src/models/Attachment'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'

const FIELD_ID = 'self'
const messages = defineMessages({
  self: {
    defaultMessage: 'Photo',
    id: 'form.field.self'
  },
  self_sublabel: {
    defaultMessage: 'Photo requirements: Front, without glasses, without hat.',
    id: 'form.field.self.sublabel'
  }
})
const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id: localeId }, person: { attachments }, required } = injection

  const attachmentsIds = CONFIGS[localeId]
  const TYPE = attachmentsIds[FIELD_ID]
  const defaultValue = defineDefaultValue(attachments, TYPE)

  return {
    id: FIELD_ID,
    type: 'userMedia',
    defaultValue,
    success: true,
    label: intl.formatMessage(messages[FIELD_ID]),
    sublabel: intl.formatMessage(messages[`${FIELD_ID}_sublabel`]),
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_IMAGE_FORMATS,
      required
    }),
    htmlProps: {
      accept: ACCEPT_IMAGE_FORMATS.join(','),
    },
    customProps: {
      icon: 'upload_photo',
      modal: {
        Component: 'photo',
        className: 'modal-take-photo',
      },
      type: TYPE
    }
  }
}
