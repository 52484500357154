import { compose } from 'redux'
import { injectIntl } from 'react-intl'

import DigitalSpaceStateConnect from './DigitalSpace.connect'
import DigitalSpace from './DigitalSpace'

export default compose(
  injectIntl,
  DigitalSpaceStateConnect
)(DigitalSpace)
