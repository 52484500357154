import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  maps_description: {
    defaultMessage: 'Valid if your residential address is indicated correctly by the point on the map',
    id: 'form.field.maps.description'
  },
  maps_description_actions: {
    defaultMessage: 'The location of the point of your address on the map is correct or do you want to adjust?',
    id: 'form.field.maps.description.actions'
  },
  maps_button_pin: {
    defaultMessage: 'Adjsut PIN',
    id: 'form.field.maps.adjust.pin'
  },
  maps_button_confirm: {
    defaultMessage: 'Yes, confirm',
    id: 'form.field.maps.confirm'
  },
  maps_modal_error: {
    defaultMessage: 'Address not found Please correct the address or improve the location by moving the point on the map.',
    id: 'form.field.maps.modal.error'
  },
  maps_modal_error_title: {
    defaultMessage: 'Attention',
    id: 'form.field.maps.modal.error.title'
  },
  maps_modal_error_button: {
    defaultMessage: 'Ok',
    id: 'form.field.maps.modal.error.button'
  },
  maps_modal_map: {
    defaultMessage: 'To improve the identification of your address, move the point on the map to the correct location.',
    id: 'form.field.maps.modal.map'
  },
  maps_modal_map_pin_description: {
    defaultMessage: 'Move the map to put the point in the correct location.',
    id: 'form.field.maps.modal.map.pin'
  },
  maps_modal_map_pin_button: {
    defaultMessage: 'Confirm',
    id: 'form.field.maps.modal.map.button'
  },
})
