import PersonalInfoBuilder from './PersonalInfo'
import ContactBuilder from './Contact'
import DocumentsBuilder from './Documents'
import AddressHomeBuilder from './AddressHome'
import AddressDeliveryBuilder from './AddressDelivery'
import AddressAlternativeBuilder from './AddressAlternative'
import ManagementBuilder from './Management/Management'
import BusinessModel from './BusinessModel'
import CollaboratorBuilder from './Collaborator'

export function ProfileSchemaBuilder(params) {
  const BUSINESS_MODEL = BusinessModel(params)
  const PERSONAL_INFO = PersonalInfoBuilder(params)
  const CONTACT = ContactBuilder(params)
  const DOCUMENTS = DocumentsBuilder(params)
  const ADDRESS_HOME = AddressHomeBuilder(params)
  const ADDRESS_DELIVERY = AddressDeliveryBuilder(params)
  const ADDRESS_ALTERNATIVE = AddressAlternativeBuilder(params)
  const COLLABORATOR = CollaboratorBuilder(params)
  const MANAGEMENT = ManagementBuilder(params)

  return [
    BUSINESS_MODEL,
    PERSONAL_INFO,
    CONTACT,
    DOCUMENTS,
    ADDRESS_HOME,
    ADDRESS_DELIVERY,
    ...ADDRESS_ALTERNATIVE,
    COLLABORATOR,
    MANAGEMENT
  ]
}
