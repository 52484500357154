import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  password: {
    defaultMessage: 'Password',
    id: 'form.field.password'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const { messages } = { ...dependencies, ...injection }

  return {
    id: 'password',
    type: 'password',
    placeholder: intl.formatMessage(messages['password']),
    validations: yup.string().required()
  }
}
