import { parseApiDate } from 'src/lib/SpeckAdapter/Yup.customs'

export default function CollaboratorBuilder(params) {
  const { intl, messages, person, locale, establishments: { establishments } } = params
  const {
    additionalInformation: {
      establishmentId,
      admissionDate
    } = {}
  } = person

  const { configs: { localization: { date: { format } } } } = locale

  const establishment = establishments.items.find(findEstablishment, { establishmentId }) || {}

  getEstablishments(params.establishments)

  return {
    id: 'profile-collaborator',
    title: intl.formatMessage(messages.box_collaborator_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      {
        label: intl.formatMessage(messages.box_management_label_establishment),
        field: establishmentId
          ? establishment.label
          : '-'
      },
      {
        label: intl.formatMessage(messages.box_management_label_admission),
        field: admissionDate
          ? parseApiDate(admissionDate, format)
          : '-'
      }
    ]
  }
}

function findEstablishment({ id }) {
  return id === this.establishmentId
}

const calleds = {}

function getEstablishments(establishments) {
  const { establishments: { items, getEstablishments: getEstablishmentsList } } = establishments

  if (!items.length && !calleds.establishments ) {
    calleds.establishments = true

    getEstablishmentsList()
  }
}
