import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import classNames from 'classnames'

import './Link.styl'

const bem = bemClassName.bind(null, 'link')

class Link extends PureComponent {
  render() {
    const { className, title, external, href } = this.props

    let externalConfig = {}

    if(external) {
      externalConfig = {
        rel: 'noopener noreferrer',
        target: '_blank'
      }
    }

    return (
      <a
        href={href}
        className={classNames(bem(), className)}
        title={title}
        {...externalConfig}>
        {title}
      </a>
    )
  }
}

Link.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.bool
}

export default Link
