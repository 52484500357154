import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import IndicationModal from './Indication.modal'
import Input from 'src/base/Form/Components/Input'
import Button, { TYPES } from 'src/base/Button'
import TagManager from 'react-gtm-module'
import messages from './Indication.messages'

import './Indication.styl'

const bem = bemClassName.bind(null, 'indication_default')

export class Indication extends PureComponent {
  setDataLayer(buttonId, buttonText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }

  render() {
    return (
      <div className={bem('fieldset--group')}>
        <div className={bem('fieldset')}>
          {this.renderSearchInput()}
          {this.renderButton()}
        </div>
        {this.renderIndicatorName()}
        {this.renderModal()}
      </div>
    )
  }

  renderIndicatorName() {
    const {intl, introducerSellerName} = this.props
    return (
      introducerSellerName &&
      <div className={bem('indicator_section')}>
        {intl.formatMessage(messages['indication_name_label'])}
        <span>{introducerSellerName}</span>
      </div>
    )
  }

  renderModal() {
    const {
      id,
      search,
      searchType,
      showModal,
      handleConfirmModal,
      handleCancelModal,
      consultants: {
        consultants,
        loading,
        error
      },
      customProps: {
        personId
      }
    } = this.props

    return (
      <IndicationModal
        id={id}
        personId={personId}
        showModal={showModal}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
        consultants={consultants}
        loading={loading}
        error={error}
        searchType={searchType}
        search={search}
      />
    )
  }

  renderSearchInput() {
    const {
      id,
      intl,
      mask,
      search,
      onKeyDown,
      searchType,
      errorSearch,
      messages,
      searchSuccess,
      disabledSearch,
      handleChangeSearch,
      showAsterisk,
      handleBlur
    } = this.props

    return (

      <Input
        id={`${id}-input`}
        error={errorSearch}
        value={search}
        label={intl.formatMessage(messages['label_indication_code'])}
        className={bem('search', { 'search-success': searchSuccess })}
        onChange={handleChangeSearch}
        onKeyDown={onKeyDown}
        disabled={disabledSearch}
        required={!!searchType}
        mask={mask}
        showAsterisk={showAsterisk}
        onBlur={handleBlur}
      />
    )
  }

  renderButton() {
    const { intl, disabledButton, messages, handleModal, id, customProps: { showSearchButton } } = this.props
    return (
      showSearchButton &&
      <Button
        id={`${id}-button`}
        type={TYPES.DEFAULT}
        className={bem('button_search')}
        disabled={disabledButton}
        onClick={(event) => {
          this.setDataLayer(`${id}-button`, intl.formatMessage(messages['indication_search']))
          handleModal(event)
        }}>
        {intl.formatMessage(messages['indication_search'])}
      </Button>
    )
  }
}

Indication.propTypes = {
  id: PropTypes.string,
  personId: PropTypes.string,
  searchOffline: PropTypes.string,
  handleChangeOffline: PropTypes.func,
  connectivity: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  intl: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  messages: PropTypes.object,
  consultants: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  form: PropTypes.object,
  hasIndication: PropTypes.bool,
  searchType: PropTypes.string,
  search: PropTypes.string,
  introducerSellerId: PropTypes.any,
  introducerSellerName: PropTypes.string,
  showModal: PropTypes.bool,
  changeIntroducer: PropTypes.bool,
  hasIndicationError: PropTypes.object,
  searchTypeError: PropTypes.object,
  searchError: PropTypes.object,
  onKeyDown: PropTypes.func,
  handleBlur: PropTypes.func,
  handleConfirmModal: PropTypes.func,
  handleCancelModal: PropTypes.func,
  handleChangeHasIndication: PropTypes.func,
  handleChangeSearchType: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  disabledSearch: PropTypes.bool,
  disabledButton: PropTypes.bool,
  disabledTypeSearch: PropTypes.bool,
  searchSuccess: PropTypes.bool,
  handleModal: PropTypes.func,
  errorSearch: PropTypes.string,
  errorSearchType: PropTypes.string,
  errorHasIndication: PropTypes.string,
  customProps: PropTypes.object,
  required: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  mask: PropTypes.string,
  labelSearch: PropTypes.string,
  options: PropTypes.object,
}

Indication.defaultProps = {
  introducerSellerId: '',
  id: 'indicationDefault',
  messages
}

export default Indication
