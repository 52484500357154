import { DOCUMENTS } from "../../../../../models/Document/Document"

export default ({ intl, messages }) => {
  return [
    {
      value: DOCUMENTS.CITIZEN_CARD,
      label: intl.formatMessage(messages['type_citizen'])
    },
    {
      value: DOCUMENTS.FOREIGN_CARD,
      label: intl.formatMessage(messages['type_foreign'])
    }
  ]
}
