import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import { FormattedMessage } from 'react-intl'
import MESSAGES from '../../Register.messages'

import Modal from 'src/base/Modal/Modal'

import './Attention.styl'

const bem = bemClassName.bind(null, 'attention')

class Attention extends PureComponent {
  constructor(props) {
    super(props)

    const { person: { roles: { cnActivate } } } = props

    this.state = { isOpen: !!cnActivate }

    this.onCloseModal = this.onCloseModal.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { person: { roles: { cnActivate: prevCNActive } } } = prevProps
    const { person: { roles: { cnActivate } } } = this.props

    const shouldOpenModal = prevCNActive !== cnActivate

    if (shouldOpenModal){
      return this.setState({ isOpen: cnActivate })
    }
  }

  onCloseModal() {
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen } = this.state

    return (
      <Modal
        className={bem('modal')}
        open={isOpen}
        accept={true}
        onClose={this.onCloseModal}>
        <h4 className={bem('modal-title')}>
          <FormattedMessage {...MESSAGES['attention_title']} />
        </h4>
        <p className={bem('modal-message')}>
          <FormattedMessage {...MESSAGES['attention_message']} />
        </p>
      </Modal>
    )
  }
}

Attention.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired
}

export default Attention
