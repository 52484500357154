import { getContext } from 'src/infra/Api/Api.context'
import User from 'src/models/User'
import { getUserRole } from '../../models/User/strategies/userRoles'

export const USER_PUBLIC_LOADING = 'natura-caduni/User/USER_PUBLIC_LOADING'
export const USER_PUBLIC_SUCCESS = 'natura-caduni/User/USER_PUBLIC_SUCCESS'
export const USER_PUBLIC_ERROR = 'natura-caduni/User/USER_PUBLIC_ERROR'

export const types = [
  USER_PUBLIC_LOADING,
  USER_PUBLIC_SUCCESS,
  USER_PUBLIC_ERROR
]

export const UNAUTORIZED_STATUS = 403

export function userPublicAuthReducer(state, action, dependencies) {
  if (action.type === USER_PUBLIC_LOADING) {
    return {
      ...state,
      loading: true
    }
  }

  if (action.type === USER_PUBLIC_ERROR) {
    return {
      ...state,
      error: {
        ...action.error,
        timestamp: + new Date()
      },
      loading: false
    }
  }

  if (action.type === USER_PUBLIC_SUCCESS) {
    return {
      ...state,
      user: new User(action.payload.user, dependencies),
      loading: false,
      error: false
    }
  }

  return state
}

export async function publicAuthenticate() {
  const { dispatch } = getContext()
  const rolesByTenant = getUserRole()

  return dispatch({
    type: USER_PUBLIC_SUCCESS,
    payload: {
      user: {
        sellerId: rolesByTenant.user.SELF_REGISTRATION,
        authentication: {
          xApikey: process.env.SELF_REGISTRATION_X_API_KEY
        },
      }
    }
  })
}
