import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    ::selection{
      color: ${({ theme }) => theme.color.background};
      background:  ${({ theme }) => theme.color.primary};
    }
  }
`

export default GlobalStyle
