import { AddressParser } from './AddressParser'
import { store } from '../../../../../../../../App.state'
import { ADDRESS_USE, DELIVERY_ADDRESS, MAILING_ADDRESS } from '../../../../../../../../models/Address/AddressUse'
import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import { pickAddressByUse } from '../../../../../../../../models/Address/AddressPicker'

export class ChileAddressParser extends AddressParser {
  getParsedData({ data, configs }) {
    const {
      localization: {
        address: { levels }
      }
    } = configs
    const { person } = store.getState()

    const dataMailingAddress = data.address ? pickAddressByUse(data.address, ADDRESS_USE.MAILLING) : undefined

    if (!dataMailingAddress) {
      const addresses = []
      localStorage.setItem('cumulativeAddress', this.getCumulativeAddress(addresses))
      return { addresses }
    }

    const mailingAddress = this.mapAddress({ address: dataMailingAddress, levels })
    const complementaryAddress = this.getComplementaryAddressFrom(person, levels)

    localStorage.setItem('cumulativeAddress', this.getCumulativeAddress([mailingAddress]))

    mailingAddress.geographicalLevels = [
      ...mailingAddress?.geographicalLevels,
      ...complementaryAddress
    ]

    const result = this.getAllAddresses(person, mailingAddress, levels)
    return result
  }

  getAllAddresses(person, mailingAddress, levels) {
    const deliveryAddress = person.person.addresses ? pickAddressByUse(person.person.addresses, ADDRESS_USE.DELIVERY) : undefined
    const personDontHasDeliveryAddress = !deliveryAddress?.geographicalLevels?.length
    const personHasLessThanTwoAddresses = person.person.addresses.length < 2
    if (personDontHasDeliveryAddress || personHasLessThanTwoAddresses) {
      return { addresses: [mailingAddress] }
    }
    const parsedMailingAddress = {
      ...mailingAddress,
      addressType: ADDRESS_TYPE.MAILING,
      addressUse: [MAILING_ADDRESS]
    }

    const parsedDeliveryAddressGeographicalLevels = deliveryAddress.geographicalLevels.map(
      ({ code, level, description }) => {
        const parsedGeographicalLevel = { level, description }
        if (code) {
          parsedGeographicalLevel.code = code
        }

        if (level === levels.NEIGHBORHOOD) {
          parsedGeographicalLevel.code = null
        }

        return parsedGeographicalLevel
      }
    )

    const parsedDeliveryAddress = {
      ...deliveryAddress,
      geographicalLevels: parsedDeliveryAddressGeographicalLevels,
      addressType: ADDRESS_TYPE.DELIVERY,
      addressUse: [DELIVERY_ADDRESS],
    }

    const otherAddresses = parsedMailingAddress.sequence ? person.person.addresses.filter(address =>
      address.sequence !== parsedMailingAddress.sequence && address.sequence !== parsedDeliveryAddress.sequence
    ) : []

    const parsedOtherAddresses = otherAddresses.map(address => this.parseAddress(address))

    return { addresses: [parsedMailingAddress, parsedDeliveryAddress, ...parsedOtherAddresses] }
  }

  parseAddress(address) {
    const parsedAddressGeographicalLevels =  address.geographicalLevels.map(({ code, level, description }) => {
      const parsedGeographicalLevel = { level, description }
      if (code) {
        parsedGeographicalLevel.code = code
      }
      return parsedGeographicalLevel
    })
    return {
      ...address,
      geographicalLevels: parsedAddressGeographicalLevels
    }
  }

  buildGeographicalLevels({ addressesStructure, country, levels }) {
    const { region, commune } = addressesStructure
    return [
      { level: levels.COUNTRY, description: country },
      {
        level: levels.REGION,
        code: region.geoStructureCode,
        description: region.structureLevelName
      },
      {
        level: levels.CITY,
        code: commune.geoStructureCode,
        description: commune.structureLevelName
      },
      {
        level: levels.NEIGHBORHOOD,
        code: null,
        description: "-"
      }
    ]
  }

  getAddressStructure({ types, addresses }) {
    const { regions, communes } = addresses
    const { country, region, commune } = types

    return {
      region: this.getGeoStructureValues(regions[country], region),
      commune: this.getGeoStructureValues(communes[region], commune)
    }
  }

  getComplementaryAddressFrom(personStructure, levels) {
    const { person } = personStructure
    const results = []

    if (!person.addresses.length) return results

    const onlyMailingAddress = pickAddressByUse(person.addresses, ADDRESS_USE.MAILLING)
    const geographicalLevels = onlyMailingAddress?.geographicalLevels || []

    const levelsToFilter = [
      levels.STREET,
      levels.NUMBER,
      levels.COMPLEMENT,
      levels.REFERENCES
    ]

    geographicalLevels.forEach((geoLevel) => {
      if (levelsToFilter.includes(geoLevel.level)) {
        results.push({
          level: geoLevel.level,
          description: geoLevel.description,
        })
      }
    })
    return results
  }
}
