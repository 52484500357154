import { APP_ROUTES, history } from 'src/App'

export function onSubmition(deps) {
  const { steps, flows, defaultFlow, person } = deps

  return function(data) {
    const { create, documents, draft, personId, reviewStep } = person
    const { businessModel: [businessModel] } = data

    if (!documents || !documents.length)
      return history.replace(APP_ROUTES.REGISTER)

    const { businessModelAsUrl } = businessModel
    const selectedFlow = flows[businessModelAsUrl] || defaultFlow
    const { path } = steps.find(findStepByName, selectedFlow[2])
    const url = path.replace(':businessModelId/:roleId/:functionId', businessModelAsUrl)

    const params = {
      draft,
      personId,
      documents,
      businessModel,
      reviewStep: { ...reviewStep, step: 'personal-data' }
    }

    const callbackFunc = (newPerson, result) => {
      const { payload: { data: { personId } = {} } = {}, error } = result

      if (error) {
        if (error.response.status === 409) {
          const personIdFromError = error.response.data.personId
          return history.push(`${url}/${personIdFromError}`)
        }
        return
      }

      return history.push(`${url}/${personId}`)
    }
    return create(params, callbackFunc)
  }
}

function findStepByName({ name }) {
  return name === this
}
