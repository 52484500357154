import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  address_residential: {
    defaultMessage: 'Residential Address',
    id: 'form.field.address.map.address.residential'
  },
  address_delivery: {
    defaultMessage: 'Delivery Address',
    id: 'form.field.address.map.address.delivery'
  },
  address_alternative: {
    defaultMessage: 'Alternative Address',
    id: 'form.field.address.map.address.alternative'
  },
  address_required: {
    defaultMessage: 'Required field',
    id: 'form.field.address.map.addres.required'
  },
  region: {
    defaultMessage: 'Region',
    id: 'form.field.region'
  },
  region_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.region.placeholder'
  },
  region_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.region.no.options.message'
  },
  department: {
    defaultMessage: 'Department',
    id: 'form.field.department'
  },
  department_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.department.placeholder'
  },
  department_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.department.no.options.message'
  },
  province: {
    defaultMessage: 'Province',
    id: 'form.field.province'
  },
  province_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.province.placeholder'
  },
  province_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.province.no.options.message'
  },
  district: {
    defaultMessage: 'District',
    id: 'form.field.district'
  },
  district_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.district.placeholder'
  },
  district_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.district.no.options.message'
  },
  city: {
    defaultMessage: 'City',
    id: 'form.field.city'
  },
  city_plain_placeholder: {
    defaultMessage: 'Insert your city',
    id: 'form.field.city.plain_placeholder'
  },
  city_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.city.placeholder'
  },
  city_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.city.no.options.message'
  },
  neighborhood: {
    defaultMessage: 'Neighborhood',
    id: 'form.field.neighborhood'
  },
  neighborhood_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.urbanization.placeholder'
  },
  neighborhood_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.urbanization.no.options.message'
  },
  address_additional: {
    defaultMessage: 'Additional data',
    id: 'form.field.address.additional'
  },
  address_confirm: {
    defaultMessage: 'Confirm address',
    id: 'form.field.address.confirm'
  }
})
