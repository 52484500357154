import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'

import './Stepper.styl'

const bem = bemClassName.bind(null, 'stepper')

class Stepper extends PureComponent {
  onClick = (action, path) => {
    return function() {
      return action(path)
    }
  }

  get checkedIcon() {
    return <Icon name="ui_checked" className={bem('step__icon')} />
  }

  render() {
    const { className, steps } = this.props

    if(!steps || !steps.length) return null

    return (
      <ul className={classNames(bem(), className)}>
        {steps.map(this.renderStep, this)}
      </ul>
    )
  }

  renderStep({ label, action, path }, key) {
    const { currentStep } = this.props
    const completed = key < currentStep
    const current = key === currentStep
    const index = completed ? this.checkedIcon : key + 1
    const onClick = completed && action
      ? { onClick: this.onClick(action, path) }
      : null

    return (
      <li
        className={bem('step', { completed, current, actionable: !!onClick })}
        key={key}
        {...onClick}>
        <span className={bem('step__index')}>{index}</span>
        <h6 className={bem('step__label')}>{label}</h6>
      </li>
    )
  }
}

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    path: PropTypes.string.isRequired,
    action: PropTypes.func
  })),
  currentStep: PropTypes.number
}

export default Stepper
