import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

export const NO_INFORMATION =[{ geoStructureDescription: 'SIN INFORMACION', geoStructureCode: 'SIN INFORMACION'}]

const adapter = validatorAdapter('Yup', yup)

class Address extends Entity {
  static SCHEMA = {
    geoStructureCode: adapter(yup.string().required()),
    geoStructureDescription: adapter(yup.string().required()),
    parentTree: adapter(yup.array().of(yup.object())),
    nextLevel: adapter(yup.array().of(yup.object()))
  }

  get label() { return this.geoStructureDescription }

  get value() { return this.geoStructureCode }
}

export default class Addresses extends Entity {
  static SCHEMA = {
    countryName: adapter(yup.string().required()),
    regions: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildDeps(Address, data)
      },
      defaultValue: {}
    },
    departments: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildDeps(Address, data)
      },
      defaultValue: {}
    },
    provinces: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildDeps(Address, data)
      },
      defaultValue: {}
    },
    districts: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildDeps(Address, data)
      },
      defaultValue: {}
    },
    neighborhoods: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildDeps(Address, data)
      },
      defaultValue: {}
    }
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }
}

function buildDeps(builder, data) {
  const entries = Object.entries(data)
  return entries.length
    ? entries.reduce(reduceDeps, { builder })
    : {}
}

function reduceDeps(accum, [key, value], index, array) {
  const lastItem = index === array.length - 1
  const { builder } = accum

  if (lastItem) delete accum.builder
  if (!value && !value.length) return accum

  return { ...accum, [ key ]: Builder(value, builder) }
}

function Builder(data, builder) {
  return data
    .map(mapBuilder, { builder })
    .filter(builder => builder.value)
    .sort(sort)
}

function mapBuilder(data) {
  return new this.builder(data)
}

function sort(a, b) {
  return a.label.localeCompare(b.label)
}
