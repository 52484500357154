import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import { setLocale } from 'yup'

import messages from 'src/yup.messages'

const values = {
  path: '{path}',
  min: '{min}',
  max: '{max}',
  length: '{length}',
  values: '{values}',
  matches: '{matches}',
  regex: '{regex}',
  less: '{less}',
  more: '{more}',
  notEqual: '{notEqual}'
}

function setMessage(message, intl) {
  return intl.formatMessage(message, values)
}

export function YupLocale({ intl, children }) {
  setLocale({
    mixed: {
      default: setMessage(messages.mixed_default, intl),
      required: setMessage(messages.mixed_required, intl),
      oneOf: setMessage(messages.mixed_oneOf, intl),
      notOneOf: setMessage(messages.mixed_notOneOf, intl)
    },
    string: {
      length: setMessage(messages.string_length, intl),
      min: setMessage(messages.string_min, intl),
      max: setMessage(messages.string_max, intl),
      matches: setMessage(messages.string_matches, intl),
      email: setMessage(messages.string_email, intl),
      url: setMessage(messages.string_url, intl),
      trim: setMessage(messages.string_trim, intl),
      lowercase: setMessage(messages.string_lowercase, intl),
      uppercase: setMessage(messages.string_uppercase, intl)
    },
    number: {
      min: setMessage(messages.number_min, intl),
      max: setMessage(messages.number_max, intl),
      less: setMessage(messages.number_less, intl),
      more: setMessage(messages.number_more, intl),
      notEqual: setMessage(messages.number_notEqual, intl),
      positive: setMessage(messages.number_positive, intl),
      negative: setMessage(messages.number_negative, intl),
      integer: setMessage(messages.number_integer, intl),
    },
    date: {
      min: setMessage(messages.date_min, intl),
      max: setMessage(messages.date_max, intl)
    }
  })

  return children
}

YupLocale.propTypes = {
  children: PropTypes.node,
  intl: PropTypes.object,
  messages: PropTypes.object
}

YupLocale.defaultProps = {
  messages
}

export default injectIntl(YupLocale)
