import { getSectionTitle } from './Messages'

export function buildCompleted(deps) {
  const statusInfo = {
    icon: 'clock',
    title: 'processing_title',
    description: 'processing_description',
    label: 'processing_profile_title',
    loading: 'processing_profile_loading',
    actions: [
      {
        id: 'profile',
        shouldShow: true,
      }
    ]
  }

  const { title, subtitle, messages } = getSectionTitle(deps, statusInfo)
  const { loader: { personLoading } } = deps

  return {
    statusInfo,
    messages,
    step: {
      title,
      subtitle
    },
    loader: personLoading
  }
}

