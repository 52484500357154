import { defineMessages } from 'react-intl'

export const Messages = defineMessages({
  title_analysis: {
    defaultMessage: 'In analysis',
    id: 'register.credit_modality.title.analysis'
  },
  title_credit: {
    defaultMessage: 'Credit',
    id: 'register.credit_modality.title.credit'
  },
  title_pre_paid: {
    defaultMessage: 'Pre-Paid',
    id: 'register.credit_modality.title.pre.paid'
  },
  title_not_approved: {
    defaultMessage: 'Registration not approved',
    id: 'register.credit_modality.title.not.approved'
  },
  title_select_payment: {
    defaultMessage: 'Select the payment method',
    id: 'register.credit_modality.title.select.payment'
  },
  do_not_accept_label: {
    defaultMessage: 'Do not accept',
    id: 'form.actions.do.not.accept'
  },
  yes_accept_label: {
    defaultMessage: 'Yes, Accept',
    id: 'form.actions.yes.accept'
  },
  modal_description: {
    defaultMessage: 'If you do not accept the form of payment, you will not be able to continue with the registration. Do you really want to cancel the registration?',
    id: 'form.modal.description'
  },
  modal_registration_canceled: {
    defaultMessage: 'Registration canceled',
    id: 'form.modal.registration.canceled'
  },
  modal_button_yes: {
    defaultMessage: 'Yes',
    id: 'form.modal.yes'
  },
  modal_button_no: {
    defaultMessage: 'No',
    id: 'form.modal.no'
  },
  modal_button_ok: {
    defaultMessage: 'Ok',
    id: 'form.modal.ok'
  },
  modal_title: {
    defaultMessage: 'Attention',
    id: 'form.modal.title'
  },
  error_title: {
    defaultMessage: 'Error',
    id: 'register.complementary_data.error.title'
  }
})
