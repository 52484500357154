import * as yup from 'yup'

import Document from './Documents.document'
import DocumentType, { documentsEnum } from './Documents.type'
import { DOCUMENTS } from '../../../models/Document'
import { defineMessages } from 'react-intl'
import { validateRut } from '@fdograph/rut-utilities'

const messages = defineMessages({
  rut_error: {
    defaultMessage: 'Invalid RUT',
    id: 'form.rut.validation.error'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  const {
    person = {},
    locale: {
      configs: { localization }
    }
  } = injection
  const {
    documents: { personal }
  } = localization

  let documents = person
    .toJSON()
    .documents.filter(filterDocuments, { documents: personal })

  const DOCUMENT = Document(intl, injection)
  const DOCUMENT_TYPE = DocumentType(intl, injection)
  const rutValidationError = intl.formatMessage(messages['rut_error'])

  if (!documents || !documents.length) {
    documents = [{ type: DOCUMENT_TYPE.options[0].value, document: '' }]
  }

  return {
    id: 'documents',
    min: 1,
    max: 1,
    defaultValue: documents,
    fields: [[DOCUMENT]],
    validations: yup.array().of(
      yup.object().shape({
        document: documentValidation('type', localization, rutValidationError),
        type: yup.mixed().required()
      })
    )
  }
}

export function documentValidation(
  documentType,
  localization,
  rutValidationError
) {
  const { documents: { lengthValidation } = {} } = localization || {}
  const { min, max } = lengthValidation[documentsEnum[DOCUMENTS.RUT]] || {}

  return yup
    .string()
    .required()
    .when(documentType, function (type, schema) {
      if (min) return (schema = schema.min(min))
      if (max) return (schema = schema.max(max))

      return schema
    })
    .test('valid', rutValidationError, (value) => {
      if (value.length < min) return true
      const lowerCaseValue = value.toLowerCase()
      return validateRut(lowerCaseValue)
    })
}

function filterDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}
