import React, { Fragment } from 'react'
import { PHONE_TYPES } from 'src/models/Phone'

import ContactValidation from 'src/base/ContactValidation'

const LINE_REGEX = /(\d{1,2})(\d{1,4})(\d{1,4})/
const MOBILE_REGEX = /(\d{1,2})(\d{1,5})(\d{1,4})/

export default function Builder(params) {
  const { intl, messages, person } = params
  const { phones = [], emails = [], substatus } = person

  const phoneFields = phones.reduce(createPhoneField, [params])
  const [email] = emails

  return {
    id: 'profile-contact',
    title: intl.formatMessage(messages.box_contact_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      ...phoneFields,
      {
        label: intl.formatMessage(messages.box_contact_label_email),
        field: (
          <Fragment>
            {email && email.email || '-'} &nbsp;
            <ContactValidation email={email} substatus={substatus} />
          </Fragment>
        )
      }
    ]
  }
}

export const phoneEnum = {
  [PHONE_TYPES.LINE]: 'box_contact_label_phone',
  [PHONE_TYPES.MOBILE]: 'box_contact_label_mobile'
}

export function createPhoneField(accum, phoneObj, index, array) {
  const { phoneNumber = '', areaCode = '', type } = phoneObj
  const [{ intl, messages }] = accum

  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!phoneEnum[type]) return accum

  const FORMAT_DIGITS = type === PHONE_TYPES.LINE ? LINE_REGEX : MOBILE_REGEX
  const formatedPhone = `${areaCode}${phoneNumber}`.replace(FORMAT_DIGITS, '($1) $2-$3')

  const phone = {
    label: intl.formatMessage(messages[phoneEnum[type]]),
    field: (
      <Fragment>
        {formatedPhone}
      </Fragment>
    )
  }

  return [...accum, phone]
}
