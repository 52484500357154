import { isEnableThemeElo } from "../../config"
import { COMPANY_ID, COMPANY_NAME } from "../../models/Company"
import { getCountryByUrl } from "../getCountryByUrl"


export const enableThemeElo = () => {
  const availableCountries = process.env.ENABLE_THEME_ELO_TO_COUNTRY?.split(',')

  const currentLocale = getStorageValue('currentLocale')
  const currentLocaleStorage = JSON.parse(currentLocale)

  const isActivatedToCountry = availableCountries?.includes(getCountryByUrl())

  const countryLocale = getStorageValue('country')

  const isActivatedToCountryStorage = availableCountries?.includes(countryLocale?.toUpperCase())

  const currentCompanyStorage = getStorageValue('company')

  const verifyThemeElo = isEnableThemeElo()
  && (currentLocaleStorage?.companyId === COMPANY_ID.NATURA || currentCompanyStorage?.toUpperCase() === COMPANY_NAME.NATURA)
  && (isActivatedToCountry || isActivatedToCountryStorage)

  return verifyThemeElo
}


export function getStorageValue(key) {
  const saved = localStorage.getItem(key)
  let initial = null
  if(saved !== null) {
    initial = JSON.parse(JSON.stringify(saved))
  }
  return initial
}
