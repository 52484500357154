import { set, unset } from './Form.state.helpers'

export default class Values {
  constructor(props) {
    Object.assign(this, { ...props })
  }

  setValue(id, value) {
    const newValues = value !== null
      ? set(this, id, value)
      : unset(this, id)

    const objectWithValidArrays = Object.entries(newValues)
      .reduce(reduceValidValues, {})

    return new Values(objectWithValidArrays)
  }
}

function reduceValidValues(accum, [key, value]) {
  if(Array.isArray(value))
    return { ...accum, [key]: value.filter(removeEmpty) }

  return { ...accum, [key]: value }
}

function removeEmpty(item) {
  return !!item
}
