import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_REMOVE_PENDENCY_LOADING = 'natura-caduni/People/PROFILE_REMOVE_PENDENCY_LOADING'
export const PROFILE_REMOVE_PENDENCY_SUCCESS = 'natura-caduni/People/PROFILE_REMOVE_PENDENCY_SUCCESS'
export const PROFILE_REMOVE_PENDENCY_ERROR = 'natura-caduni/People/PROFILE_REMOVE_PENDENCY_ERROR'

const TYPES_REMOVE_PENDENCY = [PROFILE_REMOVE_PENDENCY_LOADING, PROFILE_REMOVE_PENDENCY_SUCCESS, PROFILE_REMOVE_PENDENCY_ERROR]

export function removePersonPendency(personRoleId) {
  const {
    dispatch,
    urls: { REMOVE_PENDENCY },
    locale: { id, countryId },
    user: {
      sellerId,
      authentication: { accessToken }
    }
  } = getContext()

  return dispatch({
    types: TYPES_REMOVE_PENDENCY,
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: REMOVE_PENDENCY(personRoleId),
        headers: {
          access_token: accessToken,
          Authorization: accessToken,
          userId: sellerId,
          countryId
        }
      }
    }
  })
}
