import BRA from './Locales/BRA'
import configurationBuilder from './Locales/DEFAULT'
import {
  isMalaysiaFlowToggleActive,
  isColombiaFlowToggleActive,
  isChileFlowToggleActive,
  isArgentinaFlowToggleActive,
  isAvonMexicoFlowToggleActive,
  isAvonBrazilFlowToggleActive,
  isAvonArgentinaFlowToggleActive,
  isNaturaMexicoFlowToggleActive,
  isNaturaEcuadorFlowToggleActive
} from '../config'
import { COMPANY_ID, COMPANY_NAME } from '../models/Company'
import { TENANT_ID } from '../models/tenant/tenant-id'
import { Languages } from './locales.languages'

const argentinaFlowToggle = isArgentinaFlowToggleActive()
const chileFlowToggle = isChileFlowToggleActive()
const colombiaFlowToggle = isColombiaFlowToggleActive()
const avonMexicoFlowToggle = isAvonMexicoFlowToggleActive()
const naturaMexicoFlowToggle = isNaturaMexicoFlowToggleActive()
const naturaEcuadorFlowToggle = isNaturaEcuadorFlowToggleActive()
const avonBrazilFlowToggle = isAvonBrazilFlowToggleActive()
const avonArgentinaFlowToggle = isAvonArgentinaFlowToggleActive()

export const LOCALES = [
  {
    id: 'USA',
    name: 'United States',
    companyId: COMPANY_ID.NATURA,
    locale: 'en_US',
    region: 'US',
    language: Languages.ENGLISH,
    login: false,
    registration: false,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: false
  },
  {
    id: 'BRA',
    countryId: 1,
    companyId: COMPANY_ID.NATURA,
    name: 'Brazil',
    locale: 'pt_BR',
    region: 'BR',
    language: Languages.PORTUGUESE,
    login: false,
    registration: false,
    subCompany: COMPANY_NAME.NATURA,
    tenantId: TENANT_ID.NATURA_BRAZIL,
    displayOnSelectCountry: true,
    naturaUrl: 'https://www.natura.com.br',
    configs: BRA
  },
  {
    id: 'ARG',
    countryId: 2,
    companyId: COMPANY_ID.NATURA,
    name: 'Argentina',
    locale: 'es_AR',
    region: 'AR',
    language: Languages.SPANISH,
    login: argentinaFlowToggle,
    registration: argentinaFlowToggle,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: true,
    tenantId: TENANT_ID.NATURA_ARGENTINA,
    configs: configurationBuilder(TENANT_ID.NATURA_ARGENTINA)
  },
  {
    id: 'AVON_ARG',
    countryId: 2,
    companyId: COMPANY_ID.NATURA,
    name: 'Avon Argentina',
    locale: 'es_AR',
    region: 'AR',
    language: Languages.SPANISH,
    login: avonArgentinaFlowToggle,
    tenantId: TENANT_ID.AVON_ARGENTINA,
    registration: avonArgentinaFlowToggle,
    subCompany: COMPANY_NAME.AVON,
    displayOnSelectCountry: isAvonArgentinaFlowToggleActive(),
    configs: configurationBuilder(TENANT_ID.AVON_ARGENTINA)
  },
  {
    id: 'BOL',
    countryId: 0,
    companyId: COMPANY_ID.NATURA,
    name: 'Bolivia',
    locale: 'es_BO',
    fallbackLocale: 'es_CO',
    region: 'BO',
    language: Languages.SPANISH,
    login: false,
    registration: false,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: false
  },
  {
    id: 'CHL',
    countryId: 4,
    companyId: COMPANY_ID.NATURA,
    name: 'Chile',
    locale: 'es_CL',
    fallbackLocale: 'es_PE',
    region: 'CL',
    language: Languages.SPANISH,
    login: chileFlowToggle,
    registration: chileFlowToggle,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: true,
    tenantId: TENANT_ID.NATURA_CHILE,
    configs: configurationBuilder(TENANT_ID.NATURA_CHILE)
  },
  {
    id: 'COL',
    countryId: 5,
    companyId: COMPANY_ID.NATURA,
    name: 'Colombia',
    locale: 'es_CO',
    region: 'CO',
    language: Languages.SPANISH,
    login: false,
    naturaUrl: 'http://natura.com.co',
    tenantId: TENANT_ID.NATURA_COLOMBIA,
    registration: colombiaFlowToggle,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: true,
    configs: configurationBuilder(TENANT_ID.NATURA_COLOMBIA)
  },
  {
    id: 'FRA',
    countryId: 0,
    companyId: COMPANY_ID.NATURA,
    name: 'France',
    locale: 'fr_FR',
    region: 'FR',
    language: 'fr',
    fallbackLocale: 'en_US',
    login: false,
    registration: false,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: false
  },
  {
    id: 'MEX',
    countryId: 7,
    companyId: COMPANY_ID.AVON,
    name: 'Mexico',
    locale: 'es_MX',
    fallbackLocale: 'es_CO',
    region: 'MX',
    language: Languages.SPANISH,
    login: avonMexicoFlowToggle,
    tenantId: TENANT_ID.AVON_MEXICO,
    registration: avonMexicoFlowToggle,
    subCompany: COMPANY_NAME.AVON,
    displayOnSelectCountry: true,
    configs: configurationBuilder(TENANT_ID.AVON_MEXICO)
  },
  {
    id: 'MEX',
    countryId: 7,
    companyId: COMPANY_ID.NATURA,
    name: 'Mexico',
    locale: 'es_MX',
    fallbackLocale: 'es_CO',
    region: 'MX',
    language: Languages.SPANISH,
    login: naturaMexicoFlowToggle,
    tenantId: TENANT_ID.NATURA_MEXICO,
    registration: naturaMexicoFlowToggle,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: true,
    configs: configurationBuilder(TENANT_ID.NATURA_MEXICO)
  },
  {
    id: 'ECU',
    countryId: 10,
    companyId: COMPANY_ID.NATURA,
    name: 'Ecuador',
    locale: 'es_EC',
    fallbackLocale: 'es_CO',
    region: 'EC',
    language: Languages.SPANISH,
    login: naturaEcuadorFlowToggle,
    tenantId: TENANT_ID.NATURA_ECUADOR,
    registration: naturaEcuadorFlowToggle,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: true,
    configs: configurationBuilder(TENANT_ID.NATURA_ECUADOR)
  },
  {
    id: 'PER',
    countryId: 18,
    companyId: COMPANY_ID.NATURA,
    name: 'Peru',
    locale: 'es_PE',
    fallbackLocale: 'es_CO',
    region: 'PE',
    language: Languages.SPANISH,
    login: true,
    naturaUrl: 'https://www.natura.com.pe',
    registration: true,
    subCompany: COMPANY_NAME.NATURA,
    tenantId: TENANT_ID.NATURA_PERU,
    displayOnSelectCountry: true,
    configs: configurationBuilder(TENANT_ID.NATURA_PERU)
  },
  {
    id: 'GBR',
    companyId: COMPANY_ID.NATURA,
    countryId: 0,
    name: 'United Kingdom',
    locale: 'en_GB',
    fallbackLocale: 'en_US',
    region: 'GB',
    language: Languages.ENGLISH,
    login: false,
    registration: false,
    subCompany: COMPANY_NAME.NATURA,
    displayOnSelectCountry: false
  },
  {
    id: 'MYS',
    countryId: 25,
    companyId: COMPANY_ID.NATURA,
    name: 'Malaysia',
    locale: 'en_MY',
    fallbackLocale: 'en_US',
    region: 'MY',
    language: Languages.ENGLISH,
    login: true,
    naturaUrl: 'https://www.natura.com.my',
    registration: true,
    subCompany: COMPANY_NAME.NATURA,
    tenantId: TENANT_ID.NATURA_MALAYSIA,
    displayOnSelectCountry: isMalaysiaFlowToggleActive(),
    configs: configurationBuilder(TENANT_ID.NATURA_MALAYSIA)
  },
  {
    id: 'BRA',
    countryId: 1,
    companyId: COMPANY_ID.AVON,
    name: 'Brazil',
    locale: 'pt_BR',
    region: 'BR',
    language: Languages.PORTUGUESE,
    login: avonBrazilFlowToggle,
    tenantId: TENANT_ID.AVON_BRAZIL,
    registration: avonBrazilFlowToggle,
    subCompany: COMPANY_NAME.AVON,
    displayOnSelectCountry: isAvonBrazilFlowToggleActive(),
    configs: configurationBuilder(TENANT_ID.AVON_BRAZIL)
  }
]

function availableLocales({ displayOnSelectCountry }) {
  return !!displayOnSelectCountry
}

export const AVAILABLE_LOCALES = LOCALES.filter(availableLocales)

export const DEFAULT_LOCALE = getUsaLocale(LOCALES)

function getUsaLocale(locales) {
  const usaIndex = locales.findIndex((locale) => locale.id === 'USA')
  const countryIndex = usaIndex >= 0 ? usaIndex : 0

  return locales[countryIndex]
}
