import {DocumentFieldsMapper} from './DocumentFieldsMapper'
import {DOCUMENTS} from '../../../../../../../../models/Document'

export const MESSAGES_ID = {
  [DOCUMENTS.MYKAD]: 'box_documents_label_document_mykad',
  [DOCUMENTS.PASSPORT_MALAYSIA]: 'box_documents_label_document_passport_malaysia',
}

export const getDocumentsFields = ({ intl, nationalityId, messages, documents, attachments }) => {
  const documentFieldsMapper = new DocumentFieldsMapper({
    intl,
    messagesId: MESSAGES_ID,
    attachmentTypeToMessageIdsEnum: {}
  })
  const nationality = documentFieldsMapper.mapNationalityField({ intl, nationalityId, messages })
  const documentsFields = documentFieldsMapper.mapDocuments({ documents, messages })
  const attachmentsField = documentFieldsMapper.mapAttachmentsField({ attachments, intl, messages, documentsFields })
  const termsField = documentFieldsMapper.mapTermsField({ attachments, intl, messages })

  return [nationality, ...documentsFields, attachmentsField, termsField]
}
