import React from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Icon from '../../../../Icon'
import './Thumbnail.styl'

const Thumbnail = ({ error, success, thumbnail }) => {
  const bem = bemClassName.bind(null, 'defaultThumbnail')

  return (
    <figure className={bem('wrapper')}>
      <figcaption
        className={bem('status', {
          disabled: !error && !success,
          error,
          success
        })}
      >
        {success && <Icon name="approved_white" />}
        {error && <Icon name="denied_white" />}
      </figcaption>

      <picture>
        <img
          className={bem('image')}
          alt="Previa do documento"
          src={thumbnail}
        />
      </picture>
    </figure>
  )
}

Thumbnail.propTypes = {
  error: PropTypes.bool,
  subtitle: PropTypes.node,
  success: PropTypes.bool,
  thumbnail: PropTypes.string
}

export default Thumbnail
