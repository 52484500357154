import { ADDRESS_TYPES } from 'src/models/Address'
import { ADDRESS_USE } from 'src/models/Address/AddressUse'

export default function Builder(params) {
  const { intl, messages, person } = params
  const { addresses } = person

  const homeAddress = addresses.find(findAddress)

  const fields = homeAddress ? parseAddress(homeAddress, params) : [{
    field: intl.formatMessage(messages.box_address_value_empty)
  }]

  return {
    id: 'profile-address-home',
    title: intl.formatMessage(messages.box_address_home_title),
    icon: 'ui_home',
    wrapper: 'default',
    content: 'list',
    fields
  }
}

function findAddress({ addressUse }) {
  return addressUse.find(findHomeAddress)
}

function findHomeAddress({ id }) {
  return id === ADDRESS_USE.MAILLING
}

const addressEnum = {
  [ADDRESS_TYPES.STREET]: 'box_address_label_street',
  [ADDRESS_TYPES.NUMBER]: 'box_address_label_number',
  [ADDRESS_TYPES.ZIPCODE]: 'box_address_label_zipcode',
  [ADDRESS_TYPES.COMPLEMENT]: 'box_address_label_complement',
  [ADDRESS_TYPES.NEIGHBORHOOD]: 'box_address_label_neighborhood',
  [ADDRESS_TYPES.CITY]: 'box_address_label_city',
  [ADDRESS_TYPES.REGION]: 'box_address_label_region',
  [ADDRESS_TYPES.COUNTRY]: 'box_address_label_country',
  [ADDRESS_TYPES.REFERENCES]: 'box_address_label_reference',
}

export function parseAddress(address = {}, params) {
  const { geographicalLevels, extraInfo, complement, number, zipCode } = address
  const reducedGeographicLevels = !!geographicalLevels && geographicalLevels.reduce(reduceGeographicLevels, {})

  return [
    createField(ADDRESS_TYPES.STREET, reducedGeographicLevels, params),
    createField(ADDRESS_TYPES.NUMBER, { [ADDRESS_TYPES.NUMBER]: number }, params),
    createField(ADDRESS_TYPES.ZIPCODE, { [ADDRESS_TYPES.ZIPCODE]: zipCode }, params),
    createField(ADDRESS_TYPES.COMPLEMENT, { [ADDRESS_TYPES.COMPLEMENT]: complement }, params),
    createField(ADDRESS_TYPES.NEIGHBORHOOD, reducedGeographicLevels, params),
    createField(ADDRESS_TYPES.CITY, reducedGeographicLevels, params),
    createField(ADDRESS_TYPES.REGION, reducedGeographicLevels, params),
    createField(ADDRESS_TYPES.COUNTRY, reducedGeographicLevels, params),
    createField(ADDRESS_TYPES.REFERENCES, { [ADDRESS_TYPES.REFERENCES]: extraInfo }, params),
  ]
}

function createField(type, levels, { intl, messages }) {
  return {
    label: intl.formatMessage(messages[addressEnum[type]]),
    field: levels[type] || '-'
  }
}

function reduceGeographicLevels(accum, { type, description }) {
  return {
    ...accum,
    [type]: description
  }
}
