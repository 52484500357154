import { getContext } from '../../infra/Api/Api.context'

const FLOW_DIRECTION_SET = 'FLOW_DIRECTION_SET'

export const DIRECTION = {
  FORWARD: 'FORWARD',
  BACKWARD: 'BACKWARD',
}

const initialState = {
  flowDirection: DIRECTION.FORWARD,
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  if (type === FLOW_DIRECTION_SET && payload) {
    return {
      ...state,
      flowDirection: payload.flowDirection
    }
  }

  return state
}

export const SetFlowDirection = (flowDirection) => {
  const { dispatch } = getContext()

  return dispatch({
    type: FLOW_DIRECTION_SET,
    payload: {
      flowDirection
    }
  })
}
