import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  label: {
    defaultMessage: 'The intention accepts to receive the invoice digitally',
    id: 'form.field.policy.digital.label'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'invoice',
    type: 'checkboxModal',
    label: intl.formatMessage(messages.label),
    customProps: {
      linkIndexes: [],
      modal: []
    }
  }
}
