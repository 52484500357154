import React, { Component } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Input from 'src/base/Form/Components/Input'
import Loader from 'src/base/Loader'

const bem = bemClassName.bind(null, 'maps__input')

const ENTER_KEY = 13

const TYPES = [
  { street: ['route'] },
  { country: ['country'] },
  { neighborhood: ['sublocality_level_1', 'sublocality'] },
  { city: ['locality', 'administrative_area_level_2'] },
  { uf: ['administrative_area_level_1'] },
  { zipCode: ['postal_code'] },
  { number: ['street_number'] },
]

const dependencies = {
  geocodeByAddress,
  getLatLng
}

class MapsInput extends Component {
  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, { ...dependencies, ...injection })

    this.state = {
      address: {},
      update: false
    }

    this.error = false

    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)

    this.onEnterCancel = this.onEnterCancel.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleLatLang = this.handleLatLang.bind(this)
    this.repeatSearchGeocodeByAddress = this.repeatSearchGeocodeByAddress.bind(this)

    this.renderContent = this.renderContent.bind(this)
  }

  onBlur(onGMapBlur) {
    return function(value, event) {
      return onGMapBlur(event)
    }
  }

  onChange(onGMapChange) {
    return function(value, event) {
      return onGMapChange(event)
    }
  }

  onEnterCancel(onGMapKeyDown) {
    const { value } = this.props
    const { handleSelect } = this

    return function(event) {
      if(event.wich === ENTER_KEY || event.keyCode === ENTER_KEY) {
        event.preventDefault()
        handleSelect(value)
      }

      onGMapKeyDown(event)
    }
  }

  get searchOptions() {
    const { locale: { region: country }} = this.props

    return {
      strictBounds: true,
      componentRestrictions: { country },
      types: ['address']
    }
  }

  handleError() {
    this.error = true
  }

  handleChange(value) {
    const { form: { setFieldValue }, id, group } = this.props

    this.props.onChange({ street: value, location: null })

    if(!value)
      setFieldValue(id, {}, group.id)
  }

  handleSelect(value) {
    const { region } = this.props

    let street = value
    const addressTemp = {}

    if (!value) return null

    if (region) {
      street = `${value.split(',')[0]}, ${region}`
    }

    if (this.error) {
      this.error = false
      Object.assign(addressTemp, this.state.address, { hasError: true })
    }

    Object.assign(addressTemp, this.state.address, { street })

    this.setState({ address: addressTemp, value: street })

    this.searchGeocodeByAddress(street)
  }

  searchGeocodeByAddress(address) {
    this.geocodeByAddress(address)
      .then(this.handleAddress)
      .then(this.getLatLng)
      .then(this.handleLatLang)
      .catch(this.repeatSearchGeocodeByAddress)
  }

  repeatSearchGeocodeByAddress() {
    return this.searchGeocodeByAddress(this.props.region)
  }

  handleAddress([address]) {
    const { formatted_address: value, address_components } = address

    const {
      street,
      country,
      neighborhood,
      city,
      uf,
      zipCode,
      number: street_number
    } = TYPES
      .map(mapGLevel, { types: address_components })
      .reduce(reduceLevels, {})

    const numFromHash = findNumber(value)
    const { number, block } = normalizeNumber(numFromHash, street_number)

    const gMapsAddress = {
      street: block ? `${street} ${block}` : street,
      neighborhood,
      number,
      city,
      zipCode,
      uf,
      country
    }

    const params = Object.assign({}, this.state.address, { gMapsAddress, hasError: !street })

    this.setState({ address: params })

    return address
  }

  handleLatLang(location) {
    const params = Object.assign({}, this.state.address, { location })

    this.setState({ address: params })

    this.props.onChange(params)
  }

  render() {
    return (
      <PlacesAutocomplete
        searchOptions={this.searchOptions}
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
        debounce={200}>
        {this.renderContent}
      </PlacesAutocomplete>
    )
  }

  renderContent(props) {
    return (
      <div className={bem()}>
        {this.renderInput(props)}
        {this.renderDropdown(props)}
      </div>
    )
  }

  renderInput({ getInputProps }) {
    const {
      onBlur: onGMapBlur,
      onChange: onGMapChange,
      onKeyDown: onGMapKeyDown
    } = getInputProps ? getInputProps() : {}

    const {
      type,
      label,
      message,
      error,
      required,
      disabled,
      hidden,
      id,
      success,
      mask,
      value,
      htmlProps
    } = this.props

    const inputError = error ? error.confirmed : ''

    return (
      <Input
        id={id}
        name={id}
        type={type}
        label={label}
        hidden={hidden}
        message={message}
        value={value}
        error={inputError}
        required={required}
        disabled={disabled}
        success={success}
        mask={mask}
        htmlProps={htmlProps}
        onBlur={this.onBlur(onGMapBlur)}
        onChange={this.onChange(onGMapChange)}
        onKeyDown={this.onEnterCancel(onGMapKeyDown)}
      />
    )
  }

  renderDropdown({ suggestions, getSuggestionItemProps, loading }) {
    const { region } = this.props

    if(!suggestions.length) return null

    return (
      <div className={bem('suggestions')} data-testid="gmap-suggestions">
        {loading && <Loader className={bem('loader')} />}
        {suggestions.map(this.renderSugestion, { getSuggestionItemProps, region })}
      </div>
    )
  }

  renderSugestion(suggestion, key) {
    const { active, formattedSuggestion: { mainText, secondaryText } } = suggestion
    const props = this.getSuggestionItemProps(suggestion)

    return (
      <div
        key={key}
        className={bem('suggestion', { active })}
        {...props}>
        <span className={bem('suggestion__main')}>{mainText}</span>
        <span className={bem('suggestion__secondary')}>
          {!this.region ? secondaryText : this.region}
        </span>
      </div>
    )
  }
}

function mapGLevel(type) {
  const [key, types] = Object.entries(type)[0]

  const { long_name = '' } = this.types.find(findAddressType, { types }) || {}

  return { [key]: long_name }
}

function findAddressType({ types }) {
  return this.types.find(address => types.includes(address))
}

function reduceLevels(obj, item) {
  const [key, value] = Object.entries(item)[0]

  return key && { ...obj, [key]: value }
}

function findNumber(address, mark = '#') {
  if (!address) return ''

  const addressItems = address.split(' ')
  const hashPos = addressItems.find(item => item.includes(mark))

  return hashPos ? hashPos.split(mark)[1] : ''
}

// TODO: This shouldn't be here, should be on Locale.config
function normalizeColombiaNumber(number) {
  if (!number) return ''

  const splittedNumber = number.split('-')
  const hasHyphen = splittedNumber.length > 1

  if (!hasHyphen) return false

  return {
    number: splittedNumber[1].split(',')[0],
    block: `#${splittedNumber[0]}`,
  }
}

function normalizeNumber(number, number_gm) {
  const numberNormalized = normalizeColombiaNumber(number)
  const numberGMNormalized = normalizeColombiaNumber(number_gm)

  return numberNormalized || numberGMNormalized || number
}

MapsInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  locale: PropTypes.object,
  region: PropTypes.string,
  id: PropTypes.string,
  group: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func
  }),
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.object ]),
  htmlProps: PropTypes.object,
  error: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  success: PropTypes.bool,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  placeholder: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
  mask: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
  label: PropTypes.string,
  message: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default MapsInput
