import { DOCUMENTS } from "../../../../../models/Document/Document"

export default ({ intl, messages }) => {
  return [
    {
      value: DOCUMENTS.MYKAD,
      label: intl.formatMessage(messages['type_mykad'])
    },
    {
      value: DOCUMENTS.PASSPORT_MALAYSIA,
      label: intl.formatMessage(messages['type_passport_malaysia'])
    }
  ]
}
