import PeopleRegister from 'src/viewModels/PeopleRegister'

export const PEOPLE_REGISTER_REHYDRATE = 'persist/REHYDRATE'

export function reducerPeopleRegisterRehydrate(state, action, dependencies) {
  const { payload = {} } = action
  const { peopleRegister: { peopleRegister: storedPeopleRegister = [] } = {} } = payload

  const peopleRegister = new PeopleRegister(storedPeopleRegister, dependencies)

  return {
    peopleRegister
  }
}
