import { DOCUMENTS } from 'src/models/Document'
import {ATTACHMENT_TYPES} from '../../../configs/AttachmentTypes'

export default {
  [DOCUMENTS.CITIZEN_CARD]: {
    identificationCardFront: ATTACHMENT_TYPES.CELULA_CIUDADANIA_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.CELULA_CIUDADANIA_BACK
  },
  [DOCUMENTS.FOREIGN_CARD]: {
    identificationCardFront: ATTACHMENT_TYPES.CELULA_EXTRANJERIA_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.CELULA_EXTRANJERIA_BACK
  },
  termAndConditions: ATTACHMENT_TYPES.TERMS_AND_CONDITIONS_ACCEPTANCE,
}
