import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  rne_label: {
    defaultMessage: 'RNE',
    id: 'form.field.rne.label'
  },
  rne_invalid: {
    defaultMessage: 'Invalid RNE',
    id: 'form.field.rne.invalid'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { documents } = {} } = injection
  const { document: defaultValue } = documents && documents.find(({ type }) => type == 2) || {}

  const invalidRNE = intl.formatMessage(messages['rne_invalid'])

  return {
    id: 'rne',
    label: intl.formatMessage(messages['rne_label']),
    placeholder: 'XXXXXXX-X',
    mask: '9999999-9',
    defaultValue,
    validations: yup.string().min(7, invalidRNE)
  }
}
