import { getContext } from 'src/infra/Api/Api.context'
import Consultants from 'src/viewModels/Consultants'

export const CONSULTANTS_INDICATION_VERIFY = 'natura-caduni/CONSULTANTS/CONSULTANTS_INDICATION_VERIFY'
export const types = [CONSULTANTS_INDICATION_VERIFY]

export function consultantsIndicationVerifyReducer(state, action, dependencies) {
  const consultants = new Consultants([], dependencies)
  const { isVerified } = action.payload

  return {
    loading: false,
    error: false,
    isVerified,
    consultants
  }
}

export function consultantsIndicationVerify(payload) {
  const { dispatch } = getContext()

  return dispatch({
    type: CONSULTANTS_INDICATION_VERIFY,
    payload
  })
}
