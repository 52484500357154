import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

class ListItemOfflineSchema extends Entity {
  static SCHEMA = {
    label: adapter(yup.string()),
    key: adapter(yup.string()),
    type: {
      validator: adapter(yup.string()),
      defaultValue: 'default'
    },
    connection: adapter(yup.boolean()),
    onChange: adapter(yup.object())
  }
}

export default ListItemOfflineSchema
