import User from 'src/models/User'

export const REHYDRATE = 'persist/REHYDRATE'
export const types = [REHYDRATE]

export function rehydrateReducer(state, action, dependencies) {
  const { payload: { user: { user } = {} } = {} } = action

  return {
    ...state,
    user: new User(user, dependencies)
  }
}
