import PeopleRegister from 'src/viewModels/PeopleRegister'
import { consultantsById }  from 'src/reducers/Consultants/Consultants.getById'

import { PEOPLE_REGISTER_REHYDRATE, reducerPeopleRegisterRehydrate } from './PeopleRegister.rehydrate'
import { PEOPLE_REGISTER_SAVE, reducerPeopleRegisterSave, savePerson } from './PeopleRegister.save'
import { PEOPLE_REGISTER_REMOVE, reducerPeopleRegisterRemove, removePerson } from './PeopleRegister.remove'

const dependencies = {
  savePerson,
  removePerson,
  consultantsById,
}

const initialState = {
  peopleRegister: new PeopleRegister([], dependencies)
}

export default function reducer(state = initialState, action) {
  if (action.type === PEOPLE_REGISTER_REHYDRATE)
    return reducerPeopleRegisterRehydrate(state, action, dependencies)

  if (action.type === PEOPLE_REGISTER_SAVE)
    return reducerPeopleRegisterSave(state, action, dependencies)

  if (action.type === PEOPLE_REGISTER_REMOVE)
    return reducerPeopleRegisterRemove(state, action, dependencies)

  return state
}
