
import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import EmailValidation from 'src/viewModels/EmailValidation'

export const EMAIL_VERIFY_LOADING = 'natura-caduni/EMAIL_VERIFY_LOADING'
export const EMAIL_VERIFY_SUCCESS = 'natura-caduni/EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_ERROR = 'natura-caduni/EMAIL_VERIFY_ERROR'

export const types = [EMAIL_VERIFY_LOADING, EMAIL_VERIFY_SUCCESS, EMAIL_VERIFY_ERROR]

export function emailVerifyReducer(state, action, dependences) {
  let { error, emailValidation } = state
  const { payload, type } = action

  const hasSuccess = type === EMAIL_VERIFY_SUCCESS
  const hasError = type === EMAIL_VERIFY_ERROR
  const loading = type === EMAIL_VERIFY_LOADING

  if (hasSuccess) {
    const { data } = payload

    const verified = data ? data.verified : true

    emailValidation = new EmailValidation({ verified }, dependences)
    error = false
  }

  if (hasError) {
    error = new Error(action.error)
  }

  return {
    loading,
    emailValidation,
    error
  }
}

export function verifyEmail(data) {
  const {
    dispatch,
    urls: { EMAIL_VALIDATION },
    locale: { id },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: EMAIL_VALIDATION(data),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
        },
        data: {
          signature: data.signature
        }
      }
    }
  })
}
