import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  nationality_label: {
    defaultMessage: 'Nationality',
    id: 'form.field.nationality.label'
  },
  nationality_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.nationality.placeholder'
  },
  nationality_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.nationality.no.options.message'
  }
})

export const nationalitiesMessages = defineMessages({
  nationality_afghan: {
    defaultMessage: 'Afghan',
    id: 'form.field.nationality.afghan'
  },
  nationality_albanian: {
    defaultMessage: 'Albanian',
    id: 'form.field.nationality.albanian'
  },
  nationality_american: {
    defaultMessage: 'American',
    id: 'form.field.nationality.american'
  },
  nationality_andorran: {
    defaultMessage: 'Andorran',
    id: 'form.field.nationality.andorran'
  },
  nationality_angolan: {
    defaultMessage: 'Angolan',
    id: 'form.field.nationality.angolan'
  },
  nationality_anguillan: {
    defaultMessage: 'Anguillan',
    id: 'form.field.nationality.anguillan'
  },
  nationality_antartic: {
    defaultMessage: 'Antartic',
    id: 'form.field.nationality.antartic'
  },
  nationality_antiguans: {
    defaultMessage: 'Antiguans',
    id: 'form.field.nationality.antiguans'
  },
  nationality_arab: {
    defaultMessage: 'Arab',
    id: 'form.field.nationality.arab'
  },
  nationality_arubean: {
    defaultMessage: 'Arubean',
    id: 'form.field.nationality.arubean'
  },
  nationality_algerian: {
    defaultMessage: 'Algerian',
    id: 'form.field.nationality.algerian'
  },
  nationality_argentinean: {
    defaultMessage: 'Argentinean',
    id: 'form.field.nationality.argentinean'
  },
  nationality_armenian: {
    defaultMessage: 'Armenian',
    id: 'form.field.nationality.armenian'
  },
  nationality_australian: {
    defaultMessage: 'Australian',
    id: 'form.field.nationality.australian'
  },
  nationality_austrian: {
    defaultMessage: 'Austrian',
    id: 'form.field.nationality.austrian'
  },
  nationality_azerbaijani: {
    defaultMessage: 'Azerbaijani',
    id: 'form.field.nationality.azerbaijani'
  },

  nationality_bahamian: {
    defaultMessage: 'Bahamian',
    id: 'form.field.nationality.bahamian'
  },
  nationality_bahraini: {
    defaultMessage: 'Bahraini',
    id: 'form.field.nationality.bahraini'
  },
  nationality_bangladeshi: {
    defaultMessage: 'Bangladeshi',
    id: 'form.field.nationality.bangladeshi'
  },
  nationality_barbadian: {
    defaultMessage: 'Barbadian',
    id: 'form.field.nationality.barbadian'
  },
  nationality_batswana: {
    defaultMessage: 'Batswana',
    id: 'form.field.nationality.batswana'
  },
  nationality_belarusian: {
    defaultMessage: 'Belarusian',
    id: 'form.field.nationality.belarusian'
  },
  nationality_belgian: {
    defaultMessage: 'Belgian',
    id: 'form.field.nationality.belgian'
  },
  nationality_belizean: {
    defaultMessage: 'Belizean',
    id: 'form.field.nationality.belizean'
  },
  nationality_beninese: {
    defaultMessage: 'Beninese',
    id: 'form.field.nationality.beninese'
  },
  nationality_bermudian: {
    defaultMessage: 'Bermudian',
    id: 'form.field.nationality.bermudian'
  },
  nationality_bhutanese: {
    defaultMessage: 'Bhutanese',
    id: 'form.field.nationality.bhutanese'
  },
  nationality_bolivian: {
    defaultMessage: 'Bolivian',
    id: 'form.field.nationality.bolivian'
  },
  nationality_bosnian: {
    defaultMessage: 'Bosnian',
    id: 'form.field.nationality.bosnian'
  },
  nationality_bouvet_island: {
    defaultMessage: 'Bouvet Island',
    id: 'form.field.nationality.bouvet_island'
  },
  nationality_brazilian: {
    defaultMessage: 'Brazilian',
    id: 'form.field.nationality.brazilian'
  },
  nationality_british: {
    defaultMessage: 'British',
    id: 'form.field.nationality.british'
  },
  nationality_bruneian: {
    defaultMessage: 'Bruneian',
    id: 'form.field.nationality.bruneian'
  },
  nationality_bulgarian: {
    defaultMessage: 'Bulgarian',
    id: 'form.field.nationality.bulgarian'
  },
  nationality_burkinabe: {
    defaultMessage: 'Burkinabe',
    id: 'form.field.nationality.burkinabe'
  },
  nationality_burmese: {
    defaultMessage: 'Burmese',
    id: 'form.field.nationality.burmese'
  },
  nationality_burundian: {
    defaultMessage: 'Burundian',
    id: 'form.field.nationality.burundian'
  },

  nationality_cambodian: {
    defaultMessage: 'Cambodian',
    id: 'form.field.nationality.cambodian'
  },
  nationality_cameroonian: {
    defaultMessage: 'Cameroonian',
    id: 'form.field.nationality.cameroonian'
  },
  nationality_canadian: {
    defaultMessage: 'Canadian',
    id: 'form.field.nationality.canadian'
  },
  nationality_cape_verdean: {
    defaultMessage: 'Cape Verdean',
    id: 'form.field.nationality.cape_verdean'
  },
  nationality_central_african: {
    defaultMessage: 'Central African',
    id: 'form.field.nationality.central_african'
  },
  nationality_chadian: {
    defaultMessage: 'Chadian',
    id: 'form.field.nationality.chadian'
  },
  nationality_chilean: {
    defaultMessage: 'Chilean',
    id: 'form.field.nationality.chilean'
  },
  nationality_chinese: {
    defaultMessage: 'Chinese',
    id: 'form.field.nationality.chinese'
  },
  nationality_colombian: {
    defaultMessage: 'Colombian',
    id: 'form.field.nationality.colombian'
  },
  nationality_comoran: {
    defaultMessage: 'Comoran',
    id: 'form.field.nationality.comoran'
  },
  nationality_congolese: {
    defaultMessage: 'Congolese',
    id: 'form.field.nationality.congolese'
  },
  nationality_cook_island: {
    defaultMessage: 'Cook Islands',
    id: 'form.field.nationality.cook_island'
  },
  nationality_costa_rican: {
    defaultMessage: 'Costa Rican',
    id: 'form.field.nationality.costa_rican'
  },
  nationality_corean: {
    defaultMessage: 'Corean',
    id: 'form.field.nationality.corean'
  },
  nationality_croatian: {
    defaultMessage: 'Croatian',
    id: 'form.field.nationality.croatian'
  },
  nationality_cuban: {
    defaultMessage: 'Cuban',
    id: 'form.field.nationality.cuban'
  },
  nationality_cypriot: {
    defaultMessage: 'Cypriot',
    id: 'form.field.nationality.cypriot'
  },
  nationality_czech: {
    defaultMessage: 'Czech',
    id: 'form.field.nationality.czech'
  },

  nationality_danish: {
    defaultMessage: 'Danish',
    id: 'form.field.nationality.danish'
  },
  nationality_djibouti: {
    defaultMessage: 'Djibouti',
    id: 'form.field.nationality.djibouti'
  },
  nationality_dominican: {
    defaultMessage: 'Dominican',
    id: 'form.field.nationality.dominican'
  },
  nationality_dutch: {
    defaultMessage: 'Dutch',
    id: 'form.field.nationality.dutch'
  },

  nationality_east_timorese: {
    defaultMessage: 'East Timorese',
    id: 'form.field.nationality.east_timorese'
  },
  nationality_ecuadorean: {
    defaultMessage: 'Ecuadorean',
    id: 'form.field.nationality.ecuadorean'
  },
  nationality_egyptian: {
    defaultMessage: 'Egyptian',
    id: 'form.field.nationality.egyptian'
  },
  nationality_equatorial_guinean: {
    defaultMessage: 'Equatorial Guinean',
    id: 'form.field.nationality.equatorial_guinean'
  },
  nationality_eritrean: {
    defaultMessage: 'Eritrean',
    id: 'form.field.nationality.eritrean'
  },
  nationality_estonian: {
    defaultMessage: 'Estonian',
    id: 'form.field.nationality.estonian'
  },
  nationality_ethiopian: {
    defaultMessage: 'Ethiopian',
    id: 'form.field.nationality.ethiopian'
  },

  nationality_fijian: {
    defaultMessage: 'Fijian',
    id: 'form.field.nationality.fijian'
  },
  nationality_filipino: {
    defaultMessage: 'Filipino',
    id: 'form.field.nationality.filipino'
  },
  nationality_finnish: {
    defaultMessage: 'Finnish',
    id: 'form.field.nationality.finnish'
  },
  nationality_french: {
    defaultMessage: 'French',
    id: 'form.field.nationality.french'
  },

  nationality_gabonese: {
    defaultMessage: 'Gabonese',
    id: 'form.field.nationality.gabonese'
  },
  nationality_gambian: {
    defaultMessage: 'Gambian',
    id: 'form.field.nationality.gambian'
  },
  nationality_georgian: {
    defaultMessage: 'Georgian',
    id: 'form.field.nationality.georgian'
  },
  nationality_german: {
    defaultMessage: 'German',
    id: 'form.field.nationality.german'
  },
  nationality_gibraltar: {
    defaultMessage: 'Gibraltar',
    id: 'form.field.nationality.gibraltar'
  },
  nationality_ghanaian: {
    defaultMessage: 'Ghanaian',
    id: 'form.field.nationality.ghanaian'
  },
  nationality_greek: {
    defaultMessage: 'Greek',
    id: 'form.field.nationality.greek'
  },
  nationality_grenadian: {
    defaultMessage: 'Grenadian',
    id: 'form.field.nationality.grenadian'
  },
  nationality_guatemalan: {
    defaultMessage: 'Guatemalan',
    id: 'form.field.nationality.guatemalan'
  },
  nationality_guinea_bissauan: {
    defaultMessage: 'Guinea-Bissauan',
    id: 'form.field.nationality.guinea_bissauan'
  },
  nationality_guinean: {
    defaultMessage: 'Guinean',
    id: 'form.field.nationality.guinean'
  },
  nationality_guyanese: {
    defaultMessage: 'Guyanese',
    id: 'form.field.nationality.guyanese'
  },

  nationality_haitian: {
    defaultMessage: 'Haitian',
    id: 'form.field.nationality.haitian'
  },
  nationality_honduran: {
    defaultMessage: 'Honduran',
    id: 'form.field.nationality.honduran'
  },
  nationality_hong_kong: {
    defaultMessage: 'Hong Kong',
    id: 'form.field.nationality.hong_kong'
  },
  nationality_hungarian: {
    defaultMessage: 'Hungarian',
    id: 'form.field.nationality.hungarian'
  },

  nationality_i_kiribati: {
    defaultMessage: 'I-Kiribati',
    id: 'form.field.nationality.i_kiribati'
  },
  nationality_icelander: {
    defaultMessage: 'Icelander',
    id: 'form.field.nationality.icelander'
  },
  nationality_indian: {
    defaultMessage: 'Indian',
    id: 'form.field.nationality.indian'
  },
  nationality_indonesian: {
    defaultMessage: 'Indonesian',
    id: 'form.field.nationality.indonesian'
  },
  nationality_iranian: {
    defaultMessage: 'Iranian',
    id: 'form.field.nationality.iranian'
  },
  nationality_iraqi: {
    defaultMessage: 'Iraqi',
    id: 'form.field.nationality.iraqi'
  },
  nationality_irish: {
    defaultMessage: 'Irish',
    id: 'form.field.nationality.irish'
  },
  nationality_israeli: {
    defaultMessage: 'Israeli',
    id: 'form.field.nationality.israeli'
  },
  nationality_italian: {
    defaultMessage: 'Italian',
    id: 'form.field.nationality.italian'
  },
  nationality_ivorian: {
    defaultMessage: 'Ivorian',
    id: 'form.field.nationality.ivorian'
  },

  nationality_jamaican: {
    defaultMessage: 'Jamaican',
    id: 'form.field.nationality.jamaican'
  },
  nationality_japanese: {
    defaultMessage: 'Japanese',
    id: 'form.field.nationality.japanese'
  },
  nationality_jordanian: {
    defaultMessage: 'Jordanian',
    id: 'form.field.nationality.jordanian'
  },

  nationality_kazakhstani: {
    defaultMessage: 'Kazakhstani',
    id: 'form.field.nationality.kazakhstani'
  },
  nationality_kenyan: {
    defaultMessage: 'Kenyan',
    id: 'form.field.nationality.kenyan'
  },
  nationality_kittian_and_nevisian: {
    defaultMessage: 'Kittian and Nevisian',
    id: 'form.field.nationality.kittian_and_nevisian'
  },
  nationality_kuwaiti: {
    defaultMessage: 'Kuwaiti',
    id: 'form.field.nationality.kuwaiti'
  },
  nationality_kyrgyz: {
    defaultMessage: 'Kyrgyz',
    id: 'form.field.nationality.kyrgyz'
  },

  nationality_laotian: {
    defaultMessage: 'Laotian',
    id: 'form.field.nationality.laotian'
  },
  nationality_latvian: {
    defaultMessage: 'Latvian',
    id: 'form.field.nationality.latvian'
  },
  nationality_lebanese: {
    defaultMessage: 'Lebanese',
    id: 'form.field.nationality.lebanese'
  },
  nationality_liberian: {
    defaultMessage: 'Liberian',
    id: 'form.field.nationality.liberian'
  },
  nationality_libyan: {
    defaultMessage: 'Libyan',
    id: 'form.field.nationality.libyan'
  },
  nationality_liechtensteiner: {
    defaultMessage: 'Liechtensteiner',
    id: 'form.field.nationality.liechtensteiner'
  },
  nationality_lithuanian: {
    defaultMessage: 'Lithuanian',
    id: 'form.field.nationality.lithuanian'
  },
  nationality_luxembourger: {
    defaultMessage: 'Luxembourger',
    id: 'form.field.nationality.luxembourger'
  },

  nationality_macedonian: {
    defaultMessage: 'Macedonian',
    id: 'form.field.nationality.macedonian'
  },
  nationality_malagasy: {
    defaultMessage: 'Malagasy',
    id: 'form.field.nationality.malagasy'
  },
  nationality_malawian: {
    defaultMessage: 'Malawian',
    id: 'form.field.nationality.malawian'
  },
  nationality_malaysian: {
    defaultMessage: 'Malaysian',
    id: 'form.field.nationality.malaysian'
  },
  nationality_maldivian: {
    defaultMessage: 'Maldivian',
    id: 'form.field.nationality.maldivian'
  },
  nationality_malian: {
    defaultMessage: 'Malian',
    id: 'form.field.nationality.malian'
  },
  nationality_maltese: {
    defaultMessage: 'Maltese',
    id: 'form.field.nationality.maltese'
  },
  nationality_marshallese: {
    defaultMessage: 'Marshallese',
    id: 'form.field.nationality.marshallese'
  },
  nationality_mauritanian: {
    defaultMessage: 'Mauritanian',
    id: 'form.field.nationality.mauritanian'
  },
  nationality_mauritian: {
    defaultMessage: 'Mauritian',
    id: 'form.field.nationality.mauritian'
  },
  nationality_mexican: {
    defaultMessage: 'Mexican',
    id: 'form.field.nationality.mexican'
  },
  nationality_micronesian: {
    defaultMessage: 'Micronesian',
    id: 'form.field.nationality.micronesian'
  },
  nationality_moldovan: {
    defaultMessage: 'Moldovan',
    id: 'form.field.nationality.moldovan'
  },
  nationality_monacan: {
    defaultMessage: 'Monacan',
    id: 'form.field.nationality.monacan'
  },
  nationality_montserrat: {
    defaultMessage: 'Montserrat',
    id: 'form.field.nationality.montserrat'
  },
  nationality_mongolian: {
    defaultMessage: 'Mongolian',
    id: 'form.field.nationality.mongolian'
  },
  nationality_moroccan: {
    defaultMessage: 'Moroccan',
    id: 'form.field.nationality.moroccan'
  },
  nationality_mosotho: {
    defaultMessage: 'Mosotho',
    id: 'form.field.nationality.mosotho'
  },
  nationality_mozambican: {
    defaultMessage: 'Mozambican',
    id: 'form.field.nationality.mozambican'
  },

  nationality_namibian: {
    defaultMessage: 'Namibian',
    id: 'form.field.nationality.namibian'
  },
  nationality_nauruan: {
    defaultMessage: 'Nauruan',
    id: 'form.field.nationality.nauruan'
  },
  nationality_nepalese: {
    defaultMessage: 'Nepalese',
    id: 'form.field.nationality.nepalese'
  },
  nationality_new_zealander: {
    defaultMessage: 'New Zealander',
    id: 'form.field.nationality.new_zealander'
  },
  nationality_ni_vanuatu: {
    defaultMessage: 'Ni-Vanuatu',
    id: 'form.field.nationality.ni_vanuatu'
  },
  nationality_nicaraguan: {
    defaultMessage: 'Nicaraguan',
    id: 'form.field.nationality.nicaraguan'
  },
  nationality_nigerian: {
    defaultMessage: 'Nigerian',
    id: 'form.field.nationality.nigerian'
  },
  nationality_nigerien: {
    defaultMessage: 'Nigerien',
    id: 'form.field.nationality.nigerien'
  },
  nationality_niue_island: {
    defaultMessage: 'Niue Island',
    id: 'form.field.nationality.niue_island'
  },
  nationality_norwegian: {
    defaultMessage: 'Norwegian',
    id: 'form.field.nationality.norwegian'
  },
  nationality_norfolk_island: {
    defaultMessage: 'Norfolk Island',
    id: 'form.field.nationality.norfolk_island'
  },

  nationality_omani: {
    defaultMessage: 'Omani',
    id: 'form.field.nationality.omani'
  },

  nationality_pakistani: {
    defaultMessage: 'Pakistani',
    id: 'form.field.nationality.pakistani'
  },
  nationality_palauan: {
    defaultMessage: 'Palauan',
    id: 'form.field.nationality.palauan'
  },
  nationality_panamanian: {
    defaultMessage: 'Panamanian',
    id: 'form.field.nationality.panamanian'
  },
  nationality_papua_new_guinean: {
    defaultMessage: 'Papua New Guinean',
    id: 'form.field.nationality.papua_new_guinean'
  },
  nationality_paraguayan: {
    defaultMessage: 'Paraguayan',
    id: 'form.field.nationality.paraguayan'
  },
  nationality_peruvian: {
    defaultMessage: 'Peruvian',
    id: 'form.field.nationality.peruvian'
  },
  nationality_polish: {
    defaultMessage: 'Polish',
    id: 'form.field.nationality.polish'
  },
  nationality_portuguese: {
    defaultMessage: 'Portuguese',
    id: 'form.field.nationality.portuguese'
  },

  nationality_qatari: {
    defaultMessage: 'Qatari',
    id: 'form.field.nationality.qatari'
  },

  nationality_romanian: {
    defaultMessage: 'Romanian',
    id: 'form.field.nationality.romanian'
  },
  nationality_russian: {
    defaultMessage: 'Russian',
    id: 'form.field.nationality.russian'
  },
  nationality_rwandan: {
    defaultMessage: 'Rwandan',
    id: 'form.field.nationality.rwandan'
  },

  nationality_saint_lucian: {
    defaultMessage: 'Saint Lucian',
    id: 'form.field.nationality.saint_lucian'
  },
  nationality_salvadoran: {
    defaultMessage: 'Salvadoran',
    id: 'form.field.nationality.salvadoran'
  },
  nationality_samoan: {
    defaultMessage: 'Samoan',
    id: 'form.field.nationality.samoan'
  },
  nationality_san_marinese: {
    defaultMessage: 'San Marinese',
    id: 'form.field.nationality.san_marinese'
  },
  nationality_san_vicent: {
    defaultMessage: 'Saint Vincent and the Grenadines',
    id: 'form.field.nationality.san_vicent'
  },
  nationality_saint_helenians: {
    defaultMessage: 'Saint Helenians',
    id: 'form.field.nationality.saint_helenians'
  },
  nationality_sao_tomean: {
    defaultMessage: 'Sao Tomean',
    id: 'form.field.nationality.sao_tomean'
  },
  nationality_saudi: {
    defaultMessage: 'Saudi',
    id: 'form.field.nationality.saudi'
  },
  nationality_senegalese: {
    defaultMessage: 'Senegalese',
    id: 'form.field.nationality.senegalese'
  },
  nationality_seychellois: {
    defaultMessage: 'Seychellois',
    id: 'form.field.nationality.seychellois'
  },
  nationality_sierra_leonean: {
    defaultMessage: 'Sierra Leonean',
    id: 'form.field.nationality.sierra_leonean'
  },
  nationality_singaporean: {
    defaultMessage: 'Singaporean',
    id: 'form.field.nationality.singaporean'
  },
  nationality_slovakian: {
    defaultMessage: 'Slovakian',
    id: 'form.field.nationality.slovakian'
  },
  nationality_slovenian: {
    defaultMessage: 'Slovenian',
    id: 'form.field.nationality.slovenian'
  },
  nationality_solomon_islander: {
    defaultMessage: 'Solomon Islander',
    id: 'form.field.nationality.solomon_islander'
  },
  nationality_somali: {
    defaultMessage: 'Somali',
    id: 'form.field.nationality.somali'
  },
  nationality_south_african: {
    defaultMessage: 'South African',
    id: 'form.field.nationality.south_african'
  },
  nationality_spanish: {
    defaultMessage: 'Spanish',
    id: 'form.field.nationality.spanish'
  },
  nationality_sri_lankan: {
    defaultMessage: 'Sri Lankan',
    id: 'form.field.nationality.sri_lankan'
  },
  nationality_sudanese: {
    defaultMessage: 'Sudanese',
    id: 'form.field.nationality.sudanese'
  },
  nationality_surinamer: {
    defaultMessage: 'Surinamer',
    id: 'form.field.nationality.surinamer'
  },
  nationality_stateless: {
    defaultMessage: 'Stateless',
    id: 'form.field.nationality.stateless'
  },
  nationality_swazi: {
    defaultMessage: 'Swazi',
    id: 'form.field.nationality.swazi'
  },
  nationality_swedish: {
    defaultMessage: 'Swedish',
    id: 'form.field.nationality.swedish'
  },
  nationality_swiss: {
    defaultMessage: 'Swiss',
    id: 'form.field.nationality.swiss'
  },
  nationality_syrian: {
    defaultMessage: 'Syrian',
    id: 'form.field.nationality.syrian'
  },

  nationality_taiwanese: {
    defaultMessage: 'Taiwanese',
    id: 'form.field.nationality.taiwanese'
  },
  nationality_tajik: {
    defaultMessage: 'Tajik',
    id: 'form.field.nationality.tajik'
  },
  nationality_tanzanian: {
    defaultMessage: 'Tanzanian',
    id: 'form.field.nationality.tanzanian'
  },
  nationality_thai: {
    defaultMessage: 'Thai',
    id: 'form.field.nationality.thai'
  },
  nationality_togolese: {
    defaultMessage: 'Togolese',
    id: 'form.field.nationality.togolese'
  },
  nationality_tokelau: {
    defaultMessage: 'Tokelau',
    id: 'form.field.nationality.tokelau'
  },
  nationality_tongan: {
    defaultMessage: 'Tongan',
    id: 'form.field.nationality.tongan'
  },
  nationality_trinidadian_or_tobagonian: {
    defaultMessage: 'Trinidadian or Tobagonian',
    id: 'form.field.nationality.trinidadian_or_tobagonian'
  },
  nationality_tunisian: {
    defaultMessage: 'Tunisian',
    id: 'form.field.nationality.tunisian'
  },
  nationality_turkish: {
    defaultMessage: 'Turkish',
    id: 'form.field.nationality.turkish'
  },
  nationality_turkmens: {
    defaultMessage: 'Turkmens',
    id: 'form.field.nationality.turkmens'
  },
  nationality_tuvaluan: {
    defaultMessage: 'Tuvaluan',
    id: 'form.field.nationality.tuvaluan'
  },
  nationality_turks_caicos: {
    defaultMessage: 'Turks and Caicos Islands',
    id: 'form.field.nationality.turks_caicos'
  },

  nationality_ugandan: {
    defaultMessage: 'Ugandan',
    id: 'form.field.nationality.ugandan'
  },
  nationality_ukrainian: {
    defaultMessage: 'Ukrainian',
    id: 'form.field.nationality.ukrainian'
  },
  nationality_us_minor_islands: {
    defaultMessage: 'United States Minor Outlying Islands',
    id: 'form.field.nationality.us_minor_islands'
  },
  nationality_uruguayan: {
    defaultMessage: 'Uruguayan',
    id: 'form.field.nationality.uruguayan'
  },
  nationality_uzbekistani: {
    defaultMessage: 'Uzbekistani',
    id: 'form.field.nationality.uzbekistani'
  },

  nationality_vaticano: {
    defaultMessage: 'Vaticano',
    id: 'form.field.nationality.vaticano'
  },
  nationality_venezuelan: {
    defaultMessage: 'Venezuelan',
    id: 'form.field.nationality.venezuelan'
  },
  nationality_vietnamese: {
    defaultMessage: 'Vietnamese',
    id: 'form.field.nationality.vietnamese'
  },

  nationality_wallis_futuna_island: {
    defaultMessage: 'Wallis and Futuna Islands',
    id: 'form.field.nationality.wallis_futuna_island'
  },
  nationality_western_sahara: {
    defaultMessage: 'Western Sahara',
    id: 'form.field.nationality.western_sahara'
  },

  nationality_yemenite: {
    defaultMessage: 'Yemenite',
    id: 'form.field.nationality.yemenite'
  },
  nationality_yugoslavian: {
    defaultMessage: 'Yugoslavian',
    id: 'form.field.nationality.yugoslavian'
  },

  nationality_zairense: {
    defaultMessage: 'Zairense',
    id: 'form.field.nationality.zairense'
  },
  nationality_zambian: {
    defaultMessage: 'Zambian',
    id: 'form.field.nationality.zambian'
  },
  nationality_zimbabwean: {
    defaultMessage: 'Zimbabwean',
    id: 'form.field.nationality.zimbabwean'
  }
})
