import { createDocumentTypeOptions as createDocumentTypeOptionsForChile } from './create-document-type-options.chl'
import { createDocumentTypeOptions as createDocumentTypeOptionsForPeru } from './create-document-type-options.per'
import { createDocumentTypeOptions as createDocumentTypeOptionsForMalaysia } from './create-document-type-options.mys'
import { createDocumentTypeOptions as createDocumentTypeOptionsForColombia } from './create-document-type-options.col'
import { createDocumentTypeOptions as createDocumentTypeOptionsForArgentina } from './create-document-type-options.arg'
import { createDocumentTypeOptions as createDocumentTypeOptionsForAnyCountry } from './create-document-type-options.default'

const strategies = {
  CHL: createDocumentTypeOptionsForChile,
  PER: createDocumentTypeOptionsForPeru,
  MYS: createDocumentTypeOptionsForMalaysia,
  COL: createDocumentTypeOptionsForColombia,
  ARG: createDocumentTypeOptionsForArgentina,
  __default__: createDocumentTypeOptionsForAnyCountry
}

export const createDocumentTypeOptions = ({ localeId, messages, candidateId, intl }) => {
  const strategy = strategies[localeId] ?? strategies.__default__

  return strategy({ messages, candidateId, intl })
}
