import ArgentinaUserRole from './userRoles.arg'
import ChileUserRole from './userRoles.chl'
import ColombiaUserRole from './userRoles.col'
import MalaysiaUserRole from './userRoles.mys.js'
import PeruUserRole from './userRoles.per.js'
import AvonMexicoUserRole from './userRoles.avon.mex'
import NaturaMexicoUserRole from './userRoles.natura.mex'
import NaturaEcuadorUserRole from './userRoles.ecu'
import AvonBrazilUserRole from './userRoles.avon.bra'
import { getContext } from '../../../infra/Api/Api.context'
import { TENANT_ID } from '../../tenant/tenant-id'
import AvonArgentinaUserRole from './userRoles.avon.arg'

const strategies = (tenantId, roles) => {
  switch(tenantId) {
    case TENANT_ID.NATURA_ARGENTINA: return new ArgentinaUserRole(roles)
    case TENANT_ID.NATURA_CHILE: return new ChileUserRole(roles)
    case TENANT_ID.NATURA_COLOMBIA: return new ColombiaUserRole(roles)
    case TENANT_ID.NATURA_MALAYSIA: return new MalaysiaUserRole(roles)
    case TENANT_ID.NATURA_PERU: return new PeruUserRole(roles)
    case TENANT_ID.AVON_MEXICO: return new AvonMexicoUserRole(roles)
    case TENANT_ID.AVON_BRAZIL: return new AvonBrazilUserRole(roles)
    case TENANT_ID.NATURA_MEXICO: return new NaturaMexicoUserRole(roles)
    case TENANT_ID.AVON_ARGENTINA: return new AvonArgentinaUserRole(roles)
    case TENANT_ID.NATURA_ECUADOR: return new NaturaEcuadorUserRole(roles)
  }
}

export const getUserRole = (roles) => {
  const { locale: { tenantId } } = getContext()
  const strategy = strategies(tenantId, roles)
  return strategy
}
