import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'

const bem = bemClassName.bind(null, 'take-photo')

import MESSAGES from './TakePhoto.messages'

const PhotoPreview = function ({ accept, discard, photo, intl }) {
  return (
    <div className={bem()}>
      <section className={bem('content')}>
        <img className={bem('stream')} src={photo} />
        <div className={bem('gridlines')} />
        <footer className={bem('actions', 'photo-taken')}>
          <button className={bem('actions__button', 'trash')} onClick={discard}>
            <Icon name="trash" className={bem('actions__icon', 'trash')} />
            { intl.formatMessage(MESSAGES.photoPreviewDiscart) }
          </button>
          <button className={bem('actions__button', 'accept')} onClick={accept}>
            <Icon name="check" className={bem('actions__icon', 'accept')} />
            { intl.formatMessage(MESSAGES.photoPreviewConfirm) }
          </button>
        </footer>
      </section>
    </div>
  )
}

PhotoPreview.propTypes = {
  accept: PropTypes.func,
  discard: PropTypes.func,
  photo: PropTypes.string,
  intl: PropTypes.object
}

export default injectIntl(PhotoPreview)
