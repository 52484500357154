export const SORT = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  APPROVAL_DATE: 'approvalDate_desc',
  REGISTRATION_DATE_ASC: 'registrationDate_asc',
  REGISTRATION_DATE_DESC: 'registrationDate_desc',
}

export const PAYMENT_PLANS = {
  CREDIT_PAYMENT: 1,
  PREPAID_PAYMENT: 2
}

export const SOURCE_SYSTEM = {
  REGISTERED_BY_ME: 5,
  THIRD_PARTY_REGISTER: 4
}
