import checkPersonRoles from './checkPersonRoles'

const PERMITTED_CSC_ROLES = [12, 13]

export class LoginBrazil {
  static errors = {
    missingParameters: {
      message: 'Missing required parameters',
    },
    unauthorizedRole: {
      message: 'Unauthorized role'
    }
  }

  static unauthorizedURL = process.env.CSC_REDIRECT_URI

  constructor({ getSellerById } = {}) {
    Object.assign(this, { getSellerById })
  }

  async execute({ accessToken, sellerId, isCsc = true }) {
    if (!sellerId || !accessToken) {
      return { error: LoginBrazil.errors.missingParameters }
    }

    const { error: getSellerByIdError, data } = await this.getSellerById({ accessToken, sellerId })

    if (getSellerByIdError) return { error: getSellerByIdError }

    const { commercialStructure = {}, name, roles = [] } = data

    const notAllowedToLoginAsRegistrationTeamMember =
      isCsc && !checkPersonRoles({ allowedRoles: PERMITTED_CSC_ROLES, rolesList: roles }).allowed

    if (notAllowedToLoginAsRegistrationTeamMember) {
      return {
        error: {
          ...LoginBrazil.errors.unauthorizedRole,
          redirectURL: LoginBrazil.unauthorizedURL
        }
      }
    }

    return {
      hierarchyId: commercialStructure.code,
      name,
      roles: {
        CSC: !!isCsc,
        otherRoles: roles
      },
      sellerId,
    }
  }
}
