import Consultants from 'src/viewModels/Consultants'

import { resetConsultants, consultantsResetReducer, types as consultantsResetReducerTypes }  from './Consultants.reset'
import { consultantsIndicationVerify, consultantsIndicationVerifyReducer, types as consultantsIndicationVerifyTypes }  from './Consultants.indicationVerify'
import { consultantsById, consultantsByIdReducer, types as consultantsByIdTypes}  from './Consultants.getById'
import { consultantsByName, consultantsByNameReducer, types as consultantsByNameTypes } from './Consultants.getByName'
import { consultantsByDocuments, consultantsByDocumentsReducer, types as consultantsByDocumentsTypes } from './Consultants.getByDocuments'

const dependencies = {
  consultantsByIdReducer,
  consultantsByNameReducer,
  consultantsByDocumentsReducer,
  consultantsResetReducer,
  consultantsIndicationVerifyReducer
}

const consultantsDependencies = {
  resetConsultants,
  consultantsById,
  consultantsByName,
  consultantsByDocuments,
  consultantsIndicationVerify
}

const initialState = {
  consultants: new Consultants([], consultantsDependencies),
  loading: false,
  error: false,
  isVerified: false
}

export default function reducer(state = initialState, action = {}, _, injection = {}){
  const {
    consultantsByIdReducer,
    consultantsByNameReducer,
    consultantsByDocumentsReducer,
    consultantsIndicationVerifyReducer
  } = { ...dependencies, ...injection }

  if ([].concat(consultantsByIdTypes).includes(action.type))
    return consultantsByIdReducer(state, action, consultantsDependencies)

  if ([].concat(consultantsByNameTypes).includes(action.type))
    return consultantsByNameReducer(state, action, consultantsDependencies)

  if ([].concat(consultantsByDocumentsTypes).includes(action.type))
    return consultantsByDocumentsReducer(state, action, consultantsDependencies)

  if ([].concat(consultantsResetReducerTypes).includes(action.type))
    return consultantsResetReducer(state, action, consultantsDependencies)

  if ([].concat(consultantsIndicationVerifyTypes).includes(action.type))
    return consultantsIndicationVerifyReducer(state, action, consultantsDependencies)

  return state
}
