import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import bemClassName from 'bem-classname'

import Thumbnail from './Thumbnail'

const FilePlaceholder = ({ error, success, subtitle, title, thumbnail }) => {
  const classes = bemClassName.bind(null, 'defaultFile')

  return (
    <div>
      <Typography
        className={classes('placeholderTitle')}
        component={'h2'}
        variant={'h2'}
      >
        {title}
      </Typography>
      {thumbnail ? (
        <Thumbnail error={!!error} success={success} thumbnail={thumbnail} />
      ) : (
        <Typography
          className={classes('placeholderSubtitle')}
          component={'p'}
          paragraph={true}
          variant={'body1'}
        >
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default FilePlaceholder

FilePlaceholder.propTypes = {
  error: PropTypes.bool,
  subtitle: PropTypes.node,
  success: PropTypes.bool,
  thumbnail: PropTypes.string,
  title: PropTypes.string
}
