export function onSubmition({ person }) {
  return function({ paymentPlan }, callback) {
    const params = {
      paymentPlan: {
        id: paymentPlan
      },
      reviewStep: { step: 'documents' }
    }

    return person.save(params, callback)
  }
}
