import * as yup from 'yup'
import Maps from './Maps'
import FormTitle from './AddressTitle'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'
import { isDefaultFlowToggleActive } from '../../../config'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    connectivity
  } = injection

  const baseValue = {
    copyAdress: true,
    countryName: 'PERU'
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)
  const MAPS = { ...Maps(intl, injection), onChange: onMapChange }
  const GROUP_TITLE = isDefaultFlowToggleActive() ? null : FormTitle(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    itemTitle: GROUP_TITLE,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [MAPS],
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          gmap: getGmapValidation(connectivity),
        })
      )
      .min(1)
      .max(20)
  }
}

function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)

    return connectivity.isOffline
      ? addresses.reduce(reduceAddressOffline, [baseValue])
      : addresses.reduce(reduceAddress, [baseValue])
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress = deliveryAddress && !mailingAddress.isUseToDelivery

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

function reduceAddress(accum, address, index, array) {
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    location,
    street,
    addressUse
  } = address

  const streetDescription = street && street.description
  const confirmed = streetDescription && !!location.lat && !!location.lng

  const newAddress = {
    gmap: {
      location,
      street: streetDescription,
      confirmed
    },
    addressUse,
    countryName
  }

  return [...accum, newAddress]
}

function reduceAddressOffline(accum, address, index, array) {
  const [{ countryName, copyAdress }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    street,
  } = address

  const newAddress = {
    gmap: street,
    countryName,
    copyAdress
  }

  return [...accum, newAddress]
}

function onMapChange(props) {
  const {
    schema: {
      parent,
      group: { id }
    },
    setFieldValue,
    setFieldTouched,
    value
  } = props

  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.gmap`, !value.street)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.references`, false)
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  return value.toString() === this.code
}

function getGmapValidation({ isOffline }) {
  if (isOffline) return yup.string().required()

  return yup
    .object()
    .shape({
      street: yup
        .string()
        .required()
        .nullable(),
      location: yup
        .object()
        .shape({
          lat: yup
            .number()
            .required()
            .nullable(),
          lng: yup
            .number()
            .required()
            .nullable()
        })
        .required(),
      confirmed: yup.bool().required()
    })
    .required()
    .nullable()
}
