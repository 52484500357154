import { store } from '../../../../../../../App.state'
import { BuildStepsMapper } from './BuildStepsMapper'
import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import Loader from '../../../../../../../views/Register/Components/Loader'
import { buildPreRegisterPropsMicrofrontend } from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegister/PreRegisterProps'
import { buildPersonalDataPropsMicrofrontend } from '../../PersonalData/PersonalDataPropsMicrofrontend'
import { buildDocumentsPropsMicrofrontend } from '../../Documents/DocumentsPropsMicrofrontend'
import { buildDocumentationPropsMicroFrontend } from '../../Documentation'
import { buildCompletedMicrofrontendProps } from 'src/configs/Locales/DEFAULT/views/SelfRegister/CompletedMicrofrontend/CompletedMicrofrontendProps'
import { isEnableDigitalStoreStepForAvonBrazilFlowToggleActive } from '../../../../../../../config'

export const buildStepsAvonBrazil = ({ basePath }) => {
  const { user: { user } } = store.getState()


  const IS_DIGITAL_STORE_ENABLED = isEnableDigitalStoreStepForAvonBrazilFlowToggleActive()
  const totalSteps = IS_DIGITAL_STORE_ENABLED ? 6 : 5
  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: totalSteps })

  const overrideFormDefaultWithMfeContainer = {
    components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
  }

  const overrideComponentsPropsBuilderPersonalData = {
    componentsPropsBuilder: buildPersonalDataPropsMicrofrontend
  }

  const defaultStepsRegistration = [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        ...overrideComponentsPropsBuilderPersonalData
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
  ]

  const stepsRegistrationAuthenticated = [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        ...overrideComponentsPropsBuilderPersonalData
      }
    }),
    buildStepsMapper.mapAuthorizationCode({
      currentStep: 3,
      overrideProps: {
        actions: { next: true, previous: true },
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 5,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
  ]

  const insertCompleteStepInBuildStepRegistration = (array, step) => {
    array.push(
      buildStepsMapper.mapCompleted({
        currentStep: step,
        overrideProps: {
          ...overrideFormDefaultWithMfeContainer,
          componentsPropsBuilder: buildCompletedMicrofrontendProps
        }
      })
    )
    return array
  }

  const insertDigitalStoreStepInBuildStepRegistration = (array, step) => {
    array.push(
      buildStepsMapper.mapDigitalStore({
        currentStep: step,
      })
    )
    return array
  }

  const stepsRegistrationDefault = () => {
    let steps = defaultStepsRegistration

    if (IS_DIGITAL_STORE_ENABLED) {
      steps = insertDigitalStoreStepInBuildStepRegistration(steps, 5)
      steps = insertCompleteStepInBuildStepRegistration(steps, 6)

      return steps
    }

    steps = insertCompleteStepInBuildStepRegistration(steps, 6)

    return steps
  }

  if (user.authenticated) {
    let stepsRegistrationForAuthenticated = stepsRegistrationAuthenticated

    stepsRegistrationForAuthenticated = insertCompleteStepInBuildStepRegistration(stepsRegistrationForAuthenticated, 6)

    return stepsRegistrationForAuthenticated
  }

  return stepsRegistrationDefault()
}
