import { connect } from 'react-redux'

import { documentsEnum } from 'src/configs/Fields/Documents/Documents.type'
import Input from 'src/base/Form/Components/Input'
import { DOCUMENTS } from 'src/models/Document'
import { messages } from './Search.messages'
import { createDocumentTypeOptions } from './strategies/create-document-type-options'

export const CANDIDATE_ID = 'candidateId'
const helpMessage = {}

export default function createFields(dependencies) {
  helpMessage[DOCUMENTS.PASSPORT_PERU] = dependencies.intl.formatMessage(messages['passport'])

  const DOCUMENT_TYPE = createDocumentType(dependencies)
  const DOCUMENT = createDocument(dependencies)

  return [DOCUMENT_TYPE, DOCUMENT]
}

function createDocumentType({ intl, people, locale }) {
  const options = createDocumentTypeOptions({ intl, messages, localeId: locale?.id, candidateId: CANDIDATE_ID })

  return {
    id: 'type',
    type: 'select',
    label: null,
    noOptionsMessage: intl.formatMessage(messages['type_no_options_message']),
    options,
    placeholder: intl.formatMessage(messages.search_type_search),
    onChange: documentTypeIdChange({ people })
  }
}

function documentTypeIdChange({ people }) {
  return function ({ value, schema, setFieldValue, setFieldTouched }) {
    const { group: { id }, parent } = schema

    if (!value) people.search({})

    setFieldValue(`${parent}.document`, '', id)
    setFieldTouched(`${parent}.document`, false)
  }
}

function createDocument({ intl, messages }) {
  return {
    id: 'document',
    type: connect(mapStateToProps)(Input),
    disabledBy: ['documents[].type'],
    placeholder: intl.formatMessage(messages.search_document),
  }
}

function mapStateToProps({ locale: { locale } }, ownProps) {
  const { configs: { localization: { documents: { mask: documentsMask } } } } = locale
  const { form: { values: { documents: [{ type } = {}] = [] } } } = ownProps

  return {
    ...ownProps,
    message: helpMessage[type],
    mask: getMask({ type, documentsMask })
  }
}

function getMask({ type, documentsMask }) {
  const isCandidateId = type === CANDIDATE_ID
  const isDNI = type === DOCUMENTS.NATIONAL_REGISTRY

  if (isDNI || isCandidateId)
    return '9999999999999999999999999'

  return documentsMask[documentsEnum[type]]
}
