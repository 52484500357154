export const SORT = {
  NAME_ASC: 'person.fullName_asc',
  NAME_DESC: 'person.fullName_desc',
  REGISTRATION_DATE_ASC: 'person.createdAt_asc',
  REGISTRATION_DATE_DESC: 'person.createdAt_desc'
}

export const PAYMENT_PLANS = {
  CREDIT_PAYMENT: 1,
  PREPAID_PAYMENT: 2
}

export const SOURCE_SYSTEM = {
  REGISTERED_BY_ME: 5,
  THIRD_PARTY_REGISTER: 7
}
