import React from 'react'
import Parcel from 'single-spa-react/parcel'
import { mountRootParcel } from 'single-spa'
import PropTypes from 'prop-types'
import { getContext } from '../../../../infra/Api/Api.context'
import { DIRECTION } from '../../../../reducers/FlowDirection'

const MicroFrontendContainer = ({ mfeId, microFrontendProps = {}, goToNextPage, goToPreviousPage, match }) => {
  const { flowDirection } = getContext().getState()

  const navigationProps = {
    goToNextPage,
    goToPreviousPage,
    bypassStep: flowDirection.flowDirection === DIRECTION.FORWARD ? goToNextPage : goToPreviousPage
  }

  return (
    <Parcel
      mountParcel={mountRootParcel}
      // eslint-disable-next-line
      config={async () => System.import(mfeId)}
      routerMatch={match}
      {...microFrontendProps}
      {...navigationProps}
    />
  )
}

MicroFrontendContainer.propTypes = {
  mfeId: PropTypes.string.isRequired,
  microFrontendProps: PropTypes.object,
  goToNextPage: PropTypes.func,
  goToPreviousPage: PropTypes.func,
  match: PropTypes.object,
}

export default MicroFrontendContainer
