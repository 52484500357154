import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Form from 'src/base/Form'
import RegisterErrorModal from 'src/views/Register/RegisterErrorModal'

import VerifyContacts from './VerifyContacts'
import { PERSON_SAVE_ERROR } from 'src/reducers/Person/Person.save'

import Person from 'src/models/Person'

class FormWithContactValidation extends Component {
  constructor(props) {
    super(props)

    this.state = { shouldVerifyContacts: false }

    this.onSubmit = this.onSubmit.bind(this)
    this.verifyContacts = this.verifyContacts.bind(this)
  }

  onSubmit(data) {
    const { goToNextPage, onSubmit } = this.props
    const person = new Person(data)

    if (!this.shouldSkipVerifyContacts && person.mobilePhone)
      return onSubmit(data, this.verifyContacts)

    return onSubmit(data, goToNextPage)
  }

  async verifyContacts(oldPerson, result) {
    const { onResendToken, goToNextPage, locale: { configs: { localization: { shouldCallGetPersonInVerifyContacts } } } } = this.props

    if (result.type === PERSON_SAVE_ERROR) return null

    const {
      personId,
      mobilePhone
    } = new Person({ ...oldPerson, ...result.payload.data })

    if (mobilePhone.verified)
      return goToNextPage()

    if (!shouldCallGetPersonInVerifyContacts)
      await onResendToken({ personId, sequence: mobilePhone.sequence })

    return this.setState({ shouldVerifyContacts: true })
  }

  get shouldSkipVerifyContacts() {
    const { connectivity: { isOffline }, locale, user } = this.props
    const { configs: { localization: { featureLite } } } = locale

    const areFeatureLite = user.isCSC && featureLite

    return isOffline || areFeatureLite
  }

  render() {
    return (
      <Fragment>
        {this.renderForm()},
        {this.renderVerifyContacts()}
      </Fragment>
    )
  }

  renderForm() {
    const {
      step: { name, actions: { previous, next }, updateRule, title, subtitle },
      connectivity: { isOffline },
      touchAfterReload,
      goToPreviousPage,
      formSchema,
      error
    } = this.props

    return (
      <Form
        title={title}
        subtitle={subtitle}
        className={`${name}__form`}
        key={name}
        error={error}
        updateRule={updateRule}
        formSchema={formSchema}
        touchAfterReload={touchAfterReload}
        onSubmit={this.onSubmit}
        cancelAction={previous ? goToPreviousPage : null}
        hiddenSubmit={!next}
        offline={isOffline}
        modal={RegisterErrorModal}
      />
    )
  }

  renderVerifyContacts() {
    const { shouldVerifyContacts } = this.state
    const { person, goToNextPage, locale: { configs: { localization } } } = this.props

    if (!shouldVerifyContacts)
      return null

    return (
      <VerifyContacts
        key="verifyContact"
        person={person}
        callback={goToNextPage}
        localization={localization}
      />
    )
  }
}

FormWithContactValidation.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  touchAfterReload: PropTypes.array.isRequired,
  formSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  connectivity: PropTypes.object,
  user: PropTypes.object,
  locale: PropTypes.object,
  onResendToken: PropTypes.func
}

export default FormWithContactValidation
