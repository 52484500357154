import { multiClientMiddleware } from 'redux-axios-middleware'

import { history } from 'src/App'
import { logout } from 'src/reducers/User/User.logout'
import { ShowWarning } from 'src/reducers/Warning'

import BRA_CLIENT from './BRA'
import COL_CLIENT from './COL'
import PER_CLIENT from './PER'
import CHL_CLIENT from './CHL'
import MYS_CLIENT from './MYS'
import ARG_CLIENT from './ARG'
import ARG_CMM_CLIENT from './ARG/CMM'
import ARG_BFF_CLIENT from './ARG/BFF'
import MYS_CMM_CLIENT from './MYS/CMM'
import MYS_BFF_CLIENT from './MYS/BFF'
import AWS_S3_CLIENT from './S3'
import PER_CMM_CLIENT from './PER/CMM'
import PER_BFF_CLIENT from './PER/BFF'
import CHL_CMM_CLIENT from './CHL/CMM'
import CHL_BFF_CLIENT from './CHL/BFF'
import COL_CMM_CLIENT from './COL/CMM'
import COL_BFF_CLIENT from './COL/BFF'
import AVON_MEX_CLIENT from './MEX/'
import AVON_MEX_BFF_CLIENT from './MEX/BFF'
import DEFAULT_CLIENT from './DEFAULT'
import DEFAULT_BFF_CLIENT from './DEFAULT/BFF'
import AUTHENTICATION from './Authentication'

import { APP_ROUTES } from 'src/App'

const UNAUTHORIZED_STATUS = [401, 403, 498]
export const CLIENT_NAMES = {
  S3: 'S3',
  DEFAULT_BFF: 'DEFAULT_BFF'
}

export function indentifyUnauthorizedError({ response }) {
  if (!response) return false

  return UNAUTHORIZED_STATUS.includes(response.status)
}

export async function errorHandler({ dispatch, getState }, error) {
  const {
    user: {
      user: { authenticated }
    }
  } = getState()

  const tokenExpired = indentifyUnauthorizedError(error)

  if (authenticated && tokenExpired) {
    dispatch(ShowWarning())
    dispatch(logout())
    history.replace(APP_ROUTES.LOGOUT)
  }

  throw error
}

const middlewareConfig = {
  interceptors: {
    request: [
      // https://github.com/svrcekmichal/redux-axios-middleware#interceptors
      // eslint-disable-next-line no-unused-vars
      function( reduxParams, req) {
        if (shouldSkipRequestUpdate(req)) {
          return req
        }

        return { ...req, headers: getRequestHeaders(req) }

        function shouldSkipRequestUpdate(req){
          return req?.reduxSourceAction?.payload?.client === CLIENT_NAMES.S3
        }
      }
    ],
    response: [
      {
        error: errorHandler
      }
    ]
  }
}

const getRequestHeaders = (req) => {
  const header = { ...req.headers }
  const Authorization = getAuthorization(req)
  addAuthorization(header, Authorization)
  return header
}

const getAuthorization = (req) => {
  const accessToken = req.headers.Authorization || req.headers.authorization
  const xApikey = req.headers['x-api-key']
  return accessToken ?? xApikey
}

const addAuthorization = (header, Authorization) => {
  removeDuplicateValues(header)
  header.Authorization = Authorization
}

const removeDuplicateValues = (header) => {
  if (header.authorization) { delete header.authorization }
  if (header.Authorization) { delete header.Authorization }
}

export const clients = {
  BRA: BRA_CLIENT,
  [CLIENT_NAMES.S3]: AWS_S3_CLIENT,
  PER: PER_CLIENT,
  PER_CMM: PER_CMM_CLIENT,
  PER_BFF: PER_BFF_CLIENT,
  MYS: MYS_CLIENT,
  MYS_CMM: MYS_CMM_CLIENT,
  MYS_BFF: MYS_BFF_CLIENT,
  DEFAULT: DEFAULT_CLIENT,
  [CLIENT_NAMES.DEFAULT_BFF]: DEFAULT_BFF_CLIENT,
  CHL: CHL_CLIENT,
  CHL_CMM: CHL_CMM_CLIENT,
  CHL_BFF: CHL_BFF_CLIENT,
  COL: COL_CLIENT,
  COL_CMM: COL_CMM_CLIENT,
  COL_BFF: COL_BFF_CLIENT,
  ARG: ARG_CLIENT,
  ARG_CMM: ARG_CMM_CLIENT,
  ARG_BFF: ARG_BFF_CLIENT,
  MEX: AVON_MEX_CLIENT,
  MEX_BFF: AVON_MEX_BFF_CLIENT,
  AUTHENTICATION,
}

export default multiClientMiddleware(clients, middlewareConfig)
