import * as yup from 'yup'
import { store } from 'src/App.state'
import Region from './Region'
import Department from './Department'
import Province from './Province'
import District from './District'
import FormDivider from './AddressDivider'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    locale,
    connectivity
  } = injection
  const countryName = retrieveCountryNameFromLocale(locale)

  const baseValue = {
    copyAdress: true,
    countryName
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)
  const autoFocus = { autoFocus: false }

  const REGION = {
    ...Region(intl, injection),
    htmlProps: autoFocus
  }
  const DEPARTMENT = { ...Department(intl, injection) }
  const PROVINCE = { ...Province(intl, injection) }
  const DISTRICT = { ...District(intl, injection) }
  const GROUP_DIVIDER = FormDivider(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    itemTitle: GROUP_DIVIDER,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [REGION, DEPARTMENT],
      [PROVINCE, DISTRICT]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          region: yup.string().required(),
          department: yup.string().required(),
          province: yup.string().required(),
          district: yup.string().required()
        })
      )
      .min(1)
      .max(20)
  }

  function retrieveCountryNameFromLocale(locale) {
    return locale.name && locale.name.toUpperCase()
  }
}

export function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)

    const onlyDeliveryAddress = addresses.filter(address => {
      const addressUseDelivery =  address.addressUse.find(use =>  {
        return use.id === ADDRESS_USE.DELIVERY
      })
      return addressUseDelivery !== undefined
    })

    const parsedValues = connectivity.isOffline
      ? onlyDeliveryAddress.reduce(reduceAddressOffline, [baseValue])
      : onlyDeliveryAddress.reduce(reduceAddress, [baseValue])

    return [{...parsedValues[0], addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]}]
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

export function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress =
    (deliveryAddress && !mailingAddress) ||
    (deliveryAddress && mailingAddress && !mailingAddress.isUseToDelivery)

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

export function reduceAddress(accum, address, index, array) {
  const {
    addresses: { addresses }
  } = store.getState()
  const { regions, departments, provinces, districts } = addresses
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    region,
    department,
    province,
    district,
    neighborhood,
    addressUse,
    isUseToDelivery
  } = address

  const { geoStructureCode: regionCode } = getStructure(
    regions[countryName],
    region
  )

  const { geoStructureCode: departmentCode } = getStructure(
    departments[regionCode],
    department
  )

  const { geoStructureCode: provinceCode } = getStructure(
    provinces[departmentCode],
    province
  )

  const { geoStructureCode: districtCode } = getStructure(
    districts[provinceCode],
    district
  )

  const neighborhoodDescription = neighborhood && neighborhood.description

  const newAddress = {
    region: regionCode,
    department: departmentCode,
    province: provinceCode,
    district: districtCode,
    neighborhood: neighborhoodDescription,
    addressUse,
    countryName,
    copyAdress: isUseToDelivery
  }

  return [...accum, newAddress]
}

export function reduceAddressOffline(accum, address, index, array) {
  const [{ countryName, copyAdress }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const { region, department, province, district, neighborhood } = address

  const newAddress = {
    region,
    department,
    province,
    district,
    neighborhood,
    countryName,
    copyAdress
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  if (this.code) {
    return value.toString() === this.code.toString()
  }
  return value.toString() === this.code
}
