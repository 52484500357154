import { defineMessages } from 'react-intl'

const messages = defineMessages({
  number: {
    defaultMessage: 'Number',
    id: 'form.field.number'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection){
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'number',
    label: intl.formatMessage(messages['number']),
    type: 'input',
    required: true
  }
}
