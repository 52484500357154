import { APP_ROUTES } from '../App'

export const getBasePath = country => {
  if (!country) {
    return APP_ROUTES.SELF_REGISTER
  }

  return APP_ROUTES.SELF_REGISTER_WITH_COUNTRY.replace(':country', country.toLowerCase())
}

export const removeLastBackslashFromURL = url => {
  const maxIndex = url.length - 1
  const lastChar = url.charAt(maxIndex)

  if (lastChar == '/') {
    return url.substring(0, maxIndex)
  }

  return url
}

export const removeCountryFromURL = url => {
  if (url.match(/\/[a-z][a-z]\/auto\/*/gm)) {
    return APP_ROUTES.SELF_REGISTER
  }
  return url
}
