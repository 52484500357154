import * as yup from 'yup'
import { store } from 'src/App.state'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import { ADDRESS_USE, DELIVERY_ADDRESS, MAILING_ADDRESS } from 'src/models/Address/AddressUse'
import StreetAddressLine1 from '../StreetAddress/StreetAddressLine1'
import StreetAddressLine2 from '../StreetAddress/StreetAddressLine2'
import City from '../Address/City'
import State from '../State/State.plain'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    connectivity
  } = injection

  const baseValue = {
    copyAdress: true,
    countryName: 'MALAYSIA'
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)

  const STREET_ADDRESS_LINE1 = { ...StreetAddressLine1(intl, injection) }
  const STREET_ADDRESS_LINE2 = { ...StreetAddressLine2(intl, injection) }
  const CITY = { ...City(intl, injection) }
  const STATE = { ...State(intl, injection) }

  return {
    id: 'address',
    min: 1,
    max: 20,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [STREET_ADDRESS_LINE1],
      [STREET_ADDRESS_LINE2],
      [CITY, STATE],
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          streetAddressLine1: yup.string().max(75).required(),
          streetAddressLine2: yup.string().max(50).nullable(),
          city: yup.string().max(40).required(),
          state: yup.string().max(20).required()
        })
      )
  }
}

function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)
    const onlyDeliveryAddress = addresses.filter(address => {
      return address.addressUse.find(use => use.id === ADDRESS_USE.DELIVERY)
    })

    return connectivity.isOffline
      ? onlyDeliveryAddress.reduce(reduceAddressOffline, [baseValue])
      : onlyDeliveryAddress.reduce(reduceAddress, [baseValue])
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress = deliveryAddress && !mailingAddress.isUseToDelivery

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

function reduceAddress(accum, address, index, array) {
  const {
    locale: { locale: { configs: { localization: { address: { levels } } } } },
    person: { person }
  } = store.getState()

  const streetAddressLine1 = person.addresses[0].geographicalLevels.find(({ level }) => level === levels.STREET_ADDRESS_LINE_1)
  const streetAddressLine2 = person.addresses[0].geographicalLevels.find(({ level }) => level === levels.STREET_ADDRESS_LINE_2)
  const state = person.addresses[0].geographicalLevels.find(({ level }) => level === levels.STATE)
  const city = person.addresses[0].geographicalLevels.find(({ level }) => level === levels.CITY)
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    addressUse
  } = address

  const streetAddressLine1Description = streetAddressLine1 && streetAddressLine1.description
  const streetAddressLine2Description =
    streetAddressLine2 && streetAddressLine2.description
  const cityDescription = city && city.description
  const stateDescription = state && state.description

  const newAddress = {
    streetAddressLine1: streetAddressLine1Description,
    streetAddressLine2: streetAddressLine2Description,
    state: stateDescription,
    city: cityDescription,
    addressUse,
    countryName
  }

  return [...accum, newAddress]
}

function reduceAddressOffline(accum, address, index, array) {

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    streetAddressLine1,
    streetAddressLine2,
    state,
    city,
  } = address

  const newAddress = {
    streetAddressLine1,
    streetAddressLine2,
    state,
    city
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  return value.toString() === this.code
}
