import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import CircularProgress from '@material-ui/core/CircularProgress'
import { ProgressIndicator as DSProgressIndicator } from '@naturacosmeticos/natds-web'

const bem = bemClassName.bind(null, 'loader')

import './Loader.styl'
import { isDesignSystemToggleActive } from '../../config'

class Loader extends PureComponent {
  render() {
    const { className, size, transparent, fixed } = this.props

    if (isDesignSystemToggleActive()) {
      return (
        <div className={classNames(bem({ transparent }), className)}>
          <DSProgressIndicator />
        </div>
      )
    }

    return (
      <div className={classNames(bem({ transparent }), className)}>
        <CircularProgress className={bem('icon', { fixed })} size={size} />
      </div>
    )
  }
}

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  transparent: PropTypes.bool,
  fixed: PropTypes.bool
}

Loader.defaultProps = {
  size: 40,
  transparent: false,
  fixed: true
}

export default Loader
