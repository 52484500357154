import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LoaderBase from 'src/configs/Locales/DEFAULT/layouts/LoaderWithCarousel'
import { REGISTER_STATUS } from '../../RegisterStatus'

class LoaderWithCarousel extends PureComponent {
  render() {
    const { registrationStatus } = this.props

    if(registrationStatus === REGISTER_STATUS.COMPLETED_LOADING) return <LoaderBase className="register__loader" />

    return null
  }
}

LoaderWithCarousel.propTypes = {
  className: PropTypes.string,
  registrationStatus: PropTypes.string.isRequired
}

export default LoaderWithCarousel
