import React from 'react'
import Message from 'src/base/Form/Components/Message'
import { defineMessages } from 'react-intl'

const defaultMessage = defineMessages({
  description_not_approved: {
    defaultMessage: 'Thanks to inform your data. Your registration will be reviewed and we contact you soon! If you have your document and electricity or water bill with you, click on NEXT',
    id: 'form.field.description.not.approved'
  }
})

export default function Builder(intl, injection) {
  const messages = {
    ...defaultMessage,
    ...injection.messages
  }

  const notApprovedMessage = intl.formatMessage(messages['description_not_approved'])

  return {
    id: 'notApproved',
    node: <Message message={notApprovedMessage} />
  }
}
