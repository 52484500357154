import { defineMessages } from 'react-intl'

export const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Pre Register',
    id: 'register.personal.label'
  },
  page_title: {
    defaultMessage: 'Perfect!',
    id: 'register.personal.data.page.title'
  },
  subtitle_1: {
    defaultMessage: 'Complete the fields below to make your register.',
    id: 'register.personal.data.subtitle_1'
  },
  subtitle_2: {
    defaultMessage: 'Fields with "*" are required.',
    id: 'register.personal.subtitle_2'
  },
  button_add: {
    defaultMessage: 'Add a second phone',
    id: 'register.personal.phone.button.add'
  },
  button_remove: {
    defaultMessage: 'Remove second phone',
    id: 'register.personal.phone.button.remove'
  },
  email_confirm_error: {
    defaultMessage: 'E-mails must match',
    id: 'register.personal.email.confirm.error'
  },
  review_registrant_id: {
    defaultMessage: 'Registrant code',
    id: 'form.field.review.registrant.code'
  }
})

