import * as yup from 'yup'
import { set } from 'lodash'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import FormFieldSchema from './FormFieldSchema'

const adapter = validatorAdapter('Yup', yup)

export default class FormGroupSchema extends Entity {
  static SCHEMA = {
    fields: {
      validator: adapter(yup.array().required()),
    },
    list: {
      validator: adapter(yup.array().nullable()),
      defaultValue: []
    },
    id: adapter(yup.string()),
    title: adapter(yup.mixed()),
    itemTitle: adapter(yup.mixed()),
    min: {
      validator: adapter(yup.number()),
      defaultValue: 1
    },
    max: {
      validator: adapter(yup.number()),
      defaultValue: 1
    },
    pad: adapter(yup.boolean().nullable()),
    validations: adapter(yup.object().required()),
    required: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    disabled: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    disabledBy: {
      validator: adapter(yup.array().nullable()),
      defaultValue: []
    },
    hidden: {
      validator: adapter(yup.boolean()),
      defaultValue: false
    },
    hiddenBy: {
      validator: adapter(yup.array().nullable()),
      defaultValue: []
    },
    defaultValue: {
      validator: adapter(yup.array().nullable()),
      defaultValue: []
    },
    baseValue: {
      validator: adapter(yup.object().nullable()),
      defaultValue: []
    },
    addButton: adapter(yup.mixed().nullable()),
    removeButton: adapter(yup.mixed().nullable())
  }

  constructor(props) {
    super(props)

    this.validate = this.validate.bind(this)
    this.getTemplate = this.getTemplate.bind(this)

    this.buildList()

    this.applyRequiredValidation()
  }

  buildList() {
    const { length } = this.defaultValue || []
    const limit = Math.max(this.min, length)

    for(let i = 0; i < limit; i++) {
      this.list.push(this.getTemplate(i))
    }
  }

  get index() {
    return this.list.length
  }

  getTemplate(index) {
    const { id, fields, disabled, hidden } = this

    return fields.map(buidFields, { index, id, disabled, hidden })
  }

  get canAdd() {
    return this.addButton && this.index < this.max
  }

  get canRemove() {
    return this.removeButton && this.index > this.min
  }

  applyRequiredValidation() {
    if(!this.validations && !this.required) return

    if(this.required) {
      const schema = this.validations ? this.validations : yup.mixed()

      Object.assign(this, { validations: schema.required() })
    }

    const { _exclusive: { required: schemaRequired } = {}} = this.validations
    const required = schemaRequired !== undefined

    if(!required) return

    if(this.list.length) {
      const limit = this.min

      this.list.map(mapRequiredFields, { limit })
    }
  }

  validate(value, { values, errors }) {
    try {
      this.validations && this.validations.validateSync(value, {
        abortEarly: false,
        context: { values, errors }
      })

      return false
    }
    catch(error) {
      const { inner = [] } = error
      return inner.reduce(reduceErrors, {})
    }
  }
}

function reduceErrors(accum, { path, message }) {
  const final = path ? `fields${path}` : `group`
  set(accum, final, message)
  return accum
}

function buidFields(schema) {
  if(Array.isArray(schema))
    return schema.map(buidFields, this)

  const { id, index } = this
  const cleanedSchema = removeAutoFocus(schema, index)

  return new FormFieldSchema({
    ...cleanedSchema,
    group: { ...schema.group, id, index }
  })
}

function removeAutoFocus(schema, index) {
  if(index < 1) return schema

  const { htmlProps = {} } = schema

  if(htmlProps.autoFocus)
    delete schema.htmlProps.autoFocus

  return schema
}

function mapRequiredFields(field, index) {
  if(Array.isArray(field)) {
    return field.map(mapRequiredFields, this)
  }

  const { required } = field
  const groupMinRequired = index <= this.limit

  return Object.assign(field, {
    required: required || groupMinRequired
  })
}
