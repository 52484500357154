import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  passport: {
    defaultMessage: 'Enter the passport number',
    id: 'form.field.passport'
  },
  type_dni: {
    defaultMessage: 'National Registry',
    id: 'form.field.document.type.dni'
  },
  type_ce: {
    defaultMessage: 'Foreign Registry',
    id: 'form.field.document.type.cd'
  },
  type_passport: {
    defaultMessage: 'Passport',
    id: 'form.field.document.type.ptp'
  },
  type_rut: {
    defaultMessage: 'RUT',
    id: 'form.field.document.type.rut'
  },
  type_mykad: {
    defaultMessage: 'MyKad',
    id: 'form.field.document.type.mykad'
  },
  type_candidate: {
    defaultMessage: 'Personal Code',
    id: 'form.field.document.type.candidate'
  },
  search_type_search: {
    defaultMessage: 'Select type of search',
    id: 'dashboard.myregisters.search.type.search'
  },
  type_citizen: {
    defaultMessage: 'Citizen Card',
    id: 'form.field.document.type.citizen'
  },
  type_foreign: {
    defaultMessage: 'Foreign Card',
    id: 'form.field.document.type.foreign'
  },
  type_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.type.no.options.message'
  }
})
