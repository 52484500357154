import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

const bem = bemClassName.bind(null, 'loaderSlide')

import './LoaderWithCarousel.Slide.styl'

class LoaderWithCarouselSlide extends PureComponent {
  render() {
    const { icon, title, children } = this.props

    return (
      <div className={bem('slide')}>
        <div className={bem('slideIcon')}>{icon}</div>
        <h2 className={bem('slideTitle')}>{title}</h2>
        <div className={bem('slideContent')}>{children}</div>
      </div>
    )
  }
}

LoaderWithCarouselSlide.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

LoaderWithCarouselSlide.defaultProps = {
}

export default LoaderWithCarouselSlide
