import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  login: {
    defaultMessage: 'User',
    id: 'login.user'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const { messages } = { ...dependencies, ...injection }

  return {
    id: 'login',
    placeholder: intl.formatMessage(messages['login']),
    validations: yup.string().required()
  }
}
