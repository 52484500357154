import { defineMessages } from 'react-intl'
import { ACCEPT_IMAGE_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'
import { CONFIGS } from 'src/models/Attachment'

const FIELD_ID = 'identificationCardFront'

const messages = defineMessages({
  identificationCardFront: {
    defaultMessage: 'Icon Front',
    id: 'icon.label.front'
  }
})

const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale: { id: localeId, configs: { localization } }, person } = injection
  const { documents: { personal } } = localization
  const { attachments, documents } = person

  const [{ type } = {}] = documents.filter(filterDocuments, { documents: personal })

  const attachmentsIds = CONFIGS[localeId][type]
  const TYPE = attachmentsIds &&  attachmentsIds[FIELD_ID]

  return {
    id: FIELD_ID,
    type: 'file',
    success: true,
    defaultValue: defineDefaultValue(attachments, TYPE),
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_IMAGE_FORMATS
    }),
    accept: ACCEPT_IMAGE_FORMATS.join(' '),
    customProps: {
      type: TYPE,
      icon: 'frontPhoto',
      iconLabel: intl.formatMessage(messages[FIELD_ID]),
      doUpload: true,
    }
  }
}

function filterDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}
