import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'

export const getEcuadorParsedData = ({ data, configs, person }) => {
  const { levels } = configs?.localization?.address

  const addresses = mapAddress(data.address[0], levels)
  addCountryAndZipCodeToAddress(addresses, person)

  return { ...person, addresses }
}

const mapAddress = (address, levels) => [
  {
    addressType: ADDRESS_TYPE.MAILING,
    geographicalLevels: mountGeographicalLevels(address, levels),
    zipCode: address.zipCode
  }
]

const addCountryAndZipCodeToAddress = (addresses, person) => {
  const {
    geographicalLevels,
    addressUse,
    latitude,
    longitude,
    sequence
  } = person?.addresses[0]
  const { country } = getCountryAndZipCode(geographicalLevels)

  addresses[0].geographicalLevels.unshift(country)
  addresses[0].addressUse = addressUse
  addresses[0].latitude = latitude
  addresses[0].longitude = longitude

  if (sequence) {
    addresses[0].sequence = sequence
  }
}

const mountGeographicalLevels = (address, levels) => [
  { level: levels.STATE, description: address.state.description },
  { level: levels.CITY, description: address.city },
  { level: levels.ZIP_CODE, description: address.zipCode },
  { level: levels.COLOGNE, description: address.cologne },
  { level: levels.STREET, description: address.street },
  { level: levels.NUMBER, description: getProcessedNumber(address.number) },
  { level: levels.COMPLEMENT, description: address.complement },
  { level: levels.REFERENCES, description: address.references }
]

const getProcessedNumber = (number) => (number === 'S/N' ? 'S/N' : number)

const getCountryAndZipCode = (geographicalLevels) => {
  const COUNTRY_LEVEL = 0
  const ZIP_CODE_LEVEL = 1

  const filter = (levelToFind) => ({ level }) => level === levelToFind
  const country = geographicalLevels.find(filter(COUNTRY_LEVEL))
  const zipCode = geographicalLevels.find(filter(ZIP_CODE_LEVEL))

  return { country, zipCode }
}
