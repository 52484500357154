import React, { Component } from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import TagManager from 'react-gtm-module'

import Button, { TYPES } from 'src/base/Button'
import OcrModals from 'src/base/Form/Components/Attachments/base/OcrModals'

import './Ocr.styl'
import { isDefaultFlowToggleActive } from '../../../../../config'
import { SUBSTATUS } from '../../../../../models/Substatus'

const messages = defineMessages({
  ocr_action: {
    defaultMessage: 'Validate documentation',
    id: 'form.field.ocr.action.validation'
  }
})

class Ocr extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showOCRModal: false,
      attachments: []
    }

    this.bem = bemClassName.bind(
      null,
      isDefaultFlowToggleActive() ? 'defaultFile' : 'file'
    )

    this.closeModal = this.closeModal.bind(this)
    this.onStartOcr = this.onStartOcr.bind(this)
  }

  componentDidUpdate() {
    const {
      customProps: { shouldValidateAttachments }
    } = this.props

    const shouldShowOcrModal =
      shouldValidateAttachments &&
      this.hasFieldToValidate &&
      this.attachmentsUploaded &&
      !this.attachmentsErrors &&
      !this.attachmentsLoading &&
      !this.state.showOCRModal &&
      !this.state.loading

    if (shouldShowOcrModal) {
      this.onStartOcr()
    }
  }

  async onStartOcr() {
    const { person, ocr } = this.props

    this.setState({ showOCRModal: true, loading: true })

    const result = await ocr.saveAttachments(person, {
      attachments: this.attachmentsValues
    })
    const {
      payload: { data: attachments = [] },
      error: saveAttachmentsError
    } = result

    if (saveAttachmentsError) return

    this.setState({ attachments }, this.setAttachmentSequence)

    await this.validateOCR(attachments)

    this.setState({ loading: false })
  }

  async validateOCR(attachments) {
    const {
      ocr,
      customProps: { attachmentsToValidate },
      person
    } = this.props

    // eslint-disable-next-line no-unused-vars
    for (const attachment of attachments) {
      const { type, sequence } = attachment

      const shouldValidateAttachment = attachmentsToValidate.find(
        findAttachmentByType,
        { type }
      )

      if (shouldValidateAttachment) {
        const { error: ocrValidationError, type } = await ocr.start(person, sequence)

        this.registerSubstatus(type)

        if (ocrValidationError) break
      }
    }
  }

  registerSubstatus(type) {
    const { person } = this.props

    if (type === 'natura-caduni/OCR_SUCCESS') {
      person.saveStatus({ substatus: SUBSTATUS.ATTACHMENT_VALIDATION_SUCCESS }, { noLoading: true })
    } else if (type === 'natura-caduni/OCR_ERROR') {
      person.saveStatus({ substatus: SUBSTATUS.ATTACHMENT_VALIDATION_FAIL }, { noLoading: true })
    }
  }

  closeModal() {
    const { upload, ocr } = this.props

    this.setAttachmentVerifired()
    upload.reset()
    ocr.reset()

    return this.setState({ showOCRModal: false })
  }

  setAttachmentVerifired() {
    const {
      customProps: { fields }
    } = this.props

    fields.forEach(this.setAttachmentVerifiredForEach, this)
  }

  setAttachmentVerifiredForEach(field) {
    const {
      form: { setFieldValue, setFieldTouched, values },
      ocr: { verified }
    } = this.props

    setFieldValue(field, { ...values[field], verified })
    setFieldTouched(field, true)
  }

  setAttachmentSequence() {
    const {
      customProps: { fields }
    } = this.props

    fields.forEach(this.setAttachmentSequenceForEach, this)
  }

  setAttachmentSequenceForEach(field) {
    const {
      form: { setFieldValue, values }
    } = this.props

    const { sequence } =
      this.state.attachments.find(findAttachmentByType, values[field]) || {}

    setFieldValue(field, { ...values[field], sequence })
  }

  get attachmentsUploaded() {
    const {
      upload: { attachments },
      customProps: { fields },
      form: { values }
    } = this.props

    return fields.every(everyAttachmentsUploaded, { attachments, values })
  }

  get attachmentsLoading() {
    const {
      uploadLoading,
      form: { values },
      customProps: { fields }
    } = this.props

    return fields.some(someLoading, { uploadLoading, values })
  }

  get attachmentsErrors() {
    const {
      form: { errors },
      customProps: { fields }
    } = this.props

    return fields.some(someDocumentError, { errors })
  }

  get attachmentsValues() {
    const {
      form: { values },
      customProps: { fields },
      person: { attachments }
    } = this.props

    return fields.reduce(reduceAttachments, [{ values, attachments }])
  }

  get shouldShowOcrAction() {
    const {
      customProps: { fields = [], shouldValidateAttachments },
      form: { values },
      connectivity
    } = this.props

    const attachmentsHasDownloadUrl = fields.every(
      everyAttachmentsHasDownloadUrl,
      { values }
    )

    return (
      attachmentsHasDownloadUrl &&
      shouldValidateAttachments &&
      this.hasFieldToValidate &&
      !connectivity.isOffline
    )
  }

  get hasFieldToValidate() {
    const {
      customProps: { attachmentsToValidate }
    } = this.props

    return !!attachmentsToValidate.length
  }

  setDataLayer(buttonId, buttonText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }

  render() {
    const { className } = this.props

    return (
      <div className={classNames(this.bem('ocr'), className)}>
        {this.renderOcr()}
        {this.renderOcrModals()}
      </div>
    )
  }

  renderOcr() {
    if (!this.shouldShowOcrAction) return null
    const {intl} = this.props.customProps

    return (
      <Button
        disabled={isDefaultFlowToggleActive() && !this.props.form.errors.hasErrors}
        className={this.bem('ocr-action')}
        onClick={() => {
          this.setDataLayer('ocr-action', intl.formatMessage(messages.ocr_action))
          this.onStartOcr()
        }}
        type={isDefaultFlowToggleActive() ? TYPES.PRIMARY : TYPES.TEXT_PRIMARY}
      >
        <FormattedMessage {...messages.ocr_action} />
      </Button>
    )
  }

  renderOcrModals() {
    const { ocr, personId } = this.props
    const { showOCRModal, loading } = this.state

    if (!showOCRModal) return null

    return (
      <OcrModals
        ocr={ocr}
        personId={personId}
        open={showOCRModal}
        loading={loading}
        oncloseModal={this.closeModal}
      />
    )
  }
}

Ocr.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  personId: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  validations: PropTypes.object,
  customProps: PropTypes.object,
  form: PropTypes.object,
  uploadLoading: PropTypes.object,
  ocr: PropTypes.object,
  connectivity: PropTypes.object,
  person: PropTypes.object,
  upload: PropTypes.object
}

export default Ocr

function reduceAttachments(accum, attachment, index, array) {
  const [{ values, attachments }] = accum
  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!attachment) return accum

  const orcAttachment = values[attachment]

  if (!orcAttachment.type) return accum

  const personAttachment =
    attachments.find(findAttachmentByType, { type: orcAttachment.type }) || {}
  const sequence = orcAttachment.sequence || personAttachment.sequence

  const { type, downloadUrl: url } = orcAttachment

  return [...accum, { type, url, sequence }]
}

function findAttachmentByType({ type }) {
  return type === this.type
}

function someDocumentError(field) {
  return this.errors[field]
}

function someLoading(field) {
  const { type } = this.values[field]

  return this.uploadLoading[type]
}

function everyAttachmentsUploaded(field) {
  const { type } = this.values[field]

  return this.attachments[type] && this.attachments[type].saved
}

function everyAttachmentsHasDownloadUrl(field) {
  return this.values[field] && this.values[field].downloadUrl
}
