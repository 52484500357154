import React from 'react'
import Icon from '../../../../../base/Icon'
import { FormattedHTMLMessage } from 'react-intl'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import './Pending.styl'
import Link from '../../../../../base/Link'

const bem = bemClassName.bind(null, 'completed-pending')

const Pending = (props) => {
  const { messages, intl, user, showInstructionMessage = false } = props
  let showThirdPartyMessage = false
  let isFVUser = false

  const webPage = intl.formatMessage({ ...messages['web_page'] })

  if (user) {
    showThirdPartyMessage = user.roles && (user.roles.GDN || user.roles.LEADER)
    isFVUser = user.isFV
  }

  const renderProcessingMessage = () => {
    const processingMessageId = getProcessingMessageId(isFVUser)

    return <FormattedHTMLMessage {...messages[processingMessageId]} />

    function getProcessingMessageId(isFVUser) {
      return isFVUser ? 'processing_message_for_fv_user' : 'processing_message'
    }
  }

  const renderInstructions = () => {
    if (!showInstructionMessage) return null
    const instructionsMessageId = getInstructionsMessageId(isFVUser)

    return <FormattedHTMLMessage {...messages[instructionsMessageId]} />

    function getInstructionsMessageId(isFVUser) {
      return isFVUser ? 'processing_instructions_for_fv_user' : 'processing_instructions'
    }
  }

  const renderContactInstructions = () => {
    if (!showThirdPartyMessage) {
      return <p data-testid={'contact_number'}>
        <FormattedHTMLMessage {...messages['contact_number']} />{' '}
      </p>
    }

    const contactInstructionsMessageId = getContactInstructionsMessageId(isFVUser)

    return showThirdPartyMessage && (
      <p data-testid={contactInstructionsMessageId}>
        <FormattedHTMLMessage
          {...messages[contactInstructionsMessageId]}
        />
      </p>
    )

    function getContactInstructionsMessageId(isFVUser) {
      return isFVUser ? 'contact_number_third_party_for_fv_user' : 'contact_number_third_party'
    }

  }

  return (
    <>
      <div className={bem('container')}>
        <div className={bem('info')}>
          <Icon name="processing_icon_thin" className={bem('pending_icon')} />
          <h4>
            <FormattedHTMLMessage {...messages['processing_title']} />
          </h4>
          <p className={bem('status_message')}>
            {renderProcessingMessage()}
            {/* <FormattedHTMLMessage {...messages['processing_message']} /> */}
          </p>
          {
            renderInstructions()
          }
          <div className={bem('message')}>
            {renderContactInstructions()}
            <br />
            <br />
            <span className={bem('web_page')}>
              <Link href={`https://${webPage}`}>{webPage}</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  )


}

Pending.propTypes = {
  match: PropTypes.object,
  messages: PropTypes.object.isRequired,
  intl: PropTypes.object,
  user: PropTypes.object,
  showInstructionMessage: PropTypes.bool
}

export default Pending
