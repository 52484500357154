import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'neighborhood',
    label: intl.formatMessage(messages['neighborhood']),
    type: 'input'
  }
}
