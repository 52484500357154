import { defineMessages } from 'react-intl'

export default defineMessages({
  modal_no_backend_service_title: {
    defaultMessage: 'Unavailable service',
    id: 'smsToken.modal.content.no.backend.service.title'
  },
  modal_no_backend_service_text: {
    defaultMessage: 'Please, try later again.',
    id: 'smsToken.modal.content.no.backend.service.text'

  },
  modal_resend_title: {
    defaultMessage: 'SMS code resent',
    id: 'smsToken.modal.content.resend.title'
  },
  modal_resend_text: {
    defaultMessage: 'An SMS was sent with the validation code',
    id: 'smsToken.modal.content.resend.text'
  },
  modal_success_title: {
    defaultMessage: 'Your contact has been validated!',
    id: 'smsToken.modal.content.success.title'
  },
  modal_success_text: {
    defaultMessage: 'We will be informing you about your registration status and the next steps.',
    id: 'smsToken.modal.content.success.text'
  },
  modal_success_text2: {
    defaultMessage: 'See you soon',
    id: 'smsToken.modal.content.success.text2'
  },
  modal_unsuccessfully_title: {
    defaultMessage: 'Attention',
    id: 'smsToken.modal.content.unsuccessfully.title'
  },
  modal_unsuccessfully_text: {
    defaultMessage: 'The indicated code is not valid, please check if it is correct and try again.',
    id: 'smsToken.modal.content.unsuccessfully.text'
  },
  modal_form_text: {
    defaultMessage: 'To validate the mobile phone',
    id: 'smsToken.modal.content.form.text'
  },
  modal_form_text2: {
    defaultMessage: 'informs the received code in SMS',
    id: 'smsToken.modal.content.form.text2'
  },
  modal_form_code: {
    defaultMessage: 'Code',
    id: 'smsToken.modal.content.form.code'
  },
  modal_form_resend: {
    defaultMessage: 'Resend SMS',
    id: 'smsToken.modal.content.form.resend'
  },
  modal_button_validate_late: {
    defaultMessage: 'Validate later',
    id: 'smsToken.modal.content.button.validate.late'
  },
  modal_button_try_again: {
    defaultMessage: 'Try again',
    id: 'smsToken.modal.content.button.try.again'
  },
  modal_button_ok: {
    defaultMessage: 'Ok, Validate',
    id: 'smsToken.modal.content.button.ok'
  },
  modal_button_agree: {
    defaultMessage: 'I agree',
    id: 'smsToken.modal.content.button.agree'
  },
  modal_button_cancel: {
    defaultMessage: 'Cancel',
    id: 'contactValidation.modal.content.button.cancel'
  },
  modal_button_send: {
    defaultMessage: 'Send Email',
    id: 'contactValidation.modal.content.button.send'
  },
  modal_email_success_text: {
    defaultMessage: 'An email was sent with the validation link. It is necessary to click on the link to complete the validation.',
    id: 'contactValidation.modal.content.email.success'
  },
  modal_email_default_text: {
    defaultMessage: 'An email will be sent to',
    id: 'contactValidation.modal.content.email.default'
  },
  validate_contact: {
    defaultMessage: 'Validate Contact',
    id: 'contactValidation.validate.contact'
  },
})
