import { getAddressNumberValidation as getAddressNumberValidationForPeru } from './get-address-number-validation.per'
import { getAddressNumberValidation as getDefaultAddressNumberValidation } from './get-address-number-validation.default'

const strategies = {
  PER: getAddressNumberValidationForPeru,
  __default__: getDefaultAddressNumberValidation
}

export const getAddressNumberValidation = (localeId) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy()
}
