import axios from 'axios'

const APPLICATION_JSON = 'application/json'
const URL_GLOBAL_REGISTERS_BFF = process.env.REGISTERS_URL
const REGISTERS_X_API_KEY = process.env.REGISTERS_X_API_KEY

export const DEFAULT_BFF_CLIENT = {
  baseURL: URL_GLOBAL_REGISTERS_BFF,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
    'x-api-key': REGISTERS_X_API_KEY
  }
}

export const DEFAULT_BFF_APIKEYS = {
  REGISTERS_APIKEY: REGISTERS_X_API_KEY
}

export const DEFAULT_BFF_URLS = {
  REPORTS_STATUS_SUBSTATUS: `${URL_GLOBAL_REGISTERS_BFF}/reports/status-substatus`,
  REPORTS_REGISTRATION_TEAM: `${URL_GLOBAL_REGISTERS_BFF}/reports/registration-team`,
  GET_PERSON: ({ personId }) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}`,
  GET_PEOPLE: () => `${URL_GLOBAL_REGISTERS_BFF}/people`,
  REGISTRATION_TEAM_SEARCH_INDICATION: (params) => `${URL_GLOBAL_REGISTERS_BFF}/indication/registration-search?${params}`,
  CREATE_PERSON: () => `${URL_GLOBAL_REGISTERS_BFF}/people`,
  UPDATE_PERSON: ({ personId }) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}`,
  UPDATE_REGISTRATION_SUBSTATUS: ({ personId, personSubstatus }) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/substatus/${personSubstatus}`,
  ESTABLISHMENTS: () => `${URL_GLOBAL_REGISTERS_BFF}/establishments`,
  BUSINESS_MODEL: () => `${URL_GLOBAL_REGISTERS_BFF}/business-model`,
  REJECTION_REASONS: () => `${URL_GLOBAL_REGISTERS_BFF}/people-management/rejection-reasons`,
  COMPLETE_PEOPLE: () => `${URL_GLOBAL_REGISTERS_BFF}/people/complete-registration`,
  CREATE_PERSON_ROLE_BFF: (personId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/roles`,
  APPROVATION_BFF: (personId, personRoleId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/role/approve/${personRoleId}`,
  CEASE_PERSON_ROLE_BFF: (personId, personRoleId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/role/cease/${personRoleId}`,
  REJECTION_BFF: (personId, personRoleId) => `${URL_GLOBAL_REGISTERS_BFF}/people/${personId}/role/reject/${personRoleId}`,
}

export default {
  client: axios.create(DEFAULT_BFF_CLIENT),
  urls: DEFAULT_BFF_URLS,
  apiKeys: DEFAULT_BFF_APIKEYS,
}
