import { getContext } from 'src/infra/Api/Api.context'
import uuid from 'uuid/v4'
import Error from 'src/viewModels/Error'
import Person from 'src/models/Person'

import { PERSON_SAVE_STATE } from 'src/reducers/Person/Person.save'
import { BUSINESS_MODEL } from 'src/models/BusinessModel/BusinessModel.js'
import { PEOPLE_REGISTER_SAVE } from 'src/reducers/PeopleRegister/PeopleRegister.save'
import { CLIENT_NAMES } from '../../infra/Api/Api'
import { BEAUTY_CONSULTANT_PROFILE, getBusinessModelByProfile } from '../../configs/Locales/DEFAULT/NaturaProfiles'

export const PERSON_CREATE_LOADING = 'natura-caduni/Person/PERSON_CREATE_LOADING'
export const PERSON_CREATE_SUCCESS = 'natura-caduni/Person/PERSON_CREATE_SUCCESS'
export const PERSON_CREATE_ERROR = 'natura-caduni/Person/PERSON_CREATE_ERROR'

export const types = [PERSON_CREATE_LOADING, PERSON_CREATE_SUCCESS, PERSON_CREATE_ERROR]

export function personCreateReducer(state, action, dependencies) {
  const { payload, type, meta } = action
  let { loading, error, person } = state

  const hasSuccess = type === PERSON_CREATE_SUCCESS
  const hasError = type === PERSON_CREATE_ERROR
  const isLoading = type === PERSON_CREATE_LOADING

  if (hasSuccess) {
    const { previousAction: { payload: { reviewStep } } } = meta

    error = false
    loading = false

    person = generatePerson({ ...person, ...payload.data, reviewStep }, dependencies)
  }

  if (hasError) {
    loading = false

    if (action?.error?.response?.status !== 409) {
      error = new Error(action.error)
    } else {
      person.personId = action.error.response.data.personId
    }
  }

  return {
    ...state,
    loading: isLoading || loading,
    error,
    person
  }
}

export function createPerson(data, headers = {}) {
  const globalAppContext = getContext()
  const {
    dispatch,
    connectivity,
    user: {
      sellerId,
    },
  } = globalAppContext

  const { documents, businessModel, reviewStep, draft } = data

  if (connectivity.isOffline)
    return new Promise(offlineStep(documents, businessModel))

  const { clientName, requestBody, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext, personData: data })

  return dispatch({
    types,
    payload: {
      client: clientName,
      personLocal: {
        shouldUpdate: draft,
        data
      },
      reviewStep,
      request: {
        method: 'POST',
        url: requestUrl,
        headers: requestHeaders,
        data: requestBody
      }
    }
  })

  function getRequestPayload({ globalAppContext, personData }) {
    const {
      PEOPLE_URL,
      CREATE_PERSON_URL,
      bffHeaders,
      countryId,
      companyId,
      headersAuthenticationInfo,
      shouldCreatePersonFromBff
    } = getNeededInformationFromGlobalContext({ globalAppContext })
    const clientName = shouldCreatePersonFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId
    const { requestBody } = getRequestBody({ personData, shouldCreatePersonFromBff })
    const { requestHeaders } = getRequestHeaders({ personData, shouldCreatePersonFromBff, headersAuthenticationInfo })

    const requestUrl = shouldCreatePersonFromBff ? CREATE_PERSON_URL : PEOPLE_URL

    return { clientName, requestUrl, requestBody, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { PEOPLE },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { CREATE_PERSON },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldCreatePersonFromBff = configs?.localization?.preRegister?.useCreatePersonFromBFF

      return {
        PEOPLE_URL: PEOPLE(),
        CREATE_PERSON_URL: CREATE_PERSON(),
        shouldCreatePersonFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestBody({ personData, shouldCreatePersonFromBff }){
      const { documents, businessModel, personId, additionalInformation } = personData
      const {
        businessModelId,
      } = businessModel
      const requestBody = shouldCreatePersonFromBff ? {
        documents,
        additionalInformation
      } : {
        businessModelId,
        documents,
        personId,
        additionalInformation
      }

      return { requestBody }
    }

    function getRequestHeaders({ personData, shouldCreatePersonFromBff, headersAuthenticationInfo }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo
      const { businessModel } = personData
      const {
        businessModelId,
        functionId,
        roleId
      } = businessModel
      const ConsultantBusinessModel = getBusinessModelByProfile(BEAUTY_CONSULTANT_PROFILE)
      const requestHeaders = shouldCreatePersonFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        businessmodel: businessModelId || ConsultantBusinessModel.businessModelId,
        functionid: functionId || ConsultantBusinessModel.functionId,
        role: roleId || ConsultantBusinessModel.roleId,
        sourcesystem: '7',
        countryid: countryId,
        companyid: companyId,
      } : {
        ...headers,
        'x-api-key': globalPeoplePlatformApiKey,
        businessModel: businessModelId,
        function: functionId,
        role: roleId,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}

function generatePerson(data, dependencies) {
  const person = new Person(data, dependencies)

  if (person.roles.hasRestartBeautyConsultantRole) {
    const { personId, documents } = person

    return new Person({ personId, documents }, dependencies)
  }

  return person
}

function offlineStep(documents, businessModel) {
  const { dispatch, user: { sellerId } } = getContext()
  const { businessModelId } = businessModel

  const personId = uuid()

  const data = {
    draft: true,
    personId,
    registrationDate: new Date(),
    createdBy: sellerId,
    documents,
    businessModel,
    roles: [businessModel],
    businessModelId: businessModelId || BUSINESS_MODEL.CONSULTANT,
  }

  return function (resolve) {
    dispatch({ type: PERSON_SAVE_STATE, payload: { data } })
    dispatch({ type: PEOPLE_REGISTER_SAVE, payload: { person: data } })

    return resolve({ payload: { data } })
  }
}
