import GoogleTagManager from 'react-gtm-module'
import { COMPANY_ID } from '../../models/Company'
import { GOOGLE_TAG_MANAGER_ID } from '../../models/google-tag-manager'

export const setGoogleTagManager = ({ companyId }) => {
  const googleTagManagerArguments = getGoogleTagManagerArgumentsFromCompany({
    companyId
  })

  removePreviousGoogleTagManager()

  GoogleTagManager.initialize(googleTagManagerArguments)
}

const getGoogleTagManagerArgumentsFromCompany = ({ companyId }) => {
  if (companyId === COMPANY_ID.AVON) {
    const googleTagManagerArguments = {
      gtmId: GOOGLE_TAG_MANAGER_ID.AVON
    }

    return googleTagManagerArguments
  }

  if (companyId === COMPANY_ID.NATURA) {
    const googleTagManagerArguments = {
      gtmId: GOOGLE_TAG_MANAGER_ID.NATURA
    }

    return googleTagManagerArguments
  }
}

const removePreviousGoogleTagManager = () => {
  document
    .querySelectorAll('script')
    .forEach((script) => {
      script.src.includes('googletagmanager') ? script.remove() : null
      script.innerHTML.includes('googletagmanager') ? script.remove() : null
    })
}
