import * as yup from 'yup'
import ColombiaNumber from '../ColombiaNumber/ColombiaNumber'
import Street from './Street'
import Complement from './Complement'
import References from './References'
import City from 'src/configs/Fields/AddressStructure/COL/City.col'
import Department from 'src/configs/Fields/AddressStructure/COL/Department.col'
import Neighborhood from 'src/configs/Fields/AddressStructure/COL/Neighborhood.col'
import SectionTitle from 'src/base/SectionTitle/SectionTitle.js'
import React from 'react'
import { messages } from 'src/configs/Fields/AddressStructure/Messages.js'
import { parseDefaultValuesForColombiaAddress } from './DefaultValuesParser.col'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    locale: { configs: { localization: { address: { levels } } } }
  } = injection

  const baseValue = {
    countryName: 'COLOMBIA',
    levels
  }

  const defaultValue = parseDefaultValuesForColombiaAddress(addresses, baseValue)

  const STREET = { ...Street(intl, { ...injection }), showAsterisk: false }
  const NUMBER_ADDRESS = { ...ColombiaNumber(intl, { ...injection }), showAsterisk: false }
  const COMPLEMENT = Complement(intl, injection)
  const REFERENCES = { ...References(intl, { ...injection }), showAsterisk: false }
  const GROUP_DIVIDER = { node: <hr className="defaultForm__group__divider__centered"/> }
  const SECTION_SECOND_TITLE = { node: <SectionTitle title={intl.formatMessage(messages.address_delivery)}/> }

  const DELIVERY_DEPARTMENT = Department(intl, injection)
  const DELIVERY_CITY = City(intl, injection)
  const DELIVERY_NEIGHBORHOOD = Neighborhood(intl, injection)
  const DELIVERY_STREET = { ...STREET, id: 'deliveryStreet' }
  const DELIVERY_NUMBER_ADDRESS = { ...NUMBER_ADDRESS, id: 'deliveryNumber' }
  const DELIVERY_COMPLEMENT = { ...COMPLEMENT, id: 'deliveryComplement' }
  const DELIVERY_REFERENCES = { ...REFERENCES, id: 'deliveryReferences' }

  return {
    id: 'address',
    min: 1,
    max: 20,
    baseValue,
    defaultValue,
    fields: [
      [STREET],
      [NUMBER_ADDRESS, COMPLEMENT],
      [REFERENCES],
      [GROUP_DIVIDER],
      [SECTION_SECOND_TITLE],
      [DELIVERY_DEPARTMENT, DELIVERY_CITY],
      [DELIVERY_NEIGHBORHOOD],
      [DELIVERY_STREET],
      [DELIVERY_NUMBER_ADDRESS, DELIVERY_COMPLEMENT],
      [DELIVERY_REFERENCES]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          street: yup.string().max(100).required(),
          number: yup.string().max(15).required(),
          complement: yup.string().max(100),
          references: yup.string().max(100).required(),
          department: yup.string().max(100).required(),
          city: yup.string().max(100).required(),
          neighborhood: yup.string().max(100).required(),
          deliveryStreet: yup.string().max(100).required(),
          deliveryNumber: yup.string().max(100).required(),
          deliveryReferences: yup.string().max(100).required(),
        })
      )
      .min(1)
      .max(20)
  }
}
