import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual, throttle } from 'lodash'

import { focusOnFirstError, set, unset, splitIdParts } from './Form.state.helpers'

import Toucheds from './Form.state.toucheds'
import Values from './Form.state.values'
import Errors from './Form.state.errors'
import Schema from './Form.state.schema'

import Form from './Form'

const dependencies = {
  focusOnFirstError, set, unset, splitIdParts, Toucheds, Values, Errors, Schema
}

export const UPDATE_TYPES = {
  EXTRICTED: 'extricted',
  VALUES_ONLY: 'values',
  FIELDS_ONLY: 'fields',
  LOOSE: 'loose'
}

class FormState extends Component {
  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, dependencies, injection)

    const { formSchema: { initialValues, fieldsSchema } } = this.props

    this.state = {
      initialValues,
      values: new this.Values(initialValues),
      toucheds: new this.Toucheds({}),
      errors: new this.Errors({}),
      schema: new this.Schema({ fieldsSchema }),
      isSubmitting: false
    }

    this.setFieldValue = this.setFieldValue.bind(this)
    this.setFieldTouched = this.setFieldTouched.bind(this)
    this.setFieldError = this.setFieldError.bind(this)
    this.setGroupFields = this.setGroupFields.bind(this)
    this.setSchemaStates = this.setSchemaStates.bind(this)
    this.runFieldValidation = throttle(this.runFieldValidation.bind(this), 300)
    this.addItemToGroup = this.addItemToGroup.bind(this)
    this.removeItemFromGroup = this.removeItemFromGroup.bind(this)
    this.runAllFieldsValidation = this.runAllFieldsValidation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetSubmittingAfterPromise = this.resetSubmittingAfterPromise.bind(this)
  }

  componentDidMount() {
    const { touchAfterReload } = this.props

    if (touchAfterReload)
      this.touchFields(touchAfterReload)
  }

  componentDidUpdate({ formSchema }) {
    this.resetSubmitting(this.state.errors)
    this.externalUpdate(formSchema)
  }

  get updateRule() {
    return this.props.updateRule
  }

  get isExtrict() {
    return this.updateRule === UPDATE_TYPES.EXTRICTED
  }

  get isValuesOnly() {
    return this.updateRule === UPDATE_TYPES.VALUES_ONLY
  }

  get isFieldsOnly() {
    return this.updateRule === UPDATE_TYPES.FIELDS_ONLY
  }

  get isLoose() {
    return this.updateRule === UPDATE_TYPES.LOOSE
  }

  get disabledSubmit() {
    if (this.props.disabledSubmit) {
      const errors = this.validateAllFields()

      return errors.hasErrors
    }

    return false
  }

  getRule(fieldSchemaUpdated, initialValuesUpdated) {
    if (this.isLoose)
      return fieldSchemaUpdated || initialValuesUpdated

    if (this.isExtrict)
      return fieldSchemaUpdated && initialValuesUpdated

    if (this.isValuesOnly)
      return initialValuesUpdated

    if (this.isFieldsOnly)
      return fieldSchemaUpdated

    return false
  }

  externalUpdate(formSchema) {
    const { formSchema: { initialValues, fieldsSchema }, touchAfterReload } = this.props
    const fieldSchemaUpdated = !isEqual(fieldsSchema, formSchema.fieldsSchema)
    const initialValuesUpdated = !isEqual(initialValues, formSchema.initialValues)

    const validatedRule = this.getRule(fieldSchemaUpdated, initialValuesUpdated)

    if (validatedRule) {
      const schema = new this.Schema({ fieldsSchema })
      const values = new this.Values(initialValues)

      this.setState({ values, schema }, function () {
        this.resetErrors()
        touchAfterReload ? this.touchFields(touchAfterReload) : this.touchAllFields()
      })
    }
  }

  // SUBMIT
  handleSubmit(event) {
    event.preventDefault()

    const { onSubmit, formSchema: { name }, hiddenSubmit } = this.props
    const { values } = this.state

    this.setState({ isSubmitting: true })
    this.touchAllFields()

    const errors = this.validateAllFields()

    if (errors.hasErrors) {
      this.setState({
        errors,
        isSubmitting: false
      }, () => this.focusOnFirstError(name))

      return
    }

    if (hiddenSubmit || !onSubmit)
      return this.setState({ isSubmitting: false })

    const result = onSubmit(values)

    if (result && result.then) {
      result.then(this.resetSubmittingAfterPromise)
    }

    return result
  }

  resetSubmittingAfterPromise({ error } = {}) {
    this.resetSubmitting(error)
  }

  resetSubmitting(errors) {
    const { isSubmitting } = this.state

    if (errors && isSubmitting && Object.keys(errors).length > 0) {
      this.setState({ isSubmitting: false })
    }
  }

  // TOUCHEDS
  touchAllFields() {
    this.setState(function ({ schema, toucheds }) {
      return { toucheds: toucheds.touchAllFields(schema) }
    }, this.setSchemaStates)
  }

  touchFields(fieldList) {
    this.setState(function ({ toucheds, values }) {
      return { toucheds: toucheds.touchFields(fieldList, values) }
    }, this.setSchemaStates)
  }

  setFieldTouched(id, action = true) {
    this.setState(function ({ toucheds }) {
      return { toucheds: toucheds.touchField(id, action) }
    }, this.setSchemaStates)
  }

  resetToucheds() {
    return this.setState({ toucheds: new this.Toucheds({}) })
  }

  // VALIDATION/ERROR
  validateAllFields() {
    const { errors, values, schema } = this.state

    return errors.validateAll({ values, schema })
  }

  runAllFieldsValidation() {
    const errors = this.validateAllFields()

    this.setState({ errors })
  }

  runFieldValidation(id, groupId) {
    this.setState(function ({ errors, values, schema }) {
      return { errors: errors.validate({ id, groupId, values, schema }) }
    }, this.setSchemaStates)
  }

  setFieldError(id, error) {
    this.setState(function ({ errors }) {
      return { errors: errors.setError(id, error) }
    }, this.setSchemaStates)
  }

  resetErrors() {
    return this.setState({ errors: new this.Errors({}) })
  }

  // VALUES
  setFieldValue(id, value, groupId) {
    this.setState(
      function ({ values }) {
        return { values: values.setValue(id, value) }
      },
      function () {
        this.runFieldValidation(id, groupId)
      }
    )
  }

  resetValues() {
    const { initialValues } = this.state

    return this.setState({ values: new this.Values(initialValues) })
  }

  // SCHEMA
  setSchemaStates() {
    this.setState(function ({ values, errors, toucheds, schema }) {
      return {
        schema: schema.setFieldHiddenAndDisabledStates({ values, errors, toucheds })
      }
    })
  }

  setGroupFields(id, list) {
    this.setState(function ({ schema }) {
      return { schema: schema.setGroupFields({ id, list }) }
    })
  }

  addItemToGroup(groupSchema) {
    const { id, getTemplate, baseValue, index, max, list } = groupSchema

    if (index === max) return

    const template = getTemplate(index)
    const fieldId = `${id}[${index}]`

    list.push(template)

    this.setFieldValue(fieldId, baseValue, id)
    this.setGroupFields(id, list)
  }

  removeItemFromGroup(groupSchema) {
    const { id, list, index, min } = groupSchema

    if (index === min) return

    list.splice(-1)

    const fieldId = `${id}[${index - 1}]`
    const lastField = `${id}[${list.length}]`

    this.setFieldValue(fieldId, null, id)
    this.setFieldTouched(lastField, false)
    this.setGroupFields(id, list)
  }

  render() {
    const {
      modal,
      className,
      cancelLabel,
      confirmLabel,
      hiddenSubmit,
      customButtom,
      cancelAction,
      offline,
      error: externalError,
      formSchema: { name },
      paper,
      title, subtitle
    } = this.props

    const {
      values,
      errors,
      toucheds,
      schema: { fieldsSchema },
      isSubmitting
    } = this.state

    return (
      <Form
        title={title}
        subtitle={subtitle}
        className={className}
        name={name}
        values={values}
        errors={errors}
        modal={modal}
        offline={offline}
        toucheds={toucheds}
        externalError={externalError}
        isSubmitting={isSubmitting}
        fieldsSchema={fieldsSchema}
        hiddenSubmit={hiddenSubmit}
        disabledSubmit={this.disabledSubmit}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
        customButtom={customButtom}
        cancelAction={cancelAction}
        setGroupFields={this.setGroupFields}
        addItemToGroup={this.addItemToGroup}
        removeItemFromGroup={this.removeItemFromGroup}
        handleSubmit={this.handleSubmit}
        runFieldValidation={this.runFieldValidation}
        runAllFieldsValidation={this.runAllFieldsValidation}
        setFieldError={this.setFieldError}
        setFieldValue={this.setFieldValue}
        setFieldTouched={this.setFieldTouched}
        focusOnFirstError={this.focusOnFirstError}
        paper={paper}
      />
    )
  }
}

FormState.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  className: PropTypes.string,
  hiddenSubmit: PropTypes.bool,
  formSchema: PropTypes.shape({
    name: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    fieldsSchema: PropTypes.array.isRequired
  }).isRequired,
  updateRule: PropTypes.string,
  cancelLabel: PropTypes.object,
  confirmLabel: PropTypes.object,
  customButtom: PropTypes.object,
  modal: PropTypes.func,
  offline: PropTypes.bool,
  touchAfterReload: PropTypes.array,
  cancelAction: PropTypes.func,
  onSubmit: PropTypes.func,
  paper: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

FormState.defaultProps = {
  hiddenSubmit: false,
  disabledSubmit: false,
  updateRule: UPDATE_TYPES.EXTRICTED
}

export default FormState
