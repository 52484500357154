import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'

export const buildFlows = () => {
  return {
    [BEAUTY_CONSULTANT_PROFILE]: [
      'pre-register',
      'personal-data',
      'address',
      'documentation',
      'documents',
      'indication',
      'completed'
    ]
  }
}
