import { getContext } from 'src/infra/Api/Api.context'

import Establishments from 'src/viewModels/Establishments'
import { CLIENT_NAMES } from '../../infra/Api/Api'

const ESTABLISHMENTS_LOADING = 'natura-caduni/Consultants/ESTABLISHMENTS_LOADING'
const ESTABLISHMENTS_SUCCESS = 'natura-caduni/Consultants/ESTABLISHMENTS_SUCCESS'
const ESTABLISHMENTS_ERROR = 'natura-caduni/Consultants/ESTABLISHMENTS_ERROR'

export const types = [
  ESTABLISHMENTS_LOADING,
  ESTABLISHMENTS_SUCCESS,
  ESTABLISHMENTS_ERROR
]

const initialState = {
  establishments: new Establishments([], { getEstablishments }),
  loading: false,
  error: false
}

export default function establishmentsReducer(state = initialState, action) {
  const { type, payload, error: actionError } = action
  let { establishments, loading, error } = state

  const isLoading = type === ESTABLISHMENTS_LOADING
  const isSuccess = type === ESTABLISHMENTS_SUCCESS
  const isError = type === ESTABLISHMENTS_ERROR

  if (isSuccess) {
    const { data } = payload

    const items = data ? [].concat(data.items || data) : []

    error = false
    loading = false
    establishments = new Establishments(items, { getEstablishments })
  }

  if (isError) {
    loading = false
    error = new Error(actionError)
  }

  return {
    loading: isLoading || loading,
    establishments,
    error
  }
}

export function getEstablishments(){
  const globalAppContext = getContext()
  const {
    dispatch,
    user: { sellerId, authentication: { accessToken } }
  } = globalAppContext

  const { requestClientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext })

  return dispatch({
    types,
    payload: {
      client: requestClientName,
      request: {
        method: 'GET',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload({ globalAppContext }) {
    const {
      ESTABLISHMENTS_URL,
      ESTABLISHMENTS_BFF_URL,
      bffHeaders,
      countryId,
      companyId,
      headersAuthenticationInfo,
      shouldGetEstablishmentsFromBff
    } = getNeededInformationFromGlobalContext({ globalAppContext })
    const requestClientName = shouldGetEstablishmentsFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId
    const requestUrl = shouldGetEstablishmentsFromBff ? ESTABLISHMENTS_BFF_URL : ESTABLISHMENTS_URL
    const { requestHeaders } = getRequestHeaders({ shouldGetEstablishmentsFromBff, headersAuthenticationInfo })

    return { requestClientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { ESTABLISHMENTS },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { ESTABLISHMENTS: ESTABLISHMENTS_BFF },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetEstablishmentsFromBff = configs?.localization?.shouldGetEstablishmentsFromBff

      return {
        ESTABLISHMENTS_URL: ESTABLISHMENTS,
        ESTABLISHMENTS_BFF_URL: ESTABLISHMENTS_BFF(),
        shouldGetEstablishmentsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetEstablishmentsFromBff, headersAuthenticationInfo }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo
      const requestHeaders = shouldGetEstablishmentsFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        countryid: countryId,
        companyid: companyId,
      } : {
        'cache-control': 'no-cache',
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: accessToken,
        access_token: accessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}
