import TYPES from 'src/configs/Fields'

export const getColombiaFieldsSchema = ({ intl, dependencies }) => {
  const ADDRESS_STRUCTURE_COL = commonParams(TYPES.ADDRESS_STRUCTURE_COL(intl, dependencies))

  return [[ADDRESS_STRUCTURE_COL]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
