import * as yup from 'yup'
import { equalTo, format } from './Yup.customs'

yup.addMethod(yup.string, 'equalTo', equalTo)
yup.addMethod(yup.date, 'format', format)

export function Adapter (Yup) {
  return function YupValidatorAdapter (yupChain) {
    return function PropTypesValidator (props, propName) {
      const schema = Yup.object().shape({ [propName]: yupChain })

      try {
        schema.validateSync({ [propName]: props[propName] })

        return null
      }
      catch(e) {
        return new Error(`Yup${e}`)
      }
    }
  }
}
