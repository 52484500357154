const SHOW_WARNING = 'natura-caduni/Warning/SHOW_WARNING'
const HIDE_WARNING = 'natura-caduni/Warning/HIDE_WARNING'

const initialState = {
  warning: {
    message: null,
    open: false
  }
}

const types = [SHOW_WARNING, HIDE_WARNING]

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  if(types.includes(type)) {
    const { warning } = payload

    return {
      ...state,
      warning
    }
  }

  return state
}

export function ShowWarning(message, autoHideDuration, clickAway) {
  return {
    type: SHOW_WARNING,
    payload: {
      warning: {
        message,
        open: true,
        autoHideDuration,
        clickAway
      }
    }
  }
}

export function HideWarning() {
  return {
    type: HIDE_WARNING,
    payload: initialState
  }
}
