import { connect } from 'react-redux'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'department',
    label: intl.formatMessage(messages['department']),
    placeholder: intl.formatMessage(messages['department_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['department_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: validateFieldDependencies,
    required: true,
    disabledBy: [ 'address[].region' ]
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.province`, '', id)
  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.street`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.province`, false)
  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.street`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getProvinceWithDepartment(params) {
  const { value } = params
  return value && getProvince(params)
}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, addressesPeopleManagement, loading, error }, locale: { locale }} = state
  const { getAddressesCMM, departments: departmentsCMM } = addresses
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { region } = address[index] || {}

  const { getLevels, departments: departmentsBFF } = addressesPeopleManagement
  const { configs: {localization: { shouldGetAddressFromBff }}} = locale

  const departments = shouldGetAddressFromBff ? departmentsBFF : departmentsCMM
  const options = departments[region]

  const shouldGetDepartments = !options && !loading.departments && !error.addresses && region

  if (shouldGetDepartments) {
    getDepartments({region, getAddressesCMM, getLevels, shouldGetAddressFromBff })
  }

  return {
    ...ownProps,
    options: options ?? [],
    onChange: function(value) {
      getProvinceWithDepartment({ value, getAddressesCMM, getLevels, shouldGetAddressFromBff})
      onChange(value)
    }
  }
}

const getProvince = ({ value, getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if (shouldGetAddressFromBff) {
    getLevels({
      geoStructureCode: value,
      geoStructureType: 'provinces',
      queryParams: `level=3&parentLevel=2&parentCode=${value}`,
    })
  } else {
    getAddressesCMM({
      geoStructureCode: value,
      geoStructureType: 'provinces'
    })
  }
}

const getDepartments = ({region, getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if(shouldGetAddressFromBff) {
    getLevels({
      geoStructureType: 'departments',
      queryParams: `level=2&parentLevel=1&parentCode=${region}`,
      geoStructureCode: region
    })
  } else {
    getAddressesCMM({
      geoStructureCode: region,
      geoStructureType: 'departments'
    })
  }
}
