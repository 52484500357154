
import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import EmailValidation from 'src/viewModels/EmailValidation'

export const EMAIL_RESEND_LOADING = 'natura-caduni/EMAIL_RESEND_LOADING'
export const EMAIL_RESEND_SUCCESS = 'natura-caduni/EMAIL_RESEND_SUCCESS'
export const EMAIL_RESEND_ERROR = 'natura-caduni/EMAIL_RESEND_ERROR'

export const types = [EMAIL_RESEND_LOADING, EMAIL_RESEND_SUCCESS, EMAIL_RESEND_ERROR]

export function emailResendReducer(state, action, dependences) {
  let { error, emailValidation } = state
  const { type } = action

  const hasSuccess = type === EMAIL_RESEND_SUCCESS
  const hasError = type === EMAIL_RESEND_ERROR
  const loading = type === EMAIL_RESEND_LOADING

  if (hasSuccess) {
    emailValidation = new EmailValidation({ wasSent: true }, dependences)
    error = false
  }

  if (hasError) {
    error = new Error(action.error)
  }

  return {
    loading,
    emailValidation,
    error
  }
}

export function resendEmail(data) {
  const {
    dispatch,
    urls: { EMAIL_RESEND },
    locale: {
      id,
      configs: {
        localization: {
          contactValidationLegacy
        }
      }
    },
    user: {
      sellerId,
      authentication: { accessToken }
    }
  } = getContext()

  const method = contactValidationLegacy ? 'POST' : 'PATCH'

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method,
        url: EMAIL_RESEND(data),
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
        },
        data: {}
      }
    }
  })
}
