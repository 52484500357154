import { STATUS } from 'src/models/Status/Status'
import { SUBSTATUS } from 'src/models/Substatus/Substatus'
export class PersonRolesService {
  static errors = {
    missingParameters: {
      message: 'Missing required parameters'
    },
    unauthorizedRole: {
      message: 'Unauthorized role'
    }
  }

  constructor({ gateway, roles } = {}) {
    this.roles = roles
    this.gateway = gateway
  }

  async execute({ accessToken, personId }) {
    if (!personId || !accessToken) {
      return { error: PersonRolesService.errors.missingParameters }
    }

    return this.retrieveUserData({ accessToken, personId })
  }

  async retrieveUserData({ accessToken, personId }) {
    let getPersonData
    try {
      const getPerson = await this.gateway.getPersonById({ accessToken, personId })
      getPersonData = getPerson.data
    } catch (exception) {
      return { error: PersonRolesService.errors.unauthorizedRole }
    }

    const { name, roles, naturaCode } = getPersonData

    const user = this.generateManagerUserData(this.roles, roles)

    if (user.isValid) {
      return {
        name: user.roles.CSC ? 'CSC' : name,
        sellerId: naturaCode,
        roles: user.roles
      }
    }

    return { error: PersonRolesService.errors.unauthorizedRole }
  }

  generateManagerUserData(systemRoles, personRoles) {
    const roleKeys = this.getRoleKeys(systemRoles)

    for (const personRole of personRoles) {
      for (const roleKey of roleKeys) {
        if (systemRoles[roleKey].roleId === personRole.roleId
          && systemRoles[roleKey].functionId === personRole.functionId
          && isApprovedUser(personRole.status, personRole.substatus)) {
          return {
            isValid: true,
            roles: { [roleKey]: true }
          }
        }
      }
    }

    return {
      isValid: false
    }

    function isApprovedUser(status, substatus) {
      return status === STATUS.APPROVED && substatus === SUBSTATUS.APPROVED_SELLER
    }
  }

  getRoleKeys(systemRoles) {
    return Object.keys(systemRoles)
  }
}
