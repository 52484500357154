import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class Upload extends Entity {
  static SCHEMA = {
    attachments: {
      validator: adapter(yup.object()),
      defaultValue: {}
    }
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    this.save = this.save.bind(this)
    this.create = this.create.bind(this)
    this.reset = this.reset.bind(this)
  }

  save({ file, type, uploadUrl }) {
    return this.saveAction({ uploadUrl, file, type })
  }

  create(personId, file) {
    return this.createUrl(personId, file)
  }

  reset() {
    return this.resetUpload()
  }
}
