import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import Input from 'src/base/Form/Components/Input'
import { documentsEnum } from './Documents.type'
import { DOCUMENTS } from 'src/models/Document'
import { isDefaultFlowToggleActive } from '../../../config'

const messages = defineMessages({
  passport: {
    defaultMessage: 'Enter the passport number',
    id: 'form.field.passport'
  },
  national_registry: {
    defaultMessage: 'Enter the national registry number',
    id: 'form.field.national_registry'
  },
  foreign_registry: {
    defaultMessage: 'Enter the foreign registry number',
    id: 'form.field.foreign_registry'
  },
})

const dependencies = {
  messages
}

const helpMessage = {}
const placeholderMessage = {}


export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  helpMessage[DOCUMENTS.PASSPORT_PERU] = intl.formatMessage(messages['passport'])
  placeholderMessage[DOCUMENTS.PASSPORT_PERU] = intl.formatMessage(messages['passport'])
  placeholderMessage[DOCUMENTS.FOREIGN_REGISTRY] = intl.formatMessage(messages['foreign_registry'])
  placeholderMessage[DOCUMENTS.NATIONAL_REGISTRY] = intl.formatMessage(messages['national_registry'])

  return {
    id: 'document',
    type: connect(mapStateToProps)(Input)
  }
}

function mapStateToProps({ locale: { locale } }, ownProps) {
  const {
    configs: {
      localization: {
        documents: {
          mask: documentsMask
        }
      }
    }
  } = locale

  const { form: { values: { documents: [{ type } = {}] = [] } }, disabled } = ownProps

  return {
    ...ownProps,
    placeholder: isDefaultFlowToggleActive() ? placeholderMessage[type] : null,
    message: isDefaultFlowToggleActive() ? null : helpMessage[type],
    mask: getMask({ type, disabled, documentsMask })
  }
}

function getMask({ type, disabled, documentsMask }) {
  if (disabled)
    return null

  return documentsMask[documentsEnum[type]]
}
