import TYPES from 'src/configs/Fields'
import Policies from 'src/configs/Locales/DEFAULT/views/SelfRegister/Policies'

export const getDefaultFieldsSchema = ({ intl, dependencies }) => {
  const POLICIES = commonParams(
    Policies(intl, dependencies, TYPES.POLICIES),
    false
  )

  return [[POLICIES]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
