import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  indication_search_type_cedula_extranjeira: {
    defaultMessage: 'Foreign Card',
    id: 'form.field.indicationSearchType.foreign'
  },
  indication_search_type_cedula_cidadania: {
    defaultMessage: 'Citizen Card',
    id: 'form.field.indicationSearchType.citizen'
  },
  indication_search_type_dni: {
    defaultMessage: 'National Registry',
    id: 'form.field.indicationSearchType.din'
  },
  indication_search_type_carnet_foreign: {
    defaultMessage: 'Foreign Registry',
    id: 'form.field.indicationSearchType.foreign.registry'
  },
  indication_search_type_passport: {
    defaultMessage: 'Passport',
    id: 'form.field.indicationSearchType.passport'
  },
  indication_search_type_code: {
    defaultMessage: 'Code',
    id: 'form.field.indicationSearchType.code'
  },
  indication_search_type_name: {
    defaultMessage: 'Name',
    id: 'form.field.indicationSearchType.name'
  },
  has_Indication_yes: {
    defaultMessage: 'Yes',
    id: 'form.field.hasIndication.yes'
  },
  has_Indication_not: {
    defaultMessage: 'Not',
    id: 'form.field.hasIndication.not'
  },
  indication_search_type: {
    defaultMessage: 'Search recommendation',
    id: 'form.field.indicationSearchType'
  },
  registrant_search_type: {
    defaultMessage: 'Search registrant',
    id: 'form.field.registrantSearchType'
  },
  indication_code: {
    defaultMessage: 'Indication code',
    id: 'form.field.indication.code'
  },
  registrant_code: {
    defaultMessage: 'Registrant code',
    id: 'form.field.registrant.code'
  },
  field_required: {
    defaultMessage: 'Required field',
    id: 'form.field.required'
  }
})
