import * as yup from 'yup'

import Document from './Documents.document'
import DocumentType, { documentsEnum } from './Documents.type'
import { DOCUMENTS } from 'src/models/Document'

export default function Builder(intl, injection) {
  const {
    person = {},
    locale: {
      configs: { localization }
    }
  } = injection
  const {
    documents: { personal }
  } = localization

  let documents = person
    .toJSON()
    .documents.filter(filterDocuments, { documents: personal })
  const DOCUMENT = Document(intl, injection)
  const DOCUMENT_TYPE = DocumentType(intl, injection)

  if (!documents || !documents.length) {
    documents = [{ type: DOCUMENT_TYPE.options[0].value, document: '' }]
  }

  return {
    id: 'documents',
    min: 1,
    max: 1,
    defaultValue: documents,
    fields: [[DOCUMENT]],
    validations: yup.array().of(
      yup.object().shape({
        document: documentValidation('type', localization),
        type: yup.mixed().required()
      })
    )
  }
}

export function documentValidation(
  documentType,
  localization
) {
  const { documents: { lengthValidation } = {} } = localization || {}
  const { min, max } = lengthValidation[documentsEnum[DOCUMENTS.DNI_ARGENTINA]] || {}

  return yup
    .string()
    .required()
    .transform((value) => value.replace(/\./g, ''))
    .when(documentType, (type, schema) => {
      if (min) schema = schema.min(min)
      if (max) schema = schema.max(max)

      return schema
    })
}

function filterDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}
