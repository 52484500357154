import { buildTheme } from '@naturacosmeticos/natds-react'
import { COMPANY_NAME_BY_ID } from '../models/Company'
import { useEffect, useState } from 'react'
import { ColorScheme } from '../models/theme-ds/color-scheme'
import { enableThemeElo } from '../utils'

export const useFVTheme = (companyId) => {
  const isActivatedToCountry = enableThemeElo()
  const company = isActivatedToCountry ? 'consultoriaDeBeleza' : COMPANY_NAME_BY_ID[companyId]?.toLowerCase()

  let defaultTheme = buildTheme(company, ColorScheme.LIGHT)

  if(company === 'avon') defaultTheme = buildTheme('avon_v2', ColorScheme.LIGHT)
  
  const [fvTheme, setTheme] = useState(defaultTheme)

  useEffect(() => {
    const newTheme = buildTheme(company, ColorScheme.LIGHT)
    setTheme(newTheme)
  }, [company])

  return fvTheme
}