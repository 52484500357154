export default [
  'Por medio del presente, doy mi autorización para que NATURA COSMÉTICOS S.A. (en adelante NATURA), domiciliada en Av. Del Ejercito N° 801, Urb. Santa Cruz, distrito de Miraflores, Lima-Perú, para que trate mis datos personales. He sido informado que la información que otorgue será almacenada en la base de datos denominada “Consultoras Natura”, por el tiempo necesario para cumplir con las finalidades que se describen a continuación o hasta que decida revocar mi consentimiento.',
  'NATURA tratará mis datos personales para las siguientes finalidades: i) cumplir con lo pactado en mi relación contractual con NATURA, ratificando mi condición de revendedora; ii) absolver mis dudas, reclamos y sugerencias; iii) en caso tenga obligaciones de pago pendiente, NATURA podrá realizar labores de cobranza; iv) con el objeto de cumplir mi responsabilidad de revendedora, NATURA me comunicará acerca de las promociones, beneficios, concursos, novedades y, en general, publicidad sobre los productos y/o servicios de NATURA; v) fines estadísticos e históricos para que NATURA verifique los productos o servicios que he adquirido; vi) compartir mi información (nombre, teléfono y correo electrónico) en los canales oficiales de NATURA; y, vii) en caso lo autorice, tratar de mi imagen en los diversos documentos físicos o virtuales como la página web institucional, folletos, mural físico o virtual, intranet corporativo, redes sociales de NATURA, entre otros documentos elaborados por NATURA.',
  'NATURA tratará mis datos personales directamente o podrá contratar con terceras personas quienes, al igual que NATURA, han adoptado las medidas de seguridad técnicas, legales y organizativas necesarias para tratar mis datos personales bajo condiciones de seguridad y confidencialidad y exclusivamente para las finalidades que autorizo por medio del presente documento. Los encargados de tratar mis datos personales son:',
  '• PROMOTICK S.A.C., ubicada en Cal. Santa Isabel Nro. 194, distrito de Miraflores, Lima-Perú. ',
  '• IPSOS OPINION Y MERCADO S.A., ubicada en Av. Reducto Nro. 1363 distrito de Miraflores, Lima-Perú.',
  '• TELEATENTO DEL PERÚ S.A.C., ubicada en Av. La Molina Nro. 190, distrito de Ate, Lima-Perú.',
  '• IRON MOUNTAIN PERU S.A., ubicada en Av. Elmer Faucett Nro. 3462, Callao, Lima.',
  '• TELEFONICA DEL PERU S.A.A., ubicada en Av. Cruz del Sur 129, distrito de Santiago de Surco, Lima-Perú.',
  '• EQUIFAX PERÚ S.A. ubicada en Calle Chinchon N° 1018, San Isidro, Lima – Perú.',
  '• EXPERIAN PERÚ S.A.C. ubicada en Av. Canaval y Moreyra N° 480, San Isidro, Lima – Perú.',
  '• XCHANGE PERÚ S.A.C. ubicada en Calle Andrés Reyes N° 510, San Isidro, Lima – Perú.',
  '• COBRADORES & ABOGADOS CONSULTORES S.A.C. ubicada en Chalet 3-M Unidad Vecinal de Mirones, Lima, Lima -Perú.',
  '• GESTIÓN DE COBRANZAS Y RECUPERO S.R.L. ubicada en Calle Jorge Muelle N° 433, Oficina 8, San Borja, Lima – Perú.',
  '• SENTINEL PERÚ S.A. ubicada en Av. Salaverry N° 2375, San Isidro, Lima – Perú.',
  '• SOSAYA, MATTA & SHIMABUKURO ABOGADOS S.A.C. ubicada en Av. Arequipa N° 4130, Oficina 601, Miraflores, Lima – Perú.',
  '• TRANSOLYFER S.A.C. ubicada en José María Seguin N° 545, San Juan de Miraflores, Lima – Perú.',
  '• UNION STARE E.I.R.L. ubicada en Calle Las Orquídeas N° 2624, Lince, Lima – Perú.',
  '• TRANSROWI S.A. ubicada en Alameda Sur N° 275, Chorrillos, Lima – Perú.',
  '• LOOTUS CARGO EXPRESS S.A.C. ubicada en Mariano Pastor Sevilla Mz. “M” Lote 18, Villa El Salvador, Lima – Perú.',
  '• AQPMAIL S.R.L. ubicada en San Martin N° 710, Miraflores, Arequipa - Perú.',
  '• ANDES EXPRESS S.A.C. ubicada en Calle Ignacio Merino N° 1554, Lince, Lima – Perú.',
  '• DOMUS SOLUCOES INTERNET, LTDA, ubicada en Calle Josefina Gasparian, N°. 61 - Sala 7, Ciudad Comendador Levy Gaspanan, Río de Janeiro, Brasil.',
  '• GERA INOVAÇÃO E DESENVOLVIMENTO LTDA, ubicada en R Ubaldo Pinto, 41 Parque Terranova - Valinhos/ Sao Paulo, Brasil.',
  '• NATURA COSMETICOS S.A.(Argentina), ubicada en Cazadores de Coquimbo 2860, Torre 2, piso 2, Munro, Buenos Aires, B1605AZF Munro, Buenos Aires, Argentina.',
  '• NATURA COSMETICOS S.A. (Brasil), ubicada en Rodovia Régis Bittencourt, s/n - Km 293 - Potuverá Sao Paulo Brasil.',
  '• XYCLON, empresa de Gonzales Castro Fernando Javier, identificado con CUIT 20222976988, ubicada en Bolivia 5331 – Ciudad de Buenos Aires – Argentina',
  'He sido informado que: (i) NATURA garantiza la confidencialidad y buen uso de mis datos personales; (ii) esta autorización es facultativa pero que, si no la otorgo, ninguna de las finalidades arriba indicadas podrá realizarse, y (iii) podré ejercer los derechos previstos en la Ley N° 29733, dirigiendo una comunicación escrita con el asunto “Derechos Datos Personales” al correo electrónico: SNACPERU@NATURA.NET o a la dirección: empresa domiciliada en NATURA',
  'En señal de conformidad, suscribo voluntariamente el presente documento sabiendo que, pese a ser opcional, sin mi consentimiento no se podrán cumplir las finalidades indicadas con anterioridad.'
]
