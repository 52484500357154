import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import Button, { TYPES } from 'src/base/Button'
import MESSAGES from '../ContactValidation.messages'

import '../ContactValidation.styl'

const bem = bemClassName.bind(null, 'contact-validation-modal')

export function ResendEmailSuccess({ intl, closeModal }) {
  return (
    <div className={classNames(bem('content'))} >
      <h3 className={classNames(bem('title'))}>
        {intl.formatMessage(MESSAGES['modal_unsuccessfully_title'])}
      </h3>
      <p className={classNames(bem('text'))}>
        {intl.formatMessage(MESSAGES['modal_email_success_text'])}
      </p>
      <footer className={classNames(bem('footer'))}>
        <Button type={TYPES.PRIMARY} onClick={closeModal}>
          {intl.formatMessage(MESSAGES['modal_button_agree'])}
        </Button>
      </footer>
    </div>
  )
}

ResendEmailSuccess.propTypes = {
  intl: PropTypes.object,
  closeModal: PropTypes.func
}

export function ResendEmailContent({ intl, email, closeModal, send }) {
  return (
    <div className={classNames(bem('content'))}>
      <h3 className={classNames(bem('title'))}>
        {intl.formatMessage(MESSAGES['modal_unsuccessfully_title'])}
      </h3>

      <p className={classNames(bem('text'))}>
        {intl.formatMessage(MESSAGES['modal_email_default_text'])} {email}
      </p>

      <footer className={classNames(bem('footer'))}>
        <Button onClick={closeModal}>
          {intl.formatMessage(MESSAGES['modal_button_cancel'])}
        </Button>
        <Button type={TYPES.PRIMARY} onClick={send}>
          {intl.formatMessage(MESSAGES['modal_button_send'])}
        </Button>
      </footer>
    </div>
  )
}

ResendEmailContent.propTypes = {
  closeModal: PropTypes.func,
  email: PropTypes.string,
  send: PropTypes.func,
  intl: PropTypes.object,
}

export function ResendEmailError({ intl, closeModal }) {
  return (
    <div className={classNames(bem('content'))} >
      <h3 className={classNames(bem('title'))}>
        {intl.formatMessage(MESSAGES['modal_no_backend_service_title'])}
      </h3>

      <p className={classNames(bem('text'))}>
        {intl.formatMessage(MESSAGES['modal_no_backend_service_text'])}
      </p>

      <footer className={classNames(bem('footer'))}>
        <Button onClick={closeModal}>
          {intl.formatMessage(MESSAGES['modal_button_validate_late'])}
        </Button>
      </footer>
    </div>
  )
}

ResendEmailError.propTypes = {
  closeModal: PropTypes.func,
  intl: PropTypes.object,
}
