import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'
import { getCities } from './City.col'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'department',
    label: intl.formatMessage(messages['department']),
    placeholder: intl.formatMessage(messages['department_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['department_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChangeDepartment(intl),
    required: true,
    disabledBy: [],
    htmlProps: { autoFocus: false },
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['department']),
      value: label
    }
  })
}

const onChangeDepartment = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.city`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)

  setFieldTouched(`${parent}.city`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
}

const getDepartments = getLevels => {
  getLevels({
    queryParams: 'level=1',
    geoStructureType: 'departments'
  })
}

const mapStateToProps = ({ addresses, connectivity }, ownProps) => {
  const {
    addressesPeopleManagement: { departments, getLevels },
    loading,
    error
  } = addresses

  const {
    group: { index },
    onChange,
    form: {
      values: { address }
    }
  } = ownProps

  const { countryName } = address[index] || {}

  const options = departments[countryName]
  const shouldGetDepartments =
    !options && !loading.departments && !error.addresses && countryName

  if (shouldGetDepartments && !connectivity.isOffline) {
    getDepartments(getLevels)
  }

  return {
    ...ownProps,
    options: options || [],
    loading: loading.departments,
    onChange: (value, label) => {
      getCities({ value, getLevels })
      onChange(value, label)
    }
  }
}
