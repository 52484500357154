import { store } from '../../../../../../../App.state'
import { isEnableDigitalStoreStepForAvonMexicoFlowToggleActive } from '../../../../../../../config'
import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'
import { isPendingSignatureFlow } from '../buildSteps/defaultSteps/defaultSteps.mex'

export const buildFlowsAvon = () => {
  const {
    user: { user }
  } = store.getState()
  const IS_DIGITAL_STORE_ENABLED = isEnableDigitalStoreStepForAvonMexicoFlowToggleActive()

  if (user.authenticated) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: [
        'pre-register',
        'personal-data',
        'authorization-code',
        'documentation',
        'documents',
        'pre-register-confirmation'
      ].filter((step) => typeof step !== 'undefined')
    }
  }
  if (isPendingSignatureFlow()) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: [
        'pending-signature',
        'digital-signature',
        'completed'
      ].filter((step) => typeof step !== 'undefined')
    }
  }

  return {
    [BEAUTY_CONSULTANT_PROFILE]: [
      'pre-register',
      'personal-data',
      'authorization-code',
      'documentation',
      'documents',
      'digital-signature',
      IS_DIGITAL_STORE_ENABLED ? 'digital-store' : undefined,
      'completed'
    ].filter((step) => typeof step !== 'undefined')
  }
}
