import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import Ocr from 'src/viewModels/Ocr'
import {
  isRemoveOCRValidationToggleActive
} from '../../config'

export const OCR_LOADING = 'natura-caduni/OCR_LOADING'
export const OCR_SUCCESS = 'natura-caduni/OCR_SUCCESS'
export const OCR_ERROR = 'natura-caduni/OCR_ERROR'

export const types = [OCR_LOADING, OCR_SUCCESS, OCR_ERROR]

export function ocrStartReducer(state, action, dependencies) {
  let { error, ocr } = state

  const hasSuccess = action.type === OCR_SUCCESS
  const hasError = action.type === OCR_ERROR
  const loading = action.type === OCR_LOADING

  if (hasSuccess) {
    ocr = new Ocr({ verified: true }, dependencies)
  }

  if (hasError) {
    ocr = new Ocr({}, dependencies)
    error = new Error(action.error)
  }

  return {
    loading,
    error,
    ocr
  }
}

export function startOcr(person, sequence) {
  if (!sequence) return

  const {
    dispatch,
    urls: { DOCUMENTS_VALIDATION },
    locale: { id },
    user: {
      sellerId,
      authentication: { xApikey, accessToken }
    }
  } = getContext()

  if (isRemoveOCRValidationToggleActive()) {
    return dispatch({ type: OCR_SUCCESS })
  }

  const {
    personId,
    businessModel: { businessModelId, functionId, roleId }
  } = person

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'PATCH',
        url: DOCUMENTS_VALIDATION(personId, sequence),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          businessModel: businessModelId,
          function: functionId,
          role: roleId
        }
      }
    }
  })
}
