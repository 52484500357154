import { getMalaysiaFieldsSchema } from './getFieldsSchema.mys'
import { getColombiaFieldsSchema } from './getFieldsSchema.col'
import { getDefaultFieldsSchema } from './getFieldsSchema.default'

const strategies = {
  MYS: getMalaysiaFieldsSchema,
  COL: getColombiaFieldsSchema,
  __default__: getDefaultFieldsSchema
}

export const getFieldsSchema = (localeId, { intl, dependencies }) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy({ intl, dependencies })
}
