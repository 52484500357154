import {DocumentFieldsMapper} from './DocumentFieldsMapper'
import {ATTACHMENT_TYPES} from '../../../../../../../AttachmentTypes'

export const DOCUMENTS = {
  NATIONAL_REGISTRY: 19,
  FOREIGN_REGISTRY: 33,
  TEMPORARY_PERMISSION_WORK: 32,
  RUC: 27
}

export const MESSAGES_ID = {
  [DOCUMENTS.NATIONAL_REGISTRY]: 'box_documents_label_document_national_registry',
  [DOCUMENTS.FOREIGN_REGISTRY]: 'box_documents_label_document_foreign_registry',
  [DOCUMENTS.TEMPORARY_PERMISSION_WORK]: 'box_documents_label_document_passport',
  [DOCUMENTS.RUC]: 'box_documents_label_document_ruc'
}

const attachmentTypeToMessageIdsEnum = {
  [ATTACHMENT_TYPES.CARNET_EXTRANJERIA_FRONT]: 'box_documents_label_attachments_document_foreign_front',
  [ATTACHMENT_TYPES.CARNET_EXTRANJERIA_BACK]: 'box_documents_label_attachments_document_foreign_back',
  [ATTACHMENT_TYPES.RUC_FRONT]: 'box_documents_label_attachments_document_front',
  [ATTACHMENT_TYPES.RUC_BACK]: 'box_documents_label_attachments_document_back',
  [ATTACHMENT_TYPES.DNI_PERU_FRONT]: 'box_documents_label_attachments_document_front',
  [ATTACHMENT_TYPES.DNI_PERU_BACK]: 'box_documents_label_attachments_document_back',
}

export const getDocumentsFields = ({ intl, nationalityId, messages, documents, attachments }) => {
  const documentFieldsMapper = new DocumentFieldsMapper({
    intl,
    messagesId: MESSAGES_ID,
    attachmentTypeToMessageIdsEnum: attachmentTypeToMessageIdsEnum
  })
  const nationality = documentFieldsMapper.mapNationalityField({ nationalityId, messages })
  const documentsFields = documentFieldsMapper.mapDocuments({ documents, messages })
  const rucField = getRUC({ documents, messages })
  const attachmentsField = documentFieldsMapper.mapAttachmentsField({ attachments, messages, documentsFields })
  const termsField = documentFieldsMapper.mapTermsField({ attachments, messages })

  return [nationality, rucField, ...documentsFields, attachmentsField, termsField]

  function getRUC({ documents, messages }) {
    const ruc = documents.find(document => document.type === DOCUMENTS.RUC)

    return {
      label: intl.formatMessage(messages.box_documents_label_document_ruc),
      field: ruc?.document || '-'
    }
  }
}
