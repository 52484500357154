import axios from 'axios'

const SOURCE_SYSTEM = '7'
const COUNTRY = 'CL'
const COUNTRY_ID = 4
const COMPANY_ID = 1
const LANGUAGE = 'es-CL'
const FLOW_TYPE = 'CT'
const URL_GLOBAL_PEOPLE_MANAGEMENT = process.env.GLOBAL_PEOPLE_BASE_URI
const GEOGRAPHICAL_LEVELS_BFF_URL = process.env.GEOGRAPHICAL_LEVELS_BFF_URL
const URL_PEOPLE_MANAGEMENT = '/businessrelationship/peoplemanagement/v1'
const URL_PEOPLE = `${URL_PEOPLE_MANAGEMENT}/people`
const URL_GLOBAL_PEOPLE = `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people`
const TENANT_ID = '51326ed9-1db3-4af0-bf3e-40190a68e98f'
const APPLICATION_JSON = 'application/json'

const CHL_CLIENT = {
  baseURL: process.env.BASE_URI,
  headers: {
    Accept: APPLICATION_JSON,
    'Content-Type': APPLICATION_JSON,
    client_id: process.env.CLIENT_ID,
    country: COUNTRY,
    countryId: COUNTRY_ID,
    language: LANGUAGE,
    sourceSystem: SOURCE_SYSTEM,
    flowType: FLOW_TYPE,
    tenantId: TENANT_ID,
    companyId: COMPANY_ID
  }
}

const URLS = {
  AUTH_PUBLIC: 'api-authorization/api/v2/oauth/access-token',
  ADDRESS_BFF: (params = '') =>
    `${GEOGRAPHICAL_LEVELS_BFF_URL}/commercial-structure/geographic-levels?${params}`,
  ADDRESS: (params = '') => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/commercial-structure-levels?${params}`,
  CSC: () => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/parameters/registrationTeam`,
  ROLES: () => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people`,
  COMMERCIAL_STRUCTURE: (userId) => `/sgi/pe/v1/api/commercialStructures?responsibleUserCode=${userId}`,
  PEOPLE: (personId) => personId ? `${URL_GLOBAL_PEOPLE}/${personId}` : URL_GLOBAL_PEOPLE,
  COMPLETE_PEOPLE: (personId) => personId ? `${URL_GLOBAL_PEOPLE}/${personId}/complete` : URL_GLOBAL_PEOPLE,
  CREATE_PERSON_ROLE: (personId) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people/${personId}/person-roles`,
  APPROVATION: (personId, personRoleId) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people/${personId}/person-roles/${personRoleId}/approve`,
  REJECTION: (personId, personRoleId) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people/${personId}/person-roles/${personRoleId}/reject`,
  REMOVE_PENDENCY: (personRoleId) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/person-roles/${personRoleId}/remove-pendency`,
  REJECTION_REASONS: () => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/rejection-reasons`,
  CONSULTANTS: {
    find: ({ sellerId }) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/people/${sellerId}`,
    findById: ({ personId, sellerId }) => `${URL_GLOBAL_PEOPLE}/${personId}/consultants?consultantId=${sellerId}`,
    findByName: ({ personId, name, queryString }) => `${URL_GLOBAL_PEOPLE}/${personId}/consultants?name=${name}&${queryString}`,
    findByDocuments: ({ personId, sellerDocument, documentTypeId }) => `${URL_GLOBAL_PEOPLE}/${personId}/consultants?documentNumber=${sellerDocument}&documentTypeId=${documentTypeId}`
  },
  DOCUMENTS: (personId) => `${URL_GLOBAL_PEOPLE}/${personId}/documents/attachments`,
  DOCUMENTS_VALIDATION: (personId, documentAttachmentId) => `${URL_GLOBAL_PEOPLE}/${personId}/document-attachments/${documentAttachmentId}/validate`,
  ATTACHMENTS: (personId) => `${URL_GLOBAL_PEOPLE}/${personId}/attachments`,
  CEASE: (personId, personRoleId) => `${URL_GLOBAL_PEOPLE}/${personId}/person-role/${personRoleId}/cease`,
  SEARCH: (query) => `${URL_GLOBAL_PEOPLE}?${query}`,
  REVALIDATE: (personId) => `${URL_PEOPLE}/${personId}/workflow/reset`,
  EMAIL_RESEND: ({ sequence }) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/emails/${sequence}/send-confirmation-code`,
  EMAIL_VALIDATION: ({ emailId, confirmationCode }) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/emails/${emailId}/validate/${confirmationCode}`,
  SMS_TOKEN_RESEND: ({ sequence }) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/telephone/${sequence}/send-confirmation-code`,
  SMS_TOKEN_VALIDATION: ({ sequence, token }) => `${URL_GLOBAL_PEOPLE_MANAGEMENT}/telephones/${sequence}/validate/${token}`,
  FILTERS: `${URL_GLOBAL_PEOPLE_MANAGEMENT}/status-substatus`,
  ESTABLISHMENTS: `${URL_GLOBAL_PEOPLE_MANAGEMENT}/establishments`,
  ALLOCATE_PERSON: (personId) => `${URL_GLOBAL_PEOPLE}/${personId}/allocate-commercial-structure`,
  SAVE_PERSON_STATUS: (personId, personSubstatus) => `${URL_GLOBAL_PEOPLE}/${personId}/substatus/${personSubstatus}`
}

export default {
  client: axios.create(CHL_CLIENT),
  urls: URLS
}
