import { ADDRESS_TYPE } from 'src/models/Address/AddressType'
import { MAILING_ADDRESS, DELIVERY_ADDRESS } from 'src/models/Address/AddressUse'

const LEVELS = [
  { type: 'country' },
  { type: 'region' },
  { type: 'city' },
  { type: 'neighborhood' },
  { type: 'street' },
]

export function onSubmition({ person }) {
  return function (data, callback) {
    const addresses = parseData(data)

    const params = {
      addresses,
      isRegistrationComplete: true
    }

    return person.save(params, callback)
  }
}

function parseData(data) {
  const { address } = data
  const [{ copyAdress }] = address

  return address.map(mapAddress, { copyAdress })
}

function mapAddress(address, key) {
  const {
    gmap: {
      street,
      location: {
        lat: latitude,
        lng: longitude
      }
    },
    number,
    zipCode,
    department: region,
    city,
    neighborhood,
    countryName: country,
    complement,
    references
  } = address

  const types = {
    country,
    region,
    city,
    neighborhood,
    street: street.split(',')[0],
  }

  const geographicalLevels = LEVELS.map(mapLevels, { types })
  const addressType = ADDRESS_TYPE.MAILING
  const addressUse = getAddessUse(this.copyAdress, key)
  const extraInfo = getExtraInfo({ references })

  return {
    zipCode,
    complement,
    number,
    latitude,
    longitude,
    extraInfo,
    addressType,
    addressUse,
    geographicalLevels
  }
}

function getAddessUse(copyAdress, key) {
  if (!copyAdress && key === 0) return [MAILING_ADDRESS]
  if (!copyAdress && key === 1) return [DELIVERY_ADDRESS]

  return [DELIVERY_ADDRESS, MAILING_ADDRESS]
}

function mapLevels(level) {
  const { type: locale } = level

  return { type: locale.toUpperCase(), description: this.types[locale].toUpperCase() }
}

function getExtraInfo(params) {
  const extraInfo = Object.entries(params).reduce(reducerExtraInfo, {})

  return JSON.stringify(extraInfo)
}

function reducerExtraInfo(accum, [key, value]) {
  return value ? { ...accum, [key.toUpperCase()]: value } : accum
}
