import { store } from '../../../../../../../App.state'
import { BuildStepsMapper } from './BuildStepsMapper'

import {
  getTotalSteps,
  createPendingSignatureSteps,
  createAuthenticatedSteps,
  isPendingSignatureFlow,
  stepsRegistrationDefault
} from './defaultSteps/defaultSteps.mex'

export const buildStepsAvon = ({ basePath }) => {
  const { user: { user } } = store.getState()

  const totalSteps = getTotalSteps()
  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: totalSteps })

  const pendingSignatureSteps = createPendingSignatureSteps(buildStepsMapper)
  const stepsRegistrationAuthenticated = createAuthenticatedSteps(buildStepsMapper)

  if (user.authenticated) {
    return stepsRegistrationAuthenticated
  }

  if (isPendingSignatureFlow()) {
    return pendingSignatureSteps
  }

  return stepsRegistrationDefault(buildStepsMapper)
}
