import { normalizePhones } from 'src/models/Phone'
import { EMAIL_ALREADY_EXISTS_CODE } from 'src/views/Register/RegisterErrorEnum'
import { getSubstatus } from './strategies/getSubstatus/getSubstatus'
import { getParsedData } from './strategies/getParsedData'
import { EventDispatcher } from '../../../events/EventDispatcher'
import { EVENT_TYPE } from '../../../events/events'
import { CountryIdAlphaCode3 } from '../../../../../../models/country/country-ids'
import { isColombiaWithLegoToggleActive } from 'src/config'

export function onSubmitting(deps) {
  const { person, peopleRegister, locale: { configs, id: localeId }, user } = deps

  const { shouldPreventAllocatePersonCall } = getNeedInfoFromLocaleConfigs(configs)

  return function (data, callback) {
    const parsedData = parseData(localeId, { data, person, configs, user })

    const updatePerson = person.save(parsedData, (newPerson, result) => {
      if (!result.error) {
        if (shouldAllocatePerson({ person })) {
          person.allocate(newPerson)
        }
        person.saveStatus({ substatus: getSubstatus(localeId) })
        callback()
      }
      handleEmailAlreadyExistsError(result, person, localeId, parsedData, callback)
    })

    peopleRegister.savePerson({ ...person, ...parsedData })

    return updatePerson
  }

  function getNeedInfoFromLocaleConfigs(configs){
    const shouldPreventAllocatePersonCall = configs?.localization?.personalData?.shouldPreventAllocatePersonCall

    return { shouldPreventAllocatePersonCall }
  }

  function shouldAllocatePerson({ person }){
    const { businessModel: { roleId, functionId, businessModelId } } = person
    switch (localeId) {
      case CountryIdAlphaCode3.COLOMBIA:
        return isColombiaWithLegoToggleActive() && !shouldPreventAllocatePersonCall && isPersonCN(roleId, functionId, businessModelId)
      default:
        return !shouldPreventAllocatePersonCall && isPersonCN(roleId, functionId, businessModelId)
    }
  }
}

export function parseData(localeId, { data, person, configs, user }) {
  const {
    name
  } = data

  const phones = data.phones.map(normalizePhones, { configs, phones: person.phones })
  const emails = data.emails && data.emails.reduce(reduceEmails, person.emails)
  const additionalDataFields = getParsedData(localeId, { data, configs })
  const additionalInformation = handleAdditionalInformation(data, person.additionalInformation, user)

  return {
    name,
    phones,
    emails,
    additionalInformation,
    ...additionalDataFields
  }
}

export function reduceEmails(accum, current, index, array) {
  const EMAIL = 1
  const [original, { sequence } = {}] = accum

  if (index === array.length - 1) accum.splice(0, 1)

  const { email } = current

  if (!email) return accum

  return [...accum, { ...original, email, type: EMAIL, sequence }]
}

function handleEmailAlreadyExistsError(result, person, localeId, parsedData, callback) {
  if (emailAlreadyExistsError(result)) {
    const substatus = getSubstatus(localeId, formatResultErrorForGetSubstatus(result))
    person.saveStatus({ substatus })
      .then(() => {
        person.setError({ originalError: result.error })
        EventDispatcher.send(EVENT_TYPE.PERSONAL_DATA_OPEN_DIALOG_EMAIL_ALREADY_EXISTS_ERROR)
      })
    callback()
  }
}

function formatResultErrorForGetSubstatus(result) {
  const { error: { response: { data: error } } } = result
  return {
    error: { response: { data: error } }
  }
}

function handleAdditionalInformation(data, personAdditionalInformation, user) {
  const additionalInformation = user.authenticated ?
    {...personAdditionalInformation, registrantId: user.sellerId } :
    {...personAdditionalInformation, registrantId: null }

  if(data?.shouldUseRecommender){
    return { ...additionalInformation, introducerSellerId: data.additionalInformation.introducerSellerId }
  }
  return additionalInformation
}

function emailAlreadyExistsError(result) {
  return result.error
    && result.error.response
    && result.error.response.data
    && (
      result.error.response.data.code === EMAIL_ALREADY_EXISTS_CODE
      || (result.error.response.data.error && result.error.response.data.error.code  === EMAIL_ALREADY_EXISTS_CODE)
    )
}

function isPersonCN(roleId, functionId, businessModelId) {
  return roleId === 1 && functionId === 1 && businessModelId === 1
}
