import User from 'src/models/User'

import { rehydrateReducer, types as userRehydrateTypes } from './User.rehydrate'
import { userLoginReducer, login, loginWithCognito, types as userLoginTypes } from './User.login'
import { userLogoutReducer, logout, types as userLogoutTypes } from './User.logout'
import { userPublicAuthReducer, publicAuthenticate, types as userPublicTypes } from './User.public'
import { userOfflineAuthReducer, offlineAuthenticate, types as userOfflineTypes } from './User.offline'
import { userRefreshAuthenticationReducer, refreshAuthentication, types as userAuthenticationRefreshTypes } from './User.refresh'

const dependencies = {
  rehydrateReducer,
  userLoginReducer,
  userPublicAuthReducer,
  userOfflineAuthReducer,
  userRefreshAuthenticationReducer
}

const userDependencies = {
  loginWithCognito,
  login,
  logout,
  publicAuthenticate,
  offlineAuthenticate,
  refreshAuthentication
}

const initialState = {
  loading: false,
  error: false,
  user: new User({}, userDependencies)
}

export default function reducer(state = initialState, action = {}, _, injection = {}) {
  const { rehydrateReducer, userLoginReducer } = { ...injection, ...dependencies }

  const { type } = action

  if(userRehydrateTypes.includes(type))
    return rehydrateReducer(state, action, userDependencies)

  if(userLoginTypes.includes(type))
    return userLoginReducer(state, action, userDependencies)

  if(userLogoutTypes.includes(type))
    return userLogoutReducer(state, action, userDependencies)

  if(userPublicTypes.includes(type))
    return userPublicAuthReducer(state, action, userDependencies)

  if(userOfflineTypes.includes(type))
    return userOfflineAuthReducer(state, action, userDependencies)

  if(userAuthenticationRefreshTypes.includes(type))
    return userRefreshAuthenticationReducer(state, action, userDependencies)

  return state
}
