import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import Document from 'src/models/Document'
import Email from 'src/models/Email'
import Phone, { PHONE_TYPES } from 'src/models/Phone'
import ContactReference from 'src/models/ContactReference'
import Address from 'src/models/Address'
import Status from 'src/models/Status'
import Substatus from 'src/models/Substatus'
import PoliciesAccepted from 'src/models/PoliciesAccepted'
import PaymentPlan from 'src/models/PaymentPlan'
import Workflow from 'src/models/Workflow'
import BusinessModel from 'src/models/BusinessModel'
import Roles from 'src/models/Roles'
import SuggestedSalesHierarchy from 'src/models/SuggestedSalesHierarchy'
import AdditionalInformation from 'src/models/AdditionalInformation'
import Attachment from 'src/models/Attachment'
import { isEnablePersonMiddleName } from 'src/config'

const adapter = validatorAdapter('Yup', yup)

// TODO: Move genders options to the model
export const GENDERS = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3
}

const SELF_REGISTER_SOURCE_SYSTEM = '5'

export default class Person extends Entity {
  static SCHEMA = {
    personId: adapter(yup.string().required()),
    firstName: adapter(yup.string()),
    lastName: adapter(yup.string()),
    ...(isEnablePersonMiddleName() && {
      middleName: adapter(yup.string()),
    }),
    name: adapter(yup.string().required()),
    fullName: adapter(yup.string().required()),
    motherName: adapter(yup.string().nullable()),
    nationalityId: adapter(yup.number()),
    nationality: adapter(yup.string()),
    birthday: adapter(yup.date().nullable()),
    gender: adapter(yup.number()),
    approvalDate: adapter(yup.date()),
    registrationDate: adapter(yup.date()),
    _links: adapter(yup.array().nullable()),
    sourceSystem: adapter(yup.string().nullable()),
    createdBy: adapter(yup.number().nullable()),
    createdByName: adapter(yup.string().nullable()),
    createdFrom: adapter(yup.string().nullable()),
    isRegistrationComplete: adapter(yup.bool().nullable()),
    isFormerSeller: adapter(yup.bool().nullable()),
    draft: {
      validator: adapter(yup.bool()),
      defaultValue: false
    },
    reviewStep: {
      validator: adapter(yup.object()),
      defaultValue: {}
    },
    additionalInformation: {
      validator: adapter(yup.object()),
      type: AdditionalInformation,
      defaultValue: {}
    },
    businessModel: {
      validator: adapter(yup.object()),
      type: BusinessModel,
      defaultValue: {}
    },
    addresses: {
      validator: adapter(yup.array().of(yup.object())),
      builder: builderAddresses,
      defaultValue: []
    },
    attachments: {
      validator: adapter(yup.array().of(yup.object())),
      type: Attachment,
      defaultValue: []
    },
    contactReferences: {
      validator: adapter(yup.array().of(yup.object())),
      type: ContactReference,
      defaultValue: []
    },
    documents: {
      validator: adapter(yup.array().of(yup.object())),
      type: Document,
      defaultValue: []
    },
    emails: {
      validator: adapter(yup.array().of(yup.object())),
      type: Email,
      defaultValue: []
    },
    phones: {
      validator: adapter(yup.array().of(yup.object())),
      type: Phone,
      defaultValue: []
    },
    status: {
      validator: adapter(yup.object().nullable()),
      type: Status,
      defaultValue: {}
    },
    substatus: {
      validator: adapter(yup.object().nullable()),
      type: Substatus,
      defaultValue: {}
    },
    paymentPlan: {
      validator: adapter(yup.object().nullable()),
      type: PaymentPlan,
      defaultValue: {}
    },
    roles: {
      validator: adapter(yup.array().of(yup.object())),
      builder: builderRoles,
      defaultValue: []
    },
    policiesAccepted: {
      validator: adapter(yup.object()),
      builder: builderPoliciesAccepted,
      defaultValue: []
    },
    approvedCandidate: {
      validator: adapter(yup.object()),
      defaultValue: {}
    },
    workflows: {
      validator: adapter(yup.array().of(yup.object())),
      type: Workflow,
      defaultValue: []
    },
    suggestedSalesHierarchy: {
      validator: adapter(yup.object()),
      type: SuggestedSalesHierarchy,
      defaultValue: {}
    },
    review: {
      validator: adapter(yup.object().shape({
        approved: yup.boolean(),
        reason: yup.number()
      }))
    }
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    // TODO Get rid of this (normalize on api)
    this.normalizeNames()

    this.create = this.create.bind(this)
    this.createRole = this.createRole.bind(this)
    this.complete = this.complete.bind(this)
    this.get = this.get.bind(this)
    this.chainedGet = this.chainedGet.bind(this)
    this.find = this.find.bind(this)
    this.save = this.save.bind(this)
    this.chainedSave = this.save.bind(this)
    this.saveAttachments = this.saveAttachments.bind(this)
    this.savePaymentPlan = this.savePaymentPlan.bind(this)
    this.saveOnState = this.saveOnState.bind(this)
    this.reset = this.reset.bind(this)
    this.resetErrors = this.resetErrors.bind(this)
    this.cease = this.cease.bind(this)
    this.updatePersonLocal = this.updatePersonLocal.bind(this)
    this.saveContactInformation = this.saveContactInformation.bind(this)
    this.removePendency = this.removePendency.bind(this)
    this.allocate = this.allocate.bind(this)
    this.saveStatus = this.saveStatus.bind(this)
    this.setError = this.setError.bind(this)
    this.setRegistrationStatus = this.setRegistrationStatus.bind(this)
  }

  get hierarchyId() {
    return this.suggestedSalesHierarchy.id
  }

  get created() {
    return !isNaN(Date.parse(this.registrationDate))
  }

  get isSelfRegister() {
    return this.sourceSystem === SELF_REGISTER_SOURCE_SYSTEM
  }

  get cnCode() {
    return getCnCode(this)
  }

  get cnoCode() {
    return this.createdBy
  }

  get origin() {
    return this.isSelfRegister || this.cnoCode
  }

  get mobilePhone() {
    return this.phones.find(phone => phone.type === PHONE_TYPES.MOBILE)
  }

  get primaryEmail() {
    return this.emails.find(email => email.type === 1)
  }

  normalizeNames() {
    const { name, firstName, lastName } = normalizeNames(this)

    Object.assign(this, {
      name,
      firstName,
      lastName
    })
  }

  create(data, callback = () => { }, headers) {
    const person = new Person({ ...data })
    const params = data

    this.createPerson(person, headers, params)
      .then(this.updatePersonLocal)
      .then((result) => callback(person, result))

    return person
  }

  createRole(data, callback = () => { }) {
    const person = new Person({ ...this, ...data })

    this.createPersonRole(person)
      .then((result) => callback(person, result))

    return person
  }

  updatePersonLocal(data) {
    const { meta: { previousAction: { payload = {} } = {} } = {} } = data
    const { personLocal = {}, request = {}, client = {} } = payload
    const { personId } = this

    const isBFFRequest = client.toString().includes('BFF')

    if (data?.error?.response?.status === 409) {
      const responseData = data.error.response.data
      const personId = isBFFRequest ? responseData.error.personId : responseData.personId

      this.savePersonLocal({ personId, documents: request.data.documents })
    }

    if (personLocal.shouldUpdate) {
      this.savePersonLocal({ ...personLocal.data, personId })
    }

    return data
  }

  find(data, callback) {
    return this.findPerson(data).then(callback)
  }

  reset() {
    return this.resetPerson()
  }

  resetErrors() {
    return this.resetPersonErrors()
  }

  get(personId, callback = () => { }) {
    const { businessModel } = this

    this.getPerson({ personId, businessModel })
      .then(callback)

    return this
  }

  chainedGet(personId) {
    const { businessModel } = this

    return this.getPerson({ personId, businessModel })
  }

  complete(businessModel) {
    const { personId } = this

    return this.completePerson({ personId, businessModel: businessModel || this.businessModel })
  }

  save(data, callback = () => { }) {
    const person = new Person({ ...this, ...data })

    this.savePerson(person, data)
      .then(this.updatePersonLocal)
      .then((result) => callback(person, result))
      .then(() => this.saveOnState(data))

    return this
  }

  chainedSave(data) {
    const person = new Person({ ...this, ...data })

    return this.savePerson(person, data)
      .then(this.updatePersonLocal)
  }

  saveOnState(data) {
    const person = new Person({ ...this, ...data })

    this.savePersonOnState(person, data)

    return person
  }

  saveAttachments(data, callback = (item) => { return item }) {
    const person = new Person({ ...this, ...data })

    return this.savePersonAttachments(person, data)
      .then((result) => callback(person, result))
  }

  savePaymentPlan(data, callback = (item) => { return item }) {
    const person = new Person({ ...this, ...data })

    return this.savePersonPaymentPlan(person, data)
      .then((result) => callback(person, result))
  }

  saveContactInformation(data, callback = () => { }) {
    const person = new Person({ ...this, ...data })

    return this.savePersonContactInformation(person, data)
      .then(callback)
  }

  cease(role, callback = () => { }) {
    return this.ceaseRole(this.personId, role).then(callback)
  }

  removePendency(personRolesId, callback = () => { }) {
    return this.removePersonPendency(personRolesId).then(callback)
  }

  allocate(data) {
    const person = new Person({ ...this, ...data })

    return this.allocatePerson(person, data)
  }

  saveStatus(data, loadingBlock) {
    const person = new Person({ ...this, ...data })

    const loadingBlockFlag = loadingBlock ? loadingBlock.noLoading : false

    return this.savePersonStatus(person, data, loadingBlockFlag)
  }

  setError(data) {
    return this.setPersonError(data)
  }

  setRegistrationStatus(status) {
    return this.setPersonRegistrationStatus(status)
  }

  get hasMainRoleDraft () {
    return this.roles.hasRoleDraftFor(this.businessModel)
  }

  get isNewProspect () {
    return this.roles.items.length === 0
  }
}

function normalizeNames(props) {
  const { name = '', firstName = '', lastName = '' } = props
  if (!name && !firstName && !lastName) return {}

  if (name && (!firstName || !lastName)) {
    const splitedName = name.trim().split(' ')
    const nameStart = splitedName[0]

    return {
      name: name.trim(),
      firstName: nameStart.trim(),
      lastName: name.replace(nameStart, '').trim()
    }
  }

  return {
    name: name.trim(),
    lastName: lastName.trim(),
    firstName: firstName.trim()
  }
}

function getCnCode({ _links: links }) {
  if (!links || !links.length) return null

  const cnCode = links.find(findLinks)

  return cnCode && cnCode.href.split('/').pop()
}

function findLinks({ rel }) {
  return rel === 'url_seller'
}

function builderRoles(data) {
  const { items } = data

  return new Roles(items || data)
}

function builderPoliciesAccepted(data = {}, _type, deps = {}) {
  const { items } = data

  return new PoliciesAccepted(items || data, { policies: deps.policies })
}

function builderAddresses(data = [], _type, deps = {}) {
  return data.map(address => new Address(address, { config: deps.address }))
}
