import { BuildStepsMapper } from './BuildStepsMapper'
import {
  isArgentinaPersonalDataMfeToggleActive,
  isEnableCompletedStepOnMfeToggleActive,
  isEnableAttachmentsStepOnMfeToggleActive,
  isPreRegisterMfeToggleActive
} from 'src/config'
import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import Loader from '../../../../../../../views/Register/Components/Loader'
import { buildCompletedPropsWithInstructionsMessage } from '../../Completed/CompletedPropsWithInstructionMessage'
import { buildCompletedMicrofrontendProps } from '../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildDocumentsPropsMicrofrontend } from '../../Documents/DocumentsPropsMicrofrontend'
import { buildPreRegisterPropsMicrofrontend } from '../../PreRegister'

export const buildSteps = ({ basePath }) => {
  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 8 })

  const overrideFormDefaultWithMfeContainer = {
    components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
  }
  const personalDataOverrideProps = isArgentinaPersonalDataMfeToggleActive() ? overrideFormDefaultWithMfeContainer : {}
  const attachmentsOverrideProps = isEnableAttachmentsStepOnMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildDocumentsPropsMicrofrontend,
  } : {}
  const preRegisterOverrideProps = isPreRegisterMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildPreRegisterPropsMicrofrontend,
  } : {}

  const overrideCompletedWithStatusWithMfeContainer = {
    components:  [
      SectionTitle,
      MicroFrontendContainer,
    ],
    componentsPropsBuilder: buildCompletedMicrofrontendProps
  }

  const completedOverrideProps = isEnableCompletedStepOnMfeToggleActive() ? overrideCompletedWithStatusWithMfeContainer : { componentsPropsBuilder: buildCompletedPropsWithInstructionsMessage }

  return [
    buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
    buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
    buildStepsMapper.mapAuthorizationCode({ currentStep: 3 }),
    buildStepsMapper.mapAddress({ currentStep: 4, overrideProps: overrideFormDefaultWithMfeContainer }),
    buildStepsMapper.mapDocumentation({ currentStep: 5, overrideProps: overrideFormDefaultWithMfeContainer }),
    buildStepsMapper.mapDocumentAttachment({ currentStep: 6, overrideProps: attachmentsOverrideProps }),
    buildStepsMapper.mapIndication({ currentStep: 7 }),
    buildStepsMapper.mapCompleted({ currentStep: 8, overrideProps: { ...completedOverrideProps } }),
  ]
}
