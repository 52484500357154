import { connect } from 'react-redux'

export function mapStateToProps(state, ownProps) {
  const {
    digitalSpace: { digitalSpace },
    person: { person }
  } = state

  return {
    ...ownProps,
    digitalSpace,
    person
  }
}

export default connect(mapStateToProps)
