import TYPES from 'src/configs/Fields'
import Policies from 'src/configs/Locales/DEFAULT/views/SelfRegister/Policies'
import termsAndConditions from '../PER/termsAndConditions.per'

export const getPeruFieldsSchema = ({ intl, dependencies }) => {
  const POLICIES = commonParams(
    Policies(intl, dependencies, TYPES.POLICIES, termsAndConditions),
    false
  )

  return [[POLICIES]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
