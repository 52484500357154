import { store } from 'src/App.state'
import { ShowWarning } from 'src/reducers/Warning'
import { SUBSTATUS } from 'src/models/Substatus'

import Management from 'src/viewModels/Management'

export default function Builder(params) {
  const { intl, messages, person, depsLoading, cnoList, rejectionReasonList, user } = params
  const {
    suggestedSalesHierarchy: { id },
    additionalInformation: {
      leaderId,
      leaderName,
      introducerSellerId,
      introducerSellerName,
      createdByName
    },
    createdBy,
    status: { status },
    substatus: { id: substatus },
    suggestedSalesHierarchy: { id: suggestedSalesHierarchyId }
  } = person

  const data = {
    status,
    substatus,
    suggestedSalesHierarchyId,
    user,
    cnoList
  }

  const management = new Management(data)
  const cnoSelected = cnoList.find(findOnList, { leaderId })

  const modifiers = {
    approve: management.showApproveAndRejectButton,
    reject: management.showApproveAndRejectButton,
    revalidate: management.showRevalidateButton,
    pendency: management.showRemovePendencyButton,
    actions:
      management.showApproveAndRejectButton ||
      management.showRevalidateButton ||
      management.showRemovePendencyButton
  }

  return {
    id: 'profile-management',
    title: intl.formatMessage(messages.box_management_title),
    wrapper: 'management',
    content: 'list',
    loading: depsLoading,
    customProps: {
      person,
      cnoList,
      modifiers,
      cnoSelected,
      rejectionReasonList,
      shouldOpenProgressModal: true,
      onReject: onReject(params),
      onApprove: onApprove(params),
      onRevalidate: onRevalidate(params),
      onCNOSelect: buildCNOSelect(params),
      showCNOlist: management.showCNOlist,
      onRemovePendency: onRemovePendency(params),
      cnoLabel: intl.formatMessage(messages.box_management_label_list_leader),
      cnoApproveLabel: intl.formatMessage(messages.box_management_button_approve),
      cnoRejectLabel: intl.formatMessage(messages.box_management_button_reject),
      cnoRevalidateLabel: intl.formatMessage(messages.box_management_button_revalidate),
      cnoRemovePendencyLabel: intl.formatMessage(messages.box_management_button_remove_pendency),
      modalApproveTitle: intl.formatMessage(messages.box_management_cno_modal_approve_title),
      modalApproveMessage: intl.formatMessage(messages.box_management_cno_modal_approve_message),
      modalApproveButtonConfirm: intl.formatMessage(messages.box_management_cno_modal_approve_button_confirm),
      modalApproveButtonCancel: intl.formatMessage(messages.box_management_cno_modal_approve_button_cancel),
      modalRejectTitle: intl.formatMessage(messages.box_management_cno_modal_reprove_title),
      modalRejectMessage: intl.formatMessage(messages.box_management_cno_modal_reprove_message),
      modalRejectLabel: intl.formatMessage(messages.box_management_cno_modal_reprove_label),
      modalRejectPlaceholder: intl.formatMessage(messages.box_management_cno_modal_reprove_placeholder),
      modalRejectButtonConfirm: intl.formatMessage(messages.box_management_cno_modal_reprove_button_confirm),
      modalRejectButtonCancel: intl.formatMessage(messages.box_management_cno_modal_reprove_button_cancel),
      modalProgressTitle: intl.formatMessage(messages.box_management_cno_modal_progress_title)
    },
    fields: [
      {
        label: intl.formatMessage(messages.box_management_label_sector),
        field: id || '-'
      },
      {
        label: intl.formatMessage(messages.box_management_label_indication),
        field: introducerSellerId && introducerSellerName
          ? `${introducerSellerId} - ${introducerSellerName}`
          : '-'
      },
      {
        label: intl.formatMessage(messages.box_management_label_leader),
        field: leaderId && leaderName
          ? `${leaderId} - ${leaderName}`
          : '-'
      },
      {
        label: intl.formatMessage(messages.box_management_label_origen),
        field: createdBy && createdByName
          ? `${createdBy} - ${createdByName}`
          : '-'
      }
    ]
  }
}

function findOnList({ value }) {
  return value === this.leaderId
}

function buildCNOSelect({ person, intl, messages }) {
  return function ({ value: leaderId, label: leaderName }) {
    const message = intl.formatMessage(messages.box_management_cno_message_saved)
    const { dispatch } = store

    const additionalInformation = {
      ...person.additionalInformation,
      leaderId,
      leaderName
    }

    person.save({ additionalInformation })

    dispatch(ShowWarning(message))
  }
}

function onReject({ person: { save } }) {
  return function (rejectReasonId) {
    const params = {
      review: {
        approved: false,
        reason: rejectReasonId
      }
    }

    return save(params)
  }
}

function onApprove({ person: { save } }) {
  return function () {
    const params = {
      review: {
        approved: true
      }
    }

    return save(params)
  }
}

function onRevalidate({ person: { revalidateProfile, personId, getProfile } }) {
  return function () {
    return revalidateProfile(personId).then(function (data) { data.error || getProfile(personId) })
  }
}

function onRemovePendency({ person: { save, getProfile, personId } }) {
  return function () {
    const params = {
      substatus: {
        id: SUBSTATUS.APPROVED_SELLER
      }
    }

    return save(params, function (data) { data.error || getProfile(personId) })
  }
}
