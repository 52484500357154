import { getContext } from '../../infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

import Error from 'src/viewModels/Error'
import PersonVerify from 'src/viewModels/PersonVerify'

export const PERSON_VERIFY_FIND_LOADING = 'natura-caduni/Person/PERSON_VERIFY_FIND_LOADING'
export const PERSON_VERIFY_FIND_SUCCESS = 'natura-caduni/Person/PERSON_VERIFY_FIND_SUCCESS'
export const PERSON_VERIFY_FIND_ERROR = 'natura-caduni/Person/PERSON_VERIFY_FIND_ERROR'

export const types = [PERSON_VERIFY_FIND_LOADING, PERSON_VERIFY_FIND_SUCCESS, PERSON_VERIFY_FIND_ERROR]

export function personVerifyFindReducer(state, action, dependencies) {
  let { loading, error, person } = state
  const { payload, type } = action

  const hasSuccess = type === PERSON_VERIFY_FIND_SUCCESS
  const hasError = type === PERSON_VERIFY_FIND_ERROR
  const isLoading = type === PERSON_VERIFY_FIND_LOADING

  if (hasSuccess) {
    const personData = payload.data[0] || {}

    error = false
    loading = false
    person = new PersonVerify({ ...personData }, dependencies)
  }

  if (hasError) {
    loading = false
    error = new Error(action.error)
  }

  return {
    loading: isLoading || loading,
    error,
    person
  }
}

export function findPerson({ documents }) {
  const globalAppContext = getContext()

  const {
    connectivity,
    dispatch,
  } = globalAppContext

  if (connectivity.isOffline) {
    return new Promise(offlineStep({ documents }))
  }

  const {
    clientName,
    requestUrl,
    requestHeaders
  } = getRequestPayload({ globalAppContext, documents })

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'GET',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })
}

function getRequestPayload({ globalAppContext, documents }) {
  const {
    PEOPLE_URL,
    GET_PEOPLE_URL,
    isHostedUIEnabled,
    bffHeaders,
    headersAuthentication,
    countryId,
    companyId,
    sellerId,
  } = getNeededInformationFromGlobalContext({ globalAppContext })

  const clientName = isHostedUIEnabled ? CLIENT_NAMES.DEFAULT_BFF : countryId

  const { requestHeaders } = getRequestHeaders({ isHostedUIEnabled, headersAuthentication })

  const firstDocument = documents[0]

  const queryParams = `documentTypeId=${firstDocument.type}&document=${firstDocument.document}&relations=documents`
  const requestUrl = isHostedUIEnabled
    ? `${GET_PEOPLE_URL()}?${queryParams}`
    : `${PEOPLE_URL()}?${queryParams}`

  return { clientName, requestUrl, requestHeaders }

  function getNeededInformationFromGlobalContext({ globalAppContext }) {
    const {
      urls: { PEOPLE },
      default_bff_client: { headers: bffHeaders },
      default_bff_urls: { GET_PEOPLE },
      default_bff_apiKeys: { REGISTERS_APIKEY },
      locale: {
        id,
        configs,
        companyId,
      },
      user: {
        sellerId,
        authentication: {
          xApikey,
          accessToken,
        }
      }
    } = globalAppContext

    const isHostedUIEnabled = configs?.localization?.isHostedUIEnabled

    return {
      PEOPLE_URL: PEOPLE,
      GET_PEOPLE_URL: GET_PEOPLE,
      isHostedUIEnabled,
      bffHeaders,
      headersAuthentication: {
        globalPeoplePlatformApiKey: xApikey,
        registersBffApiKey: REGISTERS_APIKEY,
        userAccessToken: accessToken
      },
      countryId: id,
      companyId,
      sellerId,
    }
  }

  function getRequestHeaders({ isHostedUIEnabled, headersAuthentication }) {
    const {
      globalPeoplePlatformApiKey,
      registersBffApiKey,
      userAccessToken
    } = headersAuthentication

    const requestHeaders = isHostedUIEnabled ? {
      ...bffHeaders,
      'x-api-key': registersBffApiKey,
      Authorization: userAccessToken,
      country: countryId,
      companyid: companyId,
    } : {
      'x-api-key': globalPeoplePlatformApiKey,
      Authorization: userAccessToken,
      access_token: userAccessToken,
      userId: sellerId,
    }

    return { requestHeaders }
  }
}

function offlineStep({ documents }) {
  const { peopleRegister, dispatch } = getContext()
  const { items: [person] } = peopleRegister.draft({ documents })

  return function (resolve) {
    dispatch({
      type: PERSON_VERIFY_FIND_SUCCESS,
      payload: { data: [person] }
    })

    return resolve([person])
  }
}
