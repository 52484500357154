import { FUNCTIONS } from 'src/viewModels/BusinessModel'

import { defineMessages } from 'react-intl'
import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'

const messages = defineMessages({
  business_model_function: {
    defaultMessage: 'Business Model Function',
    id: 'form.field.business.model.function'
  },
  business_model_function_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.business.model.function.placeholder'
  },
  business_model_function_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.business.model.function.no.options.message'
  },
  business_model_function_option_beauty_consultant: {
    defaultMessage: 'Beauty Consultant',
    id: 'form.field.business.model.function.option.beauty.consultant'
  },
  business_model_function_option_business_leader: {
    defaultMessage: 'Business Leader',
    id: 'form.field.business.model.function.option.business.leader'
  }
})

const dependencies = {
  messages
}

const translateDependencies = {}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  Object.assign(translateDependencies, { intl })

  return {
    id: 'functionId',
    label: intl.formatMessage(messages['business_model_function']),
    placeholder: intl.formatMessage(messages['business_model_function_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['business_model_function_no_options_message']),
    type: connect(mapStateToProps)(Select),
    disabledBy: ['businessModel[].businessModelId', 'businessModel[].roleId',],
    required: true,
  }
}

function mapStateToProps(state, ownProps) {
  const { businessModel: { businessModel }, user: { user }, locale: { locale }, connectivity } = state
  const { form: { values: { businessModel: [formBusinessModel] } } } = ownProps

  const options = getOptions({
    formBusinessModel,
    businessModel,
    connectivity,
    locale,
    user
  })

  return {
    ...ownProps,
    options,
  }
}

function getOptions({ businessModel, formBusinessModel, connectivity, user, locale }) {
  const { configs: { localization } } = locale
  const { businessModelId, roleId } = formBusinessModel
  const { intl } = translateDependencies
  const { items } = businessModel

  const { roles = [] } = items.find(findBusinessModel, { businessModelId }) || {}
  const { functions = [] } = roles.find(findRoles, { roleId }) || {}

  const shouldShowBusinessModelFromApi =
    localization.getBusinessModelFromApi &&
    !connectivity.isOffline &&
    user.isCSC

  if (shouldShowBusinessModelFromApi) {
    const deps = {
      intl,
      messages,
      filterType: 'business_model_function_option'
    }

    return functions.map(translateFunctionOptions, deps)
  }

  return [{
    label: intl.formatMessage(messages.business_model_function_option_beauty_consultant),
    value: FUNCTIONS.BEAUTY_CONSULTANT
  }]
}

function findBusinessModel({ businessModelId }) {
  return businessModelId === this.businessModelId
}

function findRoles({ roleId }) {
  return roleId === this.roleId
}

export function translateFunctionOptions(option) {
  const { label, value } = option

  const entries = Object.entries(FUNCTIONS)
  const [key] = entries.find(findByName, { value }) || []

  if (!key) return { ...option, label, value }

  const message = this.messages[`${this.filterType}_${key.toLowerCase()}`]

  return { ...option, label: this.intl.formatMessage(message), value }
}

function findByName([, value]) {
  return value === this.value
}
