import React from 'react'
import { defineMessages } from 'react-intl'

import Link from 'src/base/Form/Components/Link'

const messages = defineMessages({
  zipcode_search: {
    defaultMessage: 'I don`t know my Zipcode',
    id: 'form.field.zipcode.search'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const title = intl.formatMessage(messages['zipcode_search'])

  const node = (
    <Link
      href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
      className="pre-register__zipcode-search"
      title={title}>
      {title}
    </Link>
  )

  return {
    node
  }
}
