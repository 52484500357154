import React from 'react'
import PropTypes from 'prop-types'
import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    node: <Title intl={intl} messages={messages} />
  }
}

function Title(props) {
  return (
    <h6 className="form__group__title">
      {getTitle(props)}
    </h6>
  )
}

function getTitle({ intl, messages }) {
  return intl.formatMessage(messages.address_residential)
}

Title.propTypes = {
  title: PropTypes.string
}
