import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'

import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'neighborhood',
    label: intl.formatMessage(messages['neighborhood']),
    placeholder: intl.formatMessage(messages['neighborhood_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['neighborhood_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    disabledBy: ['address[].department', 'address[].city'],
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['neighborhood']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    pushDataLayer(values, intl)
  }
}

export function getNeighborhoods({ selectedCityId, getLevels }) {
  if (!selectedCityId) return

  getLevels({
    queryParams: `level=3&parentCode=${selectedCityId}`,
    geoStructureType: 'neighborhoods',
    geoStructureCode: selectedCityId
  })
}

const called = {}

function mapStateToProps({ addresses }, ownProps) {
  const {
    addressesPeopleManagement: { neighborhoods, getLevels },
    loading,
  } = addresses

  const {
    group: { index },
    form: {
      values: { address }
    },
    onChange
  } = ownProps
  const { city } = address[index] || {}
  const options = neighborhoods[city] || []

  const shouldGetNeighborhoods = !options.length && city && !loading.cities[city]

  if (shouldGetNeighborhoods && !called[city]) {
    called[city] = true

    getNeighborhoods({ selectedCityId: city, getLevels})
  }

  return {
    ...ownProps,
    onChange,
    options: options || [],
    loading: city && loading.neighborhoods && loading.neighborhoods[city]
  }
}
