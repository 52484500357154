import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'

import UserMedia from './UserMedia'

function mapStateToProps(state, ownProps) {
  const {
    person: { person },
    connectivity
  } = state

  return {
    ...ownProps,
    connectivity,
    person,
  }
}

export default compose(
  injectIntl,
  connect(mapStateToProps)
)(UserMedia)
