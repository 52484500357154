import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'region',
    label: intl.formatMessage(messages['region']),
    placeholder: intl.formatMessage(messages['region_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['region_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChangeRegion(intl),
    required: true,
    showAsterisk: false
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['region']),
      value: label
    }
  })
}

const onChangeRegion = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

export function validateFieldDependencies({
  setFieldValue,
  setFieldTouched,
  schema
}) {
  const {
    group: { id },
    parent
  } = schema

  setFieldValue(`${parent}.commune`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.commune`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getCommuneByRegion({ value, getLevels }) {
  if (!value) return

  return getLevels({
    geoStructureType: 'communes',
    queryParams: `level=2&parentLevel=1&parentCode=${value}`,
    geoStructureCode: value
  })
}

export function mapStateToProps({ addresses, connectivity }, ownProps) {
  const {
    addressesPeopleManagement: { regions, getLevels },
    loading,
    error
  } = addresses

  const {
    group: { index },
    onChange,
    form: {
      values: { address }
    }
  } = ownProps

  const { countryName } = address[index] || {}

  const options = regions[countryName]
  const shouldGetRegionAndDepartments =
    !options && !loading.regions && !error.addresses && countryName

  if (shouldGetRegionAndDepartments && !connectivity.isOffline) {
    getLevels({ geoStructureType: 'regions', queryParams: 'level=1' })
  }

  return {
    ...ownProps,
    options: options || [],
    loading: loading.regions,
    onChange: (value, label) => {
      getCommuneByRegion({ value, getLevels })
      onChange(value, label)
    }
  }
}
