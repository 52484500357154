import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_GET_LOADING = 'natura-caduni/People/PROFILE_GET_LOADING'
export const PROFILE_GET_SUCCESS = 'natura-caduni/People/PROFILE_GET_SUCCESS'
export const PROFILE_GET_ERROR = 'natura-caduni/People/PROFILE_GET_ERROR'

const TYPES_GET = [PROFILE_GET_LOADING, PROFILE_GET_SUCCESS, PROFILE_GET_ERROR]

const relationsFields = [
  'additionalInformation',
  'addresses',
  'approvedCandidate',
  'attachments',
  'contactReferences',
  'documents',
  'emails',
  'paymentPlan',
  'personPoliciesAccepts',
  'roles',
  'telephones'
]

export function getPerson(personId) {
  const globalAppContext = getContext()
  const {
    dispatch,
  } = globalAppContext

  const { requestClientName, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext, personId })

  const relations = `?relations=${relationsFields.join(',')}`
  const url = requestUrl + relations

  return dispatch({
    types: TYPES_GET,
    payload: {
      client: requestClientName,
      request: {
        method: 'GET',
        url,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload ({ globalAppContext, personId }){
    const {
      PEOPLE_URL,
      GET_PERSON_URL,
      countryId,
      companyId,
      bffHeaders,
      headersAuthenticationInfo,
      shouldGetPersonDetailsFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext, personId })

    const headersData = { companyId, countryId, bffHeaders, sellerId }

    const requestClientName = shouldGetPersonDetailsFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId

    const { requestHeaders } = getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData })

    const requestUrl = shouldGetPersonDetailsFromBff ? GET_PERSON_URL : PEOPLE_URL

    return { requestClientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({globalAppContext, personId}){
      const {
        urls: { PEOPLE },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { GET_PERSON },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetPersonDetailsFromBff = configs?.localization?.shouldGetPersonDetailsFromBff

      return {
        PEOPLE_URL: PEOPLE(personId),
        GET_PERSON_URL: GET_PERSON({ personId }),
        shouldGetPersonDetailsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const { countryId, companyId, bffHeaders, sellerId } = headersData

      const requestHeaders = shouldGetPersonDetailsFromBff ? {
        ...bffHeaders,
        Authorization: userAccessToken,
        'x-api-key': registersBffApiKey,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}
