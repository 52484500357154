const termsAndConditions = [
  'CONSULTOR/A NATURA',
  'TÉRMINOS Y CONDICIONES DEL REGISTRO DIGITAL',
  'Y',
  'CONTRATO DE CONSULTOR/A COMISIONISTA INDEPENDIENTE',
  'En Santiago de Chile, Natura Cosméticos S.A., RUT 96.575.280-3, domiciliada para estos efectos ' +
    'en Avenida Apoquindo N°5950, pisos 6 y 7, Las Condes, Santiago (“Natura” o “Empresa”), viene ' +
    'en establecer las siguientes Términos y Condiciones (“TyC”) para el proceso de Cadastro Digital ' +
    '(“Cadastro” o “Registro”) de la Consultora Comisionista Independiente. A continuación, se ' +
    'contiene el contrato que se conviene entre Natura, representada por don Gustavo Cruz de Moraes, ' +
    'RUT 26.742.449-7, Gerente General del mismo domicilio y la Consultora Comisionista ' +
    'Independiente (“Contrato”). ' +
    'TÉRMINOS Y CONDICIONES DEL REGISTRO DIGITAL',
  'I. Antecedentes. 1. Natura tiene como giro la importación y comercialización de productos ' +
    'cosméticos, de belleza e higiene, de la marca Natura (“Productos”). 2. La comercialización de los ' +
    'Productos se realiza mediante un sistema de venta personalizada por medio de las/los ' +
    'Consultoras/es de Natura (“CN”). 3. El acceso y uso del sitio web de Natura se rige por estos ' +
    'TyC, así como por la legislación chilena vigente, en particular, y en lo que resultare aplicable, por ' +
    'la Ley N°19.628 sobre Protección de la Vida Privada (“Ley N°19.628”).',
  'II. Ámbito de aplicación y vigencia de los TyC. 1. Estos TyC tienen por objeto regular el ' +
    'proceso de Registro a través del cual las personas que cumplan con los requisitos aquí establecidos ' +
    'pasarán a ser CN. 2. Las solicitudes de Registro, y los actos y contratos que se ejecuten o celebren ' +
    'por intermedio del sitio web de Natura se regirán por los TyC vigentes al momento de su ' +
    'presentación, ejecución o celebración.',
  'III. Definiciones. 1. Para los efectos de estos TyC y del Contrato, se entenderá por:',
  'a) CN: Personas naturales que comercializan los Productos. La CN actúa como Comisionista ' +
    'Independiente, es decir, vende los Productos por su cuenta y riesgo, sin que exista un vínculo ' +
    'de subordinación o dependencia o relación de consumo con Natura. En consecuencia, las ' +
    'ventas realizadas por la CN son efectuadas por cuenta y riesgo exclusivo de esta última, de ' +
    'forma independiente, y de conformidad con los presentes TyC y al Contrato. La CN no es ' +
    'representante ni agente de la Empresa. Por lo tanto, se le prohíbe expresamente asumir ' +
    'compromisos a nombre de esta, como también utilizar o reproducir bajo cualquier forma o ' +
    'pretexto las marcas o expresiones de propaganda de Natura, ya sea en anuncios, publicaciones ' +
    'o por cualquier otro medio, prohibiéndose además la exhibición de productos en vitrina o ' +
    'lugares públicos, reconociendo con esto el derecho exclusivo de propiedad a Natura.',
  'b) Cadastro Digital: Registro del CN en la Plataforma Natura para la comercializan de productos ' +
    'Natura, en la forma establecida en los presentes TyC.',
  'c) Líder de Negocio: CN encargada de, entre otros, proponer nuevas CN; realizar con las CN ' +
    'que forman parte de su grupo actividades adicionales, tales como reuniones, talleres, showrooms;  ' +
    'y orientar a dichas CN para el desarrollo de su negocio autónomo.',
  'd) Plataforma Natura: Sección en el sitio web de Natura www.natura.cl que permite a las ' +
    'personas naturales interesadas en comercializar los productos Natura, registrarse y solicitar ' +
    'ser CN.',
  'e) Nota de Venta computacional: Se refiere a un comprobante con la descripción del pedido ' +
    'realizado que llegara por correo electrónico a la casilla de correos que haya registrado la CN. ' +
    'f) Boleta de Venta: corresponde a un documento donde se indica los productos o servicios ' +
    'facturados en el pedido indicado.',
  'g) Comisión por Venta: establecido por la diferencia entre el precio de venta del producto y la ' +
    'ganancia de la consultora dependiendo de su perfil de camino de crecimiento.',
  'h) Puntos Natura: cada producto tendrá un puntaje definido asociado a su valor de compra, el ' +
    'mismo puede cambiar ciclo a ciclo.',
  'i) Datos personales: Cualquier información respecto de una persona natural, identificada o ' +
    'identificable. Se entienden comprendidos dentro de ellos el nombre, la edad, el sexo, el estado ' +
    'civil, la profesión, el domicilio, número de cédula de identidad (CI), la huella digital, los ' +
    'números telefónicos, los correos electrónicos, y las tarjetas bancarias (identificadas a través ' +
    'de sus números y banco emisor), entre otros.',
  'j) Cookies: Identificador alfanumérico que, por medio de su navegador Web, nos permite ' +
    'recolectar ciertos datos, tales como páginas visitadas, tiempo de visita, etc. Las “cookies” son ' +
    'usadas por Natura para obtener información cuando un navegador Web tiene acceso a este ' +
    'Sitio.',
  'k) Deuda Impaga o Morosa: Toda obligación que no ha sido pagada en la fecha y condiciones ' +
    'pactadas.',
  'l) Sitio: Es el sitio web de Natura bajo el cual opera la Plataforma Natura, bajo la extensión o ' +
    'dominio de Internet minegocio.natura.cl, u otro que en el futuro determine Natura.',
  'm) Productos: Productos cosméticos, de belleza e higiene, de la marca Natura.',
  '2. Los términos aquí definidos tendrán los significados que se les adscriben, independientemente ' +
    'de su uso en singular o en plural, en género masculino o femenino, o de cualquier forma o ' +
    'composición gramatical o sintáctica que sea necesaria para la adecuada redacción y comprensión ' +
    'del contrato.',
  'III. Objetivo del Sitio. Permitir el Cadastro o Registro de quienes deseen ser CN. Una vez ' +
    'revisada y aceptada la solicitud por parte de Natura, la CN podrá comenzar a vender los Productos, ' +
    'de acuerdo con las condiciones establecidas en estos TyC y en el Contrato.',
  'IV. Solicitud de Registro.',
  'A/ Requisitos para ser CN. 1. El solicitante deberá completar el formulario disponible en la ' +
    'señalada página web de la compañía, en el que deberá entregar el número de su cédula de ' +
    'identidad, su nombre completo, teléfono y correo electrónico de contacto, dirección residencial, ' +
    'fecha de nacimiento, género y nacionalidad. 2. El solicitante deberá cumplir con los siguientes ' +
    'requisitos: (a) ser una persona natural, mayor de 18 años y tener plena capacidad para suscribir ' +
    'cualquier obligación, especialmente de carácter civil; (b) poseer Cédula de Identidad vigente; (c) ' +
    'no poseer deudas morosas con Natura Cosméticos; (d) no poseer deudas morosas con empresas ' +
    'de Venta Directa; y (e) no poseer deudas morosas en comercio registradas en burós de crédito. 3. ' +
    'Natura informa que podrá fijar nuevos requisitos, los que, en cualquier caso, serán informados ' +
    'debida y oportunamente, mediante la actualización correspondiente de estos TyC. 4. ' +
    'Excepcionalmente, los solicitantes que posean deudas morosas por un total global de hasta ' +
    '$25.000, o aquel otro monto que se determine, estarán sujetos a un procedimiento de evaluación ' +
    'crediticia especial y de revisión de antecedentes adicionales para efectos de validar su registro. 5. ' +
    'Respecto de aquellos solicitantes que no sean admitidos una vez realizada la evaluación crediticia ' +
    'descrita en el numeral anterior, la Empresa podrá habilitar su incorporación como CN a través del ' +
    'modelo de Prepago, descrito en la cláusula V.3 del Contrato. 6. Natura no admitirá el Registro ' +
    'de personas jurídicas y de quienes hayan hecho solicitudes presentando cédulas de identidad que ' +
    'pertenezcan a menores de edad. En caso de identificar el número de cédula de identidad ' +
    'corresponde a una persona fallecida, menor de edad, o persona jurídica, se procederá al bloqueo ' +
    'automático del Registro. 7. No se permitirá la duplicidad de cuentas asociadas a una misma ' +
    'persona, esto es duplicidad y/o multiplicidad de cuentas y/o registros asociados a una misma ' +
    'persona singularizada con igual nombre, domicilio u otra información relevante. 8. Natura se ' +
    'reserva el derecho de rechazar y/o cancelar el Registro de quienes posean una deuda morosa con ' +
    'Natura. Asimismo, no se permitirá el Registro de quienes posean deudas morosas registradas en ' +
    'burós de crédito. 9. Antes de enviar la solicitud de Registro, el solicitante deberá leer, entender y ' +
    'aceptar expresamente estos TyC y el Contrato, los que se encontrarán disponibles en un formato ' +
    'descargable e imprimible para su lectura previo a su aceptación.',
  'B/ Antecedentes requeridos. 1. Para los efectos del Registro, la solicitante deberá enviar, copia ' +
    'de cédula de identidad, el cual se adjunta como anexo al presente contrato. Natura podrá solicitar ' +
    'antecedentes adicionales al indicado anteriormente. 2. La solicitante es responsable de la ' +
    'veracidad de todos estos documentos; declara y garantiza que es la titular de estos, y que estos ' +
    'son ideológicamente auténticos y fieles a sus originales. La solicitante o CN, en su caso, será ' +
    'responsable de cualquier perjuicio ocasionado a Natura o a terceros que se pueden derivar directa ' +
    'o indirectamente de cualquier falsedad o engaño relacionada con los documentos antes ' +
    'mencionados. Además, se deja expresa constancia que cualquier falsedad, adulteración, omisión ' +
    'o inexactitud en la información suministrada y/o documentos referidos, deliberada o no, ' +
    'invalidará el registro en el Sitio, y será causal de término anticipado del Contrato; sin perjuicio ' +
    'de las acciones que le pudieran corresponder.',
  'V. Obtención de la calidad de CN. 1. Natura revisará y evaluará el cumplimiento de los ' +
    'requisitos referidos en el punto IV.A. y la validez, autenticidad, veracidad, integridad y vigencia ' +
    'de los antecedentes referidos en el punto IV.B., en el menor tiempo posible desde su recepción. ' +
    '2. En caso de que, de acuerdo con la revisión y evaluación realizada se cumplan todas las ' +
    'condiciones exigidas, Natura aceptará la solicitud, registrará a la CN y le entregará un número ' +
    'de registro y una clave de acceso que le permitirá el acceso al sitio personalizado, confidencial ' +
    'y seguro denominado minegocio.natura.cl.',
  'VI. Autorización de Tratamiento de Datos Personales. 1. Al solicitar el Registro, aceptar estos ' +
    'TyC, y suscribir el Contrato, la CN autoriza expresamente a Natura, para la recolección, uso, ' +
    'almacenamiento, y en general para que realice cualquier tipo de tratamiento de sus datos de ' +
    'carácter personal (“Información Personal”), con los propósitos indicados en esta cláusula, ' +
    'incluida su transferencia a terceros en los casos aquí previstos. 2. La CN declara que la ' +
    'Información Personal entregada a Natura es verídica, vigente, completa y válida. Cualquier ' +
    'eliminación, actualización, corrección, o en general, cualquier modificación, deberá ser requerida ' +
    'por la CN a Natura. 3. Natura realizará el tratamiento de la Información Personal de la CN con ' +
    'todos o alguno de los siguientes propósitos: (i) comunicarse con la CN por cualquier medio; (ii) ' +
    'coordinar actividades de capacitación, tales como talleres, reuniones informativas, charlas, etc.; ' +
    '(iii) efectuar estadísticas de ventas y otras relacionadas con las actividades de la CN; (iv) ' +
    'coordinar actividades de promoción, incentivo de ventas, marketing o difusión de los productos ' +
    'Natura; (v) otorgar reconocimientos o premios por las ventas gestionadas por la CN; y (vi) hacer ' +
    'pago de sus servicios de comisionista bajo este Contrato. 4. Natura solo revelará, transferirá o ' +
    'permitirá el acceso de terceros a la Información Personal de la CN en los siguientes casos: (i) ' +
    'cuando la CN consienta expresamente en ello; o (ii) cuando Natura externalice servicios para el ' +
    'desarrollo de su giro, en cuyo caso los terceros se obligarán a guardar el mismo nivel de ' +
    'confidencialidad que ejerce Natura en el resguardo de la Información Personal de la CN; (iii) ' +
    'cuando sea asignada a una Líder de Negocio para la coordinación de sus actividades conjuntas ' +
    '(iv) si la ley o la regulación vigente en la materia así lo exige. 5. La política de privacidad de ' +
    'Natura se encuentra disponible en el siguiente enlace: https://www.natura.cl/politicas-deprivacidad.  ' +
    '6. En cualquier caso, Natura garantiza a los usuarios el libre ejercicio de sus derechos ' +
    'de información, modificación, cancelación y bloqueo de sus datos personales establecidos en la ' +
    'Ley 19.628. Por consiguiente, las CN podrán realizar requerimientos que digan relación con ' +
    'dichos derechos al correo contacto@natura.net, y en un plazo máximo de dos días corridos, Natura ' +
    'deberá dar respuesta e implementar efectivamente esta solicitud.',
  'VII. Otras disposiciones. 1. Uso de cookies y dispositivos similares. 1.1. Las cookies son ' +
    'pequeños archivos que se almacenan en su navegador y que permiten recolectar información sobre ' +
    'la interacción del Usuario y el Sitio, y que, en su mérito, permiten realizar publicidad segmentada, ' +
    'mostrando avisos y ofertas que sean de interés del Usuario. Por ejemplo, si el Usuario tiende a ' +
    'buscar más artículos electrónicos, el Sitio mostrará más publicidad de esa categoría de productos. ' +
    '1.2. La CN autoriza a Natura para el uso de cookies, sin perjuicio de que el usuario puede escoger ' +
    'navegar por el sitio sin aceptar cookies. Para ello puede desactivarlas en cualquier momento a ' +
    'través de las opciones de privacidad/seguridad de su navegador. 1.3. La información recogida por ' +
    'las cookies sólo será tratada por Natura o sus empresas relacionadas, en los términos que define ' +
    'el artículo 100 de la Ley N° 18.045, para los fines previamente indicados. 2. Propiedad ' +
    'intelectual. Todos los contenidos incluidos en este sitio, como textos, material gráfico, logotipos, ' +
    'código de fuente, diseño o cualquier otro elemento de contenido, son de propiedad exclusiva de ' +
    'la Empresa y están protegidos por las leyes chilenas e internacionales sobre propiedad intelectual. ' +
    'Ninguno de estos contenidos puede ser modificado, copiado, extraído, vendido, publicado o ' +
    'utilizado de cualquier otra forma sin el consentimiento previo, expreso y por escrito, de la ' +
    'empresa. La CN acepta que el mal uso de esta información puede resultar en la aplicación de ' +
    'sanciones civiles y penales. La Empresa es dueña de los derechos de propiedad industrial relativos ' +
    'a sus productos y servicios, así como los relativos a sus marcas registradas y/o sus nombres de ' +
    'dominio, y el usuario declara abstenerse de solicitar cualquier privilegio o derecho respecto de ' +
    'estos. 3. Continuidad e interrupciones. Natura no garantiza el acceso y uso continuado o ' +
    'ininterrumpido del Sitio. Su acceso podrá estar temporalmente no disponible, en caso de ' +
    'interrupciones necesarias para la mantención de cualquier índole, o fallas en la operación de los ' +
    'servidores, de las empresas proveedoras de energía eléctrica, empresas prestadoras de servicios ' +
    'de telecomunicaciones, casos fortuitos, fuerza mayor, o acciones de terceros en los que la empresa ' +
    'no tenga control, en cuyo caso no será responsable del eventual lucro cesante de la CN producto ' +
    'de esta situación. 4. Solución de conflictos. Cualquier dificultad o controversia que se suscite en ' +
    'relación con estos TyC, y con los actos y contratos contenidos en los mismo, será sometida al ' +
    'conocimiento de los tribunales ordinarios de justicia de la ciudad y comuna de Santiago, excepto ' +
    'en aquellos casos y materias en que la ley establezca normas imperativas de competencia, ' +
    'prohibiéndose expresamente la prórroga de competencia. 5. Canales de comunicación con ' +
    'Natura. En caso de dudas, problemas en el uso del Sitio o cualquier otra consideración, la CN ' +
    'puede contactarse con Natura, a través del CAN 600 600 9999 o asistente virtual Natura Atende ' +
    'Hispânica +55 11 93038-0000.',
  '***',
  'CONTRATO DE CONSULTOR/A COMISIONISTA INDEPENDIENTE',
  'I. Comisionista independiente. De acuerdo con lo indicado en los TyC, la CN actúa como ' +
    'Comisionista Independiente, es decir, vende los Productos por su cuenta y riesgo, sin que exista ' +
    'un vínculo de subordinación o dependencia o relación de consumo con Natura. En consecuencia, ' +
    'las ventas realizadas por la CN son efectuadas por cuenta y riesgo exclusivo de esta última, de ' +
    'forma independiente, y de conformidad con los TyC y el presente Contrato. La CN no es ' +
    'representante ni agente de la Empresa. Por lo tanto, se le prohíbe expresamente asumir ' +
    'compromisos a nombre de esta, como también utilizar o reproducir bajo cualquier forma o ' +
    'pretexto las marcas o expresiones de propaganda Natura, ya sea en anuncios, publicaciones o por ' +
    'cualquier otro medio, prohibiéndose además la exhibición de productos en vitrina o lugares ' +
    'públicos, reconociendo con esto el derecho exclusivo de propiedad a Natura. Será de exclusiva ' +
    'responsabilidad de la CN el tiempo y oportunidad que le dedique a la gestión de ventas, así como ' +
    'la selección de los compradores.',
  'II. Obligaciones de Natura',
  'II.1. Autorización. Natura autoriza a la CN para que venda los Productos que se establezcan en ' +
    'el listado correspondiente. El precio de venta de cada Producto será el que se señale en los listados ' +
    'referidos, incluyendo las campañas promocionales.',
  'II.2. Documentos de compra. Natura entregará a la CN los siguientes documentos: 1. Nota de ' +
    'Venta computacional en la que se detallarán (i) los Productos solicitados; (ii) el monto a pagar; ' +
    '(iii) la fecha de vencimiento; y (iv) la comisión; 2. Boletas de venta de cada Producto solicitado; ' +
    'y 3. Comprobante de pago para ser utilizado en las instituciones que en él se señalen. La no ' +
    'recepción de este documento no liberará a la CN de la obligación de pagar.',
  'II.3. Comisión. Natura pagará a la CN una comisión por las ventas, proporcional al valor neto de ' +
    'las ventas (sin I.V.A.), especificados en la/s Boleta/s. El porcentaje se especificará en la Nota de ' +
    'Ventas. Además, Natura emitirá una Boleta de Prestación de Servicios correspondiente a las ' +
    'comisiones obtenidas dentro de un mes, de acuerdo con la Resolución Exenta N°551 del Servicio ' +
    'de Impuestos Internos, reteniendo el Impuesto de la Renta señalado.',
  'II.4. Calidad de los Productos. 1. Natura garantiza la calidad de los Productos, por lo que el ' +
    'comprador/cliente final puede ejercer todos los derechos correspondientes contemplados en la ' +
    'normativa de consumo. Para estos efectos, la CN debe advertir al cliente que debe conservar la ' +
    'Boleta. 2. Además, Natura cuenta con una unidad de cosmetovigilancia y una unidad médica ' +
    'especializada. Todos los Productos están siendo permanente evaluados y controlados por esta ' +
    'unidad. En el evento que se acredite debidamente, mediante el certificado médico ' +
    'correspondiente, que alguno de los Productos produjo al cliente alguna reacción adversa, Natura ' +
    'se compromete a analizar en profundidad su situación a través de la unidad médica especializada, ' +
    'indagar las causas de tal reacción, y proponerle el tratamiento o solución más adecuados a su ' +
    'problema. Para más información, contactarse con Natura, a través del CAN 600 600 9999 o ' +
    'asistente virtual Natura Atende Hispánica +55 11 93038-0000.',
  'II. Obligaciones de la CN una vez registrada. 1. Actualizar sus datos. La CN se obliga a ' +
    'mantener actualizados sus datos de contacto, y a informar de cualquier cambio dentro de los 10 ' +
    'días siguientes a cualquier modificación. El incumplimiento de esta obligación dará a Natura la ' +
    'facultad de poner término anticipado al Contrato. El domicilio ingresado en el formulario será ' +
    'considerado un medio válido de notificación y la CN será responsable de mantenerlo actualizado. ' +
    '2. Mantener la confidencialidad de su clave de acceso. La clave de acceso es de uso personal ' +
    'e intransferible. La CN asume la responsabilidad de su protección y reserva. Asimismo, la CN ' +
    'será la única responsable de las operaciones realizadas a través del Sitio con el ingreso de su clave ' +
    'de acceso. Se establece expresamente que quien realiza cualquier operación, transacción o solicita ' +
    'información en el Sitio utilizando su clave de acceso será considerado, para todos los efectos ' +
    'legales, como la CN de que se trate. En caso de extravío, hurto, robo, mal uso de la clave de ' +
    'acceso o cualquier otro evento de esta naturaleza, la CN deberá informar inmediatamente a la ' +
    'Empresa, a través de los distintos medios de comunicación dispuestos por Natura, o proceder a ' +
    'su bloqueo a través del mismo Sitio.',
  'III. Obligaciones de la CN en la ejecución del Contrato. 1. Vender los productos ' +
    'Natura (hacer el pedido, recibir los Productos y entregar los Productos y la Boleta al ' +
    'comprador. 2. Pagar a Natura el precio de los Productos solicitados, haciéndose ' +
    'responsable ante Natura de la insolvencia de sus compradores. 3. Pagar las multas, ' +
    'intereses y gastos de cobranza que se contemplan en el punto VI de este Contrato.',
  'IV. Responsabilidades de la CN. 1. Del hecho de la venta; entendiendo por tal el acto serio que ' +
    'se concreta al realizar la Nota de Pedido y recibir los Productos según lo solicitado. 2. Entregar ' +
    'los Productos y la/s Boleta/s correspondientes a los compradores, independiente del hecho de su ' +
    'pago. 3. Del pago de los Productos por parte de los compradores. Será de su absoluta ' +
    'responsabilidad el pago por parte de sus clientes y plazo que conceda a éstos. La CN es ' +
    'solidariamente responsable del pago.',
  'V. Acerca de los pedidos. 1. Forma de realizar los pedidos. Una vez concertada la venta, la CN ' +
    'formulará un Pedido en los documentos que se proporcionen, con su Gerente de Negocios, a través ' +
    'del CAN 600 600 9999, o del sitio web minegocio.natura.cl. 2. Plazo para el pago de pedidos. ' +
    'Natura podrá otorgar un plazo CN para que entregue los Productos a los clientes, recaude el pago ' +
    'y entere el monto correspondiente a Natura. Natura otorgará a la CN un crédito para el pago de ' +
    'los Productos, el cual estará garantizado por un Mandato aceptado por la CN, asumiendo la ' +
    'obligación de pagarlo dentro de los plazos que Natura le otorgue. 3. Modelo de Prepago. Sin ' +
    'perjuicio de lo anterior, aquellas CN que no sean admitidos una vez realizada la evaluación ' +
    'crediticia descrita en el punto IV.A de los TyC, podrán ser registradas y habilitadas a través del ' +
    'modelo de Prepago. Según este modelo, las CN solo podrán realizar pedidos mediante el pago ' +
    'anticipado de los mismos, y luego de realizados un total de 5 (cinco) pedidos, se evaluará su ' +
    'incorporación al modelo tradicional.4. Retraso en los pagos. El no pago en las fechas de ' +
    'vencimiento de las obligaciones señaladas, hará exigible la totalidad de los créditos que estuvieran ' +
    'pendientes de pago incluyendo los máximos intereses permitidos por la ley. Una vez impaga la ' +
    'deuda la CN autoriza a Natura a entregar la gestión de cobranza a una(s) empresa(s) externa(s); ' +
    'además de informar los datos a los Buró de Crédito. Todos los gastos que se devenguen de tal ' +
    'gestión serán asumidos por la CN.',
  'VI. Multas, Intereses y Gastos de Cobranza. 1. Multas. Después de 7 días de vencimiento de ' +
    'la deuda, Natura podrá aplicar una multa equivalente al 10% de una Unidad de Fomento (UF) ' +
    'vigente a la fecha de la liquidación de la deuda, o la unidad que la reemplace en caso de que esta ' +
    'sea suprimida. 2. Intereses por Mora. La tasa de interés moratoria que se aplicará a deudas ' +
    'vencidas será la máxima convencional (TIMC) de acuerdo con la publicación que realice la ' +
    'Comisión para el Mercado Financiero en el Diario Oficial, y conforme a la normativa legal ' +
    'referente a aquellas operaciones en moneda nacional no reajustables de menos de 90 días, por ' +
    'montos Inferiores o iguales al equivalente de 5.000 unidades de fomento y operaciones no ' +
    'reajustables en moneda nacional 90 días o más, por montos inferiores o iguales al equivalente de ' +
    '50 unidades de fomento. 3. Gastos de Cobranza Extrajudicial (GCE). Transcurridos 21 ' +
    '(veintiún) días de atraso Natura podrá aplicar cobros por concepto de cobranza extrajudicial de ' +
    'acuerdo con el monto en mora conforme al siguiente detalle: hasta 9% sobre el monto en mora ' +
    'hasta UF 10; hasta 6% sobre el monto en mora que exceda de UF 10 y no exceda de UF 50; y ' +
    'hasta 3% sobre el monto en mora que exceda de UF 50. El cobro siempre se hará en base a los ' +
    'gastos de cobranza en que Natura realmente haya incurrido. Para estos efectos, Natura realizará ' +
    'siempre a lo menos una gestión útil, sin cargo para la CN, con objeto de poner en su conocimiento ' +
    'la mora o retraso en el cumplimiento de sus obligaciones, dentro de los primeros quince días ' +
    'siguientes a cada vencimiento impago. 4. Los intereses que devenguen las obligaciones ' +
    'pecuniarias de la CN a favor de Natura por aplicación de esta cláusula, cualquiera que fuere su ' +
    'causa, en ningún caso superarán el interés máximo convencional que la ley permite estipular, de ' +
    'conformidad a lo indicado en el numeral 2 anterior.',
  'VII. Vigencia, modificación y término anticipado del Contrato. 1. El presente Contrato se ' +
    'encontrará vigente desde el momento en que Natura acepte la solicitud de registro enviada por la ' +
    'CN. 2. Las Partes acuerdan que el presente Contrato podrá ser modificado, de acuerdo con el ' +
    'siguiente mecanismo: (i) Natura publicará un aviso en el Sitio, al cual la CN tendrá acceso ' +
    'mediante el ingreso de su usuario y clave personal, en el que dará cuenta de la modificación ' +
    'propuesta y solicitará a la CN que indique que ha tomado conocimiento de ella y que la acepta; ' +
    '(ii) la manifestación del consentimiento de la CN a la modificación propuesta por Natura deberá ' +
    'ser expresa a través del documento, instrumento o plataforma que Natura habilite al efecto, ' +
    'pudiendo practicarse de forma electrónica, si así lo permitiere el medio habilitado; (iii) en caso ' +
    'de que la CN no consienta en la modificación dentro del plazo que para dicho efecto se otorgue, ' +
    'Natura podrá ejercer el derecho de terminación unilateral que le confiere el punto siguiente de ' +
    'esta Cláusula; (iv) esta terminación producirá sus efectos el último día hábil indicado en la ' +
    'publicación en la que Natura hubiere comunicado su intención de modificar el Contrato. 3. ' +
    'Cualquiera de los contratantes podrá poner término a este Contrato ante el incumplimiento de las ' +
    'obligaciones del otro contratante. Para estos efectos, la Parte que desee poner término anticipado ' +
    'al Contrato deberá comunicarlo al correo electrónico que Natura determine para tales efectos o al ' +
    'correo electrónico entregado por la CN en el Sitio, según sea el caso, y producirá sus efectos 10 ' +
    'días después de la fecha de su envío. 4. Natura se reserva el derecho de bloquear automáticamente ' +
    'de la cuenta y registro de la CN y/o de poner término inmediato al presente Contrato, sin perjuicio ' +
    'de la posibilidad de ejercer las acciones legales en caso de que corresponda, en las siguientes ' +
    'situaciones: (i) por mal comportamiento de pago, conforme a los términos señalados en el presente ' +
    'contrato; (ii) por malas prácticas de venta, contrarias a lo estipulado en el presente contrato, o que ' +
    'impliquen algún tipo de perjuicio para el cliente final y/o Natura (ej. Si vendiera productos ' +
    'robados, adulteradora u otro motivo relacionado a la venta); (iii) por comportamiento ético ' +
    'contrario a los principios de Natura; (iv) por comportamientos ilícitos relativos a su actividad de ' +
    'consultoría; (v) por fallecimiento; y/o (vi) en caso de identificar el registro de número de Cédula ' +
    'de Identidad de una persona fallecida, menor de edad y/o incapaz de obligarse, o de una persona ' +
    'jurídica.',
  'VIII. Domicilio. Para todos los efectos legales las partes fijan su domicilio en la ciudad de ' +
    'Santiago, sometiéndose a la jurisdicción de sus Tribunales de Justicia. ' +
    'El presente instrumento se entiende aceptado por las partes desde el momento en que Natura ' +
    'acepte la solicitud de registro de la CN, para lo cual ésta debe haberlo aceptado expresamente con ' +
    'anterioridad. Copia de este documento será enviado a la casilla de correo electrónico señalado por ' +
    'la CN en su solicitud de registro.',
  '***',
  'MANDATO ESPECIAL',
  'Por el presente instrumento, el CONSULTOR/A (el “Mandante”) confiere poder especial a Natura ' +
    'Cosméticos S.A. (la “Mandataria”), para que en su nombre y representación proceda a suscribir ' +
    'o aceptar indistintamente pagarés o letras de cambio a la vista, a la orden de Natura Cosméticos ' +
    'S.A., por la suma que el Mandante le llegare a adeudar conforme a la liquidación que esa misma ' +
    'Empresa practique, hasta por el monto de la línea de Crédito que a la compareciente aquella le ' +
    'otorgue. Ello con el objetivo de facilitar el cobro de aquellas cantidades que la mandante se ' +
    'encontrare obligada y no pagare efectivamente en la oportunidad que correspondiere, ' +
    'incluyéndose los máximos intereses que la ley le permita estipular.',
  'Para estos efectos, la Mandataria, por sí o a través del tercero que designe, estará facultada, sin ' +
    'mediar requerimiento de pago o protesto, para: (i) hacer autorizar ante Notario la firma de los ' +
    'suscriptores de esos instrumentos, y (ii) completar en ellos todos los datos, antecedentes y ' +
    'formalidades necesarios para que reúnan los requisitos de validez en conformidad a lo dispuesto ' +
    'en los artículos 11 y 102 de la Ley N°18.092 sobre Letras de Cambio y Pagarés y demás normas ' +
    'legales aplicables.',
  'El presente mandato no será remunerado. Natura rendirá cuenta de su ejercicio, la cual enviará ' +
    'por escrito al domicilio o correo electrónico registrado por el mandante en Natura, dentro de los ' +
    '20 días siguientes a la suscripción del pagaré o letra de cambio.',
  'El mandante podrá revocar este mandato una vez que se extinga el Contrato de Consultor/a ' +
    'Comisionista Independiente suscrito con Natura Cosméticos. La revocación producirá efecto a ' +
    'contar del décimo quinto día de su notificación a Natura, por escrito o mediante correo electrónico ' +
    'dirigido a la Gerente de Negocio correspondiente al sector asignado a la Consultor/a.',
  'Todos estos gastos e impuestos establecidos por la legislación vigente, derechos notariales u otros ' +
    'que devenguen con motivo de este mandato o por el cumplimiento del encargo cometido o que ' +
    'generen los documentos que se extienden serán de cargo exclusivo del mandante, los cuales ' +
    'podrán ser parte del monto de los documentos señalados o generar unos nuevos que correspondan ' +
    'a estos valores.'
]

export default termsAndConditions
