import Person from 'src/models/Person'

export const REHYDRATE = 'persist/REHYDRATE'

export const types = [REHYDRATE]

export function personRehydrateReducer(state, action, dependencies) {
  const { payload = {} } = action
  const { person: { person: personSaved = {} } = {} } = payload

  const person = new Person(personSaved, dependencies)

  return {
    ...state,
    loading: false,
    error: false,
    person
  }
}
