import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Loader from 'src/base/Loader'

const bem = bemClassName.bind(null, 'loading-page')

export const LoadingPage = ({className}) => {
  return (
    <div className={classNames(bem('loading-page'), className)}>
      <Loader />
    </div>
  )
}

LoadingPage.propTypes = {
  className: PropTypes.string,
}
