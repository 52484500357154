import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Select from 'src/base/Form/Components/Select'
import { messages } from './Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'department',
    label: intl.formatMessage(messages['department']),
    placeholder: intl.formatMessage(messages['department_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['department_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    disabledBy: [ 'address[].region' ],
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['department']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    validateFieldDependencies(values)
    pushDataLayer(values, intl)
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.province`, '', id)
  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.province`, false)
  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getProvinceWithDepartment(params) {
  const { value, getAddress } = params
  return value && getAddress({
    geoStructureCode: value,
    geoStructureType: 'provinces',
    queryParams: `level=3&parentLevel=2&parentCode=${value}`,
  })
}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, addressesPeopleManagement }, locale: { locale }} = state
  const { getAddressesCMM, departments: departmentsCMM } = addresses
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { region } = address[index] || {}

  const { getLevels, departments: departmentsBFF } = addressesPeopleManagement
  const { configs: {localization: { shouldGetAddressFromBff }}} = locale

  const getAddress = shouldGetAddressFromBff ? getLevels : getAddressesCMM
  const departments = shouldGetAddressFromBff ? departmentsBFF : departmentsCMM

  return {
    ...ownProps,
    options: departments[region] || [],
    onChange: function(value, label) {
      getProvinceWithDepartment({ value, getAddress })
      onChange(value, label)
    }
  }
}
