import React from 'react'
import { defineMessages } from 'react-intl'
import Message from 'src/base/Form/Components/Message'

const messages = defineMessages({
  description_pre_paid: {
    defaultMessage: 'In this mode you will NOT have credit or approved payment deadline to place orders. This means that you must pay for the orders before they are dispatched.',
    id: 'form.field.description.pre.paid'
  },
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const descriptionPrePaid = intl.formatMessage(messages['description_pre_paid'])

  return {
    id: 'descriptionPrePaid',
    node: (
      <Message message={descriptionPrePaid} />
    )
  }
}
