import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'
import { store } from 'src/App.state'

export const buildFlows = () => {
  const { user: { user } } = store.getState()

  const argentinaFlow = [
    'pre-register',
    'personal-data',
    user.authenticated ? 'authorization-code' : null,
    'address',
    'documentation',
    'documents',
    'indication',
    'completed'
  ].filter(w => w)

  return {
    [BEAUTY_CONSULTANT_PROFILE]: argentinaFlow
  }
}
