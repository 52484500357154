import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { isEqual } from 'lodash'

import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Modal from 'src/base/Modal'
import Button, { TYPES } from 'src/base/Button'
import MapsDisplay from './Maps.display'

import { messages } from './Maps.messages'
import './Maps.styl'

const bem = bemClassName.bind(null, 'maps_modal')

export class MapsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nextStep: false,
      geocodeResults: this.props.location
    }

    this.handleNextStep = this.handleNextStep.bind(this)
    this.handleGeoCode = this.handleGeoCode.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidUpdate(props) {
    const { location } = this.props

    if (!isEqual(location, props.location)) {
      this.setState({ geocodeResults: location })
    }
  }

  handleNextStep() {
    this.setState({ nextStep: true })
  }

  handleGeoCode(geocodeResults) {
    this.setState({ geocodeResults })
  }

  handleConfirm() {
    const { geocodeResults } = this.state

    this.props.handleConfirmAddress(geocodeResults)
    this.setState({ nextStep: false })
  }

  get message() {
    const { error, intl } = this.props

    if (error) {
      return `${intl.formatMessage(messages['maps_modal_error'])}`
    }

    return `${intl.formatMessage(messages['maps_modal_map'])}`
  }

  get nextStep() {
    const { nextStep } = this.state

    if (!nextStep) return this.renderModalError()

    return this.renderModalMaps()
  }

  render() {
    const { showModal } = this.props

    return (
      <Modal
        className={bem('')}
        open={showModal}
        accept={false}>
        {this.nextStep}
      </Modal>
    )
  }

  renderModalError() {
    const { intl } = this.props
    return (
      <div className={bem('error')}>
        <h3 className={bem('error', ['title'])} >
          {intl.formatMessage(messages['maps_modal_error_title'])}
        </h3>
        <p className={bem('error', ['description'])}>
          {this.message}
        </p>
        <div className={bem('error', ['button'])}>
          <Button data-testid="maps-modal-error-button"
            type={TYPES.PRIMARY}
            onClick={this.handleNextStep}>
            {intl.formatMessage(messages['maps_modal_error_button'])}
          </Button>
        </div>
      </div>
    )
  }

  renderModalMaps() {
    const { GMKey, locale, intl, onMapLoad, fixedAtCenter } = this.props
    const { geocodeResults } = this.state

    return (
      <div className={bem('map')}>
        <MapsDisplay
          center={geocodeResults}
          GMKey={GMKey}
          locale={locale}
          onMapLoad={onMapLoad}
          fixedAtCenter={fixedAtCenter}
          handleGeoCode={this.handleGeoCode}
        />
        <div className={bem('map', ['action'])}>
          <p className={bem('map', ['description'])}>
            {intl.formatMessage(messages['maps_modal_map_pin_description'])}
          </p>
          <Button
            type={TYPES.PRIMARY}
            className={bem('map', ['confirm'])}
            data-testid="maps-modal-map-pin-button"
            onClick={this.handleConfirm}>
            {intl.formatMessage(messages['maps_modal_map_pin_button'])}
          </Button>
        </div>
      </div>
    )
  }
}

MapsModal.propTypes = {
  intl: PropTypes.object,
  showModal: PropTypes.bool,
  GMKey: PropTypes.any,
  error: PropTypes.bool,
  location: PropTypes.any,
  locale: PropTypes.any,
  handleConfirmAddress: PropTypes.func,
  onMapLoad: PropTypes.func,
  fixedAtCenter: PropTypes.bool
}

export default injectIntl(MapsModal)
