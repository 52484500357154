import { normalizePhones } from 'src/models/Phone/Phone'

export function onSubmition({ person, digitalSpace, locale: { configs } }) {
  return function(data, callback) {
    const { emails, phones, digitalSpace: { digitalName } } = data
    const params = {
      emails,
      phones: phones.map(normalizePhones, { configs }),
      isRegistrationComplete: true
    }

    digitalSpace.save(digitalName, person.personId)

    person.save(params, callback)
  }
}


