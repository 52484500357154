import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import OtherInformation from 'src/models/OtherInformation'
import { isEnablePersonOtherInformation } from 'src/config'

const adapter = validatorAdapter('Yup', yup)

export default class AdditionalInformation extends Entity {
  static SCHEMA = {
    informationDisclosureAllowed: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    introducerSellerId: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    introducerSellerName: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    registrantId: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    registrantName: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    leaderId: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    leaderName: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    optInMail: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    optInPush: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    optInSms: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    quitReasonId: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    rejectionReasonId: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    rejectionReasonName: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    creatorRole: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    lastUpdaterRole: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    createdByName: {
      validator: adapter(yup.mixed().nullable()),
      defaultValue: null
    },
    establishmentId: {
      validator: adapter(yup.number()),
      defaultValue: null
    },
    admissionDate: {
      validator: adapter(yup.date().nullable()),
      defaultValue: null
    },
    ethnicityId: {
      validator: adapter(yup.number()),
      defaultValue: null
    },
    ...(isEnablePersonOtherInformation() && {
      otherInformation: {
        validator: adapter(yup.object()),
        type: OtherInformation,
        defaultValue: {}
      }
    })
  }
}
