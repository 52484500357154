import { defineMessages } from 'react-intl'
import TagManager from 'react-gtm-module'

const messages = defineMessages({
  colombia_number: {
    defaultMessage: 'Number',
    id: 'form.field.number'
  },
  colombia_number_placeholder: {
    defaultMessage: 'XX-XX',
    id: 'form.field.colombia_number.placeholder'
  },
  label: {
    defaultMessage: 'Without Number',
    id: 'form.field.without_number.label'
  },
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  const pushDataLayer = (values) => {
    const { value } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationLocationData',
        fieldName: intl.formatMessage(messages['colombia_number']),
        value,
      }
    })
  }

  return {
    id: 'number',
    label: intl.formatMessage(messages['colombia_number']),
    placeholder: intl.formatMessage(messages['colombia_number_placeholder']),
    type: 'inputWithCheckbox',
    required: true,
    showAsterisk: injection.showAsterisk,
    onBlur: pushDataLayer,
    customProps: {
      checkboxId: 'withoutNumberCheckbox',
      checkboxClassName: 'without_number',
      checkboxLabel: intl.formatMessage(messages.label),
      checkboxShowAsterisk: injection.showAsterisk,
      checkboxRequired: false,
    }
  }
}
