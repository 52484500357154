import { Collection } from 'speck-entity'
import Role from './Role'

export default class Roles extends Collection {
  static TYPE = Role

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }

  get cn() {
    return this.items.find(findCN) || {}
  }

  get cnActivate() {
    return this.cn.isActive
  }

  get hasRestartBeautyConsultantRole() {
    return this.items.some(findRestartBeautyConsultantRole)
  }

  hasRoleDraftFor(businessModel) {
    return !!this.findCurrentRole(businessModel).isDraft
  }

  findCurrentRole(params) {
    return this.items.find(findRole, params) || {}
  }
}

function findRole({ businessModelId, functionId, roleId }) {
  return (
    businessModelId === this.businessModelId &&
    functionId === this.functionId &&
    roleId === this.roleId
  )
}

function findCN(role) {
  return role.isCN && !role.endDate
}

function findRestartBeautyConsultantRole(role) {
  return role.isCN && !role.isActive
}
