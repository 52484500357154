import { defineMessages } from 'react-intl'

const messages = defineMessages({
  address_line2_placeholder: {
    defaultMessage: 'Line 2 for address (optional)',
    id: 'form.field.addressline2.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'streetAddressLine2',
    label: '',
    placeholder: intl.formatMessage(messages['address_line2_placeholder']),
    type: 'input',
    required: false,
    showAsterisk: false,
    htmlProps: {
      style: {
        marginTop: '1.2rem'
      }
    }
  }
}
