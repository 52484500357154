import * as yup from 'yup'
import { optionsByLocaleId } from './strategies/options'
import { messages } from './Ethnicity.messages'
import { isDefaultFlowToggleActive } from 'src/config'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    locale: { id: localeId },
    person: { additionalInformation }
  } = injection

  const options = optionsByLocaleId(localeId, { intl, messages })
  const defaultValue = additionalInformation
    ? additionalInformation.ethnicityId
    : undefined

  return {
    id: 'ethnicityId',
    label: intl.formatMessage(messages['ethnicity']),
    validations: yup.number(),
    placeholder: intl.formatMessage(messages['ethnicity_placeholder']),
    noOptionsMessage: intl.formatMessage(
      messages['ethnicity_no_options_message']
    ),
    type: 'select',
    showAsterisk: !isDefaultFlowToggleActive(),
    defaultValue,
    options
  }
}
