import People from 'src/models/People'

export const REHYDRATE = 'persist/REHYDRATE'

export const types = [REHYDRATE]

export function peopleRehydrateReducer(state, action, dependencies) {
  const { payload = {} } = action
  const { people: { people = [] } = {} } = payload

  return {
    loading: false,
    error: false,
    people: new People(people, dependencies)
  }
}
