import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import FormDefault from '../../../../../../../views/Register/Components/FormDefault'
import Loader from '../../../../../../../views/Register/Components/Loader'
import { buildPreRegisterProps } from '../../PreRegister'
import {
  buildPreRegisterConfirmationPropsMicrofrontend
} from 'src/configs/Locales/DEFAULT/views/SelfRegister/PreRegisterConfirmation/PreRegisterConfirmationMicrofrontend'
import { buildPersonalDataProps } from '../../PersonalData'
import { buildAddressProps } from '../../Address'
import { buildDocumentationProps } from '../../Documentation'
import { buildDocumentsProps } from '../../Documents'
import { buildIndicationProps } from '../../Indication'
import CompletedWithStatus from '../../../../../../../views/Register/Components/CompletedWithStatus'
import LoaderWithCarousel from '../../../../../../../views/Register/Components/LoaderWithCarousel'
import { buildCompletedProps } from '../../Completed'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import { buildDigitalSpaceProps } from '../../DigitalSpace/DigitalSpaceProps'
import { buildAuthorizationCodeProps } from 'src/configs/Locales/DEFAULT/views/SelfRegister/AuthorizationCode/AuthorizationCodeProps'
import { isIndicationStepOnMfe } from 'src/config'
import { buildBusinessModel } from 'src/configs/Locales/DEFAULT/views/SelfRegister/BusinessModel'
import { getBusinessModelByProfile } from 'src/configs/Locales/DEFAULT/NaturaProfiles'
import { buildDigitalSignaturePropsMicrofrontend } from '../../DigitalSignature/DigitalSignatureProps'
import { buildPendingSignatureProps } from '../../PendingSignature/PendingSignatureProps'

export class BuildStepsMapper {
  constructor({ basePath, totalSteps }) {
    this.basePath = basePath
    this.totalSteps = totalSteps
  }

  progressPercentage({ currentStep }) {
    return Math.floor((100 * currentStep) / this.totalSteps)
  }

  mapPreRegister({ currentStep, overrideProps }) {
    return {
      name: 'pre-register',
      route: `${this.basePath}/pre-register`,
      path: `${this.basePath}/pre-register`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPreRegisterProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: false },
      ...overrideProps
    }
  }

  mapPendingSignature({ currentStep, overrideProps }) {
    return {
      name: 'pending-signature',
      route: `${this.basePath}/pending-signature/:personId?`,
      path: `${this.basePath}/pending-signature`,
      components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader],
      componentsPropsBuilder: buildPendingSignatureProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: false },
      ...overrideProps
    }
  }

  mapBusinessModel({ currentStep }) {
    return {
      name: 'business-model',
      route: `${this.basePath}/business-model/:personId?`,
      path: `${this.basePath}/business-model`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildBusinessModel,
      displayOnStepper: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true }
    }
  }

  mapPersonalData({ currentStep, overrideProps }) {
    return {
      name: 'personal-data',
      route: `${this.basePath}/personal-data/:personId`,
      path: `${this.basePath}/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 1
      },
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true },
      ...overrideProps
    }
  }

  mapPersonalDataByProfile({ currentStep, profile, personalStepByProfile, overrideProps }) {
    return {
      name: personalStepByProfile[profile],
      route: `${this.basePath}/${profile}/personal-data/:personId`,
      path: `${this.basePath}/${profile}/personal-data`,
      businessModel: getBusinessModelByProfile(profile),
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: false },
      ...overrideProps
    }
  }

  mapAddress({ currentStep, overrideProps }) {
    return {
      name: 'address',
      route: `${this.basePath}/complementary-data/:personId`,
      path: `${this.basePath}/complementary-data`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildAddressProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true },
      ...overrideProps
    }
  }

  mapDocumentation({ currentStep, overrideProps }) {
    return {
      name: 'documentation',
      route: `${this.basePath}/documentation/:personId`,
      path: `${this.basePath}/documentation`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildDocumentationProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep }),
      actions: { next: true, previous: true },
      ...overrideProps
    }
  }

  mapDocumentAttachment({ currentStep, overrideProps }) {
    return {
      name: 'documents',
      route: `${this.basePath}/documents/:personId`,
      path: `${this.basePath}/documents`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildDocumentsProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true },
      ...overrideProps
    }
  }

  mapDigitalSignature({ currentStep, overrideProps }) {
    return {
      name: 'digital-signature',
      route: `${this.basePath}/digital-signature/:personId`,
      path: `${this.basePath}/digital-signature`,
      components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader],
      componentsPropsBuilder: buildDigitalSignaturePropsMicrofrontend,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true },
      ...overrideProps
    }
  }

  mapIndication({ currentStep }) {
    const FormComponent = isIndicationStepOnMfe()
      ? MicroFrontendContainer
      : FormDefault

    return {
      name: 'indication',
      route: `${this.basePath}/indication/:personId`,
      path: `${this.basePath}/indication`,
      components: [LinearProgressStepper, SectionTitle, FormComponent, Loader],
      componentsPropsBuilder: buildIndicationProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true }
    }
  }

  mapDigitalStore({ currentStep }) {
    return {
      name: 'digital-store',
      route: `${this.basePath}/digital-store/:personId`,
      path: `${this.basePath}/digital-store`,
      components: [
        LinearProgressStepper,
        SectionTitle,
        MicroFrontendContainer,
        Loader
      ],
      componentsPropsBuilder: buildDigitalSpaceProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: false, previous: false }
    }
  }

  mapAuthorizationCode({ currentStep, overrideProps }) {
    return {
      name: 'authorization-code',
      route: `${this.basePath}/authorization-code/:personId`,
      path: `${this.basePath}/authorization-code`,
      components: [
        LinearProgressStepper,
        SectionTitle,
        MicroFrontendContainer,
        Loader
      ],
      componentsPropsBuilder: buildAuthorizationCodeProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: false, previous: false },
      ...overrideProps,
    }
  }

  mapCompleted({ currentStep, overrideProps }) {
    return {
      name: 'completed',
      route: `${this.basePath}/completed/:personId`,
      path: `${this.basePath}/completed`,
      components: [
        LinearProgressStepper,
        SectionTitle,
        CompletedWithStatus,
        LoaderWithCarousel
      ],
      componentsPropsBuilder: buildCompletedProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: false, previous: true },
      ...overrideProps
    }
  }

  mapPreRegisterConfirmation({ currentStep }) {
    return {
      name: 'pre-register-confirmation',
      route: `${this.basePath}/pre-register-confirmation/:personId`,
      path: `${this.basePath}/pre-register-confirmation`,
      components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader],
      componentsPropsBuilder: buildPreRegisterConfirmationPropsMicrofrontend,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: currentStep,
      completed: this.progressPercentage({ currentStep: currentStep }),
      actions: { next: true, previous: true },
    }
  }
}
