import { getSectionTitle } from './Messages'

export function buildDigitalSpaceProps(deps) {
  const { intl, locale: { id, tenantId, companyId }, person, loader: { personLoading } } = deps
  const { personId, businessModel: { businessModelId, functionId, roleId } } = person
  const { title, subtitle, messages } = getSectionTitle(deps)

  return {
    messages,
    step: {
      title,
      subtitle
    },
    loader: personLoading,
    intl,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'digitalStore',
      identityInfo: {
        personId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
        tenantId
      }
    }
  }
}
