import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  page_title: {
    defaultMessage: 'I had an indication',
    id: 'register.indication.page.title'
  },
  subtitle_1: {
    defaultMessage: 'Type the consultant code from the person who indicated you below',
    id: 'register.indication.subtitle_1'
  },
  finish_button: {
    defaultMessage: 'Finish',
    id: 'register.indication.finish_button'
  },
})

export function getSectionTitle({ messages = Messages }) {

  const {
    page_title,
    subtitle_1
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />
    ],
  }
}

export default Messages
