import { getContext } from 'src/infra/Api/Api.context'

import Error from 'src/viewModels/Error'
import DigitalSpace from 'src/viewModels/DigitalSpace'

export const DIGITAL_SPACE_GET_LOADING = 'natura-caduni/DIGITAL_SPACE_GET_LOADING'
export const DIGITAL_SPACE_GET_SUCCESS = 'natura-caduni/DIGITAL_SPACE_GET_SUCCESS'
export const DIGITAL_SPACE_GET_ERROR = 'natura-caduni/DIGITAL_SPACE_GET_ERROR'

export const types = [ DIGITAL_SPACE_GET_LOADING, DIGITAL_SPACE_GET_SUCCESS, DIGITAL_SPACE_GET_ERROR ]

export function digitalSpaceGetReducer(state, action, dependencies) {
  const { payload, type } = action
  let { digitalSpace, error } = state

  const isLoading = type === DIGITAL_SPACE_GET_LOADING
  const hasSuccess = type === DIGITAL_SPACE_GET_SUCCESS
  const hasError = type === DIGITAL_SPACE_GET_ERROR

  if(hasSuccess) {
    const { data } = payload
    digitalSpace = new DigitalSpace(data, dependencies)

    error = false
  }

  if(hasError) {
    error = new Error(action.error)
  }

  return {
    digitalSpace,
    loading: isLoading,
    error,
  }

}

export function getDigitalSpace(personId) {
  const {
    dispatch,
    urls: { DIGITAL_SPACE },
    locale: { id },
    user: {
      sellerId,
      authentication: { accessToken }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'GET',
        url: DIGITAL_SPACE.get(personId),
        headers: {
          access_token: accessToken,
          userId: sellerId,
          externalId: id
        }
      }
    }
  })

}
