import * as yup from 'yup'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  cpf_label: {
    defaultMessage: 'CPF',
    id: 'form.field.cpf.label'
  },
  cpf_invalid: {
    defaultMessage: 'Invalid CPF',
    id: 'form.field.cpf.invalid'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person = {}, person: { documents } = {} } = injection
  const { document: defaultValue } = documents && documents.find(({ type }) => type == 1) || {}

  const invalidCPF = intl.formatMessage(messages['cpf_invalid'])

  return {
    id: 'cpf',
    label: intl.formatMessage(messages['cpf_label']),
    placeholder: '000.000.000-00',
    mask: '999.999.999-99',
    onBlur: createPersonWithDocument({ person }),
    defaultValue,
    validations: yup.string()
      .test('CPF', invalidCPF, validateCPF)
  }
}

function createPersonWithDocument({ person }) {
  return function ({ values, errors, value, schema: { validate } }) {
    const validateValue = value.replace(/\D+/g, '')

    const hasError = validate(validateValue, { values, errors })
    if (hasError || !validateValue) return

    const [{ document = validateValue, type = 1 } = {}] = person.documents

    return person.create({ documents: [{ document, type }] })
  }
}

function validateCPF(value) {
  if (!value) return false

  let add = 0
  let mod = 0

  const strCPF = value.replace(/[.-\s]/g, "")

  if (strCPF == "00000000000") return false

  for (let i = 1; i <= 9; i++) {
    add = add + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }

  mod = (add * 10) % 11

  if ((mod == 10) || (mod == 11)) {
    mod = 0
  }

  if (mod != parseInt(strCPF.substring(9, 10))) {
    return false
  }

  add = 0

  for (let i = 1; i <= 10; i++) {
    add = add + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }

  mod = (add * 10) % 11

  if ((mod == 10) || (mod == 11)) {
    mod = 0
  }

  if (mod != parseInt(strCPF.substring(10, 11))) {
    return false
  }

  return true
}
