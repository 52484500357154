import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import { findAddress } from 'src/models/Address'
import { MAILING_ADDRESS, DELIVERY_ADDRESS, ADDRESS_USE } from 'src/models/Address/AddressUse'

export const messages = defineMessages({
  label: {
    defaultMessage: 'The delivery address is equal to the residential address',
    id: 'form.field.address.label'
  },
})

const dependencies = {
  messages,
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'copyAdress',
    type: 'checkbox',
    label: intl.formatMessage(messages.label),
    onChange: removeAddress
  }
}

async function removeAddress({ value, removeItemFromGroup, groupSchema, schema, setFieldValue, values }) {
  const { group: { id }, parent } = schema

  if (value) {
    const deliveryAddress = values.address.find(findAddress, { useId: ADDRESS_USE.DELIVERY })
    const index = getIndex(values, deliveryAddress)

    await removeItemFromGroup({ ...groupSchema, index })

    return setFieldValue(`${parent}.addressUse`, [MAILING_ADDRESS, DELIVERY_ADDRESS], id)
  }

  return setFieldValue(`${parent}.addressUse`, [MAILING_ADDRESS], id)
}

export function getValidationCopyAddress(message) {
  return yup.bool().test({
    name: 'copyAdress',
    message,
    test(value) {
      const { options: { context: { values: { address } }}} = this

      const deliveryAddress = address.find(findAddress, { useId: ADDRESS_USE.DELIVERY })

      if(!deliveryAddress)
        return !!value

      return true
    }
  })
}

function getIndex({ address }, value) {
  if (!value) return 1

  return address.indexOf(value) + 1
}
