import { getContext } from 'src/infra/Api/Api.context'

import People, { PAGE_SIZE } from 'src/models/People'
import Error from 'src/viewModels/Error'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PEOPLE_GET_LOADING = 'natura-caduni/People/PEOPLE_GET_LOADING'
export const PEOPLE_GET_SUCCESS = 'natura-caduni/People/PEOPLE_GET_SUCCESS'
export const PEOPLE_GET_ERROR = 'natura-caduni/People/PEOPLE_GET_ERROR'

export const types = [PEOPLE_GET_LOADING, PEOPLE_GET_SUCCESS, PEOPLE_GET_ERROR]

export function peopleGetReducer(state, action, dependencies) {
  let { loading, error } = state
  const { payload, type, meta } = action
  const { items = [] } = state.people

  const hasSuccess = type === PEOPLE_GET_SUCCESS
  const hasError = type === PEOPLE_GET_ERROR
  const isLoading = type === PEOPLE_GET_LOADING

  let list = items

  if (hasSuccess) {
    error = false
    loading = false

    const { data } = payload
    const {
      previousAction: {
        payload: { shouldResetList, queryString, queryParams }
      }
    } = meta
    const hasMore = data.length === PAGE_SIZE
    list = shouldResetList ? data : items.concat(data)

    Object.assign(dependencies, {
      queryString,
      queryParams,
      hasMore
    })
  }

  if (hasError) {
    loading = false
    error = new Error(action.error)
    list = []
  }

  return {
    loading: isLoading || loading,
    error,
    people: new People(list, dependencies)
  }
}

export function getPeople({ queryString = '', queryParams }, shouldResetList) {
  const globalAppContext = getContext()
  const { dispatch } = globalAppContext

  const { requestClientName, requestUrl, requestHeaders  } = getRequestPayload({ globalAppContext })

  const url = requestUrl + `?${queryString}`

  return dispatch({
    types,
    payload: {
      client: requestClientName,
      shouldResetList,
      queryString,
      queryParams,
      request: {
        method: 'GET',
        url,
        headers: requestHeaders
      }
    }
  })

  function getRequestPayload ({ globalAppContext }){
    const {
      PEOPLE_URL,
      GET_PEOPLE_URL,
      countryId,
      companyId,
      bffHeaders,
      headersAuthenticationInfo,
      shouldGetPersonDetailsFromBff,
      sellerId
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const headersData = { companyId, countryId, bffHeaders, sellerId }

    const requestClientName = shouldGetPersonDetailsFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId

    const { requestHeaders } = getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData })

    const requestUrl = shouldGetPersonDetailsFromBff ? GET_PEOPLE_URL : PEOPLE_URL

    return { requestClientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({globalAppContext}){
      const {
        urls: { PEOPLE },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { GET_PEOPLE },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldGetPersonDetailsFromBff = configs?.localization?.shouldGetPersonDetailsFromBff

      return {
        PEOPLE_URL: PEOPLE(),
        GET_PEOPLE_URL: GET_PEOPLE(),
        shouldGetPersonDetailsFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ shouldGetPersonDetailsFromBff, headersAuthenticationInfo, headersData }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const { countryId, companyId, bffHeaders, sellerId } = headersData

      const requestHeaders = shouldGetPersonDetailsFromBff ? {
        ...bffHeaders,
        Authorization: userAccessToken,
        'x-api-key': registersBffApiKey,
        country: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}
