import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'
import MESSAGES from './TakePhoto.messages'

const bem = bemClassName.bind(null, 'take-photo')

const AttachmentOptions =  function ({ takeFromCamera, uploadFile, intl }) {
  return (
    <div className={bem()}>
      <header>
        <h3 className={bem('title')}>
          { intl.formatMessage(MESSAGES.attachmentOptionsTitle) }
        </h3>
      </header>
      <section className={bem('content')}>
        <div className={bem('instructions')}>
          <p>{ intl.formatMessage(MESSAGES.attachmentOptionsInstructions) }</p>
          <p>{ intl.formatMessage(MESSAGES.attachmentOptionsSubInstructions) }</p>
        </div>
      </section>
      <footer className={bem('actions')}>
        <button className={bem('actions__button')} onClick={takeFromCamera}>
          <Icon name="camera" className={bem('actions__icon', 'camera')} />
          { intl.formatMessage(MESSAGES.attachmentOptionsTakePhoto) }
        </button>
        <button className={bem('actions__button')} onClick={uploadFile}>
          <Icon name="attachment" className={bem('actions__icon', 'attachment')}  />
          { intl.formatMessage(MESSAGES.attachmentOptionsAdjust) }
        </button>
      </footer>
    </div>
  )
}

AttachmentOptions.propTypes = {
  takeFromCamera: PropTypes.func,
  uploadFile: PropTypes.func,
  intl: PropTypes.object
}

export default injectIntl(AttachmentOptions)
