import {
  isEnableDigitalStoreStepForPeruFlowToggleActive,
  isEnableHostedUiToggleActive,
  isEnableThemeElo
} from '../../../../../config'
import { ROLES } from 'src/infra/Api/PER/roles.peru'
import { ROUTES } from 'src/App.routes'

export default () => ({
  preRegister: {
    useCreatePersonFromBFF: isEnableHostedUiToggleActive()
  },
  businessModel: {
    shouldHideButton: false
  },
  personalData: {
    hasFullNameInfoText: false,
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: false
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  agreements: {
    linkPDF: null,
    linkAudio: null,
    hasEnvironmentsFiles: false
  },
  address: {
    gmap: {
      fields: [
        {
          label: 'region',
          parent: 'countryName'
        },
        {
          label: 'department',
          parent: 'region'
        },
        {
          label: 'province',
          parent: 'department'
        },
        {
          label: 'district',
          parent: 'province'
        }
      ]
    },
    levels: {
      COUNTRY: 0,
      REGION: 1,
      DEPARTMENT: 2,
      PROVINCE: 3,
      DISTRICT: 4,
      NEIGHBORHOOD: 5,
      STREET: 6,
      NUMBER: 7,
      COMPLEMENT: 8,
      REFERENCES: 9,
      ZIPCODE: 10
    },
    hasZipCode: false
  },
  authenticationLegacy: false,
  appStoreUrls: {
    android:
      'https://play.google.com/store/apps/details?id=net.natura.minegocionatura&hl=es',
    iOS: 'https://apps.apple.com/pe/app/mi-negocio-natura/id1197578002'
  },
  callEndpointComplete: true,
  contactValidationLegacy: false,
  date: {
    format: 'DD/MM/YYYY'
  },
  documents: {
    lengthValidation: {
      foreignRegistry: {
        min: 9
      },
      nationalRegistry: {
        min: 8
      },
      ruc: {
        min: 11
      },
      temporaryPermissionWork: {
        max: 9,
        min: 9
      }
    },
    mask: {
      foreignRegistry: '999999999',
      nationalRegistry: '99999999',
      ruc: '99999999999',
      temporaryPermissionWork: '999999999'
    },
    other: {
      ruc: 27
    },
    personal: {
      foreignRegistry: 33,
      nationalRegistry: 19,
      temporaryPermissionWork: 32
    }
  },
  featureLite: true,
  getBusinessModelFromApi: true,
  getFiltersFromApi: true,
  map: {
    center: {
      lat: -12.0552073,
      lng: -77.0627323
    }
  },
  ocr: {
    fields: ['identificationCardFront', 'identificationCardBack'],
    validations: [
      {
        attachmentsToValidate: [
          {
            field: 'identificationCardFront',
            type: 16
          },
          {
            field: 'identificationCardBack',
            type: 17
          }
        ],
        document: 'nationalRegistry',
        documentType: 19
      }
    ]
  },
  offlineMode: true,
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '51',
    customValidation: {
      invalid: /.*/,
      line: /.*/,
      mobile: {
        start_code: {
          number: 9,
          validation: /^9/
        },
        start_number_negation: {
          number: 0,
          validation: /^.{3}[1-9]/
        }
      }
    },
    emailRequired: false,
    lengthValidation: {
      line: {
        min: 8
      },
      mobile: {
        min: 9
      }
    },
    mask: {
      line: '99 999 9999',
      mobile: '999 999 999'
    },
    placeholder: {
      line: 'XX XXX XXXX',
      mobile: 'XXX XXX XXX'
    }
  },
  policies: [
    {
      acceptanceRequired: true,
      businessModel: 1,
      country: 18,
      fieldId: 'personal_data',
      id: 7,
      name: 'Autorización de uso de datos personales',
      sequence: '6f2ecd27-bf00-446e-83ab-ceaf18062749',
      version: 1
    },
    {
      acceptanceRequired: false,
      businessModel: 1,
      country: 18,
      fieldId: 'invoice',
      id: 8,
      name: 'Aceptación recepción de factura digital',
      sequence: '5a278b2d-5825-4d32-805d-b9ffcb21c871',
      version: 1
    }
  ],
  selectPaymentPlan: true,
  shouldCallCreatePaymentPlanInPaymentView: false,
  shouldCallGetPersonInVerifyContacts: false,
  shouldCallRegisterError: false,
  shouldUseBusinessModelOnRegister: true,
  showProfileEditButton: false,
  hasLego: true,
  hasDigitalStore: isEnableDigitalStoreStepForPeruFlowToggleActive(),
  shouldCallCompleteAddressStepUsingBff: isEnableHostedUiToggleActive(),
  shouldUpdatePersonFromBff: isEnableHostedUiToggleActive(),
  shouldUpdateRegistrationSubstatusFromBff: isEnableHostedUiToggleActive(),
  shouldGetPersonDetailsFromBff: isEnableHostedUiToggleActive(),
  shouldGetEstablishmentsFromBff: isEnableHostedUiToggleActive(),
  shouldGetBusinessModelFromBff: isEnableHostedUiToggleActive(),
  shouldGetRejectionReasonsFromBff: isEnableHostedUiToggleActive(),
  shouldGetAddressFromBff: isEnableHostedUiToggleActive(),
  shouldIgnorePeopleContextResponseOnLogin: false,
  shouldCompleteRegisterWithBff: isEnableHostedUiToggleActive(),
  isHostedUIEnabled: isEnableHostedUiToggleActive(),
  profile: {
    shouldRenderContactValidation: true
  },
  canGoToReportsFV: false,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: ROUTES.SELF_REGISTER_WITH_COUNTRY.replace(':country', 'pe'),
      external: false,
      shouldSSO: false
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: ROUTES.DASHBOARD,
      external: false,
      shouldSSO: false
    }
  },
  social: isEnableThemeElo() && process.env.ENABLE_THEME_ELO_TO_COUNTRY.split(',').includes('PE')
    ? {
      natura: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/Natura',
          label: 'Facebook Natura'
        },
        {
          type: 'youtube',
          link: 'https://www.youtube.com/user/NaturaPeruOficial',
          label: 'Youtube Natura'
        },
        {
          type: 'twitter',
          link: 'https://twitter.com/natura_peru',
          label: 'Twitter Natura'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/natura.peru/',
          label: 'In`s`tagram Natura'
        }
      ],
      avon: [
        {
          type: 'facebook',
          link: 'https://www.facebook.com/avonperu',
          label: 'Facebook Avon'
        },
        {
          type: 'youtube',
          link: 'https://www.youtube.com/channel/UCqr4HH2NNd-NcFMyJsmjFuQ',
          label: 'Youtube Avon'
        },
        {
          type: 'twitter',
          link: 'https://twitter.com/avonperu',
          label: 'Twitter Avon'
        },
        {
          type: 'instagram',
          link: 'https://www.instagram.com/avon_per/',
          label: 'Instagram Avon'
        }
      ]
    }
    : [
      {
        type: 'facebook',
        link: 'https://www.facebook.com/Natura',
        label: 'Facebook Natura'
      },
      {
        type: 'youtube',
        link: 'https://www.youtube.com/user/NaturaPeruOficial',
        label: 'Youtube Natura'
      },
      {
        type: 'twitter',
        link: 'https://twitter.com/natura_peru',
        label: 'Twitter Natura'
      },
      {
        type: 'instagram',
        link: 'https://www.instagram.com/natura.peru/',
        label: 'In`s`tagram Natura'
      }
    ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
