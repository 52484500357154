import { getContext } from 'src/infra/Api/Api.context'
import PeopleRegister from 'src/viewModels/PeopleRegister'

export const PEOPLE_REGISTER_SAVE = 'natura-caduni/People/PEOPLE_REGISTER_SAVE'

export function reducerPeopleRegisterSave(state, action, dependencies) {
  const { payload: { person } } = action
  const { peopleRegister } = state

  const { items } = peopleRegister.save(person)

  return {
    peopleRegister: new PeopleRegister(items, dependencies)
  }
}

export function savePerson(person) {
  const { dispatch } = getContext()

  return dispatch({
    type: PEOPLE_REGISTER_SAVE,
    payload: {
      person
    }
  })
}
