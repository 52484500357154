import { defineMessages } from 'react-intl'

import { PHONE_TYPES } from 'src/models/Phone'
import { isCurrentLocationOnRegisterPage } from 'src/helpers/app-history'
import TagManager from 'react-gtm-module'

const messages = defineMessages({
  phone_type: {
    defaultMessage: 'Phone Type',
    id: 'form.field.phone.type'
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'form.field.phone'
  },
  phone_type_placeholder: {
    defaultMessage: 'Select',
    id: 'form.field.phone.placeholder'
  },
  phone_type_mobile: {
    defaultMessage: 'Mobile',
    id: 'form.field.phone.mobile'
  },
  phone_type_cellular_phone: {
    defaultMessage: 'Cellular',
    id: 'form.field.phone.cellular'
  },
  phone_type_landline: {
    defaultMessage: 'Landline',
    id: 'form.field.phone.landline'
  },
  phone_type_no_options_message: {
    defaultMessage: 'No options',
    id: 'form.field.phone_type.no.options.message'
  }
})

const dependencies = {
  messages
}

export const getPhoneOptions = (intl) => {
  return [
    {
      value: PHONE_TYPES.MOBILE,
      label: intl.formatMessage(messages['phone_type_cellular_phone'])
    },
    {
      value: PHONE_TYPES.LINE,
      label: intl.formatMessage(messages['phone_type_landline'])
    }
  ]
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { locale } = injection
  const options = getPhoneOptions(intl)
  const showAsterisk =  isCurrentLocationOnRegisterPage()

  return {
    id: 'type',
    label: !isCurrentLocationOnRegisterPage() ? intl.formatMessage(messages['phone']) : intl.formatMessage(messages['phone_type']),
    placeholder: intl.formatMessage(messages['phone_type_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['phone_type_no_options_message']),
    type: 'select',
    showAsterisk,
    options,
    onChange: onChange(intl, options),
    disabledBy: locale.id === 'COL' ? ['phones[].type'] : []
  }
}

const onChange = (intl, options) =>{
  return (values) => {
    resetPhone(values)
    pushDataLayer(values, intl, options)
  }
}

const pushDataLayer = (values, intl, options) => {
  const {value} = values
  const selectedPhoneType = options.filter((phoneType) => phoneType.value === value)[0]

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationPersonalData',
      fieldName: intl.formatMessage(messages['phone']),
      value: selectedPhoneType.label
    }
  })
}

function resetPhone({ value, values, setFieldValue, schema }) {
  const { group: { id, index } } = schema
  const oldValue = values[id][index].type

  if (value !== oldValue) {
    setFieldValue(`${id}[${index}].phone`, '', id)
  }
}
