export function onSubmition({ person }) {
  return function({ paymentPlan }, callback) {
    const params = {
      paymentPlan: {
        id: paymentPlan
      }
    }

    return person.save(params, callback)
  }
}
