import React from 'react'
import { defineMessages } from 'react-intl'
import Message from 'src/base/Form/Components/Message'

const messages = defineMessages({
  description_credit: {
    defaultMessage: 'Thanks to inform your data. Your registration is almost done! If you have your document and electricity or water bill with you, click on NEXT. Otherwise your data has been pre-recorded and we will contact you',
    id: 'form.field.description.credit'
  },
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const descriptionCredit = intl.formatMessage(messages['description_credit'])

  return {
    id: 'descriptionCredit',
    node: (
      <Message message={descriptionCredit} />
    )
  }
}
