import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import { isEqual } from 'lodash'

import BusinessModelIDBuilder from 'src/configs/Fields/BusinessModel/BusinessModelId'
import FunctionIDBuilder from 'src/configs/Fields/BusinessModel/FunctionId'
import RoleIDBuilder from 'src/configs/Fields/BusinessModel/RoleId'

const messages = defineMessages({
  business_model: {
    defaultMessage: 'This business model, function and paper already existent for this register.',
    id: 'form.field.business.model.error'
  }
})

export default function Builder(intl, injection) {
  const { person: { businessModel, roles, draft }, showAsterisk } = injection
  const autoFocus = { autoFocus: true }


  const BUSINESS_MODEL_ID = { ...BusinessModelIDBuilder(intl, injection), htmlProps: autoFocus, showAsterisk }
  const ROLE_ID = { ...RoleIDBuilder(intl, injection), showAsterisk }
  const FUNCTION_ID = { ...FunctionIDBuilder(intl, injection), showAsterisk }

  const message = intl.formatMessage(messages.business_model)

  const params = {
    draft,
    roles,
    message
  }

  return {
    id: 'businessModel',
    defaultValue: [businessModel],
    fields: [
      [BUSINESS_MODEL_ID],
      [ROLE_ID],
      [FUNCTION_ID],
    ],
    validations: yup.array().of(yup.object().shape({
      businessModelId: yup.string().required(),
      roleId: yup.string().required(),
      functionId: getBusinessModelValidation(params),
    }))
  }
}

function getBusinessModelValidation(params) {
  const { roles, message, draft } = params

  return yup.string().required()
    .test({
      name: 'BusinessModelError',
      message: message,
      test() {
        const { options: { context: { values: { businessModel: [ businessModel ] } }}} = this

        const businessModelExits = !roles.items.some(someBusinessModel, { businessModel })

        return !draft ? businessModelExits : true
      }
    })
}

function someBusinessModel(role) {
  const { endDate, businessModelId, functionId, roleId } = role
  const { businessModelAsParams } = this.businessModel

  const params = { businessModelId, functionId, roleId }

  return isEqual(params, businessModelAsParams) && !endDate
}
