import * as yup from 'yup'

import Document from './Documents.document'
import { documentsEnum } from './Documents.type'

export default function Builder(intl, injection) {
  const { person = {}, locale: { configs: { localization } } } = injection
  const { documents: { personal } } = localization

  const documents = person.toJSON().documents.filter(filterDocuments, { documents: personal })

  const DOCUMENT = Document(intl, injection)

  return {
    id: 'documents',
    min: 1,
    max: 1,
    defaultValue: documents,
    fields: [
      [DOCUMENT]
    ],
    validations: yup.array().of(yup.object().shape({
      document: documentValidation('type', localization),
      type: yup.mixed().required(),
    }))
  }
}

export function DocumentsCreation(intl, injection) {
  const documents = Builder(intl, injection)
  const fields = documents.fields[0]
  const { person, htmlProps } = injection
  const DOCUMENT = fields[1]

  Object.assign(DOCUMENT, {
    onBlur: createPersonWithDocument({ person }),
    htmlProps
  })

  Object.assign(documents, {
    validations: documents.validations.required()
  })

  return documents
}

function createPersonWithDocument({ person }) {
  return function ({ values, errors, value, groupSchema, schema: { id } }) {
    const { documents } = values || {}
    const [currentDocument = {}] = documents || []
    const groupValue = [Object.assign(currentDocument, { [id]: value })]
    const hasError = !!groupSchema.validate(groupValue, { values, errors })

    if (hasError || !value) return

    const [{ document, type } = {}] = person.documents
    const documentChanged = document !== value && type !== value

    if (!documentChanged) return

    return person.create({ documents })
  }
}

export function documentValidation(documentType, localization) {
  const { documents: { lengthValidation } = {} } = localization || {}

  return yup.string().required().when(documentType, function (type, schema) {
    const { min, max } = lengthValidation[documentsEnum[type]] || {}

    if (min) return schema = schema.min(min)
    if (max) return schema = schema.max(max)

    return schema
  })
}

function filterDocuments({ type }) {
  return Object.values(this.documents).includes(type)
}
