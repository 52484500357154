import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const PhotoIcon = ({ size, state }) => (
  <svg width={size} height={size} viewBox="0 0 64 66">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-cam" transform="translate(0.000000, 1.000000)">
        <g
          id="Group"
          transform="translate(19.000000, 0.000000)"
          stroke={StateColors[state]}
          strokeWidth="2.5"
        >
          <path
            d="M-8.52651283e-14,30.5 L-8.52651283e-14,8 C0.554938088,3.51136564 2.22160475,1.17803231 5,1 C7.77839525,0.821967695 18.7494622,0.821967695 37.913201,1 C41.6269704,1 43.4838551,3.33333333 43.4838551,8 C43.4838551,15 43.0092462,49.0927181 42.5,54 C42.1605025,57.2715212 40.3271692,59.6048546 37,61 L21.25,61"
            id="Path"
          />
          <path d="M9,19 L35,19" id="Path-2" />
          <path d="M9,27 L35,27" id="Path-3" />
          <path d="M14,34 L35,34" id="Path-4" />
          <path d="M21,42 L35,42" id="Path-5" />
        </g>
        <g
          id="noun_1695998_cc"
          transform="translate(0.000000, 36.000000)"
          fill={StateColors[state]}
        >
          <g id="Group">
            <path
              d="M11.875,0.145496575 C11.4921822,0.187212871 11.1527787,0.408841924 10.9625,0.741355205 L8.5125,4.91234973 L3.6,4.91234973 C1.62635688,4.91234973 2.13162821e-14,6.52747493 2.13162821e-14,8.48748959 L2.13162821e-14,25.1714836 C2.13162821e-14,27.1314863 1.62635688,28.7466274 3.6,28.7466274 L32.4,28.7466274 C34.3736432,28.7466274 36,27.1314863 36,25.1714836 L36,8.48748959 C36,6.52747493 34.3736432,4.91234973 32.4,4.91234973 L27.4875,4.91234973 L25.0375,0.741355205 C24.8234325,0.373186164 24.4280941,0.146134804 24,0.145496575 L12,0.145496575 C11.9583616,0.143340175 11.9166384,0.143340175 11.875,0.145496575 Z M12.6875,2.52892315 L23.3125,2.52892315 L25.7625,6.69992164 C25.9765685,7.06808916 26.3719067,7.29513889 26.8,7.2957763 L32.4,7.2957763 C33.085556,7.2957763 33.6,7.8066689 33.6,8.48748959 L33.6,25.1714836 C33.6,25.8523082 33.085556,26.3631849 32.4,26.3631849 L3.6,26.3631849 C2.914444,26.3631849 2.4,25.8523082 2.4,25.1714836 L2.4,8.48748959 C2.4,7.8066689 2.914444,7.2957763 3.6,7.2957763 L9.2,7.2957763 C9.62809327,7.29513889 10.0234315,7.06808916 10.2375,6.69992164 L12.6875,2.52892315 Z M18,7.69301671 C13.3750212,7.69301671 9.6,11.4419579 9.6,16.0350137 C9.6,20.6280178 13.3750212,24.3770027 18,24.3770027 C22.6249784,24.3770027 26.4,20.6280178 26.4,16.0350137 C26.4,11.4419579 22.6249784,7.69301671 18,7.69301671 Z M18,10.0764433 C21.327922,10.0764433 24,12.7300585 24,16.0350137 C24,19.3399411 21.327922,21.9935603 18,21.9935603 C14.6720776,21.9935603 12,19.3399411 12,16.0350137 C12,12.7300585 14.6720776,10.0764433 18,10.0764433 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

PhotoIcon.propTypes = {
  size: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
}

export default PhotoIcon
