import Error, { ERROR_CODES_TO_INTERCEPT } from 'src/viewModels/Error'
import { SUBSTATUS } from 'src/models/Substatus'
import Person from 'src/models/Person'
import { getContext } from '../../infra/Api/Api.context'
import { EMAIL_ALREADY_EXISTS_CODE } from '../../views/Register/RegisterErrorEnum'
import { CLIENT_NAMES } from '../../infra/Api/Api'
import { BEAUTY_CONSULTANT_PROFILE, getBusinessModelByProfile } from '../../configs/Locales/DEFAULT/NaturaProfiles'

export const PERSON_SAVE_STATUS_LOADING = 'natura-caduni/Person/PERSON_SAVE_STATUS_LOADING'
export const PERSON_SAVE_STATUS_SUCCESS = 'natura-caduni/Person/PERSON_SAVE_STATUS_SUCCESS'
export const PERSON_SAVE_STATUS_ERROR = 'natura-caduni/Person/PERSON_SAVE_STATUS_ERROR'
export const PERSON_SAVE_STATUS_STATE = 'natura-caduni/Person/PERSON_SAVE_STATUS_STATE'

export const types = [
  PERSON_SAVE_STATUS_LOADING,
  PERSON_SAVE_STATUS_SUCCESS,
  PERSON_SAVE_STATUS_ERROR,
  PERSON_SAVE_STATUS_STATE
]

const DEFAULT_PERSON_REGISTRATION_STATUS = 1

export function personSaveStatusReducer(state, action, personDependencies) {
  let { loading, error, person } = state
  const { payload = {}, type, meta } = action
  const { loadingBlockFlag = true } = payload

  const hasSuccess = type === PERSON_SAVE_STATUS_SUCCESS
  const hasError = type === PERSON_SAVE_STATUS_ERROR
  const isLoading = type === PERSON_SAVE_STATUS_LOADING
  const savedOnState = type === PERSON_SAVE_STATUS_STATE

  if (hasSuccess) {
    const {
      previousAction: {
        payload: { reviewStep }
      }
    } = meta

    person = new Person(
      { ...person, ...payload.data, reviewStep },
      personDependencies
    )
    loading = false
    error = handleError(type, error, payload.data)
  }

  if (hasError) {
    const {
      meta: {
        previousAction: {
          payload: {
            request: { data }
          }
        }
      }
    } = action

    person = new Person({ ...person, ...data }, personDependencies)
    loading = false
    error = new Error(action.error)

  }

  if (savedOnState) {
    person = new Person({ ...person, ...payload.data }, personDependencies)
  }

  return {
    ...state,
    loading: (isLoading || loading) && !loadingBlockFlag,
    error,
    person
  }
}

function handleError(type, error, payloadData){
  if(
    type === PERSON_SAVE_STATUS_SUCCESS &&
    payloadData.status &&
    payloadData.status === DEFAULT_PERSON_REGISTRATION_STATUS &&
    payloadData.substatus &&
    payloadData.substatus === SUBSTATUS.EMAIL_EXISTENT
  ){
    return new Error({ code: EMAIL_ALREADY_EXISTS_CODE })
  }

  return defineError(type, error, payloadData)
}

function defineError(type, error, params) {
  const { 0: { code, details, message } = {} } = params

  if (
    code &&
    type === PERSON_SAVE_STATUS_SUCCESS &&
    ERROR_CODES_TO_INTERCEPT.includes(code)
  ) {
    return new Error({ response: [`${details}`], title: message })
  }

  return type === PERSON_SAVE_STATUS_ERROR && new Error(error)
}

export function savePersonStatus(person, data, loadingBlockFlag = false) {
  const globalAppContext = getContext()
  const { dispatch } = globalAppContext
  const { personId } = person

  if (!person.isNewProspect && !person.hasMainRoleDraft) {
    return
  }

  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext, personData: person })

  return dispatch({
    types,
    payload: {
      loadingBlockFlag,
      client: clientName,
      personLocal: {
        shouldUpdate: true,
      },
      request: {
        method: 'PATCH',
        url: requestUrl,
        headers: requestHeaders,
      }
    }
  })

  function getRequestPayload({ globalAppContext, personData }) {
    const {
      SAVE_PERSON_SUBSTATUS_URL,
      UPDATE_REGISTRATION_SUBSTATUS_URL,
      bffHeaders,
      countryId,
      companyId,
      headersAuthenticationInfo,
      sellerId,
      shouldUpdateRegistrationSubstatusFromBff
    } = getNeededInformationFromGlobalContext({ globalAppContext })
    const clientName = shouldUpdateRegistrationSubstatusFromBff ? CLIENT_NAMES.DEFAULT_BFF : countryId
    const { requestHeaders } = getRequestHeaders({ personData, shouldUpdateRegistrationSubstatusFromBff, headersAuthenticationInfo, bffHeaders, sellerId })

    const requestUrl = shouldUpdateRegistrationSubstatusFromBff ? UPDATE_REGISTRATION_SUBSTATUS_URL : SAVE_PERSON_SUBSTATUS_URL

    return {
      clientName,
      requestUrl,
      requestHeaders
    }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { SAVE_PERSON_STATUS },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { UPDATE_REGISTRATION_SUBSTATUS },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const shouldUpdateRegistrationSubstatusFromBff = configs?.localization?.shouldUpdateRegistrationSubstatusFromBff

      return {
        SAVE_PERSON_SUBSTATUS_URL: SAVE_PERSON_STATUS(personId, data.substatus),
        UPDATE_REGISTRATION_SUBSTATUS_URL: UPDATE_REGISTRATION_SUBSTATUS({ personId, personSubstatus: data.substatus }),
        shouldUpdateRegistrationSubstatusFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ personData, shouldUpdateRegistrationSubstatusFromBff, headersAuthenticationInfo, bffHeaders, sellerId }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo
      const {
        businessModel: {
          businessModelId,
          functionId,
          roleId
        }
      } = personData
      const ConsultantBusinessModel = getBusinessModelByProfile(BEAUTY_CONSULTANT_PROFILE)
      const requestHeaders = shouldUpdateRegistrationSubstatusFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        businessmodel: businessModelId || ConsultantBusinessModel.businessModelId,
        functionid: functionId || ConsultantBusinessModel.functionId,
        role: roleId || ConsultantBusinessModel.roleId,
        sourcesystem: '7',
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        businessModel: businessModelId || ConsultantBusinessModel.businessModelId,
        function: functionId || ConsultantBusinessModel.functionId,
        role: roleId || ConsultantBusinessModel.roleId,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }
}

export function saveBFFPersonStatus(person, data, loadingBlockFlag = false) {
  const {
    dispatch,
    bff_urls: { SAVE_PERSON_STATUS },
    locale: {
      id,
    },
    user: {
      sellerId,
      authentication: { xApikey, accessToken }
    }
  } = getContext()

  const {
    personId,
    businessModel: { businessModelId, functionId, roleId }
  } = person

  return dispatch({
    types,
    payload: {
      loadingBlockFlag,
      client: id,
      personLocal: {
        shouldUpdate: true,
      },
      request: {
        method: 'PATCH',
        url: SAVE_PERSON_STATUS(personId, data.substatus),
        headers: {
          'x-api-key': xApikey,
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
          businessModel: businessModelId,
          function: functionId,
          role: roleId
        },
      }
    }
  })
}

