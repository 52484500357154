import * as yup from 'yup'
import { Entity, Collection } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const PAGE_SIZE = 20

const defaultPagination = {
  _page: 0,
  _offset: 0,
  _limit: PAGE_SIZE
}

const defaultQueryParams = {
  queryParams: {
    pagination: defaultPagination
  }
}

export const CONSULTANTS = {
  CODE: 'code',
  NAME: 'name'
}

class Consultant extends Entity {
  static SCHEMA = {
    sellerId: adapter(yup.number()),
    code: adapter(yup.number()),
    naturaCode: adapter(yup.number()),
    name: adapter(yup.string())
  }

  get candidateId() {
    return this.naturaCode || this.code
  }

  get id() {
    return this.sellerId || this.candidateId
  }
}

export default class Consultants extends Collection {
  static TYPE = Consultant

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)


    const { queryParams } = { ...defaultQueryParams, ...injection }

    this.queryParams = queryParams

    this.reset = this.reset.bind(this)
    this.getMore = this.getMore.bind(this)
    this.searchById = this.searchById.bind(this)
    this.searchByName = this.searchByName.bind(this)
    this.searchByDocuments = this.searchByDocuments.bind(this)
    this.indicationVerify = this.indicationVerify.bind(this)
  }

  search(data) {
    if (data.type === CONSULTANTS.CODE)
      return this.searchById(data)

    if (data.type === CONSULTANTS.NAME)
      return this.searchByName(data)

    return this.searchByDocuments(data)
  }

  searchById({ personId, search }) {
    return this.consultantsById({ personId, sellerId: search })
  }

  searchByDocuments({ personId, search, type }) {
    return this.consultantsByDocuments({ personId, documentTypeId: type, sellerDocument: search })
  }

  searchByName({ personId, search }) {
    return this.consultantsByName({
      queryString: this.encodedPagination,
      queryParams: this.queryParams,
      name: search,
      personId
    })
  }

  reset() {
    return this.resetConsultants()
  }

  indicationVerify(data) {
    return this.consultantsIndicationVerify(data)
  }

  getMore(data) {
    this.nextPage()

    return this.searchByName(data)
  }

  nextPage() {
    const { pagination: { _page }} = this.queryParams

    this.setPage(_page + 1)
  }

  setPage(page) {
    const { pagination: { _limit }} = this.queryParams

    const newQueryParams = {
      pagination: {
        _page: page,
        _offset: page * _limit,
        _limit
      }
    }

    this.queryParams = newQueryParams
  }

  encodeParams(params) {
    return Object.entries(params)
      .reduce(encodeParams, [])
      .join('&')
  }

  get encodedPagination() {
    const { pagination: { _offset, _limit }} = this.queryParams

    return this.encodeParams({ _offset, _limit })
  }
}

function encodeParams(accum, [key, value]) {
  if(value === null || value === undefined) return accum

  const encodedValue = encodeURIComponent(value)
  const encodedKey = encodeURIComponent(key)

  return [ ...accum, `${encodedKey}=${encodedValue}` ]
}
