import * as yup from 'yup'
import { Entity, Collection } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

class RegisterStepSchema extends Entity {
  static SCHEMA = {
    name: adapter(yup.string().required()),
    title: adapter(yup.string().nullable()),
    label: adapter(yup.string().nullable()),
    subtitle: adapter(yup.array().nullable()),
    path: adapter(yup.string().required()),
    route: adapter(yup.string().required()),
    updateRule: adapter(yup.string().nullable()),
    businessModel: adapter(yup.object().nullable()),
    components: adapter(yup.array().required()),
    shouldRequestPerson: {
      validator: adapter(yup.bool().required()),
      defaultValue: true
    },
    componentsProps: {
      validator: adapter(yup.object().required()),
      defaultValue: {}
    },
    componentsPropsBuilder: {
      validator: adapter(yup.mixed().required()),
      defaultValue: function() { return {} }
    },
    displayOnStepper: {
      validator: adapter(yup.bool().required()),
      defaultValue: false
    },
    currentStep: {
      validator: adapter(yup.number().required()),
      defaultValue: 0
    },
    completed: {
      validator: adapter(yup.number().required()),
      defaultValue: 0
    },
    paper: {
      validator: adapter(yup.bool()),
      defaultValue: false
    },
    actions: {
      validator: adapter(yup.object().shape({
        previous: yup.mixed().nullable(),
        next: yup.mixed().nullable()
      }).required()),
      defaultValue: {
        previous: null,
        next: null
      }
    }
  }

  get isSelector() {
    const { routes } = this
    return !!routes && routes.length > 0
  }
}

export default class RegisterSchema extends Collection {
  static TYPE = RegisterStepSchema

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)

    this.firstStepPath = this.steps.length ? this.steps[0].path : '/'
    this.stepsToDisplay = this.result().reduce(reduceStepsToDisplay, [])
    this.steps.map(mapStepsToChildrens, this)
  }

  get steps() {
    return this.items
  }
}

function reduceStepsToDisplay(accum, { displayOnStepper, label, path, action }) {
  if(!displayOnStepper) return accum

  return [ ...accum, { label, path, action }]
}

function mapStepsToChildrens(step) {
  const { stepsToDisplay } = this

  Object.assign(step, {
    steps: stepsToDisplay
  })

  return step
}
