import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import { getFileSubtitle, getFileTitles } from './Messages'

export const PASSPORT_ID = 10

const CARD_FRONT_ID = 'identificationCardFront'
const CARD_BACK_ID = 'identificationCardBack'

const DISABLED_BY = [CARD_FRONT_ID, CARD_BACK_ID]

export const touchAfterReload = [CARD_FRONT_ID, CARD_BACK_ID, 'proofOfAddress']

export function buildFormSchema(dependencies) {
  const { intl, user } = dependencies
  const autoFocus = { autoFocus: true }
  const { fileBack, fileFront } = getFileTitles(dependencies)
  const { fileSubtitle } = getFileSubtitle(dependencies)

  const disabledBy = !user.isCSC ? DISABLED_BY : []
  const required = !user.isCSC

  const IDENTIFICATION_CARD_FRONT_LABEL = TYPES.IDENTIFICATION_CARD_FRONT_LABEL(
    intl,
    dependencies
  )
  const IDENTIFICATION_CARD_FRONT = TYPES.IDENTIFICATION_CARD_OCR(intl, {
    ...dependencies,
    autoFocus,
    required,
    title: fileFront,
    subtitle: fileSubtitle,
    cardId: CARD_FRONT_ID
  })
  const IDENTIFICATION_CARD_BACK = TYPES.IDENTIFICATION_CARD_OCR(intl, {
    ...dependencies,
    required,
    title: fileBack,
    subtitle: fileSubtitle,
    cardId: CARD_BACK_ID
  })
  const OCR = TYPES.OCR(intl, dependencies)
  const PROOF_OF_ADDRESS = commonParams(
    TYPES.PROOF_OF_ADDRESS(intl, {
      ...dependencies,
      required,
      title: fileFront
    }),
    disabledBy
  )
  const TERM_AND_CONDITIONS = commonParams(
    TYPES.TERM_AND_CONDITIONS(intl, { ...dependencies, required }),
    disabledBy
  )
  const SELF = commonParams(
    TYPES.SELF(intl, { ...dependencies, required }),
    disabledBy
  )
  const TERM_AND_CONDITIONS_OBSERVATION = commonParams(
    TYPES.TERM_AND_CONDITIONS_OBSERVATION(intl, { ...dependencies, required }),
    disabledBy
  )

  const fieldsSchema = [
    [IDENTIFICATION_CARD_FRONT_LABEL],
    [IDENTIFICATION_CARD_FRONT],
    [IDENTIFICATION_CARD_BACK],
    [OCR],
    [PROOF_OF_ADDRESS],
    [TERM_AND_CONDITIONS],
    [TERM_AND_CONDITIONS_OBSERVATION],
    [SELF]
  ]

  return new FormSchema({ fieldsSchema, name: 'Documents' })
}

function commonParams(field, disabledBy = DISABLED_BY) {
  return {
    ...field,
    disabledBy
  }
}
