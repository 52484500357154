import { defineMessages } from 'react-intl'
import { ACCEPT_IMAGE_FORMATS } from 'src/viewModels/Upload/Upload.formats'
import { CONFIGS } from 'src/models/Attachment'
import { defineDefaultValue } from 'src/configs/Fields/Attachments/Helpers'
import { validationSchema } from 'src/configs/Fields/Attachments/validations'

const FIELD_ID = 'proofOfAddress'
const messages = defineMessages({
  proofOfAddress: {
    defaultMessage: 'Proof of Address',
    id: 'form.field.proofOfAddress.label'
  },
  proofOfAddress_sublabel: {
    defaultMessage:
      'The voucher must be coincident with the residential address indicated and must not be greater than 30 days of issuance.',
    id: 'form.field.proofOfAddress.sublabel'
  }
})
const dependencies = { messages }

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const {
    locale: { id: localeId },
    person: { attachments },
    title,
    subtitle,
    required
  } = injection

  const attachmentsIds = CONFIGS[localeId]
  const TYPE = attachmentsIds[FIELD_ID]
  const defaultValue = defineDefaultValue(attachments, TYPE)

  return {
    id: FIELD_ID,
    type: 'file',
    defaultValue,
    success: true,
    label: intl.formatMessage(messages[FIELD_ID]),
    sublabel: intl.formatMessage(messages[`${FIELD_ID}_sublabel`]),
    validations: validationSchema({
      intl,
      validFormats: ACCEPT_IMAGE_FORMATS,
      required
    }),
    accept: ACCEPT_IMAGE_FORMATS.join(' '),
    customProps: {
      type: TYPE,
      icon: 'photo',
      title,
      subtitle,
      doUpload: true
    }
  }
}
