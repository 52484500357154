import { getDocumentsFields } from './strategies/getDocumentsFields'

export default function Builder(params) {
  const { intl, messages, person, locale } = params
  const { documents = [], nationalityId, attachments = [] } = person
  const { id: localeId } = locale
  const documentsFields = getDocumentsFields({ localeId, intl, messages, nationalityId, documents, attachments })

  return {
    id: 'profile-documents',
    title: intl.formatMessage(messages.box_documents_title),
    wrapper: 'default',
    content: 'list',
    fields: documentsFields
  }
}
