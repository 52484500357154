import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PROFILE_APPROVATION_LOADING = 'natura-caduni/People/PROFILE_APPROVATION_LOADING'
export const PROFILE_APPROVATION_SUCCESS = 'natura-caduni/People/PROFILE_APPROVATION_SUCCESS'
export const PROFILE_APPROVATION_ERROR = 'natura-caduni/People/PROFILE_APPROVATION_ERROR'

const TYPES_APPROVATION = [PROFILE_APPROVATION_LOADING, PROFILE_APPROVATION_SUCCESS, PROFILE_APPROVATION_ERROR]

export function approvePerson(personId, personRoleId) {
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  const sourceSystem = 7
  const { clientName, requestUrl, requestHeaders } = getRequestPayload({ globalAppContext })

  return dispatch({
    types: TYPES_APPROVATION,
    payload: {
      client: clientName,
      request: {
        method: 'PATCH',
        url: requestUrl,
        headers: requestHeaders,
      }
    }
  })

  function getRequestPayload({ globalAppContext, businessModel }) {
    const {
      APPROVATION,
      APPROVATION_BFF,
      bffHeaders,
      headersAuthenticationInfo,
      shouldApproveRoleFromBff,
      countryId,
      companyId,
      sellerId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const { requestHeaders } = getRequestHeaders({ shouldApproveRoleFromBff, headersAuthenticationInfo, businessModel })

    const requestUrl = shouldApproveRoleFromBff ? APPROVATION_BFF : APPROVATION

    const clientName = shouldApproveRoleFromBff
      ? CLIENT_NAMES.DEFAULT_BFF
      : countryId

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }){
      const {
        urls: { APPROVATION },
        default_bff_urls: { APPROVATION_BFF },
        default_bff_client: { headers: bffHeaders },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          tenantId,
          configs
        },
        user: {
          authentication: {
            xApikey,
            accessToken
          },
          sellerId,
        },
      } = globalAppContext

      const shouldApproveRoleFromBff = configs?.localization?.isHostedUIEnabled

      return {
        APPROVATION: APPROVATION(personId, personRoleId),
        APPROVATION_BFF: APPROVATION_BFF(personId, personRoleId),
        shouldApproveRoleFromBff,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        countryId,
        companyId,
        tenantId,
        sellerId,
      }
    }

    function getRequestHeaders({ shouldApproveRoleFromBff, headersAuthenticationInfo }){
      const { globalPeoplePlatformApiKey, registersBffApiKey, userAccessToken } = headersAuthenticationInfo

      const requestHeaders = shouldApproveRoleFromBff ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        userId: sellerId,
        sourcesystem: sourceSystem,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        userId: sellerId,
        countryId,
      }

      return { requestHeaders }
    }
  }
}
