export const ROLES = {
  CSC: {
    roleId: 0,
    functionId: 0,
    businessModelId: 0
  },
  HOC: {
    roleId: 6,
    functionId: 21
  },
  BM: {
    roleId: 6,
    functionId: 17
  },
  CN: {
    roleId: 1,
    functionId: 1
  }
}
