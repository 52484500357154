import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'
import { getFieldsSchema } from './strategies/getFieldsSchema'

export const touchAfterReload = ['emails[0].email']

export function buildFormSchema(dependencies) {
  const {
    intl,
    locale: { id: localeId }
  } = dependencies
  const GENDER = commonParams(TYPES.GENDER(intl, dependencies))
  const BIRTHDAY = commonParams(TYPES.BIRTHDAY(intl, dependencies))
  const NATIONALITY = commonParams(TYPES.NATIONALITY(intl, dependencies))

  const defaultFieldsSchema = [[BIRTHDAY, GENDER], [NATIONALITY]]
  const additionalFieldsSchema = getFieldsSchema(localeId, {
    intl,
    dependencies
  })
  const fieldsSchema = [...defaultFieldsSchema, ...additionalFieldsSchema]

  return new FormSchema({ fieldsSchema, name: 'PersonalInfo' })
}

function commonParams(field, required = true) {
  return {
    ...field,
    required
  }
}
