import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

import ListItemSchema from 'src/viewModels/ListSchema/ListItemSchema'
import ListItemOfflineSchema from 'src/viewModels/ListSchema/ListItemOfflineSchema'
import ListFiltersSchema from 'src/viewModels/ListSchema/ListFiltersSchema'
import FormSchema from 'src/base/Form/Schemas'

export const TYPES = {
  AVATAR: 'AVATAR',
  ELLAPSED_TIME: 'ELLAPSED_TIME',
  BUTTON: 'BUTTON'
}

class ListSchema extends Entity {
  static SCHEMA = {
    items: {
      validator: adapter(yup.array().of(yup.object())),
      type: ListItemSchema,
      defaultValue: []
    },
    itemsOffline: {
      validator: adapter(yup.array().of(yup.object())),
      type: ListItemOfflineSchema,
      defaultValue: []
    },
    filters: {
      validator: adapter(yup.array().of(yup.object())),
      type: ListFiltersSchema,
      defaultValue: []
    },
    search: {
      validator: adapter(yup.array().of(yup.object())),
      builder({ fieldsSchema, onSubmit, touchAfterReload }) {
        return {
          formSchema: new FormSchema({ fieldsSchema, name: 'ListSearch' }),
          onSubmit,
          touchAfterReload
        }
      },
      defaultValue: {}
    }
  }
}

export default ListSchema
