
export function buildPreRegisterConfirmationPropsMicrofrontend(deps) {
  const { locale: { id, tenantId, companyId }, person } = deps
  const {
    personId,
  } = person

  return {
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'preRegisterConfirmation',
      identityInfo: {
        countryId: id,
        tenantId,
        companyId,
        personId,
      },
    }
  }
}
