import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

class Language extends Entity {
  static SCHEMA = {
    language: adapter(yup.string().required()),
    fallbackLanguage: adapter(yup.string())
  }
}

export default Language
