import React from 'react'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  button_add: {
    defaultMessage: 'Add',
    id: 'form.field.button.add'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { actions } = { ...dependencies, ...injection }

  const label = intl.formatMessage(messages['button_add'])

  const node = (
    <button
      {...actions}
      className="form__group__button form__group__button--add"
      data-testid="button_add"
      title={label}>
      <i className="form__group__button__icon" />
      {label}
    </button>
  )

  return {
    node
  }
}
