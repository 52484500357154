import React, { Fragment } from 'react'
import { PHONE_TYPES } from 'src/models/Phone'
import ContactValidation from 'src/base/ContactValidation'

const LINE_REGEX = /(\d{1,2})(\d{1,3})(\d{1,4})/
const MOBILE_REGEX = /(\d{1,3})(\d{1,3})(\d{1,3})/

export default function Builder(params) {
  const { intl, messages, person, connectivity: { isOffline }, locale: { configs: { localization }} } = params
  const { phones = [], emails = [], roles } = person
  const { profile: { shouldRenderContactValidation } } = localization

  const phoneFields = phones.reduce(createPhoneField, [params])
  const [email] = emails

  return {
    id: 'profile-contact',
    title: intl.formatMessage(messages.box_contact_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      ...phoneFields,
      {
        label: intl.formatMessage(messages.box_contact_label_email),
        field: (
          <Fragment>
            {email && email.email || '-'}
            {!isOffline && shouldRenderContactValidation && <ContactValidation email={email} substatus={roles.cn} />}
          </Fragment>
        )
      }
    ]
  }
}

export const phoneEnum = {
  [PHONE_TYPES.LINE]: 'box_contact_label_phone',
  [PHONE_TYPES.MOBILE]: 'box_contact_label_mobile'
}

export function createPhoneField(accum, phoneObj, index, array) {
  const { areaCode = '', phone, phoneNumber: number = '', type, sequence, verified, isLine } = phoneObj
  const [{ connectivity: { isOffline }, intl, messages, person: { roles }, locale: { configs: { localization }} }] = accum
  const { profile: { shouldRenderContactValidation } } = localization

  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!phoneEnum[type]) return accum

  const FORMAT_DIGITS = type === PHONE_TYPES.LINE ? LINE_REGEX : MOBILE_REGEX
  const formatedPhone = `${areaCode}${number}`.replace(FORMAT_DIGITS, '$1 $2 $3')

  const params = {
    phone,
    number,
    sequence,
    verified,
    isLine
  }

  const component = {
    label: intl.formatMessage(messages[phoneEnum[type]]),
    field: (
      <Fragment>
        {formatedPhone}
        {!isOffline && shouldRenderContactValidation && <ContactValidation phone={params} substatus={roles.cn} />}
      </Fragment>
    )
  }

  return [...accum, component]
}
