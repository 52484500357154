import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const PAYMENT_PLANS = {
  CREDIT_PAYMENT: 1,
  PRE_PAID_PAYMENT: 2,
  REGISTRATION_DENIED: 3,
}

export default class PaymentPlan extends Entity {
  static SCHEMA = {
    createdAt: adapter(yup.date().required()),
    creditLine: adapter(yup.number().required()),
    description: adapter(yup.string().required()),
    id: adapter(yup.number().required()),
    score: adapter(yup.number().required())
  }

  get hasPlan() {
    return !!this.id
  }

  get isDenied() {
    return this.id === PAYMENT_PLANS.REGISTRATION_DENIED
  }

  get isPrePaid() {
    return this.id === PAYMENT_PLANS.PRE_PAID_PAYMENT
  }

  get isCredit() {
    return this.id === PAYMENT_PLANS.CREDIT_PAYMENT
  }
}
