import React from 'react'
import PropTypes from 'prop-types'

import './StrongDarkText.styl'

const StrongDarkText = ({ children }) => (<strong className={'strong-dark-text'}>{children}</strong>)

StrongDarkText.propTypes = {
  children: PropTypes.any
}

export default StrongDarkText
