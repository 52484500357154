import { DOCUMENTS } from 'src/models/Document'

export const createDocumentTypeOptions = ({ intl, messages, candidateId }) => {
  return [
    {
      value: DOCUMENTS.MYKAD,
      label: intl.formatMessage(messages['type_mykad']),
    },
    {
      value: candidateId,
      label: intl.formatMessage(messages['type_candidate']),
    }
  ]
}
