import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'

import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'level4',
    label: intl.formatMessage(messages['level4']),
    placeholder: intl.formatMessage(messages['level4_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['level_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChange(intl),
    required: true,
    disabledBy: ['address[0].level3'],
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['level4']),
      value: label
    }
  })
}

const onChange = (intl) => {
  return (values) => {
    pushDataLayer(values, intl)
  }
}

export function getLevel4Options({ selectedCityId, getLevels }) {
  if (!selectedCityId) return

  getLevels({
    queryParams: `level=4&parentCode=${selectedCityId}`,
    geoStructureType: 'communes',
    geoStructureCode: selectedCityId
  })
}

const called = {}

function mapStateToProps({ addresses }, ownProps) {
  const {
    addressesPeopleManagement: { communes: level4Options, getLevels }, //regions
    loading,
  } = addresses

  const {
    group: { index },
    form: {
      values: { address }
    },
    onChange
  } = ownProps
  const { level3 } = address[index] || {}
  const options = level4Options[level3] || []

  const shouldGetLevel4Options = !options.length && level3 && !loading.communes?.[level3]

  if (shouldGetLevel4Options && !called[level3]) {
    called[level3] = true

    getLevel4Options({ selectedCityId: level3, getLevels})
  }

  return {
    ...ownProps,
    onChange,
    options: options || [],
    loading: Boolean(level3 && loading.communes && loading.communes[level3])
  }
}
