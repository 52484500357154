import { defineMessages } from 'react-intl'
import { checkSpecialCharacters, lettersOnly, lettersOnlyPaste } from './Name.full'

const messages = defineMessages({
  firstName: {
    defaultMessage: 'Name(s)',
    id: 'form.field.firstName'
  },
  firstNameSpecialCharacters: {
    defaultMessage: 'Special characters are not allowed',
    id: 'form.field.first.name.special.characters'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { firstName: defaultValue }} = injection

  const messageSpecialCharacters = intl.formatMessage(messages['firstNameSpecialCharacters'])

  return {
    id: 'firstName',
    defaultValue,
    label: intl.formatMessage(messages['firstName']),
    onKeyDown: lettersOnly,
    onPaste: lettersOnlyPaste,
    validations: checkSpecialCharacters(messageSpecialCharacters),
  }
}
