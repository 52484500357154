import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'
import { documentsEnum } from '../../../../../Fields/Documents/Documents.type'
import { store } from 'src/App.state'

export function buildDocumentsProps(deps) {
  const { locale: { tenantId, id }, loader: { personLoading, resendLoading }, person } = deps
  const { user: { user } } = store.getState()
  const { 
    documents,
    personId,
    businessModel: { businessModelId, functionId, roleId }
  } = person
  let documentType

  if (documents.length) {
    const { type } = documents[0]
    documentType = documentsEnum[type]
  }

  const { title, subtitle, label } = getSectionTitle(deps, documentType)
  const onSubmit = onSubmition(deps)

  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    onSubmit,
    disabledSubmit: true,
    step: {
      title,
      subtitle,
      label
    },
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'attachments',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId: 1,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
      },
      authenticatedUser: {
        accessToken: user.accessToken,
      },
      onSubmit
    },
    loader: personLoading || resendLoading
  }
}
