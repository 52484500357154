import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const RNEIcon = ({ size, state }) => {
  const styles = {
    st0: {
      fill: StateColors[state]
    },
    st2: {
      fill: StateColors[state],
      opacity: 0.2
    }
  }

  return (
    <svg width={size} height={size} viewBox="0 0 445 445.73">
      <path
        style={styles.st0}
        d="M75.23,97.26l329.12,34.59.2-4.91L75.74,92.38a11,11,0,0,0-12.12,9.82L47.93,251.53,45.49,311.3a6.21,6.21,0,0,0,.75,3.18L68.5,102.71A6.12,6.12,0,0,1,75.23,97.26Z"
      />
      <path
        style={styles.st0}
        d="M412.89,127.81l-3.45-.36-.2,4.91,3.14.33a6.13,6.13,0,0,1,5.45,6.73l-14.5,138-2,48.39a11,11,0,0,1-4,8l-2.82,26.86a6.13,6.13,0,0,1-6.73,5.45L50.7,330.69A6.12,6.12,0,0,1,45.25,324l.33-3.19a11.14,11.14,0,0,1-4.35-5.51l-.86,8.18a11,11,0,0,0,9.82,12.12L387.34,371a11,11,0,0,0,12.11-9.81L422.7,139.93A11,11,0,0,0,412.89,127.81Z"
      />
      <g style={styles.st1}>
        <path
          style={styles.st0}
          d="M72.18,94.47,409,129.87l.25-6.15a11.14,11.14,0,0,0-3.52-1L68.61,87.31a11,11,0,0,0-12.12,9.81L45.32,203.39q-1.05,10.1-1.47,20.25L40.6,311.1l.63,4.16c-.12,2.94-.21,6.37,1.58,8.42L66.41,99.15A5.25,5.25,0,0,1,72.18,94.47Z"
        />
      </g>
      <path
        style={styles.st0}
        d="M146.35,136.69l.06-.49a5.42,5.42,0,0,1,6-4.83l241.91,25.51a5.44,5.44,0,0,1,4.84,6l-.06.49a5.42,5.42,0,0,1-6,4.83L151.19,142.66A5.44,5.44,0,0,1,146.35,136.69Z"
      />
      <path
        style={styles.st0}
        d="M172.47,158.84l0-.37a4.07,4.07,0,0,1,4.49-3.63L358.8,174a4.07,4.07,0,0,1,3.63,4.49l0,.36a4.08,4.08,0,0,1-4.49,3.64L176.1,163.33A4.09,4.09,0,0,1,172.47,158.84Z"
      />
      <path
        style={styles.st0}
        d="M80.29,177.47l0-.37a4.07,4.07,0,0,1,4.49-3.63l42.43,4.39a4.09,4.09,0,0,1,3.64,4.49l0,.36a4.09,4.09,0,0,1-4.49,3.64L83.92,182A4.09,4.09,0,0,1,80.29,177.47Z"
      />
      <path
        style={styles.st0}
        d="M70.64,268.45l0-.37a4.07,4.07,0,0,1,4.49-3.63l42.43,4.39a4.09,4.09,0,0,1,3.64,4.49l0,.36a4.09,4.09,0,0,1-4.49,3.64l-42.44-4.39A4.09,4.09,0,0,1,70.64,268.45Z"
      />
      <path
        style={styles.st0}
        d="M67.59,297.26l0-.37a4.09,4.09,0,0,1,4.49-3.63l42.44,4.39a4.07,4.07,0,0,1,3.63,4.49l0,.36a4.08,4.08,0,0,1-4.49,3.64l-42.43-4.39A4.09,4.09,0,0,1,67.59,297.26Z"
      />
      <path
        style={styles.st0}
        d="M383.8,272.21a4.71,4.71,0,0,1-.53,0L77.94,239.8a5,5,0,0,1-4.43-5.49l3.68-34.71a5,5,0,0,1,5.48-4.44L388,227.55a5,5,0,0,1,4.44,5.48l-3.69,34.72a5,5,0,0,1-4.95,4.46ZM82.15,196.63a3.51,3.51,0,0,0-2.19.78,3.47,3.47,0,0,0-1.28,2.34L75,234.47a3.49,3.49,0,0,0,3.1,3.83l305.33,32.39a3.42,3.42,0,0,0,2.55-.76,3.47,3.47,0,0,0,1.28-2.34l3.68-34.72a3.48,3.48,0,0,0-3.1-3.83L82.52,196.65Z"
      />
      <path
        style={styles.st0}
        d="M293,206.17l-.56,0L220,198.45a5.23,5.23,0,0,1-2.74-9.28,5.24,5.24,0,0,1,3.84-1.14l72.45,7.68a5.24,5.24,0,0,1-.55,10.46ZM220.54,189.5a3.74,3.74,0,0,0-.4,7.46l72.45,7.68a3.68,3.68,0,0,0,2.74-.81,3.73,3.73,0,0,0-1.95-6.62l-72.45-7.69Z"
      />
      <path
        style={styles.st0}
        d="M387.6,216.43l-.57,0-53.58-5.69a5.35,5.35,0,1,1,1.13-10.64l53.58,5.68a5.36,5.36,0,0,1-.56,10.68Zm-.41-1.52a3.85,3.85,0,0,0,2.82-.84,3.86,3.86,0,0,0-2-6.83l-53.58-5.68a3.85,3.85,0,1,0-.82,7.66Z"
      />
      <circle style={styles.st0} cx="104.05" cy="139.07" r="19.58" />
      <path
        style={styles.st0}
        d="M202.13,287.44a5.07,5.07,0,0,1-.55,0l-66.94-7.1a5.18,5.18,0,1,1,1.09-10.3l66.94,7.1a5.19,5.19,0,0,1,4.61,5.7,5.21,5.21,0,0,1-5.15,4.63Zm-.39-1.52a3.67,3.67,0,0,0,3.24-6,3.62,3.62,0,0,0-2.47-1.35l-66.94-7.1a3.6,3.6,0,0,0-2.69.8,3.64,3.64,0,0,0-1.35,2.47,3.68,3.68,0,0,0,3.27,4.05Z"
      />
      <path
        style={styles.st0}
        d="M199.09,316.14a4.93,4.93,0,0,1-.55,0L131.6,309a5.18,5.18,0,1,1,1.09-10.3l66.94,7.1a5.19,5.19,0,0,1,4.6,5.7,5.19,5.19,0,0,1-5.14,4.63Zm-.4-1.52a3.68,3.68,0,0,0,.78-7.32l-66.94-7.1a3.68,3.68,0,1,0-.78,7.32Z"
      />
      <path
        style={styles.st0}
        d="M380,306.31a5.07,5.07,0,0,1-.55,0L245.32,292.05a5.18,5.18,0,1,1,1.09-10.3L380.55,296a5.18,5.18,0,0,1-.54,10.33ZM245.86,283.22a3.68,3.68,0,0,0-.38,7.34l134.14,14.23a3.68,3.68,0,0,0,.77-7.32L246.25,283.24Z"
      />
      <path
        style={styles.st0}
        d="M377,335l-.55,0L242.28,320.75a5.2,5.2,0,0,1-4.61-5.7,5.19,5.19,0,0,1,5.7-4.6l134.14,14.23A5.18,5.18,0,0,1,377,335Zm-.39-1.52a3.68,3.68,0,0,0,.78-7.32L243.21,311.94a3.68,3.68,0,0,0-3.25,6,3.64,3.64,0,0,0,2.47,1.35Z"
      />
      <path
        style={styles.st0}
        d="M399.85,92.06l-91.67-3.73a148.71,148.71,0,0,0-149.43-6.09l-97.63-4A11,11,0,0,0,49.66,88.82L40.6,311.1a11.06,11.06,0,0,0,.63,4.16l6.7-63.73L54.55,89a6.13,6.13,0,0,1,6.37-5.87L399.65,97a6.12,6.12,0,0,1,5.87,6.37l-1,23.61,4.89.51,1-23.92A11,11,0,0,0,399.85,92.06Z"
      />
      <path
        style={styles.st0}
        d="M412.89,127.81,75.74,92.38a11,11,0,0,0-12.12,9.82L40.37,323.44a11,11,0,0,0,9.82,12.12L166.67,347.8a149.05,149.05,0,0,0,93.61,9.84L387.34,371a11,11,0,0,0,12.11-9.81L422.7,139.93A11,11,0,0,0,412.89,127.81Zm4.94,11.61L394.58,360.67a6.13,6.13,0,0,1-6.73,5.45L50.7,330.69A6.12,6.12,0,0,1,45.25,324L68.5,102.71a6.12,6.12,0,0,1,6.73-5.45l337.15,35.43A6.13,6.13,0,0,1,417.83,139.42Z"
      />
      <rect
        style={styles.st0}
        x="10.98"
        y="80.9"
        width="19.58"
        height="4.87"
        rx="2.44"
        ry="2.44"
        transform="translate(5.38 -1.16) rotate(3.67)"
      />
      <rect
        style={styles.st0}
        x="22.93"
        y="54.34"
        width="19.58"
        height="4.87"
        rx="2.44"
        ry="2.44"
        transform="translate(53.74 -5.28) rotate(48.67)"
      />
      <rect
        style={styles.st0}
        x="50.15"
        y="44"
        width="19.58"
        height="4.87"
        rx="2.44"
        ry="2.44"
        transform="translate(110.12 -10.41) rotate(93.67)"
      />
    </svg>
  )
}

RNEIcon.propTypes = {
  size: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
}

export default RNEIcon
