
import { DOCUMENTS } from 'src/models/Document'
import {ATTACHMENT_TYPES} from '../../../configs/AttachmentTypes'

export default {
  [DOCUMENTS.NATIONAL_REGISTRY]: {
    identificationCardFront: ATTACHMENT_TYPES.DNI_PERU_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.DNI_PERU_BACK
  },
  [DOCUMENTS.FOREIGN_REGISTRY]: {
    identificationCardFront: ATTACHMENT_TYPES.CARNET_EXTRANJERIA_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.CARNET_EXTRANJERIA_BACK
  },
  [DOCUMENTS.PASSPORT_PERU]: {
    identificationCardFront: ATTACHMENT_TYPES.CARNET_EXTRANJERIA_FRONT,
    identificationCardBack: ATTACHMENT_TYPES.CARNET_EXTRANJERIA_BACK
  },
  proofOfAddress: ATTACHMENT_TYPES.COMPROBANTE_DOMICILIO,
  termAndConditions: ATTACHMENT_TYPES.TERMS_AND_CONDITIONS_ACCEPTANCE,
  self: ATTACHMENT_TYPES.SELFIE,
  proofEconomicLife: ATTACHMENT_TYPES.COMPROBANTE_ACTIVIDAD_ECONOMICA
}
