import React from 'react'
import PropTypes from 'prop-types'
import LinearProgressBar from '../../../../base/LinearProgressBar'
import { REGISTER_STATUS } from '../../RegisterStatus'

const LinearProgressStepper = ({ step: { name, completed }, registrationStatus }) => {

  const key = `${name}__stepper`

  const classNames = [
    key,
    completed === 100 && 'completed',
    registrationStatus === REGISTER_STATUS.COMPLETED_SUCCESS && 'success',
    registrationStatus === REGISTER_STATUS.PENDING && 'pending',
    registrationStatus === REGISTER_STATUS.COMPLETED_REJECTED && 'rejected',
  ]

  return (
    <LinearProgressBar
      className={classNames.join(' ')}
      key={key}
      completed={completed}
    />
  )

}

LinearProgressStepper.propTypes = {
  className: PropTypes.string,
  step: PropTypes.object.isRequired,
  registrationStatus: PropTypes.string.isRequired
}

export default LinearProgressStepper
