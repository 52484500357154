import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'
import { isEnableDigitalStoreStepForPeruFlowToggleActive } from '../../../../../../../config'
import { store } from '../../../../../../../App.state'

export const buildFlows = () => {

  const { user: { user } } = store.getState()
  const useAuthorizationCode = user.authenticated ? 'authorization-code' : null
  
  if (isEnableDigitalStoreStepForPeruFlowToggleActive()) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: [
        'pre-register',
        'personal-data',
        useAuthorizationCode,
        'address',
        'documentation',
        'documents',
        'indication',
        'digital-store',
        'completed'
      ].filter(step => step !== null)
    }
  }
 
  return {
    [BEAUTY_CONSULTANT_PROFILE]: [
      'pre-register',
      'personal-data',
      useAuthorizationCode,
      'address',
      'documentation',
      'documents',
      'indication',
      'completed'
    ].filter(step => step !== null)
  }

}
