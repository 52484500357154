import React from 'react'
import { defineMessages } from 'react-intl'
import bemClassName from 'bem-classname'

import Message from 'src/base/Form/Components/Message'

const messages = defineMessages({
  digital_space: {
    "id": "register.digital.space",
    "defaultMessage": "Digital Space"
  },
  description_have_a_unique_page: {
    "id": "register.digital.space.description.have.a.unique.page",
    "defaultMessage": "Have a unique page to sell over the internet and increase your earnings. You will take care of the disclosure, while Natura delivers and collects the products for you."
  },
  description_to_get_started: {
    "id": "register.digital.space.description.to.get.started",
    "defaultMessage":   "To get started, you need to choose a name. We suggest that you choose a simple address, easy to remember, and that your customers can recognize that it is you."
  },
  description_the_name_you_choose: {
    "id": "register.digital.space.description.the.name.you.choose",
    "defaultMessage": "The name you choose will be at the address you send to your customers, such as: "
  },
  description_so_lets_choose: {
    "id": "register.digital.space.description.so.lets.choose",
    "defaultMessage": "So, let's choose the name of your"
  }
})

const dependencies = {
  messages
}

const bem = bemClassName.bind(null, 'register__digital__space__url')

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const digitalSpace = intl.formatMessage(messages['digital_space'])
  const name = injection.user.name.split(' ').join('').toLowerCase()

  return {
    id: 'description',
    type: 'checkbox',
    node: (
      <div className={bem('container')}>
        <Message className={bem()} message={digitalSpace}/>
        <Message message={intl.formatMessage(messages['description_have_a_unique_page'])} />
        <Message message={intl.formatMessage(messages['description_to_get_started'])} />
        <Message
          message={intl.formatMessage(messages['description_the_name_you_choose'])}
          emphasis={`www.natura.com.br/${name}`}
          primary
          lineBreak
        />
        <Message
          message={intl.formatMessage(messages['description_so_lets_choose'])}
          emphasis={digitalSpace}
          complement='?'
          primary
        />
      </div>
    )
  }
}

