import RegisterSchema from 'src/viewModels/RegisterSchema'

import SectionTitle from 'src/views/Register/Components/SectionTitle'
import FormDefault from 'src/views/Register/Components/FormDefault'
import Completed from 'src/views/Register/Components/Completed'
import Payment from 'src/views/Register/Components/Payment'
import Loader from 'src/views/Register/Components/Loader'
import FormWithPersonExists from 'src/views/Register/Components/FormWithPersonExists'
import FormWithContactValidation from 'src/views/Register/Components/FormWithContactValidation'
import Attention from 'src/views/Register/Components/Attention'
import Blacklisted from 'src/views/Register/Components/Blacklisted'

import { buildPreRegister } from './PreRegister'
import { buildBusinessModel } from './BusinessModel'
import { buildPersonalData } from './PersonalData'
import { buildBasicPersonalData } from './BasicPersonalData'
import { buildConsultantPersonalData } from './ConsultantPersonalData'
import { buildAddress } from './Address'
import { buildDocuments } from './Documents'
import { buildCompleted } from './Completed'
import { buildPayment } from './Payment'

import { UPDATE_TYPES } from 'src/base/Form/Form.state'
import WrappedStepper from '../../../../../views/Register/Components/WrappedStepper'
import { BEAUTY_CONSULTANT_PROFILE } from '../../NaturaProfiles'

export function buildSteps({ basePath, businessModelParams }) {
  return [
    {
      name: 'pre-register',
      route: `${basePath}/pre-register`,
      path: `${basePath}/pre-register`,
      components: [SectionTitle, FormWithPersonExists, Loader],
      componentsPropsBuilder: buildPreRegister,
      displayOnStepper: false,
      shouldRequestPerson: false,
      actions: { next: true, previous: false }
    },
    {
      name: 'business-model',
      route: `${basePath}/business-model/:personId?`,
      path: `${basePath}/business-model`,
      components: [SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildBusinessModel,
      displayOnStepper: false,
      actions: { next: true, previous: true }
    },
    {
      name: 'personal-data',
      route: `${basePath}/1/1/1/personal-data/:personId`,
      path: `${basePath}/1/1/1/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 1
      },
      components: [WrappedStepper, SectionTitle, FormWithContactValidation, Loader, Blacklisted],
      componentsPropsBuilder: buildPersonalData,
      displayOnStepper: false,
      currentStep: 0,
      actions: { next: true, previous: false }
    },
    {
      name: 'big-consultant-personal-data',
      route: `${basePath}/1/1/10/personal-data/:personId`,
      path: `${basePath}/1/1/10/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 10
      },
      components: [WrappedStepper, SectionTitle, FormDefault, Loader, Blacklisted],
      componentsPropsBuilder: buildConsultantPersonalData,
      displayOnStepper: false,
      currentStep: 0,
      actions: { next: true, previous: false }
    },
    {
      name: 'entrepreneur-consultant-personal-data',
      route: `${basePath}/1/1/11/personal-data/:personId`,
      path: `${basePath}/1/1/11/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 11
      },
      components: [WrappedStepper, SectionTitle, FormDefault, Loader, Blacklisted],
      componentsPropsBuilder: buildConsultantPersonalData,
      displayOnStepper: false,
      currentStep: 0,
      actions: { next: true, previous: false }
    },
    {
      name: 'basic-personal-data',
      route: `${basePath}/${businessModelParams}/personal-data/:personId`,
      path: `${basePath}/${businessModelParams}/personal-data`,
      components: [WrappedStepper, SectionTitle, FormDefault, Loader, Blacklisted],
      componentsPropsBuilder: buildBasicPersonalData,
      updateRule: UPDATE_TYPES.FIELDS_ONLY,
      displayOnStepper: true,
      currentStep: 0,
      actions: { next: true, previous: false }
    },
    {
      name: 'address',
      route: `${basePath}/${businessModelParams}/complementary-data/:personId`,
      path: `${basePath}/${businessModelParams}/complementary-data`,
      components: [Attention, WrappedStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildAddress,
      displayOnStepper: true,
      currentStep: 1,
      actions: { next: true, previous: true }
    },
    {
      name: 'payment',
      route: `${basePath}/${businessModelParams}/payment/:personId`,
      path: `${basePath}/${businessModelParams}/payment`,
      components: [WrappedStepper, Payment, Loader],
      componentsPropsBuilder: buildPayment,
      displayOnStepper: false,
      currentStep: 2,
      actions: { next: true, previous: true }
    },
    {
      name: 'documents',
      route: `${basePath}/${businessModelParams}/documents/:personId`,
      path: `${basePath}/${businessModelParams}/documents`,
      components: [WrappedStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildDocuments,
      displayOnStepper: true,
      currentStep: 2,
      actions: { next: true, previous: true }
    },
    {
      name: 'completed',
      route: `${basePath}/${businessModelParams}/completed/:personId`,
      path: `${basePath}/${businessModelParams}/completed`,
      components: [WrappedStepper, SectionTitle, Completed, Loader],
      componentsPropsBuilder: buildCompleted,
      displayOnStepper: false,
      currentStep: 3,
      actions: { next: false, previous: true }
    }
  ]
}

export const getCSCFlowSteps = () => [
  'pre-register',
  'business-model',
  'personal-data',
  'address',
  'payment',
  'documents',
  'completed'
]

export const getFlowSteps = () => ({
  [BEAUTY_CONSULTANT_PROFILE]: getCSCFlowSteps(),
  '1/1/10': ['pre-register', 'business-model', 'big-consultant-personal-data', 'address', 'completed'],
  '1/1/11': ['pre-register', 'business-model', 'entrepreneur-consultant-personal-data', 'address', 'completed'],
  '*/*/*': ['pre-register', 'business-model', 'basic-personal-data', 'address', 'completed']
})

const salesForceFlows = () => ({
  '1/1/1': getCSCFlowSteps()
})

export function RegisterSchemaBuilder(deps) {
  const { flows, defaultFlow } = getFlows(deps)

  const list = buildSteps(deps.context)
  const dependencies = { ...deps, steps: list, flows, defaultFlow }
  const steps = list.map(buildStepProps, dependencies)

  return new RegisterSchema(steps, { flows, defaultFlow })
}

function buildStepProps(step) {
  const { componentsPropsBuilder } = step
  const { step: stepProps, ...rest } = componentsPropsBuilder(this)
  const componentsProps = { ...rest }

  Object.assign(step, {
    ...stepProps,
    componentsProps
  })

  return step
}

function getFlows({ user }) {
  if (user.isCSC) {
    return {
      flows: getFlowSteps(),
      defaultFlow: getFlowSteps()['*/*/*']
    }
  }

  return {
    flows: salesForceFlows(),
    defaultFlow: salesForceFlows()['1/1/1']
  }
}
