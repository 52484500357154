import Select from 'src/base/Form/Components/Select'
import { connect } from 'react-redux'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'department',
    label: intl.formatMessage(messages['department']),
    placeholder: intl.formatMessage(messages['department_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['department_no_options_message']),
    type: connect(mapStateToProps)(Select),
    required: true,
    onChange: validateFieldDependencies
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { parent, group: { id } } = schema

  setFieldValue(`${parent}.city`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.gmap`, {}, id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.complement`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.department`, false)
  setFieldTouched(`${parent}.city`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.gmap`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.complement`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getCityByDepartment({ value, getLevels }) {
  if (!value) return

  return getLevels({
    geoStructureType: 'cities',
    queryParams: `level=2&parentLevel=1&parentCode=${value}`,
    geoStructureCode: value
  })
}

const calleds = {}

function mapStateToProps({ addresses }, ownProps) {
  const { addressesPeopleManagement, loading: { departmentLoading } } = addresses
  const { departments, getLevels } = addressesPeopleManagement
  const { onChange, group: { index }, form: { values: { address } } } = ownProps
  const { countryName } = address[index]

  const options = departments[countryName] || []
  const loading = departmentLoading && departmentLoading[countryName]

  if (!options.length && !calleds[countryName]) {
    calleds[countryName] = true

    getLevels({ queryParams: 'level=1'})
  }

  return {
    ...ownProps,
    options,
    loading,
    onChange: function (value) {
      getCityByDepartment({ value, getLevels })
      onChange(value)
    }
  }
}
