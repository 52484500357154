import * as yup from 'yup'
import Group from '../../Group'
import Region from '../../State/State.select'
import ZipCode from '../ZipCode'
import Maps from '../Maps'
import Number from '../Number'
import Neighborhood from '../Neighborhood'
import Complement from '../Complement'
import References from '../References'
import City from '../City'
import { ADDRESS_USE } from 'src/models/Address/Address'

import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { addresses } = {} } = injection

  const baseValue = {
    copyAdress: true,
    countryName: 'BRASIL'
  }

  const disabled = true

  const defaultValue = parsedDefaultValues(addresses, baseValue)
  const TITLE_CONFIRM_ADDRESS = Group(intl.formatMessage(messages['address_confirm']))
  const ZIPCODE = { ...ZipCode(intl, injection) }
  const MAPS = { ...Maps(intl, injection), type: 'input', disabled }
  const NUMBER = { ...Number(intl, injection) }
  const COMPLEMENT = { ...Complement(intl, injection), message: '' }
  const NEIGHBORHOOD = { ...Neighborhood(intl, injection), disabled }
  const CITY = { ...City(intl, injection), disabled }
  const REGION_ADDRESS = { ...Region(intl, injection), disabled }
  const REFERENCES = { ...References(intl, injection), message: '' }

  return {
    id: 'address',
    min: 1,
    max: 2,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [TITLE_CONFIRM_ADDRESS],
      [ZIPCODE],
      [MAPS],
      [NUMBER, COMPLEMENT],
      [NEIGHBORHOOD],
      [CITY, REGION_ADDRESS],
      [REFERENCES]
    ],
    validations: yup.array().of(yup.object().shape({
      zipCode: yup.string().required(),
      gmap: yup.object().shape({
        street: yup.string().required().nullable(),
      }).required().nullable(),
      number: yup.string().required(),
      complement: yup.string().nullable(),
      neighborhood: yup.string().required(),
      city: yup.string().required(),
      region: yup.string().required(),
      references: yup.string().nullable()
    })).min(1).max(2)
  }
}

function parsedDefaultValues(values, baseValue) {
  if (values.length) {
    const copyAdress = values.length <= 1
    const addresses = getAddresses(values)

    return addresses.reduce(reduceAddress, [{ ...baseValue, copyAdress }])
  }

  return [baseValue]
}

function getAddresses(values) {
  const addresses = []

  const mailing = values.find(findAddress, { id: ADDRESS_USE.MAILLING })
  const delivery = values.find(findAddress, { id: ADDRESS_USE.DELIVERY })

  mailing && addresses.push(mailing)
  delivery && addresses.push(delivery)

  return addresses
}

function findAddress({ addressUse }) {
  return addressUse.some(({ id }) => id === this.id)
}

function reduceAddress(accum, address, index, array) {
  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    zipCode,
    number,
    complement,
    extraInfo,
    geographicalLevels
  } = address

  const { description: street } = geographicalLevels && geographicalLevels.find(({ type }) => type == "STREET")
  const { description: neighborhood } = geographicalLevels && geographicalLevels.find(({ type }) => type == "NEIGHBORHOOD")
  const { description: region } = geographicalLevels && geographicalLevels.find(({ type }) => type == "REGION")
  const { description: city } = geographicalLevels && geographicalLevels.find(({ type }) => type == "CITY")

  const newAddress = {
    zipCode,
    gmap: street,
    number,
    complement,
    neighborhood,
    city,
    region,
    references: extraInfo
  }

  return [...accum, newAddress]
}
