import { Messages } from './Messages'
import { buildFormSchema } from './FormSchema'
import { onSubmition } from './Submit'
import CreditModality from './CreditModality'

export function buildPayment(deps) {
  const messages = Messages
  const { person, locale: { configs }} = deps
  const creditModality = new CreditModality({ person, configs, messages })
  const { loader: { personLoading } } = deps

  return {
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    messages,
    creditModality,
    loader: personLoading
  }
}
