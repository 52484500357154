import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

export function Title({ children }) {
  return { ...children }
}

export function mapStateToProps({ title: { title } }, ownProps) {
  document.title = title.document

  return {
    ...ownProps,
    title
  }
}

export default withRouter(connect(mapStateToProps)(Title))
