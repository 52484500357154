import { getContext } from 'src/infra/Api/Api.context'
import People from 'src/models/People'

export const PEOPLE_RESET = 'natura-caduni/People/PEOPLE_RESET'
export const types = [PEOPLE_RESET]

export function peopleResetReducer(state, action, dependencies) {
  const { queryParams, queryString } = action.payload

  const deps = {
    ...dependencies,
    queryString,
    queryParams,
    hasMore: false
  }

  const people = new People([], deps)

  return {
    loading: false,
    error: false,
    people
  }
}

export function resetPeople({ queryString = '', queryParams = {} } = {}) {
  const { dispatch } = getContext()

  return dispatch({
    type: PEOPLE_RESET,
    payload: {
      queryString,
      queryParams
    }
  })
}
