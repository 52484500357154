import { TYPES } from 'src/viewModels/ListSchema'

export function createItemsSchema(dependencies) {
  const { intl, messages } = dependencies

  return [
    {
      label: intl.formatMessage(messages.fields_name),
      key: 'avatar',
      type: TYPES.AVATAR
    },
    {
      label: intl.formatMessage(messages.fields_code),
      key: 'cnCode'
    },
    {
      label: intl.formatMessage(messages.fields_registration_time),
      key: 'registrationTime',
      type: TYPES.ELLAPSED_TIME
    },
    {
      label: intl.formatMessage(messages.fields_situation),
      key: 'situationDescription'
    },
    {
      label: intl.formatMessage(messages.fields_group),
      key: 'group',
    }
  ]
}
