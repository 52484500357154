import { defineMessages } from 'react-intl'

export const Messages = defineMessages({
  title_analysis: {
    defaultMessage: 'In analysis',
    id: 'register.credit_modality.title.analysis'
  },
  title_credit: {
    defaultMessage: 'Credit',
    id: 'register.credit_modality.title.credit'
  },
  title_pre_paid: {
    defaultMessage: 'Pre-Paid',
    id: 'register.credit_modality.title.pre.paid'
  },
  title_attention: {
    defaultMessage: 'Attention',
    id: 'register.credit_modality.title.attention'
  },
  title_select_payment: {
    defaultMessage: 'Select the payment method',
    id: 'register.credit_modality.title.select.payment'
  },
  yes_accept_label: {
    defaultMessage: 'Yes, Accept',
    id: 'form.actions.yes.accept'
  },
  error_title: {
    defaultMessage: 'Error',
    id: 'register.complementary_data.error.title'
  }
})
