import { defineMessages } from 'react-intl'

const messages = defineMessages({
  address_line1: {
    defaultMessage: 'Street Address',
    id: 'form.field.addressLine1.label'
  },
  address_line1_placeholder: {
    defaultMessage: 'Insert your address here',
    id: 'form.field.addressline1.placeholder'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'streetAddressLine1',
    label: intl.formatMessage(messages['address_line1']),
    placeholder: intl.formatMessage(messages['address_line1_placeholder']),
    type: 'input',
    required: true,
    showAsterisk: false
  }
}
