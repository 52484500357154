import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { usePreventBrowserBackButton } from 'src/hooks/usePreventBrowserBackButton'
import { SUBSTATUS } from '../../../../models/Substatus'
import { STATUS } from '../../../../models/Status'
import Card from '../../../../base/Card'
import Success from './Success/Success'
import { REGISTER_STATUS } from '../../RegisterStatus'
import Pending from './Pending/Pending'
import Rejected from './Rejected/Rejected'
import { usePolling } from '../../../../hooks/usePolling'
import TagManager from 'react-gtm-module'
import { useGetDigitalStoreStatus } from './hooks/use-get-digital-store-status'

const CompletedWithStatus = (props) => {
  usePreventBrowserBackButton()

  const {
    intl,
    pollingTimeout = 90,
    pollingInterval = 4.5,
    person,
    messages,
    hasIndicationStep,
    match,
    peopleRegister,
    displayPendingInstructionMessage = false
  } = props
  const { isLoading: isLoadingDigitalStoreStatus, data: digitalStoreStatusData } = useGetDigitalStoreStatus({
    countryId: props.locale?.id,
    companyId: props.locale?.companyId,
    lang: props.locale?.locale?.replace('_', '-'),
    personIds: match.params.personId
  })
  const personIdFromUrl = match.params.personId

  const internalRegisterStatus = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    REJECTED: 'REJECTED',
    UNDEFINED: 'UNDEFINED'
  }

  const [registerStatus, setRegisterStatus] = useState(
    internalRegisterStatus.UNDEFINED
  )
  const [callComplete, setCallComplete] = useState(false)
  const [personDetails, setPersonDetails] = useState({})
  const [mainRole, setMainRole] = useState({})
  const SECONDS = 1000
  const INTERVAL_IN_SECONDS = pollingInterval
  const TIMEOUT_IN_SECONDS = pollingTimeout

  const isProspectRejected = ({ status }) => status === STATUS.REJECTED

  const isBeautyConsultant = ({ businessModelId, functionId, roleId }) =>
    businessModelId === 1 && functionId === 1 && roleId === 1

  const isProspectApproved = ({ status, substatus }) =>
    status === STATUS.APPROVED && substatus === SUBSTATUS.APPROVED_SELLER

  const shouldCallCompletedWorkflow = ({ status }) => status === STATUS.DRAFT || status === STATUS.PENDING || status === STATUS.APPROVED || status === STATUS.REJECTED

  function setPersonStatusAsPending() {
    if (registerStatus == internalRegisterStatus.UNDEFINED) {
      setRegisterStatus(internalRegisterStatus.PENDING)
      person.setRegistrationStatus(REGISTER_STATUS.PENDING)
      setDataLayer('in process')
    }
  }

  const { setStop } = usePolling({
    callback: () => fetchPerson(person),
    timeoutCallback: () => setPersonStatusAsPending(),
    delay: INTERVAL_IN_SECONDS * SECONDS,
    timeout: TIMEOUT_IN_SECONDS * SECONDS
  })

  useEffect(() => {
    if (registerStatus == internalRegisterStatus.UNDEFINED)
      person.setRegistrationStatus(REGISTER_STATUS.COMPLETED_LOADING)
  }, [registerStatus])

  useEffect(() => {
    if (callComplete) {
      person.complete()
    }
  }, [callComplete])

  useEffect(() => {
    if (isBeautyConsultant(mainRole) && isProspectApproved(mainRole)) {
      setStop(true)
      setRegisterStatus(internalRegisterStatus.SUCCESS)
      person.setRegistrationStatus(REGISTER_STATUS.COMPLETED_SUCCESS)
      setDataLayer('success')
    }

    if (isProspectRejected(mainRole)) {
      setStop(true)
      setRegisterStatus(internalRegisterStatus.REJECTED)
      person.setRegistrationStatus(REGISTER_STATUS.COMPLETED_REJECTED)
      setDataLayer('rejected')
    }

    if (shouldCallCompletedWorkflow(mainRole) && !callComplete) {
      setCallComplete(true)
    }
  }, [mainRole])

  const fetchPerson = (person) => {
    const personId = person.personId ? person.personId : personIdFromUrl

    if (!personId) {
      return false
    }

    return person.chainedGet(personId).then((result) => {
      if (result.payload) {
        setPersonDetails(result.payload.data?.person || result.payload.data)
        setMainRole(result.payload.data?.person?.roles[0] || result.payload.data.roles[0])
      }
    })
  }

  const setDataLayer = (status) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'registerStatus',
        status: status
      }
    })
  }

  if (registerStatus === internalRegisterStatus.UNDEFINED) return null

  const isSuccess = registerStatus === internalRegisterStatus.SUCCESS
  const isPending = registerStatus === internalRegisterStatus.PENDING
  const isRejected = registerStatus === internalRegisterStatus.REJECTED

  const findIntroducerSellerName = () => {
    const personId = person.personId ? person.personId : personIdFromUrl

    const peopleRegisterItem = peopleRegister.items.filter(
      (item) => personId === item.personId
    )

    if (peopleRegisterItem.length > 0) {
      return peopleRegisterItem[0].additionalInformation.introducerSellerName
    }

    return null
  }

  const { configs: { localization: { appStoreUrls, completedStep } = {} } } = props.locale

  return (
    <Card
      removeBackgroundForMobile={isPending || isRejected}
      isStatusScreen={true}
    >
      {isSuccess && (
        <Success
          paymentPlan={person.paymentPlan}
          messages={messages}
          personDetails={personDetails}
          country={props.locale.region}
          hasIndicationStep={hasIndicationStep}
          personId={props.person.personId}
          history={props.history}
          intl={intl}
          indicatorName={findIntroducerSellerName()}
          appStoreUrls={appStoreUrls}
          isLoadingDigitalStoreStatus={isLoadingDigitalStoreStatus}
          digitalStoreStatus={digitalStoreStatusData}
        />
      )}
      {isPending && (
        <Pending
          messages={messages}
          intl={intl}
          user={props.user}
          showInstructionMessage={displayPendingInstructionMessage}
        />
      )}
      {isRejected && <Rejected messages={messages} intl={intl} localizationConfig={completedStep.rejected} />}
    </Card>
  )
}

CompletedWithStatus.propTypes = {
  connectivity: PropTypes.object,
  displayPendingInstructionMessage: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hasIndicationStep: PropTypes.bool,
  history: PropTypes.object,
  loading: PropTypes.bool,
  locale: PropTypes.object,
  messages: PropTypes.object.isRequired,
  peopleRegister: PropTypes.object,
  personPayment: PropTypes.object,
  person: PropTypes.object.isRequired,
  match: PropTypes.object,
  registrationStatus: PropTypes.string.isRequired,
  statusInfo: PropTypes.object.isRequired,
  intl: PropTypes.object,
  pollingTimeout: PropTypes.number,
  pollingInterval: PropTypes.number,
  user: PropTypes.object
}

export default CompletedWithStatus
