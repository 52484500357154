import ADDRESS from './Address'
import ADDRESS_STRUCTURE_PER from './AddressStructure/AddressStructure.per'
import ADDRESS_STRUCTURE_ARG from './AddressStructure/ARG/AddressStructure.arg'
import ADDRESS_STRUCTURE_COL from './AddressStructure/COL/AddressStructure.col'
import ADDRESS_STRUCTURE_CHL from './AddressStructure/CHL/AddressStructure.chl'
import ADDRESS_STRUCTURE_MYS from './AddressStructure/AddressStructure.mys'
import ADDRESS_MAP from './AddressMap'
import ADDRESS_DETAILS_DEFAULT from './AddressDetails/AddressDetails.default'
import ADDRESS_DETAILS_MYS from './AddressDetails/AddressDetails.mys'
import ADDRESS_DETAILS_COL from './AddressDetails/AddressDetails.col'
import ANALYSIS from './CreditModality/Field.analysis'
import BIRTHDAY from './Birthday'
import CREDIT from './CreditModality/Field.credit'
import DISAPPROVED from './CreditModality/Field.disapproved'
import DOCUMENTS, { DocumentsCreation as DOCUMENTS_CREATION } from './Documents'
import DOCUMENTS_CHL from './Documents/Documents.chl'
import DOCUMENTS_ARG from './Documents/Documents.arg'
import DOCUMENTS_WITH_THUMBNAIL from './DocumentsWithThumbnail'
import DOCUMENT_TYPE from './Documents/Documents.type'
import DOCUMENT from './Documents/Documents.document'
import EMAILS from './Emails'
import EMAIL from './Emails/Email'
import ETHNICITY from './Ethnicity'
import GENDER from './Gender'
import IDENTIFICATION_CARD from './Attachments/IdentificationCard'
import IDENTIFICATION_CARD_OCR from './Attachments/IdentificationCardOCR'
import OCR from './Attachments/Ocr'
import IDENTIFICATION_CARD_FRONT_LABEL from './Attachments/IdentificationCardFront.label'
import INDICATION from './Indication'
import INDICATION_DEFAULT from './IndicationDefault'
import REGISTRANT from './Indication/Registrant'
import LOGIN from './Login/Login'
import NAME_FIRST from './Name/Name.first'
import NAME_FULL from './Name/Name.full'
import NAME_LAST from './Name/Name.last'
import NAME_MESSAGE from './Name/Name.message'
import NAME_MOTHER from './Name/Name.mother'
import NATIONALITY from './Nationality'
import PASSWORD from './Login/Password'
import PHONES from './Phones'
import PHONE_NUMBER from './Phones/Phone.number'
import PHONE from './Phones/Phone.mobile'
import POLICIES from './Policies'
import PRE_PAID from './CreditModality/Field.pre.paid'
import PROOF_OF_ADDRESS from './Attachments/ProofOfAddress'
import PROOF_ECONOMIC_LIFE from './Attachments/ProofEconomicLife'
import CONTACT_REFERENCES from './ContactReference'
import SELF from './Attachments/Self'
import TERM_AND_CONDITIONS from './Attachments/TermAndConditions'
import TERM_AND_CONDITIONS_OBSERVATION from './Attachments/Observation'
import SELECT_PAYMENT_PLAN from './CreditModality/Field.paymentPlan'
import BUSINESS_MODEL from './BusinessModel'
import ESTABLISHMENT from './Establishment'
import ADMISSION from './Admission'
import RUC from './Ruc'
import DIGITALSPACE_DESCRIPTION from './DigitalSpace/DigitalSpace.description'
import DIGITALSPACE from './DigitalSpace/DigitalSpace'
import GROUP from './Group'
import DOCUMENT_CPF from './Documents/BRA/CPF'
import DOCUMENT_RG from './Documents/BRA/RG'
import DOCUMENT_RNE from './Documents/BRA/RNE'
import ZIPCODE from './Address/ZipCode'
import ZIPCODE_SEARCH from './Address/BRA/ZipCode.search'
import REVIEW from './Review'
import STATE_PLAIN from './State/State.plain'
import NEIGHBORHOOD from './Address/Neighborhood'
import CITY from './Address/City'
import STREET_ADDRESS_LINE1 from './StreetAddress/StreetAddressLine1'
import STREET_ADDRESS_LINE2 from './StreetAddress/StreetAddressLine2'

const TYPES = {
  ANALYSIS,
  ADDRESS,
  ADDRESS_DETAILS_DEFAULT,
  ADDRESS_DETAILS_MYS,
  ADDRESS_DETAILS_COL,
  ADDRESS_MAP,
  ADDRESS_STRUCTURE_MYS,
  ADDRESS_STRUCTURE_PER,
  ADDRESS_STRUCTURE_COL,
  ADDRESS_STRUCTURE_ARG,
  ADDRESS_STRUCTURE_CHL,
  BIRTHDAY,
  CITY,
  CREDIT,
  DISAPPROVED,
  DOCUMENT_CPF,
  DOCUMENT_RG,
  DOCUMENT_RNE,
  PRE_PAID,
  DOCUMENTS,
  DOCUMENTS_CHL,
  DOCUMENTS_ARG,
  DOCUMENTS_WITH_THUMBNAIL,
  DOCUMENT_TYPE,
  DOCUMENT,
  DOCUMENTS_CREATION,
  EMAILS,
  EMAIL,
  ETHNICITY,
  GENDER,
  IDENTIFICATION_CARD,
  IDENTIFICATION_CARD_OCR,
  IDENTIFICATION_CARD_FRONT_LABEL,
  INDICATION,
  INDICATION_DEFAULT,
  REGISTRANT,
  LOGIN,
  NAME_FIRST,
  NAME_FULL,
  NAME_LAST,
  NAME_MESSAGE,
  NATIONALITY,
  NEIGHBORHOOD,
  PASSWORD,
  PHONES,
  PHONE_NUMBER,
  POLICIES,
  PROOF_ECONOMIC_LIFE,
  PROOF_OF_ADDRESS,
  CONTACT_REFERENCES,
  SELF,
  RUC,
  OCR,
  TERM_AND_CONDITIONS,
  TERM_AND_CONDITIONS_OBSERVATION,
  SELECT_PAYMENT_PLAN,
  BUSINESS_MODEL,
  ESTABLISHMENT,
  ADMISSION,
  DIGITALSPACE_DESCRIPTION,
  DIGITALSPACE,
  REVIEW,
  GROUP,
  NAME_MOTHER,
  ZIPCODE,
  ZIPCODE_SEARCH,
  STATE_PLAIN,
  PHONE,
  STREET_ADDRESS_LINE1,
  STREET_ADDRESS_LINE2
}

export default TYPES
