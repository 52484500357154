import { useEffect, useState } from 'react'
import { getCompanyId, LOCAL_STORAGE_KEYS } from 'src/helpers/get-company-id'

export const useCompany = () => {
  const [companyId, setCompanyId] = useState(getCompanyId())

  function handleChangesOnLocalStorage() {
    const companyIdFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEYS.OVERRIDE_COMPANY_ID
    )
    const updatedCompanyId = companyIdFromLocalStorage ? JSON.parse(companyIdFromLocalStorage) : getCompanyId()
    setCompanyId(updatedCompanyId)
  }

  function cleanup() {
    window.removeEventListener('storage', handleChangesOnLocalStorage)
  }

  useEffect(() => {
    const companyId = getCompanyId()
    setCompanyId(companyId)
    window.addEventListener('storage', handleChangesOnLocalStorage)
    return () => {
      cleanup()
    }
  }, [])

  return {
    companyId
  }
}
