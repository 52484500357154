import React, { PureComponent } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import CircularProgress from '@material-ui/core/CircularProgress'

const bem = bemClassName.bind(null, 'loaderWithCarousel')

import Icon from 'src/base/Icon'

import Slide from './LoaderWithCarousel.Slide'

import { messages } from './Messages'
import './LoaderWithCarousel.styl'
import { FormattedMessage } from 'react-intl'

class LoaderWithCarousel extends PureComponent {
  render() {
    const { className, size, transparent, fixed } = this.props
    const settings = {
      dots: false,
      draggable: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <div className={classNames(bem({ transparent }), className)}>
        <div className={bem('loadingContainer')}>
          <CircularProgress className={bem('icon', { fixed })} size={size} />
          <p className={bem('loadingContainer__paragraph')}>
            <b>
              <FormattedMessage {...messages['data_validation']} />
            </b>
          </p>
          <p className={bem('loadingContainer__paragraph')}>
            <b>
              <FormattedMessage {...messages['hold_on_and_do_not_leave']} />
            </b>
          </p>
          <p
            className={bem('loadingContainer__paragraph', { marginTop: true })}
          >
            <FormattedMessage {...messages['estimated_time']} />
          </p>
        </div>
        <Slider className={bem('slider')} {...settings}>
          <Slide
            icon={
              <Icon
                name={'beware_of_the_origin'}
                className={bem('slide_icon')}
              />
            }
            title={<FormattedMessage {...messages['slide_1_title']} />}
          >
            <FormattedMessage {...messages['slide_1_message']} />
          </Slide>
          <Slide
            icon={
              <Icon name={'natural_formulas'} className={bem('slide_icon')} />
            }
            title={<FormattedMessage {...messages['slide_2_title']} />}
          >
            <FormattedMessage {...messages['slide_2_message']} />
          </Slide>
          <Slide
            icon={
              <Icon name={'no_animal_testing'} className={bem('slide_icon')} />
            }
            title={<FormattedMessage {...messages['slide_3_title']} />}
          >
            <FormattedMessage {...messages['slide_3_message']} />
          </Slide>
          <Slide
            icon={
              <Icon
                name={'safe_for_you_and_environment'}
                className={bem('slide_icon')}
              />
            }
            title={<FormattedMessage {...messages['slide_4_title']} />}
          >
            <FormattedMessage {...messages['slide_4_message']} />
          </Slide>
          <Slide
            icon={
              <Icon
                name={'commitment_to_the_climate'}
                className={bem('slide_icon')}
              />
            }
            title={<FormattedMessage {...messages['slide_5_title']} />}
          >
            <FormattedMessage {...messages['slide_5_message']} />
          </Slide>
          <Slide
            icon={
              <Icon
                name={'eco_friendly_packaging'}
                className={bem('slide_icon')}
              />
            }
            title={<FormattedMessage {...messages['slide_6_title']} />}
          >
            <FormattedMessage {...messages['slide_6_message']} />
          </Slide>
        </Slider>
      </div>
    )
  }
}

LoaderWithCarousel.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  transparent: PropTypes.bool,
  fixed: PropTypes.bool
}

LoaderWithCarousel.defaultProps = {
  size: 40,
  transparent: false,
  fixed: true
}

export default LoaderWithCarousel
