import { getContext } from 'src/infra/Api/Api.context'
import PersonVerify from 'src/viewModels/PersonVerify'

const PERSON_VERIFY_RESET = 'natura-caduni/Person/PERSON_VERIFY_RESET'
export const types = [PERSON_VERIFY_RESET]

export function personVerifyResetReducer(state, action, dependencies) {
  return {
    loading: false,
    error: false,
    person: new PersonVerify({}, dependencies)
  }
}

export function resetPerson() {
  const { dispatch } = getContext()

  return dispatch({
    type: PERSON_VERIFY_RESET
  })
}
