import * as yup from 'yup'
import {
  VALID_IMAGE_FORMATS,
  VALID_AUDIO_FORMATS,
  MAX_UPLOAD_SIZE
} from 'src/viewModels/Upload/Upload.formats'

import { messagesValidations as messages } from 'src/configs/Fields/Attachments/Helpers'
const MAX_FILE_SIZE = 10

export function validationSchema({
  intl,
  validFormats,
  isAudio,
  newValidation,
  required = true
}) {
  const validsRegexFormats = isAudio ? VALID_AUDIO_FORMATS : VALID_IMAGE_FORMATS

  const MESSAGES = {
    size: intl.formatMessage(messages['maxFileSizeInMB'], {
      maxFileSize: `${MAX_FILE_SIZE}mb`
    }),
    type: intl.formatMessage(messages['validFormats'], {
      validFormats: validFormats.join(' ')
    })
  }

  return yup.object({
    type: required ? yup.number().required() : yup.number(),
    downloadUrl: yup.string(),
    name: yup.string().when('file', {
      is: (val) => val,
      then: yup.string().matches(validsRegexFormats, MESSAGES.type).required()
    }),
    size: yup.number().when('file', {
      is: (val) => val,
      then: yup.number().lessThan(MAX_UPLOAD_SIZE.DEFAULT, MESSAGES.size).required()
    }),
    ...newValidation
  })
}
