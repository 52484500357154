import { messages } from './IndicationDefault.messages'
import { lettersOnly } from 'src/configs/Fields/Name/Name.full'
import TagManager from 'react-gtm-module'

export default function Builder(intl, injection) {
  const { locale: { configs }, person, shouldReviewPerson, showSearchButton } = injection

  const {
    personId,
    additionalInformation: {
      introducerSellerId
    } = {}
  } = person

  const pushDataLayer = (value) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationIndicationData',
        fieldName: intl.formatMessage(messages['label_indication']),
        value,
      }
    })
  }

  return {
    id: 'introducerSellerId',
    label: intl.formatMessage(messages.indication_search_type),
    type: 'indicationDefault',
    offlineType: 'input',
    offlineLabel: intl.formatMessage(messages.indication_code),
    mask: '999999999999999',
    showAsterisk: false,
    defaultValue: introducerSellerId && introducerSellerId.toString() || false,
    customProps: {
      configs: configs.localization,
      personId,
      lettersOnly,
      handleGetConsultants,
      shouldReviewPerson,
      showSearchButton,
      pushDataLayer
    },
    required: false,
  }
}

export function handleGetConsultants(params) {
  const {
    consultants: { consultants },
    searchType: type,
    personId,
    search
  } = params

  return consultants.search({ personId, search, type })
}
