import { getContext } from 'src/infra/Api/Api.context'
import AddressesPeopleManagement from 'src/viewModels/AddressesPeopleManagement'

export const ADDRESSES_LEVELS_LOADING =
  'natura-caduni/People/ADDRESSES_LEVELS_LOADING'
export const ADDRESSES_LEVELS_SUCCESS =
  'natura-caduni/People/ADDRESSES_LEVELS_SUCCESS'
export const ADDRESSES_LEVELS_ERROR =
  'natura-caduni/People/ADDRESSES_LEVELS_ERROR'

export const types = [
  ADDRESSES_LEVELS_LOADING,
  ADDRESSES_LEVELS_SUCCESS,
  ADDRESSES_LEVELS_ERROR
]

export function addressesLevelsReducer(state, action, addressesDependencies) {
  const { loading, error, addressesPeopleManagement } = state
  const { payload, type, meta } = action

  const hasSuccess = type === ADDRESSES_LEVELS_SUCCESS
  const hasError = type === ADDRESSES_LEVELS_ERROR
  const isLoading = type === ADDRESSES_LEVELS_LOADING

  if (isLoading) {
    const { parameters } = payload
    const loadingStructure = builderLoadingStructure(parameters, true, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(error, { addresses: false })
  }

  if (hasSuccess) {
    const {
      previousAction: {
        payload: { parameters }
      }
    } = meta

    const dataStructure = builderDataStructure(
      payload,
      parameters,
      addressesPeopleManagement
    )
    const loadingStructure = builderLoadingStructure(parameters, false, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(addressesPeopleManagement, { ...dataStructure })
    Object.assign(error, { addresses: false })
  }

  if (hasError) {
    const {
      previousAction: {
        payload: { parameters }
      }
    } = meta
    const loadingStructure = builderLoadingStructure(parameters, false, loading)

    Object.assign(loading, loadingStructure)
    Object.assign(error, { addresses: true })
  }

  return {
    ...state,
    addressesPeopleManagement: new AddressesPeopleManagement(
      addressesPeopleManagement,
      addressesDependencies
    ),
    loading,
    error
  }
}

export function getLevels({ geoStructureType, queryParams, geoStructureCode } = {}) {

  const {
    dispatch,
    bff_urls: { ADDRESS_BFF },
    bff_apiKeys: { ADDRESS_APIKEY },
    locale: { id, countryName },
    user: {
      sellerId,
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      parameters: {
        countryName,
        geoStructureCode,
        geoStructureType
      },
      request: {
        method: 'GET',
        url: ADDRESS_BFF(queryParams),
        headers: {
          userId: sellerId,
          'x-api-key': ADDRESS_APIKEY,
        },
        params: {
          countryCode: id,
          companyId: '1',
        },
      }
    }
  })
}

function builderDataStructure({ data }, addressParams, addresses) {
  const { geoStructureCode, geoStructureType, countryName } = addressParams

  const geoStructureParent = geoStructureCode ? geoStructureCode : countryName

  if (!geoStructureType) {
    const country = countryName.toUpperCase()

    return {
      departments: {
        ...addresses.departments,
        [country]: data.items
      }
    }
  }

  return {
    [geoStructureType]: {
      ...addresses[geoStructureType],
      [geoStructureParent]: data.items
    }
  }
}

function builderLoadingStructure(
  { geoStructureType, geoStructureCode },
  value,
  loading
) {

  if (!geoStructureType) return { regions: value }

  if (!geoStructureCode) return { [geoStructureType]: value }

  return {
    [geoStructureType]: {
      ...loading[geoStructureType],
      [geoStructureCode]: value
    }
  }
}
