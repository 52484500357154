import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

const Messages = defineMessages({
  step_label: {
    defaultMessage: 'Complementary Data',
    id: 'register.address.label'
  },
  page_title: {
    defaultMessage: 'Address',
    id: 'register.address.step.page.title'
  },
  subtitle_1: {
    defaultMessage: 'To continue, complete the fields below with your address information:',
    id: 'register.address.step..subtitle_1'
  },
  subtitle_2: {
    defaultMessage: 'Fields with "*" are required.',
    id: 'register.address.subtitle_2'
  },
  review_region: {
    defaultMessage: 'Region',
    id: 'form.field.review.region'
  },
  review_department: {
    defaultMessage: 'Department',
    id: 'form.field.review.department'
  },
  review_province: {
    defaultMessage: 'Province',
    id: 'form.field.review.province'
  },
  review_district: {
    defaultMessage: 'District',
    id: 'form.field.review.district'
  },
  review_neighborhood: {
    defaultMessage: 'Neighborhood',
    id: 'form.field.review.neighborhood'
  },
  review_gmap: {
    defaultMessage: 'Address',
    id: 'form.field.review.gmap'
  },
  review_number: {
    defaultMessage: 'Number',
    id: 'form.field.review.number'
  },
  review_references: {
    defaultMessage: 'References',
    id: 'form.field.review.references'
  },
  review_complement: {
    defaultMessage: 'Complement',
    id: 'form.field.review.complement'
  },
  review_address_residential: {
    defaultMessage: 'Residential Address',
    id: 'form.field.review.address.residential'
  },
  review_address_delivery: {
    defaultMessage: 'Delivery Address',
    id: 'form.field.review.address.delivery'
  }
})

export function getSectionTitle({ messages = Messages }) {

  const {
    page_title,
    subtitle_1,
    step_label
  } = messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />,
    ],
    label: <FormattedMessage {...step_label} />,
  }
}

export default Messages
