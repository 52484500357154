import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import { DELIVERY_ADDRESS, MAILING_ADDRESS, ADDRESS_USE } from '../../../../../../../../models/Address/AddressUse'
import { pickAddressByUse } from '../../../../../../../../models/Address/AddressPicker'
import { store } from 'src/App.state'
import { getOtherAddress } from './getOtherAddress'

export const getChileParsedData = ({ data, configs, person }) => {
  const {
    localization: {
      address: {
        levels
      },
      countryName
    }
  } = configs
  const { person: { person: { addresses: personAddresses } }} = store.getState()
  const mailing = pickAddressByUse(personAddresses, ADDRESS_USE.MAILLING)

  const { sequence: mailingSequence } = mailing
  const delivery = pickAddressByUse(personAddresses, ADDRESS_USE.DELIVERY)
  const deliverySequence = mailingSequence === delivery.sequence ? undefined : delivery.sequence

  const { mailingAddress, deliveryAddress } = parseData(data, levels, countryName, mailingSequence, deliverySequence)
  const otherAddresses = getOtherAddress(personAddresses, mailingSequence, deliverySequence)

  return {
    ...person,
    addresses: deliveryAddress ? [mailingAddress, deliveryAddress, ...otherAddresses] : [mailingAddress, ...otherAddresses]
  }
}

function parseData(data, levels, countryName, mailingSequence, deliverySequence) {
  const dataAddress = data.address[0]
  let mailingAddress = buildMailingAddress(dataAddress, levels, mailingSequence)

  if (data.copyAddress === false) {
    const deliveryAddress = buildDeliveryAddress(dataAddress, levels, countryName, deliverySequence)

    mailingAddress = {
      ...mailingAddress,
      addressUse: [MAILING_ADDRESS]
    }

    return {
      mailingAddress,
      deliveryAddress
    }
  }

  return {
    mailingAddress
  }
}

function buildMailingAddress(dataAddress, levels, sequence) {
  const cumulativeAddress = JSON.parse(localStorage.getItem('cumulativeAddress'))

  const mailingAddress = mapAddress(dataAddress, levels, cumulativeAddress.geographicalLevels)

  return {
    ...mailingAddress,
    sequence,
    addressUse: cumulativeAddress.addressUse
  }
}

function buildDeliveryAddress(dataAddress, levels, countryName, sequence) {
  const deliveryGeographicalLevels = [
    { level: levels.COUNTRY, code: null, description: countryName },
    {
      level: levels.REGION,
      code: dataAddress.department.code,
      description: dataAddress.department.description
    },
    {
      level: levels.CITY, // 2
      code: dataAddress.city.code,
      description: dataAddress.city.description
    },
    {
      level: levels.NEIGHBORHOOD, // 3
      code: null,
      description: "-"
    }
  ]

  const deliveryAddress = mapAddress({
    number: dataAddress.deliveryNumber,
    addressUse: [DELIVERY_ADDRESS],
    complement: dataAddress.deliveryComplement,
    references: dataAddress.deliveryReferences,
    street: dataAddress.deliveryStreet,
  }, levels, deliveryGeographicalLevels, ADDRESS_TYPE.DELIVERY)

  if (sequence) {
    deliveryAddress.sequence = sequence
  }

  return deliveryAddress
}

function mapAddress(address, levels, additionalGeographicalLevels = [], addressType = ADDRESS_TYPE.MAILING) {
  const {
    number,
    addressUse,
    complement,
    references,
    street,
    sequence
  } = address

  const geographicalLevels = [
    ...additionalGeographicalLevels,
    { level: levels.NUMBER, description: number },
    { level: levels.STREET, description: street },
    { level: levels.REFERENCES, description: references }
  ]

  if (complement) {
    geographicalLevels.push(
      { level: levels.COMPLEMENT, description: complement }
    )
  }

  return {
    addressType,
    addressUse,
    geographicalLevels,
    sequence
  }
}
