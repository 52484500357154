import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import Status from 'src/models/Status'
import Substatus from 'src/models/Substatus'

const adapter = validatorAdapter('Yup', yup)

const SUBSTATUS_NOT_ALLOWED = [18, 19]

export default class StatusValidation extends Entity {
  static SCHEMA = {
    status: {
      validator: adapter(yup.object().nullable()),
      type: Status,
      defaultValue: {}
    },
    substatus: {
      validator: adapter(yup.object().nullable()),
      type: Substatus,
      defaultValue: {}
    }
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }

  get isApproved() {
    const { substatus: { id } } = this

    return SUBSTATUS_NOT_ALLOWED.includes(id)
  }
}
