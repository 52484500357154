import { ADDRESS_USE } from 'src/models/Address/AddressUse'
import { parseAddress } from './AddressHome'

export default function Builder(params) {
  const { intl, messages, person } = params
  const { addresses = [] } = person

  const deliveryAddress = addresses.find(findAddress)

  const fields = parseAddress(deliveryAddress, params)

  return {
    id: 'profile-address-delivery',
    title: intl.formatMessage(messages.box_address_delivery_title),
    icon: 'ui_office',
    wrapper: 'default',
    content: 'list',
    fields
  }
}

function findAddress({ addressUse }) {
  return addressUse.find(findDeliveryAddress)
}

function findDeliveryAddress({ id }) {
  return id === ADDRESS_USE.DELIVERY
}
