import argentinaDocumentsType from './Documents.type.arg'
import chileDocumentsType from './Documents.type.chl'
import colombiaDocumentsType from './Documents.type.col'
import malaysiaDocumentsType from './Documents.type.mys'
import peruDocumentsType from './Documents.type.per'

const strategies = {
  ARG: argentinaDocumentsType,
  CHL: chileDocumentsType,
  COL: colombiaDocumentsType,
  MYS: malaysiaDocumentsType,
  PER: peruDocumentsType
}

export const getDocumentsTypeByLocale = (locale, dependencies) => {
  const strategy = strategies[locale]
  return strategy(dependencies)
}
