import React, { PureComponent } from 'react'
import './Card.styl'
import PropTypes from 'prop-types'

class Card extends PureComponent {

  render() {
    const {
      children,
      hasPadding,
      removeBackgroundForMobile = false,
      isStatusScreen = false
    } = this.props

    const classes = [
      'card',
      hasPadding ? 'card_padding' : null,
      removeBackgroundForMobile ? 'gray_card' : null,
      isStatusScreen ? 'status_screen' : null
    ]

    return (<div className={classes.join(' ')}>
      {children}
    </div>)
  }
}

Card.propTypes = {
  children: PropTypes.any.isRequired,
  hasPadding: PropTypes.bool,
  removeBackgroundForMobile: PropTypes.bool,
  isStatusScreen: PropTypes.bool
}

export default Card
