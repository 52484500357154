import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  label: {
    defaultMessage: 'The intent states that you have read and ##accepted your business terms## and ##privacy policy##',
    id: 'form.field.policy.conditions.label'
  },
  modal_title: {
    defaultMessage: 'Acceptance of Natura\'s conditions',
    id: 'form.field.policy.conditions.modal.title'
  },
  modal_button: {
    defaultMessage: 'Accept',
    id: 'form.field.policy.conditions.modal.button'
  }
})

const dependencies = {
  messages,
  conditionsMessages: [],
  privacyMessages: []
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { conditionsMessages, privacyMessages } = { ...dependencies, ...injection }

  const title = intl.formatMessage(messages.modal_title)
  const acceptLabel = intl.formatMessage(messages.modal_button)

  return {
    id: 'conditions_privacy',
    type: 'checkboxModal',
    label: intl.formatMessage(messages.label),
    customProps: {
      linkIndexes: [1, 3],
      modal: [{
        title,
        message: conditionsMessages,
        acceptLabel
      },
      {
        title,
        message: privacyMessages,
        acceptLabel
      }]
    }
  }
}
