import { connect } from 'react-redux'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'province',
    label: intl.formatMessage(messages['province']),
    placeholder: intl.formatMessage(messages['province_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['province_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: validateFieldDependencies,
    required: true,
    disabledBy: [ 'address[].region', 'address[].department' ]
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.district`, '', id)
  setFieldValue(`${parent}.street`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.district`, false)
  setFieldTouched(`${parent}.street`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function getDistrictWithProvince(params) {
  const { value } = params

  return value && getDistricts(params)
}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, loading: { provinces: provincesloading }, error, addressesPeopleManagement }, locale: { locale } } = state
  const { group: { index }, form: { values: { address } }, onChange } = ownProps
  const { provinces: provincesCMM, getAddressesCMM } = addresses
  const { department } = address[index] || {}

  const { getLevels, provinces: provincesBFF } = addressesPeopleManagement
  const { configs: { localization: { shouldGetAddressFromBff }}} = locale

  const provinces = shouldGetAddressFromBff ? provincesBFF : provincesCMM
  const options = provinces[department]
  const loading = provincesloading && provincesloading[department]

  const shouldGetProvinces = !options && !provincesloading && !error.addresses && department

  if (shouldGetProvinces) {
    getProvinces({department, getAddressesCMM, getLevels, shouldGetAddressFromBff })
  }

  return {
    ...ownProps,
    loading,
    options: options ?? [],
    onChange: function(value) {
      getDistrictWithProvince({ value, getAddressesCMM, getLevels, shouldGetAddressFromBff })
      onChange(value)
    }
  }
}

const getDistricts = ({ value, getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if (shouldGetAddressFromBff) {
    getLevels({
      geoStructureCode: value,
      geoStructureType: 'districts',
      queryParams: `level=4&parentLevel=3&parentCode=${value}`,
    })
  } else {
    getAddressesCMM({
      geoStructureCode: value,
      geoStructureType: 'districts'
    })
  }
}

const getProvinces = ({department, getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if(shouldGetAddressFromBff) {
    getLevels({
      geoStructureType: 'provinces',
      queryParams: `level=3&parentLevel=2&parentCode=${department}`,
      geoStructureCode: department
    })
  } else {
    getAddressesCMM({
      geoStructureCode: department,
      geoStructureType: 'provinces'
    })
  }
}
