import { BuildStepsMapper } from './BuildStepsMapper'
import {
  isColombiaFlowToggleActive,
  isColombiaAddressMfeToggleActive,
  isColombiaPersonalDataMfeToggleActive,
  isPreRegisterMfeToggleActive
} from 'src/config'
import { store } from 'src/App.state'
import { buildCompletedPropsWithInstructionsMessage } from '../../Completed/CompletedPropsWithInstructionMessage'
import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import Loader from '../../../../../../../views/Register/Components/Loader'
import {
  isEnableCompletedStepOnMfeToggleActive,
  isEnableDigitalStoreStepForColombiaFlowToggleActive,
  isEnableAttachmentsStepOnMfeToggleActive
} from '../../../../../../../config'
import { buildCompletedMicrofrontendProps } from '../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildDocumentsPropsMicrofrontend } from '../../Documents/DocumentsPropsMicrofrontend'
import { buildPreRegisterPropsMicrofrontend } from '../../PreRegister'

export const buildSteps = ({ basePath }) => {
  const { user: { user } } = store.getState()

  const overrideFormDefaultWithMfeContainer = {
    components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
  }

  const overrideDefaultPropsCompletedStepWithMfeContainer = {
    components: [SectionTitle, MicroFrontendContainer],
    componentsPropsBuilder: buildCompletedMicrofrontendProps
  }

  const addressOverrideProps = isColombiaAddressMfeToggleActive() ? overrideFormDefaultWithMfeContainer : {}
  const personalDataOverrideProps = isColombiaPersonalDataMfeToggleActive() ? overrideFormDefaultWithMfeContainer : {}
  const attachmentsOverrideProps = isEnableAttachmentsStepOnMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildDocumentsPropsMicrofrontend,
  } : {}
  const completedOverrideProps = isEnableCompletedStepOnMfeToggleActive()
    ? overrideDefaultPropsCompletedStepWithMfeContainer
    : { componentsPropsBuilder: buildCompletedPropsWithInstructionsMessage }
  const preRegisterOverrideProps = isPreRegisterMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildPreRegisterPropsMicrofrontend,
  } : {}

  if (!isColombiaFlowToggleActive()) {
    return []
  }

  if (user.authenticated) {
    let buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 8 })

    if (isEnableDigitalStoreStepForColombiaFlowToggleActive()) {
      buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 9 })

      return [
        buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
        buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
        buildStepsMapper.mapAuthorizationCode({ currentStep: 3 }),
        buildStepsMapper.mapAddress({ currentStep: 4, overrideProps: addressOverrideProps }),
        buildStepsMapper.mapDocumentation({
          currentStep: 5,
          overrideProps: {
            components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
          }
        }),
        buildStepsMapper.mapDocumentAttachment({ currentStep: 6, overrideProps: attachmentsOverrideProps }),
        buildStepsMapper.mapIndication({ currentStep: 7 }),
        buildStepsMapper.mapDigitalStore({ currentStep: 8 }),
        buildStepsMapper.mapCompleted({
          currentStep: 9,
          overrideProps: {
            ...completedOverrideProps
          }
        }),
      ]
    }

    return [
      buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
      buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
      buildStepsMapper.mapAuthorizationCode({ currentStep: 3 }),
      buildStepsMapper.mapAddress({ currentStep: 4, overrideProps: addressOverrideProps }),
      buildStepsMapper.mapDocumentation({
        currentStep: 5,
        overrideProps: {
          components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
        }
      }),
      buildStepsMapper.mapDocumentAttachment({ currentStep: 6, overrideProps: attachmentsOverrideProps }),
      buildStepsMapper.mapIndication({ currentStep: 7 }),
      buildStepsMapper.mapCompleted({
        currentStep: 8,
        overrideProps: {
          ...completedOverrideProps
        }
      }),
    ]
  }

  if (isEnableDigitalStoreStepForColombiaFlowToggleActive()) {
    const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 8 })
    return [
      buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
      buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
      buildStepsMapper.mapAuthorizationCode({ currentStep: 3 }),
      buildStepsMapper.mapAddress({ currentStep: 4, overrideProps: addressOverrideProps }),
      buildStepsMapper.mapDocumentation({
        currentStep: 5,
        overrideProps: {
          components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
        }
      }),
      buildStepsMapper.mapDocumentAttachment({ currentStep: 6, overrideProps: attachmentsOverrideProps }),
      buildStepsMapper.mapIndication({ currentStep: 7 }),
      buildStepsMapper.mapDigitalStore({ currentStep: 8 }),
      buildStepsMapper.mapCompleted({
        currentStep: 8,
        overrideProps: {
          ...completedOverrideProps
        }
      })
    ]
  }

  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 7 })

  return [
    buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
    buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
    buildStepsMapper.mapAddress({ currentStep: 3, overrideProps: addressOverrideProps }),
    buildStepsMapper.mapAuthorizationCode({ currentStep: 4 }),
    buildStepsMapper.mapDocumentation({
      currentStep: 5,
      overrideProps: {
        components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
      }
    }),
    buildStepsMapper.mapDocumentAttachment({ currentStep: 6, overrideProps: attachmentsOverrideProps }),
    buildStepsMapper.mapIndication({ currentStep: 7 }),
    buildStepsMapper.mapCompleted({
      currentStep: 7,
      overrideProps: {
        ...completedOverrideProps
      }
    })
  ]
}
