import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Messages } from './Messages'

export const getSectionTitle = () => {
  const {
    page_title,
    subtitle_1,
    step_label
  } = Messages

  return {
    title: <FormattedMessage {...page_title} />,
    subtitle: [
      <FormattedMessage {...subtitle_1} key="subtitle_1" />,
    ],
    label: <FormattedMessage {...step_label} />,
  }
}
