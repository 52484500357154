import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Icon from 'src/base/Icon'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import Button, { TYPES } from 'src/base/Button'
import Loader from 'src/base/Loader'

import MESSAGES from '../ContactValidation.messages'

import '../ContactValidation.styl'

const bem = bemClassName.bind(null, 'contact-validation-modal')

const SMSTokenModalContent = ({
  closeModal,
  empty,
  onChange,
  phoneNumber,
  resend,
  resendStatus,
  resetResendStatus,
  retry,
  serverError,
  submit,
  success,
  tokenNumber,
  loading,
  intl
}) => {
  const iconSize = 75

  if(loading) {
    return (
      <section className={classNames(bem('loader'))} data-testid="contact-validation-sms-loader">
        <Loader />
      </section>
    )
  }

  if (serverError) {
    return (
      <section className={classNames(bem('content'))} data-testid="contact-validation-sms-error">
        <Icon
          name="deny"
          custom={true}
          size={iconSize}
          className={classNames(bem('icon'))}
        />

        <h3 className={classNames(bem('title'))}>
          {intl.formatMessage(MESSAGES['modal_no_backend_service_title'])}
        </h3>

        <p className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES['modal_no_backend_service_text'])}
        </p>

        <footer className={classNames(bem('footer'))}>
          <Button onClick={closeModal} className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_validate_late'])}
          </Button>
          <Button
            type={TYPES.PRIMARY}
            onClick={retry}
            className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_try_again'])}
          </Button>
        </footer>
      </section>
    )
  }

  if (resendStatus === true) {
    return (
      <section className={classNames(bem('content'))} data-testid="contact-validation-sms-resend">
        <Icon
          name="approved"
          custom={true}
          size={iconSize}
          className={classNames(bem('icon'))}
        />

        <h3 className={classNames(bem('title'))}>
          {intl.formatMessage(MESSAGES['modal_resend_title'])}
        </h3>

        <p className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES['modal_resend_text'])}
        </p>

        <footer className={classNames(bem('footer'))}>
          <Button
            type={TYPES.PRIMARY}
            onClick={resetResendStatus}
            className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_ok'])}
          </Button>
        </footer>
      </section>
    )
  }

  if (success) {
    return (
      <section className={classNames(bem('content'))} data-testid="contact-validation-sms-code">
        <Icon name="approved" custom={true} size={iconSize} />

        <h3 className={classNames(bem('title'))}>
          {intl.formatMessage(MESSAGES['modal_success_title'])}
        </h3>

        <p className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES['modal_success_text'])}
        </p>

        <p className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES['modal_success_text2'])}
        </p>

        <footer className={classNames(bem('footer'))}>
          <Button
            type={TYPES.PRIMARY}
            onClick={closeModal}
            className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_agree'])}
          </Button>
        </footer>
      </section>
    )
  }

  if (success === false) {
    return (
      <section className={classNames(bem('content'))}>
        <Icon
          name="deny"
          custom={true}
          size={iconSize}
          className={classNames(bem('icon'))}
        />

        <h3 className={classNames(bem('title'))}>
          {intl.formatMessage(MESSAGES['modal_unsuccessfully_title'])}
        </h3>

        <p className={classNames(bem('text'))}>
          {intl.formatMessage(MESSAGES['modal_unsuccessfully_text'])}
        </p>

        <footer className={classNames(bem('footer'))}>
          <Button onClick={closeModal} className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_validate_late'])}
          </Button>
          <Button
            type={TYPES.PRIMARY}
            onClick={retry}
            className={classNames(bem('button'))}>
            {intl.formatMessage(MESSAGES['modal_button_try_again'])}
          </Button>
        </footer>
      </section>
    )
  }

  return (
    <form onSubmit={submit}>
      <p className={classNames(bem('text'))}>
        {intl.formatMessage(MESSAGES['modal_form_text'])}
        <strong> {phoneNumber} </strong>
        {intl.formatMessage(MESSAGES['modal_form_text2'])}
      </p>

      <div className={classNames(bem('field-container'))}>
        <input className={classNames(bem('input', { empty }))}
          type="text"
          id="tokenNumber"
          data-testid="contact-validation-sms-code"
          onChange={onChange}
          autoComplete="off"
          name="tokenCode"
          value={tokenNumber}
          maxLength='5'
          placeholder={intl.formatMessage(MESSAGES['modal_form_code'])}
        />

        <a className={classNames(bem('link'))} onClick={resend}>
          {intl.formatMessage(MESSAGES['modal_form_resend'])}
        </a>
      </div>

      <footer className={classNames(bem('footer'))}>
        <Button
          data-testid="contact-validation-sms-late"
          onClick={closeModal}
          className={classNames(bem('button'))}>
          {intl.formatMessage(MESSAGES['modal_button_validate_late'])}
        </Button>
        <Button
          type={TYPES.PRIMARY}
          onClick={submit}
          className={classNames(bem('button'))}>
          {intl.formatMessage(MESSAGES['modal_button_ok'])}
        </Button>
      </footer>
    </form>
  )
}

SMSTokenModalContent.propTypes = {
  closeModal: PropTypes.func,
  empty: PropTypes.bool,
  onChange: PropTypes.func,
  phoneNumber: PropTypes.string,
  resend: PropTypes.func,
  resendStatus: PropTypes.bool,
  resetResendStatus: PropTypes.func,
  retry: PropTypes.func,
  serverError: PropTypes.bool,
  submit: PropTypes.func,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  tokenNumber: PropTypes.string,
  intl: PropTypes.object,
}

export default injectIntl(SMSTokenModalContent)
