import { getContext } from 'src/infra/Api/Api.context'
import User from 'src/models/User'

export const UNAUTHORIZED_STATUS = 403
export const REFRESH_AUTHENTICATION = 'natura-caduni/User/REFRESH_AUTHENTICATION'

export const types = [REFRESH_AUTHENTICATION]

export function userRefreshAuthenticationReducer(state, action, dependencies) {
  return {
    ...state,
    user: new User(action.payload.user, dependencies),
    loading: false,
    error: false
  }
}

export async function refreshAuthentication(data, keycloakParsed) {
  const { dispatch, user } = getContext()

  const { token } = data
  const { expiresIn } = keycloakParsed

  const accessToken = `Bearer ${token}`

  return dispatch({
    type: REFRESH_AUTHENTICATION,
    payload: {
      user: {
        ...user,
        authentication: {
          accessToken,
          expiresIn
        }
      }
    }
  })
}
