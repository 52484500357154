import { connect } from 'react-redux'

function mapStateToProps(state, ownProps) {
  const {
    person: { person },
    locale: { locale },
    upload: { upload, loading: uploadLoading },
    ocr: { ocr },
    connectivity
  } = state

  return {
    ...ownProps,
    connectivity,
    ocr,
    upload,
    person,
    locale,
    uploadLoading
  }
}

export default connect(mapStateToProps)
