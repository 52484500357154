import { TENANT_ID } from '../../../../../../models/tenant/tenant-id'

export function buildDocumentsPropsMicrofrontend(deps) {
  const { locale: { tenantId, id, companyId }, person } = deps
  const { personId, businessModel: { businessModelId, functionId, roleId }, roles } = person
  const {
    user: { isCSC },
  } = deps
  const role = roles.find(item => item.functionId === functionId && item.roleId === roleId && item.businessModelId === businessModelId)
 
  const isEditing = role && !role?.isDraft ? true : false
  const isChile = tenantId === TENANT_ID.NATURA_CHILE
  const isDisableNextButton =  role?.isCN && !(isCSC && isEditing && isChile) 

  return {
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'attachments',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
        disableNextButton: isDisableNextButton
      }
    }
  }
}
