import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Modal from 'src/base/Modal'

import MESSAGES from './DeniedAccess.messages'

export class DeniedAccess extends React.PureComponent {
  state = { open: true }

  messages = [
    this.props.intl.formatMessage(MESSAGES.warning),
    this.props.intl.formatMessage(MESSAGES.changeBrowserPermittions)
  ]

  title = this.props.intl.formatMessage(MESSAGES.title)

  handleClose = () => {
    if (this.props.closeParentModal) this.props.closeParentModal()
    this.setState({ open: false })
  }

  render() {
    return (
      <Modal
        open={this.state.open}
        title={this.title}
        message={this.messages}
        iconName="warning_circle"
        onClose={this.handleClose} />
    )
  }
}

DeniedAccess.propTypes = {
  intl: PropTypes.object.isRequired,
  closeParentModal: PropTypes.func
}

export default injectIntl(DeniedAccess)
