import { getContext } from 'src/infra/Api/Api.context.js'

import Error from 'src/viewModels/Error'
import StatusValidation from 'src/viewModels/StatusValidation'
import { CN } from 'src/models/BusinessModel/BusinessModel'

export const STATUS_VALIDATION_LOADING = 'natura-caduni/STATUS_VALIDATION_LOADING'
export const STATUS_VALIDATION_SUCCESS = 'natura-caduni/STATUS_VALIDATION_SUCCESS'
export const STATUS_VALIDATION_ERROR = 'natura-caduni/STATUS_VALIDATION_ERROR'

export const types = [STATUS_VALIDATION_LOADING, STATUS_VALIDATION_SUCCESS, STATUS_VALIDATION_ERROR]

const initialState = {
  statusValidation: new StatusValidation({}, { checkStatusValidation }),
  error: false,
  loading: false
}

export default function statusValidationReducer(state = initialState, action) {
  const { payload, type } = action
  let { error, statusValidation } = state

  const isLoading = type === STATUS_VALIDATION_LOADING
  const hasSuccess = type === STATUS_VALIDATION_SUCCESS
  const hasError = type === STATUS_VALIDATION_ERROR

  if (hasSuccess) {
    statusValidation = new StatusValidation(payload.data, { checkStatusValidation })
    error = false
  }

  if (hasError) {
    error = new Error(action.error)
  }

  return {
    loading: isLoading,
    error,
    statusValidation
  }
}

export function checkStatusValidation(personId) {
  const {
    dispatch,
    urls: { PEOPLE },
    locale: { id },
    user: { sellerId, authentication: { accessToken }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'GET',
        url: PEOPLE(personId),
        headers: {
          'cache-control': 'no-cache',
          access_token: accessToken,
          userId: sellerId,
          businessModel: CN.businessModelId,
          function: CN.functionId,
          role: CN.roleId,
        }
      }
    }
  })
}
