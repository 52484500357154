import { get } from 'lodash'
import { splitIdParts } from './Form.state.helpers'

export default class Schema {
  constructor(props) {
    Object.assign(this, { ...props })
  }

  setFieldHiddenAndDisabledStates(params) {
    this.fieldsSchema.map(mapHiddenAndDisabledStates, params)

    return this
  }

  setGroupFields(id, list) {
    this.fieldsSchema.map(mapGroupSchemas, { id, list })

    return this
  }
}

function mapHiddenAndDisabledStates(schema, idx) {
  if(Array.isArray(schema)) {
    return schema.map(mapHiddenAndDisabledStates, this)
  }

  const { disabledBy, hiddenBy, group } = schema
  const index = group ? group.index : idx
  const disabled = disabledBy && disabledBy.some(verifyState, { ...this, index })
  const hidden = hiddenBy && hiddenBy.some(verifyState, { ...this, index })

  Object.assign(schema, {
    disabled: disabled || this.disabled,
    hidden: hidden || this.hidden
  })

  if(schema.list) {
    return setGroupHiddenAndDisabledStates(schema, disabled, hidden, this)
  }

  return schema
}

function setGroupHiddenAndDisabledStates(schema, disabled, hidden, props) {
  const params = { ...props, disabled, hidden }
  const { list, addButton, removeButton } = schema

  list.map(mapHiddenAndDisabledStates, params)

  if(addButton || removeButton) {
    addButton && mapHiddenAndDisabledStates.call(params, addButton, 0)
    removeButton && mapHiddenAndDisabledStates.call(params, removeButton, 1)
  }

  return schema
}

function verifyState(origin) {
  const { fieldId, errorIdField, errorIdGroup, isNegative } = splitIdParts(origin, this.index)

  const hasError = !!get(this.errors, errorIdField) || !!get(this.errors, errorIdGroup)
  const isTouched = !!get(this.toucheds, fieldId)
  const hasValue = !!get(this.values, fieldId)

  const shouldBeDisabled = hasError || !isTouched || !hasValue
  const shouldBeEnabled = !hasError && hasValue

  return isNegative ? shouldBeEnabled : shouldBeDisabled
}

function mapGroupSchemas(schema) {
  if(Array.isArray(schema))
    return schema.map(mapGroupSchemas, this)

  const { list, id } = this

  if(schema.id === id)
    Object.assign(schema, { list })

  return new Schema(schema)
}
