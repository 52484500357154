import TYPES from 'src/configs/Fields'

export const getArgentinaFieldsSchema = ({ intl, dependencies }) => {
  const ADDRESS_STRUCTURE_ARG = commonParams(TYPES.ADDRESS_STRUCTURE_ARG(intl, dependencies))

  return [[ADDRESS_STRUCTURE_ARG]]

  function commonParams(field, required = true) {
    return {
      ...field,
      required
    }
  }
}
