const REGISTER_ORIGIN_GLOBAL = 'REGISTER_ORIGIN_GLOBAL'
const REGISTER_ORIGIN_MINEGOCIO = 'REGISTER_ORIGIN_MINEGOCIO'
const REGISTER_ORIGIN_UNDEFINED = 'REGISTER_ORIGIN_UNDEFINED'
const REGISTER_ORIGIN_AVON_MIGRATION = 'REGISTER_ORIGIN_AVON_MIGRATION'
const REGISTER_ORIGIN_SELF_REGISTRATION = 'REGISTER_ORIGIN_SELF_REGISTRATION'
const REGISTER_ORIGIN_FV_REGISTER = 'REGISTER_ORIGIN_FV_REGISTER'
const REGISTER_ORIGIN_FACELIFT = 'REGISTER_ORIGIN_FACELIFT'

const sourceSystemList = {
  4: REGISTER_ORIGIN_GLOBAL,
  5: REGISTER_ORIGIN_GLOBAL,
  6: REGISTER_ORIGIN_GLOBAL,
  7: REGISTER_ORIGIN_GLOBAL,
  11: REGISTER_ORIGIN_GLOBAL,
  12: REGISTER_ORIGIN_AVON_MIGRATION,
  34: REGISTER_ORIGIN_SELF_REGISTRATION,
  35: REGISTER_ORIGIN_FV_REGISTER,
  50: REGISTER_ORIGIN_MINEGOCIO,
  99: REGISTER_ORIGIN_GLOBAL,
  101: REGISTER_ORIGIN_FACELIFT,
  102: REGISTER_ORIGIN_FACELIFT
}

export const getOrigen = (sourceSystemId) => {
  return sourceSystemList[sourceSystemId] || REGISTER_ORIGIN_UNDEFINED
}