import { buildCompletedProps } from './CompletedProps.default'

export function buildCompletedPropsWithInstructionsMessage(deps) {
  const props = buildCompletedProps(deps)

  return {
    ...props,
    displayPendingInstructionMessage: true
  }
}
