import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'
import MESSAGES from './RecordAudio.messages'

const bem = bemClassName.bind(null, 'record-audio')

const AttachmentOptions = function({ recordAudio, uploadFile, intl }) {
  return (
    <div className={bem()}>
      <header>
        <h3 className={bem('title')}>
          { intl.formatMessage(MESSAGES.attachmentOptionsTitle) }
        </h3>
      </header>
      <section className={bem('content')}>
        <p>{ intl.formatMessage(MESSAGES.attachmentOptionsInstructions) }</p>
        <p>{ intl.formatMessage(MESSAGES.attachmentOptionsSubInstructions) }</p>
        <p>{ intl.formatMessage(MESSAGES.attachmentOptionsContent) }</p>
      </section>
      <footer className={bem('actions')}>
        <button className={bem('actions__button')} onClick={recordAudio} type="button">
          <Icon name="microphone" className={bem('actions__icon', 'microphone')} />
          { intl.formatMessage(MESSAGES.attachmentOptionsRecord) }
        </button>
        <button className={bem('actions__button')} onClick={uploadFile} type="button">
          <Icon name="attachment" className={bem('actions__icon', 'attachment')} />
          { intl.formatMessage(MESSAGES.attachmentOptionsAdjust) }
        </button>
      </footer>
    </div>
  )
}

AttachmentOptions.propTypes = {
  recordAudio: PropTypes.func,
  uploadFile: PropTypes.func,
  intl: PropTypes.object
}

export default injectIntl(AttachmentOptions)
