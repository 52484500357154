import { DOCUMENTS } from "../../../../../models/Document/Document"

export default ({ intl, messages }) => {
  return [
    {
      value: DOCUMENTS.RUT,
      label: intl.formatMessage(messages['type_rut'])
    }
  ]
}
