import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Indication from './Indication'
import messages from './Indication.messages'

export default class IndicationError extends Component {
  get errorSearch() {
    const { intl, form: { toucheds }, id, documentValidation } = this.props
    const {disabledSearch } = this.props

    const touched = toucheds[id] && !disabledSearch

    const hasSizeIncorrect = documentValidation.error && touched

    if (hasSizeIncorrect) return intl.formatMessage(messages.indication_search_error_size, documentValidation.size)

    return null
  }

  render() {
    return (
      <Indication
        {...this.props}
        errorSearch={this.errorSearch}
      />
    )
  }
}

IndicationError.propTypes ={
  intl: PropTypes.object,
  disabledTypeSearch: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  customProps: PropTypes.object,
  documentValidation: PropTypes.object,
  form: PropTypes.object,
  searchType: PropTypes.string,
  search: PropTypes.string,
  disabledSearch: PropTypes.bool,
  changeIntroducer: PropTypes.bool,
}
