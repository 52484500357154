import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'

import Icon from 'src/base/Icon'
import MapsMarker from './Maps.marker'

const bem = bemClassName.bind(null, 'maps__display')

class MapsDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fullscreen: false
    }

    this.setRef = this.setRef.bind(this)
    this.onMapLoad = this.onMapLoad.bind(this)
    this.handleChangeCenter = this.handleChangeCenter.bind(this)
    this.enterFullscreen = this.enterFullscreen.bind(this)
    this.exitFullscreen = this.exitFullscreen.bind(this)
  }

  componentDidUpdate(props, state) {
    const { fullscreen } = this.state

    if(this.map && (fullscreen !== state.fullscreen)) {
      this.setFullscreenButton()
    }
  }

  onMapLoad(gmap) {
    if(!gmap) return

    this.map = gmap.map

    this.setFullscreenButton()

    return this.props.onMapLoad(this.map)
  }

  handleChangeCenter({ center }) {
    const { fixedAtCenter, handleGeoCode } = this.props

    fixedAtCenter && handleGeoCode(center)
  }

  setRef(node) {
    this.display = node
  }

  setFullscreenButton() {
    const fullscreenControlWrapper = document.createElement('div')
    const { RIGHT_TOP } = window.google.maps.ControlPosition
    const control = this.map.controls[RIGHT_TOP]

    fullscreenControlWrapper.className = 'maps__display__fullscreen__wrapper'

    ReactDOM.render(this.renderFullscreenButton(), fullscreenControlWrapper)

    control.pop()
    control.push(fullscreenControlWrapper)
  }

  enterFullscreen(event) {
    event.preventDefault()

    enterFullscreen(this.display)

    this.setState({ fullscreen: true })
  }

  exitFullscreen() {
    exitFullscreen()

    this.setState({ fullscreen: false })
  }

  get bootstrapURLKeys() {
    const { GMKey, locale: { language, region } } = this.props

    return {
      key: GMKey,
      libraries: 'places',
      language,
      region
    }
  }

  get options() {
    return {
      mapTypeControl: true,
      fullscreenControl: false
    }
  }

  render() {
    const { center, zoom, hidden, fixedAtCenter } = this.props

    return (
      <div className={bem({ hidden })} ref={this.setRef}>
        <GoogleMapReact
          onChange={this.handleChangeCenter}
          onGoogleApiLoaded={this.onMapLoad}
          bootstrapURLKeys={this.bootstrapURLKeys}
          options={this.options}
          yesIWantToUseGoogleMapApiInternals
          center={center}
          defaultZoom={zoom}>
          {!fixedAtCenter && this.renderMarker(center, fixedAtCenter)}
        </GoogleMapReact>
        {fixedAtCenter && this.renderMarker(center, fixedAtCenter)}
      </div>
    )
  }

  renderMarker(center, fixed) {
    return (
      <MapsMarker
        className={bem('marker', { fixed })}
        {...center}
      />
    )
  }

  renderFullscreenButton() {
    const { fullscreen } = this.state

    const action = fullscreen ? this.exitFullscreen : this.enterFullscreen
    const icon = fullscreen ? 'ui_fullscreen_off' : 'ui_fullscreen_on'

    return (
      <div
        className={bem('fullscreen')}
        onClick={action}>
        <Icon name={icon} className={bem('fullscreen__icon')} />
      </div>
    )
  }
}

MapsDisplay.propTypes = {
  className: PropTypes.string,
  handleGeoCode: PropTypes.func,
  fixedAtCenter: PropTypes.bool,
  GMKey: PropTypes.string,
  locale: PropTypes.object,
  center: PropTypes.object,
  zoom: PropTypes.number,
  hidden: PropTypes.bool,
  onMapLoad: PropTypes.func
}

MapsDisplay.defaultProps = {
  zoom: 15
}

export default MapsDisplay

function enterFullscreen(element) {
  if(element.requestFullscreen)
    element.requestFullscreen()

  if(element.mozRequestFullScreen)
    element.mozRequestFullScreen()

  if(element.webkitRequestFullscreen)
    element.webkitRequestFullscreen()

  if(element.msRequestFullscreen)
    element.msRequestFullscreen()
}

function exitFullscreen() {
  if(document.exitFullscreen) {
    document.exitFullscreen()
  }

  if(document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  }

  if(document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}
