import { getContext } from '../../infra/Api/Api.context'

const START_LOADING = 'START_LOADING'
const STOP_LOADING = 'STOP_LOADING'

const initialState = {
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true }
    case STOP_LOADING:
      return { ...state, loading: false }
    default:
      return state
  }
}

export function SetLoading(isLoading) {
  const {
    dispatch
  } = getContext()
  dispatch({
    type: isLoading ? START_LOADING : STOP_LOADING
  })
}
