import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Form from 'src/base/Form'
import RegisterErrorModal from 'src/views/Register/RegisterErrorModal'

class FormDefault extends PureComponent {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(data) {
    const { goToNextPage, onSubmit } = this.props

    return onSubmit(data, goToNextPage)
  }

  render() {
    const {
      connectivity: { isOffline },
      confirmLabel,
      disabledSubmit,
      error,
      formSchema,
      goToPreviousPage,
      loading,
      shouldWaitForDeps,
      step: { name, actions: { previous, next }, updateRule, title, subtitle },
      touchAfterReload,
    } = this.props

    if(loading && shouldWaitForDeps) return null

    return (
      <Form
        cancelAction={previous ? goToPreviousPage : null}
        className={`${name}__form`}
        confirmLabel={confirmLabel}
        disabledSubmit={disabledSubmit}
        error={error}
        formSchema={formSchema}
        hiddenSubmit={!next}
        key={name}
        modal={RegisterErrorModal}
        offline={isOffline}
        onSubmit={this.onSubmit}
        title={title}
        subtitle={subtitle}
        updateRule={updateRule}
        touchAfterReload={touchAfterReload}
      />
    )
  }
}

FormDefault.propTypes = {
  className: PropTypes.string,
  connectivity: PropTypes.object,
  confirmLabel: PropTypes.object,
  disabledSubmit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  formSchema: PropTypes.object.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paper: PropTypes.bool,
  person: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  shouldWaitForDeps: PropTypes.bool,
  step: PropTypes.object.isRequired,
  touchAfterReload: PropTypes.array,
  updateRule: PropTypes.string
}

FormDefault.defaultProps = {
  shouldWaitForDeps: true
}

export default FormDefault
