import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import bemClassName from 'bem-classname'
import IndicationModal from './Indication.modal'
import Input from 'src/base/Form/Components/Input'
import Select from 'src/base/Form/Components/Select'
import Button, { TYPES } from 'src/base/Button'

import messages from './Indication.messages'

import './Indication.styl'

const bem = bemClassName.bind(null, 'indication')

export class Indication extends PureComponent {
  render() {
    return (
      <div className={bem('fieldset', ['group'])}>
        <div className={bem('fieldset')}>
          {this.renderIndicationSelector()}
          {this.renderTypeSelector()}
        </div>
        <div className={bem('fieldset')}>
          {this.renderSearchInput()}
          {this.renderButton()}
        </div>
        {this.renderModal()}
      </div>
    )
  }

  renderModal() {
    const {
      id,
      search,
      searchType,
      showModal,
      handleConfirmModal,
      handleCancelModal,
      consultants: {
        consultants,
        loading,
        error
      },
      customProps: {
        personId
      }
    } = this.props

    return (
      <IndicationModal
        id={id}
        personId={personId}
        showModal={showModal}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
        consultants={consultants}
        loading={loading}
        error={error}
        searchType={searchType}
        search={search}
      />
    )
  }

  renderIndicationSelector() {
    const {
      intl,
      options,
      disabled,
      messages,
      hasIndication,
      errorHasIndication,
      handleChangeHasIndication,
      customProps: {
        hiddenIndicationQuestion
      }
    } = this.props

    const label = intl.formatMessage(messages.has_Indication)
    const placeholder = intl.formatMessage(messages.has_Indication_placeholder)
    const noOptionsMessage = intl.formatMessage(messages.has_Indication_no_options_message)

    if (hiddenIndicationQuestion) return null

    return (
      <Select
        id='hasIndication'
        className={bem('has')}
        error={errorHasIndication}
        disabled={disabled}
        value={hasIndication}
        onChange={handleChangeHasIndication}
        options={options.indicationOptions}
        noOptionsMessage={noOptionsMessage}
        label={label}
        placeholder={placeholder}
      />
    )
  }

  renderTypeSelector() {
    const {
      id,
      intl,
      label,
      options,
      messages,
      required,
      searchType,
      hasIndication,
      errorSearchType,
      disabledTypeSearch,
      handleChangeSearchType,
    } = this.props

    const placeholder = intl.formatMessage(messages.indication_search_type_placeholder)

    return (
      <Select
        id={`${id}-typeIndication`}
        error={errorSearchType}
        value={searchType}
        className={bem('search_type')}
        onChange={handleChangeSearchType}
        options={options.typeOptions}
        label={label}
        placeholder={placeholder}
        disabled={disabledTypeSearch}
        required={hasIndication || required}
      />
    )
  }

  renderSearchInput() {
    const {
      id,
      mask,
      search,
      onKeyDown,
      searchType,
      errorSearch,
      labelSearch,
      searchSuccess,
      disabledSearch,
      handleChangeSearch,
    } = this.props

    return (
      <Input
        id={`${id}-input`}
        error={errorSearch}
        value={search}
        label={labelSearch}
        className={bem('search', { 'search-success': searchSuccess })}
        onChange={handleChangeSearch}
        onKeyDown={onKeyDown}
        disabled={disabledSearch}
        required={!!searchType}
        mask={mask}
      />
    )
  }

  renderButton() {
    const { intl, disabledButton, messages, handleModal, id } = this.props

    return (
      <Button
        id={`${id}-button`}
        type={TYPES.PRIMARY}
        className={bem('button_search')}
        disabled={disabledButton}
        onClick={handleModal}>
        {intl.formatMessage(messages['indication_search'])}
      </Button>
    )
  }
}

Indication.propTypes = {
  id: PropTypes.string,
  personId: PropTypes.string,
  searchOffline: PropTypes.string,
  handleChangeOffline: PropTypes.func,
  connectivity: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  intl: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  messages: PropTypes.object,
  consultants: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  form: PropTypes.object,
  hasIndication: PropTypes.bool,
  searchType: PropTypes.string,
  search: PropTypes.string,
  introducerSellerId: PropTypes.any,
  showModal: PropTypes.bool,
  changeIntroducer: PropTypes.bool,
  hasIndicationError: PropTypes.object,
  searchTypeError: PropTypes.object,
  searchError: PropTypes.object,
  onKeyDown: PropTypes.func,
  handleConfirmModal: PropTypes.func,
  handleCancelModal: PropTypes.func,
  handleChangeHasIndication: PropTypes.func,
  handleChangeSearchType: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  disabledSearch: PropTypes.bool,
  disabledButton: PropTypes.bool,
  disabledTypeSearch: PropTypes.bool,
  searchSuccess: PropTypes.bool,
  handleModal: PropTypes.func,
  errorSearch: PropTypes.string,
  errorSearchType: PropTypes.string,
  errorHasIndication: PropTypes.string,
  customProps: PropTypes.object,
  required: PropTypes.bool,
  mask: PropTypes.string,
  labelSearch: PropTypes.string,
  options: PropTypes.object
}

Indication.defaultProps = {
  introducerSellerId: '',
  id: 'indication',
  messages
}

export default Indication
