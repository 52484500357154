import { getContext } from 'src/infra/Api/Api.context'
import { CLIENT_NAMES } from '../../infra/Api/Api'

import Consultants from 'src/viewModels/Consultants'
import Error from 'src/viewModels/Error'

const CONSULTANTS_DOCUMENT_LOADING = 'natura-caduni/Consultants/CONSULTANTS_DOCUMENT_LOADING'
const CONSULTANTS_DOCUMENT_SUCCESS = 'natura-caduni/Consultants/CONSULTANTS_DOCUMENT_SUCCESS'
const CONSULTANTS_DOCUMENT_ERROR = 'natura-caduni/Consultants/CONSULTANTS_DOCUMENT_ERROR'

export const types = [
  CONSULTANTS_DOCUMENT_LOADING,
  CONSULTANTS_DOCUMENT_SUCCESS,
  CONSULTANTS_DOCUMENT_ERROR
]

export function consultantsByDocumentsReducer(state, action, dependencies) {
  const { type, payload, error: actionError } = action
  let { consultants, loading, error } = state

  const isLoading = type === CONSULTANTS_DOCUMENT_LOADING
  const isSuccess = type === CONSULTANTS_DOCUMENT_SUCCESS
  const isError = type === CONSULTANTS_DOCUMENT_ERROR

  if (isSuccess) {
    const { data } = payload

    const introducers = data ? [].concat(payload.data) : []

    error = false
    loading = false
    consultants = new Consultants(introducers, dependencies)
  }

  if (isError) {
    loading = false
    error = new Error(actionError)
  }

  return {
    loading: isLoading || loading,
    consultants,
    error
  }
}

export function consultantsByDocuments(data) {
  const globalAppContext = getContext()

  const {
    dispatch,
  } = globalAppContext

  function getRequestPayload({ globalAppContext }) {
    const {
      CONSULTANTS_URL,
      REGISTRATION_TEAM_SEARCH_INDICATION,
      bffHeaders,
      countryId,
      companyId,
      headersAuthenticationInfo,
      isHostedUIEnabled,
      sellerId,
    } = getNeededInformationFromGlobalContext({ globalAppContext })

    const clientName = isHostedUIEnabled ? CLIENT_NAMES.DEFAULT_BFF : countryId
    const { requestHeaders } = getRequestHeaders({ isHostedUIEnabled, headersAuthenticationInfo })

    const requestUrl = isHostedUIEnabled
      ? REGISTRATION_TEAM_SEARCH_INDICATION(`document=${data.sellerDocument}&documentTypeId=${data.documentTypeId}`)
      : CONSULTANTS_URL.findByDocuments(data)

    return { clientName, requestUrl, requestHeaders }

    function getNeededInformationFromGlobalContext({ globalAppContext }) {
      const {
        urls: { CONSULTANTS },
        default_bff_client: { headers: bffHeaders },
        default_bff_urls: { REGISTRATION_TEAM_SEARCH_INDICATION },
        default_bff_apiKeys: { REGISTERS_APIKEY },
        locale: {
          id: countryId,
          companyId,
          configs
        },
        user: {
          sellerId,
          authentication: {
            xApikey,
            accessToken
          }
        },
      } = globalAppContext
      const isHostedUIEnabled = configs?.localization?.isHostedUIEnabled

      return {
        CONSULTANTS_URL: CONSULTANTS,
        REGISTRATION_TEAM_SEARCH_INDICATION,
        isHostedUIEnabled,
        bffHeaders,
        headersAuthenticationInfo: {
          globalPeoplePlatformApiKey: xApikey,
          registersBffApiKey: REGISTERS_APIKEY,
          userAccessToken: accessToken
        },
        sellerId,
        countryId,
        companyId,
      }
    }

    function getRequestHeaders({ isHostedUIEnabled, headersAuthenticationInfo }) {
      const {
        globalPeoplePlatformApiKey,
        registersBffApiKey,
        userAccessToken
      } = headersAuthenticationInfo

      const requestHeaders = isHostedUIEnabled ? {
        ...bffHeaders,
        'x-api-key': registersBffApiKey,
        Authorization: userAccessToken,
        countryid: countryId,
        companyid: companyId,
      } : {
        'x-api-key': globalPeoplePlatformApiKey,
        Authorization: userAccessToken,
        access_token: userAccessToken,
        documenttypeid: data.documentTypeId,
        userId: sellerId,
      }

      return { requestHeaders }
    }
  }

  const {
    clientName,
    requestUrl,
    requestHeaders
  } = getRequestPayload({ globalAppContext })

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'GET',
        url: requestUrl,
        headers: requestHeaders
      }
    }
  })
}
