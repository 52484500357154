import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  attachmentOptionsTitle: {
    defaultMessage: "Acceptance of Natura's conditions",
    id: 'record.audio.attachment.options.title'
  },
  attachmentOptionsInstructions: {
    defaultMessage: 'Please record or attach the audio of the intention reading the acceptance of Natura terms and conditions.',
    id: 'record.audio.attachment.options.instructions'
  },
  attachmentOptionsSubInstructions: {
    defaultMessage: 'Make sure the recording is audible and with no background noise.',
    id: 'record.audio.attachment.options.sub.instructions'
  },
  attachmentOptionsContent: {
    defaultMessage: 'You will need to grant access permissions to the microphone if the desired option is to record audio',
    id: 'record.audio.attachment.options.content.instructions'
  },
  attachmentOptionsRecord: {
    defaultMessage: 'Record',
    id: 'record.audio.attachment.options.record'
  },
  attachmentOptionsAdjust: {
    defaultMessage: 'Adjust',
    id: 'record.audio.attachment.options.adjust'
  },
  recordTermsTitle: {
    defaultMessage: "Acceptance of Natura's conditions",
    id: 'record.audio.title'
  },
  recordTermsContent: {
    defaultMessage: 'I, {name} identified with {type} number {number}, accept and authorize the processing of my personal data and the consultation and report to risk centers that Natura Cosméticos S.A. makes and I declare that read and accept their commercial conditions and privacy policy.',
    id: 'record.audio.content'
  }
})

export default MESSAGES
