import Upload from 'src/viewModels/Upload'

import { createUrlReducer, createUrl, types as createUrlTypes } from './Upload.createUrl'
import { uploadReducer, saveAction, types as uploadUrlReducerTypes } from './Upload.save'
import { uploadResetReducer, resetUpload, UPLOAD_RESET  } from './Upload.reset'

const dependencies = {
  createUrlReducer,
  uploadReducer,
  uploadResetReducer
}

const uploadDependencies = {
  createUrl,
  saveAction,
  resetUpload
}

const initialState = {
  upload: new Upload({}, uploadDependencies),
  loading: {},
  error: false,
}

export default function reducer(state = initialState, action = {}, _, injection = {}) {
  const { createUrlReducer, uploadReducer } = {
    ...injection,
    ...dependencies
  }

  const { type } = action

  if(createUrlTypes.includes(type))
    return createUrlReducer(state, action, uploadDependencies)

  if(uploadUrlReducerTypes.includes(type))
    return uploadReducer(state, action, uploadDependencies)

  if(UPLOAD_RESET.includes(type))
    return uploadResetReducer(state, action, uploadDependencies)

  return state
}
