import React from 'react'
import PropTypes from 'prop-types'
import { messages } from './Messages'
import { findAddressUse } from 'src/models/Address'
import { ADDRESS_USE } from 'src/models/Address/AddressUse'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    node: <Title intl={intl} messages={messages} />
  }
}

function Title(props) {
  return (
    <h6 className="form__group__title">
      {getTile(props)}
    </h6>
  )
}

function getTile({ intl, messages, value, index }) {
  const isMailingAddress = value[index].addressUse.find(findAddressUse, { useId: ADDRESS_USE.MAILLING })

  if (isMailingAddress) {
    return intl.formatMessage(messages.address_residential)
  }

  const isDeliveryAddress = value[index].addressUse.find(findAddressUse, { useId: ADDRESS_USE.DELIVERY })

  if (isDeliveryAddress) {
    return intl.formatMessage(messages.address_delivery)
  }

  return intl.formatMessage(messages.address_alternative)
}

Title.propTypes = {
  title: PropTypes.string
}
