import { getContext } from 'src/infra/Api/Api.context'

export const PROFILE_ORIGEN_LOADING = 'natura-caduni/People/PROFILE_ORIGEN_LOADING'
export const PROFILE_ORIGEN_SUCCESS = 'natura-caduni/People/PROFILE_ORIGEN_SUCCESS'
export const PROFILE_ORIGEN_ERROR = 'natura-caduni/People/PROFILE_ORIGEN_ERROR'

export const TYPES_ORIGEN = [PROFILE_ORIGEN_LOADING, PROFILE_ORIGEN_SUCCESS, PROFILE_ORIGEN_ERROR]

export const PROFILE_LEADER_LOADING = 'natura-caduni/People/PROFILE_LEADER_LOADING'
export const PROFILE_LEADER_SUCCESS = 'natura-caduni/People/PROFILE_LEADER_SUCCESS'
export const PROFILE_LEADER_ERROR = 'natura-caduni/People/PROFILE_LEADER_ERROR'

export const TYPES_LEADER = [PROFILE_LEADER_LOADING, PROFILE_LEADER_SUCCESS, PROFILE_LEADER_ERROR]

export const PROFILE_INTRODUCER_LOADING = 'natura-caduni/People/PROFILE_INTRODUCER_LOADING'
export const PROFILE_INTRODUCER_SUCCESS = 'natura-caduni/People/PROFILE_INTRODUCER_SUCCESS'
export const PROFILE_INTRODUCER_ERROR = 'natura-caduni/People/PROFILE_INTRODUCER_ERROR'

export const TYPES_INTRODUCER = [PROFILE_INTRODUCER_LOADING, PROFILE_INTRODUCER_SUCCESS, PROFILE_INTRODUCER_ERROR]

export const PROFILE_REGISTRANT_LOADING = 'natura-caduni/People/PROFILE_REGISTRANT_LOADING'
export const PROFILE_REGISTRANT_SUCCESS = 'natura-caduni/People/PROFILE_REGISTRANT_SUCCESS'
export const PROFILE_REGISTRANT_ERROR = 'natura-caduni/People/PROFILE_REGISTRANT_ERROR'

export const TYPES_REGISTRANT = [PROFILE_REGISTRANT_LOADING, PROFILE_REGISTRANT_SUCCESS, PROFILE_REGISTRANT_ERROR]

export function getConsultantsById(personId, consultantId, types) {
  if(!parseInt(consultantId)) return

  const {
    dispatch,
    urls: { CONSULTANTS },
    locale: { id },
    user: { sellerId, authentication: { accessToken } }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'GET',
        url: CONSULTANTS.find({ personId, sellerId: consultantId }),
        params: { relations: 'approvedCandidate' },
        headers: {
          Authorization: accessToken,
          access_token: accessToken,
          userId: sellerId,
        }
      }
    }
  })
}
