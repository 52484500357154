import { getContext } from 'src/infra/Api/Api.context'

import Error from 'src/viewModels/Error'
import DigitalSpace from 'src/viewModels/DigitalSpace'

export const DIGITAL_SPACE_SAVE_LOADING = 'natura-caduni/DIGITAL_SPACE_SAVE_LOADING'
export const DIGITAL_SPACE_SAVE_SUCCESS = 'natura-caduni/DIGITAL_SPACE_SAVE_SUCCESS'
export const DIGITAL_SPACE_SAVE_ERROR = 'natura-caduni/DIGITAL_SPACE_SAVE_ERROR'

export const types = [ DIGITAL_SPACE_SAVE_LOADING, DIGITAL_SPACE_SAVE_SUCCESS, DIGITAL_SPACE_SAVE_ERROR ]

export function digitalSpaceSaveReducer(state, action, dependencies) {
  const { payload, type } = action
  let { digitalSpace, error } = state

  const isLoading = type === DIGITAL_SPACE_SAVE_LOADING
  const hasSuccess = type === DIGITAL_SPACE_SAVE_SUCCESS
  const hasError = type === DIGITAL_SPACE_SAVE_ERROR

  if(hasSuccess) {
    const { data } = payload
    digitalSpace = new DigitalSpace(data, dependencies)

    error = false
  }

  if(hasError) {
    error = new Error(action.error)
  }

  return {
    digitalSpace,
    loading: isLoading,
    error,
  }

}

export function saveDigitalSpace(url, personId) {
  const {
    dispatch,
    urls: { DIGITAL_SPACE },
    locale: { id },
    user: {
      sellerId,
      authentication: { accessToken }
    }
  } = getContext()

  return dispatch({
    types,
    payload: {
      client: id,
      request: {
        method: 'POST',
        url: DIGITAL_SPACE.save(url),
        headers: {
          access_token: accessToken,
          userId: sellerId,
          personId,
          externalId: id
        }
      }
    }
  })

}
