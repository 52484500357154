import * as yup from 'yup'
import moment from 'moment'
import { defineMessages } from 'react-intl'
import { parseApiDate, parseDateToApi } from 'src/lib/SpeckAdapter/Yup.customs'

const messages = defineMessages({
  admission_label: {
    defaultMessage: 'Admission data',
    id: 'form.field.admission.label'
  },
  admission_placeholder: {
    defaultMessage: 'MM/DD/YYYY',
    id: 'form.field.admission.placeholder'
  },
  admission_error_invalid: {
    defaultMessage: 'Invalid date',
    id: 'form.field.admission.invalid'
  },
  admission_error_lower_birth: {
    defaultMessage: 'An admission data can not be less than a birth data',
    id: 'form.field.admission.error.lower.birth'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { additionalInformation: { admissionDate } }, locale } = injection
  const { configs: { localization: { date: { format } } } } = locale

  const defaultValue = admissionDate ? parseApiDate(admissionDate, format) : ''

  return {
    id: 'admissionDate',
    label: intl.formatMessage(messages['admission_label']),
    placeholder: intl.formatMessage(messages['admission_placeholder']),
    mask: '99/99/9999',
    disabledBy: ['establishmentId'],
    defaultValue,
    validations: yup
      .date()
      .format(format)
      .typeError(intl.formatMessage(messages['admission_error_invalid']))
      .test({
        name: 'admission error lower birth',
        message: intl.formatMessage(messages['admission_error_lower_birth']),
        test() {
          const { options: { context: { values = {}, errors } = {} } } = this
          const isValidBirthday = values.birthday && !errors.birthday

          if (!isValidBirthday) return true

          const birthday = parseDateToApi(values.birthday, format)
          const admissionDate = parseDateToApi(values.admissionDate, format)

          return moment(admissionDate).isAfter(birthday)
        }
      })
  }
}
