import { defineMessages } from 'react-intl'

const messages = defineMessages({
  address_map: {
    defaultMessage: 'Address',
    id: 'form.field.address.map'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'gmap',
    label: intl.formatMessage(messages['address_map']),
    required: true,
    type: 'maps',
    offlineType: 'input',
  }
}
