export const ATTACHMENT_TYPES = {
  CNH_FRONT: 1, //Carteira Nacional de Habilitação
  CNH_BACK: 2,
  RG_FRONT: 3, //Registro Geral
  RG_BACK: 4,
  RNE_FRONT: 5, //Registro Nacional de Estrangeiros
  RNE_BACK: 6,
  CELULA_CIUDADANIA_FRONT: 7,
  CELULA_CIUDADANIA_BACK: 8,
  CELULA_EXTRANJERIA_FRONT: 9,
  CELULA_EXTRANJERIA_BACK: 10,
  COMPROBANTE_DOMICILIO: 11,
  TERMS_AND_CONDITIONS_ACCEPTANCE: 12, //Acceptacion de los términos y condiciones - Arquivo de áudio
  SELFIE: 13, //Fotografia de la intencion - Selfie
  COMPROBANTE_ACTIVIDAD_ECONOMICA: 14,
  COMPROVANTE_DOMICILIO: 15,
  DNI_PERU_FRONT: 16,
  DNI_PERU_BACK: 17,
  CARNET_EXTRANJERIA_FRONT: 18,
  CARNET_EXTRANJERIA_BACK: 19,
  PASSPORT_FRONT: 20,
  PASSPORT_BACK: 21,
  RUC_FRONT: 22,
  RUC_BACK: 23,
  RUC_PF_FRONT: 24,
  RUC_PF_BACK: 25,
  PROFILE_PICTURE: 26,
  RUT_FRONT: 27,
  RUT_BACK: 28,
  DNI_ARGENTINA_FRONT: 29,
  DNI_ARGENTINA_BACK: 30,
}
