import { connect } from 'react-redux'
import { SmsTokenValidation } from 'src/reducers/ContactValidation/ContactValidation.verify'
import { ResendToken } from 'src/reducers/ContactValidation/ContactValidation.resendToken'

export function mapStateToProps(state, ownProps) {
  const {
    person: { person: registerPerson },
    profile: { error: personError, person: profilePerson },
    contactValidation: { isValid, loading, error: validationError, resent }
  } = state
  const person = registerPerson.personId ? registerPerson : profilePerson
  const error = personError || validationError

  return {
    ...ownProps,
    person,
    isValid,
    error,
    resent,
    loading,
    smsTokenValidation(data) {
      return SmsTokenValidation(data)
    },
    resendToken(data) {
      return ResendToken(data)
    }
  }
}

export default connect(mapStateToProps)
