import { ADDRESS_USE } from 'src/models/Address/AddressUse'
import { findAddress } from 'src/models/Address'
import { parseAddress } from './AddressHome'

export default function Builder(params) {
  const { intl, messages, person } = params
  const { addresses = [] } = person

  const mailingAddress = addresses.find(findAddress, { useId: ADDRESS_USE.MAILLING  })
  const deliveryAddress = addresses.find(findAddress, { useId: ADDRESS_USE.DELIVERY  })

  let fields = [{
    field: intl.formatMessage(messages.box_address_value_delivery)
  }]

  if(deliveryAddress && !mailingAddress.isUseToDelivery) {
    fields = parseAddress(deliveryAddress, params)
  }

  return {
    id: 'profile-address-delivery',
    title: intl.formatMessage(messages.box_address_delivery_title),
    icon: 'ui_office',
    wrapper: 'default',
    content: 'list',
    fields
  }
}
