import { getTenantConfig } from './getTenantConfig'

export const getLayoutsConfig = (tenantId) => {
  const localization = getTenantConfig(tenantId)

  return {
    social: localization.social ?? [],
    apps: localization.apps ?? [],
  }
}
