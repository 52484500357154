import { getSectionTitle } from './strategies/getSectionTitle'
import { touchAfterReload } from './FormSchema'
import { onSubmitionMfe } from './SubmitMfe'
import { isPersonalDataSearchAddressWithZipCode, isDisableFieldsPersonalData } from 'src/config'

export function buildPersonalDataPropsMicrofrontend(deps) {
  const { title, subtitle, label } = getSectionTitle()
  const {
    person,
    loader: { personLoading, resendLoading, globalLoading },
    locale: { id, tenantId, companyId },
    user: { sellerId, name: userName, authenticated, isCSC, authentication },
  } = deps
  const {
    personId,
    businessModel: { businessModelId, functionId, roleId }
  } = person
  const onSubmit = onSubmitionMfe(deps)
  const personalDataSearchAddressWithZipCodeToggle = isPersonalDataSearchAddressWithZipCode()
  const disableFieldsPersonalData = isDisableFieldsPersonalData()
  return {
    touchAfterReload,
    disabledSubmit: true,
    onSubmit,
    step: {
      title,
      subtitle,
      label
    },
    loader: personLoading || resendLoading || globalLoading,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'personalData',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
        userName,
        personalDataSearchAddressWithZipCodeToggle,
        disableFieldsPersonalData,
        authorization: authenticated ? authentication.accessToken : ''
      },
      authenticatedUser: {
        authenticated,
        sellerId,
        isAttendance: isCSC
      },
      onSubmit
    }
  }
}
