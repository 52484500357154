import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import MaskedInput from 'react-input-mask'

import Label from 'src/base/Form/Components/Label'
import Message from 'src/base/Form/Components/Message'
import Icon from 'src/base/Icon'
import Loader from 'src/base/Loader'

import './Input.styl'
import { isDefaultFlowToggleActive } from '../../../../config'

class Input extends PureComponent {

  constructor(props) {
    super(props)

    this.bem = isDefaultFlowToggleActive() ? bemClassName.bind(null, 'defaultInput') : bemClassName.bind(null, 'input')
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(event) {
    const { onChange } = this.props
    const { value } = event.target

    onChange && onChange(value, event)
  }

  onBlur(event) {
    const { onBlur } = this.props
    const { value } = event.target

    onBlur && onBlur(value, event)
  }

  get value() {
    const { value } = this.props

    return (typeof value === 'string') ? value : ''
  }

  get input() {
    return this.props.mask ? this.renderMaskedInput() : this.renderInput()
  }

  get type() {
    const { type } = this.props

    return typeof type === 'string' ? type : 'text'
  }

  get shouldShowIcon() {
    const { customProps: { icon, alwaysShowIcon }, value, touched, error } = this.props

    if (alwaysShowIcon) {
      return icon
    }

    return !error && !!value && touched && icon
  }

  render() {
    const { className, disabled, error, hidden, success } = this.props

    return (
      <div className={classNames(this.bem({ disabled, error, success, hidden }), className)} data-error={!!error}>
        {this.renderLabel()}
        {this.input}
        {this.renderMessage()}
        {this.renderInfoText()}
      </div>
    )
  }

  renderLabel() {
    const { id, label, required, error, disabled, showAsterisk } = this.props

    if (!label) return null

    return (
      <Label
        className={this.bem('label')}
        id={id}
        label={label}
        showAsterisk={showAsterisk}
        required={required}
        disabled={disabled}
        error={!!error}
      />
    )
  }

  renderInfoText() {
    const { customProps } = this.props

    if (!customProps.infoText) return null

    return <div><Message
      className={this.bem('infoText')}
      message={customProps.infoText}
      error={''}
      disabled
    /></div>
  }

  renderInput() {
    const {
      id,
      disabled,
      placeholder,
      htmlProps,
      onKeyDown,
      onPaste,
    } = this.props

    return (
      <div className={this.bem('container')}>
        {this.renderIcon()}
        {this.renderLoader()}
        <input
          className={this.bem('component')}
          id={id}
          name={id}
          data-testid={id}
          placeholder={placeholder}
          disabled={!!disabled}
          type={this.type}
          value={this.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          {...htmlProps}
        />
      </div>
    )
  }

  renderMaskedInput() {
    const {
      id,
      disabled,
      maskChar,
      mask,
      placeholder,
      htmlProps,
      onKeyDown,
      onPaste,
    } = this.props

    return (
      <div className={this.bem('container')}>
        {this.renderIcon()}
        {this.renderLoader()}
        <MaskedInput
          className={this.bem('component')}
          id={id}
          name={id}
          data-testid={id}
          placeholder={placeholder}
          disabled={!!disabled}
          type={this.type}
          mask={mask}
          maskChar={maskChar}
          value={this.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          {...htmlProps}
        />
      </div>
    )
  }

  renderIcon() {
    if (!this.shouldShowIcon) return null

    return (<Icon name={this.props.customProps.icon} className={this.bem('icon')} />)
  }

  renderMessage() {
    const { message, error, disabled } = this.props
    if (!message && !error) return null

    return <Message
      className={this.bem('message')}
      message={message}
      error={error || ''}
      disabled={disabled}
    />
  }

  renderLoader() {
    const { loading } = this.props

    if (!loading) return null

    return (
      <Loader className={this.bem('loader')} size={20} transparent={true} fixed={false} />
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  showAsterisk: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  id: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  hidden: PropTypes.bool,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maskChar: PropTypes.string,
  htmlProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  customProps: PropTypes.object,
  touched: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  maskChar: null,
  mask: false,
  customProps: {}
}

export default Input
