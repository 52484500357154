import { normalizePhones } from 'src/models/Phone'
import { DOCUMENTS } from 'src/models/Document'

const EMAIL = 1

export function onSubmition(deps) {
  const { person, locale: { configs }, user } = deps

  return function (data, callback) {
    const parsedData = parseData({ data, person, configs, user })

    return person.save(parsedData, callback)
  }
}

function parseData({ data, person, configs, user }) {
  const {
    policiesAcceptedGroup,
    birthday,
    firstName,
    lastName,
    gender,
    introducerSellerId,
    nationalityId,
  } = data

  const ruc = { type: DOCUMENTS.RUC, document: data.ruc }
  const documents = data.ruc ? [...data.documents, ruc] : data.documents
  const phones = data.phones.map(normalizePhones, { configs, phones: person.phones })
  const emails = data.emails && data.emails.reduce(reduceEmails, person.emails)
  const policiesAccepted = person.policiesAccepted.filterAccepteds(policiesAcceptedGroup)
  const additionalInformation = mapAdditionalInformation(data, user)
  const reviewStep = { ...person.reviewStep, personData: true, step: 'address' }

  return {
    birthday,
    firstName,
    lastName,
    introducerSellerId,
    nationalityId,
    phones,
    gender,
    emails,
    policiesAccepted,
    additionalInformation,
    documents,
    reviewStep,
    name: `${firstName.trim()} ${lastName.trim()}`
  }
}

function mapAdditionalInformation(data, user) {
  const { introducerSellerId, registrantId } = data

  return {
    introducerSellerId: introducerSellerId ? introducerSellerId : null,
    registrantId: registrantId ? registrantId : user.sellerId
  }
}

export function reduceEmails(accum, current = {}, index, array) {
  const [{ email, sequence } = {}] = accum
  const { email: currentEmail } = current

  const isLastEmail = index === array.length - 1

  if (isLastEmail)
    accum.splice(0, 1)

  if (!currentEmail)
    return accum

  if (currentEmail === email)
    return [...accum, { email: currentEmail, type: EMAIL, sequence }]

  return [...accum, { email: currentEmail, type: EMAIL }]
}
