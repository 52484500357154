import { getDefaultFieldsSchema } from './getFieldsSchema.default'
import { getChileFieldsSchema } from './getFieldsSchema.chl'
import { getArgentinaFieldsSchema } from './getFieldsSchema.arg'

const strategies = {
  CHL: getChileFieldsSchema,
  ARG: getArgentinaFieldsSchema,
  __default__: getDefaultFieldsSchema
}

export const getFieldsSchema = (localeId, { intl, dependencies }) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy({ intl, dependencies })
}
