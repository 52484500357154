import { RegisterSchemaBuilder } from './Register'
import { ProfileSchemaBuilder } from './Profile'
import { MyRegistersSchemaBuilder, parsePerson, touchAfterReload } from './MyRegisters'

export default {
  MyRegisters: {
    MyRegistersSchemaBuilder,
    parsePerson,
    touchAfterReload
  },
  Profile: {
    ProfileSchemaBuilder
  },
  Register: {
    RegisterSchemaBuilder
  }
}
