import { DOCUMENTS } from 'src/models/Document'

export default ({ intl, messages }) => {
  return [
    {
      value: DOCUMENTS.DNI_ARGENTINA,
      label: intl.formatMessage(messages['type_dni'])
    }
  ]
}
