import { getContext } from 'src/infra/Api/Api.context'
import Upload from 'src/viewModels/Upload'

export const UPLOAD_RESET = 'natura-caduni/People/UPLOAD_RESET'


export function uploadResetReducer(state, action, uploadDependencies) {
  const { error } = state

  const upload = new Upload({}, uploadDependencies)

  return {
    loading: {},
    error,
    upload,
  }
}

export function resetUpload() {
  const { dispatch } = getContext()

  return dispatch({
    type: UPLOAD_RESET
  })
}
