import React from 'react'
import Helmet from 'react-helmet'
import { useCompany } from '../../hooks/useCompany'
import { COMPANY_ID, COMPANY_NAME } from '../../models/Company'
import {enableThemeElo} from '../../utils/eloTheme'
export const FaviconConfig = () => {
  const { companyId } = useCompany()

  const isProduction = process.env.NODE_ENV === 'production'
  const companyName = getCompanyNameFormattedToLowerCase({ companyId })
  const faviconHref = getFaviconHref({ isProduction, companyName })

  return (
    <>
      {faviconHref && (
        <Helmet>
          <link rel="shortcut icon" id="favicon" href={faviconHref} />
        </Helmet>
      )}
    </>
  )
}

function getCompanyNameFormattedToLowerCase({ companyId }) {
  if (companyId === COMPANY_ID.NATURA) {
    return COMPANY_NAME.NATURA.toLowerCase()
  }

  if (companyId === COMPANY_ID.AVON) {
    return COMPANY_NAME.AVON.toLowerCase()
  }

  return
}

function getFaviconHref({ isProduction, companyName }) {
  companyName = enableThemeElo() ? 'consultoria' : companyName
  
  return isProduction
    ? `/icons/${companyName}/favicon.ico`
    : `/public/icons/${companyName}/favicon.ico`
}
