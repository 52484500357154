import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class MapsMarker extends PureComponent {
  render(){
    const { className } = this.props

    return (
      <div className={classNames('marker', className)} />
    )
  }
}

MapsMarker.propTypes = {
  className: PropTypes.string
}

export default MapsMarker
