import * as yup from 'yup'
import { defineMessages } from 'react-intl'
import { lettersOnly, lettersOnlyPaste } from './Name.full'

const messages = defineMessages({
  name_mother_label: {
    defaultMessage: 'Mothers Full Name',
    id: 'form.field.name.mother.label'
  },
  name_mother_error: {
    defaultMessage: 'Must have at least 2 words',
    id: 'form.field.name.mother.error'
  },
  name_mother_error_characters: {
    defaultMessage: 'Special characters are not allowed',
    id: 'form.field.name.mother.special.characters'
  }
})

const dependencies = {
  messages
}

const allowedCharsRegex = new RegExp(/a-zãàáâèéêìíîõòóôùúüûçñ'` /)
const whitelistedCharsRegex = new RegExp(`^[${allowedCharsRegex.source}]+$`, 'i')

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { motherName: defaultValue } = {}, customProps } = injection

  const nameMotherError = intl.formatMessage(messages['name_mother_error'])
  const nameMotherErrorCharacters = intl.formatMessage(messages['name_mother_error_characters'])

  return {
    id: 'nameMother',
    label: intl.formatMessage(messages['name_mother_label']),
    onKeyDown: lettersOnly,
    onPaste: lettersOnlyPaste,
    defaultValue,
    customProps,
    validations: checkSpecialCharacters(nameMotherErrorCharacters)
      .test('NAMEMOTHER', nameMotherError, validateMotherName)
  }
}

function validateMotherName(value) {
  if (!value) return false

  value = value.replace(/\s{2,}/g, " ")
  const result = value.split(" ")

  if (result.length > 1)
    return result[1].length > 0
  return false
}

export function checkSpecialCharacters(message) {
  return yup
    .string()
    .trim()
    .matches(whitelistedCharsRegex, { message, excludeEmptyString: true })
}
