import People from 'src/models/People'

import { peopleRehydrateReducer, types as peopleRehydrateTypes } from './People.rehydrate'
import { peopleGetReducer, getPeople, types as peopleGetTypes } from './People.get'
import { peopleResetReducer, resetPeople, types as peopleResetTypes } from './People.reset'

export const PEOPLE_RESET = 'natura-caduni/People/PEOPLE_RESET'

const dependencies = {
  peopleGetReducer,
  peopleRehydrateReducer,
  peopleResetReducer
}

const peopleDependencies = {
  getPeople,
  resetPeople
}

const initialState = {
  people: new People([], peopleDependencies),
  loading: false,
  error: false
}

export default function reducer(state = initialState, action = {}, _, injection = {}) {
  const {
    peopleGetReducer,
    peopleRehydrateReducer,
    peopleResetReducer
  } = { ...injection, ...dependencies }

  const { type } = action

  if(peopleRehydrateTypes.includes(type))
    return peopleRehydrateReducer(state, action, peopleDependencies)

  if(peopleGetTypes.includes(type))
    return peopleGetReducer(state, action, peopleDependencies)

  if(peopleResetTypes.includes(type)) {
    return peopleResetReducer(state, action, peopleDependencies)
  }

  return state
}
