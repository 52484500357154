import { getMalaysiaParsedData } from './getParsedData.mys'
import { getPeruParsedData } from './getParsedData.per'
import { ChileAddressParser } from './AddressParser.chl'
import { ColombiaAddressParser } from './AddressParser.col'
import { ArgentinaAddressParser } from './AddressParser.arg'
import { getMexicoParsedData } from './getParsedData.mex'
import { getEcuadorParsedData } from './getParsedData.ecu'

const strategies = {
  MYS: getMalaysiaParsedData,
  PER: getPeruParsedData,
  CHL: (params) => new ChileAddressParser().getParsedData(params),
  COL: (params) => new ColombiaAddressParser().getParsedData(params),
  ARG: (params) => new ArgentinaAddressParser().getParsedData(params),
  MEX: getMexicoParsedData,
  ECU: getEcuadorParsedData
}

export const getParsedData = (localeId, { data, configs }) => {
  const strategy = strategies[localeId]
  return strategy({ data, configs })
}
