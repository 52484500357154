import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

class ListFiltersFieldsSchema extends Entity {
  static SCHEMA = {
    title: adapter(yup.string()),
    fields: adapter(yup.array().of(
      yup.object().shape({
        id: yup.string(),
        placeholder: yup.string(),
        disabledBy: yup.array().of(yup.string()),
        fallbackValue: yup.array(),
        defaultValue: yup.array(),
        isDefault: yup.boolean(),
        isMulti: yup.boolean(),
        hasParent: yup.boolean(),
        noOptionsMessage: yup.string(),
        options: yup.array().of(yup.object().shape({
          label: yup.string(),
          value: yup.string(),
          exlude: yup.boolean(),
          parent: yup.string()
        }))
      })
    ))
  }
}

class ListFiltersSchema extends Entity {
  static SCHEMA = {
    fields: {
      validator: adapter(yup.array().of(yup.object())),
      type: ListFiltersFieldsSchema
    },
    custom: adapter(yup.mixed())
  }
}

export default ListFiltersSchema
