import ListSchema from 'src/viewModels/ListSchema'

import { createSearchConfig } from './Search'
import { createItemsSchema } from './Items'
import { createFiltersSchema } from './Filters'

export const touchAfterReload = ['documentTypeId']

export function MyRegistersSchemaBuilder(dependencies) {
  const items = createItemsSchema(dependencies)
  const search = createSearchConfig(dependencies)
  const filters = createFiltersSchema(dependencies)

  return new ListSchema({
    items,
    search,
    filters
  })
}

export function parsePerson(person) {
  const { personPagePath = '' } = this
  const {
    name,
    cnCode,
    personId,
    substatus,
    registrationDate,
    suggestedSalesHierarchy,
  } = person

  const { group } = suggestedSalesHierarchy
  const { description }  = substatus

  const path = createLink(personPagePath, personId)

  return {
    avatar: {
      label: name,
      path
    },
    cnCode: {
      label: cnCode,
      path
    },
    registrationTime: {
      label: registrationDate,
      path
    },
    situationDescription: {
      label: description,
      path
    },
    group: {
      label: group,
      path
    }
  }
}

function createLink(path, id) {
  return `${path}/${id}`
}
