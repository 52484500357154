import {LoginBrazil} from 'src/infra/Api/BRA/login/login.brazil'
import BrazilEndpoints from 'src/infra/Api/BRA/login/login.endpoints'

import {ROLES as COLOMBIA_ROLES} from 'src/infra/Api/COL/roles.colombia'
import ColombiaApiContext from 'src/infra/Api/COL/api.colombia'

import {ROLES as PERU_ROLES} from 'src/infra/Api/PER/roles.peru'
import PeruApiContext from 'src/infra/Api/PER/api.peru'

import {ROLES as CHILE_ROLES} from 'src/infra/Api/CHL/roles.chile'
import ChileApiContext from 'src/infra/Api/CHL/api.chile'

import {ROLES as MALAYSIA_ROLES} from 'src/infra/Api/MYS/roles.malaysia'
import MalaysiaApiContext from 'src/infra/Api/MYS/api.malaysia'

import {ROLES as ARGENTINA_ROLES} from 'src/infra/Api/ARG/roles.argentina'
import ArgentinaApiContext from 'src/infra/Api/ARG/api.argentina'

import DefaultBFFApiContext from 'src/infra/Api/DEFAULT/BFF'

import {RegistrationTeamService} from './strategies/registration-team/registration-team.service'
import {RegistrationTeamGateway} from './strategies/registration-team/registration-team.gateway'

import { PersonRolesService } from './strategies/person-roles/person-roles.service'
import { PersonRolesGateway } from './strategies/person-roles/person-roles.gateway'
import {
  isEnableDigitalStoreStepForPeruFlowToggleActive,
  isEnableDigitalStoreStepForColombiaFlowToggleActive,
  isEnableDigitalStoreStepForChileFlowToggleActive,
} from '../../config'

const strategies = {
  BRA: () => new LoginBrazil(BrazilEndpoints),
  COL: () => new RegistrationTeamService({
    gateway: new RegistrationTeamGateway({ apiContext: ColombiaApiContext, defaultBFFApiContext: DefaultBFFApiContext }),
    roles: COLOMBIA_ROLES,
    allowConsultantUserLoginToSeeDigitalStoreStatus: isEnableDigitalStoreStepForColombiaFlowToggleActive()
  }),
  PER: () => new RegistrationTeamService({
    gateway: new RegistrationTeamGateway({ apiContext: PeruApiContext, defaultBFFApiContext: DefaultBFFApiContext }),
    roles: PERU_ROLES,
    allowConsultantUserLoginToSeeDigitalStoreStatus: isEnableDigitalStoreStepForPeruFlowToggleActive()
  }),
  CHL: () => new RegistrationTeamService({
    gateway: new RegistrationTeamGateway({ apiContext: ChileApiContext, defaultBFFApiContext: DefaultBFFApiContext }),
    roles: CHILE_ROLES,
    allowConsultantUserLoginToSeeDigitalStoreStatus: isEnableDigitalStoreStepForChileFlowToggleActive()
  }),
  MYS: () => new PersonRolesService({
    gateway: new PersonRolesGateway(MalaysiaApiContext),
    roles: MALAYSIA_ROLES
  }),
  ARG: () => new RegistrationTeamService({
    gateway: new RegistrationTeamGateway({ apiContext: ArgentinaApiContext, defaultBFFApiContext: DefaultBFFApiContext }),
    roles: ARGENTINA_ROLES
  })
}

export default {
  create(localeId) {
    const strategy = strategies[localeId]
    if (!strategy) {
      throw new Error(`Login Command is not configured for locale ${localeId}`)
    }
    return strategy()
  }
}
