import React from 'react'

export default function Builder() {
  return {
    node: <Divider />
  }
}

export function Divider() {
  return <hr className="defaultForm__group__divider" />
}
