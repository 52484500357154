import React from 'react'
import PropTypes from 'prop-types'
import StateColors from './StateColors'

const DocumentFrontPhotoIcon = ({ size, state }) => {
  const styles = {
    fill: StateColors[state]
  }
  return (
    <svg width={size} height={size} viewBox="0 0 31 48">
      <path
        style={styles}
        fillRule="evenodd"
        d="M17.143 37.359v7.581h8.347c.855 0 1.549.684 1.549 1.524 0 .843-.694 1.526-1.549 1.526H5.698a1.538 1.538 0 0 1-1.549-1.526c0-.84.694-1.524 1.549-1.524h8.348v-7.581C5.887 36.726.631 30.984.631 22.676v-5.784c0-.842.694-1.526 1.549-1.526.853 0 1.548.684 1.548 1.526v5.784c0 3.97 1.269 7.025 3.773 9.078 2.054 1.686 4.928 2.612 8.092 2.612 3.166 0 6.04-.926 8.095-2.612 2.502-2.053 3.771-5.108 3.771-9.078v-5.784c0-.842.694-1.526 1.549-1.526s1.55.684 1.55 1.526v5.784c0 8.308-5.259 14.05-13.415 14.683zm-1.548-5.226c-5.831 0-9.598-3.712-9.598-9.457V9.46c0-5.745 3.767-9.457 9.598-9.457 5.832 0 9.599 3.712 9.599 9.457v13.216c0 5.745-3.767 9.457-9.599 9.457zm6.5-22.673c0-5.943-4.974-6.406-6.5-6.406-1.525 0-6.501.463-6.501 6.406v5.081h13.001V9.46zm0 8.133H9.094v5.083c0 2.546.895 4.372 2.662 5.428 1.416.847 3.012.974 3.837.974.825 0 2.423-.127 3.839-.974 1.768-1.056 2.663-2.882 2.663-5.428v-5.083z"
      />
    </svg>
  )
}

DocumentFrontPhotoIcon.propTypes = {
  size: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
}

export default DocumentFrontPhotoIcon
