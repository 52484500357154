import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import StepperBase from 'src/base/Stepper'

class Stepper extends PureComponent {
  render() {
    const { step: { name, currentStep, steps } } = this.props
    const key = `${name}__stepper`

    return (
      <StepperBase
        className={key}
        key={key}
        currentStep={currentStep}
        steps={steps}
      />
    )
  }
}

Stepper.propTypes = {
  className: PropTypes.string,
  step: PropTypes.object.isRequired
}

export default Stepper
