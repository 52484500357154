import * as yup from 'yup'
import getIndicationOptions from './Indication.options'
import { messages } from './Indication.messages'
import { lettersOnly } from 'src/configs/Fields/Name/Name.full'

export default function Builder(intl, injection) {
  const { locale: { id: localeId, configs }, person, shouldReviewPerson } = injection

  const {
    personId,
    additionalInformation: {
      introducerSellerId
    } = {}
  } = person

  const options = getIndicationOptions(intl)[localeId]

  return {
    id: 'introducerSellerId',
    label: intl.formatMessage(messages.indication_search_type),
    type: 'indication',
    offlineType: 'input',
    offlineLabel: intl.formatMessage(messages.indication_code),
    mask: '999999999999999',
    defaultValue: introducerSellerId && introducerSellerId.toString() || false,
    validations: yup.mixed().test({
      name: 'hasIndication',
      message: intl.formatMessage(messages.field_required),
      test: function(value) {
        return value === false || typeof value === 'string'
      }
    }),
    customProps: {
      configs: configs.localization,
      personId,
      lettersOnly,
      handleGetConsultants,
      shouldReviewPerson
    },
    options
  }
}

export function handleGetConsultants(params) {
  const {
    consultants: { consultants },
    searchType: type,
    personId,
    search
  } = params

  return consultants.search({ personId, search, type })
}
