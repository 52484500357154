import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import { ADDRESS_USE } from '../../../../../../../../models/Address/AddressUse'
import { store } from '../../../../../../../../App.state'

export const getMalaysiaParsedData = ({ data, configs }) => {
  const  {
    localization: {
      address: {
        levels
      }
    }
  } = configs
  const { person } = store.getState()

  const addresses = data.address.map(mapAddress, { levels })
  const complementaryAddress = getComplementaryAddressFrom(person, levels)

  localStorage.setItem('cumulativeAddress', getCumulativeAddress(addresses))

  addresses[0].geographicalLevels = [...addresses[0].geographicalLevels, ...complementaryAddress]

  return {addresses}
}

function getComplementaryAddressFrom(personStructure, levels) {
  const { person } = personStructure
  const results = []

  if( !person.addresses[0] ) return results

  const onlyDeliveryAddress = person.addresses.filter(address => {
    return address.addressUse.find(use =>  {
      return use.id === ADDRESS_USE.DELIVERY
    })
  })

  if( !onlyDeliveryAddress.length ) return results

  const geographicalLevels = onlyDeliveryAddress[0].geographicalLevels

  const levelsToFilter = [
    levels.STATE,
    levels.CITY,
    levels.STREET_ADDRESS_LINE_1,
    levels.STREET_ADDRESS_LINE_2
  ]

  geographicalLevels.forEach(geoLevel => {
    if( levelsToFilter.includes(geoLevel.level) ) {
      results.push({
        level: geoLevel.level,
        description: geoLevel.description,
      })
    }
  })
  return results
}

const getCumulativeAddress = (addresses) => {
  const {addressUse, geographicalLevels} = addresses[0]
  return JSON.stringify({ addressUse: buildAddressUse(addressUse), geographicalLevels})
}

const buildAddressUse = (addressUse) => {
  return addressUse.map((item) => {
    return {
      id: item.id,
      description: item.description
    }
  })
}

function mapAddress(address) {
  const {
    addressUse,
    countryName: country,
    zipCode,
  } = address

  const addressType = ADDRESS_TYPE.MAILING

  const geographicalLevels = [
    { level: this.levels.COUNTRY, description: country},
    { level: this.levels.ZIPCODE, code: parseInt(zipCode), description: zipCode}

  ]

  return {
    zipCode,
    addressType,
    addressUse,
    geographicalLevels
  }
}
