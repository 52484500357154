import { Collection } from 'speck-entity'
import { debounce, omit } from 'lodash'

import Person from 'src/models/Person'

export const PAGE_SIZE = 20

const defaultPagination = {
  _page: 0,
  _offset: 0,
  _limit: PAGE_SIZE
}

const relations = 'relations=approvedCandidate,roles'

// TODO: Remove from Person non person related stuff, and:
// - Create a viewModels for List (pagination, etc)
// - Create a viewModels for Search
// - Create a viewModels for Filter
export default class People extends Collection {
  static TYPE = Person

  constructor(props, injection = {}) {
    super(props, injection)

    Object.assign(this, injection)

    this.defaultQueryParams = {
      pagination: defaultPagination,
      search: '',
      filters: ''
    }

    this.queryParams = injection.queryParams || this.defaultQueryParams

    this.add = this.add.bind(this)
    this.delete = this.delete.bind(this)
    this.reset = this.reset.bind(this)
    this.get = this.get.bind(this)
    this.getMore = this.getMore.bind(this)
    this.filter = this.filter.bind(this)
    this.search = debounce(this.search, 1000)
  }

  add(data) {
    const { personId } = data || {}
    const person = this.find(findPerson(personId))

    if(person) {
      Object.assign(person, data)

      return this
    }

    const newPerson = new Person(data)

    this.items.push(newPerson)

    return this
  }

  delete(data) {
    const { personId } = data || {}

    return this.reject(findPerson(personId))
  }

  reset() {
    return this.resetPeople({
      queryString: '',
      queryParams: this.defaultQueryParams
    })
  }

  get appliedFilters() {
    const { filters, defaultFilters } = this.queryParams

    return omit(filters, defaultFilters)
  }

  get appliedFiltersFlattened() {
    return Object.entries(this.appliedFilters)
      .reduce(flattenParams, {})
  }

  get filtersCount() {
    const { length } = Object
      .values(this.appliedFilters)
      .reduce(countFilters, [])

    return length
  }

  get encodedDefaultFilters() {
    const { search } = this.queryParams
    return this.encodeParams({ ...this.appliedFiltersFlattened, ...search })
  }

  get encodedFilters() {
    const { filters } = this.queryParams

    if(!this.encodedDefaultFilters) return ''

    const mappedFilters = Object.entries(filters)
      .reduce(flattenParams, { exclude: true })

    this.encodedSearch && delete mappedFilters.exclude

    return this.encodeParams(mappedFilters)
  }

  get encodedPagination() {
    const { pagination: { _offset, _limit }} = this.queryParams

    return this.encodeParams({ _offset, _limit })
  }

  get encodedSearch() {
    const { search } = this.queryParams

    return this.encodeParams(search)
  }

  encodeParams(params) {
    return Object.entries(params)
      .reduce(encodeParams, [])
      .join('&')
  }

  get(shouldResetList) {
    if(shouldResetList) this.setPage(0)

    const {
      encodedPagination,
      encodedFilters,
      encodedSearch,
      queryParams
    } = this

    const queryString = []
      .concat(encodedPagination)
      .concat(encodedSearch)
      .concat(encodedFilters)
      .concat(relations)
      .join('&')
      .replace('&&', '&')

    const queryStringUpdated = this.queryString !== queryString

    if(!queryStringUpdated) return false

    this.queryString = queryString

    const shouldResetPeople = !encodedSearch && !encodedFilters

    if(shouldResetPeople) {
      this.reset()

      return false
    }

    this.getPeople({ queryString, queryParams }, shouldResetList)

    return true
  }

  getMore() {
    this.nextPage()

    return this.get()
  }

  search(search) {
    Object.assign(this.queryParams, { search })

    return this.get(true)
  }

  filter(filters, defaultFilters) {
    Object.assign(this.queryParams, { filters, defaultFilters })

    return this.get(true)
  }

  nextPage() {
    const { pagination: { _page }} = this.queryParams

    this.setPage(_page + 1)
  }

  setPage(page) {
    const { pagination: { _limit }} = this.queryParams

    Object.assign(this.queryParams.pagination, {
      _page: page,
      _offset: page * _limit
    })
  }
}

function findPerson(id) {
  return function({ personId }) {
    return id === personId
  }
}

function flattenParams(accum, [key, filter], index, array) {
  const { exclude = false } = accum
  if(index === array.length -1) delete accum.exclude
  const filtersAsList = filter ? [].concat(filter) : []
  const filters = filtersAsList.length > 0 && filtersAsList.reduce(mapValue, [{ exclude }])

  if(!filters || !filters.length) return accum

  return { ...accum, [key]: filters.join(',') }
}

function mapValue(accum, { value, exclude }, index, array) {
  const [{ exclude: shouldExclude }] = accum
  if(index === array.length - 1) accum.splice(0, 1)

  if(!value || (shouldExclude && exclude)) return accum

  return [ ...accum, value ]
}

function encodeParams(accum, [key, value]) {
  if(value === null || value === undefined) return accum

  const encodedValue = encodeURIComponent(value)
  const encodedKey = encodeURIComponent(key)

  return [ ...accum, `${encodedKey}=${encodedValue}` ]
}

function countFilters(accum, current) {
  if(Array.isArray(current)) return current.reduce(countFilters, accum)

  if(!current) return accum

  return [ ...accum, current ]
}
