import axios from 'axios'
import { getApiHeaders } from './strategies/getApiHeaders/getApiHeaders'

export const getClient = ( tenantId ) => {
  const { client, urlPeople, urlGlobalPeople, urlGlobalPeopleManagement } = getApiHeaders(tenantId)

  const URLS = {
    AUTH_PUBLIC: 'api-authorization/api/v2/oauth/access-token',
    CSC: (sellerId) =>
      `${urlGlobalPeopleManagement}/parameters/registrationTeam?user=${sellerId}`,
    ROLES: (personId) =>
      `${urlGlobalPeopleManagement}/people/${personId}`,
    COMMERCIAL_STRUCTURE: (userId) =>
      `/sgi/pe/v1/api/commercialStructures?responsibleUserCode=${userId}`,
    PEOPLE: (personId) =>
      personId ? `${urlGlobalPeople}/${personId}` : urlGlobalPeople,
    COMPLETE_PEOPLE: (personId) =>
      personId ? `${urlGlobalPeople}/${personId}/complete` : urlGlobalPeople,
    CREATE_PERSON_ROLE: (personId) =>
      `${urlGlobalPeopleManagement}/people/${personId}/person-roles`,
    APPROVATION: (personId, personRoleId) =>
      `${urlGlobalPeopleManagement}/people/${personId}/person-roles/${personRoleId}/approve`,
    REJECTION: (personId, personRoleId) =>
      `${urlGlobalPeopleManagement}/people/${personId}/person-roles/${personRoleId}/reject`,
    REMOVE_PENDENCY: (personRoleId) =>
      `${urlGlobalPeopleManagement}/person-roles/${personRoleId}/remove-pendency`,
    REJECTION_REASONS: () => `${urlGlobalPeopleManagement}/rejection-reasons`,
    CONSULTANTS: {
      find: ({ sellerId }) =>
        `${urlGlobalPeopleManagement}/people/${sellerId}`,
      findById: ({ personId, sellerId }) =>
        `${urlGlobalPeople}/${personId}/consultants?consultantId=${sellerId}`,
      findByName: ({ personId, name, queryString }) =>
        `${urlGlobalPeople}/${personId}/consultants?name=${name}&${queryString}`,
      findByDocuments: ({ personId, sellerDocument, documentTypeId }) =>
        `${urlGlobalPeople}/${personId}/consultants?documentNumber=${sellerDocument}&documentTypeId=${documentTypeId}`
    },
    DOCUMENTS: (personId) =>
      `${urlGlobalPeople}/${personId}/documents/attachments`,
    DOCUMENTS_VALIDATION: (personId, documentAttachmentId) =>
      `${urlGlobalPeople}/${personId}/document-attachments/${documentAttachmentId}/validate`,
    ATTACHMENTS: (personId) => `${urlGlobalPeople}/${personId}/attachments`,
    CEASE: (personId, personRoleId) =>
      `${urlGlobalPeople}/${personId}/person-role/${personRoleId}/cease`,
    SEARCH: (query) => `${urlGlobalPeople}?${query}`,
    REVALIDATE: (personId) => `${urlPeople}/${personId}/workflow/reset`,
    EMAIL_RESEND: ({ sequence }) =>
      `${urlGlobalPeopleManagement}/emails/${sequence}/send-confirmation-code`,
    EMAIL_VALIDATION: ({ emailId, confirmationCode }) =>
      `${urlGlobalPeopleManagement}/emails/${emailId}/validate/${confirmationCode}`,
    SMS_TOKEN_RESEND: ({ sequence }) =>
      `${urlGlobalPeopleManagement}/telephone/${sequence}/send-confirmation-code`,
    SMS_TOKEN_VALIDATION: ({ sequence, token }) =>
      `${urlGlobalPeopleManagement}/telephones/${sequence}/validate/${token}`,
    FILTERS: `${urlGlobalPeopleManagement}/status-substatus`,
    ESTABLISHMENTS: `${urlGlobalPeopleManagement}/establishments`,
    ALLOCATE_PERSON: (personId) =>
      `${urlGlobalPeople}/${personId}/allocate-commercial-structure`,
    SAVE_PERSON_STATUS: (personId, personSubstatus) =>
      `${urlGlobalPeople}/${personId}/substatus/${personSubstatus}`
  }

  return {
    client: axios.create(client),
    urls: URLS
  }

}
