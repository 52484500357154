import uuid from 'uuid'
import { SUBSTATUS } from 'src/models/Substatus'
import { getBasePath } from 'src/helpers/router'
import { DOCUMENTS } from 'src/models/Document'
import { shouldNotCreatePerson } from './PreRegisterProps'
import { EventDispatcher } from '../../../events/EventDispatcher'
import { EVENT_TYPE } from '../../../events/events'

export function onSubmition(deps) {
  return function(data, callback) {
    const {
      person,
      peopleRegister,
      user: {
        sellerId,
        authenticated,
        isCSC
      },
      personVerify,
      connectivity,
      locale: {
        region,
        configs
      }
    } = deps

    const { create, saveOnState, draft, personId, reviewStep } = person
    const { documents, captchaToken } = data
    const headers = { captchaToken }

    const { isOffline } = connectivity

    if (shouldNotCreatePerson(authenticated, isCSC, region)) {
      const params = {
        ...data,
        draft,
        personId,
        reviewStep: { ...reviewStep, step: 'business-model' }
      }

      saveOnState(data)
      
      return personVerify.find(params, function(result) {
        const { payload: { data } = {} } = result
        const shouldUpdatePersonLocal = !isOffline && draft && !data.length

        if (shouldUpdatePersonLocal)
          peopleRegister.savePerson({ ...params })

        return callback(result)
      })
    }

    const createPersonFromBff = configs?.localization?.preRegister?.useCreatePersonFromBFF

    if (createPersonFromBff) {
      return createPersonWithBff(
        person,
        region,
        authenticated,
        sellerId,
        data,
        callback
      )
    }

    const sanitizedDocuments = getSanitizedDocuments(documents)

    const params = {
      personId: uuid(),
      documents: sanitizedDocuments,
      businessModel: {
        businessModelId: 1,
        functionId: 1,
        roleId: 1
      },
      additionalInformation: {
        informationDisclosureAllowed: null,
        introducerSellerId: null,
        introducerSellerName: null,
        registrantId: authenticated ? sellerId : null,
        registrantName: null,
        leaderId: null,
        leaderName: null,
        optInMail: null,
        optInPush: null,
        optInSms: null,
        quitReasonId: null,
        rejectionReasonId: null,
        rejectionReasonName: null,
        creatorRole: null,
        lastUpdaterRole: null,
        createdByName: null,
        establishmentId: null,
        admissionDate: null
      }
    }

    peopleRegister.savePerson(params)

    const callbackFunc = async (newPerson, result) => {
      if (!result.error) {
        person.saveStatus({ ...params, substatus: SUBSTATUS.DOCUMENT_POSTED })
      }

      if (result.error?.response?.status === 409) {
        params.personId = result.error.response.data.personId

        await person.saveStatus({ ...params, substatus: SUBSTATUS.DOCUMENT_POSTED })

        location.assign(`${getBasePath(region)}/personal-data/${params.personId}`)

        return
      }

      return callback(result)
    }

    return create(params, callbackFunc, headers)
  }
}

const getSanitizedDocuments = (documents) => {
  const sanitizedDocuments = []
  documents.forEach(document => {
    if (document.type === DOCUMENTS.DNI_ARGENTINA) {
      sanitizedDocuments.push({
        type: document.type,
        document: document.document.replace(/\./g, '')
      })
      return
    }
    if (document.type !== DOCUMENTS.RUT) {
      sanitizedDocuments.push(document)
      return
    }
    if (document.document.length === 9) {
      sanitizedDocuments.push(document)
      return
    }
    sanitizedDocuments.push({
      type: DOCUMENTS.RUT,
      document: `0${document.document}`
    })
  })
  return sanitizedDocuments
}

function createPersonWithBff(
  person,
  region,
  authenticated,
  sellerId,
  data,
  callback
) {
  const { create, saveStatus } = person
  const { documents } = data

  const params = {
    documents: getSanitizedDocuments(documents),
    businessModel: {
      businessModelId: 1,
      functionId: 1,
      roleId: 1
    },
    additionalInformation: {
      informationDisclosureAllowed: null,
      introducerSellerId: null,
      introducerSellerName: null,
      registrantId: authenticated ? sellerId : null,
      registrantName: null,
      leaderId: null,
      leaderName: null,
      optInMail: null,
      optInPush: null,
      optInSms: null,
      quitReasonId: null,
      rejectionReasonId: null,
      rejectionReasonName: null,
      creatorRole: null,
      lastUpdaterRole: null,
      createdByName: null,
      establishmentId: null,
      admissionDate: null
    }
  }

  const callbackBff = async (newPerson, result) => {
    const PERSON_ROLE_ALREADY_ACTIVE = 'PR-0028'
    const BAD_PAYER_FOUND_ERROR = 'BFF-001'

    if (!result.error) {
      const personId = result.payload.data.personId
      saveStatus({ personId, ...params, substatus: SUBSTATUS.DOCUMENT_POSTED })
    }

    if (
      result.error?.response?.status === 400 &&
      result.error?.response?.data?.error?.code === PERSON_ROLE_ALREADY_ACTIVE
    ) {
      EventDispatcher.send(EVENT_TYPE.PRE_REGISTER_OPEN_DIALOG_ERROR, {
        message: 'personRoleAlreadyExists'
      })
    }

    if (
      result.error?.response?.status === 400 &&
      result.error?.response?.data?.error?.code === BAD_PAYER_FOUND_ERROR
    ) {
      EventDispatcher.send(EVENT_TYPE.PRE_REGISTER_OPEN_DIALOG_ERROR, {
        message: 'badPayerFound'
      })
    }

    if (result.error?.response?.status === 409) {
      const personId = result.error.response.data.error.personId

      saveStatus({ personId, ...params, substatus: SUBSTATUS.DOCUMENT_POSTED })

      location.assign(`${getBasePath(region)}/personal-data/${personId}`)

      return
    }

    return callback(result)
  }

  return create(params, callbackBff)
}
