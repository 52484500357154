import React from 'react'
import PropTypes from 'prop-types'
import './Tag.styl'

const Tag = (props) => {
  const { content } = props

  return <span className={'tag'}>{content}</span>
}

Tag.propTypes = {
  content: PropTypes.object
}

export default Tag
