import argentinaAttachments from './attachments.arg'
import chileAttachments from './attachments.chl'
import colombiaAttachments from './attachments.col'
import malaysiaAttachments from './attachments.mys'
import peruAttachments from './attachments.per'

const strategies = {
  ARG: argentinaAttachments,
  CHL: chileAttachments,
  COL: colombiaAttachments,
  MYS: malaysiaAttachments,
  PER: peruAttachments
}

export const getAttachmentsByLocale = (locale) => {
  return strategies[locale]
}
