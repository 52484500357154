import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'
import { getLevel2Options } from './Level2.arg'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'level1',
    label: intl.formatMessage(messages['level1']),
    placeholder: intl.formatMessage(messages['level1_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['level_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: onChangeLevel1(intl),
    required: true,
    disabledBy: [],
    htmlProps: { autoFocus: false },
    showAsterisk: false,
  }
}

const pushDataLayer = (values, intl) => {
  const { label } = values

  TagManager.dataLayer({
    dataLayer: {
      event: 'registrationRegionData',
      fieldName: intl.formatMessage(messages['level1']),
      value: label
    }
  })
}

const onChangeLevel1 = (intl) => {
  return (values) => {
    clearAboveLevels(values)
    pushDataLayer(values, intl)
  }
}

function clearAboveLevels({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.level2`, '', id)
  setFieldValue(`${parent}.level3`, '', id)
  setFieldValue(`${parent}.level4`, '', id)

  setFieldTouched(`${parent}.level2`, false)
  setFieldTouched(`${parent}.level3`, false)
  setFieldTouched(`${parent}.level4`, false)
}

const getLevel1Options = getLevels => {
  getLevels({
    queryParams: 'level=1',
    geoStructureType: 'departments'
  })
}

const mapStateToProps = ({ addresses, connectivity }, ownProps) => {
  const {
    addressesPeopleManagement: { departments: level1Options, getLevels },
    loading,
    error
  } = addresses

  const {
    group: { index },
    onChange,
    form: {
      values: { address }
    }
  } = ownProps

  const { countryName } = address[index] || {}
  const options = level1Options[countryName]
  const shouldGetLevel1Options =
    !options && !loading.departments && !error.addresses && countryName

  if (shouldGetLevel1Options && !connectivity.isOffline) {
    getLevel1Options(getLevels)
  }

  return {
    ...ownProps,
    options: options || [],
    loading: Boolean(loading.departments),
    onChange: (value, label) => {
      getLevel2Options({ value, getLevels })
      onChange(value, label)
    }
  }
}
