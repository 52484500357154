import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export default class PersonVerify extends Entity {
  static SCHEMA = {
    personId: adapter(yup.string().required())
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }

  get exists() {
    return !!this.personId
  }

  find(data, callback) {
    return this.findPerson(data).then(callback)
  }

  reset() {
    return this.resetPerson()
  }
}
