import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Checkbox from 'src/base/Form/Components/Checkbox'
import Modal from 'src/base/Modal'

import './CheckboxModal.styl'

import { isDefaultFlowToggleActive } from '../../../../config'

const bem = bemClassName.bind(null, 'form-checkbox-modal')

class CheckboxModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      modalOpened: false,
      modalIndex: 0
    }

    this.onToggleModal = this.onToggleModal.bind(this)
    this.labelParts = props.label.split(props.char)
    this.label = this.createLabel()
  }

  onToggleModal(event) {
    event.preventDefault()

    const modalIndex = event.target.dataset.key
    const modalOpened = !this.state.modalOpened

    this.setState({ modalOpened, modalIndex })
  }

  onDefaultClose = (event) => {
    const { onChange } = this.props

    this.onToggleModal(event)
    onChange(true)
  }

  createLabel() {
    this.modalIndex = -1
    const { required } = this.props
    const { labelParts } = this

    return (
      <span className="label__container">
        {labelParts.map(this.createLabelParts, this)}
        {required && '*'}
      </span>
    )
  }

  createLabelParts(part, key) {
    const { customProps: { linkIndexes = [] } } = this.props

    if(linkIndexes.includes(key)) {
      this.modalIndex++
      return this.createLink(part, key)
    }

    return this.createText(part, key)
  }

  createLink(link, key) {
    return (
      <strong
        className="link"
        onClick={this.onToggleModal}
        key={key}
        data-key={this.modalIndex}>
        {link}
      </strong>
    )
  }

  createText(text, key) {
    return (
      <span key={key}>{text}</span>
    )
  }

  render() {
    const {
      className,
      id,
      type,
      message,
      form,
      field,
      value,
      error,
      disabled,
      onBlur,
      onChange
    } = this.props

    return (
      <div className={classNames(bem(), className)}>
        <Checkbox
          form={form}
          field={field}
          className={bem('checkbox')}
          id={id}
          disabled={disabled}
          error={error}
          type={type}
          value={value}
          label={this.label}
          message={message}
          onBlur={onBlur}
          onChange={onChange}
        />
        {this.renderModal()}
      </div>
    )
  }

  renderModal() {
    const { modalOpened, modalIndex } = this.state
    const { customProps: { modal = [] } } = this.props

    const className = isDefaultFlowToggleActive() ? bem('defaultModal')  : bem('modal')

    return (
      <Modal
        className={className}
        onCancel={this.onToggleModal}
        onClose={isDefaultFlowToggleActive() ? this.onDefaultClose : this.onToggleModal}
        open={modalOpened}
        {...modal[modalIndex]}
      />
    )
  }
}

CheckboxModal.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  char: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.bool,
  error: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
  form: PropTypes.object,
  field: PropTypes.object,
  customProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

CheckboxModal.defaultProps = {
  char: '##'
}

export default CheckboxModal
