import PersonVerify from 'src/viewModels/PersonVerify'

import { personVerifyFindReducer, findPerson, types as personVerifyFindTypes } from './PersonVerify.find'
import { personVerifyResetReducer, resetPerson, types as personVerifyResetTypes } from './PersonVerify.reset'

const dependencies = {
  personVerifyResetReducer,
  personVerifyFindReducer
}

const personDependencies = {
  findPerson,
  resetPerson
}

const initialState = {
  person: new PersonVerify({}, personDependencies),
  loading: false,
  error: false,
}

export default function reducer(state = initialState, action = {}, rootState = {}, injection = {}) {
  const {
    personVerifyResetReducer,
    personVerifyFindReducer
  } = { ...injection, ...dependencies }

  const { type } = action

  if([].concat(personVerifyFindTypes).includes(type))
    return personVerifyFindReducer(state, action, personDependencies)

  if([].concat(personVerifyResetTypes).includes(type))
    return personVerifyResetReducer(state, action, personDependencies, rootState)

  return state
}
