import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'

export function buildBasicPersonalData(deps) {
  const { title, subtitle, label } = getSectionTitle(deps)
  const { loader: { personLoading } } = deps

  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    step: {
      title,
      subtitle,
      label
    },
    loader: personLoading
  }
}
