import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

import FormFieldSchema from './FormFieldSchema'
import FormGroupSchema from './FormGroupSchema'

const adapter = validatorAdapter('Yup', yup)

export default class FormSchema extends Entity {
  static SCHEMA = {
    name: adapter(yup.string().required()),
    fieldsSchema: {
      validator: adapter(yup.array().of(yup.array()).required()),
      builder: buidFields,
      defaultValue: []
    },
    initialValues: {
      validator: adapter(yup.object().required()),
      defaultValue: {}
    }
  }

  constructor(props) {
    super(props)

    this.initialValues = this.fieldsSchema.reduce(reduceInitialValues, {})
  }
}

function reduceInitialValues(final, schema) {
  if(Array.isArray(schema)) {
    return schema.reduce(reduceInitialValues, final)
  }

  const { id, defaultValue } = schema

  if(id) {
    Object.assign(final, { [id]: defaultValue })
  }

  return final
}

function buidFields(field) {
  if(Array.isArray(field)) {
    return field.map(buidFields)
  }

  if(field.fields) {
    return new FormGroupSchema(field)
  }

  return new FormFieldSchema(field)
}
