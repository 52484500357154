import * as yup from 'yup'

import PhoneConfig, { getPhoneValidation } from 'src/configs/Fields/Phones/Phone.number'

export default function Builder(intl, injection) {
  const { locale: { configs: { localization: { phone } } }, errorMessage } = injection

  const { person: { phones } } = { ...injection }
  const phoneValidation = getPhoneValidation({ ...phone, intl, errorMessage })

  const PHONE = PhoneConfig(intl, injection)

  const baseValue = {
    type: 1,
    countryCode: phone.countryCode,
    phone: ''
  }

  if (!phones.length) {
    phones.push(baseValue)
  }

  return {
    id: 'phones',
    min: 1,
    max: 1,
    baseValue,
    defaultValue: phones.map(mapPhones, { phone }),
    fields: [
      [PHONE]
    ],
    validations: yup.array().of(yup.object().shape({
      phone: phoneValidation,
      type: yup.number().required()
    }))
  }
}

export function mapPhones({ phone, type }) {
  return {
    type,
    phone,
    countryCode: this.phone.countryCode,
  }
}
