export const COMPANY_ID = {
  NATURA: 1,
  AVON: 2
}

export const COMPANY_NAME = {
  NATURA: 'NATURA',
  AVON: 'AVON'
}

export const COMPANY_NAME_BY_ID = {
  [COMPANY_ID.NATURA]: 'NATURA',
  [COMPANY_ID.AVON]: 'AVON'
}
