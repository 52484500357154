import axios from 'axios'

const APPLICATION_URLENCODED = 'application/x-www-form-urlencoded'
const COUNTRY = 'PE'
const COMPANY = 1
const SOURCE_SYSTEM = 7

export const CMM_CLIENT = {
  baseURL: process.env.CMM_BASE_URI,
  headers: {
    'Content-Type': APPLICATION_URLENCODED,
    country: COUNTRY,
    company: COMPANY,
    sourceSystem: SOURCE_SYSTEM
  }
}

export const URLS = {
  ADDRESS: (queryString) =>
    `/external/zones/geo-structure-tree${queryString || ''}`,
  BUSINESS_MODEL: `/external/businessModel`
}

export default {
  client: axios.create(CMM_CLIENT),
  urls: URLS
}
