import { getContext } from 'src/infra/Api/Api.context'
import User from 'src/models/User'

export const LOGOUT = 'natura-caduni/User/LOGOUT'
export const types = [LOGOUT]

export function userLogoutReducer(state, action, dependencies) {
  if (action.type === LOGOUT) {
    return {
      loading: false,
      error: false,
      user: new User({}, dependencies)
    }
  }
}

export function logout() {
  const { dispatch } = getContext()

  return dispatch({
    type: LOGOUT
  })
}
