import { defineMessages } from 'react-intl'

const messages = defineMessages({
  complement: {
    defaultMessage: 'Complement',
    id: 'form.field.complement'
  },
  complement_message: {
    defaultMessage: 'E.g. "Residential Set Chico, House 9", "Tower A, Apartment 17"',
    id: 'form.field.complement.message'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection){
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'complement',
    label: intl.formatMessage(messages['complement']),
    type: 'input',
    message: intl.formatMessage(messages['complement_message']),
  }
}
