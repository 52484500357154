export default [
  'PRIVACY POLICY',
  'This Privacy Policy (the “Policy”) is established to inform the terms and conditions pertaining to the collection, storage, use, safekeeping and maintenance of your personal information by Ola Beleza Sdn Bhd (the “Natura Malaysia” or “We”) through visiting the Natura Malaysia’s website and application (the “Site and Application”).',
  'As part of our efforts to protect your privacy, this Policy may be updated or amended as and when required, at the sole discretion of Natura Malaysia. With any changes made to the Policy, we will publish the latest version of Policy on the Site and Application for your perusal.',
  'Should you have any concerns regarding to this Policy, please do not hesitate to contact us through our service channel at ola@naturamy.com.',
  'ACCEPTANCE TO THE POLICY',
  'You acknowledge and agree that:',
  '● By clicking on the “Subscribe” button, you provide your free, expressed and informed consent to this Policy;',
  '● You may at any time revoke the consent you have provided by writing to us',
  'at ola@naturamy.com and revocation of such consent will affect your use and level of accessibility to the Site and Application;',
  '● It is your responsibility to review this Policy which may be updated or modified by Natura Malaysia from time to time.',
  '● By continuing your use and access to the Site and Application, you indicate your acceptance to this Policy including any changes that may be made from time to time.',
  'COLLECTION OF INFORMATION',
  'Natura Malaysia always thrive to provide you a better and secure user experience while accessing our Site and Application. To achieve this, we collect both personally identifiable information (the “Personal Information”) and other information generated through accessing the Site and Application (the “General Information”) (collectively as “Information”). You hereby agree and provide your consent for Natura Malaysia to collect the followings:',
  '􏰀 PERSONAL INFORMATION',
  'o Any information about an individual whose identity can be reasonably be identifiable based on the information, including but not limited to, name, gender, date of birth, identity document, residential address, contact number, e-mail address and payment information.',
  '􏰀 GENERAL INFORMATION',
  'o Non-personally identifiable information which automatically generated while accessing the Site and Application, including but not limited to, Internet Protocol address (IP-address), geolocation data, features of your device, website usage and browser type. We may use various standard technologies such as cookies, pixel tags, beacons and local shared objects in order to collect the aforementioned information.',
  'o In the case that you are paying for your purchase using credit card not issued to you, you hereby confirm that you have obtained the prior consent of the credit card holder for the purpose of such purchase.',
  'o You acknowledge and understand that you may configure your browser to disallow the technology such as cookies from collecting certain Information. In addition, you understand that such configuration implemented will limit your level of accessibility to certain features on the Site and Application.',
  'USE OF INFORMATION',
  'Natura Malaysia is committed to treat the information collected with your consent as confidential. Information collected will only be used in the manner described in this Policy and as authorised by you.',
  'You acknowledge and agree that the information collected by us will be used, including but not limited to, the following purposes:',
  '● Provision of services through the Site and Application;',
  '● Sharing of product or service information based on your preference;',
  '● Development and dissemination of advertising and marketing campaigns;',
  '● Respond to queries and requests;',
  '● Provide access to restricted areas of the Site and Application;',
  '● Verification of identity or potential fraud;',
  '● Compliance with legal or judicial order;',
  '● Establish, protect and exercise the rights of Natura Malaysia; and',
  '● Ensure traceability and security of the Site and Application.',
  'If you no longer wish to receive incoming emails from Natura Malaysia, you may select “Opt-out” option provided or request such cancellation through our service channel ola@naturamy.com.',
  'In the case that you wish to cancel of your registration, all information registered will be disabled and the level of accessibility of you accessing the Site and Application shall be limited.',
  'The above “Opt-out” of incoming email or cancellation of your registration may not occur immediately due to certain reasons such as regular maintenance or update. However, you may at any time contact us directly via ola@naturamy.com for immediate processing on the aforementioned requests.',
  'SHARING OF INFORMATION',
  'Natura Malaysia may engage with third-party to perform functions on our behalf and/or fulfil our legal obligations. Some of the third-parties may be located outside of Malaysia.',
  'You understand and agree that Natura Malaysia may be required to disclose your information to the qualified professionals, authorities and/or third-parties who are under contractual obligation to employ information received by Natura Malaysia for the purposes of performing the functions for which they were engaged. This includes:',
  '● Third-party service providers for purposes such as processing payments, delivering packages and etc;',
  '● Qualified legal advisors, auditors and other professional consultants to fulfil our obligations or in the event of any legal proceedings;',
  '● Natura Malaysia’s business partners, principals, franchisor, and/or licensor for the purposes of business development in Malaysia or commercializing the products or services available through Site and Application;',
  '● Other third-party company in the case of major corporate exercise, in which such transfer of information will be necessary for the continuity of services; and',
  '● Administrative authorities that have legal competence to request for information or by way of judicial order.',
  'TRANSFER AND SECURITY OF INFORMATION',
  'Natura Malaysia is committed to safeguard the privacy of your information by taking reasonable steps and in accordance with applicable law.',
  'You acknowledge and agree that Natura Malaysia may transfer and store information collected from you on server located within or outside of Malaysia. All information collected are protected and only authorised personnel may access to the information.',
  'You understand and agree that you must protect your username and password at all times and not sharing with others. Certain information may be transmitted to you via email. You may at any time change your login password to ensure the security of your information or contact our service channel ola@naturamy.com should you identify any suspicious login attempt.',
  'CHANGES TO INFORMATION COLLECTED',
  'If you would like to access, update, amend, verify any information, or should you identify any inaccuracy in information, you may do the necessary changes by your own through accessing the Site and Application. Alternatively, you may contact us via our service channel ola@naturamy.com for our assistance.',
  'You may make a written request to Natura Malaysia via email to our service channel ola@naturamy.com to refrain us from collecting and/or processing certain types of information about you. We will make every effort to fulfil the request. Nevertheless, you understand that the exclusion of such information at your request may result in discontinuation of your login account to access the Site and Application. We shall not be held liable for ceasing to process your information at your request.',
  'DISCLAIMER OF LIABILITY',
  'In addition to other disclaimer of liability provisions provided in this document, Natura Malaysia shall not be held liable for the following matters:',
  '● Third-party hyperlinks made available on our Sites and Applications which your use is subject to the privacy policy of the said third party site;',
  '● Damage or loss of any nature which may arise from knowledge gained by unauthorised third-party due to no fault of Natura Malaysia;',
  '● If your use of the Site and Application has violated this Policy and any applicable law;',
  '● Information provided by you that is incomplete or inaccurate which hinders the delivery of products or completion of orders; and/or',
  '● Damage or loss caused by acts of third-party, where they are successful in collecting or using, by any means, publicly available information made available by you.',
  'GENERAL PROVISIONS',
  'You hereby declare, by accepting this Policy, that all information provided are true and accurate.',
  'If any part of this Policy should be determined to be invalid, illegal, or otherwise unenforceable under any applicable law in Malaysia, then to the extent which they are so invalid, illegal, or otherwise unenforceable, they shall be interpreted in',
  'accordance with the applicable law and all other provisions remaining in full force and effect.',
  'Any delay or failure by Natura Malaysia to exercise any right under this Policy shall not constitute a waiver, except specifically waived by us in writing.',
  'Natura Malaysia reserves the right at any time to update, amend and/or impose additional clauses to this Policy. Any changes made to this Policy shall become effective upon its availability on the Site and Application.',
  'CONTACT',
  'If you have any comments or inquiries pertaining to this Policy or would like exercise your rights, you may contact us by sending an email to us at ola@naturamy.com or call us at 03-5632 4313 for assistance.',
  'We may contact you through any contact information provided to us by you for the purposes of responding to your request, questions, or implementing anti-fraud mechanisms as and when required.',
  'GOVERNING LAW',
  'This Policy shall be governed, construed and enforced in accordance with the applicable laws of Malaysia and you hereby irrevocably agree to submit to the jurisdiction of the courts of Malaysia.',
  ' ',
  'TERMS OF USE',
  '',
  'This Terms of Use (the "Terms") govern your access to and use of the website and application (the "Site and Application"). The Site and Application is made available to you by Ola Beleza Sdn Bhd (the "Natura Malaysia" or “Us” or “We”).',
  '',
  'Through the Site and Application, Beauty Consultant (hereinafter "Consultant" or "You") may, among others, place your orders for Natura products, view existing promotions, access trainings and tools and check on stock availability, according to the conditions contained in this Terms and information presented on the Site and Application.',
  '',
  '1. ACCEPTANCE ON TERMS OF USE',
  '',
  '1.1 You agree that by clicking on the "I Accept" button, You hereby provide your free, expressed and informed consent to this Terms.',
  '',
  '1.2 You may at any time revoke your consent by writing to us through the channel as stipulated in Clause 9.1. You acknowledge that the revocation of such consent will affect your use and level of accessibility to the Site and Application.',
  '',
  '1.3 To the extent permitted by applicable law in Malaysia, You acknowledge and understand that it is your responsibility to review this Terms as may be updated or modified by Natura Malaysia from time to time. We will endeavour to notify You should there be changes to the Terms.',
  '',
  '1.4 By continuing to use the Site and Application, You indicate your acceptance of the Terms including any changes that may be made from time to time.',
  '',
  '2 ACCESS TO SITE AND APPLICATION',
  '',
  '2.1 You acknowledge and understand that You are required to register a personal account in the Natura Malaysia system in order to access the Site and Application. You understand that You will be required to create login details and a password to obtain access to the Site and Application.',
  '',
  '2.2 You understand and acknowledge that You must not disclose your login details (i.e. username and password) to third parties, including your family members.',
  '',
  '2.3 You represent and warrant that:',
  '',
  '(a) all information provided are true and accurate; and (b) You are eighteen (18) years of age and above.',
  '',
  '2.4 Natura Malaysia reserves the right to cancel or reject your registration if You fail to provide the requisite information, or the information provided is found to be false or untrue.',
  '',
  '2.5 You must apply to participate in our training sessions through the submission of the request form as provided in the Site and Application. Your participation in the training sessions is subject to approval from Natura Malaysia.',
  '',
  '3 FUNCTIONALITIES OFFERED BY THE SITE AND APPLICATION',
  '',
  '3.1 You may use the Site and Application to:',
  '',
  '(a) place new orders for Natura products;',
  '(b) review the product catalogue; and',
  '(c) check on prices of products and make payments for such products.',
  '',
  '3.2 In the course of your sales, You may collect the personal data of your customers. By collecting such personal data, you acknowledge that it is your sole and exclusive responsibility to obtain the free, express and informed consent of your customers to be provided to Natura Malaysia and that You have provided the Privacy Notice to the customer and obtained your customer\'s signature to the same.',
  '',
  '3.3 You also understand that in order to access the full functionalities of the Site and Application, the Site and Application will require access to your geolocation through the Global Positioning System (GPS) of your mobile device and You provide your free, expressed and informed consent to such access.',
  '',
  '3.4 You acknowledge that You are solely responsible for the information and documents submitted on the Site and Application and that You will be solely responsible for any damages or losses arising from any inaccurate, incomplete and untrue information provided to the Site and Application.',
  '',
  '3.5 You acknowledge that it may be illegal to provide false or untrue information under Malaysian law.',
  '',
  '3.6 Natura Malaysia reserves the right to impose further conditions on the use of the Site and Application, including but not limited to the right to impose fees on the use of the Site and Application.',
  '',
  '4 OBLIGATIONS OF CONSULTANT FOR THE USE OF SITE AND APPLICATION',
  '',
  '4.1 By using the Site and Application, You acknowledge and agree that:',
  '',
  '(a) You will keep the personal information updated to ensure the information are accurate and complete at all times;',
  '',
  '(b) You will not use the Site and Application for any purposes which would be illegal under any applicable law;',
  '',
  '(c) You may not use the Site and Application to post any content or information which is in anyway contrary to morals and generally accepted standards of decency, defamatory, blasphemous, malicious, offensive, insulting, misleading, and in violation of the rights of third parties;',
  '',
  '(d) You will not insert or upload any viruses, worms, trojan horses, self-replicating programs or any other similar programs or other physical or electronic elements which may cause damage or prevent the normal operation of the network; and',
  '',
  '(e) You will not insert or upload any content which will in anyway interrupt the normal operation of the Site and Application.',
  '',
  '4.2 You agree to use the Site and Application, including its contents, texts, images, photographs, graphics, icons, links, technology and other audiovisual or sound content, including the Site and Application software, drawings, graphics and source codes (collectively, "Content") in accordance with this Terms, applicable law and prevailing standards of morality and decency.',
  '',
  '4.3 You must also refrain from obtaining, or attempt to obtain, the Content by any means other than those which in each case have been made available for such purposes.',
  '',
  '4.4 In all circumstances, you will be solely and exclusively responsible for your use of the Site and Application and be responsible for any losses and /or damages arising from such use therein. Natura Malaysia shall not be responsible for any damages or losses arising from your violation or inappropriate use of the Site and Application.',
  '',
  '5 ACCOUNT SUSPENSION',
  '',
  '5.1 Natura Malaysia reserves the right to discontinue or suspend indefinitely your access to the Site and Application at any time with or without prior notice and without liability if,:',
  '',
  '(a) We reasonably believe that You are using the Site and Application in violation of our Terms of Use, Privacy Policy and/or any applicable regulations in Malaysia (the “Breaches”);',
  '',
  '(b) You do not cooperate with our reasonable investigation of any suspected Breaches;',
  '',
  '(c) We are required by law, or a regulatory or government body to suspend your access',
  '',
  'to the Site and Application; and/or',
  '',
  '(d) Such suspension is necessary to protect other users of our Site and Application.',
  '',
  '5.2 We will use commercially reasonable effort to give You advance notice of any such suspension unless We determine that a suspension on shorter notice or without notice is necessary to protect Us or our other users from imminent risk.',
  '',
  '5.3 Any appeal on the suspension shall be made in writing to Us through the channel as stipulated in Clause 9.1.',
  '',
  '6 DISCLAIMER OF LIABILITY',
  '6.1 In addition to the other disclaimer of liability provisions in this Terms, Natura Malaysia',
  '',
  'will not be responsible for:',
  '',
  '(a) any damages resulting from interruption of access to the Site and Application, or for malfunctions;',
  '',
  '(b) the fraudulent use by you of the Site and Application and any inability to access the Site and Application;',
  '',
  '(c) the existence of any harmful elements that may cause changes to your computer system (software and/or hardware) or electronic documents stored in the computer system;',
  '',
  '(d) for any third-party hyperlinks made available on the Site and Application. Natura Malaysia does not exercise any control over the contents of such third-party hyperlinks and your use and activities on such third-party sites is subject to the terms of use and privacy policy of the said third-party site;',
  '',
  '(e) for any damages and losses of any nature which may arise from knowledge gained by unauthorised third parties due to no fault of Natura Malaysia;',
  '',
  '(f) if your use of the Site and Application is in violation of this Terms and any applicable law;',
  '',
  '(g) for information received by Natura Malaysia which is incomplete and which hinders or prevents the delivery of products or the completion of orders; and',
  '',
  '(h) for damage and loss caused by acts of third parties, where they are successful in collecting or using, by any means, publicly available data and information made available by You.',
  '',
  '7 INTELLECTUAL PROPERTY',
  '',
  '7.1 You agree that we retain all rights of use, title and interest in and to all intellectual property rights relating to any material made available on the Site and Application, including but not limited to texts, trademarks, logos, photographs, graphics, images, links, audiovisual or sound content, software, graphic designs and source codes.',
  '',
  '8 GENERAL PROVISIONS',
  '',
  '8.1 If any part of this Terms is held to be invalid or unenforceable, that part shall be construed in accordance with applicable law to reflect, as far as possible, the original intent of the parties, with all other provisions remaining in full force and effect.',
  '',
  '8.2 Any delay or failure by Natura Malaysia to exercise any right under this Policy shall not constitute a waiver, except specifically waived by Us in writing.',
  '',
  '9 CONTACT',
  '',
  '9.1 Consultant may contact Us through ola@naturamy.com.',
  '',
  '9.2 Natura Malaysia may contact the Consultant through the contact information made available by the Consultant during registration. The notices issued through the Site and Application will also be considered valid.',
  '',
  '9.3 We may implement anti-fraud mechanisms in the Site and Application, including making contact with You to confirm any request, through the contact information made available by the Consultant during registration.',
  '',
  '10 GOVERNING LAW',
  '',
  '10.1 This Terms shall be governed, construed and enforced in accordance with the laws of Malaysia and You hereby agree to be subjected to the jurisdiction of the courts of Malaysia.',
  ' ',
  'BEAUTY CONSULTANT AGREEMENT',
  '',
  'This Beauty Consultant Agreement (“Agreement”) outlines the responsibilities, terms and conditions between Ola Beleza Sdn Bhd (“Natura Malaysia”) and the individual who wishes to become an independent contractor of Natura Malaysia (“Beauty Consultant”), including any subsequent amendments or variations made and/or entered into from time to time.',
  '',
  'Both Natura Malaysia and the Beauty Consultant are collectively referred to as "Parties" and individually as "Party"',
  '',
  'NOW IT IS AGREED AS FOLLOWS:',
  '',
  '11',
  '',
  '1.1',
  '',
  'DEFINITIONS AND INTERPRETATION',
  '',
  'In this Agreement the following terms shall, unless the context otherwise requires, have the meanings assigned to them below:',
  '',
  'Anti-Bribery Legislation',
  '',
  'Applicable Law',
  '',
  'Code of Conduct',
  '',
  'Communication Compensation Structure',
  '',
  'means laws, statutes, rules, regulations or judicial decisions in Malaysia relating to anti-bribery and anti-corruption, including but not limited to the Malaysian Anti-Corruption Commission Act 2009 and Anti-Bribery and Corruption Policy as issued by InNature Berhad, the holding company of Natura Malaysia (https://innature.com.my/corporate-governance/);',
  '',
  'means the laws, statutes, rules, regulations or judicial decisions in Malaysia which are applicable to the matters governed by this Agreement;',
  '',
  'means the Code of Conduct as issued by Direct Selling Association of Malaysia (“DSAM Code”) (https://rb.gy/kn14us), and the Code of Conduct and Business Ethics as issued by InNature Berhad (“InNature Code”) (https://innature.com.my/corporate-governance/);',
  '',
  'shall have the meaning ascribed to it in Clause 18.1;',
  '',
  'means the compensation structure for the Beauty Consultant, as permitted by the',
  '',
  'Page | 1',
  '',
  '12. 2.1',
  '',
  'TERM',
  '',
  'Confidential Information Data Protection Legislation',
  '',
  'Direct Sales Legislation',
  '',
  'Marketable Products Natura Malaysia Network',
  '',
  'Natura Trademarks',
  '',
  'Ola Beleza Sdn Bhd',
  '',
  'Ministry of Domestic Trade and Consumer Affairs in Malaysia;',
  '',
  'shall have the meaning ascribed to it in Clause 15.2;',
  '',
  'means the Personal Data Protection Act 2010, and all applicable laws and regulations in Malaysia relating to the processing of personal data and privacy, including where applicable the guidance and code of practice issued by the competent authority;',
  '',
  'means the Direct Sales and Anti-Pyramid Scheme Act 1993 and all applicable laws and regulations in Malaysia relating to direct sales in Malaysia, including where applicable the guidance and code of practice issued by the competent authority;',
  '',
  'means Natura products that are in new, unused, resalable and original condition;',
  '',
  'means the Natura Malaysia direct selling network which distributes the Natura products in return for compensation as set out in this Agreement.',
  '',
  'means all “Natura” trademarks, logos, trade names, logotypes, service marks, trade designations and all other designations, symbols and marks licensed by Natura Cosmeticos S.A. to the Company for the sale of Natura products in Malaysia; and',
  '',
  'a company incorporated under the laws of Malaysia (Company No. 201901005911(1315238-V)) and having its registered office at 802, 8th Floor, Block C, Kelana Square, 17 Jalan SS7/26, Petaling Jaya, Selangor, Malaysia. (“Natura Malaysia” or “Company”).',
  '',
  'Natura Malaysia is the authorized distributor of personal care and cosmetic products under the "Natura" brand (“Natura products”) in Malaysia.',
  '',
  'This Agreement begins on the date of the acceptance of this Agreement and will continue until terminated (the “Term”).',
  '',
  'Page | 2',
  '',
  '13. MEMBERSHIP OF NATURA MALAYSIA NETWORK',
  '',
  '3.1 Becoming a Beauty Consultant',
  'A Beauty Consultant is eligible to join the Natura Malaysia Network if he or she is:',
  '',
  '3.2',
  '',
  '(a) a citizen or permanent resident of Malaysia, or a foreigner who is legally permitted to carry out a trade or vocation in Malaysia;',
  '',
  '(b) not an undischarged bankrupt;',
  '',
  '(c) at least eighteen (18) years of age;',
  '',
  '(d) a person of good character; and',
  '',
  '(e) not any person under the employment of any direct selling company at the point of application and during the tenure of this Agreement.',
  '',
  'Experience Kit and Training',
  '',
  'In consideration of Natura Malaysia’s acceptance on a person’s application to become a Beauty Consultant, he or she is required to:',
  '',
  '(a) purchase one (1) set of the Natura Malaysia Experience Kit; and',
  '',
  '(b) attend training organized by Natura Malaysia which aims to provide guidance for Beauty Consultants to perform their obligations under this Agreement.',
  '',
  'For avoidance of doubt, other than the abovementioned Experience Kit, it is not a prerequisite for an applicant of Beauty Consultant to purchase products from Natura Malaysia.',
  '',
  '3.3 Natura Malaysia reserves the right to impose a fee in relation to the services provided by the Company to the Beauty Consultant by way of a thirty (30) calendar days’ written notice, prior to the imposition of the said fee.',
  '',
  '3.4 Natura Malaysia shall, from time to time, be entitled to revise its Compensation Structure and shall provide at least thirty (30) calendar days’ written notice of such revisions to the Beauty Consultant prior to the implementation.',
  '',
  '14. AGREEMENT COOLING-OFF PERIOD',
  '',
  '4.1 Notwithstanding Clause 17, Beauty Consultant may, before the expiry of ten (10) working days from the date of this Agreement, serve a written notice to Natura Malaysia, indicating his or her intention to withdraw from this Agreement. This Agreement will be terminated automatically upon receipt of such written notice by Natura Malaysia (“Cancellation”).',
  '',
  '4.2 Natura Malaysia shall, within one (1) month upon receipt of such cancellation, refund all fees paid by Beauty Consultant under this Agreement.',
  '',
  '15. BUY-BACK POLICY',
  '',
  '5.1 Upon termination of this Agreement, the Beauty Consultant may put in a written request for Natura Malaysia to buy back any Marketable Products which have been sold to the Beauty Consultant six (6) months prior to the date of termination of Agreement. The Beauty Consultant will be fully responsible for all shipping expenses in returning the Marketable Products. Natura Malaysia will repurchase the Marketable Products upon the receipt of such written request, after deducting a handling fee equivalent to 10% of the purchase price paid by the Beauty Consultant, and any commission paid to the Beauty Consultant in relation to',
  '',
  'Page | 3',
  '',
  '16',
  '',
  '6.1',
  '',
  'the returned Marketable Products. Natura Malaysia shall endeavour to refund the net repurchase value within three (3) months from the date of receipt of Marketable Products from the Beauty Consultant.',
  '',
  'BUSINESS CONDUCTS OF BEAUTY CONSULTANT',
  '',
  'Presentation of authority card and identification card',
  '',
  'Where the Beauty Consultant carries out door-to-door sales, the Beauty Consultant shall produce his or her authority card and identification card to the prospective customer. The authority card will be issued to qualified Beauty Consultant and shall contain the following details:',
  '',
  '(a) name and address of the Beauty Consultant;',
  '',
  '(b) name, address and telephone number of Natura Malaysia;',
  '',
  '(c) the signature of the authorized personnel of the Company;',
  '',
  '(d) the licence number issued to Natura Malaysia by the Ministry of Domestic Trade and Consumer Affairs;',
  '',
  '(e) a photograph affixed with the following requirements:',
  '',
  '● size of photo shall be 3.8cm x 3.2cm without border;',
  '',
  '● full face of the Beauty Consultant without headgear, unless it is required in accordance to his or her religious or racial custom but provided that such headgear does not cover his or her facial features.',
  '',
  'Restrictions on hours of call',
  '',
  '6.2',
  '',
  'Except by prior appointment and with customer’s consent, the Beauty Consultant shall not call at any premises for the purpose of negotiating door-to-door sales:',
  '',
  '6.3',
  '',
  '(a) on Sunday, in areas where a public holiday falls on a Sunday;',
  '',
  '(b) on Friday, in areas where a public holiday falls on a Friday;',
  '',
  '(c) on any public holiday; and',
  '',
  '(d) on any other day between 7:00 PM to 9:00 AM.',
  '',
  'Sales Contract',
  '',
  'Where the Beauty Consultant carries out a door-to-door sale, he or she shall ensure that for sales contract with end consumer worth RM300.00 or more, such contract:',
  '',
  '(a) shall be in writing;',
  '',
  '(b) shall contain the statement “THIS CONTRACT IS SUBJECT TO A COOLING-OFF PERIOD OF TEN WORKING DAYS” immediately above the place provided for the signature of the customer;',
  '',
  '(c) shall be signed by both the Beauty Consultant and the customer. A duplicate copy of signed contract must be given to the customer.',
  '',
  'Page | 4',
  '',
  '6.4',
  '',
  'Cooling-Off Period',
  '',
  'Every sales contract worth RM300.00 or more is subject to a cooling-off period of ten (10) working days from the date of sales contract. Customer may at any time, during this cooling-off period, rescind the sales contract if they change their minds. During such period, Beauty Consultant shall not deliver the Natura products ordered or receive any payment from the customer.',
  '',
  'Notice of Rescission',
  '',
  'For any rescission of sales contract by the customer before the expiry of cooling-off period, the customer shall provide a Notice of Rescission as set out in ANNEXURE 2 to Beauty Consultant. A Notice of Right of Rescission as set out in ANNEXURE 1 shall be provided to customers upon signing of any sales contract.',
  '',
  'Notice of Waiver',
  '',
  'Notwithstanding the above Clause 6.4, customer who requires the Natura products to be delivered earlier than the cooling-off period may serve a Notice of Waiver to the Beauty Consultant after a minimum of 72 hours has lapsed from the time the sales contract was signed.',
  '',
  '6.5',
  '',
  '6.6',
  '',
  'Note: The above Clause 6.3 to Clause 6.6 only applies to transaction between Beauty Consultant and the customer.',
  '',
  '6.7 Product Price',
  '',
  'The Beauty Consultant shall ensure that he or she will market Natura products based on the products price list as communicated by Natura Malaysia through the official website of Natura Malaysia at www.naturamy.com or product catalogue from time to time. The Beauty Consultant acknowledges that such prices have been pre-approved by the Ministry of Domestic Trade and Consumer Affairs, and that any deviation from the pre-approved price is STRICTLY PROHIBITED.',
  '',
  '6.8',
  '',
  'Product Return Policy',
  '',
  '6.8.1 Purchases made by Beauty Consultant, either for personal consumption or for fulfilment of customers’ orders, are subject to the following return policies:',
  '',
  '(a)',
  '',
  '(b)',
  '',
  'General Returns:',
  '',
  'Purchaser may return the Natura products sold within 14 days from the date of purchase for exchange or refund due to any of the followings:',
  '',
  '- products received are different from order placed;',
  '',
  '- defect or damage found upon receipt of products;',
  '',
  '- wrong product recommendation; or',
  '',
  '- adverse skin reaction to product.',
  '',
  '100% Satisfaction Guarantee:',
  '',
  'In addition to Clause 6.8.1 (a), Natura Malaysia offers 100% Satisfaction Guarantee to the Skin Care Routines category, for which dissatisfied purchaser may return the Skin Care Routines products for an one-time exchange with other Natura products within 30 days from the date of purchase, provided the returned Natura products contain at least 20% of content on the date of return.',
  '',
  'Page | 5',
  '',
  '6.8.2 On product exchange, if the price of the new item is higher than the original item, the price difference shall be borne by the purchaser. Nevertheless, there will be no refund of price difference to the purchaser if the price of the new item is lower than the original item.',
  '',
  '6.8.3 Customer may purchase a Natura product (i) directly from Beauty Consultant or (ii) through a weblink shared by Beauty Consultant. For avoidance of doubt, goods return period for a customer who made a purchase through (i) shall commence from the Beauty Consultant’s original date of purchase from Natura Malaysia. On the other hand, the goods return period for (ii) shall commence from the customer’s date of purchase from Natura Malaysia.',
  '',
  '6.8.4 All returns must be supported with proof of purchase and subject to acceptance by Natura Malaysia.',
  '',
  '6.9 Prohibition on Sale of Product',
  '',
  'Beauty Consultant shall not sell or resell Natura products through any unauthorized third party, business or retail establishment, and/or internet site without prior written consent from Natura Malaysia. Any contravention of this clause by the Beauty Consultant may result in the immediate termination of this Agreement by Natura Malaysia.',
  '',
  '7. PAYMENT',
  '',
  '7.1 Beauty Consultant agrees that Natura Malaysia shall not be obligated to fulfill any order placed by Beauty Consultant should there be any outstanding payment due to Natura Malaysia by the Beauty Consultant.',
  '',
  '7.2 Beauty Consultant further agrees that in recovering the outstanding payment due from Beauty Consultant, Natura Malaysia shall reserve the rights to:',
  '',
  '(a) withhold the payment of all or part of the commission or monies due to the Beauty Consultant; and/or',
  '',
  '(b) offset any amount that may be due to Natura Malaysia by Beauty Consultant against any payment due to the Beauty Consultant.',
  '',
  '8. OBLIGATIONS OF NATURA MALAYSIA',
  '',
  '8.1 Natura Malaysia will:',
  '',
  '(a) provide membership services to Beauty Consultant;',
  '',
  '(b) supply the products to customers based on the sales secured by Beauty Consultant;',
  '',
  '(c) provide adequate guidance and training to the Beauty Consultant to enable the Beauty Consultant to carry out his or her obligations under this Agreement; and',
  '',
  '(d) facilitate the sales effort of Beauty Consultant through the provision of the necessary marketing materials.',
  '',
  '9. OBLIGATIONS OF THE BEAUTY CONSULTANT',
  '',
  '9.1 The Beauty Consultant shall:',
  '(a) use his or her best efforts to conclude sales with his or her customers;',
  '',
  'Page | 6',
  '',
  '(b) provide service to customer as and when required;',
  '',
  '(c) involve in the marketing of Natura products across his or her social network;',
  '',
  '(d) obtain prior consent from Natura Malaysia before organizing any customer servicing activities;',
  '',
  '(e) provide Natura Malaysia with customer information for onward processing of online sales order;',
  '',
  '(f) ensure prompt payment of any fee due under this Agreement;',
  '',
  '(g) be responsible for the recovery of payment from customers;',
  '',
  '(h) place only reasonable quantity of order as inventory (if required);',
  '',
  '(i) honour the product return policy in the event of a claim from his or her customers; and',
  '',
  '(j) not to make any misleading or false representation in relation to Natura Malaysia or Natura products to the customers or prospective customers.',
  '',
  '10. PRODUCT SHOWCASING',
  '',
  '10.1 Beauty Consultant shall obtain prior consent from Natura Malaysia for any Natura product showcasing activity, whether through online platforms, or event hosting or participation. For physical events, it is important to note that the Natura products shall only be displayed at internal windows (without exposure to public areas) and shall not be displayed at external windows and kiosk counters. In addition, Beauty Consultant shall ensure that the event is associated with beauty, fashion (clothing, accessories), health or well-being. Natura Malaysia may, at its sole discretion, oppose the showcasing of Natura products at events deemed inappropriate and not having affinity with the brand.',
  '',
  '11. REPRESENTATIONS AND WARRANTIES OF BEAUTY CONSULTANT',
  '',
  '11.1 The Beauty Consultant hereby represents and warrants that:',
  '',
  '(a) he or she has full right and power to enter into and perform this Agreement without the consent of any third party or breach of any third-party obligation;',
  '',
  '(b) in the course of carrying out his / her obligations under this Agreement, he or she will comply with all Applicable Law (including the Direct Sales Legislation, Anti-Bribery Legislation and Data Protection Legislation), the Code of Conduct, and other rules, policies and procedures as communicated to the Beauty Consultant by the Company from time to time; and',
  '',
  '(c) should the Company permits the Beauty Consultant to use any of the Company’s equipment, tools, or facilities during the tenure of this Agreement, such permission shall be gratuitous and the Beauty Consultant shall be responsible for any injury to any person (including death) or damage to property (including Company’s property) arising from the use of such equipment, tools or facilities.',
  '',
  '12. INTELLECTUAL PROPERTY',
  '',
  '12.1 The Beauty Consultant agrees that all intellectual property residing in any information, software, documents, data, materials, Natura products, brochures, booklets, catalogues and',
  '',
  'Page | 7',
  '',
  'facility of any nature, whether digital or physical, whatsoever provided by Natura Malaysia to the Beauty Consultant in relation to this Agreement shall not be transferred, assigned and/or otherwise licensed to the Beauty Consultant, and shall belong exclusively to and shall remain the property of Natura Cosmeticos S.A.',
  '',
  '12.2 The Beauty Consultant warrants and represents that:',
  '',
  '(a) in using Natura Trademarks, he or she shall obtain prior consent from Natura Malaysia, and shall comply with usage guideline as issued by Natura Malaysia from time to time;',
  '',
  '(b) not to use the intellectual property other than for the purposes contemplated under this Agreement;',
  '',
  '(c) not to do or permit anything to be done which may infringe the intellectual property rights relating to the Agreement; and',
  '',
  '(d) notify the Company immediately of any violation of any intellectual property rights of which the Beauty Consultant is aware.',
  '',
  '13. INDEMNITY',
  '',
  '13.1 The Beauty Consultant shall indemnify Natura Malaysia from all claims, liabilities, costs, damages and expenses of every kind and nature which arise out of or in connection with:',
  '',
  '(a) failure to comply with the terms of this Agreement, Code of Conducts, and any Applicable Law (including the Direct Sales Legislation, Anti-Bribery Legislation and Data Protection Legislation);',
  '',
  '(b) any fraud, dishonesty, misrepresentation, willful misconduct or gross negligence on the part of the Beauty Consultant; and',
  '',
  '(c) the Beauty Consultant is in breach of its representations and warranties under this Agreement.',
  '',
  '13.2 This Clause 13 shall survive this Agreement.',
  '',
  '14. RELATIONSHIP OF THE PARTIES',
  '',
  '14.1 Natura Malaysia appoints the Beauty Consultant as an independent contractor. Nothing in this Agreement shall cause the Beauty Consultant to be construed as an employee, agent or solicitor of Natura Malaysia.',
  '',
  '14.2 The Beauty Consultant shall have no right to receive any employee benefits provided by the Company to its employees.',
  '',
  '14.3 The Beauty Consultant agrees to pay all taxes in respect of his or her remuneration earned under this Agreement, and to indemnify the Company in respect of any obligation that may be imposed on the Company to pay any such taxes.',
  '',
  '14.4 The Beauty Consultant is responsible for all expenses incurred in the course of carrying out his or her obligations under this Agreement. This includes traveling expenses, delivery charges, rental of equipment or facility, and meal or entertainment expenses.',
  '',
  '14.5 The Beauty Consultant acknowledges that his or her appointment is not an exclusive appointment, and that Natura Malaysia may conduct its activities in any manner which it deems appropriate.',
  '',
  'Page | 8',
  '',
  '15. CONFIDENTIAL INFORMATION',
  '',
  '15.1 The Beauty Consultant acknowledges that in the course of his or her membership with Natura Malaysia, he or she is exposed to certain Confidential Information which is not publicly available.',
  '',
  '15.2 “Confidential Information” means all information (whether or not recorded in documentary form, or stored on any magnetic or optical disk or memory) relating to the Company’s business which is not in the public domain, including without limitation, compensation scheme, commission structure, proprietary methodologies, branded solutions, research approaches, software, technical information and know-how, information relating to current, future and potential business operations, client offerings, marketing and business plans and strategies, pricing information, research and development projects, Natura product formulae, processes, inventions, designs or discoveries, sales statistics, marketing surveys and plans, costing, profit or loss, names and contact details of customers and suppliers (current and potential), as well as policies, procedures and practices of the Company.',
  '',
  '15.3 Except as required by law or court order, or agreed to in advance and in writing by the Company, the Beauty Consultant warrants and represents that, at all material times:',
  '',
  '(a) he or she shall not disclose Natura Malaysia\'s Confidential Information to any third party at any time, and shall treat such Confidential Information as secret and in strict confidence;',
  '',
  '(b) he or she shall not directly or indirectly cause the Confidential Information to be reproduced, distributed, disclosed and/or imparted to any third party;',
  '',
  '(c) he or she shall only use the Confidential Information for the purposes of fulfilling his/her obligations under this Agreement;',
  '',
  '(d) he or she shall take all necessary actions and measures to maintain confidentiality of the Confidential Information;',
  '',
  '(e) he or she shall not use the Confidential Information to solicit or enter into any business transactions or any undertaking which will be adverse to the business of Natura Malaysia; and',
  '',
  '(f) he or she shall not use, implement or modify any of the Confidential Information for any benefit to himself/herself and/or Natura Malaysia\'s competitors.',
  '',
  '15.4 In the event that the Beauty Consultant has knowledge that such Confidential Information of Natura Malaysia has or will be disclosed, the Beauty Consultant shall immediately notify Natura Malaysia and cooperate with Natura Malaysia to ensure that such divulgence of Confidential Information is immediately contained.',
  '',
  '15.5 This Clause 15 shall survive this Agreement.',
  '',
  '16. DATA PROTECTION',
  '',
  '16.1 The Beauty Consultant acknowledges that Natura Malaysia will collect and process personal data of the Beauty Consultant for purposes relating to this Agreement, including:',
  '',
  '(a) for the fulfilment of Natura Malaysia\'s obligations pursuant to this Agreement;',
  '',
  '(b) communicating with the Beauty Consultant in connection with this Agreement;',
  '',
  '(c) organizing and co-ordinating training, capacity building activities, information meetings, workshops and other similar events;',
  '',
  'Page | 9',
  '',
  '(d) for purposes of statistics;',
  '',
  '(e) for communicating with the Beauty Consultant on latest updates and information via e-mail, mobile messengers or through phone calls relating to any campaigns or promotional activities by Natura Malaysia;',
  '',
  '(f) for co-ordinating promotional activities, sales incentives, marketing or targeted advertising of the Natura products;',
  '',
  '(g) for recognizing the contributions of the Beauty Consultant and providing awards;',
  '',
  '(h) for fraud prevention and verification;',
  '',
  '(i) for compliance with legal or judicial orders;',
  '',
  '(j) to establish, defend or exercise regular judicial or administrative rights; and',
  '',
  '(k) in relation to transactions or corporate restructuring.',
  '',
  '16.2 The Beauty Consultant agrees that the Beauty Consultant\'s personal data will be disclosed to its affiliates, third party service providers and third parties such as Natura Malaysia\'s principals which may be located overseas and regulatory authorities for the purposes described above.',
  '',
  '16.3 The Beauty Consultant understands that Natura Malaysia will not be able to carry out the purposes set out in Clause 16.1 if the Beauty Consultant does not provide his or her personal data to Natura Malaysia.',
  '',
  '16.4 The Beauty Consultant understands that if he or she has any questions, complaints, or wish to access and/or update personal data, he or she may contact the Company pursuant to Clause 18.1.',
  '',
  '17. TERMINATION OF AGREEMENT',
  '',
  '17.1 Either Party may terminate this Agreement by providing a thirty (30) calendar days\' written notice of such termination to the other Party.',
  '',
  '17.2 Unless otherwise stated, this Agreement may be terminated by the Company by providing a fourteen (14) calendar days’ written notice of such termination to the Beauty Consultant in',
  '',
  'the event of a Material Breach by the Beauty Consultant. “Material Breach” shall include:',
  '',
  '(a) any breach of the terms in this Agreement of which the Beauty Consultant has failed to cure within thirty (30) calendar days after the receipt of written notice from the Company;',
  '',
  '(b) death or physical or mental incapacity of the Beauty Consultant as a result of which the Beauty Consultant becomes unable to continue the proper performance of his or her obligations under this Agreement – termination without notice upon occurrence of event;',
  '',
  '(c) an act of manipulation, gross negligence or willful misconduct by the Beauty Consultant – immediate suspension of membership pending investigation by the Company, followed by immediate termination with notice upon conviction; and',
  '',
  '(d) the bankruptcy of the Beauty Consultant.',
  '',
  'Page | 10',
  '',
  '17.3 This Agreement shall be terminated automatically if:',
  '',
  '(a) the Beauty Consultant fails to achieve the pre-determined sales target in at least one (1) calendar month out of four (4) calendar months.',
  '',
  '(b) any rights granted to Natura Malaysia to operate the direct sales business has become lapsed or terminated by any reason.',
  '',
  '18. NOTICES',
  '',
  '18.1 All notices, statements, demands, requests or other communications required or permitted under this Agreement (“Communication”) shall be in writing and in the English or Malay language, and shall be either delivered by hand, sent by electronic mail (“email”) or by registered mail (airmail in the case of all international Communication), to either Party pursuant to the contact information as stated below:',
  '',
  'Beauty Consultant:',
  '',
  'As per the information provided to Natura Malaysia.',
  '',
  'Natura Malaysia:',
  '',
  'Company Address',
  '',
  '(a) if delivered by hand, upon delivery;',
  '',
  '(b) if sent by mail, after three (3) Business Days of postage;',
  '',
  '(c) if sent by email, on the date the email is received or twenty-four (24) hours following the date of email delivery, whichever is earlier.',
  '',
  '19. GENERAL',
  '',
  '19.1 Waivers',
  '',
  'No delay or failure by any of the Parties to exercise or enforce at any time any right or provision of this Agreement shall be considered a waiver, unless otherwise made in writing. No single waiver shall constitute a continuing or subsequent waiver.',
  '',
  '19.2 Severability',
  '',
  'If any provision of this Agreement is found by any court, regulatory or administrative body of competent jurisdiction to be illegal, invalid or unenforceable, and the provision in question is not of a fundamental nature to the Agreement as a whole, such provision shall be severed and',
  '',
  ': Ola Beleza Sdn Bhd',
  '',
  ': No.5, Jalan USJ 10/1C, 47620 UEP Subang Jaya, Selangor Darul Ehsan, Malaysia',
  '',
  ': ola@naturamy.com',
  '18.2 Any Communication shall be deemed to have been given, served or delivered:',
  '',
  'Email Address',
  '',
  'Page | 11',
  '',
  'the legality, validity and enforceability of the remainder of the Agreement (including the remainder of the provision which contains the relevant provision) shall not be affected.',
  '',
  '20. GOVERNING LAW',
  '',
  'This Agreement shall be governed by and construed in accordance with the laws of Malaysia and the Parties hereby agree to submit to the exclusive jurisdiction of the courts of Malaysia.',
  '',
  '[The rest of this page is intentionally left blank]',
  '',
  'Page | 12',
  '',
  'NOTICE OF RIGHT OF RESCISSION',
  '(BEFORE THE EXPIRY OF THE COOLING-OFF PERIOD)',
  '',
  'To : Name of Customer Address of Customer',
  '',
  'Take notice that you have the right to rescind the sales contract before the expiry of cooling-off period, that is, before the end of the period of ten (10) working days commencing on the day after the date of making of this sales contract by giving a Notice of Rescission to the Beauty Consultant.',
  '',
  'Date:',
  '',
  'Signature',
  'Name : Address :',
  '',
  ':',
  '',
  'Sign by Beauty Consultant',
  '',
  'Name of Beauty Consultant Address of Beauty Consultant Address of Beauty Consultant',
  '',
  'ANNEXURE 1',
  '',
  'To :',
  '',
  'NOTICE OF RESCISSION',
  '',
  'Ola Beleza Sdn Bhd (1315238-V)',
  '',
  'No.5, Jalan USJ 10/1C 47620 UEP Subang Jaya Selangor Darul Ehsan Malaysia.',
  '',
  'Take notice that I _______________________________________ of ____________________ hereby rescind the sales contract made by me on ____/____/______to purchase or to be provided with the products set out in the abovementioned sales contract.',
  '',
  'Date:',
  '',
  'Signature Name Address',

]











