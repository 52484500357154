import { ADDRESS_TYPE } from '../../../../../../../../models/Address/AddressType'
import { DELIVERY_ADDRESS, MAILING_ADDRESS } from '../../../../../../../../models/Address/AddressUse'

export const getArgentinaParsedData = ({ data, configs, person }) => {
  const {
    localization: {
      address: {
        levels
      },
      countryName
    }
  } = configs

  const addresses = data.copyAddress
    ? buildWithSingleAddress({ data, levels })
    : buildWithDeliveryAddress({ data, levels, countryName })

  return { ...person, addresses }
}

function buildWithSingleAddress({ data, levels }) {
  const address = {
    ...buildAddress({ data, levels }),
    addressUse: [DELIVERY_ADDRESS, MAILING_ADDRESS]
  }
  return [address]
}

function buildWithDeliveryAddress({ data, levels, countryName }) {
  const parsedAddress = buildAddress({ data, levels })
  const homeAddress = {
    ...parsedAddress,
    addressType: ADDRESS_TYPE.MAILING,
    addressUse: [MAILING_ADDRESS]
  }
  const deliveryAddress = {
    addressType: ADDRESS_TYPE.DELIVERY,
    addressUse: [DELIVERY_ADDRESS],
    zipCode: parsedAddress.deliveryZipCode,
    geographicalLevels: buildGeographicLevels({ data, levels, countryName })
  }
  return [
    homeAddress,
    deliveryAddress
  ]
}

function buildAddress({ data, levels }) {
  const address = parseData(data, levels)
  const cumulativeAddress = JSON.parse(localStorage.getItem('cumulativeAddress'))

  address.geographicalLevels = [...cumulativeAddress.geographicalLevels, ...address.geographicalLevels]

  return address
}

function buildGeographicLevels({ data, levels, countryName }) {
  const {
    deliveryNumber,
    deliveryComplement,
    deliveryReferences,
    deliveryStreet,
    level1,
    level2,
    level3,
    level4
  } = data.address[0]

  const { parsedLevel1, parsedLevel2, parsedLevel3, parsedLevel4 } = buildGeoCommercialStructureLevels({
    level1,
    level2,
    level3,
    level4
  })

  const geographicalLevels = [
    { level: levels.COUNTRY, description: countryName },
    { level: levels.NUMBER, description: deliveryNumber },
    { level: levels.STREET, description: deliveryStreet },
    { level: levels.REFERENCES, description: deliveryReferences },
    { level: levels.LEVEL1, code: parsedLevel1.code, description: parsedLevel1.description },
    { level: levels.LEVEL2, code: parsedLevel2.code, description: parsedLevel2.description },
    { level: levels.LEVEL3, code: parsedLevel3.code, description: parsedLevel3.description },
    { level: levels.LEVEL4, code: parsedLevel4.code, description: parsedLevel4.description }
  ]

  if (deliveryComplement) {
    geographicalLevels.push({ level: levels.COMPLEMENT, description: deliveryComplement })
  }

  return geographicalLevels
}

function buildGeoCommercialStructureLevels({ level1, level2, level3, level4 }) {
  return {
    parsedLevel1: level1,
    parsedLevel2: level2,
    parsedLevel3: level3,
    parsedLevel4: level4,
  }
}

function parseData(data, levels) {
  const firstAddress = data.address[0]
  return mapAddress({ address: firstAddress, levels })
}

function mapAddress({ address, levels }) {
  const {
    number,
    complement,
    references,
    street,
    zipCode,
    deliveryZipCode
  } = address

  const addressType = ADDRESS_TYPE.MAILING
  const processedNumber = number === 'S/N' ? '' : number

  const geographicalLevels = [
    { level: levels.NUMBER, description: processedNumber },
    { level: levels.STREET, description: street },
    { level: levels.REFERENCES, description: references }
  ]

  if (complement) {
    geographicalLevels.push(
      { level: levels.COMPLEMENT, description: complement }
    )
  }

  return {
    addressType,
    zipCode,
    deliveryZipCode,
    geographicalLevels
  }
}
