import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter= validatorAdapter('Yup', yup)

class Level extends Entity {
  static SCHEMA = {
    geoStructureCode: adapter(yup.string().required()),
    structureLevelId: adapter(yup.string().required()),
    structureLevelName: adapter(yup.string().required())
  }

  get label() { return this.structureLevelName }

  get value() { return this.geoStructureCode }
}

export default class Addresses extends Entity {
  static SCHEMA = {
    countryName: adapter(yup.string().nullable()),
    departments: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    regions: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    cities: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    communes: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    neighborhoods: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    provinces: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
    districts: {
      validator: adapter(yup.object().nullable()),
      builder(data) {
        return buildLevel(Level, data)
      },
      defaultValue: {}
    },
  }

  constructor(props, injection) {
    super(props, injection)

    Object.assign(this, injection)
  }
}

function buildLevel(builder, data) {
  const entries = Object.entries(data)
  return entries.length
    ? entries.reduce(reduceDeps, { builder })
    : {}
}

function reduceDeps(accum, [key, value], index, array) {
  const lastItem = index === array.length - 1
  const { builder } = accum

  if(lastItem) delete accum.builder

  if(!value && !value.length) return accum

  return {
    ...accum,
    [ key ]: value
      .map(Builder, { builder })
      .filter(builder => builder.value)
      .sort(sort)
  }
}

function Builder(data) {
  return new this.builder(data)
}

function sort(a, b) {
  return a.label.localeCompare(b.label)
}
