import { isEnableDigitalStoreStepForAvonBrazilFlowToggleActive } from '../../../../../config'
import { ROUTES } from 'src/App.routes'
import { ROLES } from 'src/infra/Api/MEX/roles.mexico'

export default () => ({
  countryName: 'BRAZIL',
  agreements: {
    linkPDF: null,
    linkAudio: null,
    hasEnvironmentsFiles: false
  },
  address: {
    levels: {
      ZIP_CODE: 0,
      STREET: 1,
      NUMBER: 2,
      COMPLEMENT: 3,
      COLOGNE: 4,
      CITY: 5,
      STATE: 6
    },
    hasZipCode: true
  },
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 2
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '55',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 8
      },
      mobile: {
        min: 9
      }
    },
    mask: {
      line: '(99) 9999-9999',
      mobile: '(99) 99999-9999'
    },
    placeholder: {
      line: '(XX) XXXX-XXXX',
      mobile: '(XX) XXXXX-XXXX'
    }
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: false,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  preRegister: {
    useCreatePersonFromBFF: true
  },
  personalData: {
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: true
  },
  businessModel: {
    shouldHideButton: false
  },
  date: {
    format: 'DD/MM/YYYY'
  },
  hasDigitalStore: isEnableDigitalStoreStepForAvonBrazilFlowToggleActive(),
  shouldUpdatePersonFromBff: true,
  shouldUpdateRegistrationSubstatusFromBff: true,
  shouldGetPersonDetailsFromBff: true,
  shouldGetEstablishmentsFromBff: true,
  shouldGetBusinessModelFromBff: true,
  shouldGetRejectionReasonsFromBff: true,
  shouldGetAddressFromBff: true,
  shouldIgnorePeopleContextResponseOnLogin: false,
  shouldCompleteRegisterWithBff: true,
  isHostedUIEnabled: true,
  showProfileEditButton: true,
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: true,
  urlToReportsFV: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=bra&company=avon`,
  redirectUrlToLogin: {
    url: `${process.env.DOMAIN_HOSTED_UI_AVON}`,
    clientId: `${process.env.CLIENT_ID_COGNITO}`
  },
  urlRecoverSession: {
    url: `${process.env.REGISTERS_URL}/recover-session`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  urlSignOut: {
    url: `${process.env.REGISTERS_URL}/sign-out`,
    apiKey: `${process.env.REGISTERS_X_API_KEY}`
  },
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=bra&company=avon`,
      external: true,
      shouldSSO: true,
      shouldDeviceId: true
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=bra&company=avon`,
      external: true,
      shouldSSO: true,
      shouldDeviceId: true
    },
    GR: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=bra&company=avon`,
      external: true,
      shouldSSO: true,
      shouldDeviceId: true
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/AvonMexico/',
      label: 'Facebook Avon'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/avonmexico',
      label: 'Youtube Avon'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/AvonMexico',
      label: 'Twitter Avon'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/avonmexico',
      label: 'Instagram Avon'
    },
    {
      type: 'pinterest',
      link: 'https://www.pinterest.com.mx/avonmexico',
      label: 'Pinterest Avon'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Baixe na Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Baixe na App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
})
