import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'src/base/Loader'
import bemClassName from 'bem-classname'
import { injectIntl } from 'react-intl'
import {
  ResendEmailContent,
  ResendEmailSuccess,
  ResendEmailError
} from './EmailModalContent'

const bem = bemClassName.bind(null, 'email-validation')

class EmailModalContentState extends Component {
  constructor(props) {
    super(props)

    this.state = {
      success: false,
      error: false,
    }

    this.send = this.send.bind(this)
  }

  componentWillUnmount() {
    const { emailValidation } = this.props

    emailValidation.reset()
  }

  send() {
    const { emailValidation, person: { personId }, email: { sequence } } = this.props

    emailValidation.resend({ personId, sequence })
  }

  render() {
    const { emailValidation: { wasSent }, loading, error } = this.props

    if (loading) return this.renderLoading()
    if (error) return this.renderResendEmailError()
    if (wasSent) return this.renderResendEmailSuccess()

    return this.renderResendEmailContent()
  }

  renderLoading() {
    return <Loader className={bem('loader')} />
  }

  renderResendEmailError() {
    const { intl, closeModal } = this.props

    return <ResendEmailError intl={intl} closeModal={closeModal} />
  }

  renderResendEmailSuccess() {
    const { intl, closeModal } = this.props

    return <ResendEmailSuccess intl={intl} closeModal={closeModal} />
  }

  renderResendEmailContent() {
    const { intl, email: { email }, closeModal } = this.props

    return (
      <ResendEmailContent
        intl={intl}
        email={email}
        closeModal={closeModal}
        send={this.send}
      />
    )
  }
}

EmailModalContentState.propTypes = {
  intl: PropTypes.object,
  email: PropTypes.object.isRequired,
  emailValidation: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
}

export default injectIntl(EmailModalContentState)
