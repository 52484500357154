import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const PERSON_COMPLETE_LOADING = 'natura-caduni/Person/PERSON_COMPLETE_LOADING'
export const PERSON_COMPLETE_SUCCESS = 'natura-caduni/Person/PERSON_COMPLETE_SUCCESS'
export const PERSON_COMPLETE_ERROR = 'natura-caduni/Person/PERSON_COMPLETE_ERROR'

export const types = [PERSON_COMPLETE_LOADING, PERSON_COMPLETE_SUCCESS, PERSON_COMPLETE_ERROR]

export function personCompleteReducer(state, action) {
  let { error } = state

  const hasError = action.type === PERSON_COMPLETE_ERROR
  const isLoading = action.type === PERSON_COMPLETE_LOADING

  if (hasError) {
    error = new Error(action.error)
  }

  return {
    ...state,
    loading: isLoading,
    error
  }
}

export function completePerson({ personId, businessModel }) {
  const { dispatch } = getContext()
  const clientName = getClient()
  const headers = createHeaders(businessModel, personId)
  const url = getCompleteRegisterURL(personId)

  return dispatch({
    types,
    payload: {
      client: clientName,
      request: {
        method: 'POST',
        url,
        headers
      }
    }
  })
}

const getCompleteRegisterURL = (personId) => {
  const { urls, default_bff_urls, locale } = getContext()

  const { COMPLETE_PEOPLE: COMPLETE_PEOPLE_GPP } = urls
  const { COMPLETE_PEOPLE: COMPLETE_PEOPLE_BFF } = default_bff_urls

  if (shouldUseBFF(locale)) {
    return COMPLETE_PEOPLE_BFF()
  }

  return COMPLETE_PEOPLE_GPP(personId)
}

const getClient = () => {
  const { locale } = getContext()
  return shouldUseBFF(locale) ? CLIENT_NAMES.DEFAULT_BFF : locale.id
}

const createHeaders = (businessModel, personId) => {
  const {
    default_bff_apiKeys: { REGISTERS_APIKEY },
    user: {
      sellerId,
      authentication: {
        xApikey,
        accessToken
      }
    },
    locale,
  } = getContext()

  const { id: countryId, companyId} = locale

  const {
    businessModelId,
    functionId,
    roleId
  } = businessModel

  let headers = {
    Authorization: accessToken,
    userId: sellerId,
    role: roleId,
  }

  if (shouldUseBFF(locale)) {
    headers = {
      ...headers,
      'x-api-key': REGISTERS_APIKEY,
      personid: personId,
      countryid: countryId,
      companyid: companyId,
      functionid: functionId,
      businessmodel: businessModelId,
      sourcesystem: '7',
    }
  } else {
    headers = {
      ...headers,
      'x-api-key': xApikey,
      access_token: accessToken,
      function: functionId,
      businessModel: businessModelId,
    }
  }

  return headers
}

const shouldUseBFF = (locale) => locale.configs.localization.shouldCompleteRegisterWithBff
