import * as yup from 'yup'
import { defineMessages } from 'react-intl'

import personalMessages from './Policy.personal.messages'

export const messages = defineMessages({
  required: {
    defaultMessage: 'Required field',
    id: 'form.field.policy.error.required'
  }
})

const dependencies = {
  messages,
  personalMessages
}

export default function Builder(intl, injection, policiesField) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { policiesAccepted: { policiesAcceptedGroup } } } = injection
  const { Personal } = policiesField

  const POLICY_PERSONAL = { ...Personal(intl, { ...injection, ...dependencies }), required: true }

  const errorMsg = intl.formatMessage(messages.required)

  return {
    id: 'policiesAcceptedGroup',
    min: 1,
    max: 1,
    defaultValue: [policiesAcceptedGroup],
    fields: [
      [POLICY_PERSONAL]
    ],
    validations: yup.array().of(yup.object().shape({
      personal_data: yup.boolean().oneOf([true], errorMsg).required()
    }))
  }
}
