import { getContext } from 'src/infra/Api/Api.context'
import Person from 'src/models/Person'

export const PERSON_GET_LOCAL = 'natura-caduni/Person/PERSON_GET_LOCAL'

export const types = [PERSON_GET_LOCAL]

export function personGetLocalReducer(state, action, dependencies, peopleRegister) {
  const { payload, type } = action

  if(type === PERSON_GET_LOCAL) {
    const { person } = payload
    const data = peopleRegister.get(person) || person

    return {
      ...state,
      error: false,
      loading: false,
      person: new Person(data, dependencies)
    }
  }

  return state
}

export function getPersonLocal(person) {
  const { dispatch } = getContext()

  return dispatch({
    type: PERSON_GET_LOCAL,
    payload: {
      person
    }
  })
}
