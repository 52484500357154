import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import ReactSelect from 'react-select'
import { debounce } from 'lodash'

import Label from 'src/base/Form/Components/Label'
import Message from 'src/base/Form/Components/Message'
import Loader from 'src/base/Loader'

import { STYLES } from './Select.config'

import './Select.styl'
import { isDefaultFlowToggleActive } from '../../../../config'


class Select extends Component {
  constructor(props) {
    super(props)

    this.originalValue = this.props.value
    this.bem = bemClassName.bind(null, 'defaultSelect')

    this.state = {
      selected: this.findValueOnOptions(this.originalValue)
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = debounce(this.onBlur.bind(this), 0)
    this.noOptionsMessage = this.noOptionsMessage.bind(this)
  }

  componentDidUpdate() {
    const { value } = this.state.selected || {}
    const { value: newValue } = this.props

    if(newValue && value !== newValue) {
      const selected = this.findValueOnOptions(newValue)

      selected && selected.value && this.setState({ selected })
    }

    if(value && !newValue) {
      this.setState({ selected: null })
    }
  }

  onChange(selected) {
    const { value, label } = selected || {}
    const { onChange } = this.props

    this.setState({ selected })

    onChange && onChange(value, label)
  }

  onBlur() {
    const { value } = this.state.selected || {}
    const { onBlur } = this.props

    onBlur && onBlur(value)
  }

  findValueOnOptions(value) {
    const { options } = this

    if(!options.length) return null

    return options.find(findOptionByValue, { value })
  }

  noOptionsMessage() {
    return this.props.noOptionsMessage
  }

  get options() {
    const { options, id, form, group } = this.props

    if(typeof options === 'function')
      return options({ id, ...form, group })

    return [].concat(options)
  }

  render() {
    const { className, customClassName, disabled, hidden, error, loading } = this.props

    const modifiers = {
      [customClassName]: !!customClassName,
      disabled,
      error: error && !loading,
      loading,
      hidden
    }

    return(
      <div className={classNames(this.bem(modifiers), className)} data-error={!!error}>
        {this.renderLabel()}
        {this.renderSelect()}
        {this.renderLoader()}
        {this.renderMessage()}
      </div>
    )
  }

  renderLabel() {
    const { id, label, required, error, loading, disabled, showAsterisk } = this.props

    if(!label) return null

    return (
      <Label
        className={this.bem('label')}
        id={id}
        label={label}
        showAsterisk={showAsterisk}
        required={required}
        disabled={disabled}
        error={!!error && !loading}
      />
    )
  }

  renderSelect() {
    const {
      id,
      disabled,
      htmlProps,
      menuPlacement,
      placeholder,
      maxMenuHeight,
      styles,
      isSearchable,
      isClearable,
      loading
    } = this.props

    const { selected } = this.state

    return (
      <ReactSelect
        className={this.bem('component')}
        classNamePrefix={this.bem('component')}
        name={id}
        id={id}
        data-testid={id}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        noOptionsMessage={this.noOptionsMessage}
        options={this.options}
        styles={styles}
        value={selected}
        isSearchable={isSearchable}
        isDisabled={!!disabled && !loading}
        blurInputOnSelect={true}
        isClearable={isDefaultFlowToggleActive() ? false : isClearable}
        onBlur={this.onBlur}
        onChange={this.onChange}
        {...htmlProps}
      />
    )
  }

  renderMessage() {
    const { message, error, disabled, loading } = this.props

    if(!message && !error) return null

    return (
      <Message
        className={this.bem('message')}
        message={message}
        error={!loading && error}
        disabled={disabled}
      />
    )
  }

  renderLoader() {
    const { loading } = this.props

    if(!loading) return null

    return (
      <Loader className={this.bem('loader')} size={20} transparent={true} fixed={false} />
    )
  }
}

Select.propTypes = {
  customClassName: PropTypes.string,
  showAsterisk: PropTypes.bool,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  required: PropTypes.bool,
  form: PropTypes.object,
  group: PropTypes.object,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  htmlProps: PropTypes.object,
  menuPlacement: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
  styles: PropTypes.object,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

Select.defaultProps = {
  isSearchable: true,
  isClearable: true,
  menuPlacement: 'auto',
  maxMenuHeight: 200,
  styles: STYLES,
  disabled: false,
  success: false,
  error: false,
  loading: false
}

function findOptionByValue({ value }) {
  return value === this.value
}

export default Select
