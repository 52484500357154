import * as yup from 'yup'
import { store } from 'src/App.state'
import City from './City.col'
import Department from './Department.col'
import Neighborhood from './Neighborhood.col'
import FormDivider from '../AddressDivider'
import { NO_INFORMATION } from 'src/viewModels/Addresses'
import { findAddress } from 'src/models/Address'
import {
  ADDRESS_USE,
  MAILING_ADDRESS,
  DELIVERY_ADDRESS
} from 'src/models/Address/AddressUse'

export default function Builder(intl, injection) {
  const {
    person: { addresses = [] },
    locale,
    connectivity
  } = injection
  const countryName = retrieveCountryNameFromLocale(locale)

  const baseValue = {
    copyAdress: true,
    countryName
  }

  const defaultValue = parsedDefaultValues(addresses, baseValue, connectivity)
  const DEPARTMENT = Department(intl, injection)
  const CITY = City(intl, injection)
  const NEIGHBORHOOD = Neighborhood(intl, injection)
  const GROUP_DIVIDER = FormDivider(intl, injection)

  return {
    id: 'address',
    min: 1,
    max: 20,
    itemTitle: GROUP_DIVIDER,
    baseValue,
    defaultValue: defaultValue,
    fields: [
      [DEPARTMENT, CITY],
      [NEIGHBORHOOD]
    ],
    validations: yup
      .array()
      .of(
        yup.object().shape({
          department: yup.string().required(),
          city: yup.string().required(),
          neighborhood: yup.string().required()
        })
      )
      .min(1)
      .max(20)
  }

  function retrieveCountryNameFromLocale(locale) {
    return locale.name && locale.name.toUpperCase()
  }
}

export function parsedDefaultValues(values, baseValue, connectivity) {
  if (values.length) {
    const addresses = sortAddresses(values, baseValue)

    const onlyMailingAddress = addresses.filter(address => {
      const addressUseDelivery =  address.addressUse.find(use =>  {
        return use.id === MAILING_ADDRESS.id
      })
      return addressUseDelivery !== undefined
    })

    const parsedValues = connectivity.isOffline
      ? onlyMailingAddress.reduce(reduceAddressOffline, [baseValue])
      : onlyMailingAddress.reduce(reduceAddress, [baseValue])

    return [{...parsedValues[0], addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]}]
  }

  return [{ ...baseValue, addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS] }]
}

export function sortAddresses(values, baseValue) {
  const addresses = []
  const listAddress = [].concat(values)

  const mailingAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.MAILLING
  })
  const deliveryAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.DELIVERY
  })
  const alternativeAddress = listAddress.find(findAddress, {
    useId: ADDRESS_USE.ALTERNATIVE
  })

  const hasDeliveryAddress =
    (deliveryAddress && !mailingAddress) ||
    (deliveryAddress && mailingAddress && !mailingAddress.isUseToDelivery)

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
    addresses.push(mailingAddress)
  } else {
    addresses.push({
      ...baseValue,
      isUseToDelivery: !hasDeliveryAddress,
      addressUse: [MAILING_ADDRESS, DELIVERY_ADDRESS]
    })
  }

  if (hasDeliveryAddress) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
    addresses.push(deliveryAddress)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
    addresses.push(alternativeAddress)
  }

  return [...addresses, ...listAddress]
}

export function reduceAddress(accum, address, index, array) {
  const {
    addresses: { addressesPeopleManagement: addresses }
  } = store.getState()
  const { departments, cities, neighborhoods } = addresses
  
  const [{ countryName }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const {
    department,
    city,
    neighborhood,
    addressUse,
    isUseToDelivery,
    sequence
  } = address

  const { geoStructureCode: departmentCode } = getStructure(
    departments[countryName],
    department
  )

  const { geoStructureCode: cityCode } = getStructure(
    cities[departmentCode],
    city
  )

  const { geoStructureCode: neighborhoodCode } = getStructure(
    neighborhoods[cityCode],
    neighborhood
  )
  
  const newAddress = {
    department: departmentCode,
    city: cityCode,
    neighborhood: neighborhoodCode,
    addressUse,
    countryName,
    copyAdress: isUseToDelivery,
    sequence
  }

  return [...accum, newAddress]
}

export function reduceAddressOffline(accum, address, index, array) {
  const [{ countryName, copyAdress }] = accum

  const lastitem = index === array.length - 1

  if (lastitem) accum.shift()

  if (!address) return accum

  const { department, city, neighborhood } = address

  const newAddress = {
    department,
    city,
    neighborhood,
    countryName,
    copyAdress
  }

  return [...accum, newAddress]
}

export function getStructure(items = [], { code, description } = {}) {
  if (description === NO_INFORMATION[0].geoStructureDescription) {
    return NO_INFORMATION[0]
  }

  return items.find(findStructure, { code }) || {}
}

function findStructure({ value }) {
  if (this.code) {
    return value.toString() === this.code.toString()
  }
  return value.toString() === this.code
}
