import { normalizePhones } from 'src/models/Phone'

export function onSubmition({ person, locale: { configs } }) {
  return function (data, callback) {
    const parsedData = parseData(data, person, configs)

    return person.save(parsedData, callback)
  }
}

function parseData(data, person, configs) {
  const {
    birthday,
    gender,
    name,
  } = data

  const emails = data.emails && data.emails.reduce(reduceEmails, person.emails)
  const phones = data.phones.map(normalizePhones, { configs })
  const documents = [{ document: data.cpf, type: 1 }]
  //const addresses = [{ zipCode: data.zipCode }]

  return {
    birthday,
    documents,
    emails,
    gender,
    name,
    phones,
    //addresses
  }
}

function reduceEmails(accum, current, index, array) {
  const [original] = accum

  if (index === array.length - 1)
    accum.splice(0, 1)

  const { email } = current

  if (!email)
    return accum

  return [...accum, { ...original, email }]
}
