import {parseApiDate} from 'src/lib/SpeckAdapter/Yup.customs'
import BusinessModel from 'src/models/BusinessModel'
import {statusEnum} from 'src/models/Status'
import {substatusEnum} from 'src/models/Substatus'
import {buildSteps} from 'src/configs/Locales/DEFAULT/views/Register'
import {BUSINESS_MODEL, FUNCTIONS, ROLES} from 'src/viewModels/BusinessModel'
import {APP_ROUTES} from 'src/App'

import FormSchema from 'src/base/Form/Schemas'
import TYPES from 'src/configs/Fields'

import {translateFunctionOptions} from 'src/configs/Fields/BusinessModel/FunctionId'
import { isChileWithLegoToggleActive, isDefaultFlowToggleActive } from '../../../../../../config'
import {getBusinessStructureFor} from './getBusinessStructureFor'
import { CountryIdAlphaCode3 } from 'src/models/country/country-ids'

const businessModelEnum = {
  businessModelId: 'box_business_model_label_name',
  functionId: 'box_business_model_label_function',
  roleId: 'box_business_model_label_role',
  startDate: 'box_business_model_label_start_date',
  endDate: 'box_business_model_label_end_date',
  businessStructure: 'box_business_model_label_business_structure',
  status: 'box_business_model_label_status',
  substatus: 'box_business_model_label_substatus'
}

export default function Builder(dependencies) {
  const { intl, messages, person, locale } = dependencies
  const { roles, draft } = person
  const shouldHideAggregateButton = locale.configs.localization.businessModel.shouldHideButton

  const businessModelsFields = roles.items.reduce((accum, role, index, array) => createBusinessModelField(accum, role, index, array, locale), [
    dependencies
  ])

  const businessModelsLabels = [
    {
      key: 'businessModelId',
      label: intl.formatMessage(messages[businessModelEnum['businessModelId']])
    },
    {
      key: 'roleId',
      label: intl.formatMessage(messages[businessModelEnum['roleId']])
    },
    {
      key: 'functionId',
      label: intl.formatMessage(messages[businessModelEnum['functionId']])
    },
    {
      key: 'startDate',
      label: intl.formatMessage(messages[businessModelEnum['startDate']])
    },
    {
      key: 'endDate',
      label: intl.formatMessage(messages[businessModelEnum['endDate']])
    },
    {
      key: 'businessStructure',
      label: intl.formatMessage(
        messages[businessModelEnum['businessStructure']]
      )
    },
    {
      key: 'status',
      label: intl.formatMessage(messages[businessModelEnum['status']])
    },
    {
      key: 'substatus',
      label: intl.formatMessage(messages[businessModelEnum['substatus']])
    }
  ]

  const touchAfterReload = [
    'businessModel[0].businessModelId',
    'businessModel[0].functionId',
    'businessModel[0].roleId'
  ]

  const fieldsSchema = [[TYPES.BUSINESS_MODEL(intl, dependencies)]]

  const formSchema = new FormSchema({ fieldsSchema, name: 'PersonalInfo' })

  const getShouldShowRules = (shouldHideAggregateButton, draft) => {
    if(shouldHideAggregateButton){
      return false
    } else if (!draft) {
      return !draft
    }
  }

  return {
    id: 'profile-business-model',
    title: intl.formatMessage(messages.box_business_model_title),
    wrapper: 'default',
    content: 'table',
    fields: businessModelsFields,
    labels: businessModelsLabels,
    modal: {
      title: intl.formatMessage(messages.box_business_model_modal_title),
      button: intl.formatMessage(messages.box_business_model_modal_button_add),
      form: {
        formSchema,
        touchAfterReload,
        confirmLabel: messages.box_business_model_modal_button_add,
        onSubmit: onSubmition(dependencies)
      },
      accept: false,
      shouldShow: getShouldShowRules(shouldHideAggregateButton, draft)
    }
  }
}

function createBusinessModelField(accum, role, index, array, locale) {
  const {
    businessModelId,
    functionId,
    startDate,
    endDate,
    roleId,
    status,
    substatus,
    canCease,
    hasPendingReprocess,
    isCN
  } = role

  const [
    {
      businessModel: {
        businessModel: { items }
      },
      locale: {
        id: localeId,
        configs: {
          localization: {
            date: { format }
          }
        },
      },
      person,
      history,
      intl,
      messages,
      connectivity,
      user
    }
  ] = accum

  const deps = { connectivity, intl, messages }

  const businessModelParams = findBusinessModel({
    businessModelId,
    items,
    ...deps
  })
  const roleParams = findRoles({ roleId, businessModelParams, ...deps })
  const functionParams = findFunction({ functionId, roleParams, ...deps })

  const businessModel = new BusinessModel({
    businessModelId: businessModelParams.value || businessModelId,
    functionId: functionParams.value || functionId,
    roleId: roleParams.value || roleId
  })

  const shouldShowPersonRole =
    businessModelParams && roleParams && functionParams
  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!shouldShowPersonRole) return accum

  const statusMessage =
    messages[`box_business_model_status_${[statusEnum[status]]}`]
  const subStatusMessage =
    messages[`box_business_model_substatus_${[substatusEnum[substatus]]}`]

  const personRole = {
    data: {
      businessModel: businessModelParams.label || '',
      role: roleParams.label || '',
      function: translateFunction({ intl, messages, functionParams }),
      startDate: (startDate && parseApiDate(startDate, format)) || '',
      endDate: (endDate && parseApiDate(endDate, format)) || '',
      businessStructure: getBusinessStructureFor({currentRole: role, localeId}),
      status: statusMessage && intl.formatMessage(statusMessage),
      substatus: subStatusMessage && intl.formatMessage(subStatusMessage)
    },
    actions: [
      {
        id: 'edit',
        title: getEditButtonTitle({ draft: person.draft, ...deps }),
        shouldShow: (user.isCSC && !endDate) || (!user.isCSC && isCN),
        onClick: editRole({
          businessModel,
          history,
          connectivity,
          person,
          user,
          locale,
          personId: person.personId
        })
      },
      {
        id: 'stop',
        title: intl.formatMessage(messages.profile_table_stop),
        shouldShow: canCease,
        modal: {
          title: intl.formatMessage(messages.profile_table_stop_modal_title),
          body: intl.formatMessage(messages.profile_table_stop_modal_message),
          cancelLabel: intl.formatMessage(
            messages.profile_table_stop_modal_cancel
          ),
          acceptLabel: intl.formatMessage(
            messages.profile_table_stop_modal_accept
          ),
          cancel: true,
          accept: true,
          disableBackdropClick: true,
          onClose: ceaseRole(person, role)
        }
      },
      {
        id: 'ui_replay',
        title: intl.formatMessage(
          messages.profile_table_reprocess_registration
        ),
        shouldShow: hasPendingReprocess,
        modal: {
          title: intl.formatMessage(
            messages.profile_table_reprocess_modal_title
          ),
          body: intl.formatMessage(
            messages.profile_table_reprocess_modal_message
          ),
          cancelLabel: intl.formatMessage(
            messages.profile_table_reprocess_modal_cancel
          ),
          acceptLabel: intl.formatMessage(
            messages.profile_table_reprocess_modal_accept
          ),
          cancel: true,
          accept: true,
          disableBackdropClick: true,
          onClose: onReprocessRegistration(person, businessModel)
        }
      }
    ]
  }

  return [...accum, personRole]
}

function shouldEditByRole(user, locale) {
  const locales = [CountryIdAlphaCode3.MALAYSIA]
    .concat(isChileWithLegoToggleActive() ? [CountryIdAlphaCode3.CHILE] : [])
  return user?.isCSC && locales.includes(locale.id)
}

function editRole(params) {
  const {
    businessModel: { businessModelAsUrl },
    history,
    connectivity,
    person,
    user,
    locale,
    personId,
  } = params

  const { draft, reviewStep } = person

  return function () {
    const isReview = draft && !connectivity.isOffline

    if (isReview) {
      const basePath = APP_ROUTES.REGISTER
      const steps = buildSteps({
        basePath,
        businessModelParams: businessModelAsUrl
      })
      const step = steps.find(findStep, {
        step: reviewStep.step || 'pre-register'
      })

      return history.push(`${step.path}/${personId}`)
    }

    if (businessModelAsUrl || person.roles.items.length) {
      const roleItems = person.roles.items[0] || {}
      const { businessModelId, roleId, functionId } = roleItems
      const pathByPersonRoles = person.roles.items.length
        ? `/auto/${businessModelId}/${roleId}/${functionId}/personal-data/${personId}`
        : `/auto/${businessModelAsUrl}/personal-data/${personId}`

      const editPath = shouldEditByRole(user, locale) ? pathByPersonRoles : `/auto/personal-data/${personId}`
      const validateToggles = isDefaultFlowToggleActive() && user?.canEditInSelfRegistrationFlow

      return history.push(validateToggles ? editPath : `/dashboard/register/${businessModelAsUrl}/personal-data/${personId}`)
    }


    return history.push(`/dashboard/register/business-model/${personId}`)
  }
}

function findStep({ name }) {
  return name === this.step
}

function ceaseRole(person, role) {
  const { personId, cease, getProfile } = person

  return function () {
    cease(role, function ({ error }) {
      if (!error) {
        getProfile(personId)
      }
    })
  }
}

function onSubmition({ person, history, user, locale }) {
  const { personId, saveOnState, createRole, getProfile } = person

  return function ({ businessModel: [data] }) {
    const businessModel = new BusinessModel(data)

    saveOnState({ businessModel })
    createRole({ businessModel, personId }, async function () {
      const personAux = await getProfile(personId, undefined, false, true)
      const role = person.roles.findCurrentRole(businessModel)

      if (!role.hasActiveDebt && !data.error) {
        editRole({ businessModel, person: personAux, history, user, locale, personId })()
      }
    })
  }
}

function translateFunction({ intl, messages, functionParams }) {
  const deps = {
    intl,
    messages,
    filterType: 'profile_option_function'
  }

  return [functionParams].map(translateFunctionOptions, deps)[0].label || ''
}

function getEditButtonTitle({ draft, connectivity, intl, messages }) {
  const hasReviewRegister = draft && !connectivity.isOffline

  if (hasReviewRegister)
    return intl.formatMessage(messages[`profile_table_review`])

  return intl.formatMessage(messages[`profile_table_edit`])
}

function findBusinessModel(params) {
  const {
    businessModelId: id,
    connectivity,
    intl,
    messages,
    items = []
  } = params

  if (connectivity.isOffline) {
    return {
      label: intl
        .formatMessage(messages.box_business_model_direct_sale)
        .toUpperCase(),
      value: BUSINESS_MODEL.DIRECT_SALE
    }
  }

  return items.find(({ businessModelId }) => businessModelId === id) || {}
}

function findRoles(params) {
  const {
    roleId: id,
    connectivity,
    intl,
    messages,
    businessModelParams: { roles = [] }
  } = params

  if (connectivity.isOffline) {
    return {
      label: intl.formatMessage(messages.box_business_model_consultant),
      value: ROLES.CONSULTANT
    }
  }

  return roles.find(({ roleId }) => roleId === id) || {}
}

function findFunction(params) {
  const {
    functionId: id,
    connectivity,
    intl,
    messages,
    roleParams: { functions = [] }
  } = params

  if (connectivity.isOffline) {
    return {
      label: intl.formatMessage(messages.box_business_model_beauty_consultant),
      value: FUNCTIONS.BEAUTY_CONSULTANT
    }
  }

  return functions.find(({ functionId }) => functionId === id) || {}
}

function onReprocessRegistration(person, businessModel) {
  return function () {
    person.complete(businessModel).then(function ({ error }) {
      if (error) return

      person.getProfile(person.personId)
    })
  }
}
