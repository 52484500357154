import {DocumentFieldsMapper} from './DocumentFieldsMapper'
import {ATTACHMENT_TYPES} from '../../../../../../../AttachmentTypes'

export const DOCUMENTS = {
  NATIONAL_REGISTRY: 20
}

export const MESSAGES_ID = {
  [DOCUMENTS.NATIONAL_REGISTRY]: 'box_documents_label_document_national_registry'
}

const attachmentTypeToMessageIdsEnum = {
  [ATTACHMENT_TYPES.RUT_FRONT]: 'box_documents_label_attachments_document_front',
  [ATTACHMENT_TYPES.RUT_BACK]: 'box_documents_label_attachments_document_back',
}

export const getDocumentsFields = ({ intl, nationalityId, messages, documents, attachments }) => {
  const documentFieldsMapper = new DocumentFieldsMapper({
    intl,
    messagesId: MESSAGES_ID,
    attachmentTypeToMessageIdsEnum
  })
  const nationality = documentFieldsMapper.mapNationalityField({ nationalityId, messages })
  const documentsFields = documentFieldsMapper.mapDocuments({ documents, messages })
  const attachmentsField = documentFieldsMapper.mapAttachmentsField({ attachments, messages, documentsFields })
  const termsField = documentFieldsMapper.mapTermsField({ attachments, messages })

  return [nationality, ...documentsFields, attachmentsField, termsField]
}
