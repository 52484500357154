import { ADDRESS_USE } from 'src/models/Address/AddressUse'
import { parseAddress } from './AddressHome'
import { findAddress, findAddressUse } from 'src/models/Address'

export default function Builder(params) {
  const { person: { addresses } } = params

  const listAddress = [].concat(addresses)

  const mailingAddress = addresses.find(findAddress, { useId: ADDRESS_USE.MAILLING  })
  const deliveryAddress = addresses.find(findAddress, { useId: ADDRESS_USE.DELIVERY  })
  const alternativeAddress = listAddress.find(findAddress, { useId: ADDRESS_USE.ALTERNATIVE  })

  if (mailingAddress) {
    const index = listAddress.indexOf(mailingAddress)

    listAddress.splice(index, 1)
  }

  if (deliveryAddress && !mailingAddress.isUseToDelivery) {
    const index = listAddress.indexOf(deliveryAddress)

    listAddress.splice(index, 1)
  }

  if (alternativeAddress) {
    const index = listAddress.indexOf(alternativeAddress)

    listAddress.splice(index, 1)
  }

  return [
    createCard(alternativeAddress, params),
    ...listAddress.reduce(reduceAddresses, [params])
  ]
}

function reduceAddresses(accum, address, index, array) {
  const [params] = accum

  const lastItem = index === array.length -1

  if (lastItem) accum.shift()

  if (!address) return accum

  return [
    ...accum,
    {
      ...createCard(address, params),
      title: getTitle(address, params)
    }
  ]
}

function createCard(address, params) {
  const { intl, messages } = params

  const fields =  address ? parseAddress(address, params) : [{
    field: intl.formatMessage(messages.box_address_value_empty)
  }]

  return {
    id: 'profile-address-delivery',
    title: intl.formatMessage(messages.box_address_alternative_title),
    icon: 'ui_office',
    wrapper: 'default',
    content: 'list',
    fields
  }
}

function getTitle(address, { intl, messages }) {
  const isDeliveryAddress = address.addressUse.find(findAddressUse, { useId: ADDRESS_USE.DELIVERY })

  if (isDeliveryAddress) {
    return intl.formatMessage(messages.box_address_delivery_title)
  }

  return intl.formatMessage(messages.box_address_alternative_title)
}
