import moment from 'moment'

export default function Builder(params) {
  const { intl, messages, person, locale: { configs } } = params
  const { date: { format } } = configs.localization
  const {
    status: { description: status, isApproved } = {},
    substatus: { description: substatus } = {},
    paymentPlan: { description: paymentPlan } = {},
    additionalInformation: { rejectionReasonName } = {},
    isFormerSeller, approvalDate, registrationDate
  } = person

  return {
    id: 'profile-status',
    title: intl.formatMessage(messages.box_status_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      {
        label: intl.formatMessage(messages.box_status_label_status),
        field: status || '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_substatus),
        field: substatus || '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_reason),
        field: rejectionReasonName || '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_payment),
        field: paymentPlan || '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_restart),
        field: isFormerSeller
          ? intl.formatMessage(messages.box_status_label_restart_yes)
          : intl.formatMessage(messages.box_status_label_restart_no)
      },
      {
        label: intl.formatMessage(messages.box_status_label_start),
        field: dateFormated(registrationDate, format) || '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_approval),
        field: isApproved ? dateFormated(approvalDate, format) : '-'
      },
      {
        label: intl.formatMessage(messages.box_status_label_time),
        field: isApproved ? dateFromNow(approvalDate) : '-'
      }
    ]
  }
}

function dateFormated(date, format) {
  if (!date) return ''
  return moment(date).format(format)
}

function dateFromNow(date) {
  if (!date) return ''
  return moment(date).fromNow()
}
