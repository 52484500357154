import * as yup from 'yup'
import { defineMessages } from 'react-intl'

import { isDefaultFlowToggleActive } from '../../../../../../config'

export const messages = defineMessages({
  required: {
    defaultMessage: 'Required field',
    id: 'form.field.policy.error.required'
  }
})

const dependencies = {
  messages
}

export default function Builder(intl, injection, policiesField, termsAndConditionContent) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { policiesAccepted: { policiesAcceptedGroup } } } = injection
  const { Personal } = policiesField

  const POLICY_PERSONAL = { ...Personal(intl, { ...injection, ...dependencies, personalMessages: termsAndConditionContent }), required: !isDefaultFlowToggleActive() }

  const errorMsg = intl.formatMessage(messages.required)

  return {
    id: 'policiesAcceptedGroup',
    min: 1,
    max: 1,
    defaultValue: [policiesAcceptedGroup],
    fields: [
      [POLICY_PERSONAL]
    ],
    validations: yup.array().of(yup.object().shape({
      personal_data: yup.boolean().oneOf([true], errorMsg).required(),
    })),
  }
}
