import { connect } from 'react-redux'
import Select from 'src/base/Form/Components/Select'
import { messages } from '../Messages'

const dependencies = {
  messages
}

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }

  return {
    id: 'district',
    label: intl.formatMessage(messages['district']),
    placeholder: intl.formatMessage(messages['district_placeholder']),
    noOptionsMessage: intl.formatMessage(messages['district_no_options_message']),
    type: connect(mapStateToProps)(Select),
    offlineType: 'input',
    onChange: validateFieldDependencies,
    required: true,
    disabledBy: [
      'address[].region',
      'address[].department' ,
      'address[].province'
    ]
  }
}

function validateFieldDependencies({ setFieldValue, setFieldTouched, schema }) {
  const { group: { id }, parent } = schema

  setFieldValue(`${parent}.street`, '', id)
  setFieldValue(`${parent}.neighborhood`, '', id)
  setFieldValue(`${parent}.number`, '', id)
  setFieldValue(`${parent}.references`, '', id)

  setFieldTouched(`${parent}.street`, false)
  setFieldTouched(`${parent}.neighborhood`, false)
  setFieldTouched(`${parent}.number`, false)
  setFieldTouched(`${parent}.references`, false)
}

function mapStateToProps(state, ownProps) {
  const { addresses: { addresses, loading: { districts: districtLoading }, error, addressesPeopleManagement }, locale: { locale } } = state
  const { group: { index }, form: { values: { address } }} = ownProps
  const { getAddressesCMM, districts: districtsCMM } = addresses
  const { getLevels, districts: districtsBFF } = addressesPeopleManagement
  const { configs: { localization: { shouldGetAddressFromBff }}} = locale

  const { province } = address[index] || {}

  const districts = shouldGetAddressFromBff ? districtsBFF : districtsCMM
  const options = districts[province]
  const loading = districtLoading && districtLoading[province]

  const shouldGetDistricts = !options && !districtLoading && !error.addresses && province

  if (shouldGetDistricts) {
    getDistricts({province, getAddressesCMM, getLevels, shouldGetAddressFromBff })
  }

  return {
    ...ownProps,
    loading,
    options: options ?? [],
  }
}

const getDistricts = ({province, getAddressesCMM, getLevels, shouldGetAddressFromBff }) => {
  if(shouldGetAddressFromBff) {
    getLevels({
      geoStructureType: 'districts',
      queryParams: `level=4&parentLevel=3&parentCode=${province}`,
      geoStructureCode: province
    })
  } else {
    getAddressesCMM({
      geoStructureCode: province,
      geoStructureType: 'districts'
    })
  }
}
