import { getContext } from 'src/infra/Api/Api.context'
import Error from 'src/viewModels/Error'
import Upload from 'src/viewModels/Upload'
import { CLIENT_NAMES } from '../../infra/Api/Api'

export const UPLOAD_LOADING = 'natura-caduni/People/UPLOAD_LOADING'
export const UPLOAD_SUCCESS = 'natura-caduni/People/UPLOAD_SUCCESS'
export const UPLOAD_ERROR = 'natura-caduni/People/UPLOAD_ERROR'

export const types = [UPLOAD_LOADING, UPLOAD_SUCCESS, UPLOAD_ERROR]

export function uploadReducer(state, action, dependencies) {
  const { type, payload, meta } = action
  let { loading, error, upload } = state

  const isSuccess = type === UPLOAD_SUCCESS
  const isError = type === UPLOAD_ERROR
  const isLoading = type === UPLOAD_LOADING

  if (isLoading) {
    const { attachmentType } = payload
    const attachments = {
      ...upload.attachments,
      [attachmentType]: {
        ...upload.attachments[attachmentType],
        saved: false
      }
    }

    upload = new Upload({ attachments }, dependencies)
    loading = { ...loading, [attachmentType]: true }
    error = false
  }

  if (isSuccess) {
    const { previousAction: { payload: { attachmentType } }} = meta
    const attachments = {
      ...upload.attachments,
      [attachmentType]: {
        ...upload.attachments[attachmentType],
        saved: true
      }
    }

    upload = new Upload({ attachments }, dependencies)
    loading = { ...loading, [attachmentType]: false }
    error = false
  }

  if (isError) {
    const { previousAction: { payload: { attachmentType } }} = meta
    const attachments = {
      ...upload.attachments,
      [attachmentType]: {
        ...upload.attachments[attachmentType],
        saved: false
      }
    }

    upload = new Upload({ attachments }, dependencies)
    loading = { ...loading, [attachmentType]: false }
    error = new Error(action.error)
  }

  return {
    loading,
    error,
    upload,
  }
}

export function saveAction({ file, uploadUrl, type }) {
  const { dispatch } = getContext()

  return dispatch({
    types,
    payload: {
      client: CLIENT_NAMES.S3,
      attachmentType: type,
      request: {
        method: 'PUT',
        url: uploadUrl,
        data: file,
        headers: { 'Content-Type': file.type }
      }
    }
  })
}
