import { getContext } from 'src/infra/Api/Api.context'

const PERSON_RESET_ERRORS = 'natura-caduni/Person/PERSON_RESET_ERRORS'

export const types = [PERSON_RESET_ERRORS]

export function personResetErrorsReducer(state) {
  return {
    ...state,
    loading: false,
    error: false
  }
}

export function resetPersonErrors() {
  const { dispatch } = getContext()

  return dispatch({
    type: PERSON_RESET_ERRORS
  })
}
